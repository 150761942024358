import { useCallback, useEffect } from "react";
import { Comment, NewComment } from "../../Models/comments";
import { baseAddress, offline } from "../../settings";
import CookieService from "../cookieService";
import axios from "axios";
import { useSocket } from "../Sockets/useSocket";

//* ADD A NEW COMMENT, THIS IS SHARED ACROSS MULTIPLE APPS
const add = async (comment: NewComment) => {
  // Add a new project
  console.warn("ADDING NEW COMMENT");
  console.log(comment);
  let result: Comment | undefined = undefined;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    const response = await axios.post(
      baseAddress + "/comments/",
      comment,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.status === 201 ? (response.data as Comment) : undefined;
    console.log("ADDED COMMENT", result);
  } catch (error) {
    console.error("ERROR ADDING COMMENT", error);
  }
  return result;
};

// const addViaSockets = (comment: NewComment) => {
//   try {
//     const socket = useSocket();
//     socket.send(
//       JSON.stringify({
//         comment: comment,
//       })
//     );
//   } catch (error) {
//     console.error("SOCKET ERROR ADDING" + error);
//   }
// };

// const receiveViaSockets = () => {
//   const socket = useSocket();
//   const onMessage = useCallback((message) => {
//     const data = JSON.parse(message?.data);
//   }, []);

//   useEffect(() => {
//     socket.addEventListener("message", onMessage);

//     return () => {
//       socket.removeEventListener("message", onMessage);
//     };
//   }, [socket, onMessage]);
// };

interface UpdateBody {
  commentText?: string;
  pinned?: boolean;
  taggedUsers?: string[];
}

const update = async (commentId: string, comment: UpdateBody) => {
  // Add a new project
  let result: Comment | undefined = undefined;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    let body: UpdateBody = {};
    if (comment.commentText) {
      body.commentText = comment.commentText;
    }
    if (comment.pinned !== undefined) {
      body.pinned = comment.pinned;
    }
    if (comment.taggedUsers) {
      body.taggedUsers = comment.taggedUsers;
    }
    const response = await axios.patch(
      baseAddress + "/comments/" + commentId + "/",
      body,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    result = response.status === 200 ? (response.data as Comment) : undefined;
    console.log("UPDATED COMMENT", result);
  } catch (error) {
    console.error("ERROR UPDATING COMMENT", error);
  }
  return result;
};

const getList = async (filter: string, id: string) => {
  // Add a new project
  let result: Comment[] = []
  if (offline) return result;
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) throw "AccessTokenNotFound";
  try {
    const response = await axios.get(
        `${baseAddress}/comments/?${filter}=${id}`,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
    );
    console.log(response.data)
    result = response.status === 200 ? (response.data.results as Comment[]) : [];
  } catch (error) {
    return result
  }
  return result;
};

const CommentAPI = {
  add,
  update,
  getList
};

export default CommentAPI;
