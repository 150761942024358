import React, { ReactNode, useEffect, useState } from 'react'
import Dropdown from 'react-dropdown'
import { IXeroContext, useXeroContext } from '../../Providers/XeroProvider'

import { ReactComponent as DropdownDown } from '../../../Assets/DropdownArrowDown.svg'

import { ReactComponent as DropdownUp } from '../../../Assets/DropdownArrowUp.svg'

import './AccountMapping.css'
import { Project } from '../../../Models/project'
import { XeroProjectData } from '../../../Models/xeroData'

type Props = {
  projects: Project[]

  handleLinkProject: Function
}

const DropdownProjectLink = ({
  projects,

  handleLinkProject,
}: Props) => {
  //$$$$$$$$CONTROL XERO AUTH$$$$$$$$
  const xeroContext = useXeroContext() as IXeroContext
  const { isXeroLinked, getAllProjectLinks } = xeroContext
  //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  //###################HANDLE FETCHING XERO PROJECT DATA######
  const [loading, setLoading] = useState<boolean>(true)

  //initial useEffect to fetch all xero projects
  useEffect(() => {
    fetchXeroProjectData()
  }, [])

  const fetchXeroProjectData = async () => {
    try {
      let result = await getAllProjectLinks()
      if (result) {
        setXeroProjects([...result])

        //########SET DEFAULT VALUE DROPDOWN#####
        //check trackingOptionId of current project
        //if currentProject has trackingOptionId => set current selected dropdown item to this current project
        let currentSelectedProjectLocalStorage: string | null =
          localStorage.getItem('currentProject')
        let matchedXeroProject: IDropdownItems | undefined
        if (currentSelectedProjectLocalStorage !== null) {
          let currentProject: Project = JSON.parse(
            currentSelectedProjectLocalStorage
          ) as Project

          ;(result as XeroProjectData[]).map((data) => {
            if (currentProject.trackingId === data.TrackingOptionID) {
              setCurrentProjectOption({
                ...currentProjectOption,
                label: DropdownItem(data.Name, true),
                value: data.TrackingOptionID,
                className: 'dropdownProjectItemStyle',
              })
              matchedXeroProject = {
                ...currentProjectOption,
                label: DropdownItem(data.Name, true),
                value: data.TrackingOptionID,
                className: 'dropdownProjectItemStyle',
              }
            }
          })
        }
        //UPDATE DROPDOWN LiST FOR STYLING
        convertToDropdownData(
          result as XeroProjectData[],
          setProjectOptions,
          matchedXeroProject
        )
        //#######################################
        setLoading(false)
      } else throw 'FAILED TO FETCH XERO PROJECT DATA'
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  const [xeroProjects, setXeroProjects] = useState<XeroProjectData[]>([])

  // useEffect(() => {
  //   settingCurrentDropdownItem(xeroProjects)
  // }, [xeroProjects])

  //#######################################################

  //###############HANDLE CONVERT XERO PROJECT DATA TO DROPDOWN DATA####

  //state to store all options of dropdown
  const [projectOptions, setProjectOptions] = useState<IDropdownItems[]>([])

  //state to get selected project
  //initially, if the current project has TrackingOptionId=> this state will be set to that current project (in dropdown option format)
  const [currentProjectOption, setCurrentProjectOption] = useState<
    IDropdownItems | undefined
  >(undefined)

  //useEffect to invoke convertToDropdownData method to transform data to dropdown
  // useEffect(() => {
  //   convertToDropdownData(xeroProjects, setProjectOptions, currentProjectOption)
  // }, [currentProjectOption])

  //update dropdown list for styling
  const handleChangeDropdownList = () => {
    if (currentProjectOption) {
      let newProjectOption: IDropdownItems[] = []
      projectOptions.map((option) => {
        if (option.value === currentProjectOption.value) {
          newProjectOption.push(currentProjectOption)
        } else
          newProjectOption.push({
            ...option,
            label: DropdownItem(option.value, false),
          })
      })
      setProjectOptions([...newProjectOption])
    }
  }

  //#######################################################
  const handleSelectDropdownItem = async (item: string) => {
    //call update api first
    if (handleLinkProject(item)) {
      //########update dropdown#######
      let addressMatch: XeroProjectData = xeroProjects.filter(
        (data) => data.TrackingOptionID === item
      )[0]
      setCurrentProjectOption({
        label: DropdownItem(addressMatch.Name, true),
        value: item,
        className: 'dropdownProjectItemStyle',
      })
      //#############################

      convertToDropdownData(xeroProjects, setProjectOptions, {
        label: DropdownItem(addressMatch.Name, true),
        value: item,
        className: 'dropdownProjectItemStyle',
      })
    } else prompt('FAILED TO LINK PROJECT')
    // let xeroTrackingId:XeroProjectData = xeroProjects.filter(data=> data.Name)

    //update project to server
  }

  return (
    <div
      className='rootDropdownProjectLink'
      // style={{
      //   width: '302px',
      //   height: '35px',
      //   backgroundColor: '#F1F2F5',
      //   borderRadius: '5px',
      //   display: 'flex',
      //   flexDirection: 'row',
      //   fontFamily: 'IBM Plex Sans',
      //   fontStyle: 'normal',
      //   fontWeight: '400',
      //   fontSize: '12px',
      //   lineHeight: '16px',
      //   /* identical to box height */
      //   textAlign: 'center',
      //   letterSpacing: '0.05em',
      //   textTransform: 'lowercase',
      // }}
    >
      {/*dropdown Container */}
      <div className='dropdownContainerStyle'>
        <Dropdown
          className='dropdownStyle'
          controlClassName='controlDropdownStyle'
          menuClassName='menuDropdownStyle'
          baseClassName='baseDropdownStyle'
          placeholderClassName='placeholderDropdownStyle'
          options={projectOptions}
          onChange={(item) => handleSelectDropdownItem(item.value)}
          value={currentProjectOption}
          placeholder='Select an option'
        />
      </div>

      {/*Icon Container */}
      <div
        style={{
          width: '50px',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderLeft: '1px solid #D7CCF5',
          cursor: 'pointer',
        }}
      >
        <DropdownDown />
      </div>
    </div>
  )
}

export default DropdownProjectLink

//#############DROPDOWN OPTION FORMAT############

//this is the row selection (will be set to the value of key "label" in IDropdownItems)
const DropdownItem = (projectAddress: string, isSelected?: boolean) => {
  return (
    <div
      style={{
        color: isSelected && isSelected === true ? '#7949FF' : 'black',
        fontFamily: 'IBM Plex Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '16px',
        /* identical to box height */
        textAlign: 'center',
        letterSpacing: '0.05em',
        cursor: 'pointer',
      }}
    >
      {projectAddress}
    </div>
  )
}
interface IDropdownItems {
  label: ReactNode
  value: string
  className: string //className to style the dropdown item row
}

//method to convert "projects" Data to dropdown options data (only run when projects list change)
const convertToDropdownData = (
  projects: XeroProjectData[],
  stateControl: Function,
  currentSelectedProject: IDropdownItems | undefined
) => {
  if (!currentSelectedProject) {
    let dropdownOptions: IDropdownItems[] = projects.map((project) => {
      return {
        label: DropdownItem(project.Name),
        value: project.TrackingOptionID,
        className: 'dropdownProjectItemStyle',
      }
    })
    stateControl([...dropdownOptions])
  } else {
    let dropdownOptions: IDropdownItems[] = projects.map((project) => {
      let isSelected: boolean =
        project.TrackingOptionID === currentSelectedProject.value ? true : false
      return {
        label: DropdownItem(project.Name, isSelected),
        value: project.TrackingOptionID,
        className: 'dropdownProjectItemStyle',
      }
    })
    stateControl([...dropdownOptions])
  }
}
//###############################################,
