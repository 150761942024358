import React from 'react';
import selectedTick from "../../../Assets/selectedTick.svg";
import selectedCross from "../../../Assets/selectedCross.svg";
import selectedNA from "../../../Assets/selectedNA.svg";
import defaultTick from "../../../Assets/defaultTick.svg";
import defaultCross from "../../../Assets/defaultCross.svg";
import defaultNA from "../../../Assets/defaultNA.svg";
import { Paper, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, IconButton } from "@mui/material";
import { Checklist } from '../../../Models/checklist';

interface props{
    checklist: Checklist,
    handleCheckListChange: Function
    tableMode?: boolean
}

const AnswerIcons: React.FC<props> = ({
    checklist,
    handleCheckListChange,
    tableMode
}) => {
    // console.log("BACKEND ANSWER: " + checklist.answer)
    const handleSelectIcon = (inputAnswer: string) => {

        if(handleCheckListChange){
            handleCheckListChange(checklist, inputAnswer)
        }
    }

    const getChecklistAnswer = (inputAnswer:"YES"|"NO"|"NA"|null) =>{
        switch(inputAnswer?.toUpperCase()){
            case "YES":
                let yesAnswer = [
                    <div key="yes" style={{
                        display:"flex",
                        flexDirection:"row",
                        gap:"10px",
                        justifyContent: "center"
                    }}>
                        <IconButton
                        size="small"
                        onClick={(event: any) => handleSelectIcon("YES")}
                        sx={{
                            backgroundColor: "#23B88B",
                            textAlign: "right",
                            width: tableMode ? "20px" : "28px",
                            height: tableMode ? "20px" : "28px",
                            '&:hover': {
                                backgroundColor: "#23B88B",
                            }
                        }}
                        >
                            <img src={selectedTick} alt="selected tick" width={tableMode ? "14px" : "18px"} height={tableMode ? "14px" : "18px"}/>
                        </IconButton>
                        <IconButton
                        
                        size="small"
                        onClick={(event: any) => handleSelectIcon("NO")}
                        sx={{
                            backgroundColor: "none",
                            border:"solid #D6303A",
                            borderWidth:"thin",
                            textAlign: "right",
                            width: tableMode ? "20px" : "28px",
                            height: tableMode ? "20px" : "28px",
                            '&:hover': {
                                backgroundColor: "white",
                            }
                        }}

                        >
                            <img src={defaultCross} alt="default cross" width={tableMode ? "14px" : "18px"} height={tableMode ? "14px" : "18px"}/>

                        </IconButton>
                        <IconButton
                        size="small"
                        onClick={(event: any) => handleSelectIcon("NA")}
                        sx={{
                            backgroundColor: "none",
                            border:"solid #363a45",
                            borderWidth:"thin",
                            textAlign: "right",
                            width: tableMode ? "20px" : "28px",
                            height: tableMode ? "20px" : "28px",
                            '&:hover': {
                                backgroundColor: "white",
                            }
                        }}
                        >
                            <img src={defaultNA} alt="default NA" width={tableMode ? "14px" : "18px"} height={tableMode ? "14px" : "18px"}/>
                        </IconButton>
                    </div>
                ]
                return yesAnswer;

            case "NO":
                let noAnswer = [
                    <div key="no" style={{
                        display:"flex",
                        flexDirection:"row",
                        gap:"10px",
                        justifyContent: "center"
                    }}>
                        <IconButton
                        size="small"
                        onClick={(event: any) => handleSelectIcon("YES")}
                        sx={{
                            backgroundColor: "none",
                            border:"solid #23B88B",
                            borderWidth:"thin",
                            textAlign: "right",
                            width: tableMode ? "20px" : "28px",
                            height: tableMode ? "20px" : "28px",
                            '&:hover': {
                                backgroundColor: "white",
                            }
                        }}
                        >
                            <img src={defaultTick} alt="default tick" width={tableMode ? "14px" : "18px"} height={tableMode ? "14px" : "18px"}/>
                        </IconButton>
                        <IconButton
                        size="small"
                        onClick={(event: any) => handleSelectIcon("NO")}
                        sx={{
                            backgroundColor: "#D6303A",
                            textAlign: "right",
                            width: tableMode ? "20px" : "28px",
                            height: tableMode ? "20px" : "28px",
                            '&:hover': {
                                backgroundColor: "#D6303A",
                            }
                        }}
                        >
                            <img src={selectedCross} alt="selected cross" width={tableMode ? "14px" : "18px"} height={tableMode ? "14px" : "18px"}/>
                        </IconButton>
                        <IconButton
                        size="small"
                        onClick={(event: any) => handleSelectIcon("NA")}
                        sx={{
                            backgroundColor: "none",
                            border:"solid #363A45",
                            borderWidth:"thin",
                            textAlign: "right",
                            width: tableMode ? "20px" : "28px",
                            height: tableMode ? "20px" : "28px",
                            '&:hover': {
                                backgroundColor: "white",
                            }
                        }}
                        >
                            <img src={defaultNA} alt="default NA" width={tableMode ? "14px" : "18px"} height={tableMode ? "14px" : "18px"}/>
                        </IconButton>
                    </div>
                ]
                return noAnswer;

            case "NA":
            let naAnswer = [
                <div key="na" style={{
                    display:"flex",
                    flexDirection:"row",
                    gap:"10px",
                    justifyContent: "center"
                }}>
                    <IconButton
                    size="small"
                    onClick={(event: any) => handleSelectIcon("YES")}
                    sx={{
                        backgroundColor: "none",
                        border:"solid #23B88B",
                        borderWidth:"thin",
                        textAlign: "right",
                        width: tableMode ? "20px" : "28px",
                        height: tableMode ? "20px" : "28px",
                        '&:hover': {
                            backgroundColor: "white",
                        }
                    }}
                    >
                        <img src={defaultTick} alt="default tick"width={tableMode ? "14px" : "18px"} height={tableMode ? "14px" : "18px"}/>
                    </IconButton>
                    <IconButton
                    size="small"
                    onClick={(event: any) => handleSelectIcon("NO")}
                    sx={{
                        backgroundColor: "none",
                        border:"solid #D6303A",
                        borderWidth:"thin",
                        textAlign: "right",
                        width: tableMode ? "20px" : "28px",
                        height: tableMode ? "20px" : "28px",
                        '&:hover': {
                            backgroundColor: "white",
                        }
                    }}
                    >
                        <img src={defaultCross} alt="default cross" width={tableMode ? "14px" : "18px"} height={tableMode ? "14px" : "18px"}/>
                    </IconButton>
                    <IconButton
                    size="small"
                    onClick={(event: any) => handleSelectIcon("NA")}
                    sx={{
                        backgroundColor: "#363A45",
                        textAlign: "right",
                        width: tableMode ? "20px" : "28px",
                        height: tableMode ? "20px" : "28px",
                        '&:hover': {
                            backgroundColor: "#363A45",
                        }
                    }}
                    >
                        <img src={selectedNA} alt="selected NA" width={tableMode ? "14px" : "18px"} height={tableMode ? "14px" : "18px"}/>
                    </IconButton>
                </div>
            ]
                return naAnswer;

            default:
                let defaultAnswer = [
                    <div key="default" style={{
                        display:"flex",
                        flexDirection:"row",
                        gap:"10px",
                        justifyContent: "center"
                    }}>
                        <IconButton
                            size="small"
                            onClick={(event: any) => handleSelectIcon("YES")}
                            sx={{
                                backgroundColor: "none",
                                border:"solid #23B88B",
                                borderWidth:"thin",
                                textAlign: "right",
                                width: tableMode ? "20px" : "28px",
                                height: tableMode ? "20px" : "28px",
                                '&:hover': {
                                    backgroundColor: "white",
                                }
                            }}
                        >
                            <img src={defaultTick} alt="default tick" width={tableMode ? "14px" : "18px"} height={tableMode ? "14px" : "18px"}/>
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={(event: any) => handleSelectIcon("NO")}
                            sx={{
                                backgroundColor: "none",
                                border:"solid #D6303A",
                                borderWidth:"thin",
                                textAlign: "right",
                                width: tableMode ? "20px" : "28px",
                                height: tableMode ? "20px" : "28px",
                                '&:hover': {
                                    backgroundColor: "white",
                                }
                            }}
                        >
                            <img src={defaultCross} alt="default cross" width={tableMode ? "14px" : "18px"} height={tableMode ? "14px" : "18px"}/>
                        </IconButton>
                        <IconButton
                        size="small"
                        onClick={(event: any) => handleSelectIcon("NA")}
                        sx={{
                            backgroundColor: "none",
                            border:"solid #363A45",
                            borderWidth:"thin",
                            textAlign: "right",
                            width: tableMode ? "20px" : "28px",
                            height: tableMode ? "20px" : "28px",
                            '&:hover': {
                                backgroundColor: "white",
                            }
                        }}
                        >
                            <img src={defaultNA} alt="default NA" width={tableMode ? "14px" : "18px"} height={tableMode ? "14px" : "18px"}/>
                        </IconButton>
                    </div>
                ]
                return defaultAnswer;
        }
    }

    return(
        //React Fragment
        <>
            {checklist
            ?
            getChecklistAnswer(checklist.answer)
            :
            <></>
            }
        </>
    )
}

export default AnswerIcons