import React, { useState, useEffect } from "react"
import {Notice, NoticeCategory} from "../../../Models/notice"
import EditNotice from "./EditNotice"
import "../Notices/Notices.css"
import { User } from "../../../Models/user"
import { TableRow, TableCell, Popover, Stack, Box } from "@mui/material"
import { useStyles } from "./noticesStyle"
import { highlightText } from "../../../utilities/Markdown/markdown"
import NoticeMenu from "./NoticeMenu"
import AddComment from "../Comments/AddComment"
import Badge from "@mui/material/Badge"
import { ReactComponent as AttachmentIcon } from "../../../Assets/Clip.svg"
import { ReactComponent as AttachmentIconAlt } from "../../../Assets/Clip-alt.svg"
import titleCase from "../../../utilities/Helper/titleCase"
import { ColumnView } from "../../../Models/columnView"
import CommentsPopover from "../Comments/CommentsPopover"
import dayjs, { Dayjs } from "dayjs"
import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes"
interface props {
  notice: Notice
  categoryList: NoticeCategory[]
  notices: Notice[]
  setNotices: React.Dispatch<React.SetStateAction<Notice[]>>;
  setOpenModal: Function
  setSelectedNotice: Function
  descriptionColour?: string
  userList: User[]
  handleRowClick: Function
  forceUpdate: Function
  columnView?: ColumnView
  handleDeleteSingleNotice: Function
}

const NoticeRow: React.FC<props> = ({
  notice,
  categoryList,
  notices,
  setNotices,
  setOpenModal,
  setSelectedNotice,
  descriptionColour,
  userList,
  handleRowClick,
  forceUpdate,
  columnView,
  handleDeleteSingleNotice,
}) => {
  const [completed, setCompleted] = useState<boolean>(notice.isComplete)
  const [dateRangeValue, setDateRangeValue] = React.useState<DateRange<Dayjs>>([
    dayjs(notice.startDate),
    dayjs(notice.dueDate),
  ])
  const getRowClasses = () => {
    let rowClass: string = "tableRowStyle"
    if (completed) {
      rowClass += " strikeout"
    }
    return rowClass
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <TableRow
      className={getRowClasses()}
      key={notice.noticeId}
      sx={{
        "& .MuiTableCell-root": {
          borderBottom: " 0.3px solid #C7B4FC;",
        },
      }}
    >
      <TableCell sx={{ p: 0 }}></TableCell>
      <TableCell sx={useStyles.tableCheckboxStyle}>
        {/* <EditNotice
          notice={notice}
          action={"mark"}
          categoryList={categoryList}
          notices={notices}
          setNotices={setNotices}
          completed={completed}
          setCompleted={setCompleted}
        /> */}

        {/*DISPLAY STATUS */}
        <EditNotice
          notice={notice}
          action={"status"}
          categoryList={categoryList}
          notices={notices}
          setNotices={setNotices}
          userList={userList}
        />
        {/* <div
          style={{
            width: "6px",
            height: "6px",
            border: `2px solid ${getStatusColor(notice.status)}`,
            borderRadius: "2.5px",
          }}
        ></div> */}
      </TableCell>
      <TableCell
        className="strikeCell"
        sx={useStyles.tableBodyTitleFieldStyle}
        colSpan={3}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            onClick={() => handleRowClick(notice)}
            key={notice.noticeId}
            style={{
              color:
                descriptionColour === notice.noticeId || notice.pinned
                  ? "#E9121F"
                  : notice.isPrivate
                  ? "#66539C"
                  : "black",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "flex",
              lineHeight: "15px",
              margin: 0,
              cursor: "pointer",
              fontFamily: "IBM Plex Sans",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "11px",
            }}
          >
            {titleCase(notice.description)}
            {/* {highlightText(notice.description, notice.noticeId )} */}
          </p>
          <NoticeMenu
            notice={notice}
            categoryList={categoryList}
            notices={notices}
            setNotices={setNotices}
            setOpenModal={setOpenModal}
            setSelectedNotice={setSelectedNotice}
            completed={completed}
            setCompleted={setCompleted}
            forceUpdate={forceUpdate}
            handleDeleteSingleNotice={handleDeleteSingleNotice}
          />
          <div
            onClick={() => handleRowClick(notice)}
            style={{
              height: "33px",
              cursor: "pointer",
              display: "flex",
              flexGrow: 1,
            }}
          />
        </div>
      </TableCell>

      <TableCell
        className="strikeCell"
        style={{ paddingLeft: "10px", paddingRight: 0 }}
        sx={{
          borderLeft: "0.3px solid #C7B4FC",
          ...useStyles.tableBodyStyle,
        }}
      >
        <Badge
          sx={{
            "& .MuiBadge-badge": {
              background: "#E801DE",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "8px",
              lineHeight: "8px",
              height: "12px",
              width: "9px",
              minWidth: "9px",
              top: "-1px",
              left: "-1px",
              color: "white",
            },
          }}
          badgeContent={notice.attachments?.length}
          onClick={() => handleRowClick(notice)}
        >
          {notice.attachments && notice.attachments?.length > 0 ? (
            <AttachmentIconAlt />
          ) : (
            <AttachmentIcon />
          )}
        </Badge>
      </TableCell>
      <TableCell
        className="strikeCell"
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: "0px",
          paddingRight: "25px",
          cursor: "pointer",
        }}
        sx={useStyles.tableBodyStyle}
        onMouseEnter={
          notice.comments && notice.comments.length > 0
            ? handlePopoverOpen
            : undefined
        }
        onMouseLeave={handlePopoverClose}
      >
        <AddComment notice={notice} clickFunction={handleRowClick} />
        <CommentsPopover
          commentList={notice.comments}
          anchorEl={anchorEl}
          handlePopoverClose={handlePopoverClose}
        />
      </TableCell>
      {columnView?.assignee && (
        <TableCell
          className="strikeCell"
          style={{ paddingLeft: "3px" }}
          sx={{
            borderLeft: "0.3px solid #C7B4FC",
            ...useStyles.tableBodyStyle,
          }}
        >
          <EditNotice
            notice={notice}
            action={"assignee"}
            categoryList={categoryList}
            notices={notices}
            setNotices={setNotices}
            userList={userList}
          />
        </TableCell>
      )}
      {columnView?.startDate && (
        <TableCell
          className="strikeCell"
          sx={{
            lineHeight: 1,
            borderLeft: "0.3px solid #C7B4FC",
            ...useStyles.tableBodyStyle,
          }}
        >
          <EditNotice
            notice={notice}
            action={"startDate"}
            categoryList={categoryList}
            notices={notices}
            setNotices={setNotices}
            userList={userList}
            dateRange={dateRangeValue}
            setDateRangeValue={setDateRangeValue}
          />
        </TableCell>
      )}
      {columnView?.endDate && (
        <TableCell
          className="strikeCell"
          sx={{
            lineHeight: 1,
            borderLeft: "0.3px solid #C7B4FC",
            ...useStyles.tableBodyStyle,
          }}
        >
          <EditNotice
            notice={notice}
            action={"duedate"}
            categoryList={categoryList}
            notices={notices}
            setNotices={setNotices}
            userList={userList}
            setDateRangeValue={setDateRangeValue}
            dateRange={dateRangeValue}
          />
        </TableCell>
      )}
      {/* <TableCell
        className="strikeCell"
        sx={{
          lineHeight: 1,
          borderLeft: "1px solid #C7B4FC",
          ...useStyles.tableBodyStyle,
        }}
      >
        <EditNotice
          notice={notice}
          action={"status"}
          categoryList={categoryList}
          notices={notices}
          setNotices={setNotices}
          userList={userList}
        />
      </TableCell> */}
    </TableRow>
  )
}

export default React.memo(NoticeRow)
