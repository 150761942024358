import React from "react";
// import "../Menu/filterNavigation.css";
import { IconButton, Menu, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as MoreIcon } from "../../../Assets/MoreVert.svg";

interface props {
    handleRename: Function;
    handleDelete: Function;
}

const FileMenu: React.FC<props> = ({ handleRename, handleDelete }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = () => {
        setAnchorEl(null);
    };

    const handleRenameClick = () => {
        handleRename();
        handleClose();
    };

    const handleDeleteClick = () => {
        handleDelete();
        handleClose();
    };

    return (
        <>
            <IconButton
                sx={{
                    width: "24px",
                    height: "24px",
                    m: "-10px 3px 0 3px",
                    p: 0,
                    "&:hover": { background: "none" },
                    position: "relative",
                }}
                size="large"
                aria-label="storage file"
                aria-controls="menu-file"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                disableFocusRipple={true}
                disableTouchRipple={true}
                disableRipple={true}
            >
                <MoreIcon />
            </IconButton>
            <Menu
                id="menu-costcode"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    "& .MuiList-root": {
                        p: 0,
                    },
                    "& .MuiMenu-paper": {
                        minWidth: "185px",
                    },
                    "& .MuiMenuItem-root": {
                        fontFamily: "Roboto Mono",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "16px",
                        alignItems: "center",
                    },
                }}
            >
                <MenuItem
                    key={"close"}
                    disableRipple
                    sx={{
                        justifyContent: "end",
                        height: "35px",
                        paddingRight: "6px",
                        "&:hover": { background: "none" },
                    }}
                >
                    <IconButton
                        sx={{
                            width: "21px",
                            height: "21px",
                            m: 0,
                            p: 0,
                            cursor: "pointer",
                            borderRadius: 1,
                            background: "#F5F6F7",
                        }}
                        size="small"
                        onClick={handleClose}
                    >
                        <CloseIcon sx={{ transform: "scale(0.8)" }} />
                    </IconButton>
                </MenuItem>

                <MenuItem onClick={handleRenameClick} key={"menu-rename"}>
                    RENAME FILE
                </MenuItem>
                <MenuItem onClick={handleDeleteClick} key={"menu-delete"}>
                    DELETE FILE
                </MenuItem>

                <MenuItem
                    key={"footer"}
                    onClick={handleClose}
                    style={{
                        marginTop: "20px",
                        background: "#F1F2F5",
                        justifyContent: "center",
                        color: "#754EE0",
                        height: "35px",
                    }}
                >
                    File Menu
                </MenuItem>
            </Menu>
        </>
    );
};
export default FileMenu;
