import axios from "axios"
import CookieService from "../cookieService";
import { baseAddress, offline } from "../../settings";

const finalizeXeroConnection = async (companyId: string, code: string | null, state: string | null)  => {
  let result: any = undefined
  if (offline){ return result; }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }

  try {
      const response = await axios.post(
        baseAddress+"/xero/call_back",
        {
          "companyId": companyId,
          "code": code,
          "state": state
        },
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      )
      result = await response.data
      console.log("ACCOUNTING DETAILS", result)
  } catch (error) {
      console.error("ERROR FETCHING ACCOUNTING DETAILS", error)
      return error
  }
  return result
}


//
// const getXeroBillAttachment = async (costCodeId: string, invoiceId: string)  => {
//     let result: any[] = []
//     if (offline){ return result; }
//     const accessToken: string = CookieService.get("access_token");
//     if (!accessToken){ throw "AccessTokenNotFound" }
//     try {
//         const response = await axios.post(
//             baseAddress+"/xero/attachment/",
//             {
//                 "costCodeId": costCodeId,
//                 "billId": invoiceId
//             },
//             {
//                 headers: { Authorization: "Bearer " + accessToken },
//             }
//         )
//         result = await response.data
//         console.log("ATTACHMENTS", result)
//     } catch (error) {
//         console.error("ERROR FETCHING ATTACHMENTS", error)
//         return error
//     }
//     return result
// }

const accountingDetailAPI = {
    finalizeXeroConnection,
    // getXeroBillAttachment
  };
  
  export default accountingDetailAPI