import React from "react";
import { Invoice } from "../../../Models/invoice";
import moment from "moment";
import { TableCell, TableRow, IconButton } from "@mui/material";
import { ReactComponent as FileOnIcon } from "../../../Assets/FileAttOn.svg";
import { ReactComponent as FileOffIcon } from "../../../Assets/FileAttOff.svg";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import accountingDetailAPI from "../../../Services/DoxleAPI/accountingDetails";


interface props {
    costCodeId: string;
    invoice: Invoice;
}

const InvoiceRow: React.FC<props> = ({ costCodeId, invoice }) => {
    const formatter: Intl.NumberFormat = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });
    // const authContext = useAuth() as authContextInterface;
    // const { setLoggedIn } = authContext;
    const url = `https://go.xero.com/organisationlogin/default.aspx?shortcode=!WhLnS&redirecturl=/AccountsPayable/Edit.aspx?InvoiceID=${invoice.InvoiceID}`;
    // const handleAttachmentIconClick = async () => {
    //     try {
    //         const attachments = await accountingDetailAPI.getXeroBillAttachment(costCodeId, invoice.InvoiceID) as any[]
    //         attachments.forEach(att => {
    //             if (att?.Url) window.open(att.Url, '_blank')
    //         })
    //     } catch (err) {
    //         err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    //     }
    // }
    return (
        <TableRow>
            <TableCell
                onClick={() => {window.open(url, '_blank')}}
                style={{ cursor: "pointer" }}
            >{invoice.InvoiceNumber}</TableCell>
            <TableCell
                onClick={() => {window.open(url, '_blank')}}
                style={{ cursor: "pointer" }}
            >{invoice.Contact.Name}</TableCell>
            <TableCell
                onClick={() => {window.open(url, '_blank')}}
                style={{ cursor: "pointer" }}
            >
                {moment(invoice.DateString).format("DD MMM yyyy")}
            </TableCell>
            <TableCell
                onClick={() => {window.open(url, '_blank')}}
                style={{ cursor: "pointer" }}
            >
                {moment(invoice.DueDateString).format("DD MMM yyyy")}
            </TableCell>
            <TableCell
                onClick={() => {window.open(url, '_blank')}}
                style={{ cursor: "pointer" }}
            >{formatter.format(invoice.AmountPaid)}</TableCell>
            <TableCell
                onClick={() => {window.open(url, '_blank')}}
                style={{ cursor: "pointer" }}
            >{formatter.format(invoice.AmountDue)}</TableCell>
            <TableCell
                onClick={() => {window.open(url, '_blank')}}
                style={{ cursor: "pointer" }}
            >{invoice.Status}</TableCell>
            <TableCell>
                {invoice.HasAttachments
                    ? <FileOnIcon onClick={() => {window.open(url, '_blank')}} style={{ cursor: "pointer" }} />
                    : <FileOffIcon />}
            </TableCell>
        </TableRow>
    );
};
export default InvoiceRow;
