import { Button } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import { ReactComponent as DoxleLogo } from "../../../Assets/DrawerExpandDoxleLogo.svg";

import { ReactComponent as TextLogo } from "../../../Assets/DrawerExpandTextLogo.svg";
import { ReactComponent as MinimiseLogo } from "../../../Assets/MailroomMinimise.svg";
import { ReactComponent as CloseLogo } from "../../../Assets/MailroomCloseSideMenu.svg";
import { DRAWER_LABELS } from "../../../utilities/constants";
import DrawerItem from "./DrawerItem";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  setDrawerMode: Function;
  setOpenDrawer: Function;
  clickedIdentifier: number | undefined;
  setActiveSublist: Function;
};

const DrawerExpand = ({
  setDrawerMode,
  setOpenDrawer,
  clickedIdentifier,
  setActiveSublist,
}: Props) => {
  const listItems: string[] = Object.values(DRAWER_LABELS);

  return (
    <motion.div
      style={{
        backgroundColor: "white",
        width: "calc(100% - 30px)",
        height: "calc(100% - 16px)",
        padding: "8px 15px 8px 15px",
        display: "flex",
        flexDirection: "column",
      }}
      initial={{ x: -100 }}
      animate={{ x: 0 }}
      exit={{ x: -100 }}
      transition={{ duration: 0.4, bounce: 0.25 }}
    >
      {/*Top Section */}
      <TopDrawerExpand>
        {/*Doxle Logo */}
        <Box
          sx={{
            width: "45px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DoxleLogo />
        </Box>

        {/*Text Logo*/}
        <Box
          sx={{
            flex: 1,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextLogo />
          <Box
            sx={{
              fontFamily: "Nostromo Regular",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "6px",
              lineHeight: "8px",
              color: "#000000",
              marginTop: "4px",
            }}
          >
            VERSION 1.4
          </Box>
        </Box>

        {/*Toggle Buttons */}
        <Box
          sx={{
            width: "80px",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          }}
        >
          {/*Minimise Button */}
          <ViewControlButton onClick={() => setDrawerMode("minimised")}>
            <MinimiseLogo />
            Minimise
          </ViewControlButton>

          <ViewControlButton
            onClick={() => {
              setDrawerMode("minimised");
              setOpenDrawer(false);
            }}
          >
            <CloseLogo />
            Close
          </ViewControlButton>
        </Box>
      </TopDrawerExpand>

      {/*Middle Section */}
      <ContentDrawerExpand>
        {/*Sort the item list based on current selected item, the current selected item will stay on top of the list and will expand with sub list of the item */}
        {listItems.map((item, index) => (
          <DrawerItem
            key={index}
            expand={
              clickedIdentifier !== undefined &&
              item === listItems[clickedIdentifier]
                ? true
                : false
            }
            itemTitle={item}
            setActiveSublist={setActiveSublist}
            identifierValue={listItems.indexOf(item)}
          />
        ))}
      </ContentDrawerExpand>
    </motion.div>
  );
};

export default DrawerExpand;

const DrawerExpandContainer = styled(Box)({
  backgroundColor: "white",
  width: "calc(100% - 30px)",
  height: "calc(100% - 16px)",
  padding: "8px 15px 8px 15px",
  display: "flex",
  flexDirection: "column",
});

//########COMPONENT STYLE###########
const ViewControlButton = styled(Button)({
  minWidth: "30px !important",
  height: "30px",
  padding: "0 !important",
  margin: "0 !important",
  borderRadius: "25px !important",
  fontSize: "6px",
  lineHeight: "8px",
  backgroundColor: "transparent",
  fontFamily: "IBM Plex Sans",
  color: "#89819E",
  fontStyle: "normal",
  fontWeight: "400",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#eeeeee",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#f7f4ff",
  },
});

const TopDrawerExpand = styled(Box)({
  backgroundColor: "white",
  width: "100%",
  height: "60px",
  display: "flex",
  flexDirection: "row",
});

const ContentDrawerExpand = styled(Box)({
  backgroundColor: "white",
  width: "100%",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  overflow: "scroll",
  marginTop: "14px",
});
