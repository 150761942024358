import axios from "axios";
import { Permit } from '../../Models/permit';
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";


const getList = async (projectId: string|null, noticeId: string|null, costCodeId: string|null)  => {
    console.log("getList")
    let result: Permit[] = []
    if (offline){return result}
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        let params: any={}
        if (projectId) params.project = projectId
        else if (noticeId) params.notice = noticeId
        else if (costCodeId) params.cost_code = costCodeId
        else throw "must provide project or notice Id or cost code Id"
        console.log("params", params)
        const response = await axios.get(
            baseAddress+"/checklist_group/",
            {
                headers: { Authorization: "Bearer " + accessToken },
                params: params
            }
        )
        console.log("params", params)
        console.log("response", response)
        result = response.data.results as Array<Permit>;
        console.log("PERMIT", result)
    } catch (error) {
        console.error("ERROR FETCHING PERMIT", error)
    }
    return result
}

const getDetailed = async (checklistGroupId: string)  => {
    let result: Permit | undefined = undefined;
    if (offline){ return result}
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }

    try {
        const response = await axios.get(
            baseAddress+"/checklist_group/"+checklistGroupId+"/",
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.data as Permit;
        console.log("PERMIT DETAILED", result)
    } catch (error) {
        console.error("ERROR FETCHING PERMIT", error)
    }
    return result
}

const remove = async (checklistGroupId: string)  => {
    let result: boolean = false
    if (offline){return result}
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        const response = await axios.delete(
            baseAddress+"/checklist_group/"+checklistGroupId+'/',
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.status === 204;
    } catch (error) {
        console.error("ERROR DELETING PERMIT", error);
    }
    return result
}

const add = async (permit: Permit, userId: string) => {
    //if (offline){ return dummy.newDummyCostCode(notice); }
    // Add a new costcode
    let result: Permit | undefined = undefined
    if (offline){return result}
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    const payload = {...permit, user: userId}
    console.log("payload", payload)
    try {
        const response = await axios.post(
            baseAddress+"/checklist_group/",
            payload,
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        console.log("response", response)
        result = response.status === 201 ? response.data as Permit : undefined;
        console.log("ADDED PERMIT", result)
    } catch (error) {
        console.error("ERROR ADDING PERMIT", error)
    }
    return result
}


interface UpdateBody {
    title?: string;
    startDate?: string;
    endDate?: string;
    userId: string;
}

interface Payload {
    title?: string;
    body?: string;
    startDate?: string;
    endDate?: string;
    lastModifiedBy: string;
    lastModified: string;
}

const update = async (
    checklistGroupId: string,
    { title, startDate, endDate, userId}: UpdateBody
) => {
    // Update one or more property of a notice without affecting other values
    // Only pass in what is needed to be updated
    let result: Permit | undefined = undefined
    if (offline){return result}
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }

    try {
        let payload: Payload = {
            lastModifiedBy: userId,
            lastModified: new Date().toISOString()
        }
        if (title) {payload.title = title;}
        if (startDate) {payload.startDate = startDate;}
        if (endDate) {payload.endDate = endDate;}
        console.log(payload);
        const response = await axios.patch(
            baseAddress+"/checklist_group/"+checklistGroupId+"/",
            payload,
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.status === 200 ? response.data as Permit: undefined;
    } catch (error) {
        console.error("ERROR UPDATING PERMIT", error)
    }
    return result
}

const checklistGroupAPI = {
    getList,
    getDetailed,
    remove,
    update,
    add,
};

export default checklistGroupAPI