import React, { useState, useEffect, FormEvent } from "react";
import { ButtonProps, styled, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from "@mui/material";
import CheckBox from "../../../utilities/CheckBox/CheckBox";

interface props {
    open: boolean;
    closeAction: Function;
    saveAction: Function;
}


const UploadDialog: React.FC<props> = (
    {open, closeAction, saveAction}
) => {
    const [drawingSetTitle, setDrawingSetTitle] = useState<string>("");
    const [highResolution, setHighResolution] = useState<boolean>(false);
    const handleClose = () => {
        closeAction()
        setDrawingSetTitle("")
        setHighResolution(false)
    };

    const handleValueChange = (e: any) => {
        if (!e?.target?.value) { console.log("inputValue undefined"); return; }
        setDrawingSetTitle(e.target.value);
    };

    const handleSaveBtnClick = (e: any) =>{
        saveAction(drawingSetTitle, highResolution)
        setDrawingSetTitle("")
        setHighResolution(false)
    }


    const CancelButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText("#F8F8F8"),
        backgroundColor: "#F8F8F8",
        marginLeft:"66px",
        minWidth:"93px",
        borderRadius: "20px",
        textTransform: "none",
        fontStyle: "normal",
        fontWeight: "normal",
        fontFamily:"Inter",
        fontSize: "11px",
        '&:hover': {
            backgroundColor: "#F8F8F8",
        }
    }));


    const AddButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText("#0044CC"),
        backgroundColor: "#0044CC",
        marginLeft:"66px",
        minWidth:"93px",
        borderRadius: "20px",
        textTransform: "none",
        fontStyle: "normal",
        fontWeight: "normal",
        fontFamily:"Inter",
        fontSize: "11px",
        '&:hover': {
            backgroundColor: "#0044CC",
        },
    }));


    useEffect(() => {

    }, [])

    return(
        <React.Fragment>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Upload Drawings</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please provide a name for your drawings set
                    </DialogContentText>
                    <TextField
                        value={drawingSetTitle}
                        autoFocus
                        margin="dense"
                        id="dialogInput"
                        fullWidth
                        variant="standard"
                        onChange={(event: any) => {handleValueChange(event)}}
                        onKeyUp={(event:any) => {if (event.keyCode && event.keyCode === 13){handleSaveBtnClick(event) }}}
                    />

                    <DialogContentText>
                        Upload high resolution images? (This will take longer)
                    </DialogContentText>
                    <CheckBox
                        checked={highResolution}
                        onClick={(e:any) => setHighResolution(e?.target?.checked || false)}
                    />
                </DialogContent>
                <DialogActions>
                    <AddButton onClick={(event:any) => handleSaveBtnClick(event)}>Upload</AddButton>
                    <CancelButton onClick={handleClose}>Cancel</CancelButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default UploadDialog