export const useStyles = {
    tableHeadingStyle: {
        height: "15px",
        fontFamily:'Roboto',
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "7px",
        lineHeight: "8px",
        color: "#5A36BE",
        textAlign: "center",
        padding: "5px",
        paddingTop: "0px",
        paddingBottom: "0px",
        background: "#D6D9DF"
    },
    tableHeadingDropdownFieldStyle: {
        borderBottom: "none", 
        paddingBottom: "0px",
        height: "2px"
    },
    tableHeadingFilterStyle: {
        borderBottom: "none", 
        paddingBottom: "0px",
        paddingTop: "2px",
        height: "2px",
        paddingRight: "0px"
    },
    tableHeadingCostcodeFieldStyle: {
        height: "15px",
        fontStyle: "normal",
        fontFamily:"Roboto",
        fontWeight: "500",
        fontSize: "7px",
        lineHeight: "14px",
        color: "#5A36BE",
        padding: "5px",
        paddingTop: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        background: "#D6D9DF"
    },
    tableBodyStyle: {
        height: "33px", 
        fontStyle: "normal",
        fontFamily:"Roboto",
        fontWeight: 300,
        fontSize: "11px",
        lineHeight: "13px",
        padding: "0px",
        textAlign: "center",
        position: "relative",
        width: "88px",
    },
    tableBodyTitleFieldStyle: {
        minWidth: "150px",
        height: "33px", 
        fontStyle: "normal",
        fontFamily:"Roboto",
        fontWeight: 300,
        fontSize: "11px",
        lineHeight: "13px",
        padding: "0px",
        borderRight: "0.3px solid rgba(224, 224, 224, 1)",
        position: "relative",
        cursor: "pointer",
    },
    tableBodyCommentFieldStyle: {
        height: "33px", 
        fontStyle: "normal",
        fontFamily:"Roboto",
        fontWeight: 300,
        fontSize: "11px",
        lineHeight: "13px",
        color: "#0D0D0D",
        padding: "0px",
        paddingLeft: "25px",
        borderRight: "0.3px solid rgba(224, 224, 224, 1)",
        position: "relative",
        width: "68px"
    },
    tableBodyAssigneeFieldStyle: {
        height: "33px", 
        fontStyle: "normal",
        fontFamily:"Roboto",
        fontWeight: 300,
        fontSize: "11px",
        lineHeight: "13px",
        color: "#0D0D0D",
        padding: "0px",
        paddingLeft: "25px",
        borderRight: "0.3px solid rgba(224, 224, 224, 1)",
        position: "relative",
        width: "88px"
    },
    tableBodyBudgetFieldStyle: {
        height: "33px", 
        fontStyle: "normal",
        fontFamily:"Roboto",
        fontWeight: 300,
        fontSize: "11px",
        lineHeight: "13px",
        padding: "0px",
        paddingLeft: "25px",
        borderRight: "0.3px solid rgba(224, 224, 224, 1)",
        position: "relative",
        width: "88px"
    },
    tableBodyActualFieldStyle: {
        height: "33px", 
        fontStyle: "normal",
        fontFamily:"Roboto",
        fontWeight: 300,
        fontSize: "11px",
        lineHeight: "13px",
        color: "#0044CC",
        padding: "0px",
        paddingLeft: "25px",
        borderRight: "0.3px solid rgba(224, 224, 224, 1)",
        position: "relative",
        width: "88px"
    },
    tableBodyDateFieldStyle: {
        height: "33px", 
        fontStyle: "normal",
        fontFamily:"Roboto",
        fontWeight: 300,
        fontSize: "11px",
        lineHeight: "13px",
        padding: "0px",
        textAlign: "center",
        position: "relative",
        width: "88px"
    },
    tableBodyStatusFieldStyle: {
        height: "33px", 
        fontStyle: "normal",
        fontFamily:"Roboto",
        fontWeight: 300,
        fontSize: "11px",
        lineHeight: "13px",
        color: "#0D0D0D",
        padding: "0px",
        textAlign: "center",
        position: "relative",
        width: "88px"
    },
    tableFooterTotalStyle: {
        fontStyle: "normal",
        fontWeight: "bolder",
        fontFamily:"Roboto",
        fontSize: "13px",
        lineHeight: "15px",
        color: "#0D0D0D",
        padding: "5px",
        paddingLeft: "10px"
    },
    tableFooterTotalActualStyle: {
        fontStyle: "normal",
        fontWeight: "bolder",
        fontFamily:"Roboto",
        fontSize: "13px",
        lineHeight: "15px",
        color: "#0044CC",
        padding: "5px",
        paddingLeft: "10px"
    },
    tableFooterDateTotalStyle: {
        fontStyle: "normal",
        fontWeight: "bolder",
        fontFamily:"Roboto",
        fontSize: "13px",
        lineHeight: "15px",
        color: "#0D0D0D",
        padding: "5px",
        textAlign: "center"
    },
};