import {Divider, Grid, IconButton, InputAdornment, NativeSelect, TextField, Tooltip} from "@mui/material";
import {useRef, createRef, useState} from "react";
import UndoIcon from "@mui/icons-material/Undo";
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import ColourMenu from "./ColourMenu";
import React from "react";
import {DoxLine, Sheet} from "../../../Models/takeOffs";
import takeOffAPI from "../../../Services/DoxleAPI/takeOffAPI";
import drawingAPI from "../../../Services/DoxleAPI/drawingAPI";
import {takeOffContextInterface, useTakeOff} from "../../Providers/TakeOffProvider";
import {toFloat} from "./konvaFunctions/generalFunctions";
import {ReactComponent as RulerIcon} from '../../../Assets/RulerAlert.svg';
import CustomCheckbox from "../../../utilities/CheckBox/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import {SingleMeasureContextInterface, useSingleMeasurementContext} from "./SingleMeasurements";

interface props{
    updateSheets: Function;
    updateMultipleSheets: Function;
    single?: true;
}

const ScaleBar: React.FC<props> = ({
   updateSheets,
   updateMultipleSheets,
   single
}) => {
    const [scaleAll, setScaleAll] = useState<boolean>(false);
    const TakeOffContext = useTakeOff() as takeOffContextInterface;
    const SingleMeasureContext = useSingleMeasurementContext() as SingleMeasureContextInterface;
    const {
      currentSheet, currentDrawings,
      setScalingMode,
      scaleLine, setScaleLine,
      scaleLength, setScaleLength
    } = single ? SingleMeasureContext : TakeOffContext


    const handleUndo = (e:any) => {
        setScaleLine(null);
    }

    const handleCancel = (e:any) => {
        setScaleLine(null);
        setScalingMode(false);
    }

    const handleSave = async (e:any) => {
        if (!scaleLine) return;
        setScalingMode(false);
        const scale = scaleLength / toFloat(scaleLine?.value) / 1000;
        if (!scaleAll) await updateSheets(currentSheet?.sheetId, {scale: scale});
        else await updateMultipleSheets(currentDrawings?.setId, scale);
        setScaleLine(null);
    }

    return(
        <div className="measurement-tools">
            <Grid container>
                {
                    !scaleLine
                        ?
                        <Grid item xs={12}
                            style={{
                                textAlign: "center",
                                color: "red",
                                fontFamily: "Roboto"
                            }}
                        >
                            <RulerIcon style={{ position: "relative", top: "2px"}} /> &nbsp;
                            Draw a line of known length (Longer lines help the accuracy of the scale) &nbsp;
                            <RulerIcon style={{ position: "relative", top: "2px"}} />
                        </Grid>
                        :
                        <>
                            <Grid
                                item xs={3}
                                style={{
                                    marginTop: "5px",
                                    textAlign: "right",
                                    color: "red",
                                    paddingRight: "50px",
                                    fontFamily: "Roboto"
                                }}
                            >
                                Enter the line length
                            </Grid>
                            <Grid item xs={3} style={{alignItems: "center"}}>
                                <TextField
                                    type={"number"}
                                    value={scaleLength}
                                    variant={"standard"}
                                    onChange={(e:any) => {setScaleLength(parseFloat(e.target.value))}}
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}
                                style={{
                                    marginTop: "5px",
                                    textAlign: "center",
                                    color: "red",
                                    paddingRight: "50px",
                                    fontFamily: "Roboto"
                                }}
                            >
                                Scale All Sheets &nbsp;
                                <CustomCheckbox
                                    style={{marginTop: "-5px"}}
                                    checked={scaleAll}
                                    onClick={(e:any) => {setScaleAll(e.target.checked)}}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <IconButton
                                    style={{
                                        color: "#a584fd"
                                    }}
                                    onClick={handleUndo}
                                >
                                    <ReplayIcon/>
                                </IconButton>
                                <IconButton
                                    style={{
                                        color: "#a584fd"
                                    }}
                                    onClick={handleCancel}
                                >
                                    <CloseIcon/>
                                </IconButton>
                                <IconButton
                                    style={{
                                        color: "#a584fd"
                                    }}
                                    onClick={handleSave}
                                >
                                    <DoneIcon/>
                                </IconButton>
                            </Grid>
                        </>

                }
            </Grid>
        </div>
    )
}

export default ScaleBar