import React, { useState, useEffect } from "react";
import {
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import accountingService from "../../../Services/accountingService";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import { Project } from "../../../Models/project";
import { Costcode } from "../../../Models/costcode";
import { Stage } from "../../../Models/stage";
import { User } from "../../../Models/user";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import { formatScheduleDate } from "../../../utilities/constants";
import Loading from "../../../utilities/Lottie/Loading";
import CostCodesDetails from "../CostCodesDetails/CostCodesDetails";
import CostCodeRow from "./CostCodeRow";
import "../CostCodes/costCode.css";
import { useStyles } from "./costcodeStyle";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import userAPI from "../../../Services/DoxleAPI/userAPI";
import { AddressBookCompany } from "../../../Models/addressBook";
import addressBookAPI from "../../../Services/DoxleAPI/addressBookAPI";
import { ColumnView } from "../../../Models/columnView";
import { getCalculationColumn } from "./calculateColumn";
import { useNavigate, useParams } from "react-router-dom";
import stageAPI from "../../../Services/DoxleAPI/stageAPI";
import { toFloat } from "../Measurements/konvaFunctions/generalFunctions";
import StageHeader from "./StageHeader";
import { Profiler } from "react";
interface props {
  selectedProject: Project | null;
  costCodeFilter: string | null;
  editTitleMode: boolean;
  setEditTitleMode: Function;
  users: User[];
  contractors: AddressBookCompany[];
  userFilter: string[];
  columnView: ColumnView;
}

const CostCodeTable: React.FC<props> = ({
  selectedProject,
  costCodeFilter,
  editTitleMode,
  setEditTitleMode,
  users,
  contractors,
  userFilter,
  columnView,
}) => {
  const [costcodes, setCostCodes] = useState<Array<Costcode>>([]);
  const [stages, setStages] = useState<Array<any>>([]);
  const [selectedCostCode, setSelectedCostCode] = useState<
    Costcode | undefined
  >(undefined);
  const [tabIndex, setTabIndex] = useState<string>("7");
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [budgetTotal, setBudgetTotal] = React.useState<number>(0);
  const [orderTotal, setOrderTotal] = React.useState<number>(0);
  const [actualTotal, setActualTotal] = React.useState<number>(0);
  const [budgetIncomeTotal, setBudgetIncomeTotal] = React.useState<number>(0);
  const [actualIncomeTotal, setActualIncomeTotal] = React.useState<number>(0);
  const [orderIncomeTotal, setOrderIncomeTotal] = React.useState<number>(0);
  const [runningIncomeTotal, setRunningIncomeTotal] = React.useState<number>(0);
  const [runningTotal, setRunningTotal] = React.useState<number>(0);
  const [editTitleOption, setEditTitleOption] = React.useState<string | null>(
    null
  );
  const unmounted = useMountStatus();
  const [loading, setLoading] = useState<boolean>(false);
  const { height, width } = WindowDimensions();
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, user } = authContext;
  const projectStatus: string = selectedProject
    ? selectedProject.projectStatus
    : "";
  const costCodesIds: string[] = costcodes.map((cc) => cc.costCodeId);

  const navigate = useNavigate();
  const allParams = useParams();
  console.log('allParams', allParams)
  const [goToProjectId, goToCostcodeId, goToTab] =
  allParams["*"]?.split("/") || [];

  const formatter: Intl.NumberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const incrementSelectedCostCode = (inc: -1 | 1, checkOnly: boolean) => {
    if (!selectedCostCode?.costCodeId) return false;
    const costCodeIndex = costCodesIds.indexOf(selectedCostCode?.costCodeId);
    if (costCodeIndex + inc < 0 || costCodeIndex + inc >= costcodes.length)
      return false;
    if (!checkOnly) setSelectedCostCode(costcodes?.[costCodeIndex + inc]);
    return true;
  };

  const decideFooterDisplayMode = () => {
    if (costcodes.length === 0 || costCodeFilter === "clientMode") {
      return "none";
    } else {
      return "";
    }
  };

  interface updateTotalProps {
    budgetDelta?: number;
    orderDelta?: number;
    runningDelta?: number;
  }
  const updateTotals = ({
    budgetDelta,
    orderDelta,
    runningDelta,
  }: updateTotalProps) => {
    if (budgetDelta) {
      setBudgetTotal(budgetTotal + budgetDelta);
    }
    if (orderDelta) {
      setOrderTotal(orderTotal + orderDelta);
    }
    if (runningDelta) {
      setRunningTotal(runningTotal + runningDelta);
    }
  };

  const getEarliestDate = () => {
    const dates: number[] = [];

    if (costcodes.length > 0) {
      for (const item in costcodes) {
        if (costcodes[item] !== null) {
          dates.push(Number(new Date(costcodes[item].startDate)));
        }
      }
      var minDate = new Date(Math.min(...dates)).toISOString();
      return formatScheduleDate(minDate);
    }
  };

  const getLatestDate = () => {
    var dates: number[] = [];

    if (costcodes.length > 0) {
      for (const item in costcodes) {
        if (costcodes[item] !== null) {
          dates.push(Number(new Date(costcodes[item].endDate)));
        }
      }
      var maxDate = new Date(Math.max(...dates)).toISOString();
      return formatScheduleDate(maxDate);
    }
  };

  const handleRowClick = (costcode: Costcode, tab: string) => {
    // console.log("handle row click", tab)
    setSelectedCostCode(costcode);
    setTabIndex(tab);
    setOpenModal(true);
    if (costcode.costCodeId)
      sessionStorage.setItem("currentCostCodeId", costcode.costCodeId);
    if (costcode)
      sessionStorage.setItem("currentCostCode", JSON.stringify(costcode));
  };

  const handleClose = () => {
    setOpenModal(false);
    setSelectedCostCode(undefined);
    sessionStorage.removeItem("currentCostCode");
    sessionStorage.removeItem("currentCostCodeId");
    navigate("/Projects");
  };

  const calculateTotals = () => {
    let totalBudget: number = 0;
    let totalActual: number = 0;
    let totalOrders: number = 0;
    let totalRunning: number = 0;
    let totalIncomeBudget: number = 0;
    let totalIncomeOrders: number = 0;
    let totalIncomeActual: number = 0;
    let totalIncomeRunning: number = 0;
    if (costcodes.length > 0) {
      costcodes.forEach((costcode) => {
        if (!costcode) return;
        if (!costcode.income) {
          totalBudget += parseFloat(costcode.budget);
          totalActual += costcode.actual || 0;
          totalOrders += parseFloat(costcode.orders || "0");
          // if (costcode.completed && costcode.actual){
          //     totalRunning += costcode.actual
          if (costcode.completed) {
            totalRunning += costcode?.actual || 0;
          } else {
            totalRunning += Math.max(
              parseFloat(costcode.budget),
              parseFloat(costcode.orders || "0"),
              costcode.actual || 0
            );
          }
        } else {
          totalIncomeBudget += parseFloat(costcode.budget);
          totalIncomeActual += costcode.actual || 0;
          totalIncomeOrders += parseFloat(costcode.orders || "0");
          if (costcode.completed) {
            totalIncomeRunning += costcode?.actual || 0;
          } else {
            totalIncomeRunning += Math.max(
              parseFloat(costcode.budget),
              parseFloat(costcode.orders || "0"),
              costcode.actual || 0
            );
          }
        }
      });
    }
    setBudgetTotal(totalBudget);
    setOrderTotal(totalOrders);
    setActualTotal(totalActual);
    setRunningTotal(totalRunning);
    setBudgetIncomeTotal(totalIncomeBudget);
    setOrderIncomeTotal(totalIncomeOrders);
    setActualIncomeTotal(totalIncomeActual);
    setRunningIncomeTotal(totalIncomeRunning);
  };

  const fetchActuals = async (initResponse: Costcode[]) => {
    try {
      const updatedCostCodes = await accountingService.appendActuals(
        initResponse
      );
      setCostCodes([...updatedCostCodes]);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const updateOrders = (costCodeId: string, orders: string) => {
    let newCostCodes: Costcode[] = [];
    costcodes.forEach((costCode: Costcode) => {
      if (costCode.costCodeId === costCodeId) costCode.orders = orders;
      newCostCodes.push(costCode);
    });
    setCostCodes([...newCostCodes]);
  };

  const updateBudget = (costCodeId: string, newBudget: string) => {
    let newCostCodes: Costcode[] = [];
    costcodes.forEach((costCode: Costcode) => {
      if (costCode.costCodeId === costCodeId) costCode.budget = newBudget;
      newCostCodes.push(costCode);
    });
    setCostCodes([...newCostCodes]);
  };

  const isThisPeriod = (costCode: Costcode, period: number) => {
    const today = new Date().getTime();

    const start = new Date(costCode.startDate).getTime();
    const startsThisWeek =
      start - today > 0 && start - today < 1000 * 60 * 60 * 24 * period;

    const end = new Date(costCode.endDate).getTime();
    const endsThisWeek =
      end - today > 0 && end - today < 1000 * 60 * 60 * 24 * period;

    const current = start - today < 0 && end - today > 0;

    return startsThisWeek || endsThisWeek || current;
  };

  const fetchCostCodes = async () => {
    setLoading(true);
    if (selectedProject) {
      try {
        const response = (await CostCodeAPI.getList({
          projectId: selectedProject.projectId,
        })) as Costcode[];
        console.log("cc response", response);
        if (unmounted) return;
        console.log("mounted");
        setCostCodes([...response]);
        calculateTotals();
        fetchActuals([...response]);
        calculateTotals();
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    }
    setLoading(false);
  };

  const fetchStageDetails = async () => {
    if (selectedProject) {
      try {
        const response = (await stageAPI.getStageList(
          selectedProject.projectId
        )) as Stage[];
        if (unmounted) return;
        setStages([...response]);
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    }
  };

  const handleDeleteCostcode = async (costcode: Costcode) => {
    if (!costcode.costCodeId) return;
    const result = await CostCodeAPI.remove(costcode.costCodeId);
    let newCostCodes: Costcode[] = [];
    if (result)
      costcodes.forEach((cc) => {
        if (!(cc.costCodeId === costcode.costCodeId)) newCostCodes.push(cc);
      });
    setCostCodes([...newCostCodes]);
    setEditTitleMode(false);
    setEditTitleOption(null);
  };

  const filterbyUser = (costcode: Costcode) => {
    if (userFilter.length > 0) {
      let found: boolean = false;
      userFilter.forEach((selectedUser) => {
        if (
          user &&
          user.userId &&
          costcode.assignedUsers &&
          costcode.assignedUsers.findIndex(
            (au) => au.userId === selectedUser
          ) !== -1
        ) {
          found = true;
        }
      });
      return found;
    } else {
      return true;
    }
  };

  useEffect(() => {
    calculateTotals();
  }, [costcodes]);

  useEffect(() => {
    fetchCostCodes();
    fetchStageDetails();
  }, [selectedProject]);

  // useEffect(() => {
  //     console.log("TAB:", tabIndex)
  // },[tabIndex]);

  useEffect(() => {
    // check params from url
    console.log('goToCostcodeId', goToCostcodeId)
    console.log('goToTab', goToTab)
    console.log('costcodes', costcodes)
    if (goToCostcodeId && costcodes.length > 0) {
      const goToCostcode = costcodes.filter(
        (cc) => cc.costCodeId === goToCostcodeId
      )[0];
      if (goToTab) {
        handleRowClick(goToCostcode, "11"); // 12: comment tab
      } else {
        handleRowClick(goToCostcode, "7"); // 8: order tab
      }
    }
  }, [goToCostcodeId, goToTab, costcodes]);

  return (
    <>
      <CostCodesDetails
        selectedTabIndex={tabIndex}
        // setSelectedTabIndex={setTabIndex}
        selectedCostCode={selectedCostCode}
        openModal={openModal}
        closeModal={handleClose}
        selectedProjectAddress={selectedProject && selectedProject.siteAddress}
        updateBudget={updateBudget}
        updateOrders={updateOrders}
        height={height}
        width={width}
        users={users}
        contractors={contractors}
        project={selectedProject}
        incrementSelectedCostCode={incrementSelectedCostCode}
      />
      <div id="costcodeGroup">
        {loading ? (
          <>
            <div style={{ marginTop: "15%" }}>
              <Loading />
            </div>
          </>
        ) : (
          // <TableContainer sx={{ maxHeight: width < 928 ? (height-57)+"px" : "95.5vh" }}>
          // <TableContainer sx={{ height: "calc(100vh - 108px - 48px)" }}>
          <div style={{ position: "relative" }}>
            {stages.length > 0 && (
              <StageHeader project={selectedProject} stages={stages} />
            )}
            <div
              id="sticker"
              style={{
                background: "#D5D7E3",
                width: "10px",
                height: "16px",
                position: "absolute",
                zIndex: 900,
                right: 0,
              }}
            />
            {/* COSTCODE TABLE*/}
            <TableContainer sx={{ height: "calc(100vh - 185px)" }}>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    className="tableHeadStyle"
                    sx={{ background: "#D5D7E3" }}
                  >
                    <TableCell
                      style={{ position: "sticky", left: 0, zIndex: 99 }}
                      sx={{ ...useStyles.tableHeadingStyle, maxWidth: 44 }}
                    />
                    <TableCell
                      style={{
                        position: "sticky",
                        left: 44,
                        zIndex: 99,
                        textAlign: "left",
                      }}
                      sx={useStyles.tableHeadingStyle}
                    >
                      ACCOUNTS
                    </TableCell>
                    <TableCell sx={useStyles.tableHeadingStyle} />
                    {columnView?.notesIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableHeadingStyle,
                        }}
                      />
                    )}
                    {columnView?.photoIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableHeadingStyle,
                        }}
                      />
                    )}
                    {columnView?.fileIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableHeadingStyle,
                        }}
                      />
                    )}
                    {columnView?.checklistIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableHeadingStyle,
                        }}
                      />
                    )}
                    {columnView?.signedBills && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableHeadingStyle,
                        }}
                      />
                    )}
                    {columnView?.commentIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableHeadingStyle,
                        }}
                      />
                    )}
                    {columnView?.assignee && (
                      <TableCell sx={useStyles.tableHeadingStyle}>
                        ASSIGNEE
                      </TableCell>
                    )}
                    {columnView?.contractor && (
                      <TableCell sx={useStyles.tableHeadingStyle}>
                        CONTRACTOR
                      </TableCell>
                    )}
                    {columnView?.budget && (
                      <TableCell sx={useStyles.tableHeadingStyle}>
                        BUDGET
                      </TableCell>
                    )}
                    {columnView?.order /* BILLED is ACTUAL */ && (
                      <TableCell sx={useStyles.tableHeadingStyle}>
                        ORDER
                      </TableCell>
                    )}
                    {columnView?.billed && (
                      <TableCell sx={useStyles.tableHeadingStyle}>
                        BILLED
                      </TableCell>
                    )}
                    {columnView?.running && (
                      <TableCell sx={useStyles.tableHeadingStyle}>
                        RUNNING
                      </TableCell>
                    )}
                    {/*{ columnView?.difference &&*/}
                    {/*    <TableCell sx={useStyles.tableHeadingStyle}>{getCalculationColumn().title}</TableCell>}*/}
                    {false && (
                      <TableCell sx={useStyles.tableHeadingStyle}>
                        {getCalculationColumn().title}
                      </TableCell>
                    )}
                    {columnView?.startDate && (
                      <TableCell sx={useStyles.tableHeadingStyle}>
                        START
                      </TableCell>
                    )}
                    {columnView?.endDate && (
                      <TableCell sx={useStyles.tableHeadingStyle}>
                        FINISH
                      </TableCell>
                    )}
                    {columnView?.days && (
                      <TableCell sx={useStyles.tableHeadingStyle}>
                        DAYS
                      </TableCell>
                    )}

                    {/*<TableCell sx={useStyles.tableHeadingStyle}>STATUS</TableCell>*/}
                  </TableRow>
                </TableHead>
                <TableBody className="tableBodyStyle">
                  {costcodes.map((costcode) =>
                    (!costCodeFilter ||
                      (costCodeFilter === "overBudget" &&
                        ((costcode.orders &&
                          parseFloat(costcode.orders) >
                            parseFloat(costcode.budget)) ||
                          (costcode.actual &&
                            costcode.actual > parseFloat(costcode.budget)))) ||
                      (costCodeFilter === "assigned" &&
                        user &&
                        costcode.assignedUsers.findIndex(
                          (x) => x.userId === user.userId
                        ) !== -1) ||
                      (costCodeFilter === "week" &&
                        isThisPeriod(costcode, 7)) ||
                      (costCodeFilter === "2week" &&
                        isThisPeriod(costcode, 14)) ||
                      (costCodeFilter === "month" &&
                        isThisPeriod(costcode, 30)) ||
                      costCodeFilter === costcode.status ||
                      (costCodeFilter === "checked" && costcode.completed) ||
                      (costCodeFilter === "notChecked" &&
                        !costcode.completed) ||
                      (costCodeFilter === "clientMode" &&
                        !costcode.title.includes(".98 ") &&
                        !costcode.title.includes(".99 "))) &&
                    filterbyUser(costcode) ? (
                      <CostCodeRow
                        key={costcode.costCodeId}
                        initCostCode={costcode}
                        onCostCodeClicked={handleRowClick}
                        updateTotals={updateTotals}
                        users={users}
                        editTitleMode={editTitleMode}
                        setEditTitleMode={setEditTitleMode}
                        editTitleOption={editTitleOption}
                        setEditTitleOption={setEditTitleOption}
                        handleDeleteCostcode={handleDeleteCostcode}
                        contractors={contractors}
                        projectStatus={projectStatus}
                        openStage={true}
                        columnView={columnView}
                      />
                    ) : null
                  )}
                </TableBody>
                <TableFooter style={{ display: decideFooterDisplayMode() }}>
                  <TableRow
                    className="tableFootStyle"
                    sx={{
                      "& .MuiTableCell-footer": {
                        paddingTop: "10px",
                        borderTop: "1px solid rgb(224, 224, 224)",
                        borderBottom: "none",
                      },
                    }}
                  >
                    <TableCell
                      style={{ position: "sticky", left: 0, zIndex: 99 }}
                    />
                    <TableCell
                      style={{ position: "sticky", left: 44, zIndex: 99 }}
                      sx={{ ...useStyles.tableFooterTotalStyle }}
                    >
                      Total Expense
                    </TableCell>
                    <TableCell />
                    {columnView?.notesIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.photoIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.fileIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.checklistIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.signedBills && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.commentIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.assignee && <TableCell />}
                    {columnView?.contractor && <TableCell />}
                    {columnView?.budget && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterTotalStyle,
                          padding: "0px 5px 0px 25px",
                          borderRight: "1px solid rgb(224, 224, 224)",
                          borderLeft: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {formatter.format(budgetTotal)}
                      </TableCell>
                    )}
                    {columnView?.order && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterTotalStyle,
                          padding: "0px 5px 0px 25px",
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {formatter.format(orderTotal)}
                      </TableCell>
                    )}
                    {columnView?.billed && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterTotalActualStyle,
                          padding: "0px 5px 0px 25px",
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {formatter.format(actualTotal)}
                      </TableCell>
                    )}
                    {columnView?.running && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterTotalStyle,
                          padding: "0px 5px 0px 25px",
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {formatter.format(runningTotal)}
                      </TableCell>
                    )}
                    {/*{ columnView?.difference && <TableCell/>}*/}
                    {false && <TableCell />}
                    {columnView?.startDate && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterDateTotalStyle,
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {getEarliestDate()}
                      </TableCell>
                    )}
                    {columnView?.endDate && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterDateTotalStyle,
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {getLatestDate()}
                      </TableCell>
                    )}
                    {columnView?.days && <TableCell />}
                  </TableRow>
                  <TableRow
                    className="tableFootStyle"
                    sx={{
                      "& .MuiTableCell-footer": {
                        borderTop: "1px solid rgb(224, 224, 224)",
                        borderBottom: "none",
                      },
                    }}
                  >
                    <TableCell
                      style={{ position: "sticky", left: 0, zIndex: 99 }}
                    />
                    <TableCell
                      style={{ position: "sticky", left: 44, zIndex: 99 }}
                      sx={useStyles.tableFooterTotalStyle}
                    >
                      Total Income
                    </TableCell>
                    <TableCell />
                    {columnView?.notesIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.photoIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.fileIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.checklistIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.signedBills && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.commentIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.assignee && <TableCell />}
                    {columnView?.contractor && <TableCell />}
                    {columnView?.budget && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterTotalStyle,
                          padding: "0px 5px 0px 25px",
                          borderRight: "1px solid rgb(224, 224, 224)",
                          borderLeft: "1px solid rgb(224, 224, 224)",
                          marginLeft: "-1px",
                        }}
                      >
                        {formatter.format(budgetIncomeTotal)}
                      </TableCell>
                    )}
                    {columnView?.order && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterTotalStyle,
                          padding: "0px 5px 0px 25px",
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {formatter.format(orderIncomeTotal)}
                      </TableCell>
                    )}
                    {columnView?.billed && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterTotalActualStyle,
                          padding: "0px 5px 0px 25px",
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {formatter.format(actualIncomeTotal)}
                      </TableCell>
                    )}
                    {columnView?.running && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterTotalStyle,
                          padding: "0px 5px 0px 25px",
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {formatter.format(runningIncomeTotal)}
                      </TableCell>
                    )}
                    {/*{ columnView?.difference && <TableCell/>}*/}
                    {false && <TableCell />}
                    {columnView?.startDate && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterDateTotalStyle,
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {getEarliestDate()}
                      </TableCell>
                    )}
                    {columnView?.endDate && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterDateTotalStyle,
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {getLatestDate()}
                      </TableCell>
                    )}
                    {columnView?.days && <TableCell />}
                  </TableRow>
                  <TableRow
                    className="tableFootStyle"
                    sx={{
                      "& .MuiTableCell-footer": {
                        borderTop: "1px solid rgb(224, 224, 224)",
                      },
                    }}
                  >
                    <TableCell
                      style={{ position: "sticky", left: 0, zIndex: 99 }}
                    />
                    <TableCell
                      style={{ position: "sticky", left: 44, zIndex: 99 }}
                      sx={useStyles.tableFooterTotalStyle}
                    >
                      Profit Margin
                    </TableCell>
                    <TableCell />
                    {columnView?.notesIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.photoIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.fileIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.checklistIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.signedBills && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.commentIcon && (
                      <TableCell
                        sx={{
                          maxWidth: "40px",
                          ...useStyles.tableFooterTotalStyle,
                        }}
                      />
                    )}
                    {columnView?.assignee && <TableCell />}
                    {columnView?.contractor && <TableCell />}
                    {columnView?.budget && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterTotalStyle,
                          padding: "0px 5px 0px 25px",
                          borderRight: "1px solid rgb(224, 224, 224)",
                          borderLeft: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {budgetIncomeTotal === 0 || budgetTotal === 0
                          ? ""
                          : Math.round(
                              ((budgetIncomeTotal - budgetTotal) /
                                budgetIncomeTotal) *
                                1000
                            ) /
                              10 +
                            " %"}
                      </TableCell>
                    )}
                    {columnView?.order && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterTotalStyle,
                          padding: "0px 5px 0px 25px",
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {orderIncomeTotal === 0 || orderTotal === 0
                          ? ""
                          : Math.round(
                              ((orderIncomeTotal - orderTotal) /
                                orderIncomeTotal) *
                                1000
                            ) /
                              10 +
                            " %"}
                      </TableCell>
                    )}
                    {columnView?.billed && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterTotalActualStyle,
                          padding: "0px 5px 0px 25px",
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {/*{*/}
                        {/*    actualIncomeTotal === 0 || actualTotal === 0 ? "" : Math.round((actualIncomeTotal - actualTotal) / actualIncomeTotal * 1000) / 10 + " %"*/}
                        {/*}*/}
                      </TableCell>
                    )}
                    {columnView?.running && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterTotalStyle,
                          padding: "0px 5px 0px 25px",
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {runningIncomeTotal === 0 || runningTotal === 0
                          ? ""
                          : Math.round(
                              ((runningIncomeTotal - runningTotal) /
                                runningIncomeTotal) *
                                1000
                            ) /
                              10 +
                            " %"}
                      </TableCell>
                    )}
                    {/*{ columnView?.difference && <TableCell/>}*/}
                    {false && <TableCell />}
                    {columnView?.startDate && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterDateTotalStyle,
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {getEarliestDate()}
                      </TableCell>
                    )}
                    {columnView?.endDate && (
                      <TableCell
                        sx={{
                          ...useStyles.tableFooterDateTotalStyle,
                          borderRight: "1px solid rgb(224, 224, 224)",
                        }}
                      >
                        {getLatestDate()}
                      </TableCell>
                    )}
                    {columnView?.days && <TableCell />}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </>
  );
};

export default CostCodeTable;
