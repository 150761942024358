import React from "react";
import { Costcode } from "../../../Models/costcode";
import { Stage } from "../../../Models/stage";
import { User } from "../../../Models/user";
import { AddressBookCompany } from "../../../Models/addressBook";
import { TableRow, TableCell, IconButton } from "@mui/material";
import CostCodeRow from "./CostCodeRow";
import { ReactComponent as ArrowIcon } from "../../../Assets/Chevron.svg";
import { useStyles } from "./costcodeStyle";
import { formatScheduleDate } from "../../../utilities/constants";
import Status from "../Status/Status";
import { ColumnView } from "../../../Models/columnView";

interface props {
  index: number;
  stage: Stage;
  openStage: string;
  setOpenStage: Function;
  user?: User;
  users: User[];
  contractors: AddressBookCompany[];
  editTitleMode: boolean;
  setEditTitleMode: Function;
  editTitleOption: string | null;
  setEditTitleOption: Function;
  handleDeleteCostcode: Function;
  updateTotals: Function;
  projectStatus: string;
  costCodeFilter: string | null;
  handleRowClick: Function;
  userFilter: string[];
  columnView: ColumnView;
  fetchStages: Function;
}

const CostCodeStage: React.FC<props> = ({
  index,
  stage,
  openStage,
  setOpenStage,
  user,
  users,
  contractors,
  setEditTitleMode,
  editTitleMode,
  editTitleOption,
  setEditTitleOption,
  handleDeleteCostcode,
  updateTotals,
  projectStatus,
  costCodeFilter,
  handleRowClick,
  userFilter,
  columnView,
  fetchStages,
}) => {
  const stageIsOpen: boolean = openStage === stage.title;
  const isThisPeriod = (costCode: Costcode, period: number) => {
    const today = new Date().getTime();

    const start = new Date(costCode.startDate).getTime();
    const startsThisWeek =
      start - today > 0 && start - today < 1000 * 60 * 60 * 24 * period;

    const end = new Date(costCode.endDate).getTime();
    const endsThisWeek =
      end - today > 0 && end - today < 1000 * 60 * 60 * 24 * period;

    const current = start - today < 0 && end - today > 0;

    return startsThisWeek || endsThisWeek || current;
  };

  const formatter: Intl.NumberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const calculateDays = () => {
    const end = new Date(stage.endDate);
    const start = new Date(stage.startDate);
    const days = (end.getTime() - start.getTime()) / 1000 / 3600 / 24 + 1;
    return days;
  };

  const filterbyUser = (costcode: Costcode) => {
    if (userFilter.length > 0) {
      let found: boolean = false;
      userFilter.forEach((selectedUser) => {
        if (
          user &&
          user.userId &&
          costcode.assignedUsers &&
          costcode.assignedUsers.findIndex(
            (au) => au.userId === selectedUser
          ) !== -1
        ) {
          found = true;
        }
      });
      return found;
    } else {
      return true;
    }
  };

  return (
    <>
      <TableRow
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (openStage === stage.title) setOpenStage("");
          else setOpenStage(stage.title);
        }}
      >
        <TableCell sx={useStyles.stageHeadingStyle}>
          <Status type={"stage"} status={stage.status} />
        </TableCell>
        <TableCell
          colSpan={2}
          sx={useStyles.stageHeadingStyle}
          style={{
            position: "sticky",
            left: 0,
            zIndex: 49,
            textAlign: "left",
            paddingLeft: 0,
          }}
        >
          {`${index + 1}.0 ${stage.title}`}
          <IconButton
            aria-label="expand row"
            size="small"
            disableRipple
            sx={{
              color: "#5A36BE",
              "&:hover": { background: "none" },
              marginTop: "-9px",
              marginLeft: "9px",
              marginBottom: "-1px",
            }}
          >
            {openStage === stage.title ? (
              <ArrowIcon style={{ transform: "rotate(180deg)" }} />
            ) : (
              <ArrowIcon style={{}} />
            )}
          </IconButton>
        </TableCell>
        {columnView?.notesIcon && (
          <TableCell
            sx={{ maxWidth: "40px", ...useStyles.stageHeadingStyle }}
          />
        )}
        {columnView?.photoIcon && (
          <TableCell
            sx={{ maxWidth: "40px", ...useStyles.stageHeadingStyle }}
          />
        )}
        {columnView?.fileIcon && (
          <TableCell
            sx={{ maxWidth: "40px", ...useStyles.stageHeadingStyle }}
          />
        )}
        {columnView?.checklistIcon && (
          <TableCell
            sx={{ maxWidth: "40px", ...useStyles.stageHeadingStyle }}
          />
        )}
        {columnView?.signedBills && (
          <TableCell
            sx={{ maxWidth: "40px", ...useStyles.stageHeadingStyle }}
          />
        )}
        {columnView?.commentIcon && (
          <TableCell
            sx={{ maxWidth: "40px", ...useStyles.stageHeadingStyle }}
          />
        )}
        {columnView?.assignee && <TableCell sx={useStyles.stageHeadingStyle} />}
        {columnView?.contractor && (
          <TableCell sx={useStyles.stageHeadingStyle} />
        )}
        {columnView?.budget && (
          <TableCell sx={useStyles.stageHeadingStyle}>
            {stage.budgetTotal && costCodeFilter !== "clientMode"
              ? formatter.format(stage.budgetTotal)
              : ""}
          </TableCell>
        )}
        {columnView?.order && (
          <TableCell sx={useStyles.stageHeadingStyle}>
            {stage.orderTotal && costCodeFilter !== "clientMode"
              ? formatter.format(stage.orderTotal)
              : ""}
          </TableCell>
        )}
        {columnView?.billed && (
          <TableCell sx={useStyles.stageHeadingStyle}>
            {stage.actual && costCodeFilter !== "clientMode"
              ? formatter.format(stage.actual)
              : ""}
          </TableCell>
        )}
        {columnView?.running && (
          <TableCell sx={useStyles.stageHeadingStyle}>
            {stage.running && costCodeFilter !== "clientMode"
              ? formatter.format(stage.running)
              : ""}
          </TableCell>
        )}
        {/*{columnView?.difference && (*/}
        {/*  <TableCell sx={useStyles.stageHeadingStyle}>*/}
        {/*    {stage.orderTotal && stage.actual && costCodeFilter !== "clientMode"*/}
        {/*      ? formatter.format(stage.actual - stage.orderTotal)*/}
        {/*      : ""}*/}
        {/*  </TableCell>*/}
        {/*)}*/}
        {columnView?.startDate && (
          <TableCell sx={useStyles.stageHeadingStyle}>
            {formatScheduleDate(new Date(stage.startDate).toISOString())}
          </TableCell>
        )}
        {columnView?.endDate && (
          <TableCell sx={useStyles.stageHeadingStyle}>
            {formatScheduleDate(new Date(stage.endDate).toISOString())}
          </TableCell>
        )}
        {columnView?.days && (
          <TableCell sx={useStyles.stageHeadingStyle}>
            {calculateDays()}
          </TableCell>
        )}
        {/* <TableCell sx={useStyles.stageHeadingStyle}>
                    <Status
                        type={"stage"}
                        status={stage.status}
                    />
                </TableCell> */}
      </TableRow>

      {(stage?.costCodes || []).map((costcode) =>
        (!costCodeFilter ||
          (costCodeFilter === "overBudget" &&
            ((costcode.orders &&
              parseFloat(costcode.orders) > parseFloat(costcode.budget)) ||
              (costcode.actual &&
                costcode.actual > parseFloat(costcode.budget)))) ||
          (costCodeFilter === "assigned" &&
            user &&
            costcode.assignedUsers.findIndex(
              (x) => x.userId === user.userId
            ) !== -1) ||
          (costCodeFilter === "today" && isThisPeriod(costcode, 1)) ||
          (costCodeFilter === "week" && isThisPeriod(costcode, 7)) ||
          (costCodeFilter === "2week" && isThisPeriod(costcode, 14)) ||
          (costCodeFilter === "month" && isThisPeriod(costcode, 30)) ||
          costCodeFilter === costcode.status ||
          (costCodeFilter === "checked" && costcode.completed) ||
          (costCodeFilter === "notChecked" && !costcode.completed) ||
          (costCodeFilter === "clientMode" &&
            !costcode.title.includes(".98 ") &&
            !costcode.title.includes(".99 "))) &&
        filterbyUser(costcode) ? (
          <CostCodeRow
            key={costcode.costCodeId}
            initCostCode={costcode}
            onCostCodeClicked={handleRowClick}
            updateTotals={updateTotals}
            users={users}
            editTitleMode={editTitleMode}
            setEditTitleMode={setEditTitleMode}
            editTitleOption={editTitleOption}
            setEditTitleOption={setEditTitleOption}
            handleDeleteCostcode={handleDeleteCostcode}
            contractors={contractors}
            projectStatus={projectStatus}
            openStage={stageIsOpen}
            columnView={columnView}
            fetchStages={fetchStages}
          />
        ) : null
      )}
    </>
  );
};

export default CostCodeStage;
