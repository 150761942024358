export const useStyles = {
  tableHeadingStyle: {
    height: "15px",
    fontFamily: "Roboto Mono",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "8px",
    lineHeight: "11px",
    color: "#754EE0",
    textAlign: "center",
    padding: "5px",
    paddingTop: "0px",
    paddingBottom: "0px",
    background: "#D5D7E3",
  },
  tableHeadingDropdownFieldStyle: {
    borderBottom: "none",
    paddingBottom: "0px",
    height: "2px",
  },
  tableHeadingFilterStyle: {
    borderBottom: "none",
    paddingBottom: "0px",
    paddingTop: "2px",
    height: "2px",
    paddingRight: "0px",
  },
  tableBodyStyle: {
    height: "33px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "11px",
    lineHeight: "13px",
    padding: "0px",
    textAlign: "center",
    position: "relative",
    width: "88px",
  },
  tableBudgetStyle: {
    height: "33px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "11px",
    lineHeight: "13px",
    padding: "0px",
    textAlign: "left",
    position: "relative",
    width: "88px",
    paddingLeft: "25px",
    paddingRight: "5px",
  },
  tableBodyTitleFieldStyle: {
    minWidth: "150px",

    height: "33px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "11px",
    lineHeight: "13px",
    padding: "0px",
    // borderRight: "0.3px solid rgba(224, 224, 224, 1)",
    position: "relative",
    cursor: "pointer",
  },
  tableBodyCommentFieldStyle: {
    height: "33px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "11px",
    lineHeight: "13px",
    color: "#0D0D0D",
    padding: "0px",
    paddingLeft: "25px",
    borderRight: "0.3px solid rgba(224, 224, 224, 1)",
    position: "relative",
    width: "68px",
  },
  tableBodyAssigneeFieldStyle: {
    height: "33px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "11px",
    lineHeight: "13px",
    color: "#0D0D0D",
    padding: "0px",
    paddingLeft: "25px",
    borderRight: "0.3px solid rgba(224, 224, 224, 1)",
    position: "relative",
    width: "88px",
  },
  tableBodyBudgetFieldStyle: {
    height: "33px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "11px",
    lineHeight: "13px",
    padding: "0px",
    paddingLeft: "25px",
    borderRight: "0.3px solid rgba(224, 224, 224, 1)",
    position: "relative",
    width: "88px",
  },
  tableBodyActualFieldStyle: {
    height: "33px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "11px",
    lineHeight: "13px",
    color: "#0044CC",
    padding: "0px",
    paddingLeft: "25px",
    paddingRight: "5px",
    borderRight: "0.3px solid rgba(224, 224, 224, 1)",
    position: "relative",
    width: "88px",
  },
  tableBodyDateFieldStyle: {
    height: "33px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "11px",
    lineHeight: "13px",
    padding: "0px",
    textAlign: "center",
    position: "relative",
    width: "88px",
  },
  tableBodyStatusFieldStyle: {
    height: "33px",
    fontStyle: "normal",
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "11px",
    lineHeight: "13px",
    color: "#0D0D0D",
    padding: "0px",
    textAlign: "center",
    position: "relative",
    maxWidth: "48px !important",
  },
  tableFooterTotalStyle: {
    fontStyle: "normal",
    fontWeight: 400,
    fontFamily: "Roboto Mono",
    letterSpacing: "-0.7px",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#0D0D0D",
    padding: "5px",
    paddingLeft: "25px",
  },
  tableFooterTotalActualStyle: {
    fontStyle: "normal",
    fontWeight: 400,
    fontFamily: "Roboto Mono",
    letterSpacing: "-0.7px",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#0044CC",
    padding: "5px",
    paddingLeft: "25px",
    borderRight: "0.3px solid rgb(224, 224, 224)",
    borderBottom: "0.3px solid rgb(224, 224, 224)",
  },
  tableFooterDateTotalStyle: {
    fontStyle: "normal",
    fontWeight: 400,
    fontFamily: "Roboto Mono",
    letterSpacing: "-0.7px",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#0D0D0D",
    padding: "5px",
    textAlign: "center",
  },
  stageHeadingStyle: {
    height: "33px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "13px",
    color: "#000000",
    textAlign: "center",
    padding: "5px",
    paddingTop: "0px",
    paddingBottom: "0px",
    background: "white",
  },
};
