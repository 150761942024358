import { Box, Checkbox, Modal, styled } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { MailSubContent } from '../MailroomMainView'
import { HTMLMotionProps, motion } from 'framer-motion'

type Props = {
  content: MailSubContent
  setCurrentSubContent: Function
  currentSubContent: MailSubContent | undefined
  setCurrentSubContentPosition: Function
}

const MailViewSubContent = ({
  content,
  setCurrentSubContent,
  currentSubContent,
  setCurrentSubContentPosition,
}: Props) => {
  const [isFocused, setIsFocused] = useState<boolean>(false)

  //==========Get the position of the subcontent=========
  const subContentRef = useRef<HTMLDivElement>(null)
  const [positionX, setPositionX] = useState<number | undefined>(undefined)
  const [positionY, setPositionY] = useState<number | undefined>(undefined)
  const [subContentWidth, setSubContentWidth] = useState<number | undefined>(
    undefined
  )
  const [subContentHeight, setSubContentHeight] = useState<number | undefined>(
    undefined
  )
  // This function calculate X and Y
  const getPosition = () => {
    const x = subContentRef.current?.offsetLeft
    const y = subContentRef.current?.offsetTop
    setPositionX(x)
    setPositionY(y)
    const width = subContentRef.current?.offsetWidth
    const height = subContentRef.current?.offsetHeight
    setSubContentWidth(width)
    setSubContentHeight(height)
  }

  // Get the position of the subcontent in the beginning
  useEffect(() => {
    getPosition()
  }, [])

  // Re-calculate X and Y of the subcontent when the window gets resized by the user
  useEffect(() => {
    window.addEventListener('resize', getPosition)
  }, [])

  //==========================================
  return (
    <motion.div
      animate={{ scale: [1, 1.1, 1.1, 1] }}
      transition={{ duration: 1 }}
      className='mailViewSubContentRootContainer'
      onClick={() => {
        if (
          currentSubContent &&
          currentSubContent.contentId === content.contentId
        ) {
          setCurrentSubContent(undefined)
          setIsFocused(false)
          setCurrentSubContentPosition(undefined)
        } else {
          setCurrentSubContent(content)
          setIsFocused(true)

          if (
            typeof positionX === 'number' &&
            typeof positionY === 'number' &&
            typeof subContentWidth === 'number' &&
            typeof subContentHeight === 'number'
          ) {
            setCurrentSubContentPosition({
              x: positionX,
              y: positionY,
              width: subContentWidth,
              height: subContentHeight,
            })
          }
        }
      }}
      style={{ zIndex: isFocused ? 5 : 0 }}
      ref={subContentRef}
    >
      {/*Content Title Section */}
      <Box className='mailViewSubTitleContainer'>
        <Box sx={{ height: '100%', width: '15px' }}>
          <SubContentCheckBox
            value={content.completed}
            sx={{
              '&.Mui-checked': {
                color: '#5A36BE',
              },
              '&.css-8je8zh-MuiTouchRipple-root': {
                display: 'none',
                content: 'none',
              },
              '.css-1vkxi9d-MuiButtonBase-root-MuiCheckbox-root': {
                display: 'none',
                content: 'none',
              },
              '.css-i4bv87-MuiSvgIcon-root': {
                padding: '0 !important',
                width: '8px',
                height: '8px',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            height: '100%',
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '4px',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {/*Title */}
          <Box className='subContentTitleContainerStyle'>
            <Box sx={{ borderBottom: '0.5px solid #D2DAEA' }}>
              {content.contentTitle}
            </Box>
          </Box>

          {/*Contractor */}
          <Box className='subContentContractorTitleStyle'>
            {content.contractor}
          </Box>

          {/*User */}
          <Box className='subContentUserTitleStyle'>
            <UserDisplayContainer>{content.user}</UserDisplayContainer>
          </Box>

          {/*Sent Date */}
          <Box className='subContentSentDateTitleStyle'>{content.sentDate}</Box>
        </Box>
      </Box>

      {/*Content Description Section */}
      <Box className='mailViewSubDescriptionContainer'>
        {content.contentDescription}
      </Box>
    </motion.div>
  )
}

export default MailViewSubContent

const SubContentCheckBox = styled(Checkbox)({
  width: '8px !important',
  height: '8px !important',
  border: '1px solid #5A36BE',
  borderRadius: '2.5px',
  padding: '0 !important',
  backgroundColor: 'transparent',
})
const styles = () => ({
  root: {
    '&$checked': {
      color: '#5A36BE',
    },
  },
  checked: {},
})
const UserDisplayContainer = styled(Box)({
  backgroundColor: '#C0ACFA',
  borderRadius: '5px',
  fontFamily: 'Roboto Mono',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '8px',
  lineHeight: '11px',
  color: '#FFFFFF',

  opacity: 0.8,
  padding: '3px 6px 3px 6px',
})
