import React from "react";
import "./Gallery.css";
import ImageGallery from "react-image-gallery";
import { FileData } from "../../../Models/storage";

const imagesx = [
    {
        original: "https://picsum.photos/id/1018/1000/600/",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
        original: "https://picsum.photos/id/1015/1000/600/",
        thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
        original: "https://picsum.photos/id/1019/1000/600/",
        thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
];

interface props {
    files: FileData[];
}

const Gallery: React.FC<props> = ({ files }) => {
    const images = files.map((file: FileData) => ({
        original: file.url,
        thumbnail: file.url,
    }));
    return <ImageGallery items={images} />;
};

export default Gallery;
