import {Notice, NoticeCategory} from "../../../Models/notice"
import React, { useState } from "react"
import { Input, TableCell, CircularProgress } from "@mui/material"
import { Company } from "../../../Models/company"
import NoticeAPI from "../../../Services/DoxleAPI/noticeAPI"
import { useStyles } from "./noticesStyle"
import EditNotice from "./EditNotice"
import CustomDialog from "../../../utilities/Dialog/CustomDialog"
import addIcon from "../../../Assets/Plus - Square.svg"
import { authContextInterface, useAuth } from "../../Providers/AuthProvider"
import { ColumnView } from "../../../Models/columnView"
interface props {
  action: string
  company: Company | undefined
  addNewNotice: Function
  notices: Notice[]
  setNotices: React.Dispatch<React.SetStateAction<Notice[]>>;
  categoryList: NoticeCategory[]
  noticeCategoryFilter: NoticeCategory | null
  noticePeriodFilter: string | null
  tabValue: string | null
  columnView: ColumnView
  userFilter: string[]
}

const AddNotice: React.FC<props> = ({
  action,
  company,
  addNewNotice,
  categoryList,
  notices,
  setNotices,
  noticeCategoryFilter,
  noticePeriodFilter,
  tabValue,
  columnView,
  userFilter
}) => {
  const [description, setDescription] = useState("")
  const [descriptionColour, setDescriptionColour] = useState<string>()
  const [dialogDescriptionEvent, setDialogDescriptionEvent] = useState()
  const [inFocus, setInFocus] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [notice, setNotice] = useState<Notice | undefined>()
  const authContext = useAuth() as authContextInterface
  const { setLoggedIn, user } = authContext
  const [circleLoading, setCircleLoading] = useState<boolean>(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getAssignedUsers = () => {
    if (tabValue === "assigned" && user?.userId && userFilter.indexOf(user.userId) === -1) return [...userFilter, user.userId]
    return userFilter
  }

  const handleAddDialog = async () => {
    console.log("USER", user)
    console.log("handleAddDialog", company && description)
    if (company && description) {
      try {
        const date = new Date()
        const dueDate = noticePeriodFilter === 'today'
          ? new Date().setHours(23, 59, 59)
          : noticePeriodFilter === 'week'
            ? new Date().setDate(new Date().getDate() + 6)
              : noticePeriodFilter === '2week'
              ? new Date().setDate(new Date().getDate() + 13)
              : new Date().setFullYear(date.getFullYear(), date.getMonth() +1, 0)
        const newNotice = {
          noticeId: "",
          company: company.companyId,
          description: description,
          creator: user?.userId,
          assignedUsers: getAssignedUsers(),
          pinned: false,
          status: "D",
          timeStamp: "",
          category: noticeCategoryFilter?.categoryTitle ? noticeCategoryFilter.categoryTitle : "",
          categoryId: noticeCategoryFilter?.noticeCategoryId ? noticeCategoryFilter?.noticeCategoryId : null,
          isArchived: false,
          isComplete: false,
          isPrivate: false,
          isFollowup: false,
          startDate: noticePeriodFilter ? new Date().toISOString() : null,
          dueDate: noticePeriodFilter ? new Date(dueDate).toISOString() : null,
        }
        const response = await NoticeAPI.add(newNotice)
        addNewNotice(response)
        setDescription("")
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err)
      }
    }
    setOpen(false)
  }

  const handleAddLine = async (newDescription: string) => {
    console.log("USER", user)
    console.log("handleAddDialog", company && description)
    const date = new Date()
    const dueDate = noticePeriodFilter === 'today'
        ? new Date().setHours(23, 59, 59)
        : noticePeriodFilter === 'week'
            ? new Date().setDate(new Date().getDate() + 6)
            : noticePeriodFilter === '2week'
                ? new Date().setDate(new Date().getDate() + 13)
                : new Date().setFullYear(date.getFullYear(), date.getMonth() +1, 0)
    if (company && newDescription) {
      try {
        const newNotice = {
          noticeId: "",
          company: company.companyId,
          description: newDescription,
          creator: user?.userId,
          assignedUsers: getAssignedUsers(),
          pinned: false,
          status: "D",
          timeStamp: "",
          category: noticeCategoryFilter?.categoryTitle ? noticeCategoryFilter.categoryTitle : "",
          categoryId: noticeCategoryFilter?.noticeCategoryId ? noticeCategoryFilter?.noticeCategoryId : null,
          isArchived: false,
          isComplete: false,
          isPrivate: false,
          isFollowup: false,
          startDate: noticePeriodFilter ? new Date().toISOString() : null,
          dueDate: noticePeriodFilter ? new Date(dueDate).toISOString() : null,
        }
        const response = await NoticeAPI.add(newNotice)
        addNewNotice(response)
        setCircleLoading(false)
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err)
      }
    }
  }

  const addDescriptionState = (event: any) => {
    event.target.value = ""
  }

  const resetDescription = (event: any) => {
    event.target.value = description
  }

  const handleDialogChange = (event: any) => {
    setDialogDescriptionEvent(event)
    setDescription(event.target.value)
  }

  const handleDescriptionChange = async (event: any) => {
    try {
      console.log("USER", user)
      console.log("add " + event.target.value)
      if (event.target.value === description) { resetDescription(event); return }
      if (!company) return;
      const newNotice = {
        noticeId: "",
        company: company.companyId,
        description: event.target.value,
        creator: user && user.userId ? user.userId : undefined,
        assignedUsers: getAssignedUsers(),
        pinned: false,
        status: "D",
        timeStamp: "",
        category: noticeCategoryFilter?.categoryTitle ? noticeCategoryFilter.categoryTitle : "",
        categoryId: noticeCategoryFilter?.noticeCategoryId ? noticeCategoryFilter?.noticeCategoryId : null,
        isArchived: false,
        isComplete: false,
        isPrivate: false,
        isFollowup: false,
        dueDate: noticePeriodFilter ? new Date().toISOString() : null,
      }
      let response: Notice = (await NoticeAPI.add(newNotice)) as Notice
      setNotice(response)
      setDescription(response.description)
      event.target.blur()
      event.preventDefault()
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err)
      resetDescription(event)
    }
  }

  return (
    <>
      {action === "dialog" ? (
        <>
          <img
            style={{ cursor: "pointer", paddingTop: "5px" }}
            src={addIcon}
            alt="add notice"
            onClick={handleClickOpen}
            width="24px"
            height="24px"
          />
          <CustomDialog
            open={open}
            handleClose={handleClose}
            dialogTitle="Add Notice"
            dialogContentText="Please enter your description here"
            action="addNotice"
            handleDialogChange={handleDialogChange}
            handleAddDialog={handleAddDialog}
          />
        </>
      ) : action === "row" ? (
        <>
          {notice ? (
            <>
              <TableCell
                sx={[
                  useStyles.tableCheckboxStyle,
                  { borderBottom: " 0.3px solid #C7B4FC;" },
                ]}
              />
              <TableCell
                sx={[
                  useStyles.tableBodyTitleFieldStyle,
                  { borderBottom: " 0.3px solid #C7B4FC;" },
                ]}
                colSpan={2}
              >
                {console.log(descriptionColour)}
                <EditNotice
                  notice={notice}
                  action={"description"}
                  descriptionColour={descriptionColour}
                  categoryList={categoryList}
                  notices={notices}
                  setNotices={setNotices}
                />
              </TableCell>
              {/* <TableCell sx={useStyles.tableBodyStyle} style={{ borderRight: "0.3px solid rgba(224, 224, 224, 1)" }}> */}
              <TableCell
                sx={[
                  useStyles.tableBodyStyle,
                  { borderBottom: " 0.3px solid #C7B4FC;" },
                ]}
              >
                <EditNotice
                  notice={notice}
                  action={"category"}
                  categoryList={categoryList}
                  notices={notices}
                  setNotices={setNotices}
                />
              </TableCell>
              <TableCell
                sx={[
                  useStyles.tableBodyStyle,
                  { borderBottom: " 0.3px solid #C7B4FC;" },
                ]}
              >
                <EditNotice
                  notice={notice}
                  action={"assignee"}
                  categoryList={categoryList}
                  notices={notices}
                  setNotices={setNotices}
                />
              </TableCell>
              {/* <TableCell sx={useStyles.tableBodyStyle} style={{ borderRight: "0.3px solid rgba(224, 224, 224, 1)" }}>
                        <EditNotice
                            notice={notice}
                            action={"pin"}
                            setDescriptionColour={setDescriptionColour}
                            categoryList={categoryList}
                            notices={notices}
                            setNotices={setNotices}/>
                    </TableCell>
                    <TableCell sx={useStyles.tableBodyStyle}>
                        <EditNotice
                            notice={notice}
                            action={"status"}
                            categoryList={categoryList}
                            notices={notices}
                            setNotices={setNotices} />
                    </TableCell> */}
            </>
          ) : (
            <>
              <TableCell
                sx={[
                  useStyles.tableBodyStyle,
                  { borderBottom: " 0.3px solid #C7B4FC;" },
                ]}
              ></TableCell>
              <TableCell
                sx={[
                  useStyles.tableCheckboxStyle,
                  { borderBottom: " 0.3px solid #C7B4FC;" },
                ]}
              >
                {circleLoading && (
                  <CircularProgress
                    size={20}
                    sx={{
                      "&.MuiCircularProgress-root": { color: "#754EE0" },
                      position: "absolute",
                      zIndex: 99,
                      top: "6px",
                    }}
                  />
                )}
              </TableCell>
              <TableCell
                sx={[
                  useStyles.tableBodyTitleFieldStyle,
                  { borderBottom: " 0.3px solid #C7B4FC;" },
                ]}
                colSpan={3}
              >
                <Input
                  size="small"
                  endAdornment={inFocus ? "Press 'Enter' to save" : ""}
                  fullWidth
                  disableUnderline
                  defaultValue={description}
                  sx={{
                    height: "10px",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontFamily: "Inter",
                    fontSize: "11px",
                    lineHeight: "15px",
                    position: "relative",
                    color: "black",
                  }}
                  onFocus={(event: any) => {
                    addDescriptionState(event)
                    setInFocus(true)
                  }}
                  onBlur={(event: any) => {
                    resetDescription(event)
                    setInFocus(false)
                  }}
                  onKeyUp={(event: any) => {
                    if (event.keyCode === 13) {
                      setCircleLoading(true)
                      handleAddLine(event.target.value)
                      event.target.value = ""
                    } else if (event.keyCode === 27) {
                      resetDescription(event)
                    }
                  }}
                />
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: " 0.3px solid #C7B4FC;",
                  borderLeft: "0.3px solid #C7B4FC",
                  ...useStyles.tableBodyStyle,
                }}
              ></TableCell>
              <TableCell
                sx={[
                  useStyles.tableBodyStyle,
                  { borderBottom: " 0.3px solid #C7B4FC;" },
                ]}
              ></TableCell>
              {columnView.assignee && (
                <TableCell
                  sx={{
                    borderBottom: " 0.3px solid #C7B4FC;",
                    borderLeft: "0.3px solid #C7B4FC",
                    ...useStyles.tableBodyStyle,
                  }}
                ></TableCell>
              )}
              {columnView.startDate && (
                <TableCell
                  sx={{
                    borderBottom: " 0.3px solid #C7B4FC;",
                    borderLeft: "0.3px solid #C7B4FC",
                    ...useStyles.tableBodyStyle,
                  }}
                ></TableCell>
              )}
              {columnView.endDate && (
                <TableCell
                  sx={{
                    borderBottom: " 0.3px solid #C7B4FC;",
                    borderLeft: "0.3px solid #C7B4FC",
                    ...useStyles.tableBodyStyle,
                  }}
                ></TableCell>
              )}
              {/* <TableCell
                sx={{
                  borderBottom: " 0.3px solid #C7B4FC;",
                  borderLeft: "1px solid #C7B4FC",
                  ...useStyles.tableBodyStyle,
                }}
              ></TableCell> */}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default AddNotice
