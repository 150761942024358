import { styled } from "@material-ui/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button } from "@mui/material";
import { Company } from "../../../Models/company";
import { Pricelist } from "../../../Models/pricelist";
import { Project } from "../../../Models/project";
import pricelistAPI, {PricelistResponse} from "../../../Services/DoxleAPI/pricelistAPI";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import PricelistItem from "./PricelistItem";
import AddIcon from "@mui/icons-material/Add";
import PricelistAPI from "../../../Services/DoxleAPI/pricelistAPI";
import { useSocket } from "../../../Services/Sockets/useSocket";
import { useCallback } from "react";
import PricelistSearchBar from "./PricelistSearchBar";
import PricelistDialog from "./PricelistDialog";

interface props {
  company: Company | undefined;
  selectedProject: Project | null;
}

const Pricelists: React.FC<props> = ({ company, selectedProject }) => {
  const [fetchPage, setFetchPage] = useState<number|null>(1);
  const [pricelist, setPricelist] = useState<Array<Pricelist>>([]);
  const [searchString, setSearchString] = useState<string>("");
  const [selectedPricelist, setSelectedPricelist] = useState<Pricelist>();
  const [openPricelistModal, setOpenPricelistModal] = React.useState(false);
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn } = authContext;
  //! <--- SOCKETS
  const socket = useSocket();
  const onMessage = useCallback(
    (message) => {
      const data = JSON.parse(message?.data);

      const serverStatus: string = data.status;
      const messageType: string = data.message_type;
      // console.log(
      //   "%cSERVER= " + serverStatus + " " + messageType,
      //   "background:green; color:white"
      // );
      if (messageType === "Pricelist" || 
          messageType === "PricelistUpdate" || 
          messageType === "PricelistDelete") {
        if (serverStatus === "SUCCESS") {
          console.log("DOXLE SOCKETS=SUCCESSFULLY ADDED TO THE DATABASE");
          const receivedMessage = data.message as Pricelist;

          console.log(
            "%cDOXLE SOCKETS=SAVED  TO THE DATABASE",
            "background:green; color:white"
          );

          console.log(receivedMessage);

          let updatedList:Pricelist[] = []
          if (messageType === "Pricelist") {
            setPricelist((prevList: Pricelist[]) => {
              updatedList = [...prevList, receivedMessage];
              return updatedList
            });

          } else if (messageType === "PricelistUpdate") {
            setPricelist((prevList: Pricelist[]) => {
              updatedList = prevList.map((price: Pricelist) => (
                price.pricelistId === receivedMessage.pricelistId
                  ? receivedMessage
                  : price
              ))
              return updatedList
            });

          } else if (messageType === "PricelistDelete") {
            setPricelist((prevList: Pricelist[]) => {
              updatedList = prevList.filter((price: Pricelist) => (
                price.pricelistId !== receivedMessage.pricelistId
              ))
              return updatedList
            });
          }
          console.log("updatedList", updatedList);

        } else {
          console.log(
            "%cDOXLE SOCKETS=VALIDATION ERROR ! DID NOT SAVE  TO THE DATABASE",
            "background:red; color:white"
          );
        }
      }
    },
    [pricelist]
  );

  useEffect(() => {
    socket.addEventListener("message", onMessage);

    return () => {
      socket.removeEventListener("message", onMessage);
    };
  }, [socket, onMessage]);
  //! SOCKETS --->

  const fetchpricelist = async (page: number) => {
    try {
      const response = await pricelistAPI.getList(page) as PricelistResponse;
      if (response.items.length > 0) setPricelist((currentList:Pricelist[]) =>[...currentList, ...response.items]);
      setFetchPage(response.nextPage);
    } catch (error) {
      error === "AccessTokenNotFound" ? setLoggedIn(false) : console.error();
    }
  };

  const add = () => {
    let addPricelist = {
      cost_code: "1.01 Preliminaries",
      description: "GODZILLA ...",
      supplier: "SOCKETS",
      uom: "ea",
      price: "100",
      // timeStamp: new Date().toString(),
      company: company?.companyId,
    };

    socket.send(
      JSON.stringify({
        type: "Pricelist",
        message: addPricelist,
      })
    );
  };

  const handlePricelistCloseModal = () => {
    setOpenPricelistModal(false);
    setSelectedPricelist(undefined)
  };

  const handleSelectPricelist = (item: Pricelist) => {
    setOpenPricelistModal(true);
    setSelectedPricelist(item)
  };


  useEffect(() => {
    console.log("FETCHING page:", fetchPage);
    if (fetchPage) fetchpricelist(fetchPage);
  }, [fetchPage]);

  return (
    <>

      <PricelistDialog
        selectedCompany={company}
        selectedProject={selectedProject}
        selectedPricelist={selectedPricelist}
        openModal={openPricelistModal}
        closeModal={handlePricelistCloseModal}
      />

      <PricelistSearchBar
        setOpenPricelistModal={setOpenPricelistModal}
        searchString={searchString}
        setSearchString={setSearchString}
      />
      <TableContainer
        sx={{
          maxHeight: "calc(100vh - 180px)",
          borderBottom: "1px solid rgba(224,224,224,1)",
          overflow: "auto",
          borderRadius: "0px",
        }}
        component={Paper}
      >
        <Table sx={{ tableLayout: "fixed" }} size="small">
          <TableHead></TableHead>
          <TableBody>
            {pricelist.map((pricelistItem: Pricelist, index) => {
              if(pricelistItem.description.toUpperCase().includes(searchString.toUpperCase())
                || pricelistItem.supplier.toUpperCase().includes(searchString.toUpperCase())
              ) return (
                <PricelistItem key={index} item={pricelistItem} handleSelectPricelist={handleSelectPricelist}/>
              )
              else return null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Pricelists;
