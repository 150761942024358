import React, { useEffect } from 'react'
import { User } from '../../../Models/user'
import { authContextInterface, useAuth } from '../../Providers/AuthProvider'
import logoutIcon from '../../../Assets/logout.svg'
import settingIcon from '../../../Assets/DrawerSettingIcon.svg'
import WindowDimensions from '../../../utilities/WindowDimensions/windowDimension'

const ViewUser: React.FC = () => {
  const { height, width } = WindowDimensions()
  const authContext = useAuth() as authContextInterface
  const { user } = authContext
  const selectedUser: User = user
    ? user
    : {
        firstName: 'Anonymous',
        lastName: 'User',
        email: 'design@doxle.cpom',
      }
  const weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  let day = weekday[new Date().getDay()]
  let time = ''

  if (selectedUser.lastLogin && selectedUser.lastLogin.length >= 17) {
    day =
      weekday[
        new Date(
          parseInt(selectedUser.lastLogin.substring(0, 4)),
          parseInt(selectedUser.lastLogin.substring(5, 7)) - 1,
          parseInt(selectedUser.lastLogin.substring(8, 10))
        ).getDay()
      ]
    time =
      parseInt(selectedUser.lastLogin.substring(11, 13)) + 10 > 12
        ? (parseInt(selectedUser.lastLogin.substring(11, 13)) - 2).toString() +
          ':' +
          parseInt(selectedUser.lastLogin.substring(14, 16)).toString() +
          'PM'
        : (parseInt(selectedUser.lastLogin.substring(11, 13)) + 10).toString() +
          ':' +
          parseInt(selectedUser.lastLogin.substring(14, 16)).toString() +
          'AM'
  }
  const lastLoggedIn = day + ' ' + time

  const handleLogOut = () => {
    console.log('Log out clicked')
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: width < 928 ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            background: '#BEABF5',
            borderRadius: '50%',
            width: 27,
            height: 27,
            margin: 0,
            padding: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <span style={{ color: 'white', fontSize: '12px' }}>
            {selectedUser.firstName[0]}
            {selectedUser.lastName[0]}
          </span>
        </div>
        <img src={settingIcon} alt='logout' style={{}} onClick={handleLogOut} />
      </div>
      {/* 
            <div style={{
                display: "flex", 
                flexDirection:"column",
                marginTop:"20px", 
                marginLeft:"48px",
                textAlign:"center",
                fontFamily:"Inter",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "14px"}}>
                <p style={{
                    color: "#000000",
                    marginBottom: "0px"}}>{selectedUser.firstName} {selectedUser.lastName}</p>
                <p style={{
                    fontWeight: "500",
                    fontSize: "8px",
                    marginBottom: "0px",
                    marginTop: "0px",
                    color: "#96A2BE"}}>{selectedUser.email}</p>
                <p style={{
                    fontWeight: "500",
                    fontSize: "8px",
                    marginTop: "0px",
                    color: "#96A2BE"}}>{selectedUser.phone || "0000 000 000"}</p>
                <p style={{
                    fontWeight: "500",
                    fontSize: "8px",
                    marginTop: "0px",
                    color: "#0030A8"}}>Last Logged in { lastLoggedIn }</p>
            </div>
            */}
    </>
  )
}
export default ViewUser
