import React, { useEffect, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    Grid,
    Button,
    Tab,
    IconButton,
    Breadcrumbs,
    MenuItem,
    Select, Chip,
    TextField, InputAdornment
} from '@mui/material';
import useStyles from './permitDetailsStyle';
import { Close } from '@mui/icons-material';
import { MoreVert, AddCircle, NavigateNextSharp } from '@mui/icons-material';
import {  ITabs } from '../../../Models/costcode';
import { Permit } from '../../../Models/permit'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Bill, BillLine } from '../../../Models/bill';
import { Specification } from '../../../Models/specification';
import {Folder, FileData} from '../../../Models/storage'
import Specifications from '../Specifications/Specifications';
import Quotes from '../Quotes/Quotes';
import Storage from '../Storage/Storage';
import Bills from '../Bills/Bills';
import Checklists from '../Checklists/Checklists';
import InputDialog from "../../../utilities/InputDialog/inputDialog";
import storageAPI from "../../../Services/DoxleAPI/storageAPI";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import FolderAddIcon from '../../../Assets/Folder - Plus.svg';

import {ReactComponent as StorageIcon} from '../../../Assets/storage.svg';
import {ReactComponent as QuotesIcon} from '../../../Assets/quotes.svg';
import {ReactComponent as CommentsIcon} from '../../../Assets/comments.svg';
import {ReactComponent as SpecsIcon} from '../../../Assets/specs.svg';
import {ReactComponent as BillsIcon} from '../../../Assets/bills.svg';
import {ReactComponent as ChecklistIcon} from '../../../Assets/checklist.svg';
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import Comments from "../Comments/Comments";
import {Comment} from "../../../Models/comments";
import checklistAPI from "../../../Services/DoxleAPI/checklistAPI";
import {Checklist} from "../../../Models/checklist";
import costCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import { User } from "../../../Models/user";

const timeElapsed = new Date();

interface CommentView{permit?: Permit; checklist?: Checklist;}
interface props {
    commentView: CommentView;
    // selectedTabIndex: string;
    // selectedPermit?: Permit;
    openModal: boolean;
    closeModal: () => void;
    selectedProjectAddress: string | null;
    height: number;
    width: number;
    users: User[];
}

const PermitModal: React.FC<props> = ({
                                          commentView,
                                          openModal,
                                          closeModal,
                                          selectedProjectAddress,
                                          height,
                                          width,
                                          users
                                      }) => {
    const [commentList, setCommentList] = useState<Array<Comment>>(
        commentView.checklist?.comments ?commentView.checklist?.comments : commentView.permit?.comments || []);
    const title = commentView.checklist?.question ? commentView.checklist?.question : commentView.permit?.title || ""
    const classes = useStyles();
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn } = authContext;

    const style = {
        position: 'absolute' as 'absolute',
        top: width < 928 ? '37%' :'45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width < 928 ? (width)+"px" : "Calc(min(90vw, 1500px))",
        height: "75vh",
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius : '7px 7px 0px 0px'
    };

    const breadcrumbs = [
        <Typography key={0} className={classes.heading}> Byson Group</Typography>,
        <Typography key={1} className={classes.heading}> {selectedProjectAddress}</Typography>,
        <Typography key={2} className={classes.heading}> {title}</Typography>
    ]

    useEffect(() => {
        if (!openModal) {
            closeModal();
        }
    }, [openModal])

    useEffect(() => {
        setCommentList(commentView.checklist?.comments ?commentView.checklist?.comments : commentView.permit?.comments || [])
    }, [commentView])

    return (
        <Modal
            open={openModal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
        >
            {
                <Box sx={style}>
                    <Grid container className={classes.headerTop}>
                        <Grid item xs={10}>
                            <Breadcrumbs
                                separator={<NavigateNextSharp fontSize="small" style={{ fill: "#6A7F94" }} />}
                                aria-label="breadcrumb"
                                style={{ padding: '12px 10px 5px 25px' }}
                            >
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={2} className={classes.headrRightSec}>
                            {/* <Select  variant='standard'disableUnderline
                                     IconComponent={(props: any) => (<MoreVert {...props}/>)}
                            >
                            </Select> */}
                            <IconButton onClick={closeModal} size="small">
                                <Close className={classes.closeIcon} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <hr />
                    <div>

                        <Grid style={{ display: 'flex' }}>
                            <Typography className={classes.electrical}>{title || ''}</Typography>
                        </Grid>
                        <Grid container spacing={2} style={{marginLeft: "0", width: "100%"}}>
                            <Grid item xs={6} md={6}>
                                <Typography className={classes.date} style={{fontSize: '9px', marginTop: '15px',marginLeft: '25px',lineHeight: '11px',fontWeight: 400,fontFamily: 'Inter',}}>Last Modified by Selvin Kamal on 09/03/22 at 12:23pm</Typography>
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Box sx={{ width: '100%', typography: 'body1', paddingTop: width < 928 ? "44px":"0px"}}>
                            <div style={{ maxHeight: width < 928 ? height : "60vh", overflowY: "auto", overflowX: width < 928 ? "auto" :"hidden"}}>
                                <Grid sx={{ background: "#eff1f5", overflowY: "auto",minHeight: width < 928 ? height : "60vh" }}>
                                    <Comments 
                                        checklistGroupId={commentView.permit?.checklistGroupId}
                                        checklistId={commentView.checklist?.checklistId} 
                                        commentList={commentList} setCommentList={setCommentList} 
                                        closeModal={closeModal} 
                                        userList={users}
                                    />
                                </Grid>
                            </div>
                        </Box>
                    </div>
                </Box>
            }
        </Modal>
    )
};

export default PermitModal;