import {Divider, Grid, IconButton, MenuItem, NativeSelect, Select, TextField, Tooltip} from "@mui/material";
import {ICONS} from "../../../Assets";
import UndoIcon from "@mui/icons-material/Undo";
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import ColourMenu from "./ColourMenu";
import React, {useState} from "react";
import {DrawingSet, Sheet, TakeOff} from "../../../Models/takeOffs";
import {takeOffContextInterface, useTakeOff} from "../../Providers/TakeOffProvider";
import {User} from "../../../Models/user";
import {toFloat} from "./konvaFunctions/generalFunctions";
import {SingleMeasureContextInterface, useSingleMeasurementContext} from "./SingleMeasurements";


interface props {
    single?: true
}
const ToolBar = ({single}:props) => {
    const [heightUnit, setHeightUnit] = useState<"mm"|"m">("mm");
    const TakeOffContext = useTakeOff() as takeOffContextInterface
    const SingleMeasureContext = useSingleMeasurementContext() as SingleMeasureContextInterface;
    const {
        selectedTakeOff,
        tool, setTool,
        currentUnit,
        deduction, setDeduction,
        displayLabels, setDisplayLabels,
        currentColour, setCurrentColour,
        zHeight, setZHeight
    } = single ? SingleMeasureContext : TakeOffContext;
    return(
        <div className="measurement-tools">
            <div className="measurement-tools">
                {selectedTakeOff ?
                    <div className="measurement-tools-container">
                        <ul>
                            {currentUnit !== "EA" &&
                                <><li
                                    onClick={() => {
                                        setTool("Polygon");
                                    }}
                                    className={`measurement-tools-list ${tool === "Polygon" && "active"}`}
                                >
                                    <img src={ICONS.Polygon} className="measurement-icons" alt="" />
                                    <b className="icon1">Polygon</b>
                                </li>
                                    <li
                                        onClick={() => {
                                            setTool("Rectangle");
                                        }}
                                        className={`measurement-tools-list ${tool === "Rectangle"?"active":""}`}
                                    >
                                        <img src={ICONS.StopSquare} className="measurement-icons" alt="" />
                                        <b className="icon2">Square</b>
                                    </li>
                                    <li
                                        onClick={() => {
                                            setTool("Circle");
                                        }}
                                        className={`measurement-tools-list ${tool === "Circle"?"active":""}`}
                                    >
                                        <img src={ICONS.Ellipse} className="measurement-icons" alt="" />
                                        <b className="icon3">&nbsp;&nbsp;&nbsp;Circle</b>
                                    </li>
                                    {currentUnit !== "M3" && <li
                                        onClick={() => {
                                            setTool("Line");
                                        }}
                                        className={`measurement-tools-list ${tool === "Line"?"active":""}`}
                                    >
                                        <img src={ICONS.LineSvg} className="measurement-icons" alt="" />
                                        <b className="icon4">&nbsp;&nbsp;&nbsp;&nbsp;Line</b>
                                    </li>}

                                </>
                            }

                            {currentUnit === "EA" &&
                                <li
                                    onClick={() => {
                                        setTool("Selector");
                                    }}
                                    className={`measurement-tools-list ${tool === "Selector"?"active":""}`}
                                >
                                    <TouchAppOutlinedIcon color="action" className="measurement-icons" sx={{pt:"3px", pl:"15px"}}/>
                                </li>
                            }

                            <li
                                className={`measurement-tools-list`}
                                style={{background: currentColour}}
                            >
                                <b className="icon5">&nbsp;&nbsp;&nbsp;Colour</b>
                                <ColourMenu toolColor={currentColour} setToolColor={setCurrentColour}/>
                            </li>

                            <li onClick={() => {
                                setDisplayLabels(!displayLabels);
                            }}
                                className={`measurement-tools-list ${displayLabels?"active":""}`}
                            >
                                <img src={ICONS.SwatchBook} className="measurement-icons" alt="" />
                                <b className="icon6">&nbsp;&nbsp;Labels</b>
                            </li>
                            <li
                                onClick={() => {
                                    setDeduction(!deduction);
                                }}
                                style={{textAlign: "center", color: "#9974fe"}}
                                className={`measurement-tools-list ${deduction ? "active" : ""}`}
                            >
                                ±
                                <b className="icon4" style={{textAlign: "left", color: "black"}}>Deduction</b>
                            </li>
                            {
                                currentUnit === "M3" || (currentUnit === "M2" && tool === "Line") ?
                                    <li className="measurement-tools-list input-height">
                                        &nbsp;&nbsp;Height:&nbsp;&nbsp;&nbsp;&nbsp;
                                        <TextField
                                            variant="standard"
                                            sx={{width: "40px", height: "100%"}}
                                            value={heightUnit === "m" ? zHeight : zHeight * 1000}
                                            InputProps={{sx: {fontSize: "13px", fontFamily: "Roboto"}, disableUnderline: true}}
                                            onChange={(e) => {
                                                setZHeight(heightUnit === "m"
                                                    ? !isNaN(parseFloat(e.target.value))
                                                        ? parseFloat(e.target.value)
                                                        : 1
                                                    : !isNaN(parseFloat(e.target.value))
                                                        ? parseFloat(e.target.value) / 1000
                                                        : 1
                                                )
                                            }}
                                        />
                                        <Select
                                            sx={{width: "60px", height: "100%"}}
                                            variant="standard"
                                            value={heightUnit}
                                            disableUnderline={true}
                                            onChange={(e) => setHeightUnit(e.target.value === "mm" || e.target.value === "m" ? e.target.value : "mm")}
                                            displayEmpty
                                            inputProps={{ sx: {fontSize: "13px", fontFamily: "Roboto"}, 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value={"mm"}>mm</MenuItem>
                                            <MenuItem value={"m"}>m</MenuItem>
                                        </Select>
                                    </li>
                                    :
                                    null
                            }
                        </ul>
                    </div>
                    : null
                }
            </div>
        </div>
    )
}

export default ToolBar