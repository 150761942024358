import {TableContainer, Table, TableBody, IconButton, TableCell} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Costcode } from "../../../Models/costcode";
import { Specification } from "../../../Models/specification";
import CustomCollapsibleTable from "../../../utilities/CollapsibleTable/CollapsibleTable";
import useMountStatus  from '../../../utilities/Helper/checkMountStatus';
import MultiDialog from "../../../utilities/Dialog/MultiDialog";
import specificationAPI from "../../../Services/DoxleAPI/specificationAPI";
import "./specs.css";
import {ReactComponent as EditIcon} from "../../../Assets/editIcon2.svg";
import {ReactComponent as TrashIcon} from "../../../Assets/Trash.svg";
import DoneIcon from '@mui/icons-material/Done';
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutlined";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import AddIcon from '@mui/icons-material/Add';
import {ReactComponent as AddIcon} from "../../../Assets/Plus - Circle - White.svg";
import {TableRow} from "@material-ui/core";
import CustomCheckbox from "../../../utilities/CheckBox/CheckBox";

interface props{
    costcode: Costcode | undefined;
    specList: Specification[];
    setLoading: Function;
}

const Specifications: React.FC<props> = ({
    costcode, specList, setLoading
}) => {
    const [specifications, setSpecifications] = useState<Array<Specification>>([]);
    const [newTitle, setNewTitle] = useState<string>("");
    const [newDescription, setNewDescription] = useState<string>("");
    const [editMode, setEditMode] = useState<string | undefined>(undefined);
    const [openSpecsStatus, setOpenSpecsStatus] = useState(false);
    const [tickedSpecs, setTickedSpecs] = useState<string[]>([]);
    const [selectedSpec, setSelectedSpec] = useState<string>();
    const [specTitle, setSpecTitle] = useState(undefined);
    const [specDescription, setSpecDescription] = useState(undefined);
    const [editIconClicked, setEditIconClicked] =  useState(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const unmounted = useMountStatus();
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn } = authContext;

    const fetchSpecs = async() => {
        setLoading(true);
        try {
            const response = costcode ? await specificationAPI.getList(costcode.costCodeId) as Specification[] : [];
            if (unmounted) return;
            setSpecifications([...response]);
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
        setLoading(false);
    }

    const handleAddSpec = async () => {
        setLoading(true);
        try {
            if (newDescription === "") {  return;}
            const response = await specificationAPI.addSpec({
                specId: "",
                title: newTitle,
                description: newDescription,
                index: specifications.length,
                costCode: costcode ? costcode.costCodeId : "",
                images: []
            }) as Specification
            setSpecifications([...specifications, response])
            setDialogOpen(false)
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
        setLoading(false);
    }

    const getSpecPDF = async () => {
        setLoading(true);
        try {
            if(!costcode?.costCodeId) return;
            const response = await specificationAPI.getCostCodePDF(costcode.costCodeId);
            if (response) window.open(response)
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
        setLoading(false);
    }

    const handleToggleAll = () => {
        if (tickedSpecs.length < specifications.length) setTickedSpecs(specifications.map((spec:Specification) => spec.specId))
        else setTickedSpecs([])
    }

    const handleTitleChange = (e: any) => {
        if (e.target && e.target.value) {
            setNewTitle(e.target.value)
        }
    }

    const handleDescriptionChange = (e: any) => {
        if (e.target && e.target.value) {
            setNewDescription(e.target.value)
        }
    }

    const handleClose = () => {
        setNewTitle("");
        setNewDescription("");
        setDialogOpen(false)
    }

    const handleEditIcon = () => {
        if (editIconClicked) {
            handleSaveButton();
            return;
        }
        if(tickedSpecs.length === 1){
            setSelectedSpec(tickedSpecs?.[0])
            setEditMode(tickedSpecs?.[0])
            setEditIconClicked(true)
        } else {
            setEditIconClicked(false)
        }
    };
    
    const handleCheckBox = (e: any, specId: string) =>{
            //setEditIconClicked(false)
            setTickedSpecs([])
            if (e.target && typeof e.target.checked !== "undefined"){
                let specs = tickedSpecs
                if(specs){
                    const i = specs.indexOf(specId)
                    if (e.target.checked) {
                        setOpenSpecsStatus(true)

                        if (i === -1){ specs.push(specId) }
                    } else {
                        if(tickedSpecs.length === 1){
                            setOpenSpecsStatus(false)
                        }

                        if (i !== -1){ specs.splice(i, 1); }
                    }
                    setTickedSpecs([...specs])
                }
                
            }

            if(e.target.checked){
                if(tickedSpecs.length === 1){
                    setSelectedSpec(specId)
                    setEditMode(specId)
                } else {
                    setEditMode(undefined)
                    setEditIconClicked(false)
                }
            } else {
                setEditMode(undefined)
                setEditIconClicked(false)
            }

    }

    const handleDelete = async () => {
        setLoading(true);
        try {
            let successful: string[] = [];
            if (tickedSpecs) {
                for (let b = 0; b < tickedSpecs.length; b++) {
                    if (await specificationAPI.removeSpec(tickedSpecs[b])) {
                        successful.push(tickedSpecs[b])
                    }
                }
                let specs: Specification[] = []
                specifications.forEach(spec => {
                    if (successful.indexOf(spec.specId || "") === -1) {
                        specs.push(spec)
                    }
                    setSpecifications([...specs])
                    setTickedSpecs([])
                })
            }
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
        setLoading(false);
    }

    const handleSaveButton = async () => {
        setLoading(true);
        try {
            if(selectedSpec){
                let body: any = {}

                if (specTitle !== undefined){
                    body.title = specTitle
                }
    
                if(specDescription !== undefined){
                    body.description = specDescription;
                }
                if (body.description || body.title){
                    const response = await specificationAPI.updateSpec(selectedSpec, body)
                    if(response){
                        let specIndex = specifications.findIndex((row => row.specId === response.specId))
                        specifications[specIndex] = response
                        setSpecifications([...specifications])
                    }
                }
            }

            setEditMode(undefined);
            setEditIconClicked(false);

        } catch(err){
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchSpecs();
    }, [costcode]); //pass dependencies

    useEffect(() => {
        console.log(tickedSpecs);
        console.log(tickedSpecs.length);
        console.log(specifications.length);
    }, [tickedSpecs]); //pass dependencies
    
    return(
        <div>
            <div style={{
                paddingLeft:"20px",
                display:"flex",
                flexDirection:"row",
            }}>
                {/* <div
                    style={{
                        display:"flex",
                        flexDirection:"row",
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '30px',
                        lineHeight: '35px',
                        color: '#000000',
                    }}>
                    Specs
                </div> */}
                <div style={{
                    display:"flex",
                    flexDirection:"row",
                    gap:"10px",
                    justifyContent: "flex-end",
                    width: "100%",
                    height: "27px",
                    maxHeight: "27px",
                }}>
                    <Button variant="contained" startIcon={<EditIcon />} size="small"
                        // disabled={tickedSpecs.length !== 1}
                        sx={{
                            display: tickedSpecs.length === 1 ? "flex" : "none",
                            textTransform: 'none',
                            background: '#36bfa8',
                            borderRadius: '4px',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '9px',
                            lineHeight: '11px',
                            padding: "4px 9px",
                            "&:hover": {
                                background: '#3dd9bf',
                            }
                        }}
                        onClick={(event: any) => handleEditIcon()}
                    >
                        { !editIconClicked ? "Edit Spec" : "Confirm"}
                    </Button>
                    <Button variant="contained" startIcon={<DeleteOutlineIcon />} size="small"
                        // disabled={tickedSpecs.length === 0}
                        sx={{
                            display: tickedSpecs.length > 0 ? "flex" : "none",
                            textTransform: 'none',
                            background: '#CD326A',
                            borderRadius: '4px',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '9px',
                            lineHeight: '11px',
                            padding: "4px 9px",
                            "&:hover": {
                                background: '#EF548C',
                            }
                        }}
                        onClick={(event:any)=> handleDelete()}
                    >
                        Delete Spec
                    </Button>
                    <Button variant="contained" startIcon={<FileDownloadIcon />} size="small"
                        sx={{
                            textTransform: 'none',
                            background: '#364dbf80',
                            borderRadius: '4px',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '9px',
                            lineHeight: '11px',
                            padding: "4px 9px",
                            "&:hover": {
                                background: '#364dbf',
                            }
                        }}
                        onClick={(event:any)=> getSpecPDF()}
                    >
                        Export Specs
                    </Button>
                    <Button variant="contained" startIcon={<AddIcon />} size="small"
                        sx={{
                            textTransform: 'none',
                            background: '#4C2BA780',
                            borderRadius: '4px',
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '9px',
                            lineHeight: '11px',
                            padding: "4px 9px",
                            "&:hover": {
                                background: '#4C2BA7',
                            }
                        }}
                        onClick={(event:any)=> setDialogOpen(true)}
                    >
                        Specs
                    </Button>
                </div>
            </div>

            <TableContainer sx={{paddingTop:"39px"}}>
                <Table size="small" aria-label="collapsible table" sx={{
                    backgroundColor:"#FFFFFF"
                }}>
                    <TableBody sx={{
                        "& > tr": {
                            background: "#F5F6F7",
                            borderBottom: "2px solid white",
                            "& > td:first-of-type ":
                            { borderRadius: "4px 0 0 4px" },
                            "& > td:last-child ":
                            { borderRadius: "0 4px 4px 0" },
                            "& > td": {
                            background: "#F5F6F7",
                            }
                        }
                    }}>
                        <TableRow >
                            <TableCell style={{backgroundColor: "white"}}>
                                <CustomCheckbox
                                    onClick={handleToggleAll}
                                    style={{ transform: "scale(0.4)" }}
                                    checked={(specifications.length === tickedSpecs.length)}
                                />
                            </TableCell>
                            <TableCell style={{backgroundColor: "white", color:"#9974FE", fontFamily: "Roboto", fontSize: "11px"}}>Number</TableCell>
                            <TableCell style={{backgroundColor: "white", color:"#9974FE", fontFamily: "Roboto", fontSize: "11px"}}>Description</TableCell>
                            <TableCell style={{backgroundColor: "white", color:"#9974FE", fontFamily: "Roboto", fontSize: "11px"}} />
                        </TableRow>
                    {specifications 
                    ? 
                        specifications.map((spec: Specification, index: number) => (
                            <CustomCollapsibleTable
                                key={spec.specId}
                                action="specs"
                                row={spec}
                                index={index+1}
                                costcode={costcode}
                                editMode={editMode}
                                setEditMode={setEditMode}
                                editIconClicked={editIconClicked}
                                openSpecsStatus={openSpecsStatus}
                                handleCheckBox={handleCheckBox}
                                setSpecTitle={setSpecTitle}
                                setSpecDescription={setSpecDescription}
                                handleSaveButton={handleSaveButton}
                                tickedSpecs={tickedSpecs}
                            />
                        ))
                    :
                        <></>
                    }
                    </TableBody>
                </Table>
            </TableContainer>

            <MultiDialog
                open={dialogOpen || false }
                dialogTitle="Add New Spec"
                inputs={[
                    {
                        dialogText: "Description",
                        handleInputChange: handleDescriptionChange
                    }
                ]}
                handleClose={handleClose}
                handleSubmit={handleAddSpec}
            />
        </div>   
    )
}
export default Specifications