import React, { useEffect, useRef, useState } from "react";
import { Typography, Grid, Stack, Box } from "@mui/material";
import { authContextInterface, useAuth } from "../../../Providers/AuthProvider";
import moment from "moment";
import NoticeItem from "../NoticeItem";
import { getTimeRangeNotice, IUserWithTask } from "../CommonResources";
import { Notice } from "../../../../Models/notice";
import titleCase from "../../../../utilities/Helper/titleCase";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as BackIcon } from "../../../../Assets/BackMyViewTimetable.svg";
import MyViewNotice from "./MyViewNotice";
interface props {
  notice?: any;
  dailySelect: string;
  currentSelectedUser: IUserWithTask;
  setCurrentSelectedUser: Function;
}

const MyView: React.FC<props> = ({
  notice,
  dailySelect,
  currentSelectedUser,
  setCurrentSelectedUser,
}) => {
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, user } = authContext;
  const noticeRowSizeRef = useRef<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [notices, setnotices] = useState<Notice[]>([]);

  function getWeekDates() {
    let date = moment(),
      weeklength = 7,
      result = [];
    date = date.startOf("week");
    while (weeklength--) {
      result.push(date.format("DD"));
      date.add(1, "day");
    }
    return result;
  }

  const weekDates = getWeekDates();
  const dateNow = moment().format("DD").toString();
  const dayNow = moment().format("dddd").toString();
  const weekdays = moment.weekdays();

  //#########HANDLE BEHAVIOURS SHOWING BACK BTN#########

  const rootContainerRef = useRef<HTMLDivElement>(null);
  const [rootContainerWidth, setrootContainerWidth] = useState<number>(0);
  const [rootContainerLeftBound, setrootContainerLeftBound] =
    useState<number>(0);
  useEffect(() => {
    setrootContainerWidth(rootContainerRef.current?.offsetWidth as number);
    setrootContainerLeftBound(
      rootContainerRef.current?.getBoundingClientRect().left as number
    );
  }, [rootContainerRef]);

  const [showBackBtn, setShowBackBtn] = useState<boolean>(false);
  const handleMouseMove = (event: MouseEvent) => {
    if (event.pageX - rootContainerLeftBound >= rootContainerWidth - 40)
      setShowBackBtn(true);
    else setShowBackBtn(false);
  };
  const handleMouseLeave = (event: MouseEvent) => {
    setShowBackBtn(false);
  };
  useEffect(() => {
    const div = rootContainerRef.current;
    if (div) {
      div.addEventListener("mousemove", handleMouseMove);
      div.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        div.removeEventListener("mousemove", handleMouseMove);
        div.removeEventListener("mouseleave", handleMouseLeave);
      };
    } else setShowBackBtn(false);
  }, []);

  //#######################################################

  const officeTimeData = [
    7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  ];
  if (dailySelect === "Daily View")
    return (
      <Box
        sx={{
          marginBottom: "50px",
          backgroundColor: "white",
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          transform: "translateZ(0)",
        }}
        ref={rootContainerRef}
      >
        {/*Header Section */}
        <Box className="timetable-header-myview">
          <Box className={"sidebar-timetable-header-teamview"}>
            <Typography fontWeight={"bold"}>
              {titleCase(currentSelectedUser.user.firstName)}{" "}
              {titleCase(currentSelectedUser.user.lastName)}
            </Typography>
          </Box>

          <Box
            className={"row-timetable-header-teamview"}
            sx={{ justifyContent: "center", alignItems: "center" }}
            ref={noticeRowSizeRef}
          >
            <Typography
              sx={{
                color: "#7657CB",
                fontFamily: "Nostromo Regular",
                fontSize: "45px",
                fontStyle: "normal",
                fontWeight: "500",
                width: "100%",
                textAlign: "center",
              }}
            >
              {dateNow} <span style={{ color: "#AC8FFF" }}>{dayNow}</span>
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            flex: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
          }}
        >
          {/* {officeTimeData.map((time, index) => {
            let noticeMatchTime: Notice[] = currentSelectedUser.notice.filter(
              (notice) => getTimeRangeNotice(notice,pageTime).start === time
            );
            return (
              <Box key={`${time}#${index} `} className="timetable-data-myview">
                <Box className={"sidebar-timetable-header-teamview"}>
                  {time <= 12 ? time : Math.abs(12 - time)}:00{" "}
                  {time < 12 ? "am" : "pm"}
                </Box>

                <Box
                  className={"row-timetable-header-teamview"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "scroll",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  {noticeMatchTime.length > 0
                    ? noticeMatchTime.map((notice, index) => (
                        <MyViewNotice
                          key={`${notice.noticeId}#${index}`}
                          notice={notice}
                        />
                      ))
                    : null}
                </Box>
              </Box>
            );
          })} */}
        </Box>
        {/* {dailySelect === "Daily View" ? (
        <Grid
          item
          xs={10}
          className={"row-timetable"}
          sx={{ justifyContent: "center" }}
        >
          <Typography
            sx={{
              color: "#7657CB",
              fontFamily: "Nostromo Regular",
              fontSize: "45px",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            {dateNow} <span style={{ color: "#AC8FFF" }}>{dayNow}</span>
          </Typography>
        </Grid>
      ) : (
        <Grid
          item
          xs={10}
          className={"row-timetable"}
          sx={{ justifyContent: "center", padding: 0 }}
        >
          <Stack
            direction="row"
            width="100%"
            sx={{
              justifyContent: "center",
              ".MuiBox-root:last-child": {
                borderRight: "none",
              },
            }}
          >
            {weekDates.map((date, i) => (
              <Box
                sx={{
                  width: "calc(100%/7)",
                  borderRight: "1px solid #cbdae2",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography className={"title-weekly-date"}>{date}</Typography>
                <Typography className={"title-weekly-day"}>
                  <span style={{ color: "#AC8FFF" }}>{weekdays[i]}</span>
                </Typography>
              </Box>
            ))}
          </Stack>
        </Grid>
      )}

      {[
        { time: "07:00 am" },
        {
          time: "08:00 am",
          notices: [
            {
              title: "Riddle FF Trusses Detailing",
              status: "#FFBA35",
              progress: "10%",
            },
          ],
        },
        {
          time: "09:00 am",
          notices: [
            {
              title: "Brady Concrete QA",
              status: "#855DF8",
            },
            {
              title: "Cambridge Site Visit",
              status: "#855DF8",
            },
            {
              title: "Selvin & Pat Meeting",
              status: "#FF7C04",
              start: "30%",
            },
            {
              title: "Organise BP Card For Pat",
              status: "#855DF8",
              start: "70%",
            },
          ],
        },
        {
          time: "10:00 am",
          notices: [
            {
              title: "Eloura Final QA",
              status: "#855DF8",
            },
            {
              title: "Geel Fixing QA",
              status: "#855DF8",
            },
            {
              title: "Mccomas - Spend Time With Framer",
              status: "#855DF8",
              start: "30%",
            },
          ],
        },
        { time: "11:00 am" },
        { time: "12:00 pm" },
        {
          time: "01:00 pm",
          notices: [
            {
              title: "Melrose Estimating With Pat..",
              status: "#FFBA35",
              progress: "50%",
            },
            {
              title: "Send Po To Eq Scaffolding",
              status: "#1DD1A1",
            },
            {
              title: "Email Thomas ..",
              status: "#FF7C04",
              start: "40%",
            },
          ],
        },

        { time: "02:00 pm" },
        { time: "03:00 pm" },
        { time: "04:00 pm" },
        { time: "05:00 pm" },
        { time: "06:00 pm" },
        { time: "07:00 pm" },
      ].map((data, i) => (
        <>
          <Grid item xs={2} className={"sidebar-timetable"}>
            {data.time}
          </Grid>
          {dailySelect === "Daily View" ? (
            <Grid
              item
              xs={10}
              className={"row-timetable"}
              flexDirection="column"
              position="relative"
            >
              {data.notices?.map((notice: any, index: number) => (
                <NoticeItem
                  key={notice.noticeId}
                  notice={notice}
                  taskTimeRange={getTimeRangeNotice(notice)}
                  noticeOrder={index}
                  pageTimeRange={{ start: 7, end: 13 }}
                  rowSize={noticeRowSizeRef.current?.offsetWidth}
                  notices={notices}
                  setnotices={setnotices}
                />
              ))}
            </Grid>
          ) : (
            <Grid
              item
              xs={10}
              className={"row-timetable"}
              sx={{ justifyContent: "center", padding: 0 }}
            >
              <Stack
                direction="row"
                width="100%"
                sx={{
                  justifyContent: "center",
                  ".MuiBox-root:last-child": {
                    borderRight: "none",
                  },
                }}
              >
                {weekDates.map((date, i) => (
                  <Box
                    sx={{
                      width: "calc(100%/7)",
                      borderRight: "1px solid #cbdae2",
                    }}
                  >
                    {date === dateNow &&
                      data.notices?.map((notice: any, index: number) => (
                        <NoticeItem
                          key={notice.noticeId}
                          notice={notice}
                          taskTimeRange={getTimeRangeNotice(notice)}
                          noticeOrder={index}
                          pageTimeRange={{ start: 7, end: 13 }}
                          rowSize={noticeRowSizeRef.current?.offsetWidth}
                          notices={notices}
                          setnotices={setnotices}
                        />
                      ))}
                  </Box>
                ))}
              </Stack>
            </Grid>
          )}
        </>
      ))} */}

        <AnimatePresence>
          {showBackBtn ? (
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: [0, 60] }}
              exit={{ width: [60, 0] }}
              whileTap={{ opacity: [0.8, 0.4] }}
              transition={{ duration: 0.2 }}
              style={{
                position: "fixed",
                top: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "#7657cb",
                opacity: 0.8,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderTopLeftRadius: "",
              }}
              onClick={() => setCurrentSelectedUser(undefined)}
            >
              <BackIcon />
            </motion.div>
          ) : null}
        </AnimatePresence>
      </Box>
    );
  else
    return (
      <Box
        sx={{
          marginBottom: "50px",
          backgroundColor: "white",
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
        ref={rootContainerRef}
      >
        {/*Header Section */}
        <Box className="timetable-header-myview">
          <Box className={"sidebar-timetable-header-teamview"}>
            <Typography fontWeight={"bold"}>
              {titleCase(currentSelectedUser.user.firstName)}{" "}
              {titleCase(currentSelectedUser.user.lastName)}
            </Typography>
          </Box>

          <Box
            className={"row-timetable-header-teamview"}
            sx={{ justifyContent: "center", alignItems: "center" }}
            ref={noticeRowSizeRef}
          >
            {dailySelect === "Daily View" ? (
              <Typography
                sx={{
                  color: "#7657CB",
                  fontFamily: "Nostromo Regular",
                  fontSize: "45px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {dateNow} <span style={{ color: "#AC8FFF" }}>{dayNow}</span>
              </Typography>
            ) : (
              <Stack
                direction="row"
                width="100%"
                sx={{
                  justifyContent: "center",
                  ".MuiBox-root:last-child": {
                    borderRight: "none",
                  },
                }}
              >
                {weekDates.map((date, i) => (
                  <Box
                    sx={{
                      width: "calc(100%/7)",
                      borderRight: "1px solid #cbdae2",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography className={"title-weekly-date"}>
                      {date}
                    </Typography>
                    <Typography className={"title-weekly-day"}>
                      <span style={{ color: "#AC8FFF" }}>{weekdays[i]}</span>
                    </Typography>
                  </Box>
                ))}
              </Stack>
            )}
          </Box>
        </Box>

        {/* {dailySelect === "Daily View" ? (
        <Grid
          item
          xs={10}
          className={"row-timetable"}
          sx={{ justifyContent: "center" }}
        >
          <Typography
            sx={{
              color: "#7657CB",
              fontFamily: "Nostromo Regular",
              fontSize: "45px",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            {dateNow} <span style={{ color: "#AC8FFF" }}>{dayNow}</span>
          </Typography>
        </Grid>
      ) : (
        <Grid
          item
          xs={10}
          className={"row-timetable"}
          sx={{ justifyContent: "center", padding: 0 }}
        >
          <Stack
            direction="row"
            width="100%"
            sx={{
              justifyContent: "center",
              ".MuiBox-root:last-child": {
                borderRight: "none",
              },
            }}
          >
            {weekDates.map((date, i) => (
              <Box
                sx={{
                  width: "calc(100%/7)",
                  borderRight: "1px solid #cbdae2",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography className={"title-weekly-date"}>{date}</Typography>
                <Typography className={"title-weekly-day"}>
                  <span style={{ color: "#AC8FFF" }}>{weekdays[i]}</span>
                </Typography>
              </Box>
            ))}
          </Stack>
        </Grid>
      )}

      {[
        { time: "07:00 am" },
        {
          time: "08:00 am",
          notices: [
            {
              title: "Riddle FF Trusses Detailing",
              status: "#FFBA35",
              progress: "10%",
            },
          ],
        },
        {
          time: "09:00 am",
          notices: [
            {
              title: "Brady Concrete QA",
              status: "#855DF8",
            },
            {
              title: "Cambridge Site Visit",
              status: "#855DF8",
            },
            {
              title: "Selvin & Pat Meeting",
              status: "#FF7C04",
              start: "30%",
            },
            {
              title: "Organise BP Card For Pat",
              status: "#855DF8",
              start: "70%",
            },
          ],
        },
        {
          time: "10:00 am",
          notices: [
            {
              title: "Eloura Final QA",
              status: "#855DF8",
            },
            {
              title: "Geel Fixing QA",
              status: "#855DF8",
            },
            {
              title: "Mccomas - Spend Time With Framer",
              status: "#855DF8",
              start: "30%",
            },
          ],
        },
        { time: "11:00 am" },
        { time: "12:00 pm" },
        {
          time: "01:00 pm",
          notices: [
            {
              title: "Melrose Estimating With Pat..",
              status: "#FFBA35",
              progress: "50%",
            },
            {
              title: "Send Po To Eq Scaffolding",
              status: "#1DD1A1",
            },
            {
              title: "Email Thomas ..",
              status: "#FF7C04",
              start: "40%",
            },
          ],
        },

        { time: "02:00 pm" },
        { time: "03:00 pm" },
        { time: "04:00 pm" },
        { time: "05:00 pm" },
        { time: "06:00 pm" },
        { time: "07:00 pm" },
      ].map((data, i) => (
        <>
          <Grid item xs={2} className={"sidebar-timetable"}>
            {data.time}
          </Grid>
          {dailySelect === "Daily View" ? (
            <Grid
              item
              xs={10}
              className={"row-timetable"}
              flexDirection="column"
              position="relative"
            >
              {data.notices?.map((notice: any, index: number) => (
                <NoticeItem
                  key={notice.noticeId}
                  notice={notice}
                  taskTimeRange={getTimeRangeNotice(notice)}
                  noticeOrder={index}
                  pageTimeRange={{ start: 7, end: 13 }}
                  rowSize={noticeRowSizeRef.current?.offsetWidth}
                  notices={notices}
                  setnotices={setnotices}
                />
              ))}
            </Grid>
          ) : (
            <Grid
              item
              xs={10}
              className={"row-timetable"}
              sx={{ justifyContent: "center", padding: 0 }}
            >
              <Stack
                direction="row"
                width="100%"
                sx={{
                  justifyContent: "center",
                  ".MuiBox-root:last-child": {
                    borderRight: "none",
                  },
                }}
              >
                {weekDates.map((date, i) => (
                  <Box
                    sx={{
                      width: "calc(100%/7)",
                      borderRight: "1px solid #cbdae2",
                    }}
                  >
                    {date === dateNow &&
                      data.notices?.map((notice: any, index: number) => (
                        <NoticeItem
                          key={notice.noticeId}
                          notice={notice}
                          taskTimeRange={getTimeRangeNotice(notice)}
                          noticeOrder={index}
                          pageTimeRange={{ start: 7, end: 13 }}
                          rowSize={noticeRowSizeRef.current?.offsetWidth}
                          notices={notices}
                          setnotices={setnotices}
                        />
                      ))}
                  </Box>
                ))}
              </Stack>
            </Grid>
          )}
        </>
      ))} */}

        <AnimatePresence>
          {showBackBtn ? (
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: [0, 60] }}
              exit={{ width: [60, 0] }}
              whileTap={{ opacity: [0.8, 0.4] }}
              transition={{ duration: 0.2 }}
              style={{
                position: "fixed",
                top: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "#7657cb",
                opacity: 0.8,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderTopLeftRadius: "",
              }}
              onClick={() => setCurrentSelectedUser(undefined)}
            >
              <BackIcon />
            </motion.div>
          ) : null}
        </AnimatePresence>
      </Box>
    );
};

export default MyView;
