import { ProjectCashflow } from "../../../Models/project";
import { BasicStage } from "../../../Models/stage";
import {
  checkEqualMonthWithoutDate,
  convertWeekDaysValueToText,
  IDateInfo,
  TWeekDayAcronym,
} from "../../../utilities/FunctionUtilities";

export const getStageColor = (stageName: string) => {
  switch (stageName) {
    case "deposit":
      return "#855DF8";
    case "base":
      return "#FD0808";
    case "fixing":
      return "#FF7C04";
    case "lock up":
      return "#35CE7C";
    case "frame":
      return "#FFBA35";
    case "final":
      return "grey";
    default:
      return "green";
  }
};
export const calculateTotalCashflowInMonth = (
  cashflowData: ProjectCashflow[],
  monthValue: number,
  yearValue: number
): number => {
  let total: number = 0;
  cashflowData
    .filter((project) => project.projectStatus === "AC")
    .forEach((project) => {
      project.stages.forEach((stage) => {
        if (
          checkEqualMonthWithoutDate(
            new Date(stage.targetDate),
            new Date(yearValue, monthValue, 14)
          )
        ) {
          total += parseFloat(stage.value.toString());
        }
      });
    });

  return total;
};

interface ICashflowMilestone {
  fullDay: Date;
  dayValue: number;
  dateInWeek: TWeekDayAcronym;
  status: "TN" | "AC" | "AR" | "TM";
  title: string;
}
export const getAllStagePaymentInMonth = (
  cashflowData: ProjectCashflow[],
  monthValue: number,
  yearValue: number
): ICashflowMilestone[] => {
  let returnedData: ICashflowMilestone[] = [];
  cashflowData
    .filter((data) => data.projectStatus === "AC")
    .forEach((dataProject) => {
      dataProject.stages.forEach((cashflowProject) => {
        let cashflowDate: Date = new Date(cashflowProject.targetDate);
        if (
          cashflowDate.getMonth() === monthValue &&
          cashflowDate.getFullYear() === yearValue
        )
          returnedData.push({
            fullDay: cashflowDate,
            dayValue: cashflowDate.getDate(),
            dateInWeek: convertWeekDaysValueToText(
              cashflowDate.getDay(),
              "acronym"
            ) as TWeekDayAcronym,
            status: dataProject.projectStatus,
            title: cashflowProject.title,
          });
      });
    });

  return returnedData;
};
