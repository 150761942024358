import React from 'react'
import clipBoardIcon from '../../Assets/Clipboard.svg'
import clipBoardIconClicked from '../../Assets/Clipboard-alt.svg'
import clipBoardIconMinimised from '../../Assets/Clipboard.svg'
import clipBoardIconMinimisedClicked from '../../Assets/Clipboard-alt.svg'

import timeTableIcon from '../../Assets/TimeTable.svg'
import timeTableIconClicked from '../../Assets/TimeTable-alt.svg'
import timeTableIconMinimised from '../../Assets/TimeTable.svg'
import timeTableIconMinimisedClicked from '../../Assets/TimeTable-alt.svg'

import emailIcon from '../../Assets/Email.svg'
import emailIconClicked from '../../Assets/Email.svg'
import emailIconMinimised from '../../Assets/DrawerMailrooom.svg'
import emailIconMinimisedClicked from '../../Assets/DrawerMailrooomClicked.svg'

import posIcon from '../../Assets/POS.svg'
import posIconClicked from '../../Assets/POSEstimator.svg'
import posIconMinimised from '../../Assets/POSMinimised.svg'
import posIconMinimisedClicked from '../../Assets/POSEstimator.svg'

import tableIcon from '../../Assets/Grid.svg'
import tableIconClicked from '../../Assets/Grid-alt.svg'
import tableIconMinimised from '../../Assets/Grid.svg'
import tableIconMinimisedClicked from '../../Assets/Grid-alt.svg'

import newsIcon from '../../Assets/Newspaper.svg'
import newsIconClicked from '../../Assets/Newspaper-alt.svg'
import newsIconMinimised from '../../Assets/Newspaper.svg'
import newsIconMinimisedClicked from '../../Assets/Newspaper-alt.svg'

import dollarIcon from '../../Assets/Dollar.svg'
import dollarIconClicked from '../../Assets/Dollar.svg'
import dollarIconMinimised from '../../Assets/Dollar - CircleMinimised.svg'
import dollarIconMinimisedClicked from '../../Assets/Dollar.svg'

import costingIconMinimised from '../../Assets/DrawerCosting.svg'
import costingIconMinimisedClicked from '../../Assets/DrawerCostingClicked.svg'

import invoiceIcon from '../../Assets/Invoice.svg'
import chartIcon from '../../Assets/Chart - Column.svg'
import gearIcon from '../../Assets/Gear.svg'
import phoneIcon from '../../Assets/Phone.svg'

import homeIconClicked from '../../Assets/HomeClicked.svg'

import invoiceIconClicked from '../../Assets/InvoiceClicked.svg'
import chartIconClicked from '../../Assets/Chart - ColumnClicked.svg'
import gearIconClicked from '../../Assets/GearClicked.svg'
import phoneIconClicked from '../../Assets/PhoneClicked.svg'

import homeIconMinimised from '../../Assets/HomeMinimised.svg'

import invoiceIconMinimised from '../../Assets/InvoiceMinimised.svg'
import chartIconMinimised from '../../Assets/Chart - ColumnMinimised.svg'
import gearIconMinimised from '../../Assets/Gear.svg'
import phoneIconMinimised from '../../Assets/Phone.svg'

import homeIconMinimisedClicked from '../../Assets/HomeMinimisedClicked.svg'

import invoiceIconMinimisedClicked from '../../Assets/InvoiceMinimisedClicked.svg'
import chartIconMinimisedClicked from '../../Assets/Chart - ColumnMinimisedClicked.svg'
import gearIconMinimisedClicked from '../../Assets/GearMinimisedClicked.svg'
import phoneIconMinimisedClicked from '../../Assets/PhoneMinimisedClicked.svg'
import bookIconMinimised from '../../Assets/BookMinimised.svg'

import { ListItemAvatar, Avatar } from '@mui/material'

interface props {
  open: boolean
  text: string
  index: number
  clickedIdentifier: number | undefined
}

const IconItem: React.FC<props> = ({
  open,
  text,
  index,
  clickedIdentifier,
}) => {
  const getIcon = (inputText: string, clicked: boolean) => {
    if (open) {
      if (clicked) {
        if (inputText) {
          switch (inputText) {
            case 'Notice Board':
              return clipBoardIconClicked
            case 'Mailroom':
              return emailIconClicked
            case 'Projects':
              return tableIconClicked
            case 'Costings':
              return tableIconClicked
            case 'Estimator':
              return posIconClicked
            case 'PriceSpace':
              return dollarIconClicked
            case 'Dockets':
              return newsIconClicked
            case 'Settings':
              return gearIconClicked
            case 'Contact':
              return phoneIconClicked
            case 'Time Table':
              return timeTableIconClicked
          }
        }
      } else {
        if (inputText) {
          switch (inputText) {
            case 'Notice Board':
              return clipBoardIcon
            case 'Mailroom':
              return emailIcon
            case 'Projects':
              return tableIcon
            case 'Costings':
              return costingIconMinimised
            case 'Estimator':
              return posIcon
            case 'PriceSpace':
              return dollarIcon
            case 'Dockets':
              return newsIcon
            case 'Settings':
              return gearIcon
            case 'Contact':
              return phoneIcon
            case 'Time Table':
              return timeTableIcon
          }
        }
      }
    } else {
      if (clicked) {
        if (inputText) {
          switch (inputText) {
            case 'Notice Board':
              return clipBoardIconMinimisedClicked
            case 'Mailroom':
              return emailIconMinimisedClicked
            case 'Projects':
              return tableIconMinimisedClicked
            case 'Costings':
              return costingIconMinimisedClicked
            case 'Estimator':
              return posIconMinimisedClicked
            case 'PriceSpace':
              return dollarIconMinimisedClicked
            case 'Dockets':
              return newsIconMinimisedClicked
            case 'Settings':
              return gearIconMinimisedClicked
            case 'Contact':
              return phoneIconMinimisedClicked
            case 'Time Table':
              return timeTableIconMinimisedClicked
          }
        }
      } else {
        if (inputText) {
          switch (inputText) {
            case 'Notice Board':
              return clipBoardIconMinimised
            case 'Mailroom':
              return emailIconMinimised
            case 'Projects':
              return tableIconMinimised
            case 'Costings':
              return costingIconMinimised
            case 'Estimator':
              return posIconMinimised
            case 'PriceSpace':
              return dollarIconMinimised
            case 'Dockets':
              return newsIconMinimised
            case 'AddressBook':
              return bookIconMinimised
            case 'Settings':
              return gearIconMinimised
            case 'Contact':
              return phoneIconMinimised
            case 'Time Table':
              return timeTableIconMinimised
          }
        }
      }
    }
  }

  return (
    <ListItemAvatar
      sx={{
        minWidth: 0,
        mr: open ? 3 : 'auto',
        justifyContent: 'center',
        marginRight: open ? '9px' : 'auto',
        marginLeft: open ? '29px' : 'auto',
      }}
    >
      <Avatar
        alt={text}
        src={getIcon(text, index === clickedIdentifier)}
        sx={{
          height: open ? '16px' : '22px',
          width: open ? '16px' : '22px',
          padding: '0px',
          borderRadius: '0px',
          overflow: 'hidden',
        }}
      />
    </ListItemAvatar>
  )
}
export default IconItem
