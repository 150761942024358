import React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { Costcode } from "../../../Models/costcode";
import { User } from "../../../Models/user";
import AddComment from '../Comments/AddComment';
import AddUser from '../Users/AddUser';
// import useMountStatus  from '../../../utilities/Helper/checkMountStatus';
import "../CostCodes/costCode.css";
import { useStyles } from '../CostCodes/costcodeStyle';
import comments from "../Comments/Comments";
import AutocompleteCustom from "../../../utilities/Autocomplete/Autocomplete";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import userAPI from "../../../Services/DoxleAPI/userAPI";
import costCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import listIcon from "../../../Assets/ListOrdered.svg";
import listIconHighlight from "../../../Assets/ListOrderedHighlight.svg";
import fileAgreementIcon from "../../../Assets/FileAgreement.svg";
import fileTextIcon from "../../../Assets/FileText.svg";
import photosIcon from "../../../Assets/photos.svg";
import Badge from "@mui/material/Badge";
import EditPermit from "../Permits/EditPermit";
import {ReactComponent as TrashIcon} from "../../../Assets/Trash2.svg";
import AddContractor from "../Contractor/AddContractor";
import {AddressBookCompany} from "../../../Models/addressBook";
import CostCodeMenu from '../../Menu/CostCodeMenu';
import { ColumnView } from "../../../Models/columnView";
import CommentsPopover from '../Comments/CommentsPopover';
import {ScheduleItem} from "../../../Models/schedules";
import EditCostCode from "../CostCodes/EditCostCode";
import titleCase from '../../../utilities/Helper/titleCase';
import {DateRange} from "@mui/lab";
import dayjs, {Dayjs} from "dayjs";
import { useSocket } from '../../../Services/Sockets/useSocket';

interface props{
    initScheduleItem: ScheduleItem;
    users: User[];
    editTitleMode: boolean;
    setEditTitleMode: Function;
    editTitleOption: string|null;
    setEditTitleOption: Function;
    handleDeleteScheduleItem: Function;
    contractors:AddressBookCompany[];
    openStage: boolean;
    columnView: ColumnView;
}

const ScheduleRow: React.FC<props> = ({initScheduleItem, users, editTitleMode, editTitleOption, setEditTitleMode, setEditTitleOption, handleDeleteScheduleItem, contractors, columnView}) => {
    const [scheduleItem, setScheduleItem] = useState<ScheduleItem>(initScheduleItem);
    const [title, setTitle] = useState<string>(initScheduleItem.title);
    const [dateRangeValue, setDateRangeValue] = React.useState<DateRange<Dayjs>>([dayjs(initScheduleItem.startDate), dayjs(initScheduleItem.endDate)]);
    const [assignedContractor, setAssignedContractor] = useState<AddressBookCompany|undefined>(initScheduleItem?.assignedContractor as AddressBookCompany || undefined);
    const [assignedUsers, setAssignedUsers] = useState<(User)[]>([]);
    const [completed, setCompleted] = useState<boolean>(initScheduleItem.completed);

    const {height, width} = WindowDimensions();
    const limitAssignee = width < 928 ? 1 : 3;

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };


    const formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    })



    const getRowClasses = () => {
        let rowClass:string = "tableRowStyle"
        if (completed) { rowClass += " strikeout" }
        return rowClass
    }

    const getTextColor = () => {
        // if (status === "P") return "red";
        // if (status === "N") return "#5e5f5f";
        return "#0D0D0D";
    }

    const calculateDays = () => {
        if (!(dateRangeValue?.[0] && dateRangeValue?.[1])) return 0;
        return Math.round((dateRangeValue[0].diff(dateRangeValue[1]) / 1000 / 3600 / 24) + 1);
    }


    const handleRename = () => {
        setEditTitleMode(true);
        setEditTitleOption(scheduleItem.scheduleId)
    }

    const exitEditTitleMode = () =>{
        setEditTitleMode(false);
        setEditTitleOption(null);
    }

    useEffect(() => {
        setScheduleItem(initScheduleItem);
        let assUsers: User[] = []
        scheduleItem.assignedUsers.forEach((user) => {
            if (typeof user !== "string") assUsers.push(user as User)
        })
        setAssignedUsers(assUsers)
    },[initScheduleItem, initScheduleItem.assignedUsers])

    useEffect(() => {
        console.log('scheduleItem', scheduleItem)
    },[])


    useEffect(() => {
        setDateRangeValue([dayjs(initScheduleItem.startDate), dayjs(initScheduleItem.endDate)]);
      }, [initScheduleItem.startDate])

    useEffect(() => {
        setAssignedContractor(initScheduleItem.assignedContractor as AddressBookCompany | undefined)
    }, [initScheduleItem.assignedContractor])

    //! <--- SOCKETS LISTENER
    const socket = useSocket();
    const onMessage = useCallback(
        (message) => {
            const data = JSON.parse(message?.data);

            const serverStatus: string = data.status;
            const messageType: string = data.message_type;
            // console.log(
            //     "%cDOXLE SOCKETS=RECEIVE SUCCESS DATA", "background:green; color:white"
            // );

            if (messageType === "SocketDataUpdate") {
                if (serverStatus === "SUCCESS") {
                    const {costCodeId, status, startDate, endDate, assignedUsers, assignedContractor} = data.message;
                    const {checklistId, scheduleId} = data.message;

                    if (scheduleId && scheduleId === scheduleItem.scheduleId) {
                        if (assignedUsers) {
                            setScheduleItem(item => ({...item,assignedUsers}))
                            setAssignedUsers(assignedUsers)
                        }
                        if (assignedContractor) {
                            setScheduleItem(item => ({...item,assignedContractor}))
                            setAssignedContractor(assignedContractor)
                        }
                        if (assignedContractor === null) {
                            setAssignedContractor(undefined)
                        }
                        if (startDate && endDate) {
                            setScheduleItem(item => ({ ...item, startDate, endDate}))
                            setDateRangeValue([dayjs(startDate), dayjs(endDate)]);
                        }
                    }
        
                } else {
                    console.log(
                    "%cDOXLE SOCKETS=DATA ERROR",
                    "background:red; color:white"
                    );
                }
            }
        },[]
    );

    useEffect(() => {
        socket.addEventListener("message", onMessage);

        return () => {
        socket.removeEventListener("message", onMessage);
        };
    }, [socket, onMessage]);
    // ! SOCKETS --->

    return(
        <>
            <TableRow className={getRowClasses()} key={initScheduleItem.scheduleId}>
                {/* Costcode Title Cell */}
                <TableCell style={{minWidth: "44px", textAlign:"center", position: "sticky", left: 0, zIndex: 49, background: "white"}} sx={useStyles.tableBodyStyle}>
                    {/*<hr style={{position: "fixed", width: "65vw", marginLeft: "70px", marginTop: "18px"}}/>*/}
                    {     editTitleMode ?
                        <TrashIcon onClick={(e: any) => handleDeleteScheduleItem(scheduleItem)} style={{cursor: "pointer", transform: "scale(0.9)"}} />
                        :
                        <EditCostCode
                            action={"checkbox"}
                            costcode={undefined}
                            scheduleItem={scheduleItem}
                            formatter={formatter}
                            setValue={setCompleted}
                            textColor={getTextColor}
                        />}
                </TableCell>
                <TableCell onDoubleClick={(e:any) => {setEditTitleMode(true); setEditTitleOption(scheduleItem.scheduleId)}}
                           className='strikeCell'
                           style={{position: "sticky", left: 44, zIndex: 49, background: "white"}}
                           sx={{color: getTextColor(), ...useStyles.tableBodyTitleFieldStyle}}
                >
                    {   editTitleMode && editTitleOption === initScheduleItem.scheduleId
                        ?
                        <EditCostCode costcode={undefined} scheduleItem={scheduleItem} formatter={formatter} action={"title"} setValue={setTitle} textColor={getTextColor} exitEditTitleMode={exitEditTitleMode} />
                        :
                        <div style={{display: "flex"}}>
                            <p style={{
                                   display: "flex",
                                   width: "max-content",
                                   margin: 0,
                                   padding: 0,
                                   alignItems: "center"
                               }}>{titleCase(title)}</p>
                            <CostCodeMenu
                                variant={"schedule"}
                                handleDelete={() => handleDeleteScheduleItem(scheduleItem)}
                                handleRename={handleRename}
                            />
                        </div>
                    }
                </TableCell>

                {/* Notes Storage Icon Cell */}
                {columnView?.notesIcon &&
                    <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyStyle}}
                               style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", maxWidth: "40px"}}>
                        <Badge
                            style={{
                                transform: "scale(0.8)",
                                cursor: "pointer",
                                color: "#B6A9DA",
                                top: "-8px",
                                left: "15px"
                            }}
                            badgeContent={0}
                            onClick={() => {
                            }}
                        />
                        <img src={fileTextIcon} alt="Notes" title="Notes"/>
                    </TableCell>
                }

                {/* Files Icon Cell */}
                {columnView?.fileIcon &&
                    <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyStyle}}
                               style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", maxWidth: "40px"}}>
                    </TableCell>
                }

                {/* Photos Icon Cell */}
                {columnView?.photoIcon &&
                    <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyStyle}}
                               style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", maxWidth: "40px"}}>
                    </TableCell>
                }

                {/* Checklist Icon Cell */}
                {columnView?.checklistIcon &&
                    <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyStyle}}
                               style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", maxWidth: "40px"}}>
                    </TableCell>
                }

                {/* Comment Icon Cell */}
                {columnView.commentIcon &&
                    <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyStyle}}
                               style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", maxWidth: "40px"}}>
                    </TableCell>
                }

                {/* Assigned users Cell */}
                { columnView?.assignee &&
                    <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", width: "150px"}}>
                        <div style={{height: "33px", overflow: "hidden", display: "flex", alignContent: "center", color: getTextColor()}}>
                            {
                                assignedUsers.length > limitAssignee ?
                                    <span className='assignedUsersStyle'>{assignedUsers.length}</span>
                                    :
                                    null
                            }
                            <AddUser scheduleId={scheduleItem.scheduleId} users={users} assignedUsers={assignedUsers} setAssignedUsers={setAssignedUsers} />
                        </div>
                    </TableCell>
                }

                {/* Contractor Cell */}
                { columnView?.contractor &&
                    <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", paddingLeft: 3, paddingRight: 3}}>

                        <div style={{height: "33px", overflow: "hidden", display: "flex", alignContent: "center", color: getTextColor()}}>
                            <AddContractor scheduleId={scheduleItem.scheduleId} addressBookCompanies={contractors} assignedContractor={assignedContractor} setAssignedContractor={setAssignedContractor} />
                        </div>
                    </TableCell>
                }

                {/* Budget Cell */}
                { columnView?.budget &&
                    <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyBudgetFieldStyle}}/>
                }

                {/* Orders Cell */}
                { columnView?.order &&
                    <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)"}}/>
                }

                {/* Actuals / Billed Cell */}
                {columnView?.billed &&
                    <TableCell className='strikeCell' sx={useStyles.tableBodyActualFieldStyle}/>
                }

                {/* Running Budget Cell */}
                {columnView?.running &&
                    <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)"}}/>
                }

                {/* Difference Cell */}
                {/*{ columnView?.difference &&*/}
                { false &&
                    <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", color: "#E801DE"}}/>
                }

                {/* Start Date Cell */}
                { columnView?.startDate &&
                    <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyDateFieldStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)"}}>

                        <EditCostCode
                            action={"startDate"}
                            costcode={undefined}
                            scheduleItem={scheduleItem}
                            formatter={formatter}
                            dateRange={dateRangeValue}
                            setValue={setDateRangeValue}
                            textColor={getTextColor}
                        />

                    </TableCell>
                }

                {/* End Date Cell */}
                { columnView?.endDate &&
                    <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyDateFieldStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)"}}>

                        <EditCostCode
                            action={"endDate"}
                            costcode={undefined}
                            scheduleItem={scheduleItem}
                            formatter={formatter}
                            dateRange={dateRangeValue}
                            setValue={setDateRangeValue}
                            textColor={getTextColor}
                        />

                    </TableCell>
                }

                {/* Days / Duration Cell */}
                { columnView?.days &&
                    <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)"}}>
                        {calculateDays()}
                    </TableCell>
                }

                {/* Status Cell */}
                {columnView?.status &&
                    <TableCell sx={useStyles.tableBodyStatusFieldStyle}/>
                }
            </TableRow>
        </>
    )
}

export default ScheduleRow