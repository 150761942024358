import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as ExpandIcon } from "../../../Assets/expandTimeline.svg";
import { Project, ProjectCashflow } from "../../../Models/project";

type Props = {
  project: ProjectCashflow;
};

const ProjectCardTopAddress = ({ project }: Props) => {
  const navigate = useNavigate();
  const handleClickExpandIcon = (event: React.MouseEvent) => {
    event.stopPropagation();
    localStorage.setItem("currentProject", JSON.stringify(project));
    localStorage.setItem("currentProjectId", project.projectId);
    if (project.projectPrefix)
      sessionStorage.setItem("projectPrefix", project.projectPrefix);
    else if (project.siteAddress)
      sessionStorage.setItem(
        "projectPrefix",
        project.siteAddress.replace(/ /g, "").substring(0, 7).toUpperCase()
      );

    localStorage.setItem("currentProject", JSON.stringify(project));
    sessionStorage.setItem("defaultView", "timeline");
    navigate(`/Projects/${project.projectId}`);
  };
  return (
    <RootProjectCardTopAddress>
      <ProjectAddressContainer>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {project.siteAddress}{" "}
          <ProjectDetailsContainer>
            <div style={{ position: "absolute", left: 0, top: 0, maxWidth: "70%", textOverflow:"ellipsis", whiteSpace:"nowrap",overflow:"hidden" }}>
              {project.ownerName}
            </div>
            <div style={{ position: "absolute", right: 0, top: 0 }}>
              $ {parseInt(project.contractPrice.toString()).toLocaleString()}
            </div>
          </ProjectDetailsContainer>
        </div>
      </ProjectAddressContainer>

      <motion.div
        whileHover={{ zoom: 1.3 }}
        whileTap={{ filter: "blur(1px)" }}
        transition={{ duration: 0.4 }}
        style={{
          width: "27px",
          height: "27px",

          borderRadius: "10px",
          backgroundColor: "#EFF0F9",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={(event) => handleClickExpandIcon(event)}
      >
        <ExpandIcon />
      </motion.div>
    </RootProjectCardTopAddress>
  );
};

export default ProjectCardTopAddress;

const RootProjectCardTopAddress = styled(motion.div)`
  width: calc(100% - 30px);
  height: calc(10% - 10px);
  padding: 5px 15px 5px 15px;
  display: flex;
  flex-direction: row;
  justfy-content: center;
  align-items: center;
`;

const ProjectAddressContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justfy-content: center !important;
  align-items: flex-start;
  font-family: "Nostromo Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #353146;
`;

const ProjectDetailsContainer = styled.div`
  width: 100%;
  padding-top: 3px;
  display: flex;
  flex-direction: row;
  justfy-content: space-around;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  position: relative;
  color: #7657cb;
`;
