import React, { useState, useEffect, FormEvent } from "react";
import {ReactComponent as TrashIcon} from "../../../Assets/Trash2.svg";
import PreviewIcon from '@mui/icons-material/Preview';
import {
    ButtonProps,
    styled,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    InputAdornment
} from "@mui/material";
import CheckBox from "../../../utilities/CheckBox/CheckBox";
import {DrawingSet, Sheet} from "../../../Models/takeOffs";

interface props {
    open: boolean;
    closeAction: Function;
    saveAction: Function;
    drawingSet: DrawingSet;
    deleteSheet: Function;
    deleteDrawing: Function;
}


const DrawingSetDialog: React.FC<props> = (
    {open, closeAction, saveAction, drawingSet, deleteSheet, deleteDrawing}
) => {
    const [deleteSheetId, setDeleteSheetId] = useState<string|undefined>(undefined);
    const [deleteDrawingId, setDeleteDrawingId] = useState<string|undefined>(undefined);
    const [drawingSetTitle, setDrawingSetTitle] = useState<string>("");
    const [sheets, setSheets] = useState<Sheet[]>(drawingSet.sheets);
    const [previewSheet, setPreviewSheet] = useState<Sheet|null>(null);
    const [mouse, setMouse] = useState<any>(null);
    const handleClose = () => {
        closeAction()
    };

    const handleValueChange = (e: any) => {
        if (!e?.target?.value) { console.log("inputValue undefined"); return; }
        setDrawingSetTitle(e.target.value);
    };

    const handleSaveBtnClick = (e: any) =>{
        saveAction(drawingSet.setId, drawingSetTitle, sheets)
    }

    const getPositionValues = () =>{
        let values = {
            height: 0,
            x: 0,
            y: 1
        }
        if(!previewSheet || !mouse) return values
        const width = window.innerWidth- mouse.pageX - 50;
        console.log("width", width)
        if (width > 300)
            return {
                height: width / parseInt(previewSheet.width) * parseInt(previewSheet.height),
                x: mouse.pageX + 25,
                y:  mouse.pageY - width / parseInt(previewSheet.width) * parseInt(previewSheet.height) / 2
            }
        else
            return {
                height: 500 / parseInt(previewSheet.width) * parseInt(previewSheet.height),
                x: window.innerWidth / 2 - 250,
                y: window.innerHeight / 2 - 250
            }
    }

    const handleDelete = (deleteMeasures: boolean) => {
        if ((deleteSheetId && deleteDrawingId) || (!deleteSheetId && !deleteDrawingId)) return;
        if (deleteSheetId) deleteSheet(deleteSheetId, deleteMeasures)
        if (deleteDrawingId) deleteDrawing(deleteDrawingId, deleteMeasures)
        setDeleteDrawingId(undefined)
        setDeleteSheetId(undefined)
    }

    const handleSheetTitleChange = (e:any, i:number) => {
        let updatedSheet : Sheet = sheets[i];
        updatedSheet.title = e.target.value;
        let newSheets: Sheet[] = sheets;
        newSheets.splice(i, 1, updatedSheet)
        setSheets([...newSheets]);
    }

    const CancelButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText("#F8F8F8"),
        backgroundColor: "#F8F8F8",
        marginLeft:"66px",
        minWidth:"93px",
        borderRadius: "20px",
        textTransform: "none",
        fontStyle: "normal",
        fontWeight: "normal",
        fontFamily:"Inter",
        fontSize: "11px",
        '&:hover': {
            backgroundColor: "#F8F8F8",
        }
    }));


    const AddButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText("#0044CC"),
        backgroundColor: "#0044CC",
        marginLeft:"66px",
        minWidth:"93px",
        borderRadius: "20px",
        textTransform: "none",
        fontStyle: "normal",
        fontWeight: "normal",
        fontFamily:"Inter",
        fontSize: "11px",
        '&:hover': {
            backgroundColor: "#0044CC",
        },
    }));


    useEffect(() => {
        setSheets(drawingSet.sheets);
        setDrawingSetTitle(drawingSet.name);
    }, [drawingSet])

    useEffect(() => {
        setPreviewSheet(null);
    }, [open])

    return(
        <React.Fragment>
            {!previewSheet ? null :
                <img
                    src={previewSheet.url}
                    style={{
                        position: "absolute",
                        left: getPositionValues().x+"px",
                        top: getPositionValues().y+"px",
                        zIndex: "9999"
                    }}
                    height={getPositionValues().height+"px"}
                    onClick={() => setPreviewSheet(null)}
                />
            }
            {/* Rename Dialog*/}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Upload Drawings</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Update Drawing Set Name
                    </DialogContentText>
                    <TextField
                        value={drawingSetTitle}
                        autoFocus
                        margin="dense"
                        id="dialogInput"
                        fullWidth
                        variant="standard"
                        onChange={(event: any) => {handleValueChange(event)}}
                        onKeyUp={(event:any) => {if (event.keyCode && event.keyCode === 13){handleSaveBtnClick(event) }}}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <TrashIcon
                                        style={{ cursor:"pointer"}}
                                        onClick={()=>setDeleteDrawingId(drawingSet.setId)}
                                    />
                                </InputAdornment>,
                        }}
                    />
                    <DialogContentText>
                        Update Sheet Names
                    </DialogContentText>
                    {sheets.map((sheet: Sheet, i:number) =>(
                        <TextField
                            key={sheet.sheetId}
                            value={sheets?.[i]?.title || ""}
                            margin="dense"
                            fullWidth
                            variant="standard"
                            label={"Page "+(i+1)+" Title"}
                            onChange={(event: any) => {handleSheetTitleChange(event, i)}}
                            onKeyUp={(event:any) => {if (event.keyCode && event.keyCode === 13){handleSaveBtnClick(event) }}}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <PreviewIcon
                                            style={{color:"#6d4dc5", cursor:"pointer"}}
                                            onClick={(e: React.MouseEvent<SVGSVGElement>)=>{
                                                console.log(e)
                                                previewSheet?.sheetId !== sheet.sheetId
                                                    ? setPreviewSheet({...sheet})
                                                    : setPreviewSheet(null);
                                                setMouse(e);
                                            }}
                                        />
                                        <TrashIcon
                                            style={{ cursor:"pointer"}}
                                            onClick={()=>setDeleteSheetId(sheet.sheetId)}
                                        />
                                    </InputAdornment>,
                            }}
                        />
                    ))

                    }
                </DialogContent>
                <DialogActions>
                    <AddButton onClick={(event:any) => handleSaveBtnClick(event)}>Upload</AddButton>
                    <CancelButton onClick={handleClose}>Cancel</CancelButton>
                </DialogActions>
            </Dialog>
            {/* Delete Dialog */}
            <Dialog
                disableEscapeKeyDown
                open={deleteSheetId !== undefined || deleteDrawingId !== undefined }
                onClose={(e:any, reason) => {
                    if (reason !==  "backdropClick") {
                        setDeleteDrawingId(undefined);
                        setDeleteSheetId(undefined);
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Delete Measurements?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you wish to delete all measurements on the deleted sheet(s)?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDeleteDrawingId(undefined); setDeleteSheetId(undefined); }}>Cancel</Button>
                    <Button onClick={() => handleDelete(false)}>No</Button>
                    <Button onClick={() => handleDelete(true)} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default DrawingSetDialog