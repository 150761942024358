import axios from "axios";
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";
import {
  Stage,
  CreateStage,
  EditStage,
  StageWithCostCode,
} from "../../Models/stage";
import { ProjectCashflow } from "../../Models/project";

const getStageList = async (projectId: string) => {
  let result: Stage[] = [];
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }

  try {
    const response = await axios.get(baseAddress + "/stage/", {
      headers: { Authorization: "Bearer " + accessToken },
      params: { project: projectId },
    });
    result =
      response.status === 200 ? (response.data.results as Array<Stage>) : [];
    console.log("STAGES", result);
  } catch (error) {
    console.error("ERROR FETCHING FILES", error);
  }
  return result;
};

const getStageListWithCostCodes = async (projectId: string) => {
  let result: Stage[] = [];
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }

  try {
    const response = await axios.get(
      baseAddress + "/stage/costcodes/",
      {
        headers: { Authorization: "Bearer " + accessToken },
        params: { project: projectId },
      }
    );
    result =
      response.status === 200
        ? (response.data.results as Array<StageWithCostCode>)
        : [];
    console.log("STAGES", result);
  } catch (error) {
    console.error("ERROR FETCHING FILES", error);
  }
  return result;
};
interface GetParams {
  company?: string;
}
const getCashflowProject = async (companyId: string) => {
  let result: ProjectCashflow[] = [];
  if (offline) return result;
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }

  try {
    let getParams: GetParams = { company: companyId };

    const response = await axios.get(
      baseAddress + "/stage/cashflow/",
      {
        headers: { Authorization: "Bearer " + accessToken },
        params: getParams,
      }
    );
    // console.log("RESPONSE SERVER:", response.data);
    result =
      response.status === 200 ? (response.data as ProjectCashflow[]) : [];
  } catch (error) {
    console.log("ERROR FETCHING PROJECTS", error);
  }

  return result;
};
const createStage = async (stage: CreateStage) => {
  let result: Stage | undefined = undefined;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }

  try {
    const response = await axios.post(
      baseAddress + "/stage/",
      stage,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.status === 201 ? (response.data as Stage) : undefined;
    console.log("ADDED FOLDER", result);
  } catch (error) {
    console.error("ERROR CREATING FOLDER", error);
  }
  return result;
};

const updateStage = async (stageId: string, stage: EditStage) => {
  let result: Stage | undefined = undefined;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    const response = await axios.patch(
      baseAddress + "/stage/" + stageId + "/",
      stage,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.data as Stage;
    console.log("updateFolder", result);
  } catch (error) {
    console.error("ERROR UPDATING STAGE", error);
  }
  return result;
};

const removeStage = async (stageId: string) => {
  let result: boolean = false;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    const response = await axios.delete(
      baseAddress + "/stage/" + stageId + "/",
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.status === 204;
    console.log("updateFolder", result);
  } catch (error) {
    console.error("ERROR DELETING STAGE", error);
  }
  return result;
};

interface updateResult {
  stageResponse: Stage[];
  errors: any[];
}
const updateMultipleStages = async (stages: Stage[]) => {
  let result: updateResult = { stageResponse: [], errors: [] };
  if (offline) {
    return { stageResponse: stages, errors: [] };
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    const response = await axios.post(
      baseAddress + "/stage/update/",
      stages,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.data as updateResult;
    console.log("updateFolder", result);
  } catch (error) {
    console.error("ERROR DELETING STAGE", error);
  }
  return result;
};

const stageAPI = {
  getStageList,
  getStageListWithCostCodes,
  createStage,
  updateStage,
  removeStage,
  updateMultipleStages,
  getCashflowProject,
};

export default stageAPI;
