import { Button } from "@mui/material";
import React from "react";

interface props{
    email: string | undefined;
    modal: boolean;
}

const ResetPassword: React.FC<props> = ({
    email,
    modal
}) => {

    const handleResetPassword = () =>{
        if(email){
            console.log("reset password for "+email)
        }
    }

    return(
        <div>
            <div 
            // style={{
            //     display: modal ? "none": "block",
            //     textAlign:"center",
            //     paddingTop:"9px",
            //     fontStyle: "normal",
            //     fontWeight: "normal",
            //     fontFamily:"Inter",
            //     fontSize: "8px",
            //     color: "#96A2BE",
            // }}
            className="forgotPassword"
            onClick={(event:any) => (handleResetPassword())}>
                Forgot Password?
            </div>
        </div>   
    )
}
export default ResetPassword