import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Notice } from "../../../../Models/notice";
import {
  convertDateAndTime,
  distributeNoticeWithStartTime,
  getDateRangeNotice,
  getTimeRangeNotice,
  INoticeAxisPos,
  ITimeRange,
  IUserWithTask,
  populateTimeRow,
} from "../CommonResources";
import TeamViewNoticeItem, {
  convertColorValueByNoticeStatus,
} from "./TeamViewNoticeItem";
import { ReactComponent as VisibleIcon } from "../../../../Assets/visibleIcon.svg";
import { ReactComponent as InvisibleIcon } from "../../../../Assets/invisibleIcon.svg";

import { AnimatePresence, motion } from "framer-motion";
import NoticeListPopoverDetail from "./NoticeListPopoverDetail";
import ExpandedNoticeDetail from "./ExpandedNoticeDetail";
import NoticeAPI from "../../../../Services/DoxleAPI/noticeAPI";
import { User } from "../../../../Models/user";
type Props = {
  noticeRowData: IUserWithTask;
  pageTimeRange: ITimeRange;
  rowSize: number;
  notices: Notice[];
  setnotices: Function;
  currentMousePosX: number | undefined;
  setcurrentMousePosX: Function;
  boundLeft: number;
  setCurrentUserRow: Function;
  currentUserRow: User | undefined;
  currentDraggedNotice: Notice | undefined;
  setCurrentDraggedNotice: Function;
  viewType: "daily" | "weekly";
  showSideTimeList: boolean;
  setshowSideTimeList: Function;
  timeValueSelectedFromSideList: number | undefined;
  settimeValueSelectedFromSideList: Function;
};

const TeamViewNoticeDataRow = ({
  noticeRowData,
  pageTimeRange,
  rowSize,
  notices,
  setnotices,
  currentMousePosX,
  setcurrentMousePosX,
  boundLeft,
  setCurrentUserRow,
  currentUserRow,
  currentDraggedNotice,
  setCurrentDraggedNotice,
  viewType,
  showSideTimeList,
  setshowSideTimeList,
  timeValueSelectedFromSideList,
  settimeValueSelectedFromSideList,
}: Props) => {
  // if (noticeRowData.notice.length > 0)
  //   console.log(
  //     "NOTICE OF ",
  //     noticeRowData.user.firstName,
  //     ":",
  //     noticeRowData.notice
  //   );
  const widthOfCell: number =
    (1 / (pageTimeRange.end - pageTimeRange.start + 1)) * rowSize;
  const [currentMenuCell, setcurrentMenuCell] = useState<number | undefined>(
    undefined
  );
  const [currentSelectedNotice, setCurrentSelectedNotice] = useState<
    Notice | undefined
  >(undefined);

  const [selectedTimeValueCell, setSelectedTimeValueCell] = useState<
    number | undefined
  >(undefined);
  //this useEEfeect is to reset all states triggering edit mode when user click finish editing
  useEffect(() => {
    setcurrentMenuCell(undefined);
    setCurrentSelectedNotice(undefined);
  }, [currentUserRow]);

  const handleClickVisible = (timeIndex: number) => {
    if (currentMenuCell !== timeIndex || !currentMenuCell) {
      setcurrentMenuCell(timeIndex);
      setcurrentNoticeListUserRow(noticeRowData.user);
    } else setcurrentMenuCell(undefined);
  };

  //control where the notice list details will slide up or slide down based on the top edge of the notice cell container to top of the screen
  const [noticeRowAxisPos, setnoticeRowAxisPos] = useState<
    INoticeAxisPos | undefined
  >(undefined);
  const rowRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (rowRef)
      setnoticeRowAxisPos({
        boundLeft: rowRef.current?.getBoundingClientRect().left as number,
        boundRight: rowRef.current?.getBoundingClientRect().right as number,
        width: rowRef.current?.getBoundingClientRect().width as number,
        height: rowRef.current?.getBoundingClientRect().height as number,
        boundTop: rowRef.current?.getBoundingClientRect().top as number,
      });
  }, [rowRef]);

  //this state is to control the zIndex to handle notice list details slide up to avoid overlap
  const [currentNoticeListUserRow, setcurrentNoticeListUserRow] = useState<
    User | undefined
  >(undefined);
  return (
    <Box
      ref={rowRef}
      className={"row-timetable-data-teamview"}
      sx={{
        justifyContent: "center",
        position: "relative",
        zIndex: currentNoticeListUserRow
          ? currentNoticeListUserRow.userId === noticeRowData.user.userId
            ? 100
            : -10
          : undefined,
        border:
          currentDraggedNotice &&
          currentUserRow &&
          currentUserRow.userId === noticeRowData.user.userId
            ? "0.5px solid #7949FF"
            : undefined,
        borderLeft:
          currentDraggedNotice &&
          currentUserRow &&
          currentUserRow.userId === noticeRowData.user.userId
            ? "none"
            : undefined,
        borderRight:
          currentDraggedNotice &&
          currentUserRow &&
          currentUserRow.userId === noticeRowData.user.userId
            ? "none"
            : undefined,
      }}
    >
      {currentDraggedNotice &&
      currentUserRow &&
      currentUserRow.userId === noticeRowData.user.userId ? (
        <TeamViewNoticeItem
          notice={currentDraggedNotice}
          taskTimeRange={
            viewType === "daily"
              ? getTimeRangeNotice(currentDraggedNotice, pageTimeRange)
              : getDateRangeNotice(currentDraggedNotice, pageTimeRange)
          }
          setCurrentUserRow={setCurrentUserRow}
          pageTimeRange={pageTimeRange}
          rowSize={rowSize}
          notices={notices}
          setnotices={setnotices}
          setcurrentMousePosX={setcurrentMousePosX}
          boundLeft={boundLeft}
          setCurrentDraggedNotice={setCurrentDraggedNotice}
          action="editGroup"
          assignedUser={noticeRowData.user}
          viewType={viewType}
          showSideTimeList={showSideTimeList}
          setshowSideTimeList={setshowSideTimeList}
          timeValueSelectedFromSideList={timeValueSelectedFromSideList}
          settimeValueSelectedFromSideList={settimeValueSelectedFromSideList}
        />
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            zIndex: 1,
          }}
        >
          {populateTimeRow(pageTimeRange).map((timeValue, timeIndex) => {
            let noticesOfCell: Notice[] =
              viewType === "daily"
                ? noticeRowData.notice.filter(
                    (notice) =>
                      timeValue ===
                      getTimeRangeNotice(notice, pageTimeRange).start
                  )
                : noticeRowData.notice.filter(
                    (notice) =>
                      timeValue ===
                      getDateRangeNotice(notice, pageTimeRange).start
                  );

            return (
              <Box
                key={`${timeIndex}#${timeValue}`}
                sx={{
                  height: "calc(100% - 20px)",
                  width: `${widthOfCell - 20}px`,
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: selectedTimeValueCell
                    ? selectedTimeValueCell === timeValue
                      ? 1
                      : 0
                    : undefined,
                  filter: selectedTimeValueCell
                    ? selectedTimeValueCell === timeValue
                      ? undefined
                      : "blur(5px) brightness(0.3)"
                    : undefined,
                }}
              >
                {noticesOfCell.length > 1 ? (
                  <Box
                    sx={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      cursor: "pointer",
                    }}
                  >
                    {/**Notice count display */}
                    <Box
                      sx={{
                        position: "absolute",
                        top: -7,
                        left: -1,
                        width: "20px",
                        height: "20px",
                        backgroundColor: "white !important",
                        fontFamily: "Nostromo Regular",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "18px",
                        color: "#855DF8",
                        zIndex: 3,
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        border: "0.5px dashed #855DF8",
                      }}
                    >
                      {noticesOfCell.length}
                    </Box>

                    {noticesOfCell.map((notice, index) => {
                      if (index < 3)
                        return (
                          <Box
                            key={`${notice.noticeId}#${index}`}
                            sx={{
                              position: "absolute",
                              top: 0,
                              height: "calc(100% - 10px)",
                              width:
                                index === noticesOfCell.length - 1
                                  ? `calc(100% - ${index * 30}px - 40px)`
                                  : `calc(100% - ${index * 30}px - 10px)`,
                              left: `${index * 30}px`,
                              borderRadius: "28px",
                              backgroundColor: convertColorValueByNoticeStatus(
                                notice.status
                              ),
                              padding: `5px ${
                                index === noticesOfCell.length - 1
                                  ? "35px"
                                  : "5px"
                              } 5px 5px`,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              borderLeft: "1px solid white",
                              zIndex: 0,
                            }}
                          >
                            <Typography
                              className="notice-list-description-text-style"
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {notice.description}
                            </Typography>
                          </Box>
                        );
                    })}

                    {noticesOfCell.length > 0 ? (
                      <motion.div
                        whileHover={{ scale: 1.4 }}
                        whileTap={{ opacity: 0.4 }}
                        transition={{ duration: 0.1 }}
                        style={{
                          position: "absolute",
                          top: "calc(50% - 9px)",
                          right: "5px",
                          cursor: "pointer",
                          zIndex: 3,
                        }}
                        onClick={() => handleClickVisible(timeIndex)}
                      >
                        {currentMenuCell === timeIndex ? (
                          <InvisibleIcon />
                        ) : (
                          <VisibleIcon />
                        )}
                      </motion.div>
                    ) : null}

                    {/*Notice list detail slide up */}
                    <AnimatePresence>
                      {currentMenuCell === timeIndex &&
                      noticeRowAxisPos &&
                      noticesOfCell.length > 0 ? (
                        <motion.div
                          // initial={
                          //   noticeRowAxisPos.boundTop > 360
                          //     ? { y: 0 }
                          //     : { y: -350 }
                          // }
                          animate={{ scaleY: [0, 1] }}
                          exit={{ scaleY: [1, 0], opacity: [0.6, 0] }}
                          transition={{ duration: 0.2 }}
                          style={{
                            position: "absolute",
                            top:
                              noticeRowAxisPos.boundTop > 360
                                ? -360
                                : "calc(100% + 10px)",
                            left: `calc(${(-300 + widthOfCell) / 2}px - 10px)`,
                            height: "330px",
                            width: "280px",
                            zIndex: 10,
                            backgroundColor: "rgba(133, 93, 248, 0.9)",
                            borderRadius: "14px",
                            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.8)",
                            padding: "10px",
                          }}
                        >
                          <NoticeListPopoverDetail
                            notices={noticesOfCell}
                            user={noticeRowData.user}
                            timeValue={timeValue}
                            setcurrentMenuCell={setcurrentMenuCell}
                            currentSelectedNotice={currentSelectedNotice}
                            setCurrentSelectedNotice={setCurrentSelectedNotice}
                            setCurrentUserRow={setCurrentUserRow}
                            setCurrentDraggedNotice={setCurrentDraggedNotice}
                          />
                        </motion.div>
                      ) : null}
                    </AnimatePresence>

                    {/* Selected Notice Details*/}
                    <AnimatePresence>
                      {currentSelectedNotice &&
                      noticeRowAxisPos &&
                      currentMenuCell === timeIndex ? (
                        <motion.div
                          initial={{ x: -200 }}
                          animate={{ x: [-200, 0] }}
                          exit={{ x: [0, -200], opacity: [0.6, 0] }}
                          transition={{ duration: 0.2 }}
                          style={{
                            position: "absolute",
                            top:
                              noticeRowAxisPos.boundTop > 360
                                ? -325
                                : `calc(100% + 25px)`,
                            // noticeRowAxisPos.boundTop > 360 ? -360 : "100%",
                            left: `calc(${
                              (-300 + widthOfCell) / 2
                            }px - 10px + 300px)`,
                            height: "280px",
                            width: "290px",
                            zIndex: 998,
                            backgroundColor: convertColorValueByNoticeStatus(
                              currentSelectedNotice.status
                            ),
                            borderRadius: "14px",
                            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
                            padding: "10px 0 10px 10px",
                          }}
                        >
                          <ExpandedNoticeDetail
                            notice={currentSelectedNotice}
                          />
                        </motion.div>
                      ) : null}
                    </AnimatePresence>
                  </Box>
                ) : noticesOfCell.length === 1 ? (
                  <TeamViewNoticeItem
                    notice={noticesOfCell[0]}
                    taskTimeRange={
                      viewType === "daily"
                        ? getTimeRangeNotice(
                            noticeRowData.notice[0],
                            pageTimeRange
                          )
                        : getDateRangeNotice(
                            noticeRowData.notice[0],
                            pageTimeRange
                          )
                    }
                    setCurrentUserRow={setCurrentUserRow}
                    pageTimeRange={pageTimeRange}
                    rowSize={rowSize}
                    notices={notices}
                    setnotices={setnotices}
                    setcurrentMousePosX={setcurrentMousePosX}
                    boundLeft={boundLeft}
                    setCurrentDraggedNotice={setCurrentDraggedNotice}
                    action="editSingle"
                    setSelectedTimeValueCell={setSelectedTimeValueCell}
                    assignedUser={noticeRowData.user}
                    viewType={viewType}
                    showSideTimeList={showSideTimeList}
                    setshowSideTimeList={setshowSideTimeList}
                    timeValueSelectedFromSideList={
                      timeValueSelectedFromSideList
                    }
                    settimeValueSelectedFromSideList={
                      settimeValueSelectedFromSideList
                    }
                  />
                ) : null}
              </Box>
            );
          })}
        </div>
      )}
    </Box>
  );
};

export default React.memo(TeamViewNoticeDataRow);
