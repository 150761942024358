import React from "react";
import "../Menu/filterNavigation.css";
import { IconButton, Menu, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as MoreIcon } from "../../Assets/MoreVert.svg";

interface props {
  variant: "costCode" | "schedule";
  handleDelete: () => void;
  handleRename: () => void;
  income?: boolean;
  stage?: string;
  toggleIncome?: () => void;
  updateStage?: (stage: string) => void;
  handleMarkComplete?: Function;
  complete?: boolean;
}

const CostCodeMenu: React.FC<props> = ({
  variant,
  handleDelete,
  handleRename,
  income,
  stage,
  toggleIncome,
  updateStage,
  handleMarkComplete,
  complete,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action: string) => {
    if (action === "delete") handleDelete();
    else if (action === "rename") handleRename();
    else if (action === "income" && toggleIncome) toggleIncome();
    else if (action === "complete") {
      if (handleMarkComplete) handleMarkComplete();
    } else if (updateStage) updateStage(action);

    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        sx={{
          width: "24px",
          height: "24px",
          m: "-2px 3px 0 3px",
          p: 0,
          "&:hover": { background: "none" },
          position: "relative",
        }}
        size="large"
        aria-label="account of current user"
        aria-controls="menu-costcode"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="menu-costcode"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiList-root": {
            p: 0,
          },
          "& .MuiMenu-paper": {
            minWidth: "185px",
          },
          "& .MuiMenuItem-root": {
            fontFamily: "Roboto Mono",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "16px",
            alignItems: "center",
          },
        }}
      >
        <MenuItem
          key={"close"}
          disableRipple
          sx={{
            justifyContent: "end",
            height: "35px",
            paddingRight: "6px",
            "&:hover": { background: "none" },
          }}
        >
          <IconButton
            sx={{
              width: "21px",
              height: "21px",
              m: 0,
              p: 0,
              cursor: "pointer",
              borderRadius: 1,
              background: "#F5F6F7",
            }}
            size="small"
            onClick={handleClose}
          >
            <CloseIcon sx={{ transform: "scale(0.8)" }} />
          </IconButton>
        </MenuItem>

        <MenuItem
          onClick={handleClose}
          key={"MenuHeader"}
          sx={{ color: "#754EE0" }}
        >
          {variant === "costCode" ? "ACCOUNT MENU" : "SCHEDULE MENU"}
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("rename")} key={"rename"}>
          {variant === "costCode" ? "Rename Account" : "Rename Schedule Item"}
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("delete")} key={"delete"}>
          {variant === "costCode" ? "Delete Account" : "Delete Schedule Item"}
        </MenuItem>
        {variant === "costCode" && (
          <MenuItem
            onClick={() => handleMenuItemClick("complete")}
            key={"complete"}
          >
            {complete
              ? complete === true
                ? "Mark Uncompleted"
                : "Mark Completed"
              : "Mark Completed"}
          </MenuItem>
        )}
        {variant === "costCode" && (
          <MenuItem
            onClick={() => handleMenuItemClick("income")}
            key={"income"}
          >
            {income !== undefined
              ? "Change to cost account"
              : "Change to income account"}
          </MenuItem>
        )}
        {variant === "costCode" && (
          <MenuItem
            onClick={handleClose}
            key={"StageHeader"}
            sx={{ color: "#754EE0" }}
          >
            CHANGE COSTCODE STAGE
          </MenuItem>
        )}
        {variant === "costCode" && stage && stage !== "D" && (
          <MenuItem onClick={() => handleMenuItemClick("D")} key={"stageD"}>
            Change to Deposit
          </MenuItem>
        )}
        {variant === "costCode" && stage && stage !== "B" && (
          <MenuItem onClick={() => handleMenuItemClick("B")} key={"stageB"}>
            Change to Base
          </MenuItem>
        )}
        {variant === "costCode" && stage && stage !== "FR" && (
          <MenuItem onClick={() => handleMenuItemClick("FR")} key={"stageFR"}>
            Change to Frame
          </MenuItem>
        )}
        {variant === "costCode" && stage && stage !== "LU" && (
          <MenuItem onClick={() => handleMenuItemClick("LU")} key={"stageLU"}>
            Change to Lock Up
          </MenuItem>
        )}
        {variant === "costCode" && stage && stage !== "FX" && (
          <MenuItem onClick={() => handleMenuItemClick("FX")} key={"stageFX"}>
            Change to Fixing
          </MenuItem>
        )}
        {variant === "costCode" && stage && stage !== "FN" && (
          <MenuItem onClick={() => handleMenuItemClick("FN")} key={"stageFN"}>
            Change to Final
          </MenuItem>
        )}
        {variant === "costCode" && stage && stage !== "DV" && (
          <MenuItem onClick={() => handleMenuItemClick("DV")} key={"stageDV"}>
            Change to Developer
          </MenuItem>
        )}
        {variant === "costCode" && stage && stage !== "V" && (
          <MenuItem onClick={() => handleMenuItemClick("V")} key={"stageV"}>
            Change to Variation
          </MenuItem>
        )}
        {/*<MenuItem*/}
        {/*    key={"plain"}*/}
        {/*    onClick={handleMenuItemClick}*/}
        {/*    style={{*/}
        {/*        fontFamily: "Roboto",*/}
        {/*        fontStyle: "normal",*/}
        {/*        fontWeight: "400",*/}
        {/*        fontSize: "10px",*/}
        {/*        lineHeight: "12px",*/}
        {/*        color: "#B0BBD4",*/}
        {/*        paddingLeft: "39px",*/}
        {/*    }}*/}
        {/*>*/}
        {/*    Menu plain*/}
        {/*</MenuItem>*/}

        <MenuItem
          key={"footer"}
          onClick={handleClose}
          style={{
            marginTop: "20px",
            background: "#F1F2F5",
            justifyContent: "center",
            color: "#754EE0",
            height: "35px",
          }}
        >
          Costcode Menu
        </MenuItem>
      </Menu>
    </>
  );
};
export default React.memo(CostCodeMenu);
