import {Grid, Box, Typography, Collapse, IconButton, TableRow, TableCell } from "@mui/material";
import React, {DragEvent, useEffect, useState} from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Image } from "../../Models/image";
import { Specification } from "../../Models/specification";
import CustomCheckbox from "../CheckBox/CheckBox";
import Files from "../../components/Content/Files/Files";
import Emails from "../../components/Content/Emails/Emails";
import { DropzoneArea } from 'material-ui-dropzone';
import SpecificationAPI from "../../Services/DoxleAPI/specificationAPI";
import EditSpecification from "../../components/Content/Specifications/EditSpecification";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutlined';
import {authContextInterface, useAuth} from "../../components/Providers/AuthProvider";
import ScaleBar from "../../components/Content/Measurements/ScaleBar";
import {FileData} from "../../Models/storage";
import ImageGallery from "react-image-gallery";
import invoiceIcon from "../../Assets/invoiceQuotes.svg";
interface props{
    action: string;
    row: any;
    index: number;
    costcode?: any;
    editMode?: string,
    editIconClicked?: boolean,
    setEditMode?: Function,
    openSpecsStatus?: boolean,
    handleCheckBox?: Function,
    setSpecTitle?: Function,
    setSpecDescription?: Function
    handleSaveButton?: Function
    tickedSpecs?: string[]
}

const CustomCollapsibleTable: React.FC<props> = ({
    action,
    row,
    index,
    costcode,
    editMode,
    editIconClicked,
    openSpecsStatus,
    setEditMode,
    handleCheckBox,
    setSpecTitle,
    setSpecDescription,
    handleSaveButton,
    tickedSpecs
})=>{
    const [openQuotes, setOpenQuotes] = useState(false);
    const [currentlyDragging, setCurrentlyDragging] = useState<boolean>(false);
    const [currentlyUploading, setCurrentlyUploading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [images, setImages] = useState<Image[]>(row.images || []);
    const [openSpecs, setOpenSpecs] = useState(false);
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn } = authContext;

    const leadingZeros = (num: number, places: number) => {
        const numZeroes = places - num.toString().length + 1;
        if (numZeroes > 0) {
          return Array(+numZeroes).join("0") + num;
        }
        return num
      }

    const getNumberValue = (input: string | undefined) =>{

        if(input){
            ///^[[\d]+[\.]?[\d]*]*\s/gm
            let numberRegex = /^[[\d]+[\.]?[\d]*]*\s/gm
            let extractedNumberValue = input.match(numberRegex)?.join("")

            if(extractedNumberValue){
                let numValue = extractedNumberValue.replace(" ","."+leadingZeros(index,3))
                return numValue
            }
        }
    }

    const handleFileDrop = async (newFiles:Blob[], rowId:string) => {
        try {
            if (newFiles.length === 0) {
                return
            }
            setCurrentlyUploading(true);
            setCurrentlyDragging(false);
            const response = await SpecificationAPI.addSpecImages(rowId, newFiles, setProgress);
            console.log(response)
            if (response) {
                if (images && images.length > 0) {
                    setImages([...images, ...response.images])
                } else {
                    setImages([...response.images])
                }

            }
            setCurrentlyUploading(false);
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setCurrentlyDragging(true);
    };

    const handleDragExit = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setCurrentlyDragging(false);
    };

    const handleQuotesRowClick = (event: any) => {
        if(event.target.type === undefined){
            setOpenQuotes(!openQuotes)
        }
    }

    const mapImages = (images: Image[]) => {
        return images.map((image: Image) => ({
            original: image.url,
            thumbnail: image.url,
            description: image.imageId
        }));
    }

    const handleSpecRowClick = (event: any) => {
        if(event.target.type === undefined){
            handleArrowIcon()
        }
    }

    const handleArrowIcon = () => {
        if(setOpenSpecs && setEditMode){
            setOpenSpecs(!openSpecs)
            setEditMode(undefined)   
        }
    }

    useEffect(() => {
        try {
            let images: Image[] = row.images
            if (images && images.length > 0) {
                setImages(images)
            }
        } catch (e) {}
    }, [row]); //pass dependencies

    const handleDeleteImage = async (item: any) => {
        console.log(item)
        try {
            const response = await SpecificationAPI.removeSpecImage(item.description);
            if(response){
                let newImages: Image[] = []
                images.forEach((img: Image) => {
                    if (img.imageId !== item.description) {
                        newImages.push(img)
                    }
                });
                setImages([...newImages])
            }
        } catch(err){
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }
        return (
            <>
                {action === "specs" && costcode && row
              ?
                <React.Fragment>
                    <TableRow key={0}
                        onDragLeave={handleDragExit}
                        onClick={(event: any) => handleSpecRowClick(event)}
                    >
                        <TableCell sx={{
                            width:"10px",
                            py:0
                        }}>
                            <EditSpecification 
                                action="checkbox" 
                                row={row}
                                openSpecs={openSpecsStatus}
                                editMode={editMode}
                                setEditMode={setEditMode}
                                editIconClicked={editIconClicked}
                                handleCheckBox={handleCheckBox}
                                tickedSpecs={tickedSpecs ? tickedSpecs : []}
                            />
                        </TableCell>
                        <TableCell
                            sx={{
                                fontFamily:'Roboto Mono',
                                fontStyle:'normal',
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#000000",
                                width:"90px",
                                padding:0,
                                overflowWrap: "normal"
                            }}
                        >
                           {getNumberValue(costcode.title)}
                        </TableCell>
                        <TableCell
                            component="th"
                            scope="row"
                            sx={{
                                fontFamily:'Roboto Mono',
                                fontStyle:'normal',
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#000000",
                                py:0
                            }}
                        >
                            <EditSpecification
                                action="description"
                                row={row}
                                setSpecDescription={setSpecDescription}
                                openSpecs={openSpecsStatus}
                                editMode={editMode}
                                setEditMode={setEditMode}
                                editIconClicked={editIconClicked}
                                handleCheckBox={handleCheckBox}
                                handleSaveButton={handleSaveButton}
                                tickedSpecs={tickedSpecs ? tickedSpecs : []}
                                />
                        </TableCell>
                        <TableCell sx={{
                            width:"50px",
                            py:0
                        }}>
                            <div style={{
                                display:"flex",
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent: "end"
                            }}>
                                <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleArrowIcon()}
                                sx={{
                                    // color: "#0044CC",
                                    textAlign: "right",
                                    transform: "scale(0.6)"
                                }}
                                >
                                {openSpecs ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </div>
                            
                        </TableCell>
                    </TableRow>
                    <TableRow key={1}

                    >
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                            <Collapse in={openSpecs} timeout="auto" unmountOnExit >
                            <Box sx={{ margin: 1 }}
                                onDragEnter={handleDragEnter}
                                onDragExit={handleDragExit}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={1.2}
                                    style={{
                                        background: "#FFFFFF",
                                        borderRadius: "4px",
                                        // padding: "10px",
                                        marginBottom: "20px",
                                    }}
                                >
                                    <Grid item xs={2}/>
                                    <Grid item xs={8}>
                                        {
                                            images && images.length >0
                                                ? <ImageGallery
                                                    items={mapImages(images)}
                                                    thumbnailPosition={"right"}
                                                    showFullscreenButton={false}
                                                    showPlayButton={false}
                                                    renderThumbInner={(item) => (
                                                        <div>
                                                            {editMode
                                                                ?
                                                                <div
                                                                    style={{
                                                                        position: "fixed",
                                                                        width: "100%",
                                                                        right: "Calc(18px - 50%)"
                                                                    }}
                                                                >
                                                                    <DeleteOutlineIcon
                                                                        onClick={()=>(handleDeleteImage(item))}
                                                                        style={{color: "#0044cc", transform: "scale(0.8)", cursor:"pointer"}}
                                                                    />
                                                                </div>
                                                                :
                                                                <></>}
                                                            <img className={'image-gallery-thumbnail-image'} src={item.thumbnail}/>
                                                        </div>
                                                    )}
                                                />
                                                : <></>
                                        }
                                    </Grid>
                                    <Grid item xs={2}/>
                                    <Grid item xs={2}/>
                                    <Grid item xs={8}>
                                        {   currentlyUploading
                                                ?
                                                <Typography>Uploading ({progress} %)</Typography>
                                                :
                                                (images.length === 0) || currentlyDragging
                                                    ?
                                                        <DropzoneArea
                                                            onDrop={(files: Blob[]) => handleFileDrop(files, row.specId)}
                                                            // filesLimit={10}
                                                            maxFileSize={15000000}
                                                            showAlerts={false}
                                                        />
                                                    :
                                                        null

                                        }
                                    </Grid>
                                    <Grid item xs={2}/>
                                </Grid>
                            </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>
              :
              action==="quotes"
              ?
                <React.Fragment>
                    <TableRow key={0} onClick={(event: any) => handleQuotesRowClick(event)}
                    >
                       <TableCell sx={{
                            width:"20px"
                        }}>
                            <CustomCheckbox 
                                style = {{
                                    transform: "scale(0.7)"
                                }}/>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{
                            fontFamily:"Inter",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "14px",
                            color: "#000000",
                            width:"152px"
                        }}
                        >
                            {row.supplier.supplierName}
                        </TableCell>
                        <TableCell sx={{
                            fontFamily:"Inter",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "14px",
                            color: "#000000",
                            textAlign: "center",
                            width:"152px"
                        }}>
                            {row.dateEmailed ? row.dateEmailed : "-" }
                        </TableCell>
                        <TableCell sx={{
                            fontFamily:"Inter",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "14px",
                            color: "#000000",
                            textAlign: "center",
                            width:"152px"
                        }}>
                            {row.dateResponded ? row.dateResponded : "-"}
                        </TableCell>
                        <TableCell sx={{
                            fontFamily:"Inter",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "14px",
                            color: "#000000",
                            textAlign: "center",
                            width:"152px"
                        }}>
                            {row.price}
                        </TableCell>
                        <TableCell sx={{
                            fontFamily:"Inter",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "14px",
                            color: "#000000",
                            textAlign: "center",
                            width:"152px"
                        }}>
                            <div style={{
                                display:"flex",
                                flexDirection:"row",
                                justifyContent: "center",
                                gap:"5px"
                            }}>
                                {/*<Emails quote={row}/>*/}
                                <Files action={"quote"}/>
                                <div style={{
                                    display:"flex",
                                    flexDirection:"row",
                                    gap:"7px"
                                }}>
                                    <img src={invoiceIcon} alt="invoice icon" style={{width: "18px"}}/>
                                </div>  
                            </div>

                        </TableCell>
                        <TableCell sx={{
                            width:"10px"
                        }}>
                            <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenQuotes(!openQuotes)}
                            sx={{
                                color: "#0044CC",
                                textAlign: "right"
                            }}
                            >
                            {openQuotes ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>
                    </TableRow>
                    <TableRow key={1}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                        <Collapse in={openQuotes} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <div style={{marginBottom: "25px"}}>
                                <Typography
                                    sx={{
                                        fontFamily:"Inter",
                                        fontWeight: "500",
                                        fontSize: "12px",
                                        lineHeight: "14px",
                                        color: "#000000"
                                        }}>
                                    {row.description}
                                </Typography>
                            </div>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1.2}>
                                <Grid item>
                                    Dummy item 1
                                </Grid>
                                <Grid item>
                                    Dummy item 2
                                </Grid>
                                <Grid item>
                                    Dummy item 3
                                </Grid>
                            </Grid>
                        </Box>
                        </Collapse>
                    </TableCell>
                    </TableRow>
                </React.Fragment>
              :
              <></>
              }
            </>
        );
}

export default CustomCollapsibleTable