import React from "react";
import { useEffect, useState, useCallback } from "react";
import { TableRow, TableCell, Typography } from "@mui/material";
import { Costcode } from "../../../Models/costcode";
import { User } from "../../../Models/user";
import AddComment from "../Comments/AddComment";
import AddUser from "../Users/AddUser";
import dayjs from "dayjs";
// import useMountStatus  from '../../../utilities/Helper/checkMountStatus';
import EditCostCode from "./EditCostCode";
import "../CostCodes/costCode.css";
import { useStyles } from "./costcodeStyle";
import comments from "../Comments/Comments";
import AutocompleteCustom from "../../../utilities/Autocomplete/Autocomplete";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import userAPI from "../../../Services/DoxleAPI/userAPI";
import costCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import listIcon from "../../../Assets/ListOrdered.svg";
import listIconHighlight from "../../../Assets/ListOrderedHighlight.svg";
import fileAgreementIcon from "../../../Assets/FileAgreement.svg";
import fileTextIcon from "../../../Assets/FileText.svg";
import photosIcon from "../../../Assets/photos.svg";
import Badge from "@mui/material/Badge";
import EditPermit from "../Permits/EditPermit";
import { ReactComponent as TrashIcon } from "../../../Assets/Trash2.svg";
import AddContractor from "../Contractor/AddContractor";
import { AddressBookCompany } from "../../../Models/addressBook";
import CostCodeMenu from "../../Menu/CostCodeMenu";
import { ColumnView } from "../../../Models/columnView";
import CommentsPopover from "../Comments/CommentsPopover";
import { getCalculationColumn, calculateValue } from "./calculateColumn";
import { toFloat } from "../Measurements/konvaFunctions/generalFunctions";
import { DateRange } from "@mui/lab";
import { Dayjs } from "dayjs";
import { useSocket } from "../../../Services/Sockets/useSocket";
import BudgetChip from "./BudgetChip";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import { Profiler } from "react";
interface props {
  initCostCode: Costcode;
  onCostCodeClicked: Function;
  updateTotals: Function;
  users: User[];
  editTitleMode: boolean;
  setEditTitleMode: Function;
  editTitleOption: string | null;
  setEditTitleOption: Function;
  handleDeleteCostcode: Function;
  contractors: AddressBookCompany[];
  projectStatus: string;
  openStage: boolean;
  columnView: ColumnView;
  fetchStages?: Function;
}

const CostCodeRow: React.FC<props> = ({
  initCostCode,
  onCostCodeClicked,
  updateTotals,
  users,
  setEditTitleMode,
  editTitleMode,
  editTitleOption,
  setEditTitleOption,
  handleDeleteCostcode,
  contractors,
  projectStatus,
  openStage,
  columnView,
  fetchStages,
}) => {
  const [costcode, setCostcode] = useState<Costcode>(initCostCode);
  const [title, setTitle] = useState<string>(initCostCode.title);
  const [budget, setBudget] = useState<string>(initCostCode.budget);
  const [dateRangeValue, setDateRangeValue] = React.useState<DateRange<Dayjs>>([
    dayjs(initCostCode.startDate),
    dayjs(initCostCode.endDate),
  ]);
  // const [startDate, setStartDate] = useState<string>(initCostCode.startDate);
  // const [endDate, setEndDate] = useState<string>(initCostCode.endDate);
  const [assignedContractor, setAssignedContractor] = useState<
    AddressBookCompany | undefined
  >((initCostCode?.assignedContractor as AddressBookCompany) || undefined);
  const [assignedUsers, setAssignedUsers] = useState<User[]>(
    costcode.assignedUsers
  );
  // const [budgetOverride, setBudgetOverride] = useState<boolean>(initCostCode.budgetOverride);
  const [income, setIncome] = useState<boolean>(initCostCode.income);
  // const [locked, setLocked] = useState<boolean>(initCostCode.locked);
  const [status, setStatus] = useState<string>(initCostCode.status);
  const [completed, setCompleted] = useState<boolean>(initCostCode.completed);
  const [runningBudget, setRunningBudget] = useState<number>(
    parseFloat(initCostCode.budget)
  );

  //const unmounted = useMountStatus();
  const { height, width } = WindowDimensions();
  const limitAssignee = width < 928 ? 1 : 3;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn } = authContext;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const formatter: Intl.NumberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const handleRename = () => {
    setEditTitleMode(true);
    setEditTitleOption(costcode.costCodeId);
  };

  const toggleIncome = async () => {
    try {
      const result = await costCodeAPI.update(costcode.costCodeId, {
        income: !income,
      });
      if (result) setIncome(result.income);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
    setIncome(!income);
  };

  const updateStage = async (stage: string) => {
    try {
      const result = await costCodeAPI.update(costcode.costCodeId, {
        stage: stage,
      });
      if (fetchStages) fetchStages();
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const updateRunningValue = () => {
    const oriRunningBudget: number = runningBudget;
    let newRunningBudget: number;
    if (completed) {
      newRunningBudget = costcode.actual || 0;
      // if (costcode.actual){newRunningBudget = costcode.actual  || 0 }
      // else newRunningBudget = Math.max(parseFloat(budget), parseFloat(costcode.orders || "0"), costcode.actual || 0);
    } else {
      newRunningBudget = Math.max(
        parseFloat(budget),
        parseFloat(costcode.orders || "0"),
        costcode.actual || 0
      );
    }
    if (isNaN(newRunningBudget)) setRunningBudget(runningBudget);
    else setRunningBudget(newRunningBudget);
    const runningDelta = newRunningBudget - oriRunningBudget;
    if (!costcode.income) updateTotals({ runningDelta: runningDelta });
  };

  const getRowClasses = () => {
    let rowClass: string = "tableRowStyle";
    if (completed) {
      rowClass += " strikeout";
    }
    if (income) {
      rowClass += " income";
    }
    return rowClass;
  };

  const getTextColor = () => {
    if (status === "N") return "#5e5f5f";
    return "#0D0D0D";
  };

  const getCalculateColor = () => {
    // const difference = calculateValue(costcode, runningBudget);
    // if (status === "P" || difference > 0) return "red";
    if (status === "P") return "red";
    if (status === "N") return "#5e5f5f";
    // if (difference < 0) return "LimeGreen";
    return "#0D0D0D";
  };

  const calculateDays = () => {
    if (!(dateRangeValue?.[0] && dateRangeValue?.[1])) return 0;
    return Math.round(
      dateRangeValue[1].diff(dateRangeValue[0]) / 1000 / 3600 / 24 + 1
    );
  };

  const handleTitleClicked = () => {
    if (!editTitleMode) onCostCodeClicked(costcode, "7");
    if (editTitleMode && !(editTitleOption === costcode.costCodeId))
      setEditTitleOption(costcode.costCodeId);
    if (editTitleMode && editTitleOption === costcode.costCodeId)
      setEditTitleOption(null);
  };

  const exitEditTitleMode = () => {
    setEditTitleMode(false);
    setEditTitleOption(null);
  };

  const getDifference = () => {
    const orders = costcode.orders ? costcode.orders : "0";
    const actual = costcode.actual ? costcode.actual : 0;
    if (isNaN(parseFloat(orders))) return 0;
    return actual - parseFloat(orders);
  };

  const handleMarkComplete = async () => {
    try {
      if (costcode?.costCodeId) {
        const response = await CostCodeAPI.update(costcode?.costCodeId, {
          completed: costcode.completed ? false : true,
        });
        if (response) {
          setCompleted(response.completed);
          setCostcode(response);
        }
      }
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      console.log("Unable to save to backend, returning to complete");
    }
  };

  useEffect(() => {
    updateRunningValue();
  }, [costcode, costcode.actual, costcode.orders, budget, status, completed]);

  //! <--- SOCKETS LISTENER
  const socket = useSocket();
  const onMessage = useCallback((message) => {
    const data = JSON.parse(message?.data);

    const serverStatus: string = data.status;
    const messageType: string = data.message_type;
    // console.log(
    //     "%cDOXLE SOCKETS=RECEIVE SUCCESS DATA", "background:green; color:white"
    // );
    if (messageType === "SocketDataUpdate" || messageType === "CostCodeUpdate") {
      if (serverStatus === "SUCCESS") {
        const {
          costCodeId,
          budget,
          status,
          startDate,
          endDate,
          assignedUsers,
          assignedContractor,
        } = data.message;

        if (costCodeId && costCodeId === costcode.costCodeId) {
          console.log(costcode.title, data.message)
          setCostcode((cc) => ({ ...cc, ...data.message }));
          if (budget) {
            setBudget(budget);
          }
          if (status) {
            setCostcode((cc) => ({ ...cc, status }));
            setStatus(status);
          }
          if (assignedUsers) {
            setCostcode((cc) => ({ ...cc, assignedUsers }));
            setAssignedUsers(assignedUsers);
          }
          if (assignedContractor) {
            setCostcode((cc) => ({ ...cc, assignedContractor }));
            setAssignedContractor(assignedContractor);
          }
          if (assignedContractor === null) {
            setAssignedContractor(undefined);
          }
          if (startDate && endDate) {
            setCostcode((cc) => ({ ...cc, startDate, endDate }));
            setDateRangeValue([dayjs(startDate), dayjs(endDate)]);
          }
        }
      } else {
        console.log(
          "%cDOXLE SOCKETS=DATA ERROR",
          "background:red; color:white"
        );
      }
    }
  }, []);

  useEffect(() => {
    socket.addEventListener("message", onMessage);

    return () => {
      socket.removeEventListener("message", onMessage);
    };
  }, [socket, onMessage]);
  // ! SOCKETS --->

  return (
    <>
      <TableRow
        className={getRowClasses()}
        key={initCostCode.costCodeId}
        style={{
          opacity: status === "N" ? "0.5" : status === "C" ? "0.7" : "1",
          display: openStage ? "table-row" : "none",
        }}
      >
        {/* Costcode Title Cell */}
        {/* <TableCell style={{minWidth: "44px", textAlign:"center", position: "sticky", left: 0, zIndex: 49, background: "white"}} sx={useStyles.tableBodyStyle}>

              {     editTitleMode ?
                  <TrashIcon onClick={(e: any) => handleDeleteCostcode(costcode)} style={{cursor: "pointer", transform: "scale(0.9)"}} />
                  :
                  <EditCostCode
                      action={"checkbox"}
                      costcode={costcode}
                      formatter={formatter}
                      setValue={setCompleted}
                      textColor={getTextColor}
                  />}
          </TableCell> */}
        <TableCell sx={{...useStyles.tableBodyStatusFieldStyle, width: 48}}>
          <EditCostCode
            action={"status"}
            costcode={costcode}
            formatter={formatter}
            setValue={setStatus}
            textColor={getTextColor}
          />
        </TableCell>
        <TableCell
          className="strikeCell"
          style={{
            cursor: "default",
            position: "sticky",
            left: 44,
            zIndex: 49,
            background: "white",
          }}
          sx={{ color: getTextColor(), ...useStyles.tableBodyTitleFieldStyle }}
        >
          {editTitleMode && editTitleOption === costcode.costCodeId ? (
            <EditCostCode
              costcode={costcode}
              formatter={formatter}
              action={"title"}
              setValue={setTitle}
              textColor={getTextColor}
              exitEditTitleMode={exitEditTitleMode}
            />
          ) : (
            <div style={{ display: "flex", height: "100%" }}>
              <p
                onClick={() => handleTitleClicked()}
                style={{
                  display: "flex",
                  width: "max-content",
                  margin: 0,
                  padding: 0,
                  paddingLeft: fetchStages ? 20 : 0,
                  alignItems: "center",
                  cursor: "pointer",
                  height: "100%",
                  zIndex: "49",
                }}
              >
                {title}
              </p>
              <CostCodeMenu
                variant={"costCode"}
                handleDelete={() => handleDeleteCostcode(costcode)}
                handleRename={handleRename}
                income={costcode.income}
                stage={costcode.stage}
                toggleIncome={toggleIncome}
                updateStage={updateStage}
                handleMarkComplete={handleMarkComplete}
                complete={costcode.completed}
              />
            </div>
          )}
        </TableCell>
        <TableCell
          className="strikeCell"
          style={{
            borderRight: "0.3px solid rgba(224, 224, 224, 1)",
          }}
          sx={useStyles.tableBodyStatusFieldStyle}
        ></TableCell>
        {/* Notes Storage Icon Cell */}
        {columnView?.notesIcon && (
          <TableCell
            className="strikeCell"
            sx={{ color: getTextColor(), ...useStyles.tableBodyStyle }}
            style={{
              borderRight: "0.3px solid rgba(224, 224, 224, 1)",
              maxWidth: "40px",
            }}
          >
            <Badge
              style={{
                transform: "scale(0.8)",
                cursor: "pointer",
                color: "#B6A9DA",
                top: "-8px",
                left: "15px",
              }}
              badgeContent={0}
              onClick={() => {}}
            />
            <img src={fileTextIcon} alt="Notes" title="Notes" />
          </TableCell>
        )}

        {/* Files Icon Cell */}
        {columnView?.fileIcon && (
          <TableCell
            className="strikeCell"
            onClick={() => onCostCodeClicked(costcode, "3")}
            sx={{ color: getTextColor(), ...useStyles.tableBodyStyle }}
            style={{
              borderRight: "0.3px solid rgba(224, 224, 224, 1)",
              maxWidth: "40px",
            }}
          >
            <Badge
              style={{
                transform: "scale(0.8)",
                cursor: "pointer",
                color: "#B6A9DA",
                top: "-8px",
                left: "15px",
              }}
              badgeContent={costcode.fileCount ? costcode.fileCount : 0}
              onClick={() => {}}
            />
            <img src={fileAgreementIcon} alt="Files" title="Files" />
          </TableCell>
        )}

        {/* Photos Icon Cell */}
        {columnView?.photoIcon && (
          <TableCell
            className="strikeCell"
            sx={{ color: getTextColor(), ...useStyles.tableBodyStyle }}
            style={{
              borderRight: "0.3px solid rgba(224, 224, 224, 1)",
              maxWidth: "40px",
            }}
          >
            <Badge
              style={{
                transform: "scale(0.8)",
                cursor: "pointer",
                color: "#B6A9DA",
                top: "-8px",
                left: "15px",
              }}
              badgeContent={0}
              onClick={() => {}}
            />
            <img src={photosIcon} alt="Photos" title="Photos" />
          </TableCell>
        )}

          {/* Checklist Icon Cell */}
          {columnView?.checklistIcon &&
              <TableCell className='strikeCell' onClick={() => onCostCodeClicked(costcode, "4")}
                         sx={{color: getTextColor(), ...useStyles.tableBodyStyle}}
                         style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", maxWidth: "40px"}}>
                  {
                      costcode.checklistCount && costcode.checklistFailCount && costcode.checklistFailCount > 0
                          ?
                          <>
                              <Badge
                                  style={{
                                      transform: "scale(0.8)",
                                      cursor: "pointer",
                                      color: "#FF7C04",
                                      top: "-8px",
                                      left: "15px"
                                  }}
                                  badgeContent={costcode.checklistCount}
                                  onClick={() => {
                                  }}
                              />
                              <img src={listIconHighlight} alt="Checklist" title="Checklist"/>
                          </>
                          :
                          costcode.checklistCount && costcode.checklistCount > 0
                              ?
                              <>
                                  <Badge
                                      style={{
                                          transform: "scale(0.8)",
                                          cursor: "pointer",
                                          color: "#B6A9DA",
                                          top: "-8px",
                                          left: "15px"
                                      }}
                                      badgeContent={costcode.checklistCount}
                                      onClick={() => {
                                      }}
                                  />
                                  <img src={listIcon} alt="Checklist" title="Checklist"/>
                              </>
                              :
                              <img src={listIcon} alt="Checklist" title="Checklist"/>
                  }
              </TableCell>
          }

          {/* SignOff Icon Cell */}
          {columnView.signedBills &&
              <TableCell className='strikeCell' onClick={() => onCostCodeClicked(costcode, "")}
                         sx={{color: getTextColor(), ...useStyles.tableBodyStyle}}
                         style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", maxWidth: "40px"}}
              >
                  <Badge
                      style={{
                          transform: "scale(0.8)",
                          cursor: "pointer",
                          color: "#FF7C04",
                          top: "0px",
                          left: "15px",
                      }}
                      badgeContent={costcode?.signedOffCount || 0}
                      onClick={() => {
                      }}
                  />
                  <Typography sx={{fontFamily: "DearJoe4", fontSize: "x-small", color: costcode?.signedOffInitial ? "#FF7C04" : "#B6A9DA" }}>
                      {costcode?.signedOffInitial || "XX"}
                  </Typography>
              </TableCell>
          }

          {/* Comment Icon Cell */}
          {columnView.commentIcon &&
              <TableCell className='strikeCell' onClick={() => onCostCodeClicked(costcode, "11")}
                         sx={{color: getTextColor(), ...useStyles.tableBodyStyle}}
                         style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", maxWidth: "40px"}}
                         onMouseEnter={
                             costcode.commentCount &&
                             costcode.commentCount > 0 ? handlePopoverOpen : undefined}
                         onMouseLeave={handlePopoverClose}
              >
                  <AddComment costCode={costcode} clickFunction={() => onCostCodeClicked(costcode, "11")}/>
                  <CommentsPopover
                      costCodeId={costcode.costCodeId}
                      anchorEl={anchorEl}
                      handlePopoverClose={handlePopoverClose}/>
              </TableCell>
          }

        {/* Assigned users Cell */}
        {columnView?.assignee && (
          <TableCell
            className="strikeCell"
            sx={{ color: getTextColor(), ...useStyles.tableBodyStyle }}
            style={{
              borderRight: "0.3px solid rgba(224, 224, 224, 1)",
              width: "150px",
            }}
          >
            <div
              style={{
                height: "33px",
                overflow: "hidden",
                display: "flex",
                alignContent: "center",
                color: getTextColor(),
              }}
            >
              {assignedUsers.length > limitAssignee ? (
                <span className="assignedUsersStyle">
                  +{assignedUsers.length}
                </span>
              ) : null}
              <AddUser
                costCodeId={costcode.costCodeId}
                users={users}
                assignedUsers={assignedUsers}
                setAssignedUsers={setAssignedUsers}
              />
            </div>
          </TableCell>
        )}

        {/* Contractor Cell */}
        {columnView?.contractor && (
          <TableCell
            className="strikeCell"
            sx={{ color: getTextColor(), ...useStyles.tableBodyStyle }}
            style={{ paddingLeft: 3, paddingRight: 3 }}
          >
            <div
              style={{
                height: "33px",
                overflow: "hidden",
                display: "flex",
                alignContent: "center",
                color: getTextColor(),
              }}
            >
              <AddContractor
                costCodeId={costcode.costCodeId}
                addressBookCompanies={contractors}
                assignedContractor={assignedContractor}
                setAssignedContractor={setAssignedContractor}
              />
            </div>
            {/*<div style={{borderRadius: "40px", height: "15px", backgroundColor: "#E7DFFF", color:"#201D20", fontSize: "8px", whiteSpace: "nowrap", paddingLeft: 5, paddingRight: 5}}>UP ARCHITECTS</div>*/}
          </TableCell>
        )}

        {/* Budget Cell */}
        {columnView?.budget && (
          <TableCell
            className="strikeCell"
            sx={{
              color: getTextColor(),
              ...useStyles.tableBodyBudgetFieldStyle,
              borderLeft: "0.3px solid rgb(224, 224, 224)",
            }}
          >
            <EditCostCode
              action={"budget"}
              costcode={costcode}
              formatter={formatter}
              setValue={setBudget}
              updateTotals={updateTotals}
              textColor={getTextColor}
              projectStatus={projectStatus}
            />
          </TableCell>
        )}

          {/* Orders Cell */}
          { columnView?.order && 
          <TableCell
              className='strikeCell'
              onClick={() => onCostCodeClicked(costcode, '7')}
              sx={{cursor: "pointer", color: getTextColor(), ...useStyles.tableBudgetStyle}}
              style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)"}}
          >
              {costcode.orders ? formatter.format(parseFloat(costcode.orders)) : '$0.00'}
              {toFloat(costcode.budget) - toFloat(costcode.orders) !== 0 && <BudgetChip value={toFloat(costcode.orders) - toFloat(costcode.budget)} income={costcode.income}/>}
          </TableCell>
        }

          {/* Actuals / Billed Cell */}
          {columnView?.billed &&
                <TableCell 
                    className='strikeCell'
                    onClick={() => onCostCodeClicked(costcode, '8')}
                    sx={useStyles.tableBodyActualFieldStyle}
                    style={{cursor: "pointer"}}
                >
                    {costcode.actual ? formatter.format(costcode.actual) : '$0.00'}
                    {toFloat(costcode.budget) - toFloat(costcode.actual) !== 0 && <BudgetChip value={toFloat(costcode.actual) - toFloat(costcode.budget)} income={costcode.income}/>}
                </TableCell>
          }

        {/* Running Budget Cell */}
        {columnView?.running && (
          <TableCell
            className="strikeCell"
            sx={{ color: getTextColor(), ...useStyles.tableBudgetStyle }}
            style={{ borderRight: "0.3px solid rgba(224, 224, 224, 1)" }}
          >
            {!costcode.income ? formatter.format(runningBudget) : ""}
            {toFloat(costcode.budget) - runningBudget !== 0 && (
              <BudgetChip
                value={runningBudget - toFloat(costcode.budget)}
                income={costcode.income}
              />
            )}
          </TableCell>
        )}

        {/* Difference Cell */}
        {/*{columnView?.difference && (*/}
        {false && (
          <TableCell
            className="strikeCell"
            sx={{ color: getTextColor(), ...useStyles.tableBodyStyle }}
            style={{
              borderRight: "0.3px solid rgba(224, 224, 224, 1)",
              color: getCalculateColor(),
            }}
          >
            {formatter.format(calculateValue(costcode, runningBudget))}
          </TableCell>
        )}

        {/* Start Date Cell */}
        {columnView?.startDate && (
          <TableCell
            className="strikeCell"
            sx={{ color: getTextColor(), ...useStyles.tableBodyDateFieldStyle }}
            style={{ borderRight: "0.3px solid rgba(224, 224, 224, 1)" }}
          >
            <EditCostCode
              action={"startDate"}
              costcode={costcode}
              formatter={formatter}
              dateRange={dateRangeValue}
              setValue={setDateRangeValue}
              textColor={getTextColor}
            />
          </TableCell>
        )}

        {/* End Date Cell */}
        {columnView?.endDate && (
          <TableCell
            className="strikeCell"
            sx={{ color: getTextColor(), ...useStyles.tableBodyDateFieldStyle }}
            style={{ borderRight: "0.3px solid rgba(224, 224, 224, 1)" }}
          >
            <EditCostCode
              action={"endDate"}
              costcode={costcode}
              formatter={formatter}
              dateRange={dateRangeValue}
              setValue={setDateRangeValue}
              textColor={getTextColor}
            />
          </TableCell>
        )}

        {/* Days / Duration Cell */}
        {columnView?.days && (
          <TableCell
            className="strikeCell"
            sx={{ color: getTextColor(), ...useStyles.tableBodyStyle }}
            style={{ borderRight: "0.3px solid rgba(224, 224, 224, 1)" }}
          >
            {calculateDays()}
          </TableCell>
        )}

        {/* Status Cell */}
        {/* {columnView?.status &&
              <TableCell sx={useStyles.tableBodyStatusFieldStyle}>
                  <EditCostCode
                      action={"status"}
                      costcode={costcode}
                      formatter={formatter}
                      setValue={setStatus}
                      textColor={getTextColor}
                  />
              </TableCell>
          } */}
      </TableRow>
    </>
  );
};

export default CostCodeRow;
