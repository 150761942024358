import React from 'react';
import AccountMappingApp from './AccountMappingApp';
import App from '../../../App';
import accountingDetailAPI from '../../../Services/DoxleAPI/accountingDetails'
import { useLocation } from 'react-router-dom';
import { baseAddress } from '../../../settings';
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";

const XeroFirstAuth: React.FC = () => {
    window.location.href = baseAddress+"/xero/first_auth";
    return(
        <p>Redirecting you to xero</p>
    );
}

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CallBack: React.FC = () => {
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn } = authContext;
    try {
        let query = useQuery();
        const companyId: string = localStorage.getItem("currentCompanyId") || '';
        const code = query.get("code");
        const state = query.get("state");
        accountingDetailAPI.finalizeXeroConnection(companyId, code, state)
    } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
    return (
        // <AccountMappingApp />
        <App/>
    )
}

export { XeroFirstAuth, CallBack }