import React, { useState } from "react";
import { Grid, Typography, TextField, Box } from "@mui/material";
import checklistGroupAPI from "../../../Services/DoxleAPI/checklistGroupAPI";
import { Permit } from "../../../Models/permit";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import { Checklist } from "../../../Models/checklist";
import checklistAPI from "../../../Services/DoxleAPI/checklistAPI";
import { User } from "../../../Models/user";
import { AddressBookCompany } from "../../../Models/addressBook";
import ChecklistItem from "./ChecklistItem";
import { ReactComponent as TrashIcon } from "../../../Assets/Trash2.svg";
import { ReactComponent as PlusCircle } from "../../../Assets/PlusCircleGrey.svg";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface props {
    checklistList: Permit;
    deleteChecklistList: Function;
    users?: User[];
    contractors?: AddressBookCompany[];
}

const ChecklistTable: React.FC<props> = ({
    checklistList,
    deleteChecklistList,
    users,
    contractors,
}) => {
    const [expanded, setExpanded] = useState<boolean>(true);
    const [editState, setEditState] = useState<boolean>(false);
    const [hovering, setHovering] = useState<boolean>(false);
    const [questions, setQuestions] = useState<Checklist[]>(
        checklistList?.checklistItems ? checklistList?.checklistItems : []
    );
    const [title, setTitle] = useState<string>(checklistList.title);
    const [newQuestion, setNewQuestion] = useState<string>("");
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;

    const handleQuestionChange = (e: any) => {
        setNewQuestion(e.target.value);
    };

    const deleteChecklistItem = async (checklistId: string | undefined) => {
        try {
            if (!checklistId) return;
            const response = await checklistAPI.remove(checklistId);
            if (!response) return;
            let newQuestions: Checklist[] = [];
            questions.forEach((question: Checklist) => {
                if (checklistId !== question.checklistId)
                    newQuestions.push(question);
            });
            setQuestions([...newQuestions]);
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
    };

    const handleTitleUpdate = async (checklistGroupId: string) => {
        if (!user?.userId) {
            console.log("No UserId");
            return;
        }
        try {
            const response = await checklistGroupAPI.update(checklistGroupId, {
                title: title,
                userId: user.userId,
            });
            if (response) setEditState(false);
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
    };

    const handleTitleKeyUp = (e: any, checklistGroupId: string) => {
        if (e.keyCode !== 13) return;
        handleTitleUpdate(checklistGroupId);
    };

    const handleQuestionKeyUp = async (e: any, checklistGroupId: string) => {
        // console.log(e)
        if (!user?.userId) return;
        if (e.keyCode !== 13) return;
        try {
            const response = await checklistAPI.addChecklist(
                {
                    question: newQuestion,
                    answer: null,
                    // notice: notice.noticeId,
                    timeStamp: new Date().toISOString(),
                    permit: checklistGroupId,
                },
                user.userId
            );
            if (response) setQuestions([...questions, response]);
            setNewQuestion("");
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
    };

    return (
        <Grid
            container
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            sx={{
                paddingTop: "20px",
                marginBottom: "50px",
            }}
        >
            <Grid item xs={1} sx={{ height: "24px" }} onClick={() => setExpanded(!expanded)}>
                {expanded
                    ? <KeyboardArrowUpIcon />
                    : <KeyboardArrowDownIcon />
                }
            </Grid>
            <Grid
                item
                xs={10}
                onDoubleClick={() => setEditState(true)}
                onBlur={() => setEditState(false)}
            >
                {!editState ? (
                    <Typography
                        style={{
                            color: "#5A36BE",
                            fontWeight: 600,
                            fontSize: "12px",
                            fontFamily: "Roboto Mono",
                            fontStyle: "normal",
                            lineHeight: "16px",
                        }}
                    >
                        {title}
                    </Typography>
                ) : (
                    <TextField
                        variant="standard"
                        value={title}
                        onChange={(e: any) => setTitle(e.target.value)}
                        onKeyUp={(e: any) =>
                            handleTitleKeyUp(e, checklistList.checklistGroupId)
                        }
                        onBlur={() => handleTitleUpdate(checklistList.checklistGroupId)}
                        sx={{
                            color: "#5A36BE",
                        }}
                        InputProps={{
                            disableUnderline: true,
                            sx: {
                                color: "#5A36BE",
                                fontWeight: 600,
                                fontSize: "12px",
                                fontFamily: "Roboto Mono",
                                fontStyle: "normal",
                                lineHeight: "16px",
                                "& .MuiInputBase-input": {
                                    textAlign: "left",
                                    paddingBottom: "1px",
                                    paddingTop: "1px",
                                    maxHeight: "28px",
                                    width: "70vw",
                                    // border: "white",
                                    borderRadius: "5px 5px 0px 0px",
                                },
                                "& .MuiInputLabel-formControl": {
                                    color: "#9974fe",
                                },
                            },
                        }}
                        InputLabelProps={{
                            style: { color: "#9974fe" },
                            sx: {
                                top: "-10px",
                                "& .MuiInputLabel-shrink": {
                                    top: "0px !important",
                                },
                            },
                        }}
                    />
                )}
            </Grid>
            <Grid item xs={1} sx={{ height: "35px" }}>
                {hovering ? (
                    <TrashIcon
                        onClick={() =>
                            deleteChecklistList(checklistList.checklistGroupId)
                        }
                        style={{ cursor: "pointer" }}
                    />
                ) : null}
            </Grid>

            {expanded && questions.map((checklist: Checklist) => (
                <ChecklistItem
                    key={checklist.checklistId}
                    checklist={checklist}
                    deleteChecklistItem={deleteChecklistItem}
                    users={users}
                    contractors={contractors}
                />
            ))}

            {expanded &&
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        height="24px"
                        gap="5px"
                        py="5px"
                        px="25px"
                    >
                        <div>
                            <PlusCircle style={{ width: "21px", height: "21px" }} />
                        </div>
                        <TextField
                            variant="standard"
                            placeholder={"Add Another Item Here ..."}
                            value={newQuestion}
                            onChange={handleQuestionChange}
                            onKeyUp={(e: any) =>
                                handleQuestionKeyUp(e, checklistList.checklistGroupId)
                            }
                            sx={{
                                color: "#9974fe",
                                borderRadius: "0px 0px 0px 5px",
                            }}
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    fontFamily: "Roboto Mono",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    fontSize: "10px",
                                    lineHeight: "13px",
                                    "& .MuiInputBase-input": {
                                        textAlign: "left",
                                        // color:"#",
                                        paddingBottom: "1px",
                                        paddingTop: "1px",
                                        maxHeight: "28px",
                                        width: "70vw",
                                        // border: "white",
                                        borderRadius: "5px 5px 0px 0px",
                                    },
                                    "& .MuiInputLabel-formControl": {
                                        color: "#9974fe",
                                    },
                                },
                            }}
                            InputLabelProps={{
                                style: { color: "#9974fe" },
                                sx: {
                                    top: "-10px",
                                    "& .MuiInputLabel-shrink": {
                                        top: "0px !important",
                                    },
                                },
                            }}
                        />
                    </Box>
                </Grid>
            }
        </Grid>
    );
};

export default ChecklistTable;
