import axios from "axios";
import {Bill, BillHistoryLine, BillLine} from '../../Models/bill';
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";
import dummy from './dummyData';


interface GetParams {
  costCodeId: string,
  orderBy?: string
  page?: number,
}

const getList = async ({costCodeId, orderBy = "number", page = 1}: GetParams)  => {
  // Get's a list of costcodes for a given project selected with project Id
  // Order costcode with orderBy, options =  title, startDate, endDate 
  // Pagination 100 costcodes per result - call for next page when scrolling
  let result: Bill[] = []
  if (offline){ /* result = dummy.billsListResponse.results ;*/ return result; }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  try {
    let params = { cost_code: costCodeId, ordering: orderBy, page: page}
    const response = await axios.get(
      baseAddress+"/bill/",
      {
        headers: { Authorization: "Bearer " + accessToken },
        params: params
      }
    )
    result = response.status === 200 ? response.data.results as Array<Bill> : [];
    console.log("BILL", result)
  } catch (error) {
      console.error("ERROR FETCHING BILL", error)
  }
  return result
}

const getDetailed = async (billId: string)  => {
  // Gets one costcode only using the costcode's UUID
  // At this stage there is no additional information from the detailed
  // Future back end change will mean this call will get storage, specs, comments with this call
  let result: Bill | undefined = undefined;
  if (offline){ /*result = dummy.billFullResponse;*/return result;}
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  try {
    const response = await axios.get(
      baseAddress+"/bill/"+billId+"/",
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    )
    result = response.status === 200 ? response.data as Bill : undefined;
  } catch (error) {
      console.error("ERROR FETCHING BILL", error)
  }
  return result
}

const remove = async (billId: string)  => {
  /// Permanently delete costcode by uuid
  interface response {success: boolean; orders: number}
  let result ={success: false, orders: 0}
  if (offline){result.success = true; return result; }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  try {
    const response = await axios.delete(
      baseAddress+"/bill/"+billId+"/",
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    )
    console.log(response.status)
    result.success = response.status === 200
    result.orders = !isNaN(response.data.orders as number) ? response.data.orders : 0;
    console.log("SUCCESSFULLY REMOVED BILL, COST CODE ORDERS:", result)
  } catch (error) {
      console.error("ERROR DELTEING BILL", error)
  }
  return result
}


const add = async (bill: Bill)  => {
  interface Result{bill: Bill; orders: number}
  let result: Result | undefined = undefined
  if (offline){ return result; }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  try {
    if (!bill.lines) {return result}
    let subTotal: number = 0;
    let validLines: BillLine[] = []
    for (let l =0; l < bill.lines.length; l++){
      if (!bill.lines[l].description || bill.lines[l].description.replaceAll(' ', '') === "") continue;
      bill.lines[l].billLineId = ""
      bill.lines[l].lineCost = Math.round(parseFloat(bill.lines[l].quantity as string) * parseFloat(bill.lines[l].itemCost as string) *10000) / 10000
      subTotal += bill.lines[l].lineCost
      validLines.push(bill.lines[l])
    }
    bill.lines = validLines;
    bill.subTotal = Math.round(subTotal * 100) / 100;
    bill.tax = Math.round(bill.subTotal * 10) / 100;
    bill.total = Math.round((bill.subTotal + bill.tax) * 100) / 100;
    bill.issueDate = new Date().toISOString().substring(0, 10)
    bill.history = [{
      index: 0,
      shortText: "Bill Created",
      longText: "New bill created for "+bill.supplier+" to the value of "+bill.total,
      timeStamp: new Date().toString(),
    }]

    //fix issues with djangorestframework_camel_case
    let newBill: any = bill
    newBill.cost_code = bill.costCode
    newBill.cost_code = bill.costCode

    // Add a new bill
    const response = await axios.post(
      // "http://3.26.150.72:8000/bill/new/",
      baseAddress+"/bill/new/",
      bill,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    )
    result = response.status === 201 ? {bill: response.data.bill as Bill, orders: response.data.orders as number} : undefined;
    console.log("ADDED BILL", result)
  } catch (error) {
      console.error("ERROR ADDING BILL", error)
  }
  return result
}


interface UpdateBody {  
  number?: string;
  dueDate?: string;
  issueDate?: string;
  emailSent?: boolean;
  supplier?: string;
  accepted?: boolean;
  internalNotes?: string;
  specialConditions?: string;
  status?: string;
  abSupplier?: string;
  lines?: BillLine[];
  history?: any[];
  subTotal?: number;
  tax?: number;
  total?: number;
  reference?: string;
  startDate?: string;
  endDate?: string;
  paymentTerms?: string|number;
  paymentTermsSuffix?: "Days"|"NET"|"EOM";
  damages?: string;
  damagesPeriod?: "Day"|"Week"|"Month";
  signedOff?: string|null
}

const update = async (
  billId: string, 
  billHistoryLength: number,
  {
      number,
      issueDate,
      dueDate,
      supplier,
      accepted,
      internalNotes,
    specialConditions,
      status,
      abSupplier,
      lines,
      emailSent,
      reference,
      startDate,
      endDate,
      paymentTerms,
      paymentTermsSuffix,
      damages,
      damagesPeriod,
      signedOff
  }: UpdateBody
) => {
  interface Result{bill: Bill; orders: number}
  let result: Result | undefined = undefined
  if (offline){ return result; }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }

  // Update one or more property of a costcode without affecting other values
  try {
    let body: any = {}
    let editText: string = ""
    if (number) {
      body.number = number;
      editText += "Bill Number changed to "+number+"\n";
    }
    if (issueDate) {
      body.issueDate = issueDate.substring(0, 10);
      editText += "Bill issueDate changed to "+issueDate+"\n";
    }
    if (dueDate) {
      body.dueDate = dueDate.substring(0, 10);
      editText += "Bill dueDate changed to "+dueDate+"\n";
    }
    if (supplier) {
      body.supplier = supplier;
      editText += "Bill supplier changed to "+supplier+"\n";
    }
    if (abSupplier !== undefined) {
      body.abSupplier = abSupplier
    }
    if (reference) {
      body.reference = reference;
      editText += "Bill reference changed to "+reference+"\n";
    }
    if (accepted !== undefined) {
      body.accepted = accepted;
      if (accepted) { editText += "Bill marked as accepted\n"; }
      else { editText += "Bill marked as unaccepted\n"; }
    }
    if (emailSent !== undefined) {
      body.emailSent = emailSent;
      if (emailSent) { editText += "Bill marked as sent\n"; }
      else { editText += "Bill marked as unsent\n"; }
    }
    if (internalNotes) {
      body.internalNotes = internalNotes;
      editText += "Internal notes were edited\n";
    }
    if (specialConditions) {
      body.specialConditions = specialConditions;
      editText += "General notes were edited\n";
    }
    if (status) {
      body.status = status;
      editText += "Bill status changed to "+status+"\n";
    }
    if (startDate  !== undefined ) {
      body.startDate = startDate;
      editText += "Bill start date changed to "+startDate+"\n";
    }
    if (endDate !== undefined ) {
      body.endDate = endDate;
      editText += "Bill end date changed to "+endDate+"\n";
    }
    if (paymentTerms) {
      body.paymentTerms = paymentTerms;
      editText += "Bill payment terms changed to "+paymentTerms+"\n";
    }
    if (paymentTermsSuffix) {
      body.paymentTermsSuffix = paymentTermsSuffix;
      editText += "Bill payment terms changed to "+paymentTermsSuffix+"\n";
    }
    if (damages) {
      body.damages = damages;
      editText += "Bill damage rate changed to "+damages+"\n";
    }
    if (damagesPeriod) {
      body.damagesPeriod = damagesPeriod;
      editText += "Bill damage rate changed to "+damagesPeriod+"\n";
    }
    if (signedOff === null || (typeof signedOff === "string" && signedOff !== "")) {
      body.signedOff = signedOff;
      editText += "Bill signed off by "+signedOff+"\n";
    }
    if (abSupplier) {body.abSupplier = abSupplier;}
    let subTotal: number = 0;
    if (lines) {
      body.lines = [] as any;
      // console.log(lines)
      for (var l =0; l < lines.length; l++){
        if (!lines[l].description || lines[l].description.replaceAll(' ', '') === "") continue;
        const quantity: number = !isNaN(parseFloat(lines[l].quantity as string)) ? parseFloat(lines[l].quantity as string) : 0
        const itemCost: number = !isNaN(parseFloat(lines[l].itemCost as string)) ? parseFloat(lines[l].itemCost as string) : 0
        const lineCost = itemCost * quantity;
        
        body.lines.push({
          index: lines[l].index,
          description: lines[l].description,
          item_cost: Math.round(itemCost*10000) / 10000,
          item_formula: "",
          quantity: Math.round(quantity*10000) / 10000,
          quantity_formula: "",
          line_cost: Math.round(lineCost*10000) / 10000,
          ticked: lines[l].ticked,
          unit: lines[l].unit
        })
        subTotal += lineCost
      }
      body.subTotal = Math.round(subTotal * 100) / 100;
      body.tax = Math.round(body.subTotal * 10) / 100;
      body.total = Math.round((body.subTotal + body.tax)*100) / 100;
      editText += "Bill lines edited; new total = $"+body.total+"\n";
      // console.log(body.lines)
    }
    body.history = [{
      index: billHistoryLength + 1, 
      short_text: "Bill Edited",
      long_text: editText,
      time_stamp: new Date().toString(),
      // userName: string
    }]

    // console.log(body);
    const response = await axios.patch(
      // "http://3.26.150.72:8000/bill/"+billId,
      baseAddress+"/bill/"+billId+"/",
      body,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    )
    result = response.status === 200 ? {bill: response.data.bill as Bill, orders: response.data.orders as number} : undefined;
    console.log("UPDATED BILL", result)
  } catch (error) {
      console.error("ERROR UPDATING BILL", error)
  }
  return result;
}


const getPDF = async (billId: string) => {
  let result: string = "false"
  if (offline){ return result; }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  try{
    const response = await axios.get(
        // "http://3.26.150.72:8000/bill/"+billId,
        baseAddress+"/bill/pdf/"+billId+"/",
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
    )
    result = response.data as string
    console.log("SUCCESSFULLY GENERATED PDF", result)
  } catch (error) {
    console.error("ERROR GENERATING PDF", error)
  }
  return result
}


interface emailDetails{
  toEmails: string[];
  ccEmails: string[];
  emailSubject: string;
  emailBody: string;
  emailAttachments?: FileList
}

const sendEmail = async (billId: string, emailDetails: emailDetails) => {
  let result: BillHistoryLine|undefined = undefined
  if (offline){ return result; }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  try{
    const emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    const formData = new FormData()
    formData.append("emailSubject", emailDetails.emailSubject);
    formData.append("emailBody", emailDetails.emailBody);
    emailDetails.toEmails.forEach((emailAddress) => {
      if (!emailReg.test(emailAddress)) return result
      formData.append("toEmails", emailAddress);
    })
    emailDetails.ccEmails.forEach((emailAddress) => {
      if (!emailReg.test(emailAddress)) return result
      formData.append("ccEmails", emailAddress);
    })
    if (emailDetails.emailAttachments) {
      for (let i = 0; i < (emailDetails.emailAttachments.length); i++) {
        formData.append("emailAttachments", emailDetails.emailAttachments[i])
      }
    }
    const response = await axios.post(
        // "http://3.26.150.72:8000/bill/"+billId,
        baseAddress+"/bill/email/"+billId+"/",
        formData,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
    )
    if (response.status === 200 && response?.data?.log) result = response.data.log as BillHistoryLine
    console.log("SUCCESSFULLY EMAILED BILL", result)
  } catch (error) {
    console.error("ERROR EMAILING BILL", error)
  }
  return result
}

const uploadCSV = async (bill: Bill|UpdateBody, overrideLines: boolean, file:Blob)  => {
  // Get's a list of costcodes for a given project selected with project Id
  // Order costcode with orderBy, options =  title, startDate, endDate
  // Pagination 100 costcodes per result - call for next page when scrolling
  interface Result{bill: Bill; orders: number}
  let result: Result | undefined = undefined
  if (offline){ /* result = dummy.billsListResponse.results ;*/ return result; }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }

  const formData = new FormData();
  formData.append('override', overrideLines ? "True" : "False");
  for (const [key, value] of Object.entries(bill)) {
    formData.append(key, value);
  }
  formData.append("billCsv", file)
  try {
    const response = await axios.post(
        baseAddress+"/bill/csv/", formData,
        {
          headers: { Authorization: "Bearer " + accessToken }
          // onUploadProgress: progressEvent =>setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        }
    )
    result = response.status === 201 || response.status === 200 ? response.data as Result : undefined
    console.log("BILL", result)
  } catch (error) {
    console.error("ERROR CREATING BILL", error)
  }
  return result
}

interface BillResponseProps {
  billId: string;
  action: string;
  versionCheck: string;
  email: string;
}
const billResponse = async (data: BillResponseProps) => {
  interface Result {bill?: Bill; error?: any}
  let result: Result = {}
  try {
    const response = await axios.post(baseAddress + "/bill/response/" , data)
    if (response.status === 200) result.bill = response.data as Bill
  } catch (error) {
    result.error = error
  }
  return result;
}

const revokePO = async (billId: string) => {
  let result: Bill|undefined = undefined
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  try {
    const response = await axios.post(`${baseAddress}/bill/revoke/${billId}/`,{},  {
      headers: { Authorization: "Bearer " + accessToken }
    })
    if (response.status === 200) result = response.data as Bill
  } catch (error) {
    console.log("FAILED TO REVOKE PO", error)
  }
  return result;
}

const BillAPI = {
  getList,
  getDetailed,
  remove,
  update,
  add,
  getPDF,
  sendEmail,
  uploadCSV,
  billResponse,
  revokePO
};

export default BillAPI