import React, { useState, useEffect, Component, ReactElement } from "react";
import "../Menu/filterNavigation.css";
import { IconButton, Button, Menu, MenuItem, TextField } from "@mui/material";
import {
  STATUS_SYMBOL_WITH_LOCK,
  STATUS_SYMBOL_COLOR,
} from "../../utilities/constants";
import { ReactComponent as NoticePopperIcon } from "../../Assets/noticeDropDownIcon.svg";
import { ReactComponent as AddIcon } from "../../Assets/Plus-purple.svg";
import EditIcon from "../../Assets/Edit-purple.svg";
import EditAltIcon from "../../Assets/Edit-blue.svg";
import DeleteIcon from "../../Assets/Trash-purple.svg";
import DeleteAltIcon from "../../Assets/Trash-red.svg";
import CloseIcon from "@mui/icons-material/Close";
import titleCase from "../../utilities/Helper/titleCase";
import {NoticeCategory} from "../../Models/notice";
import NoticeAPI from "../../Services/DoxleAPI/noticeAPI";

interface props {
  index: number;
  category: NoticeCategory;
  categories: NoticeCategory[];
  setCategoryList: Function;
  handleClose: Function;
  handleMenuItemClick: Function;
  handleDeleteCategory: Function;
}

const CategoryMenuItem: React.FC<props> = ({
  index,
  category,
  categories,
  setCategoryList,
  handleClose,
  handleMenuItemClick,
  handleDeleteCategory,
}) => {
  const [title, setTitle] = useState<string>(category.categoryTitle);
  const [editState, setEditState] = useState<boolean>(false);

  const handleTitleKeyUp = async (e: any) => {
    if (e.keyCode !== 13) return;
    const tempList: NoticeCategory[] = categories;
    tempList[index].categoryTitle = e.target.value;
    // const result = await NoticeAPI.editCategory(category.noticeCategoryId, title)
    setCategoryList([...tempList]);
    setEditState(false);
  };

  return (
    <MenuItem
      key={category + "-catemenu"}
      divider
      disableRipple
      sx={{
        justifyContent: "space-between",
        gap: "10px",
        py: 0,
      }}
    >
      {!editState ? (
        <div
          style={{ width: "100%", paddingTop: "6px", paddingBottom: "6px" }}
          onClick={() => handleMenuItemClick(category)}
        >
          {index + 1}) {titleCase(category.categoryTitle)}
        </div>
      ) : (
        <TextField
          autoFocus
          value={titleCase(title)}
          onChange={(e) => setTitle(e.target.value)}
          onKeyUp={(e) => handleTitleKeyUp(e)}
          onBlur={() => setEditState(false)}
          variant="standard"
          InputProps={{
            disableUnderline: true,
            sx: {
              alignContent: "center",
              "& .MuiInputBase-input": {
                fontFamily: "Roboto Mono",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "16px",
              },
            },
          }}
        />
      )}

      <div style={{ display: "flex", gap: "7px" }}>
        <IconButton
          size="small"
          onClick={() => setEditState(true)}
          sx={{
            width: "14px",
            height: "14px",
            background: "url(" + EditIcon + ")",
            "&:hover": {
              background: "url(" + EditAltIcon + ")",
            },
          }}
        />
        <IconButton
          size="small"
          onClick={() => handleDeleteCategory && handleDeleteCategory(category)}
          sx={{
            width: "14px",
            height: "14px",
            background: "url(" + DeleteIcon + ")",
            "&:hover": {
              background: "url(" + DeleteAltIcon + ")",
            },
          }}
        />
      </div>
    </MenuItem>
  );
};
export default CategoryMenuItem;
