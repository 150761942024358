import React from 'react';
import { User } from '../../../Models/user'
import addUserIcon from "../../../Assets/User - Plus.svg";
import {Autocomplete, Box, Chip, Popper, TextField} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import costCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import {Costcode} from "../../../Models/costcode";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import checklistAPI from "../../../Services/DoxleAPI/checklistAPI";
import {Checklist} from "../../../Models/checklist";
import ScheduleAPI from "../../../Services/DoxleAPI/scheduleAPI";
import {ScheduleItem} from "../../../Models/schedules";
import { useSocket } from "../../../Services/Sockets/useSocket";
interface props{
    costCodeId?: string;
    scheduleId?: string;
    checklistId?: string;
    users: User[],
    assignedUsers: User[],
    setAssignedUsers: Function;
}

const AddUser: React.FC<props> = ({costCodeId, scheduleId, checklistId, users, assignedUsers, setAssignedUsers}) => {
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;
    const {height, width} = WindowDimensions();

    const socket = useSocket();


    const notifyAssignedUsersViaSocket = (assignedUserIds : string[]) => {
        // send notification for users via socket
        console.log(
          "%c CLIENT = Sending notification for " + assignedUserIds.length + " users",
          "background:green; color:black"
        );
        assignedUserIds.forEach( userAssigned => {
          socket.send(
            JSON.stringify({
              messageType: "Notification",
              message: {notifiedUser: userAssigned},
            })
          );  
        })
    }

    const updateAssignedUsersViaSocket = (
            costcodeId: any = null, 
            checklistId: any = null, 
            scheduleId: any = null,
            assignedUsers : any) => {
        // send notification for users via socket
        console.log(
          "%c CLIENT = Sending update assignee",
          "background:green; color:black"
        );

        socket.send(
            JSON.stringify({
                messageType: "SocketDataUpdate",
                message: {
                    costCodeId: costcodeId,
                    checklistId: checklistId,
                    scheduleId: scheduleId,
                    assignedUsers: assignedUsers, 
                },
            })
        );  

    }

    const handleEditAssignedUsers = async (objectInput: any) =>{
        try {
            if (costCodeId) {
                let assignedUserIds: string[] = [];
                objectInput.value.forEach((user: User) => user.userId ? assignedUserIds.push(user.userId) : null)
                const response = await costCodeAPI.update(costCodeId, {assignedUsers: assignedUserIds}) as Costcode;
                setAssignedUsers(objectInput.value)
                if (assignedUserIds.length > 0) notifyAssignedUsersViaSocket(assignedUserIds);
                updateAssignedUsersViaSocket(costCodeId, null, null, objectInput.value);
            } else if (checklistId && user?.userId) {
                let assignedUserIds: string[] = [];
                objectInput.value.forEach((user: User) => user.userId ? assignedUserIds.push(user.userId) : null)
                const response = await checklistAPI.updateChecklist(checklistId, {assignedUsers: assignedUserIds}, user.userId) as Checklist;
                setAssignedUsers(objectInput.value)
                if (assignedUserIds.length > 0) notifyAssignedUsersViaSocket(assignedUserIds);
                updateAssignedUsersViaSocket(null, checklistId, null, objectInput.value);
            } else if (scheduleId && user?.userId) {
                let assignedUserIds: string[] = [];
                objectInput.value.forEach((user: User) => user.userId ? assignedUserIds.push(user.userId) : null)
                const response = await ScheduleAPI.update(scheduleId, {assignedUsers: assignedUserIds}) as ScheduleItem;
                setAssignedUsers(objectInput.value)
                if (assignedUserIds.length > 0) notifyAssignedUsersViaSocket(assignedUserIds);
                updateAssignedUsersViaSocket(null, null, scheduleId, objectInput.value);
            }
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }


    return(
            <Autocomplete
                size="small"
                autoHighlight={true}
                multiple
                disableClearable
                limitTags={width < 928 ? 1 : 3}
                popupIcon={
                    assignedUsers.length === 0
                        ?
                        <img src={addUserIcon} alt="addUser" style={{position: "relative", right: width < 928 ? "26px" : "70px"}} />
                        :
                        <ArrowDropDownIcon />
                }
                PopperComponent={(props: any) => <Popper {...props} style={{width: "fit-content"}} placement="bottom-start" />}
                // getOptionLabel={(user) => user.firstName.charAt(0)+""+user.lastName.charAt(0)}
                getOptionLabel={(user) => user.firstName}
                isOptionEqualToValue={(option, value) => option.userId === value.userId}
                onChange={  (event: any, value: User[]) => value ? handleEditAssignedUsers({ "event": event, "value": value} ) : null }
                options={users}
                value={assignedUsers}
                renderOption={(props, user) => (
                    <Box component="li" {...props} key={user.userId ? user.userId : (user.firstName+user.lastName).replaceAll(' ', '')} style={{
                        paddingTop:"20px",
                        paddingBottom:"15px",
                        borderBottom: "0.3px solid rgba(224, 224, 224, 1)",
                        height: "18px",
                        fontSize: "11px",
                        fontFamily:"Inter",
                        letterSpacing: "0.15px",
                    }}>
                        <Chip
                            variant="outlined"
                            label={user.firstName.charAt(0)+""+user.lastName.charAt(0)}
                            // label={user.firstName}
                            size="small"
                            sx={{
                                // backgroundColor:"#0044CC",
                                backgroundColor:"#927CD2",
                                color:"white",
                                fontSize: "8px",
                                height:"15px",
                                // width:"27px",
                                marginRight:"10px",
                                border: "none",
                                borderRadius: "6px"
                            }}
                        />{user.firstName} {user.lastName}
                    </Box>
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={option.userId ? option.userId : index}
                            variant="outlined"
                            // label={option.firstName.charAt(0)+""+option.lastName.charAt(0)}
                            label={option.firstName}
                            size="small"
                            sx={{
                                // backgroundColor:"#0044CC",
                                // color:"white"
                                backgroundColor: "#927CD2",
                                color:"white",
                                fontSize: "8px",
                                height:"15px",
                                border: "none",
                                // width:"27px",
                                fontFamily:"Roboto Mono",
                                fontStyle:"normal",
                                fontweight:"400",
                                borderRadius: "6px"
                            }}

                        />
                    ))
                }
                renderInput={(params) =>
                    <TextField
                        {...params}
                        sx={{ "&::placeholder": {
                                color: "green"
                            },
                            "& .MuiOutlinedInput-root":{
                                // width: "150px",
                                width: width < 928 ? "71px" : "150px",
                                gap: "2px"
                            },
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "0px",
                            },
                            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "0px"
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderWidth: "0px"
                            },
                            "& .MuiOutlinedInput-input": {
                                color: "#000000",
                                borderWidth: "0px",
                                fontSize: "11px",
                                fontWeight: "bold",
                                fontFamily:"Inter"
                            },
                            "&:hover .MuiOutlinedInput-input": {
                                borderWidth: "0px"
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                                borderWidth: "0px"
                            }
                        }}
                    />
                }
                sx={{
                    "&.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon" : {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                    },
                    '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
                        right: "0px"
                    },
                    '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                        padding: '2px',
                        flexWrap: "nowrap"
                    }
                }}
            />
    )
}

export default AddUser