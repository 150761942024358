import React, {useState, useEffect} from "react";
import { Project } from "../../Models/project";
import { User } from "../../Models/user";
import { Autocomplete, Box, TextField, Chip } from '@mui/material';
import { Notice } from "../../Models/notice";
import WindowDimensions from '../WindowDimensions/windowDimension';
import {ReactComponent as ProjectPopperIcon} from "../../Assets/projectDropDownIcon.svg";
import {ReactComponent as NoticePopperIcon} from "../../Assets/noticeDropDownIcon.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface props{
    action: string
    setProjectSelected?: React.Dispatch<React.SetStateAction<Project | null>>
    projectSelected?: Project | null
    projects?: Project[]
    notice?: Notice,
    users?: User[],
    assignedUsers?: User[] | null
    handleEditAssignedUsers?: React.Dispatch<React.SetStateAction<Object>>
    categories?: string[];
    selectedCategory?: string;
    handleEditCategory?: Function;
    popupIcon?: any;
    noticeFilter?: string | null;
  	setNoticeFilter?: Function;
    handleAddCategory?: Function
    openModal?: boolean
}

const AutocompleteCustom: React.FC<props> = ({
    action,
    setProjectSelected,
    projectSelected,
    projects,
    notice,
    users,
    assignedUsers,
    handleEditAssignedUsers,
    categories,
    selectedCategory,
    handleEditCategory,
    noticeFilter,
    setNoticeFilter,
    handleAddCategory,
    openModal,
}) => {

    const {height, width} = WindowDimensions();
    const [value, setValue] = useState<string>(noticeFilter? noticeFilter:"")
    const [assignee, setAssignee] = useState<User[]>(assignedUsers? assignedUsers:[])

    useEffect(() => {
      if (noticeFilter) setValue(noticeFilter)
    }, [noticeFilter])

    useEffect(() => {
      setAssignee(assignedUsers || []);
  }, [assignedUsers])


    return(
        <>
            {
            action === "notice" && users && assignedUsers && notice && handleEditAssignedUsers
            ?
            <Autocomplete
                size="small"
                multiple
                disableClearable
                getOptionLabel={(user) => user.firstName+" "+user.lastName}
                isOptionEqualToValue={(option, value) => option.userId === value.userId}
                limitTags={openModal? -1 : width < 928 ? 1 : 2}
                popupIcon={openModal? <ProjectPopperIcon/> : null}
                onChange={(event: any, value: User[]) => {
                    if(value) {
                      handleEditAssignedUsers(
                          {
                            "event": event, 
                            "value": value, 
                            "notice": notice}
                          );
                      setAssignee(value);
                    }
                  }}
                autoHighlight
                options={users}
                value={assignee}
                renderOption={(props, user) => (
                    <Box component="li" {...props} key={user.userId ? user.userId : (user.firstName+user.lastName).replaceAll(' ', '')} style={{
                        paddingTop:"20px",
                        paddingBottom:"15px",
                        borderBottom: "0.3px solid rgba(224, 224, 224, 1)",
                        height: "18px",
                        fontSize: "11px",
                        fontFamily:"Roboto",
                        letterSpacing: "0.15px"}}>
                            <Chip
                                variant="outlined"
                                label={user.firstName.charAt(0)+""+user.lastName.charAt(0)}
                                size="small"
                                sx={{
                                    backgroundColor:"#927CD2",
                                    borderRadius: "30px",
                                    py: "3px",
                                    px: "8px",
                                    paddingTop: "5px",
                                    color:"white",
                                    fontFamily: 'Roboto',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '8px',
                                    height: '15px',
                                    marginRight:"10px",
                                    border: "none",
                                    "& .MuiChip-label" : {padding: 0},
                                }}
                            />{user.firstName} {user.lastName} 
                    </Box>
                  )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      !openModal?
                      <Chip
                        // key={option.userId ? option.userId : index}
                        variant="outlined"
                        // label={option.firstName.charAt(0)+""+option.lastName.charAt(0)}
                        label={option.firstName}
                        size="small"
                        sx={{
                            backgroundColor:"#927CD2",
                            borderRadius: "30px",
                            py: "3px",
                            px: "8px",
                            paddingTop: "5px",
                            color:"white",
                            fontFamily: 'Roboto',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '8px',
                            height: '15px',
                            "& .MuiChip-deleteIcon" : {display: 'none'},
                            "& .MuiChip-label" : {padding: 0},
                        }}
                        {...getTagProps({ index })}
                      />
                      :                      
                      <div key={index} className="linetag">
                        {option.firstName} {option.lastName}{index < (value.length - 1) ?  ", \u00a0" : " "}
                      </div>
                    ))
                  }
                renderInput={(params) => 
                    <TextField
                    {...params}
                    sx={{ "&::placeholder": {
                            color: "green"
                          },
                          "& .MuiOutlinedInput-root":{
                            // width: "165px",
                            width: openModal? "100%" : "165px",
                            flexWrap: "nowrap",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0px",
                          },
                          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0px"
                          },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0px"
                          },
                          "& .MuiOutlinedInput-input": {
                            color: "#000000",
                            borderWidth: "0px",
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily:"Inter",
                          },
                          "&:hover .MuiOutlinedInput-input": {
                            borderWidth: "0px"
                          },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                            borderWidth: "0px"
                          }
                    }}
                    />
                }    
                sx={{
                  "& .MuiAutocomplete-inputRoot" : {
                    flexWrap: openModal? "wrap": "nowrap",
                  },
                  "&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root":{
                    p: "8px",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    color: "#855CF8",
                    flexWrap: openModal? "wrap": "nowrap",
                  },
                  "& .linetag": {
                    whiteSpace: 'nowrap'
                  }
                }} 
            />
            :
            action === "project" && setProjectSelected && projects && projectSelected
            ?
            <Autocomplete
                  id="projectListDropdown"
                  disableClearable
                  getOptionLabel={(project) => project.siteAddress}
                  isOptionEqualToValue={(option, value) => option.projectId === value.projectId}                
                  onChange={(event: any, value: Project | null) => {
                    if(value){
                      setProjectSelected(value)
                      localStorage.setItem("currentProject", JSON.stringify(value));
                      if (value.projectId)  localStorage.setItem("currentProjectId", value.projectId);
                      if (value?.projectPrefix) sessionStorage.setItem("projectPrefix", value.projectPrefix);
                      else if (value?.siteAddress) sessionStorage.setItem("projectPrefix", value.siteAddress.replace(/ /g,'').substring(0,7).toUpperCase());
                    }
                  }}
                  value={projectSelected}
                  popupIcon={<ProjectPopperIcon/>}
                  options={projects}
                  renderOption={(props, project) => (
                      <Box component="li" {...props} key={project.projectId} style={{
                          paddingTop:"20px",
                          paddingBottom:"15px",
                          borderBottom: "0.3px solid rgba(224, 224, 224, 1)",
                          height: "18px",
                          fontSize: "11px",
                          fontFamily:"Inter",
                          letterSpacing: "0.15px"}}>
                        {project.siteAddress}
                      </Box>
                    )}
                  renderInput={(params) => 
                      <TextField 
                      {...params} 
                      style= {{ height: "20px", paddingBottom: "25px"}}
                      placeholder={projectSelected ? projectSelected.siteAddress : "Select a project to map..."}
                      sx={{ "&::placeholder": {
                              color: "red"
                            },
                            "& .MuiOutlinedInput-root":{
                              width: "280px",
                            },
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                              borderWidth: "0px",
                            },
                            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                              borderWidth: "0px"
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderWidth: "0px"
                            },
                            "& .MuiOutlinedInput-input": {
                              color: "#000000",
                              borderWidth: "0px",
                              fontFamily: 'Roboto Mono',
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: '18px',
                              letterSpacing: 0,
                            },
                            "&:hover .MuiOutlinedInput-input": {
                              borderWidth: "0px"
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                              borderWidth: "0px"
                            },
                            '& .MuiButtonBase-root': {
                              background: "#9974FE",
                              borderRadius: "45%",
                              width: "16px",
                              height: "16px",
                            },
                            '& .MuiButtonBase-root:hover': {
                              background: "#9974FE",
                            },
                            '& .MuiSvgIcon-root': {
                              color: "white"
                            },
                            '& .MuiInputBase-input::placeholder': {
                              opacity: "1 !important"
                            }
                      }}
                      />
                  }
                  sx={{
                    '& .MuiAutocomplete-inputRoot' : {
                      paddingLeft: 0,
                    },
                    '& .MuiAutocomplete-input' : {
                      paddingLeft: 0,
                    },
                    '& .MuiAutocomplete-endAdornment' : {
                      top: 'calc(50% - 10px)',
                    }
                  }}
              />
            :
            action === "noticeCategory" && categories && setNoticeFilter
            ?
            <Autocomplete
                  id="NotificationListDropdown"
                  value={value}
                  disableClearable
                  getOptionLabel={(categories) => categories}
                  isOptionEqualToValue={(option, value) => option === value || value === ""}                
                  onChange={(event: any, value: string | null) => {
                    if(value){
                      if (value === "Add New Category") {
                        handleAddCategory && handleAddCategory();
                      } else if (value === "View All") {
                        setNoticeFilter("");
                        setValue("");
                      } else {
                        setNoticeFilter(value);
                        if (noticeFilter) setValue(noticeFilter)
                      }
                    }
                  }}
                  popupIcon={<NoticePopperIcon />}
                  options={categories.concat(["View All", "Add New Category"])}
                  renderOption={(props, categories) => (
                      <Box component="li" {...props} key={categories} style={{
                          backgroundColor: "white",
                          paddingTop:"14px",
                          paddingBottom:"14px",
                          borderBottom: "0.3px solid rgba(224, 224, 224, 1)",
                          color: "#333333",
                          // borderWidth: "0px",
                          fontFamily: 'Roboto Mono',
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: '14px',
                          }}>
                        {categories}
                      </Box>
                    )}
                  renderInput={(params) => 
                      <TextField 
                      {...params} 
                      style= {{ height: "20px", paddingBottom: "25px"}}
                      // placeholder={noticeFilter ? noticeFilter : "Doxle.NoticeBoard"}
                      placeholder={noticeFilter ? noticeFilter : ""}
                      sx={{ "&::placeholder": {
                              color: "red"
                            },
                            "& .MuiOutlinedInput-root":{
                              width: "150px",
                            },
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                              borderWidth: "0px",
                            },
                            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                              borderWidth: "0px"
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderWidth: "0px"
                            },
                            "& .MuiOutlinedInput-input": {
                              color: "#000000",
                              borderWidth: "0px",
                              fontFamily: 'Roboto Mono',
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: '18px'
                            },
                            "&:hover .MuiOutlinedInput-input": {
                              borderWidth: "0px"
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                              borderWidth: "0px"
                            },
                            '& .MuiButtonBase-root': {
                              background: "#9974FE",
                              // borderRadius: "45%",
                              // width: "16px",
                              // height: "16px",
                            },
                            '& .MuiButtonBase-root:hover': {
                              background: "#9974FE",
                            },
                            '& .MuiSvgIcon-root': {
                              color: "white"
                            },
                            '& .MuiInputBase-input::placeholder': {
                              opacity: "1 !important"
                            }
                      }}
                      />
                  }
                  sx={{
                    '& .MuiAutocomplete-inputRoot' : {
                      paddingLeft: 0,
                    },
                    '& .MuiAutocomplete-input' : {
                      paddingLeft: 0,
                    },
                    marginLeft: "40px"
                  }}
              />
            :
            <></>
            }
        </>
    )
}
export default AutocompleteCustom