import {Divider, Grid, IconButton, NativeSelect, Tooltip} from "@mui/material";
import {ICONS} from "../../../Assets";
import UndoIcon from "@mui/icons-material/Undo";
import TouchAppOutlinedIcon from "@mui/icons-material/TouchAppOutlined";
import ColourMenu from "./ColourMenu";
import React, {useState, useEffect, MouseEventHandler} from "react";
import {DrawingSet, Sheet} from "../../../Models/takeOffs";
import {takeOffContextInterface, useTakeOff} from "../../Providers/TakeOffProvider";
import {toFloat} from "./konvaFunctions/generalFunctions";
import {SingleMeasureContextInterface, useSingleMeasurementContext} from "./SingleMeasurements";

interface props{
    handleEditSheetClicked: MouseEventHandler;
    handleUndoIconClicked: MouseEventHandler;
    setOpenDropzoneDialog: Function;
    single?: true;
}

const DrawingsNavbar: React.FC<props> = ({
                                             handleEditSheetClicked,
                                             handleUndoIconClicked,
                                             setOpenDropzoneDialog,
                                             single
                                         }) => {
    const TakeOffContext = useTakeOff() as takeOffContextInterface
    const SingleMeasureContext = useSingleMeasurementContext() as SingleMeasureContextInterface;
    const {
        drawings, handleUpdateTakeOff,
        currentDrawings, setCurrentDrawings,
        currentSheet, setCurrentSheet,
        setScalingMode, scalingMode
    } = single ? SingleMeasureContext : TakeOffContext
    const [sheets, setSheets] = useState<Sheet[]>(currentDrawings?.sheets || []);
    const [allowNextSheet, setAllowNextSheet] = useState<boolean>(true)
    const [allowPrevSheet, setAllowPrevSheet] = useState<boolean>(false)

    const handleDrawingsSelect = (e:any) => {
        const selectedDrawing = drawings.filter((draw:any)=> (
            draw.setId==e.target.value
        ))[0]
        setCurrentDrawings(selectedDrawing)
        setSheets(selectedDrawing.sheets)
        setCurrentSheet(selectedDrawing.sheets[0])
    }

    const handleSheetsSelect = (e:any) => {
        const selectedSheet = sheets.filter((sheet:any)=> (
            sheet.sheetId==e.target.value
        ))[0]
        setCurrentSheet(selectedSheet)
    }

    const handleNextSheet = (isNext:boolean) => {
        const currentSheetIndex = sheets.findIndex((sheet:any)=> sheet.sheetId === currentSheet?.sheetId)
        if (currentSheetIndex === -1) return;
        setCurrentSheet((currentSheet:any)=>{
            if (isNext && currentSheetIndex !== (sheets.length-1)) {
                return sheets[currentSheetIndex + 1]
            }
            if (!isNext && currentSheetIndex !== 0) {
                return sheets[currentSheetIndex - 1]
            }
            return currentSheet
        })
    }

    const handleRulerIcon = () => {
        if(currentSheet) setScalingMode(true)
    }

    useEffect(()=>{
        const currentSheetIdx = sheets.findIndex((sheet:any)=> sheet.sheetId === currentSheet?.sheetId)
        if (currentSheetIdx >= 0 && sheets.length > 0 ) {
            setAllowNextSheet(currentSheetIdx==(sheets.length-1)?false:true)
            setAllowPrevSheet(currentSheetIdx==0?false:true)
        }

    }, [currentSheet])


    useEffect(()=>{
        setSheets(currentDrawings?.sheets ? [...currentDrawings.sheets] : [])
    }, [currentDrawings])


    return(
        <Grid container className="drawing-nav-bar">
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={4}>
                        <NativeSelect
                            style={{ marginTop: "10px", marginLeft: "20px" }}
                            onChange={handleDrawingsSelect}
                        >
                            {drawings.map((element: any, idx: any) => {
                                return (
                                    <option key={idx} value={element.setId}>{element.name}</option>
                                );
                            })}
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: "13px" }}>
                        <small>
                            <NativeSelect
                                value={currentSheet?.sheetId}
                                onChange={handleSheetsSelect}
                            >
                                {sheets.map((element: any, idx: any) => {
                                    return (
                                        <option key={idx} value={element.sheetId}>{element.title}</option>
                                    );
                                })}
                            </NativeSelect>
                        </small>
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: "15px" }}>
                        <IconButton
                            aria-label="prev"
                            sx={{ padding: "0", mx: "8px"}}
                            onClick={()=>handleNextSheet(false)}
                            disabled={!allowPrevSheet}
                        >
                            <img src={allowPrevSheet?
                                ICONS.ArrowLeftCircle:
                                ICONS.ArrowLeftCircleOff}
                                 alt="prev" />
                        </IconButton>

                        <IconButton
                            aria-label="next"
                            sx={{ padding: "0" }}
                            onClick={()=>handleNextSheet(true)}
                            disabled={!allowNextSheet}
                        >
                            <img src={allowNextSheet?
                                ICONS.ArrowRightCircle:
                                ICONS.ArrowRightCircleOff}
                                 alt="next" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: "15px" }}>
                        <img src={ICONS.Maximize} alt="Maximize" />
                    </Grid>
                    <Grid item xs={4} style={{ float: "right" }}>
                        <div style={{ position: "relative", float: "right", display: scalingMode ? "none" : "unset"}}>
                            <ul className="measurement-header-icon-box">
                                <li className="icon-line-above clickable-icon">
                                    <IconButton onClick={handleUndoIconClicked}>
                                        <Tooltip title="Undo">
                                            <UndoIcon style={{color:"#9974fe"}}/>
                                        </Tooltip>
                                    </IconButton>
                                </li>
                                <li className="icon-line-above">
                                    <img src={ICONS.Linehorizontal} alt="" />
                                </li>
                                <li className="icon-line-above clickable-icon">
                                    <IconButton onClick={() => setOpenDropzoneDialog(true)}>
                                        <Tooltip title="Add Plans">
                                            <img
                                                src={ICONS.Plus}
                                                style={{
                                                    width: "19px",
                                                    height: "19px",
                                                    marginTop: "3px",
                                                }}
                                                alt=""
                                            />
                                        </Tooltip>
                                    </IconButton>
                                </li>
                                <li className="icon-line-above">
                                    <img src={ICONS.Linehorizontal} alt="" />
                                </li>
                                <li className="icon-line-above clickable-icon">
                                    <IconButton onClick={handleRulerIcon}>
                                        <Tooltip title="Scale Current Sheet">
                                            {
                                                !currentSheet?.scale || toFloat(currentSheet?.scale) === 0
                                                    ?
                                                    <img className="pulse-alert" src={ICONS.RulerAlert} alt="" />
                                                    :
                                                    <img src={ICONS.Ruler} alt="" />
                                            }

                                        </Tooltip>
                                    </IconButton>
                                </li>
                                <li className="icon-line-above">
                                    <img src={ICONS.Linehorizontal} alt="" />
                                </li>
                                <li
                                    className="icon-line-above clickable-icon"
                                >
                                    <IconButton onClick={handleEditSheetClicked}>
                                        <Tooltip title="Edit Sheet Names">
                                            <img
                                                src={ICONS.EditSvgIcon}
                                                style={{ width: "23px", height: "23px" }}
                                                alt=""
                                            />
                                        </Tooltip>
                                    </IconButton>
                                </li>
                                <li className="icon-line-above">
                                    <img src={ICONS.Linehorizontal} alt="" />
                                </li>
                                <li className="icon-line-above clickable-icon">
                                    <IconButton onClick={handleUpdateTakeOff}>
                                        <Tooltip title="Save Measurements">
                                            <ICONS.checkIcon style={{color: "#9974fe"}} />
                                        </Tooltip>
                                    </IconButton>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    )
}

export default DrawingsNavbar