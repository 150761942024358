import { Box, MenuItem, Modal, Select, styled } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Mail } from '../../../Models/mail'
import MailWaitingScreen from './MailWaitingScreen'
import { ReactComponent as DueDateExpandLogo } from '../../../Assets/MailroomDueExpand.svg'
import MailViewSubContent from './GeneralComponents/MailViewSubContent'
import SubContentModalView from './ModalSubContentView/SubContentModalView'
import AnimatedSubContentMask from './GeneralComponents/AnimatedSubContentMask'
import { AnimatePresence, motion } from 'framer-motion'

type Props = {
  selectedMail: Mail | undefined
}

const dueOptions = ['Due This Week', 'Due This Fornight', 'Due This Month']

export interface MailSubContent {
  contentId: string
  mailId: string
  contentTitle: string
  contentDescription: string
  user: string
  sentDate: string
  completed: boolean
  contractor: string
}
const dummySubContent: MailSubContent[] = [
  {
    contentId: '1234',
    mailId: '1',
    contentTitle: '24 Studley - Garage Measurement Short by 300mm',
    contentDescription:
      'Thank You Bonnie. Your Job Number For This Project Will Be 14256. Please Cite It Any Communications About The Pool  So We Can Talk About It. What Would Be a Good Time To Call Tomorrow?',
    user: 'Selvin',
    sentDate: '23.12.22',
    completed: false,
    contractor: 'Sam Tyrikos ',
  },
  {
    contentId: '33',
    mailId: '2',
    contentTitle: 'Invoice INV-0019 from Universal Sms for Byson Group',
    contentDescription: 'Here’s invoice INV-0119 for AUD 825',
    user: 'Kim',
    sentDate: '23.12.22',
    completed: true,
    contractor: 'Universal Sms Pty Ltd ',
  },
  {
    contentId: 'sd99',
    mailId: '3',
    contentTitle: '1 Cambridge - Cabinetry Design',
    contentDescription:
      'Happy Friday Everyone! Just writing to see if there’s any update on the preliminary drawings from consultant?',
    user: 'Maju',
    sentDate: '24.12.22',
    completed: false,
    contractor: 'Nicole Chew',
  },
]
const MailroomMainView = ({ selectedMail }: Props) => {
  const [dueType, setdueType] = useState<
    'Due This Week' | 'Due This Fornight' | 'Due This Month'
  >('Due This Week')
  const handleChangeDueType = (
    value: 'Due This Week' | 'Due This Fornight' | 'Due This Month'
  ) => {
    setdueType(value)
  }

  const [currentSubContent, setCurrentSubContent] = useState<
    MailSubContent | undefined
  >(undefined)
  //####control animation position of currentSubContent#######
  const [currentSubContentPosition, setCurrentSubContentPosition] = useState<
    { x: number; y: number; width: number; height: number } | undefined
  >(undefined)

  // height of mainViewTitleSectionContainer to determine the right position for animated item based on y axis
  //if change this height, the position of the animated item also change
  const titleSectionHeight = 80

  const rootAnimationViewRef = useRef<HTMLDivElement>(null)
  const [mainViewRootSize, setMainViewRootSize] = useState<
    { width: number; height: number } | undefined
  >(undefined)
  const getmainViewRootSize = () => {
    const width = rootAnimationViewRef.current?.offsetWidth
    const height = rootAnimationViewRef.current?.offsetHeight
    setMainViewRootSize({
      width: width as number,
      height: height as number,
    })
  }
  // Get the position of the subcontent in the beginning
  useEffect(() => {
    getmainViewRootSize()
  }, [currentSubContent, currentSubContentPosition])

  // Re-calculate X and Y of the subcontent when the window gets resized by the user
  useEffect(() => {
    window.addEventListener('resize', getmainViewRootSize)
  }, [])
  //####################################################

  return selectedMail ? (
    <Box className='mailroomMainViewRootContainer' ref={rootAnimationViewRef}>
      {/*TITLE SECTION */}
      <Box
        className='mainViewTitleSectionContainer'
        style={{ height: `${titleSectionHeight}px` }}
      >
        {/*Mail title section */}
        <Box className='mailTitleContainer'>{selectedMail.subject}</Box>

        {/*due date display section */}
        <Box className='dueDateDisplayContainer'>
          <DueDateExpand
            IconComponent={DueDateExpandLogo}
            value={dueType}
            onChange={(event) => {
              handleChangeDueType(
                event.target.value as
                  | 'Due This Week'
                  | 'Due This Fornight'
                  | 'Due This Month'
              )
            }}
            sx={{
              '.css-3qbkez-MuiSelect-icon': {
                top: '5.5px',
                color: '#226622',
                right: '12px',
              },
              '.css-10q54uo-MuiSelect-icon': {
                top: '5.5px',
                color: '#226622',
                right: '12px',
              },
              '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input:focus':
                {
                  border: '0 !important',
                },
            }}
          >
            {dueOptions.map((option, index) => {
              return (
                <DueDateOption value={option} key={index}>
                  {option}
                </DueDateOption>
              )
            })}
          </DueDateExpand>
        </Box>
      </Box>

      {/*Content SECTION */}
      <Box
        className='mainViewContentSectionContainer'
        style={{
          opacity: currentSubContent ? 0.3 : 1,
          position: 'relative',
          backgroundColor: currentSubContent
            ? 'rgba(229,229,229, 0.85'
            : undefined,
        }}
      >
        {dummySubContent.map((content, index) => {
          return (
            <MailViewSubContent
              content={content}
              key={index}
              setCurrentSubContent={setCurrentSubContent}
              currentSubContent={currentSubContent}
              setCurrentSubContentPosition={setCurrentSubContentPosition}
            />
          )
        })}
      </Box>

      {/* {currentSubContent ? (
        <SubContentModalView currentSubContent={currentSubContent} />
      ) : null} */}

      <AnimatePresence>
        {currentSubContent && currentSubContentPosition && mainViewRootSize && (
          <motion.div
            animate={{ opacity: [0, 1] }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: [1, 0] }}
            style={{
              position: 'absolute',
              width: '90%',
              left: 0,
              top: 0,
              padding: '0 5% 0 5%',
              height: '100%',
              backgroundColor: 'rgba(299,299,299,0.6)',
              backdropFilter: 'blur(5px)',
            }}
          >
            <SubContentModalView
              currentSubContent={currentSubContent}
              setCurrentSubContentPosition={setCurrentSubContentPosition}
              setCurrentSubContent={setCurrentSubContent}
              currentSubContentPosition={currentSubContentPosition}
              titleSectionHeight={titleSectionHeight}
              rootViewSize={mainViewRootSize}
            />
            {/* <AnimatedSubContentMask
            currentSubContent={currentSubContent}
            setCurrentSubContentPosition={setCurrentSubContentPosition}
            setCurrentSubContent={setCurrentSubContent}
            currentSubContentPosition={currentSubContentPosition}
            titleSectionHeight={titleSectionHeight}
            rootViewSize={mainViewRootSize}
          /> */}
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  ) : (
    <MailWaitingScreen />
  )
}

export default MailroomMainView

const DueDateExpand = styled(Select)({
  width: '177px',
  height: '29px',
  backgroundColor: '#F1F2F4',
  borderRadius: '7px',
  border: '0 !important',
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '13px',
  lineHeight: '17px',
  /* identical to box height */

  color: '#226622',
})

const DueDateOption = styled(MenuItem)({
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '13px',
  lineHeight: '17px',
  /* identical to box height */
  marginBottom: '4px',
  color: '#226622',
})
