import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import "./Cashflow.css";
import { ReactComponent as DoxleLogo } from "../../../Assets/doxleTimelineMatrixLogo.svg";
import { ReactComponent as PreviousLogo } from "../../../Assets/previousPagePurple.svg";
import { ReactComponent as NextLogo } from "../../../Assets/nextPagePurple.svg";
import titleCase from "../../../utilities/Helper/titleCase";
import {
  getAllDaysInMonthByDateInWeek,
  getAllMonthInRange,
  getLastDateOfMonth,
  IMonthInfo,
} from "../../../utilities/FunctionUtilities";
import { Company } from "../../../Models/company";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import { ProjectCashflow } from "../../../Models/project";
import Loading from "../../../utilities/Lottie/Loading";
import CashflowTableRow from "./CashflowTableRow";
import stageAPI from "../../../Services/DoxleAPI/stageAPI";
import { motion } from "framer-motion";
import {
  calculateTotalCashflowInMonth,
  getAllStagePaymentInMonth,
  getStageColor,
} from "./CashflowCommonResources";
import { useViewportSize } from "../../../utilities/Custom Hooks/useViewportSize";
type Props = {
  company: Company | undefined;
};

const CashflowTable = ({ company }: Props) => {
  const unmounted = useMountStatus();
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn } = authContext;
  // const viewportSize = useViewportSize();
  const widthSizeOfDivider = 50; //this is the size of divider between cells in px, used to calculate width size of other data cells

  const WeeksOfMonth: string[] = ["Week 1", "Week 2", "Week 3", "Week 4"];
  let today: Date = new Date();

  //this state to store the month range used to render stage actual data, later could be used for performing next or previous btn to switch period
  const [monthRange, setmonthRange] = useState<IMonthInfo[]>(
    getAllMonthInRange(
      today,
      new Date(today.getFullYear(), today.getMonth() + 2, 15)
    )
  );

  const [cashflowProjects, setcashflowProjects] = useState<
    ProjectCashflow[] | undefined
  >(undefined);

  const [loading, setloading] = useState<boolean>(false);

  const fetchCashflowProjects = async () => {
    if (company) {
      setloading(true);
      try {
        const response = await stageAPI.getCashflowProject(company.companyId);
        if (unmounted) return;

        if (response) setcashflowProjects([...response]);
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
      setloading(false);
    }
  };

  useEffect(() => {
    fetchCashflowProjects();
  }, [company]);

  const handleSwitchMonthRange = (action: "next" | "previous") => {
    if (action === "previous") {
      let firstMonthFromMonthRange: number = monthRange[0].monthValue;
      let firstYearFromMonthRange: number = monthRange[0].yearValue;
      let newEndMonthRange: Date = new Date(
        firstYearFromMonthRange,
        firstMonthFromMonthRange,
        15
      ); //set any date as long as month and year is same

      let newStartMonthRange: Date = new Date(
        firstYearFromMonthRange,
        firstMonthFromMonthRange - 3,
        15
      );
      console.log("newEndMonthRange:",newEndMonthRange)
      console.log("newStartMonthRange:",newStartMonthRange)
      console.log("MONTHS:",getAllMonthInRange(newStartMonthRange, newEndMonthRange))
      setmonthRange([
        ...getAllMonthInRange(newStartMonthRange, newEndMonthRange),
      ]);
    } else {
      let lastMonthFromMonthRange: number =
        monthRange[monthRange.length - 1].monthValue;
      let lastYearFromMonthRange: number =
        monthRange[monthRange.length - 1].yearValue;
      let newEndMonthRange: Date = new Date(
        lastYearFromMonthRange,
        lastMonthFromMonthRange + 4,
        15
      ); //set any date as long as month and year is same

      let newStartMonthRange: Date = new Date(
        lastYearFromMonthRange,
        lastMonthFromMonthRange + 1,
        15
      );
      setmonthRange([
        ...getAllMonthInRange(newStartMonthRange, newEndMonthRange),
      ]);
    }
  };
  const populateEmptyRows = useCallback((currentMonthRange: IMonthInfo[]) => {
    let numOfRows: JSX.Element[] = [];
    for (let i = 0; i <= 15; i++)
      numOfRows.push(
        <EmptyRow
          className="cashflow-table-data-row"
          key={`#emptyRow#${i}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            opacity: {
              duration: 0.8,
              ease: "easeInOut",
            },
          }}
        >
          <EmptyTitleCell>
            {i === 2 && cashflowProjects ? "TOTAL" : null}
          </EmptyTitleCell>
          <EmptyDataRow>
            {currentMonthRange.map((month, monthIndex) => {
              return (
                <EmptyMonthCell
                  key={`headerMonth#${month.monthValue}-${monthIndex}-emptyRows#${i}`}
                  style={{
                    width: `calc((100% - ${
                      widthSizeOfDivider * (currentMonthRange.length - 1)
                    }px)/3)`,
                    borderRight:
                      monthIndex < currentMonthRange.length - 1
                        ? `${widthSizeOfDivider}px solid #E9E1FF`
                        : undefined,
                  }}
                >
                  {i === 2 && cashflowProjects
                    ? `$ ${calculateTotalCashflowInMonth(
                        cashflowProjects,
                        month.monthValue,
                        month.yearValue
                      ).toLocaleString()}`
                    : null}
                </EmptyMonthCell>
              );
            })}
          </EmptyDataRow>
        </EmptyRow>
      );

    return numOfRows;
  }, []);
  return (
    <div
      style={{
        height: "calc(100vh - 40px)",
        width: "90%",
        display: "flex",
        padding: "20px 5% 20px 5%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <RootCashflowTable>
        <CashflowTableHeaderContainer
          className="cashflow-table-header-container"
          style={{ filter: loading ? "blur(3px)" : undefined }}
        >
          <TableHeaderLogoCell>
            <DoxleLogo />
            <HeaderLogoCellTextContainer>
              <CashflowText>Cashflow</CashflowText>

              <VersionText>{titleCase("Version 1.0")} </VersionText>
            </HeaderLogoCellTextContainer>
          </TableHeaderLogoCell>

          <HeaderMonthRow>

            {monthRange.map((month, monthIndex) => {
              return (
                <HeaderMonthCell
                  key={`headerMonth#${month.monthValue}-${monthIndex}`}
                  style={{
                    width: `calc((100% - ${
                      widthSizeOfDivider * (monthRange.length - 1)
                    }px)/3)`,

                    borderRight:
                      monthIndex < monthRange.length - 1
                        ? `${widthSizeOfDivider}px solid #E9E1FF`
                        : undefined,
                    overflow: "hidden",
                  }}
                >
                  <MonthNameText>{month.monthName}</MonthNameText>
                  <YearNameText>{month.yearValue}</YearNameText>

                  {/* {getAllDaysInMonthByDateInWeek(
                    month.monthValue,
                    month.yearValue,
                    undefined,
                    viewportSize.width >= 2300
                      ? 1
                      : viewportSize.width < 2300 && viewportSize.width >= 2000
                      ? 3
                      : 5
                  ).map((day, dayIndex) => {
                    return (
                      <WeekDatesMilestone
                        key={`dateMilestone#${day.dayValue}-${dayIndex}`}
                        style={{
                          left: `calc(${
                            (day.dayValue /
                              getLastDateOfMonth(month.monthValue)) *
                            100
                          }% - ${dayIndex === 0 ? 5 : 10}px)`,
                          borderLeft: `0.5px solid #88A4CD`,
                          borderRight: ` 0.5px solid #88A4CD`,
                        }}
                      >
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <DateMilestone
                            style={{
                              left: dayIndex <= 1 ? "-0.5em" : "-0.8em",
                            }}
                          >
                            {day.dayValue < 10
                              ? `0${day.dayValue}`
                              : day.dayValue}
                          </DateMilestone>
                          <WeekDateText>{day.dateInWeek}</WeekDateText>
                        </div>
                      </WeekDatesMilestone>
                    );
                  })} */}

                  {WeeksOfMonth.map((week, weekIndex) => {
                    return (
                      <WeekDatesMilestone
                        key={`weekMilestone-month#${month.monthValue}#${week}-${weekIndex}`}
                        style={{
                          left: `calc(${weekIndex * 25}% + ${25 / 2}%)`,
                          borderLeft: `0.5px solid #88A4CD`,
                          borderRight: ` 0.5px solid #88A4CD`,
                        }}
                      >
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <DateMilestone
                            style={{
                              left: "-1.4em",
                            }}
                          >
                            {week}
                          </DateMilestone>
                          {/* <WeekDateText>{day.dateInWeek}</WeekDateText> */}
                        </div>
                      </WeekDatesMilestone>
                    );
                  })}
                </HeaderMonthCell>
              );
            })}

            <PreviousIconContainer
              whileHover={{ y: [0, -4, 0] }}
              whileTap={{ scale: 1.4 }}
              onClick={() => handleSwitchMonthRange("previous")}
            >
              <PreviousLogo />
            </PreviousIconContainer>
            <NextIconContainer
              whileHover={{ y: [0, -4, 0] }}
              whileTap={{ scale: 1.4 }}
              onClick={() => handleSwitchMonthRange("next")}
            >
              <NextLogo />
            </NextIconContainer>
          </HeaderMonthRow>
        </CashflowTableHeaderContainer>

        <MatrixTableBodyContainer
          style={{
            overflow: loading ? "hidden" : "scroll",
          }}
        >
          {cashflowProjects
            ? cashflowProjects
                .filter((project) => project.projectStatus === "AC")
                .sort((a, b) =>
                  new Date(a.endDate) > new Date(b.endDate) ? 1 : -1
                )
                .map((project, projectIndex) => {
                  return (
                    <CashflowTableRow
                      key={project.projectId}
                      project={project}
                      rowOrder={projectIndex}
                      monthRange={monthRange}
                      widthSizeOfDivider={widthSizeOfDivider}
                    />
                  );
                })
            : null}
          {/* 
          {cashflowProjects ? (
            <EmptyRow className="cashflow-table-data-row">
              <TableTotalTitleCell>TOTAL</TableTotalTitleCell>
              <TotalDataRow>
                {monthRange.map((month, monthIndex) => {
                  return (
                    <TotalDataCell
                      key={`totalCell#${monthIndex}`}
                      style={{
                        width: `calc((100% - ${
                          widthSizeOfDivider * (monthRange.length - 1)
                        }px)/3)`,
                        borderRight:
                          monthIndex < monthRange.length - 1
                            ? `${widthSizeOfDivider}px solid #E9E1FF`
                            : undefined,
                      }}
                    >
                      $
                      {calculateTotalCashflowInMonth(
                        cashflowProjects,
                        month.monthValue,
                        month.yearValue
                      ).toLocaleString()}
                    </TotalDataCell>
                  );
                })}
              </TotalDataRow>
            </EmptyRow>
          ) : null} */}
          {populateEmptyRows(monthRange)}

          {loading ? (
            <LoadingContainer>
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  top: 0,
                  left: 0,
                  filter: "blur(3px)",
                  zIndex: 2,
                  backgroundColor: "white",
                  opacity: 0.75,
                }}
              ></div>
              <Loading />
            </LoadingContainer>
          ) : null}
        </MatrixTableBodyContainer>
      </RootCashflowTable>
    </div>
  );
};

export default CashflowTable;

//################STYLED COMPONENTS ######################
const RootCashflowTable = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const CashflowTableHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direciton: row;
  border-bottom: 1px solid #cbdae2;
`;
const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justfy-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;
const TableHeaderLogoCell = styled.div`
  height: 100%;
  width: calc(18% - 10px);
  padding-left: 10px;
  max-width: 300px;
  min-width: 250px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const HeaderLogoCellTextContainer = styled.div`
  margin-left: 10px;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const CashflowText = styled.span`
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #7949ff;
  text-transform: uppercase;
`;
const VersionText = styled.span`
  font-family: "Nostromo Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 12px;
  color: #7657cb;
`;
const HeaderMonthRow = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direciton: row;
  border-left: 1px solid #cbdae2;
  position: relative;
`;

const MonthNameText = styled.div`
  font-family: "Nostromo Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
`;
const YearNameText = styled.div`
  font-family: "Nostromo Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #7657cb;
`;

const HeaderMonthCell = styled.div`
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  position: relative;
`;

const WeekDatesMilestone = styled(motion.div)`
  height: 10px;
  position: absolute;
  bottom: 0;
`;

const MatrixTableBodyContainer = styled.div`
  width: 100%;
  flex: 1;
  flex-direction: column;
  padding-bottom: 5px;
  overflow: scroll;
  position: relative;
`;

const DateMilestone = styled.div`
  position: absolute;
  top: calc(-0.7rem);
  font-family: "Nostromo Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 12px;
  color: #7657cb;
  display: inline-block;
  white-space: nowrap;
`;

const WeekDateText = styled.div`
  position: absolute;
  top: calc(-0.8rem - 0.7em);
  left: 0.6em;
  font-family: "Nostromo Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 7px;
  line-height: 9px;
  color: #855df8;
`;
const EmptyRow = styled(motion.div)`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #cbdae2;
`;

const EmptyTitleCell = styled.div`
  height: 100%;
  width: calc(18% - 10px);
  padding-left: 10px;
  max-width: 300px;
  min-width: 250px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  font-family: "Nostromo Regular";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
`;

const EmptyDataRow = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  border-left: 1px solid #cbdae2;
`;
const EmptyMonthCell = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Nostromo Regular";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
`;

const PreviousIconContainer = styled(motion.div)`
  position: absolute;
  top: calc(50% - 7.5px);
  left: 4px;
`;

const NextIconContainer = styled(motion.div)`
  position: absolute;
  top: calc(50% - 7.5px);
  right: 4px;
`;
//######################################################
