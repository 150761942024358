import axios from "axios";
import {DrawingSet, Sheet} from '../../Models/takeOffs';
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";


const getList = async (projectId: string)  => {
    // Get's a list of take offs for a given project selected with project Id
    let result: DrawingSet[] = []
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        const params = { project: projectId }
        const response = await axios.get(
            baseAddress+"/drawings/",
            {
                headers: { Authorization: "Bearer " + accessToken },
                params: params
            }
        )
        result = response.data.results as Array<DrawingSet>;
        console.log("DRAWING SETS", response.data)
    } catch (error) {
        console.error("ERROR FETCHING DRAWING SETS", error)
    }
    return result
}

interface uploadDrawingsProps {
    file: Blob;
    name: string;
    projectId: string;
    companyName: string;
    lowRes?: "TRUE"|"FALSE";
}
const uploadDrawings = async ({name, projectId, companyName, file, lowRes}: uploadDrawingsProps)  => {
    let result: DrawingSet | boolean = false
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        const formData = new FormData()
        formData.append("company", companyName);
        formData.append("project", projectId);
        formData.append("name", name);
        if (lowRes) formData.append("lowRes", lowRes);
        formData.append("file", file);

        const response = await axios.post(
            baseAddress+"/drawings/upload/", formData,
            {
                headers: { Authorization: "Bearer " + accessToken }
            }
        )
        if(response.status === 201){
            console.log("UPLOADED FILES", response.data.files)
            result = response.data as DrawingSet
        }
    } catch (error) {
        console.error("ERROR SAVING FILES", error)
    }
    return result
}

const removeSet = async (setId: string, deleteMeasures:boolean)  => {
    let result: boolean = false;
    if (offline){ result = true; return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken) { throw "AccessTokenNotFound" }

    try {
        const response = await axios.post(
            baseAddress+"/drawings/del/"+setId+"/",
            {deleteMeasures: deleteMeasures},
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.status === 204;
    } catch (error) {
        console.error("ERROR DELTING COSTCODE", error)
    }
    return result
}

const updateSet = async (setId: string, name:string, sheets: Sheet[]|undefined) => {
    // Update one or more property of a costcode without affecting other values
    let result: DrawingSet | undefined;
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        let body:any = {name: name}
        let sheetData:any = [];
        if (sheets) {
            sheets.forEach((sheet: Sheet) => sheetData.push({id: sheet.sheetId, title: sheet.title}));
            body.sheets = sheetData;
        }
        const response = await axios.patch(
            baseAddress+"/drawings/"+setId+"/",
            body,
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.data as DrawingSet;
        console.log("UPDATED DRAWING SET", result)
    } catch (error) {
        console.error("ERROR UPDATING DRAWING SET", error)
    }
    return result
}


const removeSheet = async (sheetId: string, deleteMeasures: boolean)  => {
    let result: boolean = false;
    if (offline){ result = true; return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken) { throw "AccessTokenNotFound" }

    try {
        if (offline){ return {} }
        const accessToken: string = CookieService.get("access_token");
        if (!accessToken){ throw "AccessTokenNotFound" }

        const response = await axios.post(
            baseAddress+"/sheet/del/"+sheetId+"/",
            {deleteMeasures: deleteMeasures},
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.status === 204;
    } catch (error) {
        console.error("ERROR DELTING COSTCODE", error)
    }
    return result
}

interface UpdateBody {
    title?: string;
    scale?: number;
}

const updateSheet = async (
    sheetId: string | undefined,
    { title, scale}: UpdateBody
) => {
    // Update one or more property of a costcode without affecting other values
    let result: Sheet | undefined;
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        let body: any = {}
        if (title) {body.title = title;}
        if (scale) {
            const tScale = scale.toPrecision(13)
            body.scale = tScale;
        }

        const response = await axios.patch(
            baseAddress+"/sheet/"+sheetId+"/",
            body,
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.data as Sheet;
        console.log("UPDATED SHEET", result)
    } catch (error) {
        console.error("ERROR UPDATING SHEET", error)
    }
    return result
}

const updateMultiSheet = async (
    setId: string ,
    scale: number
) => {
    let result: DrawingSet | undefined;
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        console.log("scale", scale)
        const response = await axios.post(
            baseAddress+"/drawings/scale/"+setId+"/",
            {scale: scale.toPrecision(13)},
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.data as DrawingSet;
    } catch (error) {
        console.error("ERROR UPDATING SHEETS", error)
    }
    return result;
}

const drawingAPI = {
    getList,
    uploadDrawings,
    removeSet,
    updateSet,
    removeSheet,
    updateSheet,
    updateMultiSheet
};

export default drawingAPI