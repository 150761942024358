import plan from "./plan.png";
import tableIcon from "./TableUnion.svg";
import searchIcon from "./SearchUnion.svg";
import filterIcon from "./Vector.svg";
import verticalIcon from "./Vector2.svg";
import modulesIcon from "./Modules.svg";
import trashIcon from "./Trash-purple2.svg";
import plusSquareIcon from "./Plus - Square.svg";
import editIcon from "./Edit.svg";
import ArrowLeftCircle from "./Arrow-Left-Circle.svg";
import ArrowLeftCircleOff from "./Arrow-Left-Circle-off.svg";
import ArrowRightCircle from "./Arrow-right-Circle.svg";
import ArrowRightCircleOff from "./Arrow-right-Circle-off.svg";
import StopSquare from "./Stop_square.svg";
import Polygon from "./Polygon.svg";
import Maximize from "./Maximize.svg";
import Ellipse from "./Ellipse 1.svg";
import Brush from "./Brush.svg";
import SwatchBook from "./Swatchbook.svg";
import LineSvg from "./Line-svg.svg";
import Ruler from "./Ruler.svg";
import RulerAlert from "./RulerAlert.svg";
import Plus from "./Plus.svg";
import Linehorizontal from "./Linehorizontal.svg";
import EditSvgIcon from "./EditSvgIcon.svg";
import Rectangle from "./Rectangle 1401.svg";
import CheckIcon from '@mui/icons-material/Check';

const ICONS = {
	plan : plan,
	tableIcon : tableIcon,
	searchIcon : searchIcon,
	filterIcon : filterIcon,
	verticalIcon : verticalIcon,
	modulesIcon : modulesIcon,
	trashIcon : trashIcon,
	plusSquareIcon : plusSquareIcon,
	editIcon : editIcon,
	ArrowLeftCircle : ArrowLeftCircle,
	ArrowLeftCircleOff : ArrowLeftCircleOff,
	ArrowRightCircle : ArrowRightCircle,
	ArrowRightCircleOff : ArrowRightCircleOff,
	StopSquare : StopSquare,
	Polygon : Polygon,
	Maximize : Maximize,
	Ellipse : Ellipse,
	Brush : Brush,
	SwatchBook : SwatchBook,
	LineSvg : LineSvg,
	Ruler : Ruler,
	RulerAlert : RulerAlert,
	Plus : Plus,
	Linehorizontal : Linehorizontal,
	EditSvgIcon : EditSvgIcon,
	Rectangle: Rectangle,
	checkIcon : CheckIcon
}

export {ICONS}