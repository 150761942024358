import React from "react";
import { Quote } from "../../../Models/quote";
import {Bill} from "../../../Models/bill";

interface props{
    quote?: Quote;
    bill?: Bill;
    onClick: React.Dispatch<React.SetStateAction<any>>
}

const SendEmail: React.FC<props> = ({
    quote,
    bill,
    onClick
}) => {
    return(
        <div onClick={onClick}>Send Now</div>   
    )
}
export default SendEmail