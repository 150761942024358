import { Box } from "@mui/material";
import { motion } from "framer-motion";
import { type } from "os";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BasicCostCode } from "../../../Models/costcode";
import { Project, ProjectCashflow } from "../../../Models/project";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import stageAPI from "../../../Services/DoxleAPI/stageAPI";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import {
  convertMonthToText,
  getAllMonthContract,
  getPositionOfStageMilestone,
  IStagePayment,
} from "./ProjectTimelineCommonResources";

type Props = { project: ProjectCashflow };

const contractPrice: number = 1700000;
const ProjectCardTimeBarTracker = ({ project }: Props) => {
  const unmounted = useMountStatus();
  //###############AUTH CONTROL####################
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, user } = authContext;
  const [contractDuration, setcontractDuration] = useState<number[]>(
    getAllMonthContract(project.startDate, project.endDate)
  );
  console.log("STAGES OF ", project.siteAddress, ":", project.stages);
  return (
    <RootProjectCardTimeBarTracker
      // initial={{ scaleX: 0 }}
      // animate={{ scaleX: [0, 1] }}
      // transition={{ scaleX: { duration: 1.5, ease: "easeInOut" } }}
      initial={{ width: 0 }}
      animate={{ width: "calc(100% - 30px)", x: 0 }}
      transition={{
        width: {
          duration: 1,
          type: "spring",
          stiffness: 70,
          mass: 0.3,
          velocity: 1.4,
          bounce: 0,
          damping: 10,
          restDelta: 0.5,
        },
      }}
    >
      {/*Month Milestone */}
      <MonthMilestoneContainer>
        {contractDuration.map((monthValue, index) => {
          return (
            <MonthMileStone
              key={`mileStone#${index}`}
              style={{ width: `${(1 / contractDuration.length) * 100}%` }}
            >
              {/*Month name */}
              <MonthName>
                {convertMonthToText(monthValue)}
                <Box
                  sx={{
                    position: "absolute",
                    top: "-0.2em",
                    right: "-1em",
                    fonFamily: "IBM Plex Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "6px",
                    lineHeight: "7px",
                    color: "#653ED2",
                  }}
                >
                  23
                </Box>
              </MonthName>

              <div
                style={{
                  width: "100%",
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MilestoneVerticalLine />
              </div>
            </MonthMileStone>
          );
        })}
      </MonthMilestoneContainer>

      {/*Time Progress Bar */}
      <ProgressBarContainer>
        {/*Actual Date Bar*/}
        <ActualDateProgress style={{ width: "65%" }} />

        {/*Planning Date Bar */}
        <PlanningProgress style={{ width: "50%" }} />
      </ProgressBarContainer>

      {/*Stage Milestone */}
      <StageMilestoneContainer
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <>
          {project.stages
            .filter(
              (stage) =>
                stage.title.toLowerCase() !== "developer costs" &&
                stage.title.toLowerCase() !== "variations"
            )
            .map((stage, index) => {
              return (
                <StageMilestone
                  key={index}
                  style={{
                    left:
                      getPositionOfStageMilestone(
                        stage,
                        project.startDate,
                        project.endDate
                      ) >= 4
                        ? getPositionOfStageMilestone(
                            stage,
                            project.startDate,
                            project.endDate
                          ) <= 101
                          ? `${getPositionOfStageMilestone(
                              stage,
                              project.startDate,
                              project.endDate
                            )}%`
                          : `calc(${getPositionOfStageMilestone(
                              stage,
                              project.startDate,
                              project.endDate
                            )}% - 10px)`
                        : `calc(${getPositionOfStageMilestone(
                            stage,
                            project.startDate,
                            project.endDate
                          )}% + 20px)`,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <StagePaymentInfoContainer>
                      <StagePaymentRatioText>
                        {Math.floor(
                          (parseInt(stage.value.toString()) / contractPrice) *
                            100
                        )}
                        %
                      </StagePaymentRatioText>
                      {stage.title}
                    </StagePaymentInfoContainer>
                  </div>
                </StageMilestone>
              );
            })}
        </>
        <StagePaymentAmountContainer>
          {project.stages
            .filter(
              (stage) =>
                stage.title.toLowerCase() !== "developer costs" &&
                stage.title.toLowerCase() !== "variations"
            )
            .map((stage, index) => {
              return (
                <StagePaymentAmountText
                  key={index}
                  style={{
                    left: `calc(${getPositionOfStageMilestone(
                      stage,
                      project.startDate,
                      project.endDate
                    )}% - 1rem - 4px)`,
                    top: `calc(50% - 0.5em)`,
                  }}
                >
                  {parseInt(stage.value.toString()).toLocaleString()}
                </StagePaymentAmountText>
              );
            })}
        </StagePaymentAmountContainer>
      </StageMilestoneContainer>
    </RootProjectCardTimeBarTracker>
  );
};

export default ProjectCardTimeBarTracker;

const RootProjectCardTimeBarTracker = styled(motion.div)`
  width: calc(100% - 30px);
  height: 22%;
  max-height: 170px;
  min-height: 140px;
  padding: 0 15px 0 15px;
  display: flex;
  flex-direction: column;
  justfy-content: center;
  align-items: center;
  overflow: hidden;
`;

const MonthMilestoneContainer = styled.div`
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: row;
  max-height: 35px;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: row;
  justfy-content: flex-start;
  align-items: center;
  background: #e7eaec;
  border-radius: 9px;
  position: relative;
  max-height: 40px;
`;

const StageMilestoneContainer = styled.div`
  width: 99%;
  height: calc(40% - 2px);
  display: flex;
  position: relative;
  flex: 1;
  margin-top: 2px;
  border-bottom: 0.5px solid #d7d8ee;
`;

const MonthMileStone = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justfy-content: center;
  align-items: center;
`;

const MilestoneVerticalLine = styled.div`
  height: 100%;
  border: 0.25px solid #88a4cd;
`;

const MonthName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #5a7584;
  position: relative;
`;

const ActualDateProgress = styled.div`
height: 100%;
display: flex;
opacity: 0.7;
background: #CCD1D6;
border-radius: 9px;
position: absolute;
top:0,left:0;
z-index:1
`;

const PlanningProgress = styled.div`
  height: 50%;
  opacity: 0.7;
  background: #855df8;
  border-radius: 0px 4px 4px 0px;
  z-index: 2;
`;

const StageMilestone = styled.div`
  height: 15px;
  width: 0;
  border: 0.5px solid #b0bec5;
  position: absolute;
  top: 0;
`;

const StagePaymentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  /* identical to box height */
  color: #607d8b;
  text-transform: capitalize;
  bottom: calc(-2rem + 4px);
  left: -1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 40px;
`;

const StagePaymentRatioText = styled.div`
  font-family: "Nostromo Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  color: #4323bd;
`;

const StagePaymentAmountContainer = styled.div`
  height: 20px;
  border-top: 0.5px solid #d7d8ee;
  width: 100%;
  position: relative;
`;

const StagePaymentAmountText = styled.div`
  position: absolute;
  font-family: "Nostromo Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 8px;
  color: #000000;
`;
