import React, { useState, useEffect } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { User } from "../../../Models/user";
import mentionsStyle from "./mentionsStyle";

interface SuggestionDataItem {
    id: string | number;
    display?: string;
}

interface props {
    userList?: User[];
    commentText: string;
    setCommentText: Function;
    submitComment: Function;
    fontSize: string;
}

const CommentMentions: React.FC<props> = ({
    userList,
    commentText,
    setCommentText,
    submitComment,
    fontSize,
}) => {
    const [value, setValue] = useState<string>("");
    const [data, setData] = useState<SuggestionDataItem[]>([]);
    const onChange = (e: any) => {
        setCommentText(e.target.value);
    };

    useEffect(() => {
        const newData: any = userList?.map((user) => {
            return {
                id: user.userId,
                display: `${user.firstName} ${user.lastName}`,
            };
        });
        setData(newData);
    }, [userList]);

    return (
        <MentionsInput
            value={commentText}
            onChange={onChange}
            // placeholder={"Mention people using '@'"}
            placeholder={"Type your comment"}
            a11ySuggestionsListLabel={"Suggested mentions"}
            style={{...mentionsStyle, control: {...mentionsStyle.control, fontSize: fontSize}}}
            allowSuggestionsAboveCursor
            onKeyDown={(event: any) => {
                if (  (event.metaKey && event.keyCode === 13) ||
                      (event.ctrlKey && event.keyCode === 13)) {
                  setCommentText((text:any) => text + "\n")
                } else if (event?.keyCode === 13) {
                  event.preventDefault();
                  submitComment();
                };
              }}
        >
            <Mention
                markup="@[__display__]"
                data={data}
                trigger="@"
                onAdd={() => {}}
                style={{
                    backgroundColor: "#e4dafe",
                    fontSize: "11px"
                }}
                appendSpaceOnAdd={true}
            />
        </MentionsInput>
    );
};

export default CommentMentions;
