import React, { useState, useEffect } from "react";
import { Invoice } from "../../../Models/invoice";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import useStyles from "./InvoiceStyle";
import InvoiceRow from "./InvoiceRow";

interface props {
    costCodeId: string;
    setLoading: Function;
}

const Invoices: React.FC<props> = ({ costCodeId, setLoading }) => {
    const classes = useStyles();

    const [invoiceLists, setInvoiceLists] = useState<Invoice[]>([]);
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;

    const fetchInvoices = async () => {
        setLoading(true);
        try {
            const response = (await CostCodeAPI.getBilledDetails(
                costCodeId
            )) as Invoice[];
            setInvoiceLists([...response]);
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchInvoices();
    }, [costCodeId]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "7px",
            }}
        >
            <TableContainer>
                <Table
                    size="small"
                    aria-label="details table"
                    style={{
                        paddingTop: "50px",
                    }}
                >
                    <TableHead className={classes.tableHeadingStyle}>
                        <TableRow>
                            <TableCell>Reference</TableCell>
                            <TableCell>From</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Due Date</TableCell>
                            <TableCell>Paid</TableCell>
                            <TableCell>Due</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableDataStyle}>
                        {invoiceLists.length > 0 &&
                            invoiceLists.map((invoice) => (
                                <InvoiceRow
                                    key={"InvoiceRow-" + invoice.InvoiceID}
                                    invoice={invoice}
                                    costCodeId={costCodeId}
                                />
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
export default Invoices;
