import React, {useState, useEffect, useRef} from "react";
import { Costcode } from "../../../Models/costcode";
import { Button, Input, ListItemButton } from "@mui/material";
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
  MobileDatePicker,
  MobileDateRangePicker,
} from "@mui/lab";
import { Dayjs } from "dayjs";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { formatScheduleDate } from "../../../utilities/constants";
import Status from "../Status/Status";
import CustomCheckbox from "../../../utilities/CheckBox/CheckBox";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import { withStyles } from "@material-ui/core/styles";
import { Permit } from "../../../Models/permit";
import checklistGroupAPI from "../../../Services/DoxleAPI/checklistGroupAPI";
import { Company } from "../../../Models/company";
import { ScheduleItem } from "../../../Models/schedules";
import ScheduleAPI from "../../../Services/DoxleAPI/scheduleAPI";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useSocket } from "../../../Services/Sockets/useSocket";
import { makeStyles, styled } from "@mui/styles";
interface props {
  costcode?: Costcode;
  scheduleItem?: ScheduleItem;
  action: string;
  formatter: Intl.NumberFormat;
  dateRange?: DateRange<Dayjs>;
  setValue: Function;
  updateTotals?: Function;
  textColor: Function;
  exitEditTitleMode?: Function;
  projectStatus?: string;
}

export const addLeadingZeros = (input: number, digits: number) => {
  let num = input.toString();
  while (num.length < digits) num = "0" + num;
  return num;
};

const EditCostCode: React.FC<props> = ({
  costcode,
  scheduleItem,
  action,
  formatter,
  setValue,
  dateRange,
  updateTotals,
  textColor,
  exitEditTitleMode,
  projectStatus,
}) => {
  const [title, setTitle] = useState<string>(
    costcode?.title
      ? costcode?.title
      : scheduleItem?.title
      ? scheduleItem?.title
      : ""
  );
  const [budget, setBudget] = useState<string>(
    costcode?.budget ? costcode?.budget : "0"
  );
  const [completed, setCompleted] = useState<boolean>(
    costcode?.completed
      ? costcode?.completed
      : scheduleItem?.completed
      ? scheduleItem?.completed
      : false
  );
  const currentCompanyJSON: string | null =
    localStorage.getItem("currentCompany");
  const currentCompany: Company | null = currentCompanyJSON
    ? JSON.parse(currentCompanyJSON)
    : null;
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, user } = authContext;
  const socket = useSocket();

  const handleTitleChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      if (!user?.userId) {
        console.log("User not found");
        setLoggedIn(false);
        return;
      }
      if (event.target.value === title) {
        if (exitEditTitleMode) exitEditTitleMode();
        return;
      }
      if (costcode) {
        const response = await CostCodeAPI.update(costcode.costCodeId, {
          title: event.target.value,
        });
        if (response) {
          setValue(response.title);
          setTitle(response.title);
          if (exitEditTitleMode) exitEditTitleMode();
        }
      } else if (scheduleItem) {
        const response = await ScheduleAPI.update(scheduleItem.scheduleId, {
          title: event.target.value,
        });
        if (response) {
          setValue(response.title);
          setTitle(response.title);
          if (exitEditTitleMode) exitEditTitleMode();
        }
      }
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      resetTitle(event);
    }
  };

  // const editTitleState = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     event.target.value = ''
  // };

  const resetTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = title;
  };

  const handleBudgetChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    costcode: Costcode | undefined
  ) => {
    try {
      const oriBudget: string = budget;
      let budgetInput = event.target.value
        .replaceAll("$", "")
        .replaceAll(",", "");
      if (isNaN(parseFloat(budgetInput)) || budget === budgetInput) {
        resetBudget(event);
      } else {
        if (!costcode) return;
        const response = await CostCodeAPI.update(costcode.costCodeId, {
          budget: budgetInput,
        });
        if (response) {
          setValue(response.budget);
          setBudget(response.budget);
          if (updateTotals && !response.income) {
            const budgetDelta: number =
              parseFloat(response.budget) - parseFloat(oriBudget);
            updateTotals({ budgetDelta: budgetDelta });
          }
        }
      }
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      resetBudget(event);
    }
  };

  const editBudgetState = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = "$";
  };

  const resetBudget = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = formatter.format(parseFloat(budget));
  };

  const handleOnClick = (e: any) => {
    e?.target?.children?.[0]?.click();
  };

  const handleCheckBoxClick = async (event: any) => {
    if (typeof event.target.checked === "boolean") {
      try {
        if (costcode?.costCodeId) {
          const response = await CostCodeAPI.update(costcode?.costCodeId, {
            completed: event.target.checked,
          });
          if (response) {
            setValue(response.completed);
            setCompleted(response.completed);
          }
        } else if (scheduleItem?.scheduleId) {
          const response = await ScheduleAPI.update(scheduleItem?.scheduleId, {
            completed: event.target.checked,
          });
          if (response) {
            setValue(response.completed);
            setCompleted(response.completed);
          }
        }
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        console.log("Unable to save to backend, returning to complete");
      }
    }
  };

  const updateDateViaSocket = (response: any) => {
    //! SOCKET SENDING
    console.log(
      "%cCLIENT = Sending startDate/endDate update ",
      "background:green; color:white"
    );
    socket.send(
      JSON.stringify({
        messageType: "SocketDataUpdate",
        message: {
          costCodeId: costcode?.costCodeId,
          scheduleId: scheduleItem?.scheduleId,
          startDate: response.startDate,
          endDate: response.endDate,
        },
      })
    );
  };

  const handleSubmitDate = async (dateInput: DateRange<Dayjs>) => {
    if (!(dateInput?.[0]?.date() && dateInput?.[1]?.date())) return;
    const startDateInput = `${dateInput[0]?.year()}-${addLeadingZeros(
      dateInput[0]?.month() + 1,
      2
    )}-${addLeadingZeros(dateInput[0]?.date(), 2)}`;
    const endDateInput = `${dateInput[1]?.year()}-${addLeadingZeros(
      dateInput[1]?.month() + 1,
      2
    )}-${addLeadingZeros(dateInput[1]?.date(), 2)}`;
    console.log("startDateInput", startDateInput);
    console.log("endDateInput", endDateInput);

    try {
      if (costcode?.costCodeId) {
        const response = await CostCodeAPI.update(costcode?.costCodeId, {
          startDate: startDateInput,
          endDate: endDateInput,
        });
        if (response) {
          setValue([dayjs(response.startDate), dayjs(response.endDate)]);
          updateDateViaSocket(response);
        }
      } else if (scheduleItem?.scheduleId) {
        const response = await ScheduleAPI.update(scheduleItem?.scheduleId, {
          startDate: startDateInput,
          endDate: endDateInput,
        });
        if (response) {
          setValue([dayjs(response.startDate), dayjs(response.endDate)]);
          updateDateViaSocket(response);
        }
      }
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const handleCancelDate = async (dateInput: DateRange<Dayjs>) => {
    // setValue()
  };

  const DarkerDisabledInput = withStyles({
    root: {
      marginRight: 8,
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0)",
      },
    },
  })(Input);

  // If costcode.busget is updated force input to take new value
  const budgetRef: any = useRef()
  useEffect(() =>{
    if (budgetRef?.current?.value &&
        costcode?.budget &&
        budgetRef?.current?.value !== formatter.format(parseFloat(costcode?.budget))
    ) budgetRef.current.value = formatter.format(parseFloat(costcode?.budget))
  },[costcode])

  const classes = useStyles();
  return (
    <div>
      {action === "title" ? (
        <Input
          className="budgetInput"
          size="small"
          disableUnderline
          defaultValue={title}
          autoFocus
          sx={{
            height: "10px",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "Inter",
            fontSize: "11px",
            lineHeight: "15px",
            color: textColor(),
            position: "relative",
          }}
          onFocus={(event: any) => {
            // editTitleState(event);
          }}
          onBlur={(event: any) => {
            resetTitle(event);
          }}
          onKeyUp={(event: any) => {
            if (event.keyCode === 13) {
              handleTitleChange(event);
            } else if (event.keyCode === 27) {
              resetTitle(event);
            }
          }}
        />
      ) : action === "budget" ? (
        <Input
          className="budgetInput"
          size="small"
          disableUnderline
          disabled={
            !(currentCompany?.owner && user?.userId === currentCompany.owner) &&
            (projectStatus === "AC" || projectStatus === "AR")
          }
          inputRef={budgetRef}
          defaultValue={formatter.format(parseFloat(budget))}
          sx={{
            height: "10px",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "Inter",
            fontSize: "11px",
            lineHeight: "15px",
            color: textColor(),
            position: "relative",
          }}
          onFocus={(event: any) => {
            editBudgetState(event);
          }}
          onBlur={(event: any) => {
            resetBudget(event);
          }}
          onKeyUp={(event: any) => {
            if (event.keyCode === 13) {
              handleBudgetChange(event, costcode);
            } else if (event.keyCode === 27) {
              resetBudget(event);
            }
          }}
        />
      ) : action === "startDate" ? (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateRangePicker
              className={classes.dateRangePicker}
              value={dateRange || [null, null]}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              onAccept={handleSubmitDate}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <ListItemButton
                  onClick={(event: any) => {
                    handleOnClick(event);
                  }}
                  sx={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    justifyContent: "center",
                  }}
                >
                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    {...inputProps}
                  />
                  {InputProps?.endAdornment}
                  {dateRange?.[0]
                    ? `${addLeadingZeros(
                        dateRange[0]?.date(),
                        2
                      )}.${addLeadingZeros(
                        dateRange[0]?.month() + 1,
                        2
                      )}.${dateRange[0]?.year().toString().substring(2)}`
                    : ""}
                </ListItemButton>
              )}
            />
          </LocalizationProvider>
        </>
      ) : action === "endDate" ? (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateRangePicker
              className={classes.dateRangePicker}
              value={dateRange || [null, null]}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              onAccept={handleSubmitDate}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <ListItemButton
                  onClick={(event: any) => {
                    handleOnClick(event);
                  }}
                  sx={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    justifyContent: "center",
                  }}
                >
                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    {...inputProps}
                  />
                  {InputProps?.endAdornment}
                  {dateRange?.[1]
                    ? `${addLeadingZeros(
                        dateRange[1]?.date(),
                        2
                      )}.${addLeadingZeros(
                        dateRange[1]?.month() + 1,
                        2
                      )}.${dateRange[1]?.year().toString().substring(2)}`
                    : ""}
                </ListItemButton>
              )}
            />
          </LocalizationProvider>
        </>
      ) : action === "status" ? (
        <Status
          type={"costcode"}
          itemId={costcode?.costCodeId || "error"}
          status={costcode?.status || "D"}
          setValue={setValue}
        />
      ) : action === "checkbox" ? (
        <CustomCheckbox
          checked={completed}
          onClick={(event: any) => {
            handleCheckBoxClick(event);
          }}
          style={{
            transform: "scale(0.7)",
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
export default React.memo(EditCostCode);

const useStyles = makeStyles((theme) => ({
  dateRangePicker: {
    "& .MuiDateRangePickerDay-day.Mui-selected": {
      backgroundColor: "#7949FF",
    },
    "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
      backgroundColor: "rgba(121,73,255,0.4)",
    },
  },
}));
