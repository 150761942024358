import { Alert, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BasicCostCode, Costcode } from '../../../Models/costcode'
import { Project } from '../../../Models/project'
import { XeroCostcodeData } from '../../../Models/xeroData'
import CostCodeAPI from '../../../Services/DoxleAPI/costCodeAPI'
import { baseAddress } from '../../../settings'
import useMountStatus from '../../../utilities/Helper/checkMountStatus'
import Loading from '../../../utilities/Lottie/Loading'
import { authContextInterface, useAuth } from '../../Providers/AuthProvider'
import { IXeroContext, useXeroContext } from '../../Providers/XeroProvider'
import './AccountMapping.css'
import CostcodeMappingRow from './CostcodeMappingRow/CostcodeMappingRow'
import DropdownProjectLink from './DropdownProjectLink'
import { styled } from '@mui/material/styles'
import ProgressScreen from '../../../utilities/Lottie/ProgressScreen'
import ProjectAPI from '../../../Services/DoxleAPI/projectAPI'
import Snackbar from '@mui/material/Snackbar'

interface props {
  projects: Project[]
  projectSelected: Project
}
export interface IUnlinkedItem {
  costcode: BasicCostCode
  trackingId: string
}
const AccountMappingApp: React.FC<props> = ({
  projects,
  projectSelected,
}: props) => {
  const unmounted = useMountStatus()
  //###############AUTH CONTROL####################
  const authContext = useAuth() as authContextInterface
  const { setLoggedIn, user } = authContext
  //###############################################

  //########CONTROL DIFFERENT WAITING SCREEN#######
  const [loading, setLoading] = useState<boolean>(true)
  const [onUpdateProgress, setOnUpdateProgress] = useState<boolean>(false)

  const [showNotificationMessage, setShowNotificationMessage] = useState<
    | 'projectSuccess'
    | 'costcodeSuccess'
    | 'projectFail'
    | 'costcodeFail'
    | 'costcodeUnlinkSuccess'
    | 'costcodeUnlinkFail'
    | undefined
  >(undefined)
  //###############################################
  // window.location.href = baseAddress + '/xero/first_auth'
  //$$$$$$$$CONTROL XERO AUTH$$$$$$$$
  const xeroContext = useXeroContext() as IXeroContext
  const { isXeroLinked, getAllCostcodeLinks } = xeroContext
  //$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  //##########CONTROL DIRECT LINKING XERO FOR FIRST TIME######
  const navigate = useNavigate()
  //useEffect run intial to check if company already link with xero account
  useEffect(() => {
    if (isXeroLinked === false) navigate('/xero-link')
  }, [isXeroLinked])
  //###################################################

  //########CONTROL LINKING PROJECT #######
  //method handle linking project
  const handleLinkingProject = async (projectTrackingId: string) => {
    setOnUpdateProgress(true)
    try {
      const result = await ProjectAPI.update(projectSelected.projectId, {
        trackingId: projectTrackingId,
      })
      if (result) {
        console.log('UPDATED PROJECT:', result)
        setOnUpdateProgress(false)

        //control show notification
        setTimeout(() => {
          setShowNotificationMessage('projectSuccess')
        }, 300)

        return true
      } else throw 'FAILED UPDATING PROJECT'
    } catch (error) {
      console.log(error)
      setOnUpdateProgress(false)

      setTimeout(() => {
        setShowNotificationMessage('projectFail')
      }, 300)

      return false
    }
  }
  //#######################################

  //########CONTROL COSTCODE FETCHING AND DISPLAY#######

  //state to store all costcodes
  const [costcodes, setCostcodes] = useState<BasicCostCode[]>([])

  //state to store all xero costcode data
  const [xeroCostcodeData, setXeroCostcodeData] = useState<XeroCostcodeData[]>(
    []
  )

  //state to store all the unlinked costcodes used to call link all api
  //got updated everytime state  "costcodes" & "xeroCostcodeData" change
  const [unlinkedItems, setUnlinkedItems] = useState<IUnlinkedItem[]>([])

  //method to extract unlinkedItems
  const getUnlinkedItems = () => {
    let checkUnlinkItemsList: IUnlinkedItem[] = []
    //check each costcode to see which one in unlinked
    costcodes.map((costcode) => {
      //if costcodes doesn't
      if (
        costcode.accountTrackingId === null ||
        costcode.accountTrackingId === ''
      ) {
        xeroCostcodeData.map((data) => {
          if (data.Name.substring(0, 4) === costcode.title.substring(0, 4)) {
            checkUnlinkItemsList.push({
              costcode: costcode,
              trackingId: data.AccountID,
            })
          }
        })
      }
    })
    setUnlinkedItems([...checkUnlinkItemsList])
  }

  //handle unlink a costcode api call
  const unlinkCostcode = async (costcode: BasicCostCode) => {
    setOnUpdateProgress(true)
    try {
      let result = await CostCodeAPI.update(costcode.costCodeId, {
        accountTrackingId: null,
      })
      if (result) {
        console.log('SUCCESSFULL')
        let newCostcodes: BasicCostCode[] = []
        costcodes.map((costcode) => {
          if (result?.costCodeId === costcode.costCodeId)
            newCostcodes.push(result)
          else newCostcodes.push(costcode)
        })
        setCostcodes([...newCostcodes])
        console.log('RESULT FRONTEND:', result)
        setOnUpdateProgress(false)

        setTimeout(() => {
          setShowNotificationMessage('costcodeUnlinkSuccess')
        }, 300)
      } else throw 'FAILED TO UPDATE COSTCODE DATA'
    } catch (error) {
      console.log(error)
      setOnUpdateProgress(false)

      setTimeout(() => {
        setShowNotificationMessage('costcodeUnlinkFail')
      }, 300)
    }
  }

  //handle button linkall
  const handleLinkAll = async () => {
    setOnUpdateProgress(true)
    try {
      //get all unlinkedItems data and pass in API
      let updateBody = unlinkedItems.map((item) => {
        return {
          costCodeId: item.costcode.costCodeId,
          accountTrackingId: item.trackingId,
        }
      })

      let result = await CostCodeAPI.updateMany(updateBody)
      if (result.costCodes.length > 0) {
        // console.log('RESULT FRONTEND:', result)

        //update costcodes locally
        let newCostcodes: BasicCostCode[] = []
        costcodes.map((initialCostcode) => {
          //check if the current checked costcode is match in field costCodeId in the returned result
          //=> the costcode is updated
          //=> replace accountTrackingId of the current checked costcode with the accountTrackingId of the returned result
          if (
            result.costCodes.some(
              (updatedCostcode) =>
                updatedCostcode.costCodeId === initialCostcode.costCodeId
            )
          ) {
            newCostcodes.push({
              ...initialCostcode,
              accountTrackingId: result.costCodes.filter(
                (updatedCostcode) =>
                  updatedCostcode.costCodeId === initialCostcode.costCodeId
              )[0].accountTrackingId,
            })
          }
          //otherwise push the original costcode to new list
          else newCostcodes.push(initialCostcode)
        })
        setCostcodes([...newCostcodes])
        setTimeout(() => {
          setOnUpdateProgress(false)
        }, 500)

        setTimeout(() => {
          setShowNotificationMessage('costcodeSuccess')
        }, 300)
      } else throw 'FAILED TO FETCH XERO PROJECT DATA'
    } catch (error) {
      console.log(error)
      setOnUpdateProgress(false)

      setTimeout(() => {
        setShowNotificationMessage('costcodeFail')
      }, 300)
    }
  }
  useEffect(() => {
    getUnlinkedItems()
    console.log('CC LENGTH:', costcodes.length)
  }, [costcodes, xeroCostcodeData])

  // useEffect(() => {
  //   console.log(unlinkedItems)
  // }, [unlinkedItems])

  const fetchXeroCostcodeData = async () => {
    try {
      let result = await getAllCostcodeLinks()
      if (result) {
        setXeroCostcodeData([...(result as XeroCostcodeData[])])
        setLoading(false)
      } else throw 'FAILED TO FETCH XERO PROJECT DATA'
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  //fetch all costcodes data
  const fetchCostcodes = async () => {
    try {
      const response = (await CostCodeAPI.getBasicList({
        projectId: projectSelected.projectId,
      })) as BasicCostCode[]
      if (unmounted) return
      setCostcodes([...response])
    } catch (err) {
      setLoading(false)
      err === 'AccessTokenNotFound' ? setLoggedIn(false) : console.error(err)
    }
  }

  useEffect(() => {
    fetchCostcodes()
    fetchXeroCostcodeData()
  }, [projectSelected])

  return loading ? (
    <Loading />
  ) : (
    <div className='rootAccountMappingContainer'>
      {onUpdateProgress ? <ProgressScreen /> : null}

      {/*Notification control */}
      <Snackbar
        open={showNotificationMessage !== undefined}
        autoHideDuration={2000}
        onClose={() => setShowNotificationMessage(undefined)}
      >
        {showNotificationMessage === 'projectSuccess' ? (
          <Alert
            onClose={() => setShowNotificationMessage(undefined)}
            severity='success'
            sx={{ width: '100%' }}
          >
            Project Linked
          </Alert>
        ) : showNotificationMessage === 'projectFail' ? (
          <Alert
            onClose={() => setShowNotificationMessage(undefined)}
            severity='error'
            sx={{ width: '100%' }}
          >
            Failed To Link Project
          </Alert>
        ) : showNotificationMessage === 'costcodeFail' ? (
          <Alert
            onClose={() => setShowNotificationMessage(undefined)}
            severity='error'
            sx={{ width: '100%' }}
          >
            Failed To Link Costcode
          </Alert>
        ) : showNotificationMessage === 'costcodeSuccess' ? (
          <Alert
            onClose={() => setShowNotificationMessage(undefined)}
            severity='success'
            sx={{ width: '100%' }}
          >
            Costcode Linked
          </Alert>
        ) : showNotificationMessage === 'costcodeUnlinkSuccess' ? (
          <Alert
            onClose={() => setShowNotificationMessage(undefined)}
            severity='success'
            sx={{ width: '100%' }}
          >
            Costcode Unlinked
          </Alert>
        ) : showNotificationMessage === 'costcodeUnlinkFail' ? (
          <Alert
            onClose={() => setShowNotificationMessage(undefined)}
            severity='error'
            sx={{ width: '100%' }}
          >
            Failed To Unlink Costcode
          </Alert>
        ) : (
          <div></div>
        )}
      </Snackbar>
      <div
        className='linkContainer'
        style={{ opacity: onUpdateProgress ? 0.3 : 1 }}
      >
        {/*top menu container */}
        <div className='topMenu'>
          {/*title container */}
          <div className='textTitleContainer'>XERO MAPPING</div>

          {/*dropdown container */}
          <div className='dropdownContainer'>
            <DropdownProjectLink
              projects={projects}
              handleLinkProject={handleLinkingProject}
            />
          </div>
        </div>

        {/*body content container */}
        <div className='bodyContainer'>
          {costcodes.map((costcode, index) => {
            //check if costcode is linked or not
            let isLinked: boolean = xeroCostcodeData.some(
              (data) => data.AccountID === costcode.accountTrackingId
            )

            let linkedItem: XeroCostcodeData = xeroCostcodeData.filter(
              (data) => data.AccountID === costcode.accountTrackingId
            )[0]

            return (
              <CostcodeMappingRow
                key={index}
                costcode={costcode}
                xeroCostcodeData={xeroCostcodeData}
                isLinked={isLinked}
                linkedItem={linkedItem}
                unlinkedItems={unlinkedItems}
                setUnlinkedItems={setUnlinkedItems}
                unlinkFunction={unlinkCostcode}
              />
            )
          })}
        </div>

        <div className='bottomContainer'>
          <LinkAllButton
            className='buttonLinkAll'
            onClick={() => handleLinkAll()}
            disabled={unlinkedItems.length === 0 ? true : false}
          >
            Link All
          </LinkAllButton>
        </div>
      </div>
    </div>
  )
}

export default AccountMappingApp

const LinkAllButton = styled(Button)({
  width: '160px',
  height: '50px',

  fontSize: '20px',

  border: '1px solid',
  lineHeight: '20px',
  backgroundColor: '#7949FF',
  borderColor: '#7949FF',
  fontFamily: 'IBM Plex Mono',
  color: '#FFFFFF',
  fontStyle: 'normal',
  fontWeight: '400',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#8159f1',
    borderColor: '#8159f1',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'rgb(169, 138, 253)',
    borderColor: '#c4afff',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem #7922f5',
  },
})
