import axios from "axios";
import { Notice, NewNotice, NoticeCategory } from "../../Models/notice";
import { User } from "../../Models/user";
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";
import { STATUS_SYMBOL } from "../../utilities/constants";
import { PricelistResponse } from "./pricelistAPI";
import { Pricelist } from "../../Models/pricelist";

const availableStatuses = Object.values(STATUS_SYMBOL);
const availableSymbols = Object.keys(STATUS_SYMBOL);

const getStatusValue = (symbol: string) => {
  if (symbol) {
    return availableStatuses[availableSymbols.findIndex((sy) => sy === symbol)];
  }
};

export interface GetListParams {
  company: string;
  page: number;
  page_size?: number;
  ordering?: string;
  is_archived?: boolean;
  assigned_users?: string;
  status?: string;
  creator?: string;
  category_id?: string;
  category_id__is_permit?: string;
  due_by?: string;
}


interface GetParams {
  companyId: string;
  orderBy?: string;
  page?: number;
  archived?: boolean;
  page_size?: number;
  startDate?: string;
  endDate?: string;
}

export interface NoticeResponse {
  items: Notice[];
  nextPage: number | null;
}
const getList = async (params: GetListParams) => {
  // Get's a list of notices for a given project selected with projectId
  // Order costcode with orderBy, options =  title, startDate, endDate
  // Pagination 100 notices per result - call for next page when scrolling
  let result: NoticeResponse = { items: [], nextPage: null };
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    const response = await axios.get(baseAddress + "/notices/", {
      headers: { Authorization: "Bearer " + accessToken },
      params: params,
    });
    result.items = response?.data?.results
      ? (response.data.results as Array<Notice>)
      : [];
    result.nextPage = response?.data?.next ? ++params.page : null;
    // console.log("NOTICE", result)
    // console.log(archived ? "archived": "", "NOTICE", result)
  } catch (error) {
    console.error("ERROR FETCHING NOTICE", error);
  }
  return result;
};

// Get notices for timeline
const getListTimeLine = async ({ companyId }: GetParams) => {
  let result: NoticeResponse = { items: [], nextPage: null };
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    let params = { company: companyId };
    const response = await axios.get(
      baseAddress + "/notices/time_table/",
      {
        headers: { Authorization: "Bearer " + accessToken },
        params: params,
      }
    );
    console.log("RESPONSE DATA SERVER:", response);
    result.items = response?.data?.results
      ? (response.data.results as Array<Notice>)
      : [];
  } catch (error) {
    console.error("ERROR FETCHING NOTICE", error);
  }
  return result;
};

//Get notices for timeline with start date and due date
// date format: YYYY-MM-DD
const getListTimeLineWithDate = async ({
  companyId,
  startDate,
  endDate,
}: GetParams) => {
  let result: NoticeResponse = { items: [], nextPage: null };
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    let params = {
      company: companyId,
      start_date: startDate,
      end_date: endDate,
    };
    const response = await axios.get(
      baseAddress + "/notices/date_range/",
      {
        headers: { Authorization: "Bearer " + accessToken },
        params: params,
      }
    );
    console.log("RESPONSE:", response);
    //console.log("RESPONSE DATA SERVER:", response.data)
    result.items = response?.data ? (response.data as Array<Notice>) : [];
  } catch (error) {
    console.error("ERROR FETCHING NOTICE", error);
  }
  return result;
};

const getDetailed = async (noticeId: string) => {
  // Gets one notice only using the notice's UUID
  // At this stage there is no additional information from the detailed
  // Future back end change will mean this call will get storage, specs, comments with this call
  let result: Notice | undefined = undefined;
  if (offline) {
    /* result = dummy.noticeFullResponse;*/ return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }

  try {
    const response = await axios.get(
      baseAddress + "/notices/detail/" + noticeId + "/",
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.data as Notice;
    console.log("NOTICE DETAILED", result);
  } catch (error) {
    console.error("ERROR FETCHING NOTICE", error);
  }
  return result;
};

const remove = async (noticeId: string) => {
  /// Permanently delete notice by uuid
  let result: boolean = false;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  console.log("try remove ", noticeId);
  try {
    const response = await axios.delete(
      baseAddress + "/notices/" + noticeId + "/",
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    //console.log("try remove ", noticeId)
    result = response.status === 204 || response.status === 200;
    console.log(response.status);
  } catch (error) {
    console.error("ERROR DELETING NOTICE", error);
  }
  return result;
};

const add = async (notice: NewNotice) => {
  //if (offline){ return dummy.newDummyCostCode(notice); }
  // Add a new costcode
  let result: Notice | undefined = undefined;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }

  notice.history = [
    {
      index: 0,
      shortText: "Notice",
      longText: 'Created new "' + notice.description + '"',
      timeStamp: new Date().toString(),
    },
  ];

  try {
    const response = await axios.post(
      baseAddress + "/notices/",
      notice,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.status === 201 ? (response.data as Notice) : undefined;
    console.log("ADDED NOTICE", result);
  } catch (error) {
    console.error("ERROR ADDING NOTICE", error);
  }
  return result;
};

interface UpdateBody {
  description?: string;
  creator?: string;
  assignedUsers?: string[];
  pinned?: boolean;
  status?: string;
  timeStamp?: string;
  category?: string;
  categoryId?: string;
  isArchived?: boolean;
  isComplete?: boolean;
  isPrivate?: boolean;
  isFollowup?: boolean;
  dueDate?: string | null;
  startDate?: string | null;
  history?: any[];
}

const update = async (
  noticeId: string,
  user: User | undefined,
  {
    description,
    creator,
    assignedUsers,
    pinned,
    status,
    timeStamp,
    category,
    categoryId,
    isArchived,
    isComplete,
    isPrivate,
    isFollowup,
    dueDate,
    startDate,
  }: UpdateBody
) => {
  // Update one or more property of a notice without affecting other values
  // Only pass in what is needed to be updated
  let result: Notice | undefined = undefined;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }

  try {
    let body: UpdateBody = {};
    let tittleText: string = "Notice";
    let editText: string = "";

    if (description) {
      body.description = description;
      tittleText = "Notice";
      editText += 'Change to "' + description + '"\n';
    }
    if (creator) {
      body.creator = creator;
    }
    if (categoryId) {
      body.categoryId = categoryId;
    }
    if (assignedUsers) {
      body.assignedUsers = assignedUsers;
      tittleText = "Notice";
      editText += "Change assigned to " + assignedUsers.length + " user(s)\n";
    }
    if (pinned !== undefined) {
      body.pinned = pinned;
      if (pinned) {
        editText += "Change to pinned\n";
      } else {
        editText += "Change to  unpinned\n";
      }
    }
    if (status) {
      body.status = status;
      editText += "Change to " + getStatusValue(status) + "\n";
    }
    if (timeStamp) {
      body.timeStamp = timeStamp;
    }
    if (category) {
      body.category = category;
      editText += "Change category to " + category + "\n";
    }
    if (isArchived !== undefined) {
      body.isArchived = isArchived;
      if (isArchived) {
        editText += "Change to archived\n";
      } else {
        editText += "Change to  unarchived\n";
      }
    }
    if (isComplete !== undefined) {
      body.isComplete = isComplete;
      if (isComplete) {
        editText += "Change to completed\n";
      } else {
        editText += "Change to uncompleted\n";
      }
    }
    if (isPrivate !== undefined) {
      body.isPrivate = isPrivate;
      if (isPrivate) {
        editText += "Change to private\n";
      } else {
        editText += "Change to unprivate\n";
      }
    }
    if (isFollowup !== undefined) {
      body.isFollowup = isFollowup;
      if (isFollowup) {
        editText += "Change to follow up\n";
      } else {
        editText += "Change to un follow up\n";
      }
    }
    if (dueDate !== undefined) {
      body.dueDate = dueDate;
      tittleText = "Notice";
      editText += "Change due date to " + dueDate + "\n";
    }
    if (startDate !== undefined) {
      body.startDate = startDate;
      tittleText = "Notice";
      editText += "Change start date to " + startDate + "\n";
    }

    body.history = [
      {
        short_text: tittleText,
        long_text: editText === "" ? "Change" : editText,
        time_stamp: new Date().toString(),
        user: user?.userId,
      },
    ];
    // if (body === {}) {return {}}
    //console.log('req:',body);
    const response = await axios.patch(
      baseAddress + "/notices/" + noticeId + "/",
      body,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.status === 200 ? (response.data as Notice) : undefined;
    console.log("response:", response);
  } catch (error) {
    console.error("ERROR UPDATING NOTICE", error);
  }
  return result;
};

const getCategories = async (companyId: string, permits?: boolean) => {
  // Get's a list of notices for a given project selected with projectId
  // Order costcode with orderBy, options =  title, startDate, endDate
  // Pagination 100 notices per result - call for next page when scrolling
  let result: NoticeCategory[] = []
  if (offline || !companyId) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    let params = {
      company: companyId,
      is_permit: permits ? permits : false
    };
    const response = await axios.get(baseAddress + "/notices/category/", {
      headers: { Authorization: "Bearer " + accessToken },
      params: params,
    });
    result = response?.data?.results
        ? (response.data?.results as Array<NoticeCategory>)
        : [];
    // console.log("NOTICE", result)
    // console.log(archived ? "archived": "", "NOTICE", result)
  } catch (error) {
    console.error("ERROR FETCHING NOTICE", error);
  }
  return result;
};

const addCategory = async ({company, noticeCategoryId, categoryTitle, isArchived}: NoticeCategory) => {
  // Get's a list of notices for a given project selected with projectId
  // Order costcode with orderBy, options =  title, startDate, endDate
  // Pagination 100 notices per result - call for next page when scrolling
  let result: NoticeCategory|undefined = undefined
  if (offline) return result
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) throw "AccessTokenNotFound";
  try {
    const response = await axios.post(baseAddress + "/notices/category/",
        {
            company: company,
            noticeCategoryId: noticeCategoryId,
            categoryTitle: categoryTitle,
            isArchived: isArchived
        },
        {
            headers: { Authorization: "Bearer " + accessToken },
        }
      );
    if (response?.data) result = response.data as NoticeCategory;
    // console.log("NOTICE", result)
    // console.log(archived ? "archived": "", "NOTICE", result)
  } catch (error) {
    console.error("ERROR FETCHING NOTICE", error);
  }
  return result;
};

const editCategory = async (noticeCategoryId: string, categoryTitle:string|undefined, isArchived:boolean|undefined) => {
  // Get's a list of notices for a given project selected with projectId
  // Order costcode with orderBy, options =  title, startDate, endDate
  // Pagination 100 notices per result - call for next page when scrolling
  let result: NoticeCategory|undefined = undefined
  if (offline) return result
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) throw "AccessTokenNotFound";
  let payload:any = {}
  if (typeof isArchived === "boolean") payload.isArchived = isArchived;
  if (categoryTitle) payload.categoryTitle = categoryTitle;
  try {
    const response = await axios.patch(baseAddress+"/notices/category/"+noticeCategoryId+"/",
        payload,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
    );
    if (response?.data) result = response.data as NoticeCategory;
    // console.log("NOTICE", result)
    // console.log(archived ? "archived": "", "NOTICE", result)
  } catch (error) {
    console.error("ERROR FETCHING NOTICE", error);
  }
  return result;
};



const NoticeAPI = {
  getList,
  getDetailed,
  remove,
  update,
  add,
  getListTimeLine,
  getListTimeLineWithDate,
  getCategories,
  addCategory,
  editCategory
};

export default NoticeAPI;
