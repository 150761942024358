import React, { useEffect } from "react";
import useImage from "use-image";
import { Image } from "react-konva";

interface props {
  src: string;
  height: number;
  width: number;
  // setIsLoadingImage: Function;
}

const ImageCustom: React.FC<props> = ({
  src,
  height,
  width,
  // setIsLoadingImage,
}) => {
  const [image, status] = useImage(src);

  useEffect(() => {
    console.log("image", status)
    // if (status == "loading") {
    //   setIsLoadingImage(true);
    // } else setIsLoadingImage(false);
  }, [status]);

  return <Image image={image} height={height} width={width} />;
};

export default ImageCustom;
