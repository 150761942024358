import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import {Typography} from "@mui/material";
// import useMountStatus  from '../../../utilities/Helper/checkMountStatus';

interface props {
    completed: number;
    total: number;
}

const CustomLinearProgressBar: React.FC<props> = (
    {
        completed,
        total,
    }) => {
    const progressPercentage = Math.round(completed/total*100)
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 20,
        borderRadius: 10,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 10,
            backgroundColor: theme.palette.mode === 'light' ? '#bfa8ff' : '#bfa8ff',
        },
    }));

    return(
        <Box sx={{ flexGrow: 1, maxHeight: "20px"}}>
            <BorderLinearProgress variant="determinate" value={progressPercentage} />
            {/*<Typography style={{fontSize: "x-small", position: "relative", top: -18, fontFamily: "Roboto Mono"}}>*/}
            <Typography style={{fontSize: "x-small", position: "relative", top: -18}}>
                {completed} of {total}
            </Typography>
        </Box>
    )
}

export default CustomLinearProgressBar