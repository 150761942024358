import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Project, ProjectCashflow } from "../../../Models/project";
import { IContextMenuPos } from "./CashflowTableRow";
import { RootCashflowContextMenu } from "./StyledComponentCashflow";

type Props = {
  selectedProject: ProjectCashflow;
  contextMenuPos: IContextMenuPos;
  setcontextMenuPos: (pos: IContextMenuPos | undefined) => void;
};

interface ICashflowStageNavigation {}
const CashflowContextMenu = ({
  selectedProject,
  contextMenuPos,
  setcontextMenuPos,
}: Props) => {
  //####HANDLE CLICK OUTSIDE TO CLOSE PICKER BOARD####
  const componentRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      componentRef.current &&
      !componentRef.current.contains(event.target as Node)
    ) {
      setcontextMenuPos(undefined);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [componentRef]);
  //######################################################
  const navigate = useNavigate();
  const handleClickMenuItem = () => {
    localStorage.setItem("currentProject", JSON.stringify(selectedProject));
    localStorage.setItem("currentProjectId", selectedProject.projectId);
    sessionStorage.setItem("defaultView", "editStages");
    // localStorage.setItem("cashflowNavigate");
    navigate("/Projects");
  };

  //##########################################
  return (
    <RootCashflowContextMenu
      ref={componentRef}
      initial={{ scaleX: 0 }}
      animate={{ scaleX: [0, 1] }}
      whileTap={{ scale: 1.1 }}
      whileHover={{ opacity: 0.8 }}
      transition={{ scaleX: { duration: 0.2 } }}
      exit={{ scaleX: [1, 0] }}
      topPos={contextMenuPos.top}
      leftPos={contextMenuPos.left}
      onClick={handleClickMenuItem}
    >
      Edit Stage
    </RootCashflowContextMenu>
  );
};

export default CashflowContextMenu;
