import { useLottie } from 'lottie-react'
// import animationData from "./lotties/doxle-loader.json"; // wagging tail
import animationData from '../../../utilities/Lottie/lotties/MailroomWaitng.json' // running doggie

interface props {}

const MailWaitingScreen: React.FC<props> = () => {
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  }

  const style = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  }

  const { View } = useLottie(options, { width: '300px' })

  return <div style={style}>{View}</div>
}

export default MailWaitingScreen
