import React, { useEffect, useState } from "react";
import ProjectAPI from "../../../Services/DoxleAPI/projectAPI";
import { Project } from "../../../Models/project";
import Common from "../../../Services/commonFunctions";
import CostCodeTable from "../CostCodes/CostCodeTable";
import CostCodeStageTable from "../CostCodes/CostCodeStageTable";
import { Company } from "../../../Models/company";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import FilterNavigation from "../../Menu/FilterNavigation";
import AutocompleteCustom from "../../../utilities/Autocomplete/Autocomplete";
import ProjectModal from "./ProjectModal";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import projectAPI from "../../../Services/DoxleAPI/projectAPI";
import {
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@mui/material";
import "./projects.css";
import IconButton from "@mui/material/IconButton";
import sliderIcon from "../../../Assets/Sliders.svg";
import importIcon from "../../../Assets/Import.svg";
import PermitTable from "../Permits/PermitTable";
import CustomDialog from "../../../utilities/Dialog/CustomDialog";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import { Costcode } from "../../../Models/costcode";
import { User } from "../../../Models/user";
import { Bill } from "../../../Models/bill";
import { Specification } from "../../../Models/specification";
import { Comment } from "../../../Models/comments";
import { AddressBookCompany } from "../../../Models/addressBook";
import AddressBookTable from "../AddressBook/AddressBookTable";
import Pricelists from "../Pricelist/Pricelists";
import TakeOffs from "../Measurements/TakeOffs";
import specAPI from "../../../Services/DoxleAPI/specificationAPI";
import Loading from "../../../utilities/Lottie/Loading";
import { TakeOffProvider } from "../../Providers/TakeOffProvider";
import SocketBalls from "./SocketBalls";
import { useSocket } from "../../../Services/Sockets/useSocket";
import userAPI from "../../../Services/DoxleAPI/userAPI";
import addressBookAPI from "../../../Services/DoxleAPI/addressBookAPI";
import { ColumnView } from "../../../Models/columnView";
import TimeLineTable from "../Timeline/TimeLineTable";
import Notifications from "../Notifications/Notifications";
import { useNavigate, useParams } from "react-router-dom";
import CookieService from "../../../Services/cookieService";
import ProjectSelectDropDown from "./ProjectSelectDropDown";
import AccountMappingApp from "../AccountMapping/AccountMappingApp";
import { XeroProvider } from "../../Providers/XeroProvider";
import EditStage from "../Stage/EditStage";
import AddProjectModal from "./addProject";
import TimelineMatrixTable from "../TimelineMatrix/TimelineMatrixTable";
import QuickCostCodeTable from "../QuickCostCodes/QuickCostCodeTable";

const getInitialColumnViews = () => {
  let defaultViews: ColumnView = {
    notesIcon: true,
    fileIcon: true,
    photoIcon: true,
    checklistIcon: true,
    commentIcon: true,
    assignee: true,
    contractor: true,
    budget: true,
    billed: true,
    order: true,
    difference: true,
    startDate: true,
    endDate: true,
    days: true,
    status: true,
    signedBills: true,
  };
  try {
    const localViews: string | null = localStorage.getItem("columnView");
    if (!localViews) return defaultViews;
    const localViewsObj: ColumnView = JSON.parse(localViews) as ColumnView;
    defaultViews = { ...localViewsObj };
    return defaultViews;
  } catch {
    return defaultViews;
  }
};

interface props {
  company: Company | undefined;
}

const Projects: React.FC<props> = ({ company }) => {
  const socket = useSocket();

  const views = [
    "costcode",
    "takeoffs",
    "permits",
    "pricelist",
    "contacts",
    "costcodeStages",
    "timeline",
    "xeroMappingView", //!PETER ADDED THIS LINE,
    "editStages",
  ];
  let defaultView: string = sessionStorage.getItem("defaultView")
    ? sessionStorage.getItem("defaultView") || "costcode"
    : localStorage.getItem("stageView")
    ? "costcodeStages"
    : "costcode";
  if (views.indexOf(defaultView) === -1) defaultView = "costcode";

  const [projects, setProjects] = useState<Array<Project>>([]);
  const [projectSelected, setProjectSelected] = useState<Project | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [newCostCodeTitle, setNewCostCodeTitle] = useState<string>("");
  const [newCostCodeIncome, setNewCostCodeIncome] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [editTitleMode, setEditTitleMode] = useState<boolean>(false);
  const [view, setView] = useState<string>(defaultView);
  const [action, setAction] = useState<string>("");
  const [filter, setFilter] = useState<string | null>(
    sessionStorage.getItem("ccFilter"));
  const [users, setUsers] = useState<Array<User>>([]);
  const [contractors, setContractors] = useState<Array<AddressBookCompany>>([]);
  const unmounted = useMountStatus();
  const { height, width } = WindowDimensions();
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn } = authContext;
  const [userFilter, setUserFilter] = React.useState<string[]>([]);
  const [columnView, setColumnView] = useState<ColumnView>(
    getInitialColumnViews()
  );
  const navigate = useNavigate();
  const allParams = useParams();
  console.log('allParams',allParams)
  const [goToProjectId, goToCostcodeId, goToTab] =
    allParams["*"]?.split("/") || [];
  console.log('goToProjectId',goToProjectId)
  console.log('goToCostcodeId',goToCostcodeId)
  console.log('goToTab', goToTab)
  const handleDialogClose = () => {
    setNewCostCodeTitle("");
    setDialogOpen(false);
    setNewCostCodeIncome(false);
  };

  const handleEditInput = (event: any) => {
    setNewCostCodeTitle(event.target.value);
  };

  const getSpecs = async () => {
    if (projectSelected && projectSelected.projectId) {
      setLoading(true);
      const result = await specAPI.getProjectPDF(projectSelected.projectId);
      setLoading(false);
      if (result) window.open(result);
    }
  };

  const exportCostings = async () => {
    if (projectSelected && projectSelected.projectId) {
      setLoading(true);
      const result = await projectAPI.exportCostings(projectSelected.projectId);
      setLoading(false);
      if (result) window.open(result);
    }
  };

  const handleDialogSubmit = async () => {
    if (!projectSelected?.projectId) return;
    try {
      if (action === "deleteProject") {
        if (await projectAPI.remove(projectSelected.projectId)) {
          setDialogOpen(false);
          setAction("");
          let newProjects: Project[] = [];
          projects.forEach((project: Project) => {
            if (project.projectId !== projectSelected.projectId)
              newProjects.push(project);
          });
          setProjects(newProjects);
          setProjectSelected(newProjects?.[0] || null);
        }
      } else if (action === "addCostCode") {
        const newCostCode: Costcode | undefined = await CostCodeAPI.add({
          project: projectSelected.projectId,
          title: newCostCodeTitle,
          budget: "0.00",
          startDate: new Date().toISOString().substring(0, 10),
          endDate: new Date().toISOString().substring(0, 10),
          budgetOverride: false,
          income: newCostCodeIncome,
          locked: false,
          completed: false,
          status: "D",
          orders: "0.00",
          fileCount: 0,
          commentCount: 0,
          checklistCount: 0,
          checklistFailCount: 0,
          stage: "D",
        });
        if (newCostCode && projectSelected.costCodes)
          setProjectSelected({
            costCodes: [...projectSelected.costCodes, newCostCode],
            ...projectSelected,
          });
        setNewCostCodeTitle("");
        setDialogOpen(false);
        setAction("");
        setNewCostCodeIncome(false);
      }
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const getInitialProject = (projects: Project[]) => {
    const currentProjectStorage: string | null =
      localStorage.getItem("currentProject");
    if (!currentProjectStorage) return projects?.[0] || null;
    const storageProject: Project = JSON.parse(
      currentProjectStorage
    ) as Project;
    const matchedProject = projects.filter(
      (project) => project.projectId === storageProject.projectId
    )?.[0];
    if (matchedProject) return matchedProject;
    return projects?.[0] || null;
  };

  const fetchProjects = async () => {
    if (company) {
      try {
        const response = await ProjectAPI.getList({
          company: company.companyId,
        });
        if (unmounted) return;
        if (response) setProjects(response);
        setProjectSelected(getInitialProject(response));
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    }
  };

  const fetchUsers = async () => {
    if (projectSelected) {
      try {
        const response = (await userAPI.getList(
          projectSelected.company,
          projectSelected.projectId
        )) as User[];
        if (unmounted) return;
        setUsers([...response]);
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    }
  };

  const fetchContractors = async () => {
    if (projectSelected) {
      try {
        const response = (await addressBookAPI.getList(
          projectSelected.company
        )) as AddressBookCompany[];
        if (unmounted) return;
        setContractors([...response]);
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    }
  };

  const handleActionChanged = async (action: string) => {
    if (action === "delete" && projectSelected && projectSelected.projectId) {
      try {
        projectAPI.remove(projectSelected.projectId);
        localStorage.removeItem("currentProject");
        localStorage.removeItem("currentProjectId");
        fetchProjects();
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    } else {
      console.log("setting action", action);
      setAction(action);
    }
  };

  const handleFilterUserClick = (userId: string) => {
    if (userFilter.includes(userId)) {
      setUserFilter((users) => users.filter((user) => user !== userId));
    } else {
      setUserFilter((users) => [...users, userId]);
    }
  };

  const tabClicked = (action: string) => {
    setAction(action);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    fetchProjects();
  }, [company, unmounted]); //pass dependencies

  useEffect(() => {
    fetchUsers();
    fetchContractors();
    setLoading(false);
    if (projectSelected?.siteAddress)
      sessionStorage.setItem("siteAddress", projectSelected.siteAddress);
    if (projectSelected?.projectPrefix)
      sessionStorage.setItem("projectPrefix", projectSelected.projectPrefix);
    else if (projectSelected?.siteAddress)
      sessionStorage.setItem(
        "projectPrefix",
        projectSelected.siteAddress
          .replace(/ /g, "")
          .substring(0, 7)
          .toUpperCase()
      );
  }, [projectSelected]);

  useEffect(() => {
    localStorage.setItem("columnView", JSON.stringify(columnView));
  }, [columnView]);

  useEffect(() => {
    // check params from url
    if (goToProjectId === "Projects") return
    if (goToProjectId && goToCostcodeId && projects.length > 0) {
      const goToProject = projects.filter(
        (pj) => pj.projectId === goToProjectId
      )[0];
      setProjectSelected(goToProject);
      sessionStorage.setItem("defaultView", "costcode");
      setView("costcode");
      setAction("costcode");
    } else if (goToProjectId) {
      const goToProject = projects.filter(
        (pj) => pj.projectId === goToProjectId
      )[0];
      setProjectSelected(goToProject);
    }
  }, [goToProjectId, goToCostcodeId, projects]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        background: "#D5D7E3",
        paddingLeft: "4%",
        paddingRight: "4%",
        boxSizing: "border-box",
      }}
    >
      <div
        className="topBar"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          // backgroundColor: "white",
          paddingLeft: "0px",
          paddingTop: "0px",
        }}
      >
        <div id="projectListGroup">
          <ProjectSelectDropDown
            setProjectSelected={setProjectSelected}
            projects={projects}
            projectSelected={projectSelected}
            setLoading={setLoading}
          />
          {/*<AutocompleteCustom*/}
          {/*  action={"project"}*/}
          {/*  setProjectSelected={setProjectSelected}*/}
          {/*  projects={projects}*/}
          {/*  projectSelected={projectSelected}*/}
          {/*/>*/}
        </div>

        <div
          style={{
            paddingLeft: "0px",
            paddingTop: "20px",
            paddingRight: "15px",
          }}
        >
          <SocketBalls />
        </div>
      </div>

      <div className="menuBar">
        <TableContainer
          sx={{
            width: "fit-content",
            fontSize: "11px",
            overflow: "hidden",
            display: "flex",
          }}
        >
          <Table size="small">
            <TableBody>
              <TableRow
                sx={{
                  ".MuiTableCell-root": {
                    fontFamily: "Roboto Mono",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "11px",
                    lineHeight: "15px",
                    backgroundColor: "#F9F9FF",
                    borderRight: "1px solid #D6D9DF",
                    height: "32px",
                    paddingTop: 0,
                    paddingBottom: 0,
                    letterSpacing: 0,
                  },
                }}
              >
                <TableCell
                  style={{
                    cursor: view === "costcode" ? "default" : "pointer",
                  }}
                  onClick={(e: any) => {
                    if (view !== "costcode")
                      setView(
                        localStorage.getItem("stageView")
                          ? "costcodeStages"
                          : "costcode"
                      );
                    sessionStorage.setItem(
                      "defaultView",
                      localStorage.getItem("stageView")
                        ? "costcodeStages"
                        : "costcode"
                    );
                    setAction("costcode");
                  }}
                  className={view === "costcode" ? "active" : undefined}
                >
                  Budgets
                </TableCell>

                <TableCell
                  style={{ cursor: "pointer" }}
                  onClick={(e: any) => tabClicked("storage")}
                >
                  Files
                </TableCell>

                <TableCell
                  style={{ cursor: "pointer" }}
                  onClick={(e: any) => tabClicked("notes")}
                >
                  Notes
                </TableCell>

                <TableCell>Tickets</TableCell>
                {/*<TableCell*/}
                {/*  style={{*/}
                {/*    cursor: view === "timeline" ? "default" : "pointer",*/}
                {/*  }}*/}
                {/*  onClick={(e: any) => {*/}
                {/*    if (view !== "timeline") setView("timeline");*/}
                {/*    sessionStorage.setItem("defaultView", "timeline");*/}
                {/*  }}*/}
                {/*  className={view === "timeline" ? "active" : undefined}*/}
                {/*>*/}
                {/*  Timeline*/}
                {/*</TableCell>*/}
                <TableCell
                    style={{
                      cursor: view === "timelineTable" ? "default" : "pointer",
                    }}
                    onClick={(e: any) => {
                      if (view !== "timelineTable") setView("timelineTable");
                      sessionStorage.setItem("defaultView", "timeline");
                    }}
                    className={view === "timelineTable" ? "active" : undefined}
                >
                  Timeline
                </TableCell>
                <TableCell
                  style={{
                    cursor: view === "takeoffs" ? "default" : "pointer",
                  }}
                  onClick={(e: any) => {
                    if (view !== "takeoffs") setView("takeoffs");
                    sessionStorage.setItem("defaultView", "takeoffs");
                  }}
                  className={view === "takeoffs" ? "active" : undefined}
                >
                  Measure
                </TableCell>

                {/* <TableCell
                  style={{ cursor: "pointer" }}
                  onClick={(e: any) => tabClicked("photo")}
                >
                  Photos
                </TableCell> */}

                <TableCell
                  style={{ cursor: view === "permits" ? "default" : "pointer" }}
                  onClick={(e: any) => {
                    if (view !== "permits") setView("permits");
                    sessionStorage.setItem("defaultView", "permits");
                  }}
                  className={view === "permits" ? "active" : undefined}
                >
                  Permits
                </TableCell>

                <TableCell
                  style={{
                    cursor: view === "pricelist" ? "default" : "pointer",
                  }}
                  onClick={(e: any) => {
                    if (view !== "pricelist") setView("pricelist");
                    sessionStorage.setItem("defaultView", "pricelist");
                  }}
                  className={view === "pricelist" ? "active" : undefined}
                >
                  Pricelist
                </TableCell>

                <TableCell
                  style={{
                    cursor: view === "editStages" ? "default" : "pointer",
                  }}
                  onClick={(e: any) => {
                    if (view !== "editStages") setView("editStages");
                    sessionStorage.setItem("defaultView", "editStages");
                  }}
                  className={view === "editStages" ? "active" : undefined}
                >
                  Stages
                </TableCell>

                <TableCell
                  style={{
                    cursor: view === "contacts" ? "default" : "pointer",
                  }}
                  onClick={(e: any) => {
                    if (view !== "contacts") setView("contacts");
                    sessionStorage.setItem("defaultView", "contacts");
                  }}
                  className={view === "contacts" ? "active" : undefined}
                >
                  Contacts
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            marginRight: "10px",
          }}
        >
          <FilterNavigation
            menu={view}
            setView={setView}
            setAction={setAction}
            editTitleMode={editTitleMode}
            setEditTitleMode={setEditTitleMode}
            setOpenModal={setOpenModal}
            filter={filter}
            setFilter={setFilter}
            setDialogOpen={setDialogOpen}
            exportCostings={exportCostings}
            getSpecs={getSpecs}
            userList={users}
            userFilter={userFilter}
            handleFilterUserClick={handleFilterUserClick}
            columnView={columnView}
            setColumnView={setColumnView}
          />
          <Notifications />
        </div>
      </div>

      {/*  icons for selecting take offarea / switching back to costcodes  */}
      <ProjectModal
        action={action}
        openModal={openModal}
        closeModal={handleCloseModal}
        projectSelected={projectSelected}
        setProjectSelected={setProjectSelected}
        projects={projects}
        setProjects={setProjects}
        company={company}
      />
      <div>
        {loading ? (
          <Loading />
        ) : view === "costcode" && filter !== "quick" ? (
          <CostCodeTable
            selectedProject={projectSelected}
            editTitleMode={editTitleMode}
            setEditTitleMode={setEditTitleMode}
            costCodeFilter={filter}
            users={users}
            contractors={contractors}
            userFilter={userFilter}
            columnView={columnView}
          />
        ) : view === "costcode" && filter === "quick" ? (
          <QuickCostCodeTable
            selectedProject={projectSelected}
            editTitleMode={editTitleMode}
            setEditTitleMode={setEditTitleMode}
            costCodeFilter={filter}
            users={users}
            contractors={contractors}
            userFilter={userFilter}
            columnView={columnView}
          />
        ) : view === "costcodeStages" ? (
          <CostCodeStageTable
            selectedProject={projectSelected}
            editTitleMode={editTitleMode}
            setEditTitleMode={setEditTitleMode}
            costCodeFilter={filter}
            users={users}
            contractors={contractors}
            userFilter={userFilter}
            columnView={columnView}
          />
        ) : view === "takeoffs" ? (
          <TakeOffProvider>
            <TakeOffs project={projectSelected} />
          </TakeOffProvider>
        ) : view === "permits" ? (
          <PermitTable
            selectedProject={projectSelected}
            editTitleMode={editTitleMode}
            setEditTitleMode={setEditTitleMode}
            permitFilter={filter}
          />
        ) : view === "pricelist" ? (
          <Pricelists company={company} selectedProject={projectSelected} />
        ) : view === "contacts" ? (
          <AddressBookTable company={company} />
        // ) : view === "timeline" ? (
        //   <TimelineMatrixTable project={projectSelected} />
        ) : view === "timelineTable" ? (
            <TimeLineTable
              selectedProject={projectSelected}
              editTitleMode={editTitleMode}
              setEditTitleMode={setEditTitleMode}
              costCodeFilter={filter}
              users={users}
              contractors={contractors}
              userFilter={userFilter}
              columnView={columnView}
            />
        ) : view === "xeroMappingView" ? (
          projectSelected ? (
            <XeroProvider>
              <AccountMappingApp
                projects={projects}
                projectSelected={projectSelected}
              />
            </XeroProvider>
          ) : null
        ) : view === "editStages" ? (
          <EditStage project={projectSelected} />
        ) : null}
      </div>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          {action === "addCostCode"
            ? "Add a Cost Code"
            : action === "deleteProject"
            ? "Are you sure you wish to delete project?"
            : ""}
        </DialogTitle>
        <DialogContent>
          {action === "addCostCode" ? (
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Cost Code Title"
              type="text"
              fullWidth
              variant="standard"
              value={newCostCodeTitle}
              onChange={handleEditInput}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogSubmit}>
            {action === "addCostCode"
              ? "Add"
              : action === "deleteProject"
              ? "Delete"
              : ""}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Projects;
