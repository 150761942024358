import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "./MainTimeline.css";

import TimelineProjectItem from "./ProjectCard";
import ProjectCard from "./ProjectCard";
import { Project, ProjectCashflow } from "../../../Models/project";
import { Company } from "../../../Models/company";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import ProjectAPI from "../../../Services/DoxleAPI/projectAPI";
import Loading from "../../../utilities/Lottie/Loading";
import stageAPI from "../../../Services/DoxleAPI/stageAPI";
type Props = {
  company: Company | undefined;
};

const ProjectTimelineGrid = ({ company }: Props) => {
  const unmounted = useMountStatus();
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn } = authContext;

  const [loading, setloading] = useState<boolean>(false);
  const [cashflowProjects, setcashflowProjects] = useState<
    ProjectCashflow[] | undefined
  >(undefined);

  const fetchCashflowProjects = async () => {
    if (company) {
      setloading(true);
      try {
        const response = await stageAPI.getCashflowProject(company.companyId);
        if (unmounted) return;

        if (response) setcashflowProjects([...response]);
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
      setloading(false);
    }
  };

  useEffect(() => {
    fetchCashflowProjects();
  }, [company]);

  return loading ? (
    <Loading />
  ) : cashflowProjects ? (
    <div
      style={{
        height: "calc(100vh - 40px)",
        width: "90%",
        display: "flex",
        padding: "20px 5% 20px 5%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        columnSpacing={{ sm: 1, xs: 1, md: 16, lg: 16 }}
        rowSpacing={{ md: 8, lg: 8 }}
        columns={{ md: 12, lg: 12 }}
        sx={{
          height: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        {cashflowProjects &&
          cashflowProjects
            .filter((project) => project.projectStatus === "AC")
            .map((project, index) => (
              <Grid
                item
                // xs={2}
                sm={5}
                md={6}
                lg={4}
                key={project.projectId}
                className="project-grid-cell"
                sx={{
                  width: `100%`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ProjectCard project={project} />
              </Grid>
            ))}
      </Grid>
    </div>
  ) : (
    <></>
  );
};

export default ProjectTimelineGrid;
