import React from 'react';
import { useEffect, useState } from 'react';
import {TableRow, TableCell, Checkbox, IconButton} from '@mui/material';
import { Costcode } from "../../../Models/costcode";
import { User } from "../../../Models/user";
import AddComment from '../Comments/AddComment';
import AddUser from '../Users/AddUser';
import selectedTick from "../../../Assets/selectedTick.svg";
import selectedCross from "../../../Assets/selectedCross.svg";
import selectedNA from "../../../Assets/selectedNA.svg";
// import useMountStatus  from '../../../utilities/Helper/checkMountStatus';
import "../CostCodes/costCode.css";
import { useStyles } from './permitStyle';
import comments from "../Comments/Comments";
import AutocompleteCustom from "../../../utilities/Autocomplete/Autocomplete";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import userAPI from "../../../Services/DoxleAPI/userAPI";
import costCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import listIcon from "../../../Assets/ListOrdered.svg";
import listIconHighlight from "../../../Assets/ListOrderedHighlight.svg";
import fileAgreementIcon from "../../../Assets/FileAgreement.svg";
import fileTextIcon from "../../../Assets/FileText.svg";
import Badge from "@mui/material/Badge";
import {Permit} from "../../../Models/permit";
import {Checklist} from "../../../Models/checklist";
import AnswerIcons from "../Checklists/AnswerIcons";
import ChecklistAPI from "../../../Services/DoxleAPI/checklistAPI";
import {ReactComponent as TrashIcon} from "../../../Assets/Trash2.svg";
import ChecklistRow from "./ChecklistRow";
import EditPermit from "./EditPermit";
import {AddressBookCompany} from "../../../Models/addressBook";
import titleCase from "../../../utilities/Helper/titleCase"
import CustomLinearProgressBar from "../../../utilities/Progress/LinearProgressBar";
interface props{
  initPermit: Permit;
  handleCommentIconClicked:Function;
  selectedPermit: Permit | undefined;
  onPermitClicked: Function;
  users: User[];
  handleDeletePermit: Function
  editTitleMode: boolean;
  setEditTitleMode: Function;
  filter: string|null;
  contractors: AddressBookCompany[]
}

const formatDate = (date: string) => {
    return date.substring(8,10)+"."+date.substring(5,7)+"."+date.substring(2,4);
}

const PermitRow: React.FC<props> = (
    {
        initPermit,
        handleCommentIconClicked,
        selectedPermit,
        onPermitClicked,
        users,
        handleDeletePermit,
        editTitleMode,
        setEditTitleMode,
        filter,
        contractors,
    }) => {
    const [permit, setPermit] = useState<Permit>(initPermit);
    const [title, setTitle] = useState<string>(initPermit.title);
    // const [editTitleOption, setEditTitleOption]  = useState<string|null>(null);
    const [addChecklistItemMode, setAddChecklistItemMode] = useState<boolean>(false);
    const [checklistItems, setChecklistItems] = useState<Checklist[]>(initPermit.checklistItems || []);
    const [startDate, setStartDate] = useState<string>(initPermit.startDate);
    const [endDate, setEndDate] = useState<string>(initPermit.endDate);
    // const [assignedUsers, setAssignedUsers] = useState<User[]>([]);
    const [completed, setCompleted] = useState<boolean>(false);
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;
    //const unmounted = useMountStatus();
    const {height, width} = WindowDimensions();
    const limitAssignee = width < 928 ? 1 : 3;

    const getRowClasses = () => {
        let rowClass:string = "tableRowStyle"
        if (completed) { rowClass += " strikeout" }
        return rowClass
    }

    const calculateCompleted = () => {
        let numberOfCompletedItems: number = 0
        checklistItems.forEach((item: Checklist) => {
            if (item.answer === "YES" || item.answer === "NA") ++numberOfCompletedItems
        })
        return numberOfCompletedItems
    }

    const getTextColor = () => {
        // if (status === "P") return "red";
        // if (status === "N") return "#c4c5c7";
        return "#0D0D0D";
    }

    const handleCheckListChange = async(checklistInput: Checklist, value: "YES"|"NO"|"NA") =>{
        try{
            const setValue = value === checklistInput.answer ? null : value;
            if (checklistInput.checklistId) {
                if (!user?.userId) {console.log("User not found"); setLoggedIn(false); return;}
                await ChecklistAPI.updateChecklist(checklistInput.checklistId, {answer: setValue}, user.userId)
            }
            setChecklistItems((prevItems: Checklist[]) => [...prevItems.map((item: Checklist) => {
                if (item.checklistId !== checklistInput.checklistId) return item
                else return {...item, answer: value}
            })])
        }
        catch (err){
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);;
        }
    } //pass dependencies

    const handleDeleteChecklistItem = async (checklistItem: Checklist) =>{
        if (!checklistItem.checklistId) return;
        const result = await ChecklistAPI.remove(checklistItem.checklistId)
        if (!result) return
        let updatedChecklistItems: Checklist[] = []
        checklistItems.forEach((checklist: Checklist) => {
            if (checklist.checklistId !== checklistItem.checklistId) updatedChecklistItems.push(checklist)
        })
        setChecklistItems([...updatedChecklistItems])
    }

    // const handleTitleClicked = (checklistGroupId:string) =>{
    //     if (!(editTitleOption === checklistGroupId)) setEditTitleOption(checklistGroupId)
    // }
    //
    // const exitEditTitleMode = () =>{
    //     setEditTitleMode(false);
    //     setEditTitleOption(null);
    // }

    const handleAddChecklistClicked = (newChecklist:Checklist|undefined) => {
        if (!addChecklistItemMode) {
            setAddChecklistItemMode(true);
            return;
        }
        if (newChecklist) {
            setChecklistItems([...checklistItems, newChecklist]);
            setAddChecklistItemMode(false);
        }
    }

    useEffect(() => {
        if (permit.checklistItems) setChecklistItems([...permit.checklistItems])
    },[permit]);

    // useEffect(() => {
    //     let c = true;
    //     let no = 0;
    //     let yes = 0;
    //     let nul = 0;
    //     let na = 0;
    //     checklistItems.forEach((item: Checklist) => {
    //         if (item.answer === "NO"){ c = false; no += 1; }
    //         if (item.answer === null){ c = false; nul += 1; }
    //         if (item.answer === "YES") yes += 1;
    //         if (item.answer === "NA") na += 1;
    //     })
    //     setCompleted(c);
    //     setYesAnswers(yes);
    //     setNaAnswers(na);
    //     setNoAnswers(no);
    //     setNullAnswers(nul);
    // },[checklistItems]);

    useEffect(() => {
        setStartDate(initPermit.startDate);
        setEndDate(initPermit.endDate);
    }, [initPermit.startDate, initPermit.endDate ])

    return(
      <>
      <TableRow className={getRowClasses()} key={initPermit.checklistGroupId} style={{opacity: "1" }}>
          <TableCell colSpan={2} className='strikeCell'
                     style={{position: "sticky", paddingLeft: '20px', zIndex: 49, background: "white"}}
                     sx={{color: getTextColor(), ...useStyles.tableBodyTitleFieldStyle}}
                     onClick={() => onPermitClicked(permit, '4')}
                     // onClick={() => !editTitleMode ? onPermitClicked(permit, '4') : handleTitleClicked(initPermit.checklistGroupId) }
          >
              {/*{   editTitleMode && editTitleOption === initPermit.checklistGroupId*/}
              {/*    ?*/}
              {/*    <EditPermit permit={permit} action={"permitTitle"} setValue={setTitle} exitEditTitleMode={exitEditTitleMode}/>*/}
              {/*    :*/}
              {
                  titleCase(title)
              }
          </TableCell>

          {/* File Storage Icon Cell */}
          <TableCell className='strikeCell' onClick={() => onPermitClicked(permit, "3")} sx={{color: getTextColor(), ...useStyles.tableBodyStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", maxWidth: "40px"}}>
              <Badge
                  style={{ transform: "scale(0.8)", cursor: "pointer", color: "#B6A9DA", top: "-8px", left: "15px" }}
                  badgeContent={0}
                  onClick={() => {}}
              />
              <img src={fileTextIcon} alt="file"/>
          </TableCell>


          {/* Comment Icon Cell */}
          <TableCell className='strikeCell' onClick={() => handleCommentIconClicked({permit: permit})} sx={{color: getTextColor(), ...useStyles.tableBodyStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", maxWidth: "40px"}}>
              <AddComment permit={permit} clickFunction={() => {}} />
          </TableCell>

          {/*/!* Assigned users Cell *!/*/}
          <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", width: "150px"}}>

          </TableCell>

          {/*/!* Contractor Cell *!/*/}
          <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", paddingLeft: 3, paddingRight: 3}}>

          </TableCell>


          {/* Start Date Cell */}
          <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyDateFieldStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)"}}>
              <EditPermit permit={permit} value={startDate} action={"startDate"} setValue={setStartDate}/>
          </TableCell>

          {/* End Date Cell */}
          <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyDateFieldStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)"}}>
              <EditPermit permit={permit} value={endDate} action={"endDate"} setValue={setEndDate}/>
          </TableCell>

          {/* Status Date Cell */}
          <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyDateFieldStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)"}}>
              <CustomLinearProgressBar completed={calculateCompleted()} total={checklistItems.length}/>
          </TableCell>
          <TableCell className='strikeCell' sx={{color: getTextColor(), ...useStyles.tableBodyDateFieldStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", maxWidth: "40px"}}>
              <TrashIcon onClick={(e: any) => handleDeletePermit(permit)} style={{cursor: "pointer", transform: "scale(0.9)"}} />
          </TableCell>
      </TableRow>
      {checklistItems.map((checklistItem: Checklist, i)=>(
        <ChecklistRow
            key={checklistItem.checklistId}
            i={i}
            initChecklist={checklistItem}
            handleCheckListChange={handleCheckListChange}
            handleDeleteChecklist={handleDeleteChecklistItem}
            display={permit.checklistGroupId === selectedPermit?.checklistGroupId ? "" : "none"}
            handleCommentIconClicked={handleCommentIconClicked}
            users={users}
            // editTitleMode={editTitleMode}
            // editTitleOption={editTitleOption}
            // setEditTitleOption={setEditTitleOption}
            // exitEditTitleMode={exitEditTitleMode}
            contractors={contractors}
        />
      ))}
          {addChecklistItemMode
              ?
                  <TableRow style={{display: permit.checklistGroupId === selectedPermit?.checklistGroupId ? "" : "none"}}>
                      <TableCell colSpan={10} style={{
                          cursor: "pointer",
                          textAlign: "center",
                          position: "sticky",
                          left: 0,
                          zIndex: 49,
                          background: "white",
                          opacity: "0.7",
                          color: "#5a36be"
                      }}>
                        <EditPermit permit={permit} action={"addChecklist"} setValue={handleAddChecklistClicked}/>
                      </TableCell>
                  </TableRow>
              :
                  <TableRow style={{display: permit.checklistGroupId === selectedPermit?.checklistGroupId ? "" : "none"}}>
                      <TableCell colSpan={10} onClick={() => handleAddChecklistClicked(undefined)} style={{
                          cursor: "pointer",
                          textAlign: "center",
                          position: "sticky",
                          left: 0,
                          zIndex: 49,
                          background: "white",
                          opacity: "0.7",
                          color: "#5a36be"
                      }}>ADD A CHECKLIST ITEM</TableCell>
                  </TableRow>
          }
      </>
    )
}

export default PermitRow