import React, { useState } from "react";
import {
    List,
    ListItem,
    Box,
    Collapse,
    IconButton,
    TableRow,
    TableCell,
    TextField,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import NoteAPI from "../../../Services/DoxleAPI/noteAPI";
import EditNote from "./EditNote";
import {
    authContextInterface,
    useAuth,
} from "../../../components/Providers/AuthProvider";
import { Note, INoteLine } from "../../../Models/note";
import NoteLine from "./NoteLine";

interface props {
    action: string;
    row: Note;
    index: number;
    costcode?: any;
    editMode?: string;
    editIconClicked?: boolean;
    setEditMode?: Function;
    openNotesStatus?: boolean;
    handleCheckBox?: Function;
    setNoteTitle?: Function;
    handleSaveButton?: Function;
}

const NoteRow: React.FC<props> = ({
    action,
    row,
    index,
    costcode,
    editMode,
    editIconClicked,
    openNotesStatus,
    setEditMode,
    handleCheckBox,
    setNoteTitle,
    handleSaveButton,
}) => {
    const [openNotes, setOpenNotes] = useState(false);
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;
    const [lines, setLines] = useState<INoteLine[]>(row.lines);
    const [newLine, setNewLine] = useState<string>("");
    const handleRowClick = (event: any) => {
        if (event.target.type === undefined) {
            handleArrowIcon();
        }
    };

    const handleArrowIcon = () => {
        if (setOpenNotes && setEditMode) {
            setOpenNotes(!openNotes);
            setEditMode(undefined);
        }
    };

    const handleNewLineChange = (e: any) => {
        setNewLine(e.target.value);
    };

    const handleNewLineKeyUp = async (e: any, noteId: string | undefined) => {
        if (e.keyCode !== 13) return;
        if (!noteId) return;

        const updatedLines = [...lines, { text: newLine }];
        try {
            if (!user?.userId) return;
            if (!noteId) return;

            let body: any = {};

            body.costCode = row.costCode;
            body.creatorId = row.createdBy.userId;
            body.userId = user.userId;
            body.lines = [];
            updatedLines.forEach((ln) => body.lines.push(ln.text));
            const response = await NoteAPI.update(noteId, body);
            if (response) {
                setLines(response.lines);
                setNewLine("");
            }
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
    };

    return (
        <React.Fragment>
            <TableRow
                key={"note-#" + row.noteId}
                onClick={(event: any) => handleRowClick(event)}
            >
                <TableCell
                    sx={{
                        width: "10px",
                        py: 0,
                        height: "33px",
                    }}
                >
                    <EditNote
                        action="checkbox"
                        row={row}
                        openNotes={openNotesStatus}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        editIconClicked={editIconClicked}
                        handleCheckBox={handleCheckBox}
                    />
                </TableCell>
                <TableCell
                    component="th"
                    scope="row"
                    sx={{
                        fontFamily: "Roboto Mono",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#000000",
                        py: 0,
                        height: "33px",
                    }}
                >
                    <EditNote
                        action="title"
                        row={row}
                        setNoteTitle={setNoteTitle}
                        openNotes={openNotesStatus}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        editIconClicked={editIconClicked}
                        handleCheckBox={handleCheckBox}
                        handleSaveButton={handleSaveButton}
                    />
                </TableCell>
                <TableCell
                    sx={{
                        width: "50px",
                        py: 0,
                        height: "33px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "end",
                        }}
                    >
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleArrowIcon()}
                            sx={{
                                // color: "#0044CC",
                                textAlign: "right",
                                transform: "scale(0.6)",
                            }}
                        >
                            {openNotes ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow key={"notelines-#" + row.noteId}>
                <TableCell style={{ padding: 0 }} colSpan={9}>
                    <Collapse in={openNotes} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 0 }}>
                            <List
                                sx={{
                                    marginBottom: "25px",
                                    lineBreak: "anywhere",
                                    mx: "20px",
                                }}
                            >
                                {lines.map((line: INoteLine, i) => (
                                    <NoteLine line={line} i={i} setLines={setLines} />
                                ))}
                                <ListItem
                                    key={"input-#" + row.noteId}
                                    sx={{
                                        fontFamily: "Roboto Mono",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        fontSize: "12px",
                                        lineHeight: "16px",
                                        color: "#000000",
                                        background: "#EAEBEF",
                                        borderRadius: "4px",
                                        mb: "2px",
                                        pl: "10px",
                                    }}
                                >
                                    <TextField
                                        variant="standard"
                                        placeholder={"Add Line ..."}
                                        value={newLine}
                                        onChange={handleNewLineChange}
                                        onKeyUp={(e: any) =>
                                            handleNewLineKeyUp(e, row.noteId)
                                        }
                                        sx={{width: "100%",}}
                                        InputProps={{
                                            disableUnderline: true,
                                            sx: {
                                                fontFamily: "Roboto Mono",
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                fontSize: "10px",
                                                lineHeight: "13px",
                                                width: "100%",
                                                "& .MuiInputBase-input": {
                                                    textAlign: "left",
                                                    // color:"#",
                                                    paddingBottom: "1px",
                                                    paddingTop: "1px",
                                                    maxHeight: "28px",
                                                    width: "100%",
                                                    // border: "white",
                                                    borderRadius:
                                                        "5px 5px 0px 0px",
                                                },
                                                "& .MuiInputLabel-formControl":
                                                    {
                                                        color: "#9974fe",
                                                    },
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: { color: "#9974fe" },
                                            sx: {
                                                top: "-10px",
                                                "& .MuiInputLabel-shrink": {
                                                    top: "0px !important",
                                                },
                                            },
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default NoteRow;
