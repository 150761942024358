import axios from "axios"
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";
import { Checklist } from "../../Models/checklist";
import { Costcode } from "../../Models/costcode";
import { Image } from "../../Models/image";
import {Permit} from "../../Models/permit";

interface GetParams {
  costCodeId?: string,
  checklistGroupId?: string;
  ordering?: string,
}

//? return the ChecklistItems for the particular costcode
const getList = async ({ costCodeId, checklistGroupId, ordering }: GetParams) => {
  let result: Checklist[] = []
  if (offline) { return result }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) { throw "AccessTokenNotFound" }

  try {
    let params: any = {};
    if (ordering) { params.ordering = ordering }
    if (costCodeId) { params.cost_code = costCodeId }
    if (checklistGroupId) { params.permit = checklistGroupId }
    const response = await axios.get(
      baseAddress + "/checklist/",
      {
        headers: { Authorization: "Bearer " + accessToken },
        params: params
      }
    )
    result = response.data as Array<Checklist>;
    console.log("CHECKLIST:", result)
  } catch (error) {
    console.error("ERROR FETCHING CHECKLIST", error)
  }
  return result
}

//? adds a checklistItem for a particular costcode
const addChecklist = async (checklist: Checklist, userId: string) => {
  console.log("Adding New Checklist", checklist);
  let result: Checklist | undefined = undefined;
  if (offline) { return result }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) { throw "AccessTokenNotFound" }

  try {
    let payload: any = checklist
    payload.lastModifiedBy = userId;
    const response = await axios.post(
      baseAddress + "/checklist/", payload,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    )
    if (response.status === 201) {
      result = response.data
      console.log('SUCCESS: Checklist Added', result)
    }
  } catch (error) {
    console.error("ERROR ADDING CHECKLIST ITEM", error)
  }
  return result
}

interface UpdateBody {
  question?: string;
  answer?: "YES" | "NO" | "NA" | null;
  assignedUsers?: string[];
  assignedContractor?: string | null;
}
//? updating a  checklistItem with checklistId
const updateChecklist = async (checklistId: string, { question, answer, assignedUsers, assignedContractor }: UpdateBody, userId: string) => {
  console.log("Updating Checklist ", checklistId);
  let result: Checklist | undefined = undefined;
  if (offline) { return result }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) { throw "AccessTokenNotFound" }
  let body: any = { lastModifiedBy: userId }
  try {
    if (answer !== undefined) body.answer = answer;
    if (question) body.question = question;
    if (assignedUsers) body.assignedUsers = assignedUsers;
    if (typeof assignedContractor !== "undefined") body.assignedContractor = assignedContractor;
    const response = await axios.patch(
      baseAddress + "/checklist/" + checklistId + "/", body,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    )
    if (response.status === 200) {
      result = response.data
      console.log('SUCCESS: Checklist Updated', result)
    }
  } catch (error) {
    console.error("ERROR UPDATING CHECKLIST", error)
  }
  return result
}


const remove = async (checklistId: string) => {
  console.log("Deleting Checklist ", checklistId);
  let result: boolean = false;
  if (offline) { return result }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) { throw "AccessTokenNotFound" }

  try {
    const response = await axios.delete(
      baseAddress + "/checklist/" + checklistId + "/",
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    )
    result = response.status === 204;
  } catch (error) {
    console.error("ERROR DELETING CHECKLIST", error)
  }
  return result
}

const addChecklistImage = async (checklistId: string, files: Blob[], setProgress: Function) => {
  interface Result { images: Image[]; errors: string[] }
  let result: Result = { images: [], errors: [] }
  if (offline) { return result }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) { throw "AccessTokenNotFound" }
  try {

    const formData = new FormData();
    formData.append("checklistId", checklistId);
    files.forEach(file => {
      formData.append("images", file)
    })
    
    const response = await axios.post(
      baseAddress + "/checklist/img/", formData,
      {
        headers: { Authorization: "Bearer " + accessToken },
        onUploadProgress: progressEvent => setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      }
    )
    result.images = response.status == 201 || response.status == 207 ? response.data.images as Image[] : []
    result.errors = response.status == 207 ? response.data.errors as string[] : []
    console.log(result.images.length + "IMAGES SAVED SUCCESSFULLY", result.images)
    console.log(result.errors.length + "ERRORS SAVING IMAGES", result.errors)
  } catch (error: any) {
    result.errors = error.status == 400 ? error.data.errors as string[] : []
    console.log("ERROR ADDING CHECKLIST IMAGE", error)
  }
  return result
}

const removeChecklistImage = async (imageId: string)  => {
  let result:boolean = false;
  if (offline){ return result}
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }

  try {
    const response = await axios.delete(
        baseAddress+"/checklist/img/"+imageId+"/",
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
    )
    result = response.status == 204;
  } catch (error) {
    console.log("ERROR REMOVING CHEKCLIST IMAGE", error)
  }
  return result;
}

const uploadCSV = async (costCodeId: string, file: File) => {
  interface Result{
    checklists: Permit[];
    errors: string[];
  }
  let result:Result = { checklists: [], errors: [] };
  if (offline){ return result}
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  const formData = new FormData()
  formData.append("cost_code", costCodeId);
  formData.append("file", file);
  try {
    const response = await axios.post(
        baseAddress+"/checklist/csv/",
        formData,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
    )
    if (response.status === 207) result = response.data as Result;
  } catch (error) {
    console.log("ERROR UPLOADING CSV", error)
  }
  return result;
}

const ChecklistAPI = {
  getList,
  addChecklist,
  updateChecklist,
  // getDetailed,
  remove,
  // update,
  // add,
  addChecklistImage,
  removeChecklistImage,
  uploadCSV
};

export default ChecklistAPI