import React, { useState, useEffect, useRef } from "react";
import {Grid, TextField, Box, Button, Typography} from "@mui/material";
import ChecklistTable from "./ChecklistTable";
import { ReactComponent as PlusCircle } from "../../../Assets/PlusCircleWhite2.svg";
import checklistGroupAPI from "../../../Services/DoxleAPI/checklistGroupAPI";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import { User } from "../../../Models/user";
import { Permit } from "../../../Models/permit";
import { Checklist } from "../../../Models/checklist";
import { AddressBookCompany } from "../../../Models/addressBook";
import {DropzoneArea} from "material-ui-dropzone";
import {baseAddress} from "../../../settings";
import ChecklistAPI from "../../../Services/DoxleAPI/checklistAPI";


interface props {
    costCodeId: string;
    users?: User[];
    contractors?: AddressBookCompany[];
    setLoading: Function;
}

const Checklists: React.FC<props> = ({
    costCodeId,
    users,
    contractors,
    setLoading
}) => {
    const [checklistLists, setChecklistLists] = useState<Permit[]>([]);
    const [listTitle, setListTitle] = useState<string>("");
    const [uploadView, setUploadView] = useState<boolean>(false);
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;

    const ref = useRef(null);

    const handleFileDrop = async (files: File[]) => {
        setLoading(true)
        if (!(costCodeId && files?.[0])) return
        const response = await ChecklistAPI.uploadCSV(costCodeId, files[0])
        setChecklistLists([...response.checklists])
        console.log("UPLOAD ERRORS", response.errors)
        setLoading(false)
        setUploadView(false)
    }

    const deleteChecklistList = async (checklistGroupId: string | undefined) => {
        try {
            if (!checklistGroupId) return;
            const response = await checklistGroupAPI.remove(checklistGroupId);
            if (!response) return;
            let newLists: Permit[] = [];
            checklistLists.forEach((list: Permit) => {
                if (checklistGroupId !== list.checklistGroupId) newLists.push(list);
            });
            setChecklistLists([...newLists]);
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
    };

    const handleListTitleChange = (e: any) => {
        setListTitle(e.target.value);
    };

    const handleListTitleKeyUp = async (e: any) => {
        try {
            if (!user?.userId) return;
            if (e.keyCode === 13) {
                const response = await checklistGroupAPI.add(
                    {
                        title: listTitle,
                        checklistGroupId: "",
                        costCode: costCodeId,
                        timeStamp: new Date().toISOString(),
                        startDate: new Date().toISOString().substring(0, 10),
                        endDate: new Date().toISOString().substring(0, 10),
                    },
                    user.userId
                );
                if (response) setChecklistLists([...checklistLists, response]);
                setListTitle("");
            }
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
    };

    const fetchPermits = async () => {
        setLoading(true);
        try {
            const response = (await checklistGroupAPI.getList(
                null,
                null,
                costCodeId
            )) as Permit[];
            console.log(response);
            // if (unmounted) return;
            setChecklistLists([...response]);
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchPermits();
    }, [costCodeId]);

    return (
        <>
            <Grid container paddingX="0px">
                <Grid item xs={8}>
                    {uploadView &&
                        <div
                            style={{
                                paddingTop: "20px",
                                marginBottom: "50px",
                                textAlign: "center",
                                cursor: "pointer",
                                color: "#5a36be"
                            }}
                        >

                            <DropzoneArea
                                onDrop={(files: File[]) => {
                                    handleFileDrop(files)
                                }}
                                filesLimit={1}
                                maxFileSize={10000000}
                                dropzoneText='Drag your CSV here or click to select'
                                showPreviews={false}
                                showPreviewsInDropzone={false}
                                showFileNamesInPreview={false}
                                acceptedFiles={['text/csv']}
                                showAlerts={false}
                            />
                            <br/>
                            <Typography onClick={() => window.open('https://doxleexpress.s3.ap-southeast-2.amazonaws.com/Checklist+Template.csv')}>
                                Get Template
                            </Typography>
                        </div>
                    }
                    {!uploadView &&
                        checklistLists.map((list: Permit) => (
                            <ChecklistTable
                                key={list.checklistGroupId}
                                checklistList={list}
                                deleteChecklistList={deleteChecklistList}
                                users={users}
                                contractors={contractors}
                            />
                        ))
                    }

                    <Grid
                        container
                        sx={{
                            paddingTop: "20px",
                            marginBottom: "50px",
                        }}
                    >
                        <Grid item xs={1} sx={{ height: "24px" }} />
                        <Grid item xs={10}>
                            <TextField
                                inputRef={ref}
                                placeholder="Add List"
                                value={listTitle}
                                onChange={handleListTitleChange}
                                onKeyUp={handleListTitleKeyUp}
                                variant="standard"
                                sx={{
                                    color: "#5A36BE",
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    sx: {
                                        color: "#5A36BE",
                                        fontWeight: 600,
                                        fontSize: "12px",
                                        fontFamily: "Roboto Mono",
                                        fontStyle: "normal",
                                        lineHeight: "16px",
                                        "& .MuiInputBase-input": {
                                            textAlign: "left",
                                            paddingBottom: "1px",
                                            paddingTop: "1px",
                                            maxHeight: "28px",
                                            width: "70vw",
                                            // border: "white",
                                            borderRadius: "5px 5px 0px 0px",
                                        },
                                        "& .MuiInputLabel-formControl": {
                                            color: "#9974fe",
                                        },
                                    },
                                }}
                                InputLabelProps={{
                                    style: { color: "#9974fe" },
                                    sx: {
                                        top: "-10px",
                                        "& .MuiInputLabel-shrink": {
                                            top: "0px !important",
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={1} />
                    </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid
                    item
                    xs={3}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="start"
                >
                    <Button
                        sx={{
                            background: "#5A36BE",
                            borderRadius: "4px",
                            paddingY: "3px",
                            paddingX: "10px",
                            "&:hover": { background: "#855EF0" },
                            textTransform: "none",
                            marginRight: "12px"
                        }}
                        variant="contained"
                        startIcon={<PlusCircle />}
                        onClick={() =>  setUploadView(!uploadView)}
                    >
                    Upload Checklists
                    </Button>
                    <Button
                        sx={{
                            background: "#5A36BE",
                            borderRadius: "4px",
                            paddingY: "3px",
                            paddingX: "10px",
                            "&:hover": { background: "#855EF0" },
                            textTransform: "none",
                        }}
                        variant="contained"
                        startIcon={<PlusCircle />}
                        onClick={() =>
                            ref.current && (ref as any).current.focus()
                        }
                    >
                        Add Checklist
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default Checklists;
