import React, { useState, useEffect, useRef } from "react";
import { Box, Button, TextField } from "@mui/material";

import { ReactComponent as TrashIcon } from "../../../../Assets/Trash-red.svg";
import { ReactComponent as CheckIcon } from "../../../../Assets/checkFinish.svg";
import { useDetectClickOutside } from "react-detect-click-outside";
import { authContextInterface, useAuth } from "../../../Providers/AuthProvider";
import NoticeAPI from "../../../../Services/DoxleAPI/noticeAPI";
import {
  categorisingEditAction,
  checkNoticeTimeRange,
  checkStartPosOfNotice,
  convertDateAndTime,
  formatInitialWidthOfNotice,
  getDateRangeNotice,
  getDueDateOfNotice,
  getTimeRangeNotice,
  INoticeAxisPos,
  ITimeRange,
  TDailySelect,
  TDragAction,
} from "../CommonResources";
import { Notice } from "../../../../Models/notice";
import NoticePopover from "../NoticePopover";
import { User } from "../../../../Models/user";
import titleCase from "../../../../utilities/Helper/titleCase";
import { AnimatePresence, motion } from "framer-motion";

interface props {
  notice: Notice;
  taskTimeRange: ITimeRange;
  assignedUser: User;
  pageTimeRange: ITimeRange;
  rowSize: number;
  notices: Notice[];
  setnotices: Function;
  setcurrentMousePosX: Function;
  boundLeft: number;
  setCurrentUserRow: Function;

  setCurrentDraggedNotice: Function;
  action: "editGroup" | "editSingle";
  setSelectedTimeValueCell?: Function;
  viewType: "daily" | "weekly";
  showSideTimeList: boolean;
  setshowSideTimeList: Function;
  timeValueSelectedFromSideList: number | undefined;
  settimeValueSelectedFromSideList: Function;
}

const TeamViewNoticeItem: React.FC<props> = ({
  notice,
  taskTimeRange,
  pageTimeRange,
  rowSize,
  notices,
  setnotices,
  setcurrentMousePosX,
  setCurrentUserRow,
  boundLeft,
  setCurrentDraggedNotice,
  action,
  setSelectedTimeValueCell,
  assignedUser,
  viewType,
  showSideTimeList,
  setshowSideTimeList,
  timeValueSelectedFromSideList,
  settimeValueSelectedFromSideList,
}) => {
  //################################## HANDLE DAILY VIEW BEHAVIOURS FOR EDIT GROUP###########################
  const [mousePos, setmousePos] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  //######SIZE OF ROW AND EACH CELL#####
  const widthRatioOfCell = 1 / (pageTimeRange.end - pageTimeRange.start + 1);
  const widthOfACell: number = widthRatioOfCell * (rowSize as number);
  //####################################
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, user } = authContext;

  const [showNextPageTime, setshowNextPageTime] = useState<boolean>(false);
  // const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  // const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  //   document.body.removeEventListener("mousemove", () => {});
  // };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };

  //this ref is to get the current Width of notice
  const noticeRef = useRef<HTMLDivElement | null>(null);

  //state to update the width of notice used for dragging and moving function
  const [initialWidth, setInitialWidth] = useState<string>(
    formatInitialWidthOfNotice(
      notice,
      taskTimeRange,
      widthRatioOfCell,
      rowSize as number,
      "percent",
      pageTimeRange
    )
  );

  // useEffect(() => {
  //   console.log("INITIAL WIDTH: ", initialWidth);
  // }, [initialWidth]);
  // this state is used to update the absolute posX of notice, used for "left" value
  const [noticeStartPos, setnoticeStartPos] = useState<string>(
    checkStartPosOfNotice({
      taskTimeRange: taskTimeRange,
      pageTimeRange: pageTimeRange,
      valueReturnType: "percent",
      rowSize: rowSize as number,
      widthRatioOfCell: widthRatioOfCell,
    })
  );
  //function to handle update notice based on dragging and moving notice
  const handleDragAndExtendNotice = (mouseDownEvent: React.MouseEvent) => {
    // setAnchorEl(null);
    mouseDownEvent.preventDefault();
    //get the start time value
    //only trigger drag function when left mouse click
    if (mouseDownEvent.nativeEvent.button === 0 && action === "editGroup") {
      //initial width at the time mouse event triggered
      const startWidth = noticeRef.current?.offsetWidth;
      // console.log("START WITH:", startWidth);
      const startPositionX = mouseDownEvent.pageX;
      //console.log('WIDTH START:', startWidth)
      // console.log("START PS X: ", startPositionX);
      //get initial mouse pos when mouse event triggered
      const bounds = mouseDownEvent.currentTarget.getBoundingClientRect();
      const mousePosX = mouseDownEvent.clientX - bounds.left;
      //console.log('START X POSITION:', mousePosX)

      const startPosOfNotice = noticeRef.current?.getBoundingClientRect().left;
      const endPosOfNotice = noticeRef.current?.getBoundingClientRect().right;
      const startWidthOfNotice =
        noticeRef.current?.getBoundingClientRect().width;
      // console.log("INITIAL STATE POS:", noticeStartPos);
      // console.log("START based on page X:", startPosOfNotice);

      //update the current position to control animation
      setcurrentMousePosX(startPositionX);
      //update currentRow focused

      //when mouse action identified => categorise actions based on mouse position
      // when mouse pos >=0 and <=20 (the start edge pos of the displayed notice where the cursor will be displayed as 'w-resize') => edit start date action
      // and reversely, mouse pos >= (notice width -20) => edit due date action
      //and when mouse pos is in the pos between above conditions => moving edit action
      let actionType: "dueDate" | "startDate" | "moving";
      if (mousePosX >= 0 && mousePosX <= 10) {
        actionType = "startDate";
      } else if (startWidth && mousePosX >= startWidth - 10) {
        actionType = "dueDate";
      } else {
        actionType = "moving";
      }

      //functioon to handle mouse moving/ dragging
      function onMouseMove(mouseMoveEvent: any) {
        if (
          startWidth &&
          startPosOfNotice &&
          startWidthOfNotice &&
          endPosOfNotice
        ) {
          setcurrentMousePosX(mouseMoveEvent.pageX);
          let startWidthWithoutPadding: number = startWidth - 25;
          if (actionType === "dueDate") {
            //action is edit due date=> update the width of notice when mouse move
            setInitialWidth(
              (
                startWidthWithoutPadding -
                startPositionX +
                mouseMoveEvent.pageX
              ).toString() + "px"
            );

            setcurrentMousePosX(mouseMoveEvent.pageX);
          } else if (actionType === "startDate") {
            //action is edit start date => update absolute position "left" value and width of notice
            let initialWidth: number = startWidthOfNotice - 15 - mousePosX;
            // console.log("START NOTICE WIDTH:", startWidthOfNotice);
            // console.log("START POS X:", startPositionX);
            // console.log("MOUSE MOVE:", mouseMoveEvent.pageX);
            let updatedWidth =
              initialWidth + startPositionX - mouseMoveEvent.pageX;

            if (mouseMoveEvent.pageX < boundLeft) setnoticeStartPos(`5px`);
            else if (mouseMoveEvent > boundLeft + (rowSize as number))
              setnoticeStartPos(
                `${boundLeft + (rowSize as number) - widthOfACell + 5}px`
              );
            else {
              setnoticeStartPos(
                `${
                  startPosOfNotice -
                  startPositionX +
                  mouseMoveEvent.pageX -
                  boundLeft
                }px`
              );

              if (updatedWidth <= widthOfACell - 30) {
                setInitialWidth(`${widthOfACell - 30}px`);
              } else
                setInitialWidth(
                  `${initialWidth + startPositionX - mouseMoveEvent.pageX}px`
                );
            }
          } else {
            const distanceOfMouseToLeftEdge = startPositionX - startPosOfNotice;
            const distanceOfMouseToRightEdge = endPosOfNotice - startPositionX;
            if (mouseMoveEvent.pageX - distanceOfMouseToLeftEdge <= boundLeft)
              setnoticeStartPos(`5px`);
            else if (
              mouseMoveEvent.pageX + distanceOfMouseToRightEdge >=
              boundLeft + rowSize
            )
              setnoticeStartPos(`${rowSize - startWidth - 5}px`);
            else
              setnoticeStartPos(
                `${
                  startPosOfNotice -
                  startPositionX +
                  mouseMoveEvent.pageX -
                  boundLeft
                }px`
              );
          }
        } else document.body.removeEventListener("mousemove", onMouseMove);
      }
      function onMouseUp(mouseUpEvent: any) {
        document.body.removeEventListener("mousemove", onMouseMove);
        // uncomment the following line if not using `{ once: true }`
        // document.body.removeEventListener("mouseup", onMouseUp);
        if (
          startWidth &&
          rowSize &&
          startWidthOfNotice &&
          mouseUpEvent.pageX !== startPositionX
        ) {
          const ratio = Math.ceil(
            (startWidth - startPositionX + mouseUpEvent.pageX) /
              rowSize /
              widthRatioOfCell
          );
          const startRatio = Math.ceil(startWidth / rowSize / widthRatioOfCell);

          if (ratio !== startRatio) {
            if (actionType === "dueDate") {
              //this ratio is calculated when users release mouse
              //ratio = (*Final width since mouse released* / *total width size of each row in px* / *width size of each cell* )

              console.log("DUE RATIO:", ratio);
              //the width of notice item will be automatically stretched to fill the rest width of the cell where the endpoint of mouse fell into that cell
              //ex: if endpoint of mouse fall into half width of cell "10am" and the notice start in at cell "7am", the full width of notice item will be "10 - 7 + 1" * *width of each cell* - *total padding size*
              setInitialWidth(
                `${((ratio * widthOfACell - 30) / rowSize) * 100}%`
              );

              //only update if the width ratio is different with the start width ratio, i.e when dragging happen, users actually dragged the item and update a new width
              // ex: when user start dragging, then starting point of mouse fell into cell "8am", the endpoint when release mouse fell into cell "10am"=> trigger api update function
              if (startRatio !== ratio) {
                editNotice({ dueTimeValue: taskTimeRange.start + ratio - 1 });
              }
            } else if (actionType === "startDate") {
              let dragEndPoint: number = mouseUpEvent.pageX - boundLeft;
              let endpointCell: number = Math.floor(
                dragEndPoint / widthOfACell
              );

              let startHourValue: number = pageTimeRange.start + endpointCell;
              let initialWidth: number = startWidthOfNotice - 15 - mousePosX;
              let widthTotalRatio: number = Math.floor(
                (initialWidth + startPositionX - mouseUpEvent.pageX) /
                  widthOfACell
              );

              // let endHourValue: number = startHourValue + widthTotalRatio;
              // console.log("START HOUR:", startHourValue);
              // console.log("END HOUR VALUE:", endHourValue);
              setnoticeStartPos(
                `${((widthOfACell * endpointCell + 5) / rowSize) * 100}%`
              );

              // console.log("UPDATE notice start");
              setInitialWidth(
                `${
                  (((widthTotalRatio + 1) * widthOfACell - 30) / rowSize) * 100
                }%`
              );

              editNotice({ startTimeValue: startHourValue });
            } else {
              let dragEndPoint: number = mouseUpEvent.pageX - boundLeft;
              let endpointCell: number = Math.floor(
                dragEndPoint / widthOfACell
              );

              let startHourValue: number = pageTimeRange.start + endpointCell;
              let taskLength: number =
                Math.ceil((startWidthOfNotice + 10) / widthOfACell) - 1;

              let endHourValue: number = startHourValue + taskLength;

              setnoticeStartPos(
                `${((widthOfACell * endpointCell + 5) / rowSize) * 100}%`
              );

              // console.log("START HOUR:", startHourValue);
              // console.log("END HOUR:", endHourValue);
              // let dragDistanceInRatio: number = Math.ceil(
              //   dragDistance / (widthRatioOfCell * rowSize)
              // )
              // let editStartTimeRange: number = notice.startDate
              //   ? taskTimeRange.start + dragDistanceInRatio - 1
              //   : pageTimeRange.start + dragDistanceInRatio - 1

              // let editDueTimeRange: number = notice.dueDate
              //   ? taskTimeRange.end + dragDistanceInRatio - 1
              //   : pageTimeRange.end + dragDistanceInRatio - 1

              // setnoticeStartPos(
              //   `calc(${dragDistanceInRatio * widthRatioOfCell * 100}% + 5px)`
              // )

              // console.log("start TIME ZONE:", convertDateAndTime(startHourValue));
              // console.log("end TIME ZONE:", convertDateAndTime(endHourValue));
              editNotice({
                startTimeValue: startHourValue,
                dueTimeValue: endHourValue,
              });
            }
          }
        }

        //remove the value to finish animation
        setcurrentMousePosX(undefined);
      }

      document.body.addEventListener("mousemove", onMouseMove);
      document.body.addEventListener("mouseup", onMouseUp, { once: true });
    }
  };

  interface INoticeUpdateProps {
    dueTimeValue?: number;
    description?: string;
    startTimeValue?: number;
  }
  //handle edit api call
  const editNotice = async (body: INoticeUpdateProps) => {
    try {
      if (viewType === "daily") {
        const response = await NoticeAPI.update(notice.noticeId, user, {
          dueDate: body.dueTimeValue
            ? convertDateAndTime(body.dueTimeValue, "hour")
            : undefined,
          description: body.description ? body.description : undefined,
          startDate: body.startTimeValue
            ? convertDateAndTime(body.startTimeValue, "hour")
            : undefined,
        });
        if (response) {
          console.log("EDITED NOTICES:", response);
          let newNotice: Notice[] = [];
          notices.map((notices) => {
            if (notices.noticeId === response.noticeId)
              newNotice.push(response);
            else newNotice.push(notices);
          });
          setnotices([...newNotice]);
          setCurrentDraggedNotice({ ...response });
        }
      } else if (viewType === "weekly") {
        const response = await NoticeAPI.update(notice.noticeId, user, {
          dueDate: body.dueTimeValue
            ? convertDateAndTime(body.dueTimeValue, "date")
            : undefined,
          description: body.description ? body.description : undefined,
          startDate: body.startTimeValue
            ? convertDateAndTime(body.startTimeValue, "date")
            : undefined,
        });
        if (response) {
          console.log("EDITED NOTICES:", response);
          let newNotice: Notice[] = [];
          notices.map((notices) => {
            if (notices.noticeId === response.noticeId)
              newNotice.push(response);
            else newNotice.push(notices);
          });
          setnotices([...newNotice]);
          setCurrentDraggedNotice({ ...response });
        }
      }
    } catch (error) {}
  };

  const handleRightMouseClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (e.type === "click") {
    } else if (e.type === "contextmenu") {
      const bounds = e.currentTarget.getBoundingClientRect();
      setshowDeleteIcon({
        x: e.clientX - bounds.left,
        y: e.clientY - bounds.top,
      });
    }
  };

  const [showDeleteIcon, setshowDeleteIcon] = useState<
    { x: number; y: number } | undefined
  >(undefined);

  const ref = useDetectClickOutside({
    onTriggered: () => setshowDeleteIcon(undefined),
  });

  const handleDeleteNotice = async (event?: React.MouseEvent) => {
    if (event) event.preventDefault();
    try {
      const response = await NoticeAPI.remove(notice.noticeId);
      if (response) {
        setnotices([
          ...notices.filter((notices) => notices.noticeId !== notice.noticeId),
        ]);
        setCurrentUserRow(undefined);
      }
    } catch (error) {}
    setshowDeleteIcon(undefined);
  };

  const [onEditDescriptionMode, setonEditDescriptionMode] =
    useState<boolean>(false);
  const [newDescription, setNewDescription] = useState<string>("");

  const handleMouseMove = (event: React.MouseEvent) => {
    const bounds = event.currentTarget.getBoundingClientRect();

    setmousePos({
      x: event.pageX - bounds.left,
      y: event.pageY - bounds.top,
    });
  };

  //######################################################################################

  //########################HANDLE DAILY VIEW BEHAVIOURS FOR EDIT SINGLE MODE#############
  const singleNoticeRef = useRef<HTMLDivElement>(null);
  const [singleNoticeAxisPos, setsingleNoticeAxisPos] = useState<
    INoticeAxisPos | undefined
  >(undefined);
  const [editNoticeType, seteditNoticeType] = useState<TDragAction | undefined>(
    undefined
  );
  //get all the sizes of the display div to use for dragging
  useEffect(() => {
    if (singleNoticeRef)
      setsingleNoticeAxisPos({
        boundLeft: singleNoticeRef.current?.getBoundingClientRect()
          .left as number,
        boundRight: singleNoticeRef.current?.getBoundingClientRect()
          .right as number,
        width: singleNoticeRef.current?.getBoundingClientRect().width as number,
        height: singleNoticeRef.current?.getBoundingClientRect()
          .height as number,
        boundTop: singleNoticeRef.current?.getBoundingClientRect()
          .top as number,
      });
  }, [singleNoticeRef]);

  //state to get the mouse position in coordinated with the displayNotice at the time of triggering drag function
  const [mouseClickTriggeringPos, setmouseClickTriggeringPos] = useState<
    { x: number; y: number } | undefined
  >(undefined);

  const handleMouseDownOnDisplayNotice = (mouseDownEvent: React.MouseEvent) => {
    if (
      mouseDownEvent.nativeEvent.button === 0 &&
      action === "editSingle" &&
      !showDeleteIcon
    ) {
      if (singleNoticeAxisPos) {
        let timeValue: number =
          viewType === "daily"
            ? getTimeRangeNotice(notice, pageTimeRange).start
            : getDateRangeNotice(notice, pageTimeRange).start;
        if (setSelectedTimeValueCell) setSelectedTimeValueCell(timeValue);
        // setCurrentUserRow(assignedUser)
        //when the displayNotice is clicked => get the mouse position in coordinated with the displayNotice to initialising it on the maskedNotice to handle dragging
        setmouseClickTriggeringPos({
          x: mouseDownEvent.pageX - singleNoticeAxisPos.boundLeft,
          y: mouseDownEvent.pageY - singleNoticeAxisPos.boundTop,
        });

        const initialWidthOfMaskedDiv: string = formatInitialWidthOfNotice(
          notice,
          taskTimeRange,
          widthRatioOfCell,
          rowSize as number,
          "percent",
          pageTimeRange
        );

        const startPosOfMaskedDiv: string = checkStartPosOfNotice({
          taskTimeRange: taskTimeRange,
          pageTimeRange: pageTimeRange,
          valueReturnType: "percent",
          rowSize: rowSize as number,
          widthRatioOfCell: widthRatioOfCell,
        });

        //set the initial width for the masked div
        setintialWidthOfDraggedNotice(initialWidthOfMaskedDiv);

        //set start pos of the masked div
        setdraggedNoticeStartPos(startPosOfMaskedDiv);

        const editType: TDragAction = categorisingEditAction(
          {
            x: mouseDownEvent.pageX - singleNoticeAxisPos.boundLeft,
            y: mouseDownEvent.pageY - singleNoticeAxisPos.boundTop,
          },
          5,
          singleNoticeAxisPos.width
        ) as TDragAction;

        //set Edit notice type
        seteditNoticeType(editType);
        setCurrentDraggedNotice(notice);

        //$$$$$HANDLE DRAG$$$$$$$
        //initial width at the time mouse event triggered
        const startWidth = parseFloat(
          formatInitialWidthOfNotice(
            notice,
            taskTimeRange,
            widthRatioOfCell,
            rowSize as number,
            "pixel",
            pageTimeRange
          ).replace("px", "")
        );
        // console.log("START WITH:", startWidth);
        const startPositionX = mouseDownEvent.pageX;
        //console.log('WIDTH START:', startWidth)
        // console.log("START PS X: ", startPositionX);
        //get initial mouse pos when mouse event triggered
        const bounds = mouseDownEvent.currentTarget.getBoundingClientRect();
        const mousePosX = mouseDownEvent.clientX - bounds.left;
        //console.log('START X POSITION:', mousePosX)

        const startPosOfNotice = singleNoticeAxisPos.boundLeft;
        const endPosOfNotice = startPosOfNotice + startWidth;
        const startWidthOfNotice = startWidth;

        //update the current position to control animation
        setcurrentMousePosX(startPositionX);
        //update currentRow focused

        //when mouse action identified => categorise actions based on mouse position
        // when mouse pos >=0 and <=20 (the start edge pos of the displayed notice where the cursor will be displayed as 'w-resize') => edit start date action
        // and reversely, mouse pos >= (notice width -20) => edit due date action
        //and when mouse pos is in the pos between above conditions => moving edit action

        //functioon to handle mouse moving/ dragging
        function onMouseMove(mouseMoveEvent: any) {
          if (
            startWidth &&
            startPosOfNotice &&
            startWidthOfNotice &&
            singleNoticeAxisPos
          ) {
            setcurrentMousePosX(mouseMoveEvent.pageX);
            let startWidthWithoutPadding: number = startWidth - 25;
            if (editType === "dragEnd") {
              if (viewType === "daily") {
                if (mouseMoveEvent.pageX >= rowSize + boundLeft - 60) {
                  setintialWidthOfDraggedNotice(
                    (
                      rowSize -
                      (singleNoticeAxisPos.boundLeft - boundLeft) -
                      20
                    ).toString() + "px"
                  );
                  setshowSideTimeList(true);
                } else {
                  setintialWidthOfDraggedNotice(
                    (
                      singleNoticeAxisPos.width -
                      startPositionX +
                      mouseMoveEvent.pageX
                    ).toString() + "px"
                  );
                  setshowSideTimeList(false);
                }
              } else {
                setintialWidthOfDraggedNotice(
                  (
                    singleNoticeAxisPos.width -
                    startPositionX +
                    mouseMoveEvent.pageX
                  ).toString() + "px"
                );
              }
              setcurrentMousePosX(mouseMoveEvent.pageX);
            } else if (editType === "dragStart") {
              //action is edit start date => update absolute position "left" value and width of notice
              let initialWidth: number = startWidthOfNotice - 15 - mousePosX;
              // console.log("START NOTICE WIDTH:", startWidthOfNotice);
              // console.log("START POS X:", startPositionX);
              // console.log("MOUSE MOVE:", mouseMoveEvent.pageX);
              let updatedWidth =
                initialWidth + startPositionX - mouseMoveEvent.pageX;

              if (mouseMoveEvent.pageX < boundLeft)
                setdraggedNoticeStartPos(`5px`);
              else if (mouseMoveEvent > boundLeft + (rowSize as number))
                setdraggedNoticeStartPos(
                  `${boundLeft + (rowSize as number) - widthOfACell + 5}px`
                );
              else {
                setdraggedNoticeStartPos(
                  `${
                    startPosOfNotice -
                    startPositionX +
                    mouseMoveEvent.pageX -
                    boundLeft
                  }px`
                );

                if (updatedWidth <= widthOfACell - 30) {
                  setintialWidthOfDraggedNotice(`${widthOfACell - 30}px`);
                } else
                  setintialWidthOfDraggedNotice(
                    `${initialWidth + startPositionX - mouseMoveEvent.pageX}px`
                  );
              }
            } else {
              const distanceOfMouseToLeftEdge =
                startPositionX - startPosOfNotice;
              const distanceOfMouseToRightEdge =
                endPosOfNotice - startPositionX;
              if (mouseMoveEvent.pageX - distanceOfMouseToLeftEdge <= boundLeft)
                setdraggedNoticeStartPos(`5px`);
              else if (
                mouseMoveEvent.pageX + distanceOfMouseToRightEdge >=
                boundLeft + rowSize
              )
                setdraggedNoticeStartPos(`${rowSize - startWidth - 5}px`);
              else
                setdraggedNoticeStartPos(
                  `${
                    startPosOfNotice -
                    startPositionX +
                    mouseMoveEvent.pageX -
                    boundLeft
                  }px`
                );
            }
          } else document.body.removeEventListener("mousemove", onMouseMove);
        }
        function onMouseUp(mouseUpEvent: any) {
          document.body.removeEventListener("mousemove", onMouseMove);
          // uncomment the following line if not using `{ once: true }`
          // document.body.removeEventListener("mouseup", onMouseUp);

          if (
            startWidth &&
            rowSize &&
            startWidthOfNotice &&
            mouseUpEvent.pageX !== startPositionX &&
            singleNoticeAxisPos
          ) {
            const ratio = Math.ceil(
              (singleNoticeAxisPos.width -
                startPositionX +
                mouseUpEvent.pageX) /
                rowSize /
                widthRatioOfCell
            );
            const startRatio = Math.ceil(
              startWidth / rowSize / widthRatioOfCell
            );
            if (ratio !== startRatio) {
              if (editType === "dragEnd") {
                //check if the the mouse end point ended up in the sidetimeList
                if (!showSideTimeList) {
                  //this ratio is calculated when users release mouse
                  //ratio = (*Final width since mouse released* / *total width size of each row in px* / *width size of each cell* )

                  //the width of notice item will be automatically stretched to fill the rest width of the cell where the endpoint of mouse fell into that cell
                  //ex: if endpoint of mouse fall into half width of cell "10am" and the notice start in at cell "7am", the full width of notice item will be "10 - 7 + 1" * *width of each cell* - *total padding size*
                  setintialWidthOfDraggedNotice(
                    `${((ratio * widthOfACell - 30) / rowSize) * 100}%`
                  );

                  //only update if the width ratio is different with the start width ratio, i.e when dragging happen, users actually dragged the item and update a new width
                  // ex: when user start dragging, then starting point of mouse fell into cell "8am", the endpoint when release mouse fell into cell "10am"=> trigger api update function
                  if (startRatio !== ratio) {
                    editNotice({
                      dueTimeValue: taskTimeRange.start + ratio - 1,
                    });
                    console.log("END: ", taskTimeRange.start + ratio - 1);
                  }
                  //mouse endpoint on side time list => get the value selected  from the side time
                  else {
                    if (timeValueSelectedFromSideList)
                      editNotice({
                        dueTimeValue: timeValueSelectedFromSideList,
                      });
                  }
                }
              } else if (editType === "dragStart") {
                let dragEndPoint: number = mouseUpEvent.pageX - boundLeft;
                let endpointCell: number = Math.floor(
                  dragEndPoint / widthOfACell
                );

                let startHourValue: number = pageTimeRange.start + endpointCell;
                let initialWidth: number = startWidthOfNotice - 15 - mousePosX;
                let widthTotalRatio: number = Math.floor(
                  (initialWidth + startPositionX - mouseUpEvent.pageX) /
                    widthOfACell
                );

                // let endHourValue: number = startHourValue + widthTotalRatio;
                // console.log("START HOUR:", startHourValue);
                // console.log("END HOUR VALUE:", endHourValue);
                setdraggedNoticeStartPos(
                  `${((widthOfACell * endpointCell + 5) / rowSize) * 100}%`
                );

                // console.log("UPDATE notice start");
                setintialWidthOfDraggedNotice(
                  `${
                    (((widthTotalRatio + 1) * widthOfACell - 30) / rowSize) *
                    100
                  }%`
                );

                editNotice({ startTimeValue: startHourValue });
              } else {
                let dragEndPoint: number = mouseUpEvent.pageX - boundLeft;
                let endpointCell: number = Math.floor(
                  dragEndPoint / widthOfACell
                );

                let startHourValue: number = pageTimeRange.start + endpointCell;
                let taskLength: number =
                  Math.ceil((startWidthOfNotice + 10) / widthOfACell) - 1;

                let endHourValue: number = startHourValue + taskLength;

                setdraggedNoticeStartPos(
                  `${((widthOfACell * endpointCell + 5) / rowSize) * 100}%`
                );

                // console.log("START HOUR:", startHourValue);
                // console.log("END HOUR:", endHourValue);
                // let dragDistanceInRatio: number = Math.ceil(
                //   dragDistance / (widthRatioOfCell * rowSize)
                // )
                // let editStartTimeRange: number = notice.startDate
                //   ? taskTimeRange.start + dragDistanceInRatio - 1
                //   : pageTimeRange.start + dragDistanceInRatio - 1

                // let editDueTimeRange: number = notice.dueDate
                //   ? taskTimeRange.end + dragDistanceInRatio - 1
                //   : pageTimeRange.end + dragDistanceInRatio - 1

                // setnoticeStartPos(
                //   `calc(${dragDistanceInRatio * widthRatioOfCell * 100}% + 5px)`
                // )

                // console.log("start TIME ZONE:", convertDateAndTime(startHourValue));
                // console.log("end TIME ZONE:", convertDateAndTime(endHourValue));
                editNotice({
                  startTimeValue: startHourValue,
                  dueTimeValue: endHourValue,
                });

                document.body.removeEventListener("mouseup", onMouseUp);
              }
            }
          }

          //remove the value to finish animation
          setcurrentMousePosX(undefined);
          setmouseClickTriggeringPos(undefined);
          setintialWidthOfDraggedNotice(undefined);
          setdraggedNoticeStartPos(undefined);
          seteditNoticeType(undefined);
          setCurrentDraggedNotice(undefined);
          setCurrentUserRow(undefined);
          if (setSelectedTimeValueCell) setSelectedTimeValueCell(undefined);
          setshowSideTimeList(false);
          settimeValueSelectedFromSideList(undefined);
        }

        document.body.addEventListener("mousemove", onMouseMove);
        document.body.addEventListener("mouseup", onMouseUp, { once: true });
      }
    }
  };

  //$$$$$$$$$$$ Handle behaviours of masked notice used for dragging##########
  const [intialWidthOfDraggedNotice, setintialWidthOfDraggedNotice] = useState<
    string | undefined
  >(undefined);
  const [draggedNoticeStartPos, setdraggedNoticeStartPos] = useState<
    string | undefined
  >(undefined);

  const maskedNoticeRef = useRef<HTMLDivElement>(null);

  const handleMouseUpOnDraggedNotice = (
    mouseUpEvent: React.MouseEvent | MouseEvent
  ) => {
    setmouseClickTriggeringPos(undefined);
    setintialWidthOfDraggedNotice(undefined);
    setdraggedNoticeStartPos(undefined);
    seteditNoticeType(undefined);
    setCurrentDraggedNotice(undefined);
    setCurrentUserRow(undefined);
    if (setSelectedTimeValueCell) setSelectedTimeValueCell(undefined);
  };

  return action === "editGroup" ? (
    <AnimatePresence>
      <motion.div
        exit={{ scaleX: [1, 0] }}
        animate={{ scaleX: [0, 1] }}
        transition={{ scaleX: { duration: 0.3 } }}
        ref={noticeRef}
        className="notice-item-container"
        style={{
          background: convertColorValueByNoticeStatus(notice.status),
          width: initialWidth,
          // position: (notice.start && daily) ? 'absolute' : 'relative',
          // left: notice.start ? notice.start : 0,
          position: "absolute",
          left: noticeStartPos, //
          top: "7px",
          cursor:
            noticeRef.current &&
            mousePos.x <= noticeRef.current?.offsetWidth &&
            mousePos.x >= noticeRef.current?.offsetWidth - 10
              ? "e-resize"
              : mousePos.x >= 0 && mousePos.x <= 10
              ? "w-resize"
              : "all-scroll",
          marginTop: "5px !important",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          height: "calc(100% - 20px)",
          borderRadius: "28px",
        }}
        // onMouseEnter={handlePopoverOpen}
        // onMouseLeave={handlePopoverClose}
        onContextMenu={(event) => handleRightMouseClick(event)}
        onClick={(event) => handleRightMouseClick(event)}
        onMouseMove={(event) => handleMouseMove(event)}
        onMouseDown={(event) => handleDragAndExtendNotice(event)}
      >
        {!onEditDescriptionMode ? (
          <div
            style={{
              flex: 1,

              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            <span
              style={{
                textTransform: "capitalize",
              }}
              onClick={(event) => {
                if (event.detail === 2) setonEditDescriptionMode(true);
              }}
            >
              {titleCase((notice as Notice).description)}
            </span>
          </div>
        ) : (
          <TextField
            autoFocus={true}
            sx={{
              height: "100%",
              flex: 1,
              border: 0,
              "& .MuiInputBase-input": {
                flex: 1,
                height: "20px",
                padding: 0,
                fontFamily: "IBM Plex Sans",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "14px",
                color: "#FFFFFF",
              },
              "& .MuiOutlinedInput-input": {
                flex: 1,
                height: "20px",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                display: "none",
              },
            }}
            onKeyDown={(event) => {
              if (event.key === "Escape") setonEditDescriptionMode(false);
              else if (event.key === "Enter") {
                if (newDescription) {
                  editNotice({ description: newDescription });
                  setNewDescription("");
                }
                setonEditDescriptionMode(false);
              }
            }}
            value={newDescription}
            onChange={(event) => setNewDescription(event.currentTarget.value)}
          />
        )}

        {showDeleteIcon ? (
          <div
            ref={ref}
            style={{
              position: "absolute",
              left: showDeleteIcon.x,
              top: showDeleteIcon.y,
              width: "160px",
              height: "40px",
              backgroundColor: "white",
              boxShadow: "5px",
              borderRadius: 12,
              zIndex: 100000,
              border: "0.2px solid #7657cb",
            }}
          >
            <Button
              onClick={handleDeleteNotice}
              sx={{
                padding: 0,
                minWidth: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                letterSpacing: 1,
              }}
            >
              Delete Notice <TrashIcon />
            </Button>
          </div>
        ) : null}

        {viewType === "daily" && checkNoticeTimeRange(notice) === "all" ? (
          <Box
            sx={{
              height: "50%",
              fontFamily: "IBM Plex Mono",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "10px",
              lineHeight: "14px",
              color: "#7657cb",
              display: "flex",
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "4px",
              marginRight: "10px",
            }}
          >
            Due On {getDueDateOfNotice(notice)}
          </Box>
        ) : null}
        {/*Button Finish Edit */}
        <motion.div
          whileHover={{ scale: 1.1 }}
          onClick={(event) => {
            event.preventDefault();
            setCurrentUserRow(undefined);
            setCurrentDraggedNotice(undefined);
          }}
          style={{
            width: "30px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <CheckIcon />
        </motion.div>
        {/* <Button
          sx={{ color: 'white' }}
          onMouseDown={(event) => handleDragAndExtendNotice(event)}
        >
          Resize
        </Button> */}

        {/* {notice.progress && (
        <Box
          sx={{
            background: '#1DD1A1',
            width: notice.progress,
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 8,
          }}
        />
      )} */}

        {/*Control popover */}

        {/* <NoticePopover
        notice={notice}
        anchorEl={anchorEl}
        handlePopoverClose={handlePopoverClose}
      /> */}
      </motion.div>
    </AnimatePresence>
  ) : mouseClickTriggeringPos !== undefined &&
    intialWidthOfDraggedNotice !== undefined &&
    draggedNoticeStartPos !== undefined &&
    editNoticeType !== undefined ? (
    //dragged div
    <AnimatePresence>
      <motion.div
        exit={{ scaleX: [1, 0] }}
        animate={{ scaleX: [0, 1] }}
        transition={{ scaleX: { duration: 0.1 } }}
        className="notice-item-container"
        style={{
          background: convertColorValueByNoticeStatus(notice.status),
          width: intialWidthOfDraggedNotice,
          // position: (notice.start && daily) ? 'absolute' : 'relative',
          // left: notice.start ? notice.start : 0,
          position: "absolute",
          left: draggedNoticeStartPos, //
          top: "7px",
          borderRadius: "28px",
          cursor:
            editNoticeType === "dragStart" || editNoticeType === "dragEnd"
              ? "col-resize"
              : "move",
          marginTop: "5px !important",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
        onMouseUp={(event) => handleMouseUpOnDraggedNotice(event)}
        //  onMouseMove={(event)=>{handleOnDragMaskedNotice(event)}}
        onMouseDown={(event) => handleMouseDownOnDisplayNotice(event)}
      >
        <div
          style={{
            flex: 1,

            textOverflow: "ellipsis",
            overflow: "hidden",
            zIndex: 999,
          }}
        >
          <span
            style={{
              textTransform: "capitalize",
            }}
            onClick={(event) => {
              if (event.detail === 2) setonEditDescriptionMode(true);
            }}
          >
            {titleCase((notice as Notice).description)}
          </span>
        </div>

        {viewType === "daily" && checkNoticeTimeRange(notice) === "all" ? (
          <Box
            sx={{
              height: "50%",
              fontFamily: "IBM Plex Mono",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "10px",
              lineHeight: "14px",
              color: "#7657cb",
              display: "flex",
              backgroundColor: "white",
              borderRadius: "5px",
              padding: "4px",
              marginRight: "10px",
            }}
          >
            Due {getDueDateOfNotice(notice)}
          </Box>
        ) : null}
      </motion.div>
    </AnimatePresence>
  ) : (
    //   <TeamViewDraggedNotice editType={editNoticeType} draggedNotice={notice} intialWidthOfDraggedNotice={intialWidthOfDraggedNotice}
    //  noticeStartPos={draggedNoticeStartPos}/>
    //displayed div

    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      transition={{ opaicty: { duration: 0.1 } }}
      id="displayNotice"
      ref={singleNoticeRef}
      style={{
        position: "relative",
        height: "100%",
        width: "calc(100% - 10px)",
        display: "flex",
        borderRadius: "28px",
        backgroundColor: convertColorValueByNoticeStatus(notice.status),
        alignItems: "center",
        padding: "0 5px 0 5px",
        cursor: singleNoticeAxisPos
          ? (mousePos.x <= 5 && mousePos.x >= 0) ||
            (mousePos.x >= singleNoticeAxisPos.width - 5 &&
              mousePos.x <= singleNoticeAxisPos.width)
            ? "col-resize"
            : "move"
          : "pointer",
        zIndex:
          showDeleteIcon && notice.assignedUsers
            ? notice.assignedUsers.filter(
                (user) => user.userId === assignedUser.userId
              )[0]
              ? 100
              : -10
            : undefined,
      }}
      onMouseMove={(event) => handleMouseMove(event)}
      onMouseDown={(event) => {
        handleMouseDownOnDisplayNotice(event);
      }}
      onContextMenu={(event) => handleRightMouseClick(event)}
    >
      {!onEditDescriptionMode ? (
        <div
          style={{
            flex: 1,

            textOverflow: "ellipsis",
            overflow: "hidden",
            color: "#ffffff !important",
            zIndex: 1000000,
          }}
        >
          <span
            className="notice-list-description-text-style"
            style={{
              textTransform: "capitalize",
              color: "#ffffff",
            }}
            onClick={(event) => {
              event.stopPropagation();
              if (event.detail === 2) setonEditDescriptionMode(true);
            }}
          >
            {titleCase((notice as Notice).description)}
          </span>
        </div>
      ) : (
        <TextField
          autoFocus={true}
          sx={{
            height: "100%",
            flex: 1,

            display: "flex",
            justifyContent: "center",
            "& .MuiInputBase-input": {
              flex: 1,
              height: "20px",
              padding: 0,
              fontFamily: "IBM Plex Sans",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "14px",
              color: "#FFFFFF",
            },
            "& .MuiOutlinedInput-input": {
              flex: 1,
              height: "100%",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              display: "none",
            },
          }}
          onKeyDown={(event) => {
            if (event.key === "Escape") setonEditDescriptionMode(false);
            else if (event.key === "Enter") {
              if (newDescription) {
                editNotice({ description: newDescription });
                setNewDescription("");
              }
              setonEditDescriptionMode(false);
            }
          }}
          value={newDescription}
          onChange={(event) => setNewDescription(event.currentTarget.value)}
        />
      )}

      {showDeleteIcon ? (
        <div
          ref={ref}
          style={{
            position: "absolute",
            left: showDeleteIcon.x,
            top: showDeleteIcon.y,
            width: "160px",
            height: "40px",
            backgroundColor: "white",
            boxShadow: "5px",
            borderRadius: 12,
            zIndex: 100000,
            border: "0.2px solid #7657cb",
          }}
          onClick={(event) => handleDeleteNotice(event)}
        >
          <Button
            sx={{
              padding: 0,
              minWidth: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              letterSpacing: 1,
            }}
          >
            Delete Notice <TrashIcon />
          </Button>
        </div>
      ) : null}
    </motion.div>
  );
};

export default React.memo(TeamViewNoticeItem);

export const convertColorValueByNoticeStatus = (status: string) => {
  if (status.toLowerCase() === "w") return "#FFBA35";
  else if (status.toLowerCase() === "d") return "#855DF8";
  else if (status.toLowerCase() === "r") return "#1DD1A1";
  else if (status.toLowerCase() === "p") return "#FF0505";
  else return "#855DF8";
};
