import attachmentIcon from "../../../Assets/AttachmentQuotes.svg"
import attachmentZone from "../../../Assets/atttachment.svg"
import pdfIcon from "../../../Assets/Pdf.svg"
import addCommentIcon from "../../../Assets/CommentPlus.svg"
import deleteIcon from "../../../Assets/CrossBig.svg"
import { Quote } from "../../../Models/quote"
import React, { useState, DragEvent, ChangeEvent, useEffect } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Box,
  Button,
  LinearProgress,
  Stack,
} from "@mui/material"
import { FileData } from "../../../Models/storage"
import { DropzoneArea, DropzoneDialog } from "material-ui-dropzone"
import storageAPI from "../../../Services/DoxleAPI/storageAPI"
import CustomCheckbox from "../../../utilities/CheckBox/CheckBox"
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp"
import { authContextInterface, useAuth } from "../../Providers/AuthProvider"
import { Notice } from "../../../Models/notice"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import { ReactComponent as DocketIcon } from "../../../Assets/Docket.svg"
import Gallery from "../Gallery/Gallery"
import FileMenu from "./FileMenu"
interface props {
  quote?: Quote
  action: string
  editIconClicked?: boolean
  setEditIconClicked?: Function
  files?: FileData[]
  currentFolderId?: string | null
  currentFolderName?: string | null
  setFileList?: Function
  tickedFiles?: string[]
  setTickedFiles?: Function
  setTickedFolders?: Function
  notice?: Notice
  attachmentList?: FileData[]
  setAttachmentList?: Function
  handleRename?: Function
  handleDeleteFile?: Function
  costCodeId?: string | null
  setFileBlob?: Function
  fileBlobState?: File[]
  type?: "F" | "P"
  projectId?: string | null
}

const Files: React.FC<props> = ({
  quote,
  action,
  editIconClicked,
  setEditIconClicked,
  files,
  currentFolderId,
  currentFolderName,
  setFileList,
  tickedFiles,
  setTickedFiles,
  setTickedFolders,
  notice,
  attachmentList,
  setAttachmentList,
  handleRename,
  handleDeleteFile,
  costCodeId,
  setFileBlob,
  fileBlobState,
  type,
  projectId,
}) => {
  const fileList = [
    {
      fileId: "123",
      fileName: "Working Drawings.pdf",
      fileSize: "2mb",
    },
    {
      fileId: "124",
      fileName: "Purchase_order.pdf",
      fileSize: "0.7mb",
    },
  ]

  const [currentlyDragging, setCurrentlyDragging] = useState<boolean>(false)
  const [currentlyUploading, setCurrentlyUploading] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const [errors, setErrors] = useState<Array<any>>([])
  const [selectedFile, setSelectedFile] = useState<string | undefined>(
    undefined
  )
  const authContext = useAuth() as authContextInterface
  const { setLoggedIn } = authContext
  const [openDropzoneDialog, setOpenDropzoneDialog] = React.useState(false)
  const [uploadingFiles, setUploadingFiles] = React.useState<File[]>([])

  const currentFolderFiles = files
    ? files.filter((file: FileData) => file.folder === currentFolderId)
    : []

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setCurrentlyDragging(true)
  }

  const handleDragExit = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setCurrentlyDragging(false)
  }

  const formatDate = (date: string) => {
    return (
      date.substring(8, 10) +
      "." +
      date.substring(5, 7) +
      "." +
      date.substring(2, 4)
    )
  }

  const formatSize = (size: number) => {
    const len = size.toString().length
    if (len < 7) {
      return (Math.round((size / 1024) * 100) / 100).toString() + " kB"
    } else {
      return (Math.round((size / 1024 / 1024) * 100) / 100).toString() + " MB"
    }
  }

  const handleRenameFile = (fileId: string) => {
    setTickedFiles && setTickedFiles([fileId])
    setTickedFolders && setTickedFolders([])
    handleRename && handleRename()
  }

  const handleDelete = (fileId: string) => {
    handleDeleteFile && handleDeleteFile([fileId])
  }

  const getType = (type: string) => {
    return type.substring(type.indexOf("/") + 1)
  }

  const getChecked = (fileId: string) => {
    if (tickedFiles) {
      return tickedFiles.indexOf(fileId) !== -1
    }
  }

  const handleCheckBox = (e: any, fileId: string) => {
    if (
      setTickedFolders &&
      setTickedFiles &&
      tickedFiles &&
      setEditIconClicked
    ) {
      setSelectedFile(fileId)
      setEditIconClicked(false)
      setTickedFolders([])
      if (e.target && typeof e.target.checked !== "undefined") {
        let files = tickedFiles
        const i = files.indexOf(fileId)
        if (e.target.checked) {
          if (i === -1) {
            files.push(fileId)
          }
        } else {
          if (i !== -1) {
            files.splice(i, 1)
          }
        }
        setTickedFiles([...files])
      }
    }
  }

  const handleFileDrop = async (newFiles: Blob[]) => {
    //update array of file blob sent to backend
    if (setFileBlob && fileBlobState) {
      console.log("fileBLob set")
      setFileBlob([...fileBlobState, ...newFiles])
    }

    try {
      console.log("try")
      if (files && setFileList && currentFolderId && currentFolderName) {
        console.log("files && setFileList && currentFolderId && currentFolderName")
        if (newFiles.length === 0) {
          console.log("no new files")
          return
        }
        setCurrentlyUploading(true)
        setCurrentlyDragging(false)
        const response = await storageAPI.uploadFiles(
          newFiles,
          currentFolderId,
          setProgress
        )
        console.log(response ? response : "NO PROJECT OR COSTCODE ID")
        setCurrentlyUploading(false)
        setProgress(0)
        if (response?.errors) {
          console.log(response?.errors)
          setErrors(response?.errors)
        }
        if (response?.files && (costCodeId || projectId)) {
          const newFileList = (await storageAPI.getFileList(
            projectId ? projectId : null,
            costCodeId ? costCodeId : null,
            null
          )) as FileData[]
          if (newFileList.length > 0) {
            setFileList([...newFileList])
          } else {
            setFileList([])
          }
        }
      }

      if (notice && setAttachmentList && attachmentList) {
        console.log("notice && setAttachmentList && attachmentList")
        setCurrentlyUploading(true)
        setCurrentlyDragging(false)
        const response = await storageAPI.uploadNoticeFiles(
          newFiles,
          setProgress,
          notice.noticeId
        )
        console.log(response)
        setCurrentlyUploading(false)
        setProgress(0)
        if (response.errors) {
          console.log(response.errors)
          setErrors(response.errors)
        }
        if (response.files) {
          const newFileList = [...attachmentList, ...response.files]
          setAttachmentList(newFileList)
        }
      }
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err)
    }
  }

  const handleFileClicked = (event: any, url: string) => {
    if (typeof event.target.checked !== "undefined") {
      return
    }
    window.open(url, "_blank")
  }

  const handleFileDelete = async (fileId: string, fileSize?: number) => {
    //update array of file blob sent to backend
    if (fileSize && setFileBlob && fileBlobState) {
      const newFileBlobState: Blob[] = []
      fileBlobState.map((file) => {
        if (file.size !== fileSize) newFileBlobState.push(file)
      })
      setFileBlob(newFileBlobState)
    }

    try {
      if (notice && setAttachmentList) {
        await storageAPI.deleteFile(fileId)
        const newFileList = (await storageAPI.getFileList(
          null,
          null,
          notice.noticeId
        )) as FileData[]
        if (newFileList.length > 0) {
          setAttachmentList([...newFileList])
        } else {
          setAttachmentList([])
        }
      }
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err)
    }
  }

  const calculateFileSize = (size: any) => {
    let nSize = parseInt(size)

    if (nSize >= 1000) {
      nSize = nSize / 1000
      if (nSize < 1000) {
        return nSize < 100 ? nSize.toFixed(1) + " KB" : nSize.toFixed(0) + " KB"
      }
    }

    if (nSize >= 1000) {
      nSize = nSize / 1000
      return nSize < 100 ? nSize.toFixed(1) + " MB" : nSize.toFixed(0) + " MB"
    }

    return parseInt(size).toFixed(1) + " B"
  }

  const getDialogStatus = (
    file: FileData,
    selectedFile: string | undefined,
    editIconClicked: boolean
  ) => {
    console.log(file.fileId === selectedFile && editIconClicked === true)

    if (file.fileId === selectedFile && editIconClicked === true) {
      return true
    } else {
      return false
    }
  }

  let progressPercent = progress + "%"

  return (
    <React.Fragment>
      {action === "quote" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "27px",
          }}
        >
          {quote ? (
            fileList.map((file) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "7px",
                }}
              >
                <img src={pdfIcon} alt="pdf icon" />
                <p
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "12px",
                    color: "#000000",
                  }}
                >
                  {file.fileName}{" "}
                  <span
                    style={{
                      color: "#96A2BE",
                    }}
                  >
                    {file.fileSize}
                  </span>
                </p>
              </div>
            ))
          ) : (
            <img
              src={attachmentIcon}
              alt="attachment icon"
              style={{ width: "13px" }}
            />
          )}
        </div>
      ) : action === "storage" && editIconClicked !== undefined && files ? (
        <>
          <Stack direction="row" justifyContent="space-between" height="100%">
            <Stack
              direction="column"
              width={
                // (type === "P" || type === "F") && currentFolderFiles.length > 0
                //   ? "50%"
                //   :
                "100%"
              }
            >
              <Table
                sx={{
                  width: "100%",
                  height: "auto",
                  ".MuiTableCell-root": {
                    fontFamily: "Roboto Mono",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "11px",
                    lineHeight: "16px",
                    color: "#000000",
                  },
                }}
                aria-label="simple table"
                onDragEnterCapture={handleDragEnter}
                onDragLeaveCapture={handleDragExit}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      ".MuiTableCell-head": {
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "10px",
                        lineHeight: "12px",
                        color: "#9C9EAD",
                        textAlign: "center",
                        paddingBottom: "8px",
                        border: "none",
                      },
                    }}
                  >
                    {currentlyUploading || currentlyDragging ? (
                      <TableCell>Upload files</TableCell>
                    ) : (
                      <>
                        <TableCell
                          style={{ textAlign: "left", paddingLeft: "75px" }}
                        >
                          File Name
                        </TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Created By</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    backgroundColor: "none",
                    ".MuiTableCell-root": {
                      border: "1px solid #E4E6EF",
                    },
                    "td:last-child.MuiTableCell-root": {
                      borderRight: "none",
                    },
                    "tr:last-child td.MuiTableCell-root": {
                      borderBottom: "none",
                    },
                    ".MuiTableRow-root .MuiTableCell-root": {
                      border: "1px solid #E4E6EF",
                    },
                  }}
                >
                  <>
                    {files.map(
                      (file) =>
                        file.folder === currentFolderId && (
                          <TableRow
                            key={"File-" + type + "-" + file.fileId}
                            className={file.folder}
                            sx={{
                              height: "30px",
                              "& .MuiTableCell-root": {
                                borderBottom: "1px solid white",
                              },
                            }}
                          >
                            <TableCell sx={{ padding: "3px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  height: "100%",
                                }}
                              >
                                {getType(file.type) === "pdf" ? (
                                  <img
                                    src={pdfIcon}
                                    alt="pdf"
                                    style={{
                                      position: "relative",
                                      // top: '1px',
                                      transform: "scale(0.9)",
                                      marginLeft: "5px",
                                      marginRight: "10px",
                                    }}
                                  />
                                ) : (
                                  <div
                                    className="file-icon file-icon-s"
                                    data-type={getType(file.type)}
                                    style={{
                                      position: "relative",
                                      // top: '8px',
                                      transform: "scale(0.8)",
                                      marginLeft: "5px",
                                      marginRight: "10px",
                                    }}
                                  />
                                )}
                                <div
                                  onClick={(event: any) =>
                                    handleFileClicked(event, file.url)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {file.name}
                                </div>
                                <FileMenu
                                  handleRename={() =>
                                    handleRenameFile(file.fileId)
                                  }
                                  handleDelete={() => handleDelete(file.fileId)}
                                />
                              </div>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {file.size
                                ? formatSize(file.size as number)
                                : "0.00 kB"}{" "}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {file.created
                                ? formatDate(file.created)
                                : "00.00.00"}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {typeof file?.owner !== "string" &&
                              file?.owner?.firstName &&
                              file?.owner?.lastName
                                ? `${file?.owner?.firstName} ${file?.owner?.lastName}`
                                : ""}
                            </TableCell>
                            {/* <TableCell style={{ textAlign: 'center' }}>
                            {file.history ? file.history : 1}
                          </TableCell> */}
                          </TableRow>
                        )
                    )}
                  </>
                  {/* )} */}
                </TableBody>
              </Table>

              <Box
                className={"storage " + (type === "P" ? "photos" : "")}
                sx={{
                  background: "white",
                  paddingX: "24px",
                  paddingY: "12px",
                  // marginY: '50px',
                  borderTop: "1px solid #E4E6EF",
                  borderLeft: "1px solid #E4E6EF",
                  borderBottom: "1px solid #E4E6EF",
                }}
              >
                <DropzoneArea
                  onDrop={(files: Blob[]) => handleFileDrop(files)}
                  filesLimit={10}
                  maxFileSize={15000000}
                  // acceptedFiles={
                  //   type === 'F'
                  //     ? undefined
                  //     : ['image/jpeg', 'image/png', 'image/gif']
                  // }
                  dropzoneText={"or drag and drop"}
                  showPreviews={false}
                  showPreviewsInDropzone={false}
                  showFileNamesInPreview={false}
                  showAlerts={false}
                />
                <DropzoneDialog
                  cancelButtonText={"cancel"}
                  submitButtonText={"submit"}
                  maxFileSize={15000000}
                  open={openDropzoneDialog}
                  onClose={() => setOpenDropzoneDialog(false)}
                  onSave={(files: Blob[]) => {
                    console.log("Files:", files)
                    handleFileDrop(files)
                    setOpenDropzoneDialog(false)
                  }}
                  showPreviews={true}
                  showFileNamesInPreview={true}
                  showAlerts={false}
                />

                {currentlyUploading && (
                  <div
                    className="progress"
                    style={{ width: progressPercent, paddingLeft: "20px" }}
                  >
                    <Typography fontSize="9px" fontWeight="600">
                      Uploading ({progress} %)
                    </Typography>
                  </div>
                )}
              </Box>
              <Box
                sx={{
                  height: "100%",
                  borderLeft: "1px solid #E4E6EF",
                }}
              />
            </Stack>

            {type === "P" && currentFolderFiles.length > 0 && (
              <Box
                sx={{ p: "5px", background: "#F5F6F7", alignItems: "center" }}
              >
                <Gallery files={currentFolderFiles} />
              </Box>
            )}
          </Stack>
        </>
      ) : action === "notice" ? (
        <Grid
          container
          spacing={3}
          onDragEnterCapture={handleDragEnter}
          onDragLeaveCapture={handleDragExit}
        >
          <Grid item xs={7} sx={{ paddingTop: "0px" }}>
            <DropzoneArea
              onDrop={(files: Blob[]) => handleFileDrop(files)}
              // filesLimit={10}
              maxFileSize={15000000}
              dropzoneText="Drag and Drop File(s)"
              showPreviews={false}
              showPreviewsInDropzone={false}
              showFileNamesInPreview={false}
              showAlerts={false}
            />
          </Grid>
          <Grid item xs={5} sx={{ display: "flex", justifyContent: "center" }}>
            {currentlyUploading ? (
              <div
                style={{
                  paddingTop: "40px",
                  paddingBottom: "40px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div className="progressBar">
                  <div
                    className="progress"
                    style={{ width: progressPercent }}
                  ></div>
                  <span>Uploading ({progress} %)</span>
                </div>
              </div>
            ) : (
              <div
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingRight: 20,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  width: "100%"
                }}
              >
                {attachmentList?.map((att, i) => (
                  <div className="attactmentList" key={"#" + i + att.name}>
                    {getType(att.type) === "pdf" ? (
                      <img
                        src={pdfIcon}
                        onClick={()=>window.open(att.url)}
                        alt="pdf"
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          top: "1px",
                          transform: "scale(0.9)",
                          marginRight: "7px",
                          marginLeft: "4px",
                        }}
                      />
                    ) : (
                      <div
                        className="file-icon file-icon-s"
                        data-type={getType(att.type)}
                        style={{
                          position: "relative",
                          top: "1px",
                          transform: "scale(0.5)",
                          marginRight: "5px",
                        }}
                      />
                    )}

                    <a
                      style={{
                        width: "Calc(100% - 75px)",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      href={att.url}
                      target="_blank"
                    >
                      {att.name}
                    </a>
                    <a
                      style={{
                        position: "absolute",
                        right: "75px",
                        cursor: "pointer",
                      }}
                      href={att.url}
                      target="_blank"
                    >
                      {calculateFileSize(att.size)}
                    </a>

                    <img
                      src={deleteIcon}
                      alt="detele"
                      onClick={() => handleFileDelete(att.fileId)}
                      style={{
                        position: "absolute",
                        right: "30px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </Grid>
        </Grid>
      ) : action === "dockets" ? (
        <Box
          id="Dockets-dropzone"
          position="relative"
          height="100%"
          onDragEnterCapture={handleDragEnter}
          onDragLeaveCapture={handleDragExit}
        >
          <Box height="100%">
            <DropzoneArea
              onDrop={(files: File[]) => {
                setUploadingFiles(files)
                const newFiles: Blob[] = files
                handleFileDrop(newFiles)
              }}
              // filesLimit={10}
              maxFileSize={15000000}
              dropzoneText="Drag & drop your dockets for extraction"
              showPreviews={false}
              showPreviewsInDropzone={false}
              showFileNamesInPreview={false}
              acceptedFiles={["application/pdf"]}
              showAlerts={false}
            />
          </Box>

          <Box position="absolute" top="0" width="100%">
            <div
              style={{
                paddingTop: 0,
                paddingBottom: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {currentlyUploading &&
                uploadingFiles.map((fileUp, i) => (
                  <>
                    <div className="attactmentList" key={"#" + i + fileUp.name}>
                      <DocketIcon />
                      <a
                        style={{
                          width: "85%",
                          marginLeft: "10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {fileUp.name}
                      </a>
                      <img
                        src={deleteIcon}
                        alt="detele"
                        onClick={() => {}}
                        style={{
                          position: "absolute",
                          right: "30px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <LinearProgress variant="determinate" value={progress} />
                  </>
                ))}

              {fileBlobState
                ?.sort((a, b) => b.name.localeCompare(a.name))
                .map((att, i) => (
                  <>
                    <div className="attactmentList" key={"#" + i + att.name}>
                      <DocketIcon />
                      <a
                        style={{
                          width: "85%",
                          marginLeft: "10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        // href={att.url}
                        target="_blank"
                      >
                        {att.name}
                      </a>
                      <img
                        src={deleteIcon}
                        alt="detele"
                        onClick={() =>
                          handleFileDelete("file" + i, att.size as number)
                        }
                        style={{
                          position: "absolute",
                          right: "30px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </>
                ))}
            </div>
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </React.Fragment>
  )
}
export default Files
