import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Project } from "../../../Models/project";
import { Company } from "../../../Models/company";
import { Pricelist } from "../../../Models/pricelist";
import PricelistForm from "./PricelistForm";

interface props {
    selectedCompany: Company | undefined;
    selectedProject: Project | null;
    selectedPricelist?: Pricelist;
    openModal: boolean;
    closeModal: Function;
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PricelistDialog: React.FC<props> = ({
    selectedCompany,
    selectedProject,
    selectedPricelist,
    openModal,
    closeModal,
}) => {
    return (
        <Dialog
            fullScreen
            open={openModal}
            onClose={() => closeModal}
            TransitionComponent={Transition}
            PaperProps={{
                style: { backgroundColor: "rgba(245,246,247,1)" },
            }}
        >
            <AppBar
                sx={{
                    position: "relative",
                    backgroundColor: "white",
                    color: "black",
                }}
                elevation={0}
            >
                <Toolbar>
                    <Typography
                        sx={{
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            flex: 1,
                        }}
                        variant="h6"
                        component="div"
                    >
                        {selectedCompany?.name} / {selectedProject?.siteAddress}{" "}
                        /{" "}
                        {selectedPricelist ? "Edit Pricelist" : "Add Pricelist"}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => closeModal()}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <PricelistForm
                companyId={selectedCompany?.companyId as string}
                closeModal={closeModal}
                selectedPricelist={selectedPricelist}
            />
        </Dialog>
    );
};

export default PricelistDialog;
