import { Box, Button, styled } from '@mui/material'
import React from 'react'
import { ReactComponent as AttachmentComment } from '../../../../Assets/MailroomAttachmentComment.svg'

type Props = {}

const CommentContainer = (props: Props) => {
  return (
    <Box className='commentViewRootContainer'>
      <Box className='userTitleContainer'>Selvin Kamal</Box>
      <Box className='commentContentContainer'>
        {/*Date Container */}
        <Box className='commentDateTitleContainer'>
          Thursday 15/12/22 1:20pm
        </Box>

        {/*Date Container */}
        <Box className='commentDescriptionContainer'>
          asadsadadasdasdsadasdas asdsadsadadadad asadsadadasdasdsadasdas
          asdsadsadadadad asadsadadasdasdsadasdas asdsadsadadadad
          sadsadadasdasdsadasdas asdsadsadadadad sadsadadasdasdsadasdas
          asdsadsadadadad sadsadadasdasdsadasdas asdsadsadadadad
          sadsadadasdasdsadasdas asdsadsadadadad sadsadadasdasdsadasdas
          asdsadsadadadad
        </Box>

        {/*Date Container */}
        <Box className='commentAttachmentContainer'>
          <AttachmentContainer>
            <AttachmentComment />
            Photo1.jpeg
          </AttachmentContainer>
          <AttachmentContainer>
            <AttachmentComment />
            Photo1.jpeg
          </AttachmentContainer>
        </Box>
      </Box>
    </Box>
  )
}

export default CommentContainer
const AttachmentContainer = styled(Button)({
  fontFamily: 'IBM Plex Mono',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '13px',
  lineHeight: '17px',
  color: '#5628D8',
  textTransform: 'capitalize',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: 1,
  maxHeight: '20px !important',
  marginBottom: '2px',
  cursor: 'pointer',
  borderRadius: '8px',
  padding: '0 !important',

  '&:hover': {
    backgroundColor: '#eeeeee',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#BEABF5',
  },
})
