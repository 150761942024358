import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  tableHeadingStyle: {
    fontFamily: "Inter !important",
    fontStyle: "normal !important",
    fontWeight: 500 + "!important",
    fontSize: "10px !important",
    lineHeight: "12px !important",
    color: "#96A2BE !important",
    background: "none !important",
    borderBottom: "0px !important",
    paddingRight: 0,
    paddingLeft: 0
  },
  tableHeadingStyle1: {
    fontFamily: "Roboto Mono !important",
    fontStyle: "normal !important",
    fontWeight: 400 + "!important",
    fontSize: "9px !important",
    lineHeight: "12px !important",
    color: "#000000 !important",
    paddingBottom: 0,
    marginBottom: 0,
    border: "none",
    background: "#F5F6F7"
  },
  tableHeadingStyle2: {
    fontFamily: "Inter !important",
    fontStyle: "normal !important",
    fontWeight: 400 + "!important",
    fontSize: "12px !important",
    lineHeight: "15px !important",
    color: "#000000 !important",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    "&nth-last-child(1)": {
      borderRight: "0 !important",
    },
  },
  tableBodyStyle: {
    height: "10px",
    fontStyle: "normal",
    fontFamily: "Inter",
    fontWeight: "normal",
    fontSize: "11px",
    lineHeight: "15px",
    color: "#0D0D0D",
    padding: "0px",
    textAlign: "center",
    position: "relative",
    width: "88px",
  },
  signOffStyle: {
    height: "15px",
    fontStyle: "normal",
    fontFamily: "Inter",
    fontWeight: "normal",
    fontSize: "11px",
    lineHeight: "15px",
    color: "red",
    padding: "0px",
    textAlign: "center",
    position: "relative",
    width: "88px",
  },
  emailAlert: {
    fontFamily: "Inter !important",
    fontStyle: "normal !important",
    fontWeight: 400 + "!important",
    fontSize: "10px !important",
    lineHeight: "12px !important",
    color: "#96A2BE !important",
  },
  topLessHeightBtn: {
    "& button": {
      display: "block",
      width: "76px",
      height: "13px",
      fontSize: "10px",
      margin: "0 0 0 auto",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "12px",
      color: "#FFFFFF",
      borderRadius: 0,
      marginBottom: "10px",
      padding: "1px 1px 1px 1px",
    },
  },
  tableDataStyle: {
    "& > tr > td:nth-child(2)":{
      textAlign: "left !important",
    },
    "& > tr:nth-child(odd) > td":{
      textAlign: "center",
    },
    "& td:nth-last-child(2)": {
      "& div > div": {
        // padding: "0px 2px !important",
        // paddingTop: "3px !important",
      },
    },
    "& > tr": {
      borderBottom: "2px solid white",
      "& > td:first-of-type ":
      { borderRadius: "4px 0 0 4px" },
      "& > td:last-child ":
      { borderRadius: "0 4px 4px 0" },
    },
    "& tr": {
      "& td": {
        // background: "#F5F6F7",
        // borderRadius: "16px",
        fontFamily: "Roboto Mono",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "10px",
        lineHeight: "13px",
        color: "#000000",
        "&.paid": {
          color: "#0044CC",
        },
        "&.balance": {
          color: "#C50A15",
        },
        "&.statusCls > span": {
          height: "13px",
          background: "#30B4FF",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "12px",
          color: "#FFFFFF",
          borderRadius: 0,
          padding: "3px 1px 3px 1px",
          display: "block",
          textAlign: "center",
          width: "76px",
          fontSize: "10px",
        },
        "&.XERO > span": {
          background: "#30B4FF !important",
        },
        "&.REVIEW > span": {
          background: "#FFC804 !important",
        },
        "&.DRAFT > span": {
          background: "#96A2BE !important",
        },
      },
    },
  },
  innerTblInpur: {
    "& td": {
      borderBottom: 0,
      paddingRight: "22px",
    },
    "& td:nth-child(1)": {
    //   paddingLeft: 0,
        paddingLeft: "20px",
    },
    "& td:nth-last-child(1)": {
      width: "50px",
    },
    "& input": {
      background: "#F8F8F8",
      borderRadius: "4px",
      height: "16px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      color: "#000000",
    },
    "& label": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px !important",
      lineHeight: "15px",
      color: "#000000",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#FCFDFF",
      },
      "&:hover fieldset": {
        borderColor: "#FCFDFF",
        borderWidth: "0.15rem",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FCFDFF",
      },
    },
    "& label.Mui-focused": {
      color: "#646C74",
    },
  },
  headerTblInpur: {
    "& td": {
      background: "#eff1f5",
      borderBottom: 0,
      paddingRight: "22px",
      paddingTop: '10px',
      paddingBottom: '10px'
    },
    "& td:nth-child(1)": {
    //   paddingLeft: 0,
        paddingLeft: "20px",
    },
    "& td:nth-last-child(1)": {
      // width: "10px",
    },
    "& input": {
      background: "#FFFFFF",
      borderRadius: "4px",
      height: "16px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      color: "#000000",
    },
    "& .Mui-disabled": {
      WebkitTextFillColor: "#00000099",
    },
    "& label": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px !important",
      lineHeight: "15px",
      color: "#000000",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#FCFDFF",
      },
      "& fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "#FCFDFF",
        borderWidth: "0.15rem",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FCFDFF",
      },
    },
    "& label.Mui-focused": {
      color: "#646C74",
    },
  },
  yellowFlagged: {
    color: "#FEB701 !important",
    "& input": {
      color: "#FEB701 !important",
    },
    "& .MuiSelect-root": {
      color: "#FEB701 !important",
    },
    "& .MuiInput-input": {
      color: "#FEB701 !important",
    }
  },
  flagTrueIconShow: {
    width: "6px !important",
    height: "6px !important",
    top: "-7px !important",
    right: "-2px !important",
    padding: "2px !important",
  },
  inputDescription: {
    fontFamily: "Roboto Mono !important",
    fontStyle: "normal !important",
    fontWeight: 400 + "!important",
    fontSize: "11px !important",
    lineHeight: "12px !important",
    color: "#000000 !important",
    outline: 'none !important',
  },
  tableTextfield: {
    "& td": {
      "& > span": {
        color: "#FEB701 !important",
      }
    },
    "& input": {
      background: "#FCFDFF",
      borderRadius: "4px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      color: "#000000",
      "&.focus": {
        backgroundColor: "#FCFDFF",
      },
      "&[type=number]": {
        width: '15px',
      },
      "&[name=itemCost]": {
        width: '35px'
      }
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#FCFDFF",
      },
      "&:hover fieldset": {
        borderColor: "#FCFDFF",
        borderWidth: "0.15rem",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FCFDFF",
      },
    },
    "& .MuiSelect-root": {
      background: "#FCFDFF",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "10px",
      color: "#000000",
      "& option": {
        backgroundColor: "#FCFDFF",
      },
    },
  },
  select: {
    background: "#FCFDFF !important",
    fontFamily: "Inter !important",
    fontStyle: "normal !important",
    fontWeight: "400px !important",
    fontSize: "12px !important",
    padding: "0px !important",
    width: "auto",
    height: "15px",
    "& .MuiSelect-select":{
        paddingRight: "0px !important"
    }
  },
  unitItem: {
    background: "#FCFDFF !important",
    fontFamily: "Inter !important",
    fontStyle: "normal !important",
    fontWeight: "400px !important",
    fontSize: "12px !important",
  },
  tableBordered: {
    "& .tableHeadStyle th": {
      textAlign: "center !important",
      "&:nth-child(2)": {
        textAlign: "left !important",
      },
    },
    "& .tableHeadStyle td": {
      textAlign: "center !important",
      "&:nth-child(2)": {
        textAlign: "left !important",
      },
    },
    "& td": {
      // borderBottom: 0,
      // border: "0.5px solid #96A2BE",
      // borderLeft: 0,
      borderCollapse: "collapse",
      padding: "5px 10px",
    },
    "& td:nth-child(1)": {
      // borderLeft: 0,
      // borderRight: "0 !important",
      width: "20px",
    },
    // '& td:nth-child(2)':{
    //     borderLeft: 0,
    //         },
    "& td:nth-last-child(1)": {
      // borderRight: 0,
    },
  },
  notesText: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "12px",
    color: "#89819E",
  },
  notesAddPadding: {
    paddingLeft: "20px",
  },
  textAreaBox: {
    "& textarea": {
      overflow: "hidden !important",
      // width: "min(80%, 500px) !important",
      width: "90% !important",
      // height: "90px !important",
      background: "#FFFFFF !important",
      borderRadius: "6px !important",
      marginTop: "5px !important",
      padding: "8.5px 14px !important",
      color: "rgba(205, 50, 106, 0.75) !important",
      fontSize: "12px !important",
      fontStyle: "normal !important",
      fontFamily: "Roboto !important",
      border: "none !important",
      outline: "none !important",
      fontWeight: 400 + "!important",
    },
  },
  footerTaxSection: {
    textAlign: "center",
    "& div": {
      fontFamily: "Roboto Mono",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
      padding: "4px 0 4px 0",
      textAlign: "right",
    },
  },
  belowButtonClass: {
    "& button": {
      // marginLeft: "10px",
      maxHeight: "32px"
    },
  },
  viewHistoryContent: {
    "& Span": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "10px",
      lineHeight: "12px",
      background: "none",
      borderBottom: 0,
    },
    "& p": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "8px",
      lineHeight: "20px",
      color: "#000000",
      background: "#F8F8F8",
      width: "110px",
    },
  },
  endTDbtnClass: {
    display: "block !important",
    height: "33px !important",
    padding: "18px 10px 11px 10px !important",
    "& button svg path": {
      fill: "#0044CC !important",
    },
    minWidth: "50px"
  },
  notes: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "11px",
    color: "#0044CC",
  },
  notesfield: {
    color: "rgba(205, 50, 106, 0.75) !important",
    padding: "9px 14px 9px 14px",
    width: "calc(100% - 28px)",
    background: "white",
    borderRadius: "6px",
    borderColor: "#F8F8F8",
    '& .focus': {
      outline: 'none',
    }
  },
  termsBox: {
    width: "min(100%, 190px) !important",
    background: "#F8F8F8",
    borderRadius: "6px",
    border: 'none',
    '& .focus': {
      outline: 'none',
    }
  },
  damages: {
    width: "min(50%, 95px) !important",
    background: "#F8F8F8",
    borderRadius: "6px 0px 0px 6px !important",
    border: 'none',
    '& .focus': {
      outline: 'none',
    }
  },
  damagesPeriod: {
    width: "min(50%, 95px) !important",
    background: "#F8F8F8",
    borderRadius: "0px 6px 6px 0px",
    height: "33px !important",
    border: 'none',
    '& .focus': {
      outline: 'none',
    }
  },
  menuitem: {
    height: "13px",
    background: "#30B4FF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "12px",
    color: "#FFFFFF",
    borderRadius: 0,
    padding: "3px 1px 3px 1px",
    display: "block",
    textAlign: "center",
    width: "76px",
    fontSize: "10px",
  },
  deleteFlagIcon: {
    width: "24px !important",
    height: "24px !important",
    margin: "8px 3px !important",
  },
  rmvStickHeader: {
    marginTop: "3px",
    marginBottom: "30px",
    "& thead": {
      position: "static !important",
    },
    "& .billTableHeadStyle": {
      position: "static !important",
      "& > th":{
        textAlign:"center !important"
      }
    },
  },
  rmvOverFlwProp: {
    "& > div:nth-child(1)": {
      overflowX: "unset !important",
    },
  },
  dataCenterFields: {
    "& td": {
      textAlign: "center !important",
    },
    "& input": {
      textAlign: "center !important",
    },
    "& td:nth-child(2)": {
      textAlign: "left !important",
      "& input": {
        textAlign: "left !important",
      },
    },
  },
  datePicker: {
    "&.focus": {
      borderColor: "white",
    },
    "& :hover": {
      borderColor: "white",
    },
    borderColor: "white",
  },
  datePickerInput: {
    "& input": {
      padding: "8px 10px",
    },
    "& Label": {
      top: "-1px !important",
      lineHeight: "9px !important",
    },
    "& .MuiFormLabel-filled": {
      top: "5px !important",
    },
  },
  tableRowStyle: {
    width: '854px',
    height: '20px',
    background: '#FFFFFF',
    paddingBottom: '1em',
    borderBottom: '2px solid #E5E5E5',
  },
  tableBodyStyle2: {
    height: "10px",
    fontStyle: "normal",
    fontFamily: "Inter",
    fontWeight: "normal",
    fontSize: "11px !important",
    lineHeight: "15px",
    color: "#0D0D0D",
    padding: "0px",
    textAlign: "center",
    position: "relative",
    "& input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
      textAlign: "center",
    }
  },
  tablecustomInput: {
    height: "10px", 
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily:"Inter",
    fontSize: "11px",
    lineHeight: "15px",
    color: "#0D0D0D",
    padding: "0px",
    paddingLeft: "25px",
    // borderRight: "0.3px solid rgba(224, 224, 224, 1)",
    position: "relative",
    width: "88px"
  },
  tableBodyTitleFieldStyle: {
    // minWidth: "150px",
    height: "20px", 
    fontStyle: "normal",
    fontFamily:"Roboto Mono",
    fontWeight: "400",
    fontSize: "11px",
    lineHeight: "15px",
    color: "#000000",
    padding: "0px",
    // borderRight: "0.3px solid rgba(224, 224, 224, 1)",
    position: "relative",
    "& .Mui-disabled": {
      WebkitTextFillColor: "#00000099",
    },
  },
  tableBodyBudgetFieldStyle: {
    height: "10px", 
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily:"Inter",
    fontSize: "11px",
    lineHeight: "15px",
    color: "#0D0D0D",
    padding: "0px",
    paddingLeft: "25px",
    // borderRight: "0.3px solid rgba(224, 224, 224, 1)",
    position: "relative",
    width: "88px"
  },
  damagesPeriodSelect: {
    "& .MuiSelect-outlined": {
      height: "10px"
    }
  },
descriptionInputAdjustment:{
    "& input":{
        display: "inline-block",
    // minWidth: "100px",
    paddingLeft: "5px",
    }
},
labelClassAdjustInput:{
    width: "100%",
    display: "block",
    "& > div":{
        display: "block",
        height: "auto",
        width: "100%",
    },
},
labelClassAdjustInputwithYellowText:{
    width: "100%",
    display: "block",
    /* height: 100%; */
    padding: "10px 0px",
    "& > div":{
        display: "inline-block",
        height: "auto",
        // width: "auto",
        maxWidth: "calc(100% - 10px)",
    },
},
onlySelectBoxCSS:{

},
}));
