import React, { useState, useEffect } from "react";
import {
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  Grid,
  Box,
  Typography, MenuItem,
} from "@mui/material";
import accountingService from "../../../Services/accountingService";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import { Project } from "../../../Models/project";
import { Costcode } from "../../../Models/costcode";
import { User } from "../../../Models/user";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import Loading from "../../../utilities/Lottie/Loading";
import "../CostCodes/costCode.css";
import { useStyles } from "./costcodeStyle";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import { AddressBookCompany } from "../../../Models/addressBook";
import { ColumnView } from "../../../Models/columnView";
import { getCalculationColumn } from "./calculateColumn";
import { useNavigate, useParams } from "react-router-dom";
import QuickCostCodeRow from "./QuickCostCodeRow";
import SingleMeasurement from "../Measurements/SingleMeasurements";
import {DrawingSet, Sheet, TakeOff} from "../../../Models/takeOffs";
import drawingAPI from "../../../Services/DoxleAPI/drawingAPI";
import DrawingAPI from "../../../Services/DoxleAPI/drawingAPI";
import TakeOffAPI from "../../../Services/DoxleAPI/takeOffAPI";
import Menu from "@mui/material/Menu";
import {Pricelist} from "../../../Models/pricelist";
import {toFloat} from "../Measurements/konvaFunctions/generalFunctions";
import PricelistAPI from "../../../Services/DoxleAPI/pricelistAPI";

interface props {
  selectedProject: Project | null;
  costCodeFilter: string | null;
  editTitleMode: boolean;
  setEditTitleMode: Function;
  users: User[];
  contractors: AddressBookCompany[];
  userFilter: string[];
  columnView: ColumnView;
}
export interface DisplayMenuState {
  anchorEl: any;
  action: "pricelist" | "takeoff" ;
  costCode: Costcode;
}
const QuickCostCodeTable: React.FC<props> = ({
  selectedProject,
  costCodeFilter,
}) => {
  const [drawings, setDrawings] = useState<Array<DrawingSet>>([]);
  const [pricelist, setPricelist] = useState<Array<Pricelist>>([]);
  const [takeOffs, setTakeOffs] = useState<Array<TakeOff>>([]);
  const [costcodes, setCostCodes] = useState<Array<Costcode>>([]);
  const [selectedCostCode, setSelectedCostCode] = useState<
    Costcode | undefined
  >(undefined);
  const [displayMenu, setDisplayMenu] = useState<DisplayMenuState|null>(null)
  const [budgetTotal, setBudgetTotal] = React.useState<number>(0);
  const [budgetIncomeTotal, setBudgetIncomeTotal] = React.useState<number>(0);
  const unmounted = useMountStatus();
  const [loading, setLoading] = useState<boolean>(false);
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn } = authContext;
  const projectStatus: string = selectedProject
    ? selectedProject.projectStatus
    : "";
  const allParams = useParams();
  console.log('allParams', allParams)
  const [goToProjectId, goToCostcodeId, goToTab] =
  allParams["*"]?.split("/") || [];
  const formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  const decideFooterDisplayMode = () => {
    if (costcodes.length === 0 || costCodeFilter === "clientMode") {
      return "none";
    } else {
      return "";
    }
  };

  interface updateTotalProps {
    budgetDelta?: number;
  }
  const updateTotals = ({
    budgetDelta,
  }: updateTotalProps) => {
    if (budgetDelta) {
      setBudgetTotal(budgetTotal + budgetDelta);
    }
  };


  const calculateTotals = () => {
    let totalBudget: number = 0;
    let totalIncomeBudget: number = 0;
    if (costcodes.length > 0) {
      costcodes.forEach((costcode) => {
        if (!costcode) return;
        if (!costcode.income) {
          totalBudget += parseFloat(costcode.budget);
        } else {
          totalIncomeBudget += parseFloat(costcode.budget);
        }
      });
    }
    setBudgetTotal(totalBudget);
    setBudgetIncomeTotal(totalIncomeBudget);
  };

  const updateBudget = (costCodeId: string, newBudget: string) => {
    let newCostCodes: Costcode[] = [];
    costcodes.forEach((costCode: Costcode) => {
      if (costCode.costCodeId === costCodeId) costCode.budget = newBudget;
      newCostCodes.push(costCode);
    });
    setCostCodes([...newCostCodes]);
  };

  const fetchCostCodes = async () => {
    setLoading(true);
    if (selectedProject) {
      try {
        const response = (await CostCodeAPI.getList({
          projectId: selectedProject.projectId,
          orderBy: "quickTakeOff",
        })) as Costcode[];
        console.log("cc response", response);
        if (unmounted) return;
        console.log("mounted");
        setCostCodes([...response]);
        calculateTotals();
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    }
    setLoading(false);
  };

  const fetchDrawings = async () =>{
    if (selectedProject) {
      try {
        const response = await DrawingAPI.getList(selectedProject.projectId) as DrawingSet[];
        if (unmounted) return;
        setDrawings([...response])
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    }
  };
  const fetchTakeOffs = async () =>{
    if (selectedProject) {
      try {
        const response = await TakeOffAPI.getList(selectedProject.projectId, undefined) as TakeOff[];
        console.log("cc response", response);
        if (unmounted) return;
        setTakeOffs([...response]);
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    }
  };

  const fetchPricelist = async () =>{
    if (selectedProject) {
      try {
        const response = await PricelistAPI.getList(1) as any;
        console.log("cc response", response);
        if (unmounted) return;
        console.log("pricelistResponse", response)
        setPricelist([...((response?.items ?? []) as Pricelist[])]);
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    }
  };

  const handleSetItemCost = async (priceListItem: Pricelist) => {
    const result: undefined | Costcode = displayMenu?.costCode ? await CostCodeAPI.updateQuickValues({
      costCode: displayMenu?.costCode?.costCodeId,
      cost: toFloat(priceListItem.price),
      uom: priceListItem.uom
    }) : undefined
    console.log("result", result)
    if (result) setCostCodes(prevState =>
      [...prevState.map((cc) => cc.costCodeId === result?.costCodeId ? result : cc)])
    setDisplayMenu(null)
  }

  const handleSetTakeOff = async (takeOff: TakeOff) => {
    const result: undefined | Costcode = displayMenu?.costCode ? await CostCodeAPI.updateQuickValues({
      costCode: displayMenu?.costCode?.costCodeId,
      qty: Math.round(toFloat(takeOff.totalValue) * 1000) / 1000,
      uom: takeOff.unit.toLowerCase()
    }) : undefined
    console.log("result", result)
    if (result) setCostCodes(prevState =>
      [...prevState.map((cc) => cc.costCodeId === result?.costCodeId ? result : cc)])
    setDisplayMenu(null)
  }

  useEffect(() => {
    calculateTotals();
  }, [costcodes]);

  useEffect(() => {
    fetchCostCodes();
    fetchDrawings();
    fetchTakeOffs();
    fetchPricelist();
  }, [selectedProject]);
  if (selectedCostCode) return (<SingleMeasurement
    costcode={selectedCostCode}
    drawings={drawings}
    setDrawings={setDrawings}
    existingTakeOff={takeOffs.filter((to: TakeOff) => to.takeOffId === selectedCostCode?.costCodeId)?.[0] ?? null}
    setCostcode={setSelectedCostCode}
    setCostCodes={setCostCodes}
    setTakeOffs={setTakeOffs}
  />)

  else return (
    <>
      <div id="costcodeGroup">
        {loading ? (
          <>
            <div style={{ marginTop: "15%" }}>
              <Loading />
            </div>
          </>
        ) : (
          // <TableContainer sx={{ maxHeight: width < 928 ? (height-57)+"px" : "95.5vh" }}>
          // <TableContainer sx={{ height: "calc(100vh - 108px - 48px)" }}>
          <div style={{ position: "relative" }}>
            <div
              id="sticker"
              style={{
                background: "#D5D7E3",
                width: "10px",
                height: "16px",
                position: "absolute",
                zIndex: 900,
                right: 0,
              }}
            />
            {/* COSTCODE TABLE*/}
            <TableContainer sx={{ height: "calc(100vh - 185px)" }}>
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow
                    className="tableHeadStyle"
                    sx={{ background: "#D5D7E3" }}
                  >
                    <TableCell
                      style={{ position: "sticky", left: 0, zIndex: 99 }}
                      sx={{ ...useStyles.tableHeadingStyle, maxWidth: 44 }}
                    />
                    <TableCell
                      style={{
                        position: "sticky",
                        left: 44,
                        zIndex: 99,
                        textAlign: "left",
                      }}
                      sx={useStyles.tableHeadingStyle}
                    >
                      ACCOUNTS
                    </TableCell>
                    <TableCell sx={useStyles.tableHeadingStyle}>
                      QTY
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "40px",
                        ...useStyles.tableHeadingStyle,
                      }}
                    />
                    <TableCell
                      sx={{
                        maxWidth: "40px",
                        ...useStyles.tableHeadingStyle,
                      }}
                    />
                    <TableCell sx={useStyles.tableHeadingStyle}>
                      UOM
                    </TableCell>
                    <TableCell sx={useStyles.tableHeadingStyle}>
                      ITEM COST
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "40px",
                        ...useStyles.tableHeadingStyle,
                      }}
                    />
                    <TableCell sx={useStyles.tableHeadingStyle}>
                      BUDGET
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tableBodyStyle">
                  {costcodes.map((costcode) =>
                      <QuickCostCodeRow
                        key={costcode.costCodeId}
                        initCostCode={costcode}
                        projectStatus={projectStatus}
                        handleTakeOffClick={setSelectedCostCode}
                        setDisplayMenu={setDisplayMenu}
                      />
                  )}
                </TableBody>
                <TableFooter style={{ display: decideFooterDisplayMode() }}>
                  <TableRow
                    className="tableFootStyle"
                    sx={{
                      "& .MuiTableCell-footer": {
                        borderTop: "1px solid rgb(224, 224, 224)",
                      },
                    }}
                  >
                    <TableCell
                      style={{ position: "sticky", left: 0, zIndex: 99 }}
                    />
                    <TableCell
                      style={{ position: "sticky", left: 44, zIndex: 99 }}
                      sx={useStyles.tableFooterTotalStyle}
                    >
                     Total
                    </TableCell>
                    <TableCell
                      sx={{
                        ...useStyles.tableFooterTotalStyle,
                      }}
                    />
                    <TableCell
                      sx={{
                        maxWidth: "40px",
                        ...useStyles.tableFooterTotalStyle,
                      }}
                    />
                    <TableCell
                      sx={{

                        ...useStyles.tableFooterTotalStyle,
                      }}
                    />
                    <TableCell
                      sx={{
                        maxWidth: "40px",
                        ...useStyles.tableFooterTotalStyle,
                      }}
                    />
                    <TableCell
                      sx={{

                        ...useStyles.tableFooterTotalStyle,
                      }}
                    />
                    <TableCell
                      sx={{
                        ...useStyles.tableFooterTotalStyle,
                      }}
                    />
                    <TableCell
                      sx={{
                        ...useStyles.tableFooterTotalStyle,
                        padding: "0px 5px 0px 25px",
                        borderRight: "1px solid rgb(224, 224, 224)",
                        borderLeft: "1px solid rgb(224, 224, 224)",
                      }}
                    >
                      {formatter.format(budgetTotal)}
                    </TableCell>

                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
      {displayMenu?.anchorEl &&
        <Menu
          open={true}
          anchorEl={displayMenu.anchorEl}
          onClose={() => setDisplayMenu(null)}
          style={{maxHeight: 350}}
        >
          {
            displayMenu.action === "takeoff"
            ?
              takeOffs.map((to: TakeOff) =>
                <MenuItem
                  onClick={()=>handleSetTakeOff(to)}
                > {to.description} </MenuItem>
              )
            :
              pricelist.map((pl: Pricelist) =>
                <MenuItem
                  onClick={()=>handleSetItemCost(pl)}
                > {pl.description} </MenuItem>
              )
          }
        </Menu>
      }
    </>
  );
};

export default QuickCostCodeTable;
