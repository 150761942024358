import axios from "axios";
import {Note, NewNote, INoteLine} from '../../Models/note';
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";


const getList = async (projectId?: string, costCodeId?: string)  => {
    let result: Note[] = []
    if (offline){return result}
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        let params: any = {}
        if (projectId) params.project = projectId
        else if (costCodeId) params.cost_code = costCodeId
        else throw ' Missing projectId or CostCodeId'
        const response = await axios.get(
            baseAddress+"/notes/",
            {
                headers: { Authorization: "Bearer " + accessToken },
                params: params
            }
        )
        result = response.data.results as Array<Note>;
        console.log("NOTE", result)
    } catch (error) {
        console.error("ERROR FETCHING NOTE", error)
    }
    return result
}

const getDetailed = async (noteId: string)  => {
    let result: Note | undefined = undefined;
    if (offline){ return result}
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }

    try {
        const response = await axios.get(
            baseAddress+"/notes/"+noteId+"/",
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.data as Note;
        console.log("NOTE DETAILED", result)
    } catch (error) {
        console.error("ERROR FETCHING NOTE", error)
    }
    return result
}

const remove = async (noteId: string)  => {
    let result: boolean = false
    if (offline){return result}
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        const response = await axios.delete(
            baseAddress+"/notes/"+noteId+'/',
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.status === 204;
    } catch (error) {
        console.error("ERROR DELETING NOTE", error);
    }
    return result
}

const add = async (newNote: NewNote)  => {
    //if (offline){ return dummy.newDummyCostCode(notice); }
    // Add a new costcode
    let result: Note | undefined = undefined
    if (offline){return result}
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }

    try {
        let note:any = {
            title: newNote.title,
            body: newNote.body,
            created: new Date().toISOString(),
            createdBy: newNote.userId,
            lastModified: new Date().toISOString(),
            lastModifiedBy: newNote.userId,
        }
        if (newNote.project) note.project = newNote.project
        else if (newNote.costCode) note.costCode = newNote.costCode
        console.log("new note", note)
        const response = await axios.post(
            baseAddress+"/notes/",
            note,
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.status === 201 ? response.data as Note : undefined;
        console.log("ADDED NOTE", result)
    } catch (error) {
        console.error("ERROR ADDING NOTE", error)
    }
    return result
}


interface UpdateBody {
    title?: string;
    body?: string;
    userId: string;
    creatorId: string;
    costCode: string;
    lines: string[];
}

interface Payload {
    title?: string;
    body?: string;
    lastModifiedBy: string;
    lastModified: string;
    createdBy?: string;
    costCode?: string;
    lines: string[];
}

const update = async (
    noteId: string,
    { title, body, userId, creatorId, costCode, lines}: UpdateBody
) => {
    // Update one or more property of a note without affecting other values
    // Only pass in what is needed to be updated
    let result: Note | undefined = undefined
    if (offline){return result}
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }

    try {
        let payload: Payload = {
            lastModifiedBy: userId,
            lastModified: new Date().toISOString(),
            createdBy: creatorId,
            lines: lines
        }
        if (title) {payload.title = title;}
        if (body) {payload.body = body;}
        if (costCode) {payload.costCode = costCode;}

        console.log('noteId', noteId);
        console.log('payload', payload);
        const response = await axios.patch(
            baseAddress+"/notes/"+noteId+"/",
            payload,
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.status === 200 ? response.data as Note: undefined;
    } catch (error) {
        console.error("ERROR UPDATING NOTE", error)
    }
    return result
}

const updateLines = async (lineId: string, text: string) => {
    let result: INoteLine | undefined = undefined
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        const response = await axios.patch(
            `${baseAddress}/notes/line/${lineId}/`,
            {text: text},
            { headers: { Authorization: "Bearer " + accessToken } }
        )
        result = response.status === 200 ? response.data as INoteLine: undefined;
    } catch (error) {
        console.error("ERROR UPDATING NOTE LINE", error)
    }
    return result
}

const NoteAPI = {
    getList,
    getDetailed,
    remove,
    update,
    add,
    updateLines
};

export default NoteAPI