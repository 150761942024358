import { Bill } from "../../Models/bill"
import { NewProject, Project } from "../../Models/project"
import { NewCostCode, Costcode } from "../../Models/costcode"

const companyListResponse = {
    count: 2,
    next: null,
    previous: null,
    results: [
      {
        companyId:"acf2c3a3-e70d-44cf-b5d2-06d0b1bdde68",
        name:"Byson Group",
        phone:"(03) 9052 4527",
        email:"build@byson.com.au",
        companyAbn:"27 629 050 326",
        addressL1:"",
        addressL2:"",
        addressCity:"Mulgrave",
        addressPostCode:"3170",
        addressState:"VIC",
        addressCountry:"",
        logo:"https://firebasestorage.googleapis.com/v0/b/doxle-build-98566.appspot.com/o/company%2Fbyson%2Fstorage%2Flogo_1632874290638.png?alt=media&token=27d1a49d-1eca-447e-9026-cbcc3f360c29"
      },
      {
        companyId:"6dc64712-b6cc-46ef-917d-e92e24c26a5f",
        name:"Selwin Group",
        phone:"(03) 9052 4527",
        email:"office@selwin.com",
        companyAbn:"58150594853",
        addressL1:"",
        addressL2:"",
        addressCity: "Brighton",
        addressPostCode:"3186",
        addressState:"VIC",
        addressCountry:"Australia",
        logo:"https://storage.googleapis.com/doxle-build-98566.appspot.com/company/selwingroup/storage/logo_1633937434894.png"
      }
    ]
}
  
const companyDetailResponse = {
    companyId: "acf2c3a3-e70d-44cf-b5d2-06d0b1bdde68",
    name: "Byson Group",
    phone: "(03) 9052 4527",
    email: "build@byson.com.au",
    companyAbn: "27 629 050 326",
    addressL1: "",
    addressL2: "",
    addressCity: "Mulgrave",
    addressPostCode: "3170",
    addressState: "VIC",
    addressCountry: "",
    logo: "https://firebasestorage.googleapis.com/v0/b/doxle-build-98566.appspot.com/o/company%2Fbyson%2Fstorage%2Flogo_1632874290638.png?alt=media&token=27d1a49d-1eca-447e-9026-cbcc3f360c29",
    projects: [
        {
            projectId: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            siteAddress: "1 Cambridge Street, Brighton East",
            projectStatus: "AC"
        },
        {
            projectId: "ddc2cce8-85db-4f22-8d82-b6c3b6350c7a",
            siteAddress: "1 Threadbow Cres Wheelers Hill",
            projectStatus: "AC"
        },
        {
            projectId: "9f541085-545b-492f-9d13-6021b64b77da",
            siteAddress: "15 Kenny St, Balwyn North",
            projectStatus: "TN"
        },
        {
            projectId: "68984bfc-11ff-4064-a541-218dbb11447a",
            siteAddress: "178 Ludstone Street Hampton",
            projectStatus: "TN"
        },
        {
            projectId: "2d832a4a-877f-4115-8db9-aa877f74db74",
            siteAddress: "19 Eloura St, Brighton East",
            projectStatus: "AC"
        },
        {
            projectId: "bc0ba534-356c-45b9-8fdb-5e7fd6e367c2",
            siteAddress: "24 Studley St, Doncaster",
            projectStatus: "AC"
        },
        {
            projectId: "5349763b-bcfb-4985-8f7b-2c85b8c16b62",
            siteAddress: "26 Melrose Dr, Mont Albert",
            projectStatus: "AC"
        },
        {
            projectId: "2298ba81-de6c-45c6-bfa0-a3366fa2e5e3",
            siteAddress: "3 Duckmanton Ct, Bentleigh East",
            projectStatus: "TN"
        },
        {
            projectId: "bcdcfc0d-47b3-44c6-8991-45d2e2cc62d3",
            siteAddress: "46 McComas Grove, Burwood",
            projectStatus: "AC"
        },
        {
            projectId: "6aa76c65-aab8-456a-bb9f-e44183a16db8",
            siteAddress: "47 Tennyson Ave Clayton South",
            projectStatus: "TN"
        },
        {
            projectId: "dd4813b9-18d0-428b-a645-2ee51a8bdf12",
            siteAddress: "62 William St Brighton",
            projectStatus: "TN"
        },
        {
            projectId: "87878c29-e41c-4dc3-8027-868258fa5fd2",
            siteAddress: "88 Union St, Brighton East",
            projectStatus: "AC"
        },
        {
            projectId: "d96550f7-7d70-4fc7-b34e-cfc5433e8a16",
            siteAddress: "9 Geel St, Bentleigh",
            projectStatus: "AC"
        },
        {
            projectId: "df58cb53-51bd-4c12-b59b-6d55422f30eb",
            siteAddress: "9 Wordsworth Ave, Clayton South",
            projectStatus: "TN"
        },
        {
            projectId: "02a6a082-e980-4759-845a-d091e66105ff",
            siteAddress: "98 Brady Rd, Bentleigh East",
            projectStatus: "TN"
        }
    ]
}

const projectListResponse = {
    count: 15,
    next: null,
    previous: null,
    results: [
      {
        projectId:"15eea90e-5e20-4e68-8a76-6f72012d240c",
        siteAddress:"1 Cambridge Street, Brighton East",
        contractPrice:"1299600.01",
        budget:"945000.01",
        startDate:"2022-03-15T22:31:44.201621Z",
        endDate:"2023-03-30T13:00:00Z",
        projectPrefix:"",
        ownerIsCompany:false,
        company:"acf2c3a3-e70d-44cf-b5d2-06d0b1bdde68",
        ownerCompany:null,
        ownerContact:null,
        trackingId:null,
        users:[],
        projectStatus:"AC"
      },
      {
        projectId:"ddc2cce8-85db-4f22-8d82-b6c3b6350c7a",
        siteAddress:"1 Threadbow Cres Wheelers Hill",
        contractPrice:"2275000.00",
        budget:"1654545.45",
        startDate:"2022-03-15T22:31:44.241621Z",
        endDate:"2021-12-01T11:00:58.139957Z",
        projectPrefix:"THREAD",
        ownerIsCompany:false,
        company:"acf2c3a3-e70d-44cf-b5d2-06d0b1bdde68",
        ownerCompany:null,
        ownerContact:null,
        trackingId:"1da85ef7-ed4e-4630-bf66-e3590cf91db6",
        users:[],
        projectStatus:"AC"
      },
      {
        projectId:"9f541085-545b-492f-9d13-6021b64b77da",
        siteAddress:"15 Kenny St, Balwyn North",
        contractPrice:"1700000.00",
        budget:"1360000.00",
        startDate:"2022-03-15T22:31:44.279621Z",
        endDate:"2021-12-01T11:00:57.905564Z",
        projectPrefix:"",
        ownerIsCompany:false,
        company:"acf2c3a3-e70d-44cf-b5d2-06d0b1bdde68",
        ownerCompany:null,
        ownerContact:null,
        trackingId:null,
        users:[],
        projectStatus:"TN"
      },
      {
        projectId:"68984bfc-11ff-4064-a541-218dbb11447a",
        siteAddress:"178 Ludstone Street Hampton",
        contractPrice:"2076360.00",
        budget:"1661088.00",
        startDate:"2022-03-15T22:31:44.321623Z",
        endDate:"2023-04-30T00:00:00Z",
        projectPrefix:"",
        ownerIsCompany:false,
        company:"acf2c3a3-e70d-44cf-b5d2-06d0b1bdde68",
        ownerCompany:null,
        ownerContact:null,
        trackingId:null,
        users:[],
        projectStatus:"TN"
      },
      {
        projectId:"2d832a4a-877f-4115-8db9-aa877f74db74",
        siteAddress:"19 Eloura St, Brighton East",
        contractPrice:"1100000.01",
        budget:"800000.01",
        startDate:"2022-03-15T22:31:44.368621Z",
        endDate:"2022-07-17T14:00:00Z",
        projectPrefix:"ELOUR",
        ownerIsCompany:false,
        company:"acf2c3a3-e70d-44cf-b5d2-06d0b1bdde68",
        ownerCompany:null,
        ownerContact:null,
        trackingId:"7d84b4f0-3495-45bf-8ec2-7e22d32551f2",
        users:[],
        projectStatus:"AC"
      }
    ]
}
  
const projectGetResponse = {
    projectId:"15eea90e-5e20-4e68-8a76-6f72012d240c",
    siteAddress:"1 Cambridge Street, Brighton East",
    contractPrice:"1299600.01",
    budget:"945000.01",
    startDate:"2022-03-15T22:31:44.201621Z",
    endDate:"2023-03-30T13:00:00Z",
    projectPrefix:"",
    ownerIsCompany:false,
    company:"acf2c3a3-e70d-44cf-b5d2-06d0b1bdde68",
    ownerCompany:null,
    ownerContact:null,
    trackingId:null,
    users:[],
    projectStatus:"AC"
}
  
const projectFullResponse = {
    projectId: "15eea90e-5e20-4e68-8a76-6f72012d240c",
    siteAddress: "1 Cambridge Street, Brighton East",
    contractPrice: "1299600.01",
    budget: "945000.01",
    startDate: "2022-03-15T22:31:44.201621Z",
    endDate: "2023-03-30T13:00:00Z",
    projectPrefix: "",
    ownerIsCompany: false,
    company: "acf2c3a3-e70d-44cf-b5d2-06d0b1bdde68",
    ownerCompany: null,
    ownerContact: null,
    trackingId: null,
    users: [],
    projectStatus: "AC",
    costCodes: [
        {
            costCodeId: "ef43a84e-c54d-45e9-9177-491128f1b116",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.01 Preliminaries",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.556890Z",
            endDate: "2022-03-15T22:31:45.556890Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "525f8095-4f5c-4bd5-ac12-48451c4bc496",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "a22c3e35-c327-46fd-a5ac-aaf9448c990a",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.02 Council Permits",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.595890Z",
            endDate: "2022-03-15T22:31:45.595890Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "e50e112e-22b3-4c67-83fd-eeeaa216a0d4",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "0f7d9b8a-289f-4d21-acf9-ae08e0ba6f09",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.03 Protection Works",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.642891Z",
            endDate: "2022-03-15T22:31:45.642891Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "065ca246-3b35-4abb-b5a5-501a242e04f9",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "acbc0be5-1fb8-4905-ac40-cae9704a2caf",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.04 Telstra",
            budget: "1272.73",
            startDate: "2022-03-15T22:31:45.688920Z",
            endDate: "2022-03-15T22:31:45.688920Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "84b8641b-8446-4c00-9f0f-bafc7f2d272c",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "7bbe7197-69ab-478b-871c-4a520210e24c",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.05 Electricity Pit",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.726914Z",
            endDate: "2022-03-15T22:31:45.726914Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "bb7a588b-80ef-4e38-9114-6cf8c8ebef4f",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "e3f7cae9-5fd9-43b6-9cc7-4eb46ceefa02",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.06 Water Tapping",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.767913Z",
            endDate: "2022-03-15T22:31:45.767913Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "af226212-d36f-4b22-8dc1-b885220fd103",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "12951c14-b7ba-4234-9c3e-5452601ec56f",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.07 Temp Fencing",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.806912Z",
            endDate: "2022-03-15T22:31:45.806912Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "34900dd3-18be-44e7-aa54-51f5c6b15417",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "7b5d954e-c481-41f2-bf87-a41c96625a1c",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.08 Hoarding",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.845912Z",
            endDate: "2022-03-15T22:31:45.845912Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "fce3d05a-2a95-4f22-a227-6c05069b2938",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "7a949341-4611-45a6-bd34-4aee227e2b41",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.09 Temp Toilet",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.894535Z",
            endDate: "2022-03-15T22:31:45.894535Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "db481fb5-630f-45f5-b107-4cdf3bef0220",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "91248ad1-8895-49bb-b8cc-c9304e5cc577",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.10 Temp Crossing",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.956538Z",
            endDate: "2022-03-15T22:31:45.956538Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "38cc9e76-e998-416f-bdcf-84c9a09c45a9",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "1b112c1d-5ffa-41a3-9a21-e4750ce7c6ea",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.12 Temp Rubbish Cage",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.993534Z",
            endDate: "2022-03-15T22:31:45.993534Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "46655960-3211-4980-80fd-fdb10ffe9838",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "7d29a4f1-bbcf-495c-b6d0-e28b602cbd81",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.13 Demolition",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.034025Z",
            endDate: "2022-03-15T22:31:46.034025Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "ffe48d96-0d09-4701-b73f-aed9ce3a00ab",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "3699a436-608c-4c04-9d36-abb46190ecbd",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.14 Temporary Power",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.083311Z",
            endDate: "2022-03-15T22:31:46.083311Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "347eb75c-f383-45f2-b396-dc57ac900d39",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "8f25abdf-23d6-4bda-8f25-39f2dc44ead4",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.15 Warranty Insurance",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.128513Z",
            endDate: "2022-03-15T22:31:46.128513Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "257a79fe-bab0-48e5-a234-c36bd9de44d4",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "745dffd2-ad39-4c8e-9275-9848dc4400f4",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.16 Building Permit",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.174545Z",
            endDate: "2022-03-15T22:31:46.174545Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "858a1192-7cc9-4b3c-ac50-95dcfc4d74b4",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "ffad8466-0816-4ea0-b09e-5402acc41559",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.98 Deposit Contingency",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.221519Z",
            endDate: "2022-03-15T22:31:46.221519Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "bba835bf-66cb-4038-ab59-ea840a7d7a0b",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "13bb3f9c-65f2-4847-b95c-79139b73949d",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.99 Deposit Claim",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.267604Z",
            endDate: "2022-03-15T22:31:46.267604Z",
            budgetOverride: false,
            income: true,
            locked: false,
            completed: false,
            accountTrackingId: "452e1acb-467e-48b9-8b30-60849f1ea160",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "f9d8a634-46c0-49fb-962b-ffca8de54d88",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.01 Setout",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.312924Z",
            endDate: "2022-03-15T22:31:46.312924Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "a40d114e-399f-4ad7-b368-a35320503c19",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "d0327d04-eb99-4abb-be81-6e5c0f12c528",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.02 Site Prep",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.362130Z",
            endDate: "2022-03-15T22:31:46.362130Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "21591ee2-b5dc-402f-850c-155a48dc09db",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "0b7ee14f-12f6-4f41-8049-d5a208f636e3",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.05 Shoring & Underpinning",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.408203Z",
            endDate: "2022-03-15T22:31:46.408203Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "5d31ac37-cfd1-4f1e-9c10-fecec451b771",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "42d2d06b-c7bd-4abd-bec2-938ec1766312",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.06 Piling",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.455204Z",
            endDate: "2022-03-15T22:31:46.455204Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "52d31f1b-968a-436d-9b66-d5701b92a123",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "4fa5cb0d-177e-4efc-af49-2ef953785335",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.07 Basement",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.502204Z",
            endDate: "2022-03-15T22:31:46.502204Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "0478425b-3bb3-4a6a-ad11-2bb46f58c4ae",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "84693904-c5a0-442d-8c53-2a87a9738587",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.08 Retaining Wall",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.549205Z",
            endDate: "2022-03-15T22:31:46.549205Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "8183b490-1233-4d31-8081-0a87ea9685ed",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "d95a8ee3-076d-4e7b-bce0-76a06c180d0e",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.09 Underslab Insulation",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.597687Z",
            endDate: "2022-03-15T22:31:46.597687Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "da1b6744-64b1-421a-bf1f-ca34e614fda1",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "f9ec8630-025c-4f2e-896c-4d6238b5cbf9",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.10 Hydronic Heating",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.643710Z",
            endDate: "2022-03-15T22:31:46.643710Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "b6543b1f-8089-4219-bdd1-abcb8808c201",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "4ae7ce31-305c-4a55-8256-31afb0135758",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.11 Drainage",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.691711Z",
            endDate: "2022-03-15T22:31:46.691711Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "f5360b29-a2f5-49bf-8369-60cc3826641c",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "08eae724-4aa9-4f61-86d3-3299609ec564",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.12 Concrete Reinforcement",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.737711Z",
            endDate: "2022-03-15T22:31:46.737711Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "d0f58350-66ec-44e9-a396-f82b1c0de9cd",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "07f1cfc3-ed3b-4402-ace2-ba7eb4d39f46",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.13 Termite Collars",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.775711Z",
            endDate: "2022-03-15T22:31:46.775711Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "2075fdb1-1415-4e97-aab7-1cc4a3236a3e",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "ac867c36-b1f1-4b24-8734-3a7961e9a20d",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.14 Electricity Underground",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.816726Z",
            endDate: "2022-03-15T22:31:46.816726Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "45d4605b-ff4e-4e0b-a6a1-fef9e0fbf68d",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "8c9b0e96-2354-4b8b-bb57-72ac043329aa",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.15 Concrete Pump",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.864186Z",
            endDate: "2022-03-15T22:31:46.864186Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "86300079-be8a-452d-865d-ec6377620531",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "24b671ff-ffb3-43d3-97f1-29721ed987da",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.16 Concrete Material",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.911734Z",
            endDate: "2022-03-15T22:31:46.911734Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "05761ba1-89d6-4e38-9ed4-960eb440f0fc",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "879d27d7-72a0-49e2-9c6b-0112dd3d4b5e",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.17 Concrete Labour",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.958732Z",
            endDate: "2022-03-15T22:31:46.958732Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "632e9bf1-7330-4daa-98bb-b77f0e5d05bb",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "738dbe09-01a6-4c4a-a579-223a1cca4182",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.98 Concrete Contingency",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.006732Z",
            endDate: "2022-03-15T22:31:47.006732Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "72af42a2-acb5-4070-82f6-b96e6d513796",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "41d7e359-c036-45a8-865a-2096f8a5452f",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.99 Concrete Claim",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.044733Z",
            endDate: "2022-03-15T22:31:47.044733Z",
            budgetOverride: false,
            income: true,
            locked: false,
            completed: false,
            accountTrackingId: "45d8dcee-a4a8-4792-b693-61c0458c358a",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "7c84ac07-4402-4475-86b5-9beefd9a973f",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.04 Termite Sheet",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.084733Z",
            endDate: "2022-03-15T22:31:47.084733Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "41eed2ef-0a0a-44f9-b1f6-46f00e745301",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "f1d1ac73-9fce-4558-9713-5385095ab7f9",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.05 Ground Floor Frames",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.133318Z",
            endDate: "2022-03-15T22:31:47.133318Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "025971da-4c75-437b-b4ff-730c4b08af48",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "b4750340-0899-40be-b1f5-f8625799e1e3",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.06 Structural Steel",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.180347Z",
            endDate: "2022-03-15T22:31:47.180347Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "450b2858-8781-4a60-a107-dbed78752858",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "c13c0d8d-b83c-4cad-9e82-ef73b37c54d4",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.07 Party Walls",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.227499Z",
            endDate: "2022-03-15T22:31:47.227499Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "8045fc10-31bd-40a2-a977-237dda00da69",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "43de46d2-e075-4f6c-b2ae-b24083ac30b5",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.08 Floor Trusses",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.273532Z",
            endDate: "2022-03-15T22:31:47.273532Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "d23df88f-4311-4c07-9803-5c830a2ef56a",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "03bc2ba7-af0f-447e-9cd1-9d2bdf739018",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.09 Floor Beams",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.321819Z",
            endDate: "2022-03-15T22:31:47.321819Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "b85abac1-244e-4dce-9a50-79fb90183b19",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "5a5aa09d-730d-4222-9dcd-1f27172612fd",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.10 Sheet Flooring",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.368512Z",
            endDate: "2022-03-15T22:31:47.368512Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "310ad967-b75c-4acd-b6ce-7a302fa04daa",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "340ad447-ff77-4a6e-b432-0985af96ce02",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.11 Void Protection",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.414789Z",
            endDate: "2022-03-15T22:31:47.414789Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "eb6e41e4-3a2c-4379-a902-d025f5da8695",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "5d6e2307-a8ef-456d-b50f-28abbb1561ba",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.12 First Floor Frames",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.461587Z",
            endDate: "2022-03-15T22:31:47.461587Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "f3222f91-de8d-4cc9-b249-972891221265",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "2c885ebc-64a2-4de1-a189-642dd7b7e58a",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.13 Trusses",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.508586Z",
            endDate: "2022-03-15T22:31:47.508586Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "a80a73d2-8d67-48ac-8cd8-8f21f3f24c6d",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "267b1222-aa67-4aa5-9316-6faabdaed3b3",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.14 Frame Carpenter",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.546586Z",
            endDate: "2022-03-15T22:31:47.546586Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "13871f81-c618-41ac-bf8d-02df10bb249b",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "53d22d54-7099-4124-a8e8-1c5e464a545c",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.98 Frame Contingency",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.587586Z",
            endDate: "2022-03-15T22:31:47.587586Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "edb1c6d0-b7bb-4297-b9ab-312da0a45c74",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "8a240431-cc8b-4866-84b7-d331af8daf49",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.99 Frame Claim",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.624585Z",
            endDate: "2022-03-15T22:31:47.624585Z",
            budgetOverride: false,
            income: true,
            locked: false,
            completed: false,
            accountTrackingId: "3e206690-07c4-496b-b4b7-fcd90cd62a27",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "75d8f609-ad70-43bb-9b7a-5754ff8d4cc0",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.02 Windows",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.665585Z",
            endDate: "2022-03-15T22:31:47.665585Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "36c101b7-d8a4-41f5-9ffa-f3eedfc8530c",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "d6d6e1ac-d0fa-405d-abdb-4b5385eb31f7",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.03 Window Louvres",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.713585Z",
            endDate: "2022-03-15T22:31:47.713585Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "d25c82ac-75e4-4ee3-b282-419ee2f1a7c5",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "022e0a23-c7f6-4853-91d6-cead55c09f27",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.04 Skylight",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.751588Z",
            endDate: "2022-03-15T22:31:47.751588Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "6e434bb4-d978-42db-a191-3a0de767af64",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "64ba80e2-162c-4352-9f5f-9fa78e774d48",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.05 Two Plank Scaffold",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.789585Z",
            endDate: "2022-03-15T22:31:47.789585Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "5b9d5ed8-39c9-4490-a62f-19fbf76c9ac1",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "225429e7-a68d-4ab0-b51a-1b93fb0b6f8a",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.06 Fascia Gutter",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.826585Z",
            endDate: "2022-03-15T22:31:47.826585Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "c66eaf4d-8f64-4ac9-a23c-5dda61603214",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "318835e1-4787-4c30-9e5c-1d08d3c9131e",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.07 Roof Tiling",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.872611Z",
            endDate: "2022-03-15T22:31:47.872611Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "0faea234-44ed-4156-9bbe-65603c583716",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "788a82a9-c510-4daa-9661-73df0c699618",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.08 Eaves",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.921235Z",
            endDate: "2022-03-15T22:31:47.921235Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "60571df1-79df-4713-b8d8-60a1df8684f8",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "55fa1c29-eb9b-4445-816f-abd122d0e44b",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.09 Roof Plumbing",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.958234Z",
            endDate: "2022-03-15T22:31:47.958234Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "2b0f82cf-b785-4ba2-a0b0-b76f8d339db0",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "f618c63b-257d-4858-8bbf-5b7f227f1236",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.10 Sisilation",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.998234Z",
            endDate: "2022-03-15T22:31:47.998234Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "9773c32d-ef60-4968-b94b-200246f23087",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "427afe8e-d738-44de-b674-22ba7208fa73",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.11 Ground Up Scaffolding",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.045234Z",
            endDate: "2022-03-15T22:31:48.045234Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "df15c81c-2129-48a5-bb76-72f0e1f4f066",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "2c4fc21b-5598-459c-b834-1407afd7d892",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.12 First Floor Cladding",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.093234Z",
            endDate: "2022-03-15T22:31:48.093234Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "9df6f69f-163c-4a3f-bae5-fa6a27aef6af",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "5a685941-240a-4205-abe3-fb42e3ee6bc5",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.13 Ground Floor Cladding",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.142235Z",
            endDate: "2022-03-15T22:31:48.142235Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "8f74028d-a69e-4237-8ece-2dc61eb93d6d",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "036b64b2-cd8e-4869-9cf3-e32d3e17bc2a",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.14 Heating & Cooling",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.188234Z",
            endDate: "2022-03-15T22:31:48.188234Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "7ea70573-72d1-4d71-91ce-7986f18701bb",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "35fdc3ef-7036-402d-95bf-ff42ac08bc34",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.15 General Plumbing",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.233235Z",
            endDate: "2022-03-15T22:31:48.233235Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "9f9e1fab-7be1-4e43-965a-39c09475561f",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "6ad020a4-ad15-452f-bd2b-41b1bc04acfb",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.17 Cameras",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.271234Z",
            endDate: "2022-03-15T22:31:48.271234Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "9c5d1dec-33de-4e45-94e8-28f00927b10d",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "c1a6b694-3caa-4c02-8b2d-82fe2f9220c5",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.18 Alarms",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.313236Z",
            endDate: "2022-03-15T22:31:48.313236Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "870fed64-2ea2-430f-a318-f4a344408355",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "ee53e32d-f57f-4b9a-974b-a1c1961f5030",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.19 Video Intercom",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.351235Z",
            endDate: "2022-03-15T22:31:48.351235Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "8887206c-23a6-4df2-9cdf-bf4a86b904c3",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "709a9315-0896-4b88-b412-a9b8ec304008",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.20 Ducted Vacuum",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.391514Z",
            endDate: "2022-03-15T22:31:48.391514Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "8b221646-3f04-41df-a004-922e926652cb",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "e559b607-d7ae-4d44-8f88-96051365ca57",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.21 Fire Place",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.439841Z",
            endDate: "2022-03-15T22:31:48.439841Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "2c764ae3-6d5d-4de8-a446-b48aa34e238f",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "8434cc59-7eb7-4675-987d-158d1c4973b4",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.22 Electrical",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.486839Z",
            endDate: "2022-03-15T22:31:48.486839Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "1a68eb1a-c525-4563-92c2-a70f81e40d5e",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "19053669-dd66-4a2e-a6e4-1f26915e3d2a",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.23 Lock Up Material",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.532839Z",
            endDate: "2022-03-15T22:31:48.532839Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "6e29e2b3-cef1-482a-a374-c67059764412",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "443c079e-068e-424a-a86f-2f49c0191b15",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.24 Lock Up Carpenter",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.580847Z",
            endDate: "2022-03-15T22:31:48.580847Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "80a40556-b956-4e83-9435-771a2f3096d1",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "cd78302b-d7a7-4511-b541-c440f80d4670",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.25 Infill Slabs",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.626839Z",
            endDate: "2022-03-15T22:31:48.626839Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "8eac20d6-a2bf-4c85-9141-5732ad8ebd47",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "06f2f818-14c9-4e1e-93e9-9b6bca2eaecf",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.26 Fire Rating",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.675258Z",
            endDate: "2022-03-15T22:31:48.675258Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "ffe9c87f-96ee-4b06-989a-d51904af9ceb",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "cb22a185-21ce-4da0-9295-a3ca342a5ddc",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.27 Brick Cleaning",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.721767Z",
            endDate: "2022-03-15T22:31:48.721767Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "0d19349f-ca4e-4163-b14d-dfe838204805",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "9e2adb40-d494-4bf4-b029-106f66ff692b",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.28 Crossover Permit",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.759766Z",
            endDate: "2022-03-15T22:31:48.759766Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "2fd494d2-9f89-413d-bf2f-97b4489d71cd",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "c86cc94b-903f-45e3-91df-425fa968cbed",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.98 Lock Up Contingency",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.902734Z",
            endDate: "2022-03-15T22:31:48.902734Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "23393d61-b675-40c1-a850-c84aefbb1c74",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "15e373eb-a850-4a1d-bb3b-6a2eacaabe0f",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.99 Lock Up Claim",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.940743Z",
            endDate: "2022-03-15T22:31:48.940743Z",
            budgetOverride: false,
            income: true,
            locked: false,
            completed: false,
            accountTrackingId: "dd7fe6e0-e0bd-42b7-ae65-103527616900",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "dc06daa1-fd48-42dc-add6-4b5a311aca25",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.01 Polished Concrete",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.989139Z",
            endDate: "2022-03-15T22:31:48.989139Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "bf9dd867-b72e-4925-89ca-6ab77c6da508",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "ab8475ea-0584-4148-96a9-971c4cf534e8",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.03 Insulation",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.036151Z",
            endDate: "2022-03-15T22:31:49.036151Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "1c0a9aa0-1d8c-4430-a0fd-5ff1e7c2b3a9",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "54cc47c3-f0f9-4523-81d9-6c77bf8b9440",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.04 Plastering",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.190151Z",
            endDate: "2022-03-15T22:31:49.190151Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "9b0fdfe7-7387-44ac-805b-38cf510307d8",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "63f6e723-723e-4db8-9e1b-e03567e00e50",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.05 Fixing Material",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.228173Z",
            endDate: "2022-03-15T22:31:49.228173Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "69716ce1-c259-4bb0-9e0a-60e04ca8b7fe",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "1f3ba044-172d-4c9d-9bbf-e2f2117961e5",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.06 Fixing Carpenter",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.274151Z",
            endDate: "2022-03-15T22:31:49.274151Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "1d270b4e-d38e-40e8-8cba-2522140b047b",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "79e4834f-642b-4a60-858b-ad81ed3a2c64",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.07 Cabinetry",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.321443Z",
            endDate: "2022-03-15T22:31:49.321443Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "f52d7c6c-2e5a-411e-9ad7-7053a3efff37",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "68b3c208-2130-42cc-9c99-0c139a9e7a35",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.08 Staircase",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.368479Z",
            endDate: "2022-03-15T22:31:49.368479Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "3d67dd16-2697-4e8f-a893-3b0cae333cae",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "68b9ef94-9d96-4f6b-872d-237c3bf5cecc",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.09 Staircase Balustrading",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.417498Z",
            endDate: "2022-03-15T22:31:49.417498Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "6ae31b85-744d-4fca-a32d-e36e448ce481",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "6d8d5fde-15aa-4dca-806c-d5f644d85016",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.10 Waterproofing",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.464890Z",
            endDate: "2022-03-15T22:31:49.464890Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "8d7370ba-dca6-470d-b16b-49ced3e7bbbd",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "52469f92-93ec-422f-ab12-6605ce70ae97",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.11 Tiling",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.511453Z",
            endDate: "2022-03-15T22:31:49.511453Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "baec1b47-1ae6-44ba-be96-4d1f1f42dd88",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "51b50dad-9a78-48f9-99d5-8bb3ea6d662c",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.12 Painting",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.557600Z",
            endDate: "2022-03-15T22:31:49.557600Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "2030885a-0248-49ef-bc8c-5b8706ed0fea",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "c42dd44a-245e-48c0-b260-88ae2eb0a3bb",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.13 Stone Benchtop",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.605788Z",
            endDate: "2022-03-15T22:31:49.605788Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "f2210833-08c4-44ea-986b-a1a3e6759ba3",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "cd3f923b-ab20-4bf9-8ee2-04b480aff097",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.14 Splashback",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.644786Z",
            endDate: "2022-03-15T22:31:49.644786Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "1137ce8f-d1d0-4d9d-b4d2-eb328eb72678",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "5b1a61dd-7419-496b-9959-e2f90bb6c1c6",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.15 Landscape Levelling",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.682787Z",
            endDate: "2022-03-15T22:31:49.682787Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "55a9ddac-a078-4468-b6a8-04b3e8887cef",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "d0d4c964-dce8-4e8f-810c-b9e1e351bc26",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.16 Decking",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.730787Z",
            endDate: "2022-03-15T22:31:49.730787Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "90f31003-e763-47fc-a4cb-8a3767792a0c",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "03e41fd8-5791-42c8-8ccc-9b03c0035647",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.17 Porous Paving",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.777844Z",
            endDate: "2022-03-15T22:31:49.777844Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "caa889ca-72f8-43be-b2e9-624fdba95abd",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "b13025e6-6290-488a-be76-96c794168fac",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.98 Fixing Contingency",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.825561Z",
            endDate: "2022-03-15T22:31:49.825561Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "08183a03-6fef-4c29-9a55-a56631de685e",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "70fee567-6092-4c96-9633-bed81c3b6177",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.99 Fixing Claim",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.872603Z",
            endDate: "2022-03-15T22:31:49.872603Z",
            budgetOverride: false,
            income: true,
            locked: false,
            completed: false,
            accountTrackingId: "6e882c72-9a17-496c-ad7a-5a381cdb8c57",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "09971818-b631-4d9e-b9c4-c93d4c026124",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.01 Services",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.920920Z",
            endDate: "2022-03-15T22:31:49.920920Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "017fa993-83b7-49d4-858f-95c1dfd3cf17",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "554cee7d-5548-4586-b256-d0222d120d9e",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.03 Driveway",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.966971Z",
            endDate: "2022-03-15T22:31:49.966971Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "b96e6396-b7e3-4bd3-8846-219fa4a67e2c",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "1900b240-57e2-40eb-a0a7-3d1730fe33b5",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.05 Garden Sheds",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.014410Z",
            endDate: "2022-03-15T22:31:50.014410Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "2ccd0e79-71ac-4b21-be0c-dec80565e7a3",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "828c8fb3-9bfa-4d32-8cbe-c08c3864230c",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.06 Shower Screens",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.060753Z",
            endDate: "2022-03-15T22:31:50.060753Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "e09c9765-fc76-4bfe-b92f-01816db5b361",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "6e78147c-078c-481b-a8dd-87c851a95590",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.07 Mirrors",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.107801Z",
            endDate: "2022-03-15T22:31:50.107801Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "ff6fd557-abc1-4192-b4db-a7f8216e6a24",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "8cec3746-463d-4b5d-bea8-0499f0da30d7",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.08 Garage Doors",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.156037Z",
            endDate: "2022-03-15T22:31:50.156037Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "79699ae0-0859-4727-8d17-2c97b435f13a",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "e3e84add-9405-4c56-a5a6-97cc25fc5b60",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.09 External Balustrading",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.203091Z",
            endDate: "2022-03-15T22:31:50.203091Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "45abdf0b-9c4d-4802-b47f-1a76c5b0ab9e",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "3c324d65-ead1-4d4c-a05d-e860b98bd9e1",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.10 Fitoff",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.242092Z",
            endDate: "2022-03-15T22:31:50.242092Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "6bb03621-5c89-4407-aee6-bb73a7ab20bc",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "10392d96-f8c0-479b-b332-7cde2da50d25",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.11 Bathroom Fixtures",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.280657Z",
            endDate: "2022-03-15T22:31:50.280657Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "d9de8ce3-0f33-44e4-bd40-f3fc61b2bbff",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "d14b4c19-b0d5-48c2-a988-bb214fbc5899",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.12 Lighting",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.328423Z",
            endDate: "2022-03-15T22:31:50.328423Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "702f0657-0c8f-414b-bc5d-916a87f9b548",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "4b35832d-f2a5-40a1-82de-f913dfb6b75b",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.13 Timber Flooring",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.374446Z",
            endDate: "2022-03-15T22:31:50.374446Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "f2efdca3-65b0-4542-b0b3-2b33fbb64e3e",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "4ae78c0e-eb70-4abe-918a-9a2efefe64fc",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.14 Carpet",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.412445Z",
            endDate: "2022-03-15T22:31:50.412445Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "72385c16-feea-43ec-8ddc-332e1426bef9",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "a82e91d0-0387-49a0-95f0-c4958b623052",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.15 Fencing",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.450445Z",
            endDate: "2022-03-15T22:31:50.450445Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "84371d6e-e8ef-4dc3-bc72-d3fa6c4bf519",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "f18bee37-4780-42f3-931e-0cc295a65608",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.17 Landscaping",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.488445Z",
            endDate: "2022-03-15T22:31:50.489445Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "4d0c0464-286b-4293-9fb9-1ba75775599f",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "d179a2cf-ce91-44af-aeef-8227fa014921",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.19 Flyscreens",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.638445Z",
            endDate: "2022-03-15T22:31:50.638445Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "6d5df8f8-2a71-4243-8efd-7331d8ef5bd5",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "4d1228cf-f379-4872-88f7-27cbe81de461",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.20 Solar Hot Water",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.676445Z",
            endDate: "2022-03-15T22:31:50.676445Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "ad4fbd73-d902-430e-8a5e-77f07244f5a4",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "1f33cbb0-2a0b-4249-8caa-9d6369115656",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.21 Water Tanks",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.714445Z",
            endDate: "2022-03-15T22:31:50.715446Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "fe421728-be65-40d8-9e76-93fd0df761d2",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "341f1dac-83c6-4dd2-8712-1629fb2c9fb7",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.22 Clothesline",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.754445Z",
            endDate: "2022-03-15T22:31:50.754445Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "c965e26c-cf35-406b-a69a-2117e341fc2d",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "e7f4788d-579d-48fb-9d0a-cdfb4b2c40a9",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.23 Vehicle Crossing",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.802679Z",
            endDate: "2022-03-15T22:31:50.802679Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "30146990-1d8d-4814-9c1e-d1ee49f671ab",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "b4ffbbd6-02c8-4190-a2f1-dc9898b8a4fe",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.24 Site Clean",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.848692Z",
            endDate: "2022-03-15T22:31:50.848692Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "85e0b272-c246-4e08-a1af-8146d87c0fa9",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "a2703759-7d1c-4719-9364-03a792d40703",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.25 Letterbox",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.896116Z",
            endDate: "2022-03-15T22:31:50.896116Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "6347c660-a332-48d2-9b75-55cbfa19af95",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "e5357961-50ee-44c1-b813-4348df4e420f",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.26 Driveway Seal",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.943128Z",
            endDate: "2022-03-15T22:31:50.943128Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "fb20d801-f479-429e-ac4e-6cc2b67e369f",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "d37b2979-43a7-419b-9a38-274dcab8b673",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.27 Appliances",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.991600Z",
            endDate: "2022-03-15T22:31:50.991600Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "6c6c6fc9-dbb0-4cb8-b331-43765aacecb4",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "c0d3c85e-a3e3-4a7b-81ed-ea8ee211f649",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.28 Caulking",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.038885Z",
            endDate: "2022-03-15T22:31:51.038885Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "d3e521c9-e993-49b1-b787-c8750b74b8dd",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "1fd314b6-34ba-4600-aeee-edb7b5d529e7",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.29 Swimming Pool",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.085877Z",
            endDate: "2022-03-15T22:31:51.085877Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "fb55c0c8-37b0-4724-bf77-0d4dbe8c58ec",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "9b5de43c-ef1f-4493-aaf6-32a6bd6e93c1",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.30 Spa",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.130388Z",
            endDate: "2022-03-15T22:31:51.130388Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "348eeac6-b289-4dd0-bac7-386567c0ac3f",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "44c171d4-7e03-49d6-809c-fd01ba4282b4",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.31 Sauna",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.179063Z",
            endDate: "2022-03-15T22:31:51.179063Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "d82debc0-ee18-443b-9823-d8253b124517",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "fd43f432-39c2-4f9a-83f8-909f8b41ac79",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.32 CCTV",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.225755Z",
            endDate: "2022-03-15T22:31:51.225755Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "d3f012ed-b090-4e4f-8d3e-1470a550ded8",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "cde29ec7-45be-425f-8cc1-0000123d4ba4",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.33 Project Management",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.272755Z",
            endDate: "2022-03-15T22:31:51.272755Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "08e96993-de8a-44c9-9c3b-d473641002f6",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "2a9a04cf-47b3-4e01-b3d8-5f5de05b7bee",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.34 Commission",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.318755Z",
            endDate: "2022-03-15T22:31:51.318755Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "ac1b7c82-2519-44e9-852e-c946312be103",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "8ce37465-07bb-4389-a9a6-bc3ffa151404",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.35 Maintenances",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.366436Z",
            endDate: "2022-03-15T22:31:51.366436Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "4e63c823-9250-403f-92f1-5d7edea939ed",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "6e5ed5c7-0b3a-42ac-a95d-907f8b8141ea",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.98 Final Miscellaneous",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.413474Z",
            endDate: "2022-03-15T22:31:51.413474Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "3eef37c2-f20b-423c-bbfc-f0b023cc63bc",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "0194e425-4aad-4a7e-bee6-91f366ad4dd3",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.99 Final Claim",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.463937Z",
            endDate: "2022-03-15T22:31:51.463937Z",
            budgetOverride: false,
            income: true,
            locked: false,
            completed: false,
            accountTrackingId: "0a84d8e8-cf10-4e00-b1eb-42f75c75b817",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "40f4e69d-c35c-4c0e-bc4a-770fa7a11de9",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "7.01 Window Furnishings",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.508952Z",
            endDate: "2022-03-15T22:31:51.508952Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "3a552174-d8c3-46f1-9e96-a4e1fe8abcc8",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "bf8f6399-fb3c-424f-b22b-6627afc01f64",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "7.02 Interior Stylist",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.547950Z",
            endDate: "2022-03-15T22:31:51.547950Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "6dae14f7-d49a-4e63-b912-0c435bb2f283",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "ae62ab24-eb04-4328-841a-154821f20523",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "7.03 Furniture",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.587951Z",
            endDate: "2022-03-15T22:31:51.587951Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "a09fc581-4e94-4557-a04c-eb723f55bc0f",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "1d6c0412-2b58-4980-9088-f147b5a42dda",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "7.04 Photographer",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.634117Z",
            endDate: "2022-03-15T22:31:51.634117Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "e5683645-2b8b-4c2e-927e-ebc5c0a76b5a",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "4b683b26-1b9d-4478-8e6e-c8611a29e7a8",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "7.05 Videographer",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.681515Z",
            endDate: "2022-03-15T22:31:51.681515Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "2bbe3c65-735c-4eb8-b172-1f765c489e48",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "ba3d048b-620c-4cb8-9501-b1c736f7eb76",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "7.06 Marketing",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.720512Z",
            endDate: "2022-03-15T22:31:51.720512Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "6ae21d9d-be91-4e6e-a306-b95ec55d1b2e",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "e207663f-ccdb-401c-8f9e-b6817e4dee88",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "7.07 Open Space Contribution",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.760150Z",
            endDate: "2022-03-15T22:31:51.760150Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "26679f23-3e74-4ddc-ba4f-916f152da825",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "0ee06d4e-b97e-4c93-9e37-5615a311e150",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "7.08 Subdivision",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.799152Z",
            endDate: "2022-03-15T22:31:51.799152Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "94112ff4-7dc8-4be8-965c-d8775985ddb7",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "b3efe2a8-e024-4514-b21b-092741ae5d58",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "7.09 Strata",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.839151Z",
            endDate: "2022-03-15T22:31:51.839151Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "84fed365-4d5e-4b7c-8de9-199f86aea35a",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "8c7f3053-4988-4575-a86f-868e30c87b00",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "7.10 Service Connections",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.886150Z",
            endDate: "2022-03-15T22:31:51.886150Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "99dc78c7-5d1a-422f-bd52-b344a4e7b31c",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "6646c94d-d49a-47ad-9e49-e948a7c03ba1",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "7.11 Holding Costs",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.926150Z",
            endDate: "2022-03-15T22:31:51.926150Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "05600b9e-1de2-47f8-ad62-be5fd94d3378",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "0b65999e-ed2b-46aa-afd3-66de32c9131c",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "7.12 Finance Costs",
            budget: "0.00",
            startDate: "2022-03-15T22:31:51.965150Z",
            endDate: "2022-03-15T22:31:51.965150Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "9ac8f746-44ee-4595-81ea-c02ec16d646d",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "d3f61555-4d62-42e5-8e9b-a3c47170904f",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "7.13 Developer Contingency",
            budget: "0.00",
            startDate: "2022-03-15T22:31:52.003150Z",
            endDate: "2022-03-15T22:31:52.003150Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "d874f6ea-a222-49f7-a512-f99980fcec9f",
            assignedUsers: [],
            status: "D"
        }
    ]
}

const costCodeListResponse = {
    count: 139,
    next: "http://192.168.1.107:8000/costcodes/?ordering=title&page=2&project=15eea90e-5e20-4e68-8a76-6f72012d240c",
    previous: null,
    results: [
        {
            costCodeId: "ef43a84e-c54d-45e9-9177-491128f1b116",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.01 Preliminaries",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.556890Z",
            endDate: "2022-03-15T22:31:45.556890Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "525f8095-4f5c-4bd5-ac12-48451c4bc496",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "a22c3e35-c327-46fd-a5ac-aaf9448c990a",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.02 Council Permits",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.595890Z",
            endDate: "2022-03-15T22:31:45.595890Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "e50e112e-22b3-4c67-83fd-eeeaa216a0d4",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "0f7d9b8a-289f-4d21-acf9-ae08e0ba6f09",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.03 Protection Works",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.642891Z",
            endDate: "2022-03-15T22:31:45.642891Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "065ca246-3b35-4abb-b5a5-501a242e04f9",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "acbc0be5-1fb8-4905-ac40-cae9704a2caf",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.04 Telstra",
            budget: "1272.73",
            startDate: "2022-03-15T22:31:45.688920Z",
            endDate: "2022-03-15T22:31:45.688920Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "84b8641b-8446-4c00-9f0f-bafc7f2d272c",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "7bbe7197-69ab-478b-871c-4a520210e24c",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.05 Electricity Pit",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.726914Z",
            endDate: "2022-03-15T22:31:45.726914Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "bb7a588b-80ef-4e38-9114-6cf8c8ebef4f",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "e3f7cae9-5fd9-43b6-9cc7-4eb46ceefa02",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.06 Water Tapping",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.767913Z",
            endDate: "2022-03-15T22:31:45.767913Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "af226212-d36f-4b22-8dc1-b885220fd103",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "12951c14-b7ba-4234-9c3e-5452601ec56f",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.07 Temp Fencing",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.806912Z",
            endDate: "2022-03-15T22:31:45.806912Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "34900dd3-18be-44e7-aa54-51f5c6b15417",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "7b5d954e-c481-41f2-bf87-a41c96625a1c",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.08 Hoarding",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.845912Z",
            endDate: "2022-03-15T22:31:45.845912Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "fce3d05a-2a95-4f22-a227-6c05069b2938",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "7a949341-4611-45a6-bd34-4aee227e2b41",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.09 Temp Toilet",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.894535Z",
            endDate: "2022-03-15T22:31:45.894535Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "db481fb5-630f-45f5-b107-4cdf3bef0220",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "91248ad1-8895-49bb-b8cc-c9304e5cc577",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.10 Temp Crossing",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.956538Z",
            endDate: "2022-03-15T22:31:45.956538Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "38cc9e76-e998-416f-bdcf-84c9a09c45a9",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "1b112c1d-5ffa-41a3-9a21-e4750ce7c6ea",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.12 Temp Rubbish Cage",
            budget: "0.00",
            startDate: "2022-03-15T22:31:45.993534Z",
            endDate: "2022-03-15T22:31:45.993534Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "46655960-3211-4980-80fd-fdb10ffe9838",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "7d29a4f1-bbcf-495c-b6d0-e28b602cbd81",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.13 Demolition",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.034025Z",
            endDate: "2022-03-15T22:31:46.034025Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "ffe48d96-0d09-4701-b73f-aed9ce3a00ab",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "3699a436-608c-4c04-9d36-abb46190ecbd",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.14 Temporary Power",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.083311Z",
            endDate: "2022-03-15T22:31:46.083311Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "347eb75c-f383-45f2-b396-dc57ac900d39",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "8f25abdf-23d6-4bda-8f25-39f2dc44ead4",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.15 Warranty Insurance",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.128513Z",
            endDate: "2022-03-15T22:31:46.128513Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "257a79fe-bab0-48e5-a234-c36bd9de44d4",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "745dffd2-ad39-4c8e-9275-9848dc4400f4",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.16 Building Permit",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.174545Z",
            endDate: "2022-03-15T22:31:46.174545Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "858a1192-7cc9-4b3c-ac50-95dcfc4d74b4",
            assignedUsers: [],
            status: "I"
        },
        {
            costCodeId: "ffad8466-0816-4ea0-b09e-5402acc41559",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.98 Deposit Contingency",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.221519Z",
            endDate: "2022-03-15T22:31:46.221519Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "bba835bf-66cb-4038-ab59-ea840a7d7a0b",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "13bb3f9c-65f2-4847-b95c-79139b73949d",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "1.99 Deposit Claim",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.267604Z",
            endDate: "2022-03-15T22:31:46.267604Z",
            budgetOverride: false,
            income: true,
            locked: false,
            completed: false,
            accountTrackingId: "452e1acb-467e-48b9-8b30-60849f1ea160",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "f9d8a634-46c0-49fb-962b-ffca8de54d88",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.01 Setout",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.312924Z",
            endDate: "2022-03-15T22:31:46.312924Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "a40d114e-399f-4ad7-b368-a35320503c19",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "d0327d04-eb99-4abb-be81-6e5c0f12c528",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.02 Site Prep",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.362130Z",
            endDate: "2022-03-15T22:31:46.362130Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "21591ee2-b5dc-402f-850c-155a48dc09db",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "0b7ee14f-12f6-4f41-8049-d5a208f636e3",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.05 Shoring & Underpinning",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.408203Z",
            endDate: "2022-03-15T22:31:46.408203Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "5d31ac37-cfd1-4f1e-9c10-fecec451b771",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "42d2d06b-c7bd-4abd-bec2-938ec1766312",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.06 Piling",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.455204Z",
            endDate: "2022-03-15T22:31:46.455204Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "52d31f1b-968a-436d-9b66-d5701b92a123",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "4fa5cb0d-177e-4efc-af49-2ef953785335",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.07 Basement",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.502204Z",
            endDate: "2022-03-15T22:31:46.502204Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "0478425b-3bb3-4a6a-ad11-2bb46f58c4ae",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "84693904-c5a0-442d-8c53-2a87a9738587",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.08 Retaining Wall",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.549205Z",
            endDate: "2022-03-15T22:31:46.549205Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "8183b490-1233-4d31-8081-0a87ea9685ed",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "d95a8ee3-076d-4e7b-bce0-76a06c180d0e",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.09 Underslab Insulation",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.597687Z",
            endDate: "2022-03-15T22:31:46.597687Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "da1b6744-64b1-421a-bf1f-ca34e614fda1",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "f9ec8630-025c-4f2e-896c-4d6238b5cbf9",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.10 Hydronic Heating",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.643710Z",
            endDate: "2022-03-15T22:31:46.643710Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "b6543b1f-8089-4219-bdd1-abcb8808c201",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "4ae7ce31-305c-4a55-8256-31afb0135758",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.11 Drainage",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.691711Z",
            endDate: "2022-03-15T22:31:46.691711Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "f5360b29-a2f5-49bf-8369-60cc3826641c",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "08eae724-4aa9-4f61-86d3-3299609ec564",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.12 Concrete Reinforcement",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.737711Z",
            endDate: "2022-03-15T22:31:46.737711Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "d0f58350-66ec-44e9-a396-f82b1c0de9cd",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "07f1cfc3-ed3b-4402-ace2-ba7eb4d39f46",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.13 Termite Collars",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.775711Z",
            endDate: "2022-03-15T22:31:46.775711Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "2075fdb1-1415-4e97-aab7-1cc4a3236a3e",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "ac867c36-b1f1-4b24-8734-3a7961e9a20d",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.14 Electricity Underground",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.816726Z",
            endDate: "2022-03-15T22:31:46.816726Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "45d4605b-ff4e-4e0b-a6a1-fef9e0fbf68d",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "8c9b0e96-2354-4b8b-bb57-72ac043329aa",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.15 Concrete Pump",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.864186Z",
            endDate: "2022-03-15T22:31:46.864186Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "86300079-be8a-452d-865d-ec6377620531",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "24b671ff-ffb3-43d3-97f1-29721ed987da",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.16 Concrete Material",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.911734Z",
            endDate: "2022-03-15T22:31:46.911734Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "05761ba1-89d6-4e38-9ed4-960eb440f0fc",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "879d27d7-72a0-49e2-9c6b-0112dd3d4b5e",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.17 Concrete Labour",
            budget: "0.00",
            startDate: "2022-03-15T22:31:46.958732Z",
            endDate: "2022-03-15T22:31:46.958732Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "632e9bf1-7330-4daa-98bb-b77f0e5d05bb",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "738dbe09-01a6-4c4a-a579-223a1cca4182",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.98 Concrete Contingency",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.006732Z",
            endDate: "2022-03-15T22:31:47.006732Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "72af42a2-acb5-4070-82f6-b96e6d513796",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "41d7e359-c036-45a8-865a-2096f8a5452f",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "2.99 Concrete Claim",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.044733Z",
            endDate: "2022-03-15T22:31:47.044733Z",
            budgetOverride: false,
            income: true,
            locked: false,
            completed: false,
            accountTrackingId: "45d8dcee-a4a8-4792-b693-61c0458c358a",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "7c84ac07-4402-4475-86b5-9beefd9a973f",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.04 Termite Sheet",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.084733Z",
            endDate: "2022-03-15T22:31:47.084733Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "41eed2ef-0a0a-44f9-b1f6-46f00e745301",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "f1d1ac73-9fce-4558-9713-5385095ab7f9",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.05 Ground Floor Frames",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.133318Z",
            endDate: "2022-03-15T22:31:47.133318Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "025971da-4c75-437b-b4ff-730c4b08af48",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "b4750340-0899-40be-b1f5-f8625799e1e3",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.06 Structural Steel",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.180347Z",
            endDate: "2022-03-15T22:31:47.180347Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "450b2858-8781-4a60-a107-dbed78752858",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "c13c0d8d-b83c-4cad-9e82-ef73b37c54d4",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.07 Party Walls",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.227499Z",
            endDate: "2022-03-15T22:31:47.227499Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "8045fc10-31bd-40a2-a977-237dda00da69",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "43de46d2-e075-4f6c-b2ae-b24083ac30b5",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.08 Floor Trusses",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.273532Z",
            endDate: "2022-03-15T22:31:47.273532Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "d23df88f-4311-4c07-9803-5c830a2ef56a",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "03bc2ba7-af0f-447e-9cd1-9d2bdf739018",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.09 Floor Beams",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.321819Z",
            endDate: "2022-03-15T22:31:47.321819Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "b85abac1-244e-4dce-9a50-79fb90183b19",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "5a5aa09d-730d-4222-9dcd-1f27172612fd",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.10 Sheet Flooring",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.368512Z",
            endDate: "2022-03-15T22:31:47.368512Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "310ad967-b75c-4acd-b6ce-7a302fa04daa",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "340ad447-ff77-4a6e-b432-0985af96ce02",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.11 Void Protection",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.414789Z",
            endDate: "2022-03-15T22:31:47.414789Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "eb6e41e4-3a2c-4379-a902-d025f5da8695",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "5d6e2307-a8ef-456d-b50f-28abbb1561ba",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.12 First Floor Frames",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.461587Z",
            endDate: "2022-03-15T22:31:47.461587Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "f3222f91-de8d-4cc9-b249-972891221265",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "2c885ebc-64a2-4de1-a189-642dd7b7e58a",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.13 Trusses",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.508586Z",
            endDate: "2022-03-15T22:31:47.508586Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "a80a73d2-8d67-48ac-8cd8-8f21f3f24c6d",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "267b1222-aa67-4aa5-9316-6faabdaed3b3",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.14 Frame Carpenter",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.546586Z",
            endDate: "2022-03-15T22:31:47.546586Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "13871f81-c618-41ac-bf8d-02df10bb249b",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "53d22d54-7099-4124-a8e8-1c5e464a545c",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.98 Frame Contingency",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.587586Z",
            endDate: "2022-03-15T22:31:47.587586Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "edb1c6d0-b7bb-4297-b9ab-312da0a45c74",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "8a240431-cc8b-4866-84b7-d331af8daf49",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "3.99 Frame Claim",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.624585Z",
            endDate: "2022-03-15T22:31:47.624585Z",
            budgetOverride: false,
            income: true,
            locked: false,
            completed: false,
            accountTrackingId: "3e206690-07c4-496b-b4b7-fcd90cd62a27",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "75d8f609-ad70-43bb-9b7a-5754ff8d4cc0",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.02 Windows",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.665585Z",
            endDate: "2022-03-15T22:31:47.665585Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "36c101b7-d8a4-41f5-9ffa-f3eedfc8530c",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "d6d6e1ac-d0fa-405d-abdb-4b5385eb31f7",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.03 Window Louvres",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.713585Z",
            endDate: "2022-03-15T22:31:47.713585Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "d25c82ac-75e4-4ee3-b282-419ee2f1a7c5",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "022e0a23-c7f6-4853-91d6-cead55c09f27",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.04 Skylight",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.751588Z",
            endDate: "2022-03-15T22:31:47.751588Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "6e434bb4-d978-42db-a191-3a0de767af64",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "64ba80e2-162c-4352-9f5f-9fa78e774d48",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.05 Two Plank Scaffold",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.789585Z",
            endDate: "2022-03-15T22:31:47.789585Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "5b9d5ed8-39c9-4490-a62f-19fbf76c9ac1",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "225429e7-a68d-4ab0-b51a-1b93fb0b6f8a",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.06 Fascia Gutter",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.826585Z",
            endDate: "2022-03-15T22:31:47.826585Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "c66eaf4d-8f64-4ac9-a23c-5dda61603214",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "318835e1-4787-4c30-9e5c-1d08d3c9131e",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.07 Roof Tiling",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.872611Z",
            endDate: "2022-03-15T22:31:47.872611Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "0faea234-44ed-4156-9bbe-65603c583716",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "788a82a9-c510-4daa-9661-73df0c699618",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.08 Eaves",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.921235Z",
            endDate: "2022-03-15T22:31:47.921235Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "60571df1-79df-4713-b8d8-60a1df8684f8",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "55fa1c29-eb9b-4445-816f-abd122d0e44b",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.09 Roof Plumbing",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.958234Z",
            endDate: "2022-03-15T22:31:47.958234Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "2b0f82cf-b785-4ba2-a0b0-b76f8d339db0",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "f618c63b-257d-4858-8bbf-5b7f227f1236",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.10 Sisilation",
            budget: "0.00",
            startDate: "2022-03-15T22:31:47.998234Z",
            endDate: "2022-03-15T22:31:47.998234Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "9773c32d-ef60-4968-b94b-200246f23087",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "427afe8e-d738-44de-b674-22ba7208fa73",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.11 Ground Up Scaffolding",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.045234Z",
            endDate: "2022-03-15T22:31:48.045234Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "df15c81c-2129-48a5-bb76-72f0e1f4f066",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "2c4fc21b-5598-459c-b834-1407afd7d892",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.12 First Floor Cladding",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.093234Z",
            endDate: "2022-03-15T22:31:48.093234Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "9df6f69f-163c-4a3f-bae5-fa6a27aef6af",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "5a685941-240a-4205-abe3-fb42e3ee6bc5",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.13 Ground Floor Cladding",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.142235Z",
            endDate: "2022-03-15T22:31:48.142235Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "8f74028d-a69e-4237-8ece-2dc61eb93d6d",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "036b64b2-cd8e-4869-9cf3-e32d3e17bc2a",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.14 Heating & Cooling",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.188234Z",
            endDate: "2022-03-15T22:31:48.188234Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "7ea70573-72d1-4d71-91ce-7986f18701bb",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "35fdc3ef-7036-402d-95bf-ff42ac08bc34",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.15 General Plumbing",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.233235Z",
            endDate: "2022-03-15T22:31:48.233235Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "9f9e1fab-7be1-4e43-965a-39c09475561f",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "6ad020a4-ad15-452f-bd2b-41b1bc04acfb",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.17 Cameras",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.271234Z",
            endDate: "2022-03-15T22:31:48.271234Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "9c5d1dec-33de-4e45-94e8-28f00927b10d",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "c1a6b694-3caa-4c02-8b2d-82fe2f9220c5",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.18 Alarms",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.313236Z",
            endDate: "2022-03-15T22:31:48.313236Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "870fed64-2ea2-430f-a318-f4a344408355",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "ee53e32d-f57f-4b9a-974b-a1c1961f5030",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.19 Video Intercom",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.351235Z",
            endDate: "2022-03-15T22:31:48.351235Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "8887206c-23a6-4df2-9cdf-bf4a86b904c3",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "709a9315-0896-4b88-b412-a9b8ec304008",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.20 Ducted Vacuum",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.391514Z",
            endDate: "2022-03-15T22:31:48.391514Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "8b221646-3f04-41df-a004-922e926652cb",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "e559b607-d7ae-4d44-8f88-96051365ca57",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.21 Fire Place",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.439841Z",
            endDate: "2022-03-15T22:31:48.439841Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "2c764ae3-6d5d-4de8-a446-b48aa34e238f",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "8434cc59-7eb7-4675-987d-158d1c4973b4",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.22 Electrical",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.486839Z",
            endDate: "2022-03-15T22:31:48.486839Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "1a68eb1a-c525-4563-92c2-a70f81e40d5e",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "19053669-dd66-4a2e-a6e4-1f26915e3d2a",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.23 Lock Up Material",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.532839Z",
            endDate: "2022-03-15T22:31:48.532839Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "6e29e2b3-cef1-482a-a374-c67059764412",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "443c079e-068e-424a-a86f-2f49c0191b15",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.24 Lock Up Carpenter",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.580847Z",
            endDate: "2022-03-15T22:31:48.580847Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "80a40556-b956-4e83-9435-771a2f3096d1",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "cd78302b-d7a7-4511-b541-c440f80d4670",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.25 Infill Slabs",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.626839Z",
            endDate: "2022-03-15T22:31:48.626839Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "8eac20d6-a2bf-4c85-9141-5732ad8ebd47",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "06f2f818-14c9-4e1e-93e9-9b6bca2eaecf",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.26 Fire Rating",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.675258Z",
            endDate: "2022-03-15T22:31:48.675258Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "ffe9c87f-96ee-4b06-989a-d51904af9ceb",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "cb22a185-21ce-4da0-9295-a3ca342a5ddc",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.27 Brick Cleaning",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.721767Z",
            endDate: "2022-03-15T22:31:48.721767Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "0d19349f-ca4e-4163-b14d-dfe838204805",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "9e2adb40-d494-4bf4-b029-106f66ff692b",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.28 Crossover Permit",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.759766Z",
            endDate: "2022-03-15T22:31:48.759766Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "2fd494d2-9f89-413d-bf2f-97b4489d71cd",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "c86cc94b-903f-45e3-91df-425fa968cbed",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.98 Lock Up Contingency",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.902734Z",
            endDate: "2022-03-15T22:31:48.902734Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "23393d61-b675-40c1-a850-c84aefbb1c74",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "15e373eb-a850-4a1d-bb3b-6a2eacaabe0f",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "4.99 Lock Up Claim",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.940743Z",
            endDate: "2022-03-15T22:31:48.940743Z",
            budgetOverride: false,
            income: true,
            locked: false,
            completed: false,
            accountTrackingId: "dd7fe6e0-e0bd-42b7-ae65-103527616900",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "dc06daa1-fd48-42dc-add6-4b5a311aca25",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.01 Polished Concrete",
            budget: "0.00",
            startDate: "2022-03-15T22:31:48.989139Z",
            endDate: "2022-03-15T22:31:48.989139Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "bf9dd867-b72e-4925-89ca-6ab77c6da508",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "ab8475ea-0584-4148-96a9-971c4cf534e8",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.03 Insulation",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.036151Z",
            endDate: "2022-03-15T22:31:49.036151Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "1c0a9aa0-1d8c-4430-a0fd-5ff1e7c2b3a9",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "54cc47c3-f0f9-4523-81d9-6c77bf8b9440",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.04 Plastering",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.190151Z",
            endDate: "2022-03-15T22:31:49.190151Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "9b0fdfe7-7387-44ac-805b-38cf510307d8",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "63f6e723-723e-4db8-9e1b-e03567e00e50",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.05 Fixing Material",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.228173Z",
            endDate: "2022-03-15T22:31:49.228173Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "69716ce1-c259-4bb0-9e0a-60e04ca8b7fe",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "1f3ba044-172d-4c9d-9bbf-e2f2117961e5",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.06 Fixing Carpenter",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.274151Z",
            endDate: "2022-03-15T22:31:49.274151Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "1d270b4e-d38e-40e8-8cba-2522140b047b",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "79e4834f-642b-4a60-858b-ad81ed3a2c64",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.07 Cabinetry",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.321443Z",
            endDate: "2022-03-15T22:31:49.321443Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "f52d7c6c-2e5a-411e-9ad7-7053a3efff37",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "68b3c208-2130-42cc-9c99-0c139a9e7a35",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.08 Staircase",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.368479Z",
            endDate: "2022-03-15T22:31:49.368479Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "3d67dd16-2697-4e8f-a893-3b0cae333cae",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "68b9ef94-9d96-4f6b-872d-237c3bf5cecc",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.09 Staircase Balustrading",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.417498Z",
            endDate: "2022-03-15T22:31:49.417498Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "6ae31b85-744d-4fca-a32d-e36e448ce481",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "6d8d5fde-15aa-4dca-806c-d5f644d85016",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.10 Waterproofing",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.464890Z",
            endDate: "2022-03-15T22:31:49.464890Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "8d7370ba-dca6-470d-b16b-49ced3e7bbbd",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "52469f92-93ec-422f-ab12-6605ce70ae97",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.11 Tiling",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.511453Z",
            endDate: "2022-03-15T22:31:49.511453Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "baec1b47-1ae6-44ba-be96-4d1f1f42dd88",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "51b50dad-9a78-48f9-99d5-8bb3ea6d662c",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.12 Painting",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.557600Z",
            endDate: "2022-03-15T22:31:49.557600Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "2030885a-0248-49ef-bc8c-5b8706ed0fea",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "c42dd44a-245e-48c0-b260-88ae2eb0a3bb",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.13 Stone Benchtop",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.605788Z",
            endDate: "2022-03-15T22:31:49.605788Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "f2210833-08c4-44ea-986b-a1a3e6759ba3",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "cd3f923b-ab20-4bf9-8ee2-04b480aff097",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.14 Splashback",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.644786Z",
            endDate: "2022-03-15T22:31:49.644786Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "1137ce8f-d1d0-4d9d-b4d2-eb328eb72678",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "5b1a61dd-7419-496b-9959-e2f90bb6c1c6",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.15 Landscape Levelling",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.682787Z",
            endDate: "2022-03-15T22:31:49.682787Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "55a9ddac-a078-4468-b6a8-04b3e8887cef",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "d0d4c964-dce8-4e8f-810c-b9e1e351bc26",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.16 Decking",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.730787Z",
            endDate: "2022-03-15T22:31:49.730787Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "90f31003-e763-47fc-a4cb-8a3767792a0c",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "03e41fd8-5791-42c8-8ccc-9b03c0035647",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.17 Porous Paving",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.777844Z",
            endDate: "2022-03-15T22:31:49.777844Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "caa889ca-72f8-43be-b2e9-624fdba95abd",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "b13025e6-6290-488a-be76-96c794168fac",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.98 Fixing Contingency",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.825561Z",
            endDate: "2022-03-15T22:31:49.825561Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "08183a03-6fef-4c29-9a55-a56631de685e",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "70fee567-6092-4c96-9633-bed81c3b6177",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "5.99 Fixing Claim",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.872603Z",
            endDate: "2022-03-15T22:31:49.872603Z",
            budgetOverride: false,
            income: true,
            locked: false,
            completed: false,
            accountTrackingId: "6e882c72-9a17-496c-ad7a-5a381cdb8c57",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "09971818-b631-4d9e-b9c4-c93d4c026124",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.01 Services",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.920920Z",
            endDate: "2022-03-15T22:31:49.920920Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "017fa993-83b7-49d4-858f-95c1dfd3cf17",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "554cee7d-5548-4586-b256-d0222d120d9e",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.03 Driveway",
            budget: "0.00",
            startDate: "2022-03-15T22:31:49.966971Z",
            endDate: "2022-03-15T22:31:49.966971Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "b96e6396-b7e3-4bd3-8846-219fa4a67e2c",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "1900b240-57e2-40eb-a0a7-3d1730fe33b5",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.05 Garden Sheds",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.014410Z",
            endDate: "2022-03-15T22:31:50.014410Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "2ccd0e79-71ac-4b21-be0c-dec80565e7a3",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "828c8fb3-9bfa-4d32-8cbe-c08c3864230c",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.06 Shower Screens",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.060753Z",
            endDate: "2022-03-15T22:31:50.060753Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "e09c9765-fc76-4bfe-b92f-01816db5b361",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "6e78147c-078c-481b-a8dd-87c851a95590",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.07 Mirrors",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.107801Z",
            endDate: "2022-03-15T22:31:50.107801Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "ff6fd557-abc1-4192-b4db-a7f8216e6a24",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "8cec3746-463d-4b5d-bea8-0499f0da30d7",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.08 Garage Doors",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.156037Z",
            endDate: "2022-03-15T22:31:50.156037Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "79699ae0-0859-4727-8d17-2c97b435f13a",
            assignedUsers: [],
            status: "D"
        },
        {
            costCodeId: "e3e84add-9405-4c56-a5a6-97cc25fc5b60",
            project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
            title: "6.09 External Balustrading",
            budget: "0.00",
            startDate: "2022-03-15T22:31:50.203091Z",
            endDate: "2022-03-15T22:31:50.203091Z",
            budgetOverride: false,
            income: false,
            locked: false,
            completed: false,
            accountTrackingId: "45abdf0b-9c4d-4802-b47f-1a76c5b0ab9e",
            assignedUsers: [],
            status: "D"
        }
    ]
}

const costCodeFullResponse = {
    costCodeId: "ef43a84e-c54d-45e9-9177-491128f1b116",
    project: "15eea90e-5e20-4e68-8a76-6f72012d240c",
    title: "1.01 Preliminaries",
    budget: "0.00",
    startDate: "2022-03-15T22:31:45.556890Z",
    endDate: "2022-03-15T22:31:45.556890Z",
    budgetOverride: false,
    income: false,
    locked: false,
    completed: false,
    accountTrackingId: "525f8095-4f5c-4bd5-ac12-48451c4bc496",
    assignedUsers: [],
    status: "D",
    bills: [
        {
            billId: "a3d05241-bf24-4230-b265-040ae9aa59d6",
            costCode: "ef43a84e-c54d-45e9-9177-491128f1b116",
            number: "BY_0027",
            dueDate: "2022-03-16T01:12:28.895616Z",
            issueDate: "2022-03-16T01:12:28.895616Z",
            emailSent: false,
            supplier: "supplier",
            abSupplier: null,
            accepted: false,
            subTotal: "150.00",
            tax: "15.00",
            total: "165.01",
            internalNotes: "",
            specialConditions: "",
            status: "D"
        },
        {
            billId: "e97c3d73-4c18-4740-a245-db5b3507d171",
            costCode: "ef43a84e-c54d-45e9-9177-491128f1b116",
            number: "BY_0028",
            dueDate: "2022-03-16T01:18:16.589765Z",
            issueDate: "2022-03-16T01:18:16.589765Z",
            emailSent: false,
            supplier: "SUPPLIER 2",
            abSupplier: null,
            accepted: false,
            subTotal: "250.00",
            tax: "25.00",
            total: "275.00",
            internalNotes: "Some text just for us",
            specialConditions: "Some general text, terms and conditions",
            status: "D"
        }
    ],
    comments: []
}

const billsListResponse = {
    count: 2,
    next: null,
    previous: null,
    results: [
        {
            billId: "a3d05241-bf24-4230-b265-040ae9aa59d6",
            costCode: "ef43a84e-c54d-45e9-9177-491128f1b116",
            number: "BY_0027",
            reference: "test bill",
            dueDate: "2022-03-16T01:12:28.895616Z",
            issueDate: "2022-03-16T01:12:28.895616Z",
            emailSent: false,
            supplier: "supplier",
            abSupplier: null,
            accepted: false,
            subTotal: "150.00",
            tax: "15.00",
            total: "165.01",
            internalNotes: "",
            specialConditions: "",
            status: "D",
            amount: '96000.00',
            paid: '96000.00',
            balance: '0.00',
            date: '09.02.22',
            lines: [{
                index: 0,
                description: "",
                itemCost: "",
                itemFormula: "",
                quantity: "",
                quantityFormula: "",
                ticked: false,
                unit: "ea"
            }],
        },
        {
            billId: "e97c3d73-4c18-4740-a245-db5b3507d171",
            costCode: "ef43a84e-c54d-45e9-9177-491128f1b116",
            number: "BY_0028",
            dueDate: "2022-03-16T01:18:16.589765Z",
            issueDate: "2022-03-16T01:18:16.589765Z",
            emailSent: false,
            supplier: "SUPPLIER 2",
            reference: "test bill43",
            abSupplier: null,
            accepted: false,
            subTotal: "250.00",
            tax: "25.00",
            total: "275.00",
            internalNotes: "Some text just for us",
            specialConditions: "Some general text, terms and conditions",
            status: "D",
            amount: '96000.00',
            paid: '96000.00',
            balance: '0.00',
            date: '09.02.22',
            lines: [{
                index: 0,
                description: "",
                itemCost: "",
                itemFormula: "",
                quantity: "",
                quantityFormula: "",
                ticked: false,
                unit: "ea"
            }],
        }
    ]
}

const billFullResponse = {
    billId: "a3d05241-bf24-4230-b265-040ae9aa59d6",
    costCode: "ef43a84e-c54d-45e9-9177-491128f1b116",
    number: "BY_0027",
    reference: "test bill",
    dueDate: "2022-03-16T01:12:28.895616Z",
    issueDate: "2022-03-16T01:12:28.895616Z",
    emailSent: false,
    supplier: "SUPPLIER",
    abSupplier: null,
    accepted: false,
    subTotal: "150.00",
    tax: "15.00",
    total: "165.01",
    internalNotes: "",
    specialConditions: "",
    status: "D",
    lines: [
        {
            index: 0,
            description: "Item",
            itemCost: "5.0000",
            itemFormula: "",
            quantity: "15.0000",
            quantityFormula: "",
            ticked: true,
            unit: "ea"
        },
        {
            index: 1,
            description: "Item",
            itemCost: "15.0000",
            itemFormula: "",
            quantity: "5.0000",
            quantityFormula: "",
            ticked: true,
            unit: "ea"
        }
    ],
    history: [
        {
            index: 0,
            shortText: "Bill Created",
            longText: "Bill created by .... at .....",
            timeStamp: "2022-03-16T01:12:28.900617Z",
            user: null
        }
    ]
}

function newDummyBill(bill:Bill){
    if (!bill.lines) {return}
    let subTotal: number = 0;
    for (var l =0; l < bill.lines.length; l++){
      subTotal += bill.lines[l].lineCost
    }
    bill.subTotal = subTotal;
    bill.tax = bill.subTotal * 0.1;
    bill.total = bill.subTotal + bill.tax;
  
    bill.history = [{
      index: 0,
      shortText: "Bill Created",
      longText: "New bill created for "+bill.supplier+" to the value of "+bill.total,
      timeStamp: new Date().toString(),
    }]  

    const nBill: Bill = {
        billId: "xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx",
        costCode: bill.costCode,
        number: bill.number,
        issueDate: bill.issueDate,
        emailSent: bill.emailSent,
        supplier: bill.supplier,
        accepted: bill.accepted,
        subTotal: bill.subTotal,
        tax: bill.tax,
        total: bill.total,
        internalNotes: bill.internalNotes,
        specialConditions: bill.specialConditions,
        status: bill.status,
        abSupplier: bill.abSupplier ? bill.abSupplier : "",
        lines: bill.lines,
        history: bill.history,
        reference: bill.reference,
        amount: bill.amount,
        paid: bill.paid,
        balance: bill.balance,
        startDate: null,
        endDate: null,
        paymentTerms: '14',
        paymentTermsSuffix: "Days"
    }
    return nBill
}

function newDummyCostCode(costCode:NewCostCode){
    const nCostCode: Costcode = {
        costCodeId: "xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx",
        project: costCode.project,
        title: costCode.title,
        budget: costCode.budget,
        startDate: costCode.startDate,
        endDate: costCode.endDate,
        budgetOverride: costCode.budgetOverride,
        income: costCode.income,
        locked: costCode.locked,
        accountTrackingId: "costCode.accountTrackingId",
        assignedUsers: [],
        status: costCode.status,
        completed: false,
    }
    return nCostCode
}

// function newDummyProject(project:NewProject){
//     const nProject: Project = {
//         projectId: "xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx",
//         company: project.company,
//         siteAddress: project.siteAddress,
//         contractPrice: project.contractPrice,
//         budget: project.budget,
//         startDate: project.startDate,
//         endDate: project.endDate,
//         projectPrefix: project.projectPrefix,
//         users: [],
//         projectStatus: project.projectStatus,
//
//     }
//     return nProject
// }

const dummy = {
    companyListResponse,
    companyDetailResponse,
    projectListResponse,
    projectGetResponse,
    projectFullResponse,
    costCodeListResponse,
    costCodeFullResponse,
    billsListResponse,
    billFullResponse,
    newDummyBill,
    newDummyCostCode,
    // newDummyProject
};
  
export default dummy