import React, { useEffect, useState } from "react";
import { Company } from '../../../Models/company';
import Common from "../../../Services/commonFunctions";
import Projects from "../Projects/Projects";

import useMountStatus  from '../../../utilities/Helper/checkMountStatus';
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";

interface props{
    initialiseCompany: React.Dispatch<React.SetStateAction<Company | undefined>>
}

const Companies: React.FC<props> = ({
    initialiseCompany
}) => {
    const unmounted = useMountStatus();
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn } = authContext;

    const fetchCompanies = async() => {
        try {
            const response: Company = await Common.getFirstCompany();
            if (unmounted) return;
            initialiseCompany(response);
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    useEffect(() => {
        fetchCompanies();
    },[]); //pass dependencies
    return(
        <div></div>

    )
}
export default Companies