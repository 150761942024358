import React from "react";
import pinnedIconImage from "../../../Assets/pinnedIcon.svg";
import unpinnedIconImage from "../../../Assets/Pin.svg";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

interface props {
  pinned: boolean;
  itemId?: string;
  floatRight?: boolean;
  handleClick?: Function;
  action?: string;
}

const Pin: React.FC<props> = ({
  pinned,
  itemId,
  floatRight,
  handleClick,
  action,
}) => {
  return (
    <>
      {action === "notice" ? (
        <>
          <ListItemIcon>
            <img src={pinned ? pinnedIconImage : unpinnedIconImage} alt="Pin" />
          </ListItemIcon>
          <ListItemText
            primary={pinned ? "Unpin Notice" : "Pin Notice"}
            sx={pinned? {color: "#E9121F"} : {color: "#000000"}}
          />
        </>
      ) : (
        <img
          style={floatRight ? { float: "right", cursor: "pointer" } : {}}
          src={pinned ? pinnedIconImage : unpinnedIconImage}
          alt="Pin"
          onClick={(event: any) =>
            handleClick && itemId ? handleClick(itemId, !pinned) : null
          }
        />
      )}
    </>
  );
};

export default Pin;
