import React, {useState, useEffect, useRef, MutableRefObject, ChangeEvent} from "react";
import emailIcon from "../../../Assets/emailQuotes.svg";
import { Quote } from "../../../Models/quote";
import { Bill } from "../../../Models/bill";
import CustomModal from "../../../utilities/Modal/CustomModal";
import {highlightMultilineText} from "../../../utilities/Markdown/markdown";
import {Box, Button, ButtonProps, Chip, IconButton, Input, styled, TextField} from "@mui/material";
import {ReactComponent as EmailIcon} from "../../../Assets/Email.svg";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import billAPI from "../../../Services/DoxleAPI/billAPI";
// import EditEmail from "./EditEmail";
import Files from "../Files/Files";
import DeleteEmail from "./DeleteEmail";
import ViewEmailOptions from "./ViewEmailOptions";
import EmojiIcons from "./EmojiIcons";
import AddFile from "../Files/AddFile";
import SendEmail from "./SendEmail";
import ScheduleEmail from "./ScheduleEmail";
import CloseIcon from "@mui/icons-material/Close";
import addressBookAPI from "../../../Services/DoxleAPI/addressBookAPI";
import Loading from "../../../utilities/Lottie/Loading";
import addFileIcon from "../../../Assets/paperclip.svg";
interface props{
    setDisplayEmail: Function
    quote?: Quote
    bill?: Bill
    setEmailed?: Function
    addLog?: Function
}

const Emails: React.FC<props> = ({
    setDisplayEmail,
    quote,
    bill,
    setEmailed,
    addLog
}) => {
    const { setLoggedIn, user } = useAuth() as authContextInterface;
    const [loading, setLoading] = useState<boolean>(false)
    const [from, setFrom] = useState<string>("build@byson.com.au");
    const [to, setTo] = useState<string>(quote?.supplier?.supplierEmail || "");
    const [toEmailsArray, setToEmailsArray] = useState<string[]>(quote?.supplier?.supplierEmail ? [quote.supplier.supplierEmail] : []);
    const [subject, setSubject] = useState<string>(
        (sessionStorage.getItem("siteAddress") ? sessionStorage.getItem("siteAddress")+" - " : "" )+
        (bill?.number ? "Purchase Order "+bill.number : "Quote Request")
    );
    const [body, setBody] = useState<string>(
    `${bill?.supplier ? bill.supplier+",\n\n" :""}Please find attached purchase order`+
        `${bill?.number ? ": "+bill.number+"," : ""} ${bill?.reference ? "regarding "+bill.reference : ""}`+
        `". The total value of the purchase order is ${bill?.total ? "$"+bill.total : "$0.00"}.\n\n`+
        `Kind Regards,\n${user?.firstName ? user.firstName : ""}${user?.lastName ? " "+user.lastName : ""}`

    );
    const fileRef:MutableRefObject<HTMLInputElement|undefined> = useRef()

    const handleDeleteEmail = (email: string) => {
        setToEmailsArray((prevState: string[]) => {
            const i = prevState.indexOf(email)
            prevState.splice(i, 1)
            return [...prevState]
        })
    }

    const fetchContactDetails = async (abSupplierId:string) => {
        setLoading(true)
        try {
            const supplier = await addressBookAPI.getDetailed(abSupplierId)
            if (supplier?.email && toEmailsArray.indexOf(supplier.email) === -1) setToEmailsArray(
                (prevEmails) => {return [...prevEmails, supplier.email]})
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
        setLoading(false)
    }


    const handleSubjectDialogChange = (event: any) =>{
        setSubject(event.target.value);
    }

    const handleToDialogChange = (event: any) =>{
        setTo(event.target.value);
    }

    const handleSendEmail = async () => {
        setLoading(true)
        if (await sendEmail()) setDisplayEmail(false)
        setLoading(false)
    };

    const handleClose = () => {
        setDisplayEmail(false)
    }

    const sendEmail = async () =>{
        const attachments = fileRef?.current?.files ?? undefined
        try {
            if (bill?.billId && body && toEmailsArray.length > 0) {
                try {
                    const result = await billAPI.sendEmail(
                        bill.billId, {
                            toEmails: toEmailsArray,
                            ccEmails: [],
                            emailSubject: subject,
                            emailBody: body,
                            emailAttachments: attachments
                        })
                    if (result && setEmailed) setEmailed(true)
                    if (result && addLog) addLog(result)
                }catch (err){
                    err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
                }
            } else if (quote) {
                console.log("sending for quotes")
            } else {
                console.error("could not detect bill or quote")
                return false
            }
            return true
        } catch(err){
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
            return false
        }
    }

    const style = {
        // transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const SendButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText("#3E517E"),
        backgroundColor: "#3E517E",
        marginLeft:"66px",
        '&:hover': {
            backgroundColor: "#3E517E",
        },
    }));

    const parseToEmails = () => {
        const emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        const inputArray: string[] = to.replaceAll(" ","").split(";");
        let newToString: string = ""
        let newToEmails: string[] = []
        inputArray.forEach((email: string) => {
            if (email !=='' && emailReg.test(email)) newToEmails.push(email)
            else newToString += email
        })
        console.log("newToString", newToString)
        // console.log("newToEmails", newToEmails)
        setToEmailsArray(prevState => [...prevState, ...newToEmails])
        setTo(newToString)
    }

    useEffect(() => {
        console.log("abSupplier", bill?.abSupplier)
        if (bill?.abSupplier) fetchContactDetails(bill.abSupplier)
    }, [])

    useEffect(() => {
        console.log("toEmailsArray", toEmailsArray)
    }, [toEmailsArray])

    return(
        <Box sx={{ ...style, width: "95%" }}>
            {loading && <Loading/>}
            <div>
                <CloseIcon style={{float: "right"}} onClick={handleClose} />
                 <div>
                    <div style={{
                        display:"flex",
                        flexDirection:"row",
                        gap:"8px",
                        borderBottom:"0.3px solid rgba(224, 224, 224, 1)",
                        paddingTop:"15px",
                        paddingBottom:"15px"
                    }}>
                        <div>
                            From:
                        </div>
                        <div
                        >
                            {from}
                        </div>

                    </div>
                    <div style={{
                        display:"flex",
                        flexDirection:"row",
                        gap:"8px",
                        paddingTop:"15px",
                    }}>
                        <div>
                            To:
                        </div>
                        <TextField
                            InputProps={{ disableUnderline: true }}
                            fullWidth id="filled-basic"
                            variant="standard"
                            value={to}
                            onKeyUp={(e:React.KeyboardEvent<HTMLDivElement>)=>{ if (e.key === ";") parseToEmails()}}
                            onChange={ handleToDialogChange }
                            onBlur={parseToEmails}
                        />
                    </div>
                     <div style={{
                         display:"flex",
                         flexDirection:"row",
                         gap:"8px",
                         borderBottom:"0.3px solid rgba(224, 224, 224, 1)",
                         paddingBottom:"15px"
                     }}>
                         {toEmailsArray.map((email: string, index:number) => (
                             <Chip key={email+index} label={email} onDelete={() => handleDeleteEmail(email)} />
                         ))}
                     </div>
                </div>
                <div style={{
                    display:"flex",
                    flexDirection:"row",
                    gap:"8px",
                    borderBottom:"0.3px solid rgba(224, 224, 224, 1)",
                    paddingTop:"15px",
                    paddingBottom:"15px"
                }}>
                    <div>
                        Subject:
                    </div>
                    <TextField
                        InputProps={{ disableUnderline: true }}
                        fullWidth id="filled-basic"
                        variant="standard"
                        defaultValue={subject}
                        onChange={ handleSubjectDialogChange }
                    />
                </div>
                <div style={{
                    display:"flex",
                    flexDirection:"row",
                    gap:"8px",
                    borderBottom:"0.3px solid rgba(224, 224, 224, 1)",
                    paddingTop:"15px",
                    paddingBottom:"15px"
                }}>
                    <div>Body</div>
                    <TextField
                        value={body}
                        autoFocus
                        multiline
                        fullWidth
                        rows={10}
                        variant="standard"
                        onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {setBody(event.target.value)}}
                    />
                </div>
                <Files quote={quote} action={""}/>
                <div style={{
                    display:"flex",
                    flexDirection:"row",
                    gap:"400px",
                    justifyContent:"space-between",
                    alignItems:"center",
                    backgroundColor:"#E5E8EE",
                    paddingLeft:"30px",
                    marginLeft:"-32px",
                    paddingRight:"30px",
                    marginRight:"-32px",
                    height:"98px",
                    marginBottom:"-24px"
                }}>
                    <div style={{
                        display:"flex",
                        flexDirection:"row",
                        gap:"7px"
                    }}>
                        <Input
                          type={"file"}
                          inputProps={{multiple: true}}
                          inputRef={fileRef}
                        />
                        {/*<div*/}
                        {/*  onClick={()=> {*/}
                        {/*      if (fileRef.current) fileRef.current?.click()*/}
                        {/*  }}*/}
                        {/*  style={{*/}
                        {/*    alignSelf:"center"*/}
                        {/*  }}*/}
                        {/*>*/}
                        {/*    <img src={addFileIcon} alt="add file icon"/>*/}
                        {/*</div>*/}
                        {/*<DeleteEmail quote={quote} bill={bill}/>*/}
                        {/*<ViewEmailOptions quote={quote} bill={bill}/>*/}
                    </div>
                    <div style={{
                        display:"flex",
                        flexDirection:"row",
                        gap:"7px"
                    }}>
                        {/*<EmojiIcons quote={quote} bill={bill}/>*/}
                        <SendButton variant="contained">
                            <div style={{
                                display:"flex",
                                flexDirection: "row",
                                gap:"7px"
                            }}>
                                <SendEmail quote={quote} bill={bill} onClick={handleSendEmail}/>
                                {/*<span> | </span>*/}
                                {/*<ScheduleEmail quote={quote} bill={bill}/>*/}
                            </div>

                        </SendButton>
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default Emails