import { Box, Button, styled, Switch, TextField } from '@mui/material'
import React, { useState } from 'react'
import '../Mailroom.css'
import { ReactComponent as AttachmentLogo } from '../../../../Assets/MailroomAttachment.svg'
import AttachmentContainer from './AttachmentContainer'

type Props = {}

const CommentTextField = (props: Props) => {
  const [officeReplySwitch, setOfficeReplySwitch] = useState<boolean>(true)
  return (
    <Box className='commentTextFieldRootContainer'>
      {/*Toggle Control */}
      <Box className='toggleControlContainer'>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            fontFamily: 'IBM Plex Mono',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '10px',
            lineHeight: '13px',
            /* identical to box height */

            color: officeReplySwitch ? '#5628D8' : '#1F1F22',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'capitalize',
          }}
        >
          <CommentSwitch
            checked={officeReplySwitch}
            onChange={(event) => setOfficeReplySwitch(event.target.checked)}
          />
          <Box sx={{ paddingLeft: '4px' }}>
            {officeReplySwitch ? 'Email Response' : 'Internal Comment'}
          </Box>
        </Box>
        <AttachmentButton>
          <AttachmentLogo />
        </AttachmentButton>
      </Box>

      {/*Text Input Section */}
      <Box className='textInputCommentContainer'>
        <InputComment
          sx={{
            '& .MuiInputBase-root': {
              height: '100%',
              padding: 0,
              border: 0,
              alignItems: 'flex-start',
            },
            '& .MuiOutlinedInput-input': {
              padding: 0,
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              fontFamily: 'IBM Plex Mono',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '10px',
              lineHeight: '13px',
              color: '#000000',
            },
            '&.MuiInputBase-input': {
              height: '100% !important',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              display: 'none',
              content: 'none',
            },
          }}
          inputProps={{ style: { textAlign: 'start' } }}
          multiline={true}
          maxRows={6}
        />
      </Box>

      {/*Post Btn Section */}
      <Box className='postBtnContainer'>
        <Box className='attachmentRootContainer'>
          <AttachmentContainer />
          <AttachmentContainer />
          <AttachmentContainer />
        </Box>
        <PostButton>
          {officeReplySwitch ? 'Post Email' : 'Post Comment'}
        </PostButton>
      </Box>
    </Box>
  )
}

export default CommentTextField
const AttachmentButton = styled(Button)({
  minWidth: '25px !important',
  height: '25px',
  padding: '0 !important',
  margin: '0 !important',
  borderRadius: '12.5px !important',

  fontStyle: 'normal',
  fontWeight: '400',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#eeeeee',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#BEABF5',
  },
})

const CommentSwitch = styled(Switch)(({ theme }) => ({
  width: 36,
  height: 18,
  padding: 0,
  borderRadius: 18,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(12px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 3,
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#855DF8' : '#855DF8',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 11,
    height: 11,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : '#B2BBCA',
    boxSizing: 'border-box',
  },
}))

const PostButton = styled(Button)({
  minWidth: '84px !important',
  height: '20px',
  padding: '0 !important',
  margin: '0 !important',
  borderRadius: '7px !important',
  fontFamily: 'IBM Plex Mono',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '10px',
  lineHeight: '13px',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'capitalize',
  backgroundColor: '#7949FF',
  '&:hover': {
    opacity: 0.8,
    backgroundColor: '#7949FF',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#6e3afd',
  },
})

const InputComment = styled(TextField)({
  width: '100%',
  height: '100% !important',
})
