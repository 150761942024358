import React, { useRef, useEffect, useState, useCallback } from "react";
import "./Measurement.css";
import { Paper } from "@mui/material";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import { TakeOff, DrawingSet, Sheet, DoxLine, DoxPolygon, DoxRectangle, DoxCircle } from "../../../Models/takeOffs";
import ToolBar from "./ToolBar";
import ScaleBar from "./ScaleBar";
import DrawingsNavbar from "./DrawingsNavBar";
import KonvaComponent from "./Konva";
import DrawingPlaceholder from "./DrawingPlaceholder";
import {Navbar} from "react-bootstrap";
import Loading from "../../../utilities/Lottie/Loading";
import DrawingSetDialog from "./DrawingSetDialog";
import drawingAPI from "../../../Services/DoxleAPI/drawingAPI";
import {takeOffContextInterface, useTakeOff} from "../../Providers/TakeOffProvider";
interface props {
	updateDrawings: Function;
	updateSheets: Function;
	setOpenDropzoneDialog: Function
	deleteDrawing: Function;
	deleteSheet: Function;
	updateMultipleSheets: Function;
}

const Measurement: React.FC<props> = ({
										  updateDrawings,
										  updateSheets,
										  setOpenDropzoneDialog,
										  deleteDrawing,
										  deleteSheet,
										  updateMultipleSheets
}) => {
	const TakeOffContext = useTakeOff() as takeOffContextInterface
	const {
		selectedTakeOff,
		measurements, setMeasurements,
		setUnsavedMeasurements,
		drawings,
		currentDrawings, setCurrentDrawings,
		currentSheet, setCurrentSheet,
		scalingMode, saveMeasurements
	} = TakeOffContext;

	const [editDrawingDialogOpen, setEditDrawingDialogOpen] = useState<boolean>(false);

	const handleEditSheetClicked = () => {
		if (!currentDrawings) return;
		setEditDrawingDialogOpen(true);
	}

	const handleUndoIconClicked = () => {
		if (!(selectedTakeOff && measurements)) { console.log("Missing selectedTakeOff && measurements"); return;}
		setMeasurements([...measurements.slice(0,-1)])
		setUnsavedMeasurements(true)
	}

	return (
		<div tabIndex={0} style={{outline:"none"}}>
			<Paper style={{overflow: "hidden"}}>
				<DrawingsNavbar
					handleEditSheetClicked={handleEditSheetClicked}
					handleUndoIconClicked={handleUndoIconClicked}
					setOpenDropzoneDialog={setOpenDropzoneDialog}
				/>
				{scalingMode && currentSheet
					? <ScaleBar	updateSheets={updateSheets} updateMultipleSheets={updateMultipleSheets}	/>
					:
					null
				}
				<ToolBar />
				{
					currentDrawings && currentSheet
					?
						<KonvaComponent />
					: <DrawingPlaceholder/>
				}
			</Paper>
			{currentDrawings
				?
				<DrawingSetDialog
					open={editDrawingDialogOpen}
					closeAction={() => setEditDrawingDialogOpen(false)}
					saveAction={updateDrawings}
					drawingSet={currentDrawings}
					deleteDrawing={deleteDrawing}
					deleteSheet={deleteSheet}
				/>
				:
				null
			}
		</div>
	)
};

export default Measurement;
