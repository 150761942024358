import React from 'react';
import { User } from '../../../Models/user'
import addUserIcon from "../../../Assets/User - Plus.svg";
import {Autocomplete, Box, Chip, Popper, TextField} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import costCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import {Costcode} from "../../../Models/costcode";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import {AddressBookCompany} from "../../../Models/addressBook";
import {Checklist} from "../../../Models/checklist";
import checklistAPI from "../../../Services/DoxleAPI/checklistAPI";
import ScheduleAPI from "../../../Services/DoxleAPI/scheduleAPI";
import {ScheduleItem} from "../../../Models/schedules";
import { useSocket } from "../../../Services/Sockets/useSocket";
interface props{
    costCodeId?: string;
    checklistId?: string;
    scheduleId?: string;
    addressBookCompanies: AddressBookCompany[];
    assignedContractor: AddressBookCompany|undefined;
    setAssignedContractor: Function;
}

const AddContractor: React.FC<props> = ({costCodeId, checklistId, scheduleId, addressBookCompanies, assignedContractor, setAssignedContractor}) => {
    const assignedContractors: AddressBookCompany[] = assignedContractor ? [assignedContractor] : []
    const authContext = useAuth() as authContextInterface;
    const { user, setLoggedIn } = authContext;
    const {height, width} = WindowDimensions();

    const socket = useSocket();    

    const updateAssignedContractorViaSocket = (
            costcodeId: any = null, 
            checklistId: any = null, 
            scheduleId: any = null, 
            company : AddressBookCompany | null) => {
        // send notification for users via socket
        console.log(
          "%c CLIENT = Sending update assignee",
          "background:green; color:black"
        );

        socket.send(
            JSON.stringify({
                messageType: "SocketDataUpdate",
                message: {
                    costCodeId: costcodeId,
                    checklistId: checklistId,
                    scheduleId: scheduleId,
                    assignedContractor: company, 
                },
            })
        );  

    }

    const handleEditAssignedContractor = async (objectInput: any) =>{
        try {
            if (costCodeId) {
                let response: Costcode;
                if (objectInput?.value?.[0]?.abCompanyId === undefined) {
                    response = await costCodeAPI.update(costCodeId, {assignedContractor: null}) as Costcode;
                    setAssignedContractor(undefined);
                    updateAssignedContractorViaSocket(costCodeId, null, null, null);
                } else if (objectInput?.value?.[1]?.abCompanyId) {
                    response = await costCodeAPI.update(costCodeId, {assignedContractor: objectInput?.value?.[1]?.abCompanyId}) as Costcode;
                    addressBookCompanies.forEach((company:AddressBookCompany) =>{
                        if (company?.abCompanyId === response?.assignedContractor) {
                            setAssignedContractor(company);
                            updateAssignedContractorViaSocket(costCodeId, null, null, company);
                        }
                        return
                    })
                } else if (objectInput?.value?.[0]?.abCompanyId) {
                    response = await costCodeAPI.update(costCodeId, {assignedContractor: objectInput?.value?.[0]?.abCompanyId}) as Costcode;
                    addressBookCompanies.forEach((company:AddressBookCompany) =>{
                        if (company?.abCompanyId === response?.assignedContractor) {
                            setAssignedContractor(company);
                            updateAssignedContractorViaSocket(costCodeId, null, null, company);
                        }
                        return
                    })
                }
            } else if (checklistId && user?.userId) {
                let response: Checklist;
                if (objectInput?.value?.[0]?.abCompanyId === undefined) {
                    response = await checklistAPI.updateChecklist(checklistId, {assignedContractor: null}, user.userId) as Checklist;
                    setAssignedContractor(undefined);
                    updateAssignedContractorViaSocket(null, checklistId, null, null);
                } else if (objectInput?.value?.[1]?.abCompanyId) {
                    response = await checklistAPI.updateChecklist(checklistId, {assignedContractor: objectInput?.value?.[1]?.abCompanyId}, user.userId) as Checklist;
                    addressBookCompanies.forEach((company:AddressBookCompany) =>{
                        if (company?.abCompanyId === response?.assignedContractor) {
                            setAssignedContractor(company);
                            updateAssignedContractorViaSocket(null, checklistId, null, company);
                        }
                        return
                    })
                } else if (objectInput?.value?.[0]?.abCompanyId) {
                    response = await checklistAPI.updateChecklist(checklistId, {assignedContractor: objectInput?.value?.[0]?.abCompanyId}, user.userId) as Checklist;
                    addressBookCompanies.forEach((company:AddressBookCompany) =>{
                        if (company?.abCompanyId === response?.assignedContractor) {
                            setAssignedContractor(company);
                            updateAssignedContractorViaSocket(null, checklistId, null, company);
                        }
                        return
                    })
                }
            } else if (scheduleId) {
                let response: ScheduleItem;
                if (objectInput?.value?.[0]?.abCompanyId === undefined) {
                    response = await ScheduleAPI.update(scheduleId, {assignedContractor: null}) as ScheduleItem
                    setAssignedContractor(undefined);
                    updateAssignedContractorViaSocket(null, null, scheduleId, null);
                } else if (objectInput?.value?.[1]?.abCompanyId) {
                    response = await ScheduleAPI.update(scheduleId, {assignedContractor: objectInput?.value?.[1]?.abCompanyId}) as ScheduleItem;
                    addressBookCompanies.forEach((company: AddressBookCompany) => {
                        if (company?.abCompanyId === response?.assignedContractor) {
                            setAssignedContractor(company);
                            updateAssignedContractorViaSocket(null, null, scheduleId, company);
                        }
                        return
                    })
                } else if (objectInput?.value?.[0]?.abCompanyId) {
                    response = await ScheduleAPI.update(scheduleId, {assignedContractor: objectInput?.value?.[0]?.abCompanyId}) as ScheduleItem;
                    addressBookCompanies.forEach((company: AddressBookCompany) => {
                        if (company?.abCompanyId === response?.assignedContractor) {
                            setAssignedContractor(company);
                            updateAssignedContractorViaSocket(null, null, scheduleId, company);
                        }
                        return
                    })
                }
            }
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }


    return(
        <Autocomplete
            size="small"
            disableClearable
            multiple
            popupIcon={
                    !assignedContractor
                    ?
                    <img src={addUserIcon} alt="addUser" style={{position: "relative", right: width < 928 ? "26px" : "70px"}} />
                    :
                    <ArrowDropDownIcon />
            }
            PopperComponent={(props: any) => <Popper {...props} style={{width: "fit-content"}} placement="bottom-start" />}
            getOptionLabel={(assignedContractor) => assignedContractor.companyName}
            isOptionEqualToValue={(option, value) => option.abCompanyId === value.abCompanyId}
            onChange={  (event: any, value: AddressBookCompany[]) => value ? handleEditAssignedContractor({ "event": event, "value": value} ) : null }
            options={addressBookCompanies}
            value={assignedContractors}
            renderOption={(props, contractor) => (
                <Box component="li" {...props} key={contractor.companyName} style={{
                    paddingTop:"20px",
                    paddingBottom:"15px",
                    borderBottom: "0.3px solid rgba(224, 224, 224, 1)",
                    height: "18px",
                    fontSize: "11px",
                    fontFamily:"Inter",
                    letterSpacing: "0.15px",
                }}>
                    <Chip
                        variant="outlined"
                        label={contractor.companyName}
                        size="small"
                        sx={{
                            // backgroundColor:"#0044CC",
                            backgroundColor:"#D7C9FF",
                            color:"black",
                            fontSize: "8px",
                            height:"15px",
                            maxWidth:"180px",
                            marginRight:"10px",
                            border: "none"
                        }}
                    />
                </Box>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={option.abCompanyId ? option.abCompanyId : index}
                        variant="outlined"
                        label={option.companyName}
                        size="small"
                        sx={{
                            // backgroundColor:"#0044CC",
                            // color:"white"
                            backgroundColor: "#D7C9FF",
                            color:"black",
                            fontSize: "8px",
                            height:"15px",
                            border: "none",
                            maxWidth:"180px",
                        }}

                    />
                ))
            }
            renderInput={(params) =>
                <TextField
                    {...params}
                    sx={{ "&::placeholder": {
                            color: "green"
                        },
                        "& .MuiOutlinedInput-root":{
                            // width: "150px",
                            width: width < 928 ? "71px" : "150px",
                        },
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0px",
                        },
                        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0px"
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0px"
                        },
                        "& .MuiOutlinedInput-input": {
                            color: "#000000",
                            borderWidth: "0px",
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily:"Inter"
                        },
                        "&:hover .MuiOutlinedInput-input": {
                            borderWidth: "0px"
                        },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                            borderWidth: "0px"
                        }
                    }}
                />
            }
            sx={{
                "&.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon" : {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                },
                '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
                    right: "0px"
                },
                '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                    padding: '2px',
                    flexWrap: "nowrap"
                }
            }}
        />
    )
}

export default AddContractor