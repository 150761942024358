import { Box } from "@mui/material";
import { Notice, NoticeCategory } from "../../../Models/notice";
import { User } from "../../../Models/user";
import moment from "moment";
import React from "react";
import { start } from "repl";

export interface ITimeRow {
  timeValue: number;
  timeUI: JSX.Element;
  axisPos?: { start: number; end: number };
}

export interface IUserWithTask {
  user: User;
  notice: Notice[];
}
export type TDailySelect = "Daily View" | "Weekly View";

export const TimeRow = (
  rowSize?: number,
  currentMousePos?: number,
  boundLeft?: number
) => {
  const officeTime = { start: 7, end: 19 };
  let page1: ITimeRow[] = [];
  let page2: ITimeRow[] = [];
  let itemEachPage: number = 0;
  let maxNumberItemEachPage = 7;
  const rowRatio = 100 / maxNumberItemEachPage;

  if (rowSize && currentMousePos && boundLeft) {
    let widthOfACell: number = (rowRatio * rowSize) / 100;

    for (let i = officeTime.start; i <= officeTime.end; i++) {
      itemEachPage++;
      if (itemEachPage <= 7) {
        page1.push({
          timeUI: (
            <Box
              className="time-header-text-style"
              style={{ width: `${rowRatio}%` }}
            >
              {i <= 12 ? i : i - 12} {i >= 12 ? "Pm" : "Am"}
            </Box>
          ),
          timeValue: i,
          axisPos: {
            start: (i - maxNumberItemEachPage) * widthOfACell + boundLeft,
            end:
              (i - maxNumberItemEachPage) * widthOfACell +
              boundLeft +
              widthOfACell,
          },
        });
        if (itemEachPage === 7) {
          page2.push({
            timeUI: (
              <Box
                className="time-header-text-style"
                style={{ width: `${rowRatio}%` }}
              >
                {i < 12 ? i : i - 12} {i >= 12 ? "Pm" : "Am"}
              </Box>
            ),
            timeValue: i,
            axisPos: {
              start: (i - maxNumberItemEachPage) * widthOfACell + boundLeft,
              end:
                (i - maxNumberItemEachPage) * widthOfACell +
                boundLeft +
                widthOfACell,
            },
          });
        }
      } else {
        page2.push({
          timeUI: (
            <Box
              className="time-header-text-style"
              style={{ width: `${rowRatio}%` }}
            >
              {i < 12 ? i : i - 12} {i >= 12 ? "Pm" : "Am"}
            </Box>
          ),
          timeValue: i,
          axisPos: {
            start: (i - maxNumberItemEachPage) * widthOfACell + boundLeft,
            end:
              (i - maxNumberItemEachPage) * widthOfACell +
              boundLeft +
              widthOfACell,
          },
        });
      }
    }
    return {
      page1: page1,
      page2: page2,
    };
  } else {
    for (let i = officeTime.start; i <= officeTime.end; i++) {
      itemEachPage++;
      if (itemEachPage <= 7) {
        page1.push({
          timeUI: (
            <Box
              className="time-header-text-style"
              style={{ width: `${rowRatio}%` }}
            >
              {i <= 12 ? i : i - 12} {i >= 12 ? "Pm" : "Am"}
            </Box>
          ),
          timeValue: i,
        });
        if (itemEachPage === 7) {
          page2.push({
            timeUI: (
              <Box
                className="time-header-text-style"
                style={{ width: `${rowRatio}%` }}
              >
                {i < 12 ? i : i - 12} {i >= 12 ? "Pm" : "Am"}
              </Box>
            ),
            timeValue: i,
          });
        }
      } else {
        page2.push({
          timeUI: (
            <Box
              className="time-header-text-style"
              style={{ width: `${rowRatio}%` }}
            >
              {i < 12 ? i : i - 12} {i >= 12 ? "Pm" : "Am"}
            </Box>
          ),
          timeValue: i,
        });
      }
    }
    return {
      page1: page1,
      page2: page2,
    };
  }
};

export interface ITimeRange {
  start: number;
  end: number;
  dateDeadline?: { day: number; month: number };
}
export const getTimeRangeNotice = (
  notice: Notice,
  pageTimeRange: ITimeRange
) => {
  //! IF THE VALUE RETURN -1 means the date is invalid
  let currentDate: Date = new Date();
  let resultCheckNoticeTimeRangeType: TNoticeTimeRange = checkNoticeTimeRange(
    notice
  ) as TNoticeTimeRange;

  //notice without start and end date
  if (resultCheckNoticeTimeRangeType === "invalid") {
    return { start: -1, end: -1 } as ITimeRange;
  }

  //noticce without start date
  else if (resultCheckNoticeTimeRangeType === "noStart" && notice.dueDate) {
    let noticeDueDate: Date = new Date(notice.dueDate);

    //notice due date is current date (notice due today)
    if (noticeDueDate === currentDate) {
      let noticeDueHour: number = noticeDueDate.getHours();
      //due date hour less than start time of office
      if (noticeDueHour < pageTimeRange.start)
        return { start: -1, end: -1 } as ITimeRange;
      //due date hour fall in page time range
      else if (
        noticeDueHour >= pageTimeRange.start &&
        noticeDueHour <= pageTimeRange.end
      )
        return { start: pageTimeRange.start, end: noticeDueHour } as ITimeRange;
      //due date hour > page time range end
      else {
        return {
          start: pageTimeRange.start,
          end: pageTimeRange.end,
        } as ITimeRange;
      }
    }
    // notice due date > current date (notice is on progress)
    else if (noticeDueDate > currentDate) {
      return {
        start: pageTimeRange.start,
        end: pageTimeRange.end,
      } as ITimeRange;
    }

    //notice is due
    else return { start: -1, end: -1 } as ITimeRange;
  }

  //notice without due date
  else if (resultCheckNoticeTimeRangeType === "noDue" && notice.startDate) {
    let noticeStartDate: Date = new Date(notice.startDate);

    //notice start date < current date (notice without due date still get count)
    if (noticeStartDate < currentDate) {
      return {
        start: pageTimeRange.start,
        end: pageTimeRange.end,
      } as ITimeRange;
    }

    //notice start today
    else if (noticeStartDate === currentDate) {
      let noticeStartHour: number = noticeStartDate.getHours();
      if (noticeStartHour < pageTimeRange.start)
        return {
          start: pageTimeRange.start,
          end: pageTimeRange.end,
        } as ITimeRange;
      else if (
        noticeStartHour >= pageTimeRange.start &&
        noticeStartHour <= pageTimeRange.end
      )
        return {
          start: pageTimeRange.start,
          end: pageTimeRange.end,
        } as ITimeRange;
      else return { start: -1, end: -1 } as ITimeRange;
    } else return { start: -1, end: -1 } as ITimeRange;
  }

  //notice due today
  else if (
    resultCheckNoticeTimeRangeType === "dueToday" &&
    notice.startDate &&
    notice.dueDate
  ) {
    let noticeStartDate: Date = new Date(notice.startDate);
    let noticeDueDate: Date = new Date(notice.startDate);
    let noticeStartHour: number = noticeStartDate.getHours();
    let noticeEndHour: number = noticeDueDate.getHours();

    if (noticeStartDate === currentDate) {
      if (noticeStartHour < pageTimeRange.start) {
        if (
          noticeEndHour >= pageTimeRange.start &&
          noticeEndHour <= pageTimeRange.end
        )
          return {
            start: pageTimeRange.start,
            end: noticeEndHour,
          } as ITimeRange;
        else if (noticeEndHour > pageTimeRange.end)
          return {
            start: pageTimeRange.start,
            end: pageTimeRange.end,
          } as ITimeRange;
        else return { start: -1, end: -1 } as ITimeRange;
      } else {
        if (noticeStartHour <= pageTimeRange.end) {
          if (
            noticeEndHour >= noticeStartHour &&
            noticeEndHour <= pageTimeRange.end
          ) {
            return { start: noticeStartHour, end: noticeEndHour };
          } else if (noticeEndHour > pageTimeRange.end)
            return { start: noticeStartHour, end: pageTimeRange.end };
          else return { start: -1, end: -1 } as ITimeRange;
        } else return { start: -1, end: -1 } as ITimeRange;
      }
    } else {
      if (noticeEndHour < pageTimeRange.start)
        return { start: -1, end: -1 } as ITimeRange;
      else if (
        noticeEndHour >= pageTimeRange.start &&
        noticeEndHour <= pageTimeRange.end
      )
        return { start: pageTimeRange.start, end: noticeEndHour } as ITimeRange;
      else {
        return {
          start: pageTimeRange.start,
          end: pageTimeRange.end,
        } as ITimeRange;
      }
    }
    //notice working hour cover office hour
    // if (noticeStartHour < 7 && noticeEndHour > 19)
    //   return {
    //     start: pageTimeRange.start,
    //     end: pageTimeRange.end,
    //   } as ITimeRange;
    // else if (
    //   noticeStartHour >= pageTimeRange.start &&
    //   noticeStartHour <= pageTimeRange.end
    // ) {
    //   if (
    //     noticeEndHour < pageTimeRange.start ||
    //     noticeEndHour > pageTimeRange.end
    //   )
    //     return { start: noticeStartHour, end: pageTimeRange.end } as ITimeRange;
    //   else {
    //     return { start: noticeStartHour, end: noticeEndHour } as ITimeRange;
    //   }
    // } else {
    //   return { start: -1, end: -1 } as ITimeRange;
    // }
  }

  //notice due tomorrow
  else if (
    resultCheckNoticeTimeRangeType === "startToday" &&
    notice.startDate &&
    notice.dueDate
  ) {
    let noticeStartDate: Date = new Date(notice.startDate);
    let noticeEndDate: Date = new Date(notice.dueDate);
    //notive start today

    let noticeStartHour: number = noticeStartDate.getHours();
    let noticeDueHour: number = noticeEndDate.getHours();
    if (noticeStartHour < pageTimeRange.start)
      return {
        start: pageTimeRange.start,
        end: pageTimeRange.end,
      } as ITimeRange;
    else if (
      noticeStartHour >= pageTimeRange.start &&
      noticeStartHour <= pageTimeRange.end
    )
      return { start: noticeStartHour, end: pageTimeRange.end } as ITimeRange;
    else return { start: -1, end: -1 } as ITimeRange;
  }

  //notice have current date in range of start and due date, for ex: startDate "16/01/2023", today:"18/01/2023", dueDate:"23/01/2023"
  else {
    return { start: pageTimeRange.start, end: pageTimeRange.end } as ITimeRange;
  }
};

export const getDateRangeNotice = (
  notice: Notice,
  pageTimeRange: ITimeRange
) => {
  if (notice.startDate && notice.dueDate) {
    let startDate: number = new Date(notice.startDate).getDate();
    let dueDate: number = new Date(notice.dueDate).getDate();

    if (
      startDate < pageTimeRange.start &&
      dueDate >= pageTimeRange.start &&
      dueDate <= pageTimeRange.end
    )
      startDate = pageTimeRange.start;
    else if (
      startDate >= pageTimeRange.start &&
      startDate <= pageTimeRange.end &&
      dueDate > pageTimeRange.end
    )
      dueDate = pageTimeRange.end;
    return { start: startDate, end: dueDate } as ITimeRange;
  } else if (notice.startDate) {
    let startDate: number = new Date(notice.startDate).getDate();

    return { start: startDate, end: startDate } as ITimeRange;
  } else if (notice.dueDate) {
    let dueDate: number = new Date(notice.dueDate).getDate();
    let startDate: number = -1;
    if (dueDate >= pageTimeRange.start && dueDate <= pageTimeRange.end)
      startDate = pageTimeRange.start;
    return { start: startDate, end: dueDate } as ITimeRange;
  } else return { start: -1, end: -1 } as ITimeRange;
};
export const distributeNoticeForUsers = (
  userList: User[],
  notices: Notice[],
  noticeCategoryFilter: NoticeCategory | null
) => {
  // console.log("NOTICE CATE: ", noticeCategoryFilter);
  // console.log("NOTICE LENGTH:", notices.length);
  let filteredNotices: Notice[] = [];
  if (!noticeCategoryFilter) filteredNotices = [...notices];
  else
    filteredNotices = notices.filter(
      (notice) => notice.categoryId === noticeCategoryFilter.noticeCategoryId
    );

  // console.log("FILTER LENGTH:", filteredNotices.length);
  let returnedList: IUserWithTask[] = [];
  userList.forEach((user) => {
    let newNoticeListOfUser: IUserWithTask = { user: user, notice: [] };
    filteredNotices.map((notice) => {
      if (notice.assignedUsers && notice.assignedUsers.length > 0) {
        notice.assignedUsers.map((assignedUser) => {
          if (assignedUser.userId === user.userId) {
            newNoticeListOfUser = {
              ...newNoticeListOfUser,
              notice: [...newNoticeListOfUser.notice, notice],
            };
          }
        });
      }
    });
    returnedList.push(newNoticeListOfUser);
  });

  return returnedList;
};

export const convertDateAndTime = (
  timeValue: number,
  valueType: "hour" | "date"
) => {
  const newDate = new Date();

  if (valueType === "hour") newDate.setHours(timeValue);
  else {
    newDate.setDate(timeValue);
    newDate.setHours(9); //set default time at 9
  }
  // console.log(" CURRENT YEAR:", newDate.getFullYear());
  // console.log(" CURRENT Month:", newDate.getMonth());
  // console.log(" CURRENT date:", newDate.getDate());
  // console.log(" CURRENT hour:", newDate.getHours());
  // //make the date in UTC form with the same as local
  // const newDateInUTC = Date.UTC(
  //   newDate.getFullYear(),
  //   newDate.getMonth(),
  //   newDate.getDate(),
  //   newDate.getHours(),
  //   0,
  //   0,
  //   0
  // );

  // console.log("UTC DATE:", new Date(newDateInUTC).toISOString());
  // console.log("NEW DATE:", newDate);
  // console.log("ISO DATE:", newDate.toISOString());
  return newDate.toISOString();
};

export const formatInitialWidthOfNotice = (
  notice: Notice,
  taskTimeRange: ITimeRange,
  widthRatioOfCell: number,
  rowSize: number,
  valueReturnType: "percent" | "pixel",
  pageTimeRange: ITimeRange
) => {
  //invalid values will return width of one cell
  if (
    !notice.dueDate ||
    !notice.startDate ||
    taskTimeRange.end < taskTimeRange.start ||
    taskTimeRange.start === -1 ||
    taskTimeRange.end === -1
  ) {
    if (valueReturnType === "percent")
      return `${
        ((widthRatioOfCell * (rowSize as number) - 30) / (rowSize as number)) *
        100
      }%`;
    else return `${widthRatioOfCell * (rowSize as number) - 30}px`;
  } else {
    if (valueReturnType === "percent")
      return `${
        (((taskTimeRange.end - taskTimeRange.start + 1) *
          widthRatioOfCell *
          rowSize -
          30) /
          (rowSize as number)) *
        100
      }%`;
    else
      return `${
        (taskTimeRange.end - taskTimeRange.start + 1) *
          widthRatioOfCell *
          rowSize -
        30
      }px`;
  }
};

interface INoticeInitialPos {
  taskTimeRange: ITimeRange;
  pageTimeRange: ITimeRange;
  valueReturnType: "percent" | "pixel";
  rowSize: number;
  widthRatioOfCell: number;
}
export const checkStartPosOfNotice = ({
  taskTimeRange,
  pageTimeRange,
  valueReturnType,
  rowSize,
  widthRatioOfCell,
}: INoticeInitialPos) => {
  if (
    taskTimeRange.start === -1 ||
    taskTimeRange.start < pageTimeRange.start ||
    taskTimeRange.start > pageTimeRange.end ||
    taskTimeRange.end === -1
  ) {
    if (valueReturnType === "percent")
      return `${(5 / rowSize) * 100}%`; //5 means 5px
    else return "5px";
  } else {
    if (valueReturnType === "percent")
      return `${
        ((Math.abs(pageTimeRange.start - taskTimeRange.start) *
          (rowSize as number) *
          widthRatioOfCell +
          5) /
          rowSize) *
        100
      }%`;
    else
      return `${
        Math.abs(pageTimeRange.start - taskTimeRange.start) *
          (rowSize as number) *
          widthRatioOfCell +
        5
      }px`;
  }
};

export interface INoticeWithStartTime {}
export const distributeNoticeWithStartTime = (
  notices: Notice[],
  pageTimeRange: ITimeRange
) => {
  let noticeArrangeData: { [startTime: number]: Notice[] } = {};
  notices.forEach((notice) => {
    let timeRangeOfNotice: ITimeRange = getTimeRangeNotice(
      notice,
      pageTimeRange
    ) as ITimeRange;
    if (noticeArrangeData[timeRangeOfNotice.start.toString() as keyof Object]) {
      noticeArrangeData = {
        ...noticeArrangeData,
        [timeRangeOfNotice.start.toString()]: [
          ...(noticeArrangeData[timeRangeOfNotice.start] || []),
          notice,
        ],
      };
    } else
      noticeArrangeData = {
        ...noticeArrangeData,
        [timeRangeOfNotice.start]: [notice],
      };
  });
  return noticeArrangeData;
};

export interface INoticeAxisPos {
  boundLeft: number;
  boundRight: number;
  width: number;
  height: number;
  boundTop: number;
}
export type TDragAction = "dragStart" | "dragEnd" | "move";

export const categorisingEditAction = (
  triggeringPos: { x: number; y: number },
  rangeOfMouseAction: number,
  noticeWidthSize: number
) => {
  if (triggeringPos.x >= 0 && triggeringPos.x <= rangeOfMouseAction)
    return "dragStart";
  else if (
    triggeringPos.x >= noticeWidthSize - rangeOfMouseAction &&
    triggeringPos.x <= noticeWidthSize
  )
    return "dragEnd";
  else return "moving";
};

export const populateTimeRow = (pageTimeRange: ITimeRange) => {
  const timeRowUI: number[] = [];
  for (let i = pageTimeRange.start; i <= pageTimeRange.end; i++) {
    timeRowUI.push(i);
  }

  return timeRowUI;
};

export function getWeekDates(today: Date) {
  let date = moment(today),
    weeklength = 7,
    result = [];
  date = date.startOf("week");
  while (weeklength--) {
    result.push(date.format("DD"));
    date.add(1, "day");
  }
  return result;
}

export const populateEmptyRow = () => {
  let emptyRows: number[] = [];
  for (let i = 0; i <= 20; i++) {
    emptyRows.push(i);
  }
  return emptyRows;
};

type TNoticeTimeRange =
  | "all"
  | "noDue"
  | "noStart"
  | "dueToday"
  | "invalid"
  | "startToday";
export const checkNoticeTimeRange = (notice: Notice) => {
  let today = new Date();

  if (!notice.dueDate && notice.startDate) return "noDue" as TNoticeTimeRange;
  else if (!notice.startDate && notice.dueDate)
    return "noStart" as TNoticeTimeRange;
  else if (!notice.startDate && !notice.dueDate)
    return "invalid" as TNoticeTimeRange;
  else {
    let noticeStartDate = new Date(notice.startDate as string);
    let noticeDueDate = new Date(notice.dueDate as string);

    if (today > noticeStartDate && today <= noticeDueDate)
      return "all" as TNoticeTimeRange;
    else if (noticeStartDate <= today && noticeDueDate === today) {
      return "dueToday" as TNoticeTimeRange;
    } else if (noticeStartDate === today && noticeDueDate > today) {
      return "startToday" as TNoticeTimeRange;
    } else return "invalid";
  }
};

export const getDueDateOfNotice = (notice: Notice) => {
  if (notice.dueDate) {
    let today: Date = new Date();
    let todayYear: number = today.getFullYear();
    let todayMonth: number = today.getMonth() + 1;
    let todayDate: number = today.getDate();
    let noticeDuedate: Date = new Date(notice.dueDate);

    let dueDateYear: number = noticeDuedate.getFullYear();
    let dueDateMonth: number = noticeDuedate.getMonth() + 1;
    let dueDateDay: number = noticeDuedate.getDate();

    let dueDateHour: number = noticeDuedate.getHours();
    if (
      todayYear === dueDateYear &&
      todayMonth === dueDateMonth &&
      todayDate === dueDateDay
    )
      return `Today at ${formatTime(dueDateHour)}`;
    return `${dueDateYear}/${
      dueDateMonth > 10 ? dueDateMonth : `0${dueDateMonth}`
    }/${dueDateDay > 10 ? dueDateDay : `0${dueDateDay}`} at ${formatTime(
      dueDateHour
    )}`;
  }
};

export const formatTime = (timeValue: number) => {
  return `${timeValue <= 12 ? timeValue : timeValue - 12}:00 
  ${timeValue >= 12 ? "Pm" : "Am"}`;
};
