import { motion } from "framer-motion";
import React, { createContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Project, ProjectCashflow } from "../../../Models/project";
import ProjectCardScheduleTable from "./ProjectCardScheduleTable";
import ProjectCardTimeBarTracker from "./ProjectCardTimeBarTracker";
import ProjectCardTopAddress from "./ProjectCardTopAddress";

type Props = {
  project: ProjectCashflow;
};
const ProjectCard = ({ project }: Props) => {
  const navigate = useNavigate();
  const handleOnclickProjectCard = () => {
    localStorage.setItem("currentProject", JSON.stringify(project));
    localStorage.setItem("currentProjectId", project.projectId);
    if (project.projectPrefix)
      sessionStorage.setItem("projectPrefix", project.projectPrefix);
    else if (project.siteAddress)
      sessionStorage.setItem(
        "projectPrefix",
        project.siteAddress.replace(/ /g, "").substring(0, 7).toUpperCase()
      );

    localStorage.setItem("currentProject", JSON.stringify(project));
    sessionStorage.setItem("defaultView", "costcode");
    navigate(`/Projects/`);
  };
  return (
    <RootProjectCard
      onClick={handleOnclickProjectCard}
      initial={{ z: 0 }}
      whileHover={{ z: 10 }}
    >
      {/*Address Container */}
      <ProjectCardTopAddress project={project} />

      {/*Time Bar Tracker */}
      <ProjectCardTimeBarTracker project={project} />

      {/*Schedule Table */}
      <ProjectCardScheduleTable project={project} />
    </RootProjectCard>
  );
};

export default ProjectCard;

const RootProjectCard = styled(motion.div)`
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  z-index: 10;
  box-shadow: 10px 10px 15px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;
