import { Box, Button } from "@mui/material";
import { styled } from "@mui/styles";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Mail } from "../../../Models/mail";
import { ReactComponent as ExpandLogo } from "../../../Assets/MailroomExpandLogo.svg";
import { ReactComponent as DeExpandLogo } from "../../../Assets/MailroomDeexpand.svg";
import { ReactComponent as AddLogo } from "../../../Assets/MailroomPlusCircle.svg";

type Props = {
  mailContentData: Mail[];
  contentTitle: string;
};

const MailroomExpandContent = ({ mailContentData, contentTitle }: Props) => {
  const [showSubItem, setShowSubItem] = useState<boolean>(true);
  const subContentRef = useRef<HTMLDivElement | null>(null);
  const [subContentHeight, setsubContentHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (subContentRef.current && subContentHeight === 0)
      setsubContentHeight(subContentRef.current.clientHeight);
  }, [showSubItem]);

  const toggleShowSubItem = () => {
    if (showSubItem) {
      setShowSubItem(false);
    } else {
      setShowSubItem(true);
      console.log(subContentHeight);
    }
  };
  return (
    <ExpandedMailroomContainer
      style={{
        bottom: showSubItem
          ? `-${mailContentData.length * 14 - 11.5}px`
          : "-11.5px",
      }}
    >
      <div
        ref={subContentRef}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/*Title Content */}
        <Box className="contentTitleSectionRootContainer">
          <MailTitleContainer onClick={toggleShowSubItem}>
            {contentTitle} ({mailContentData.length})
            <Box sx={{ paddingLeft: "4px" }}>
              {showSubItem ? <DeExpandLogo /> : <ExpandLogo />}
            </Box>
          </MailTitleContainer>

          {/*Add Logo */}
          <LogoButton>
            <AddLogo />
          </LogoButton>
        </Box>

        {/*Sub Content */}
        {showSubItem ? (
          <MailroomDataContainer>
            {showSubItem
              ? mailContentData.map((mail, index) => {
                  return (
                    <MailroomDataItem key={index}>
                      <div className="mailroomExpandItemBulletLine"></div>

                      {index !== mailContentData.length - 1 ? (
                        <div
                          style={{
                            paddingLeft: "3px",
                          }}
                        >
                          {mail.subject}
                        </div>
                      ) : (
                        <div
                          style={{
                            paddingLeft: "3px",
                            position: "relative",
                            width: "calc(100% - 3px)",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: "4px",
                              width: "100%",
                            }}
                          >
                            {mail.subject}
                          </div>
                        </div>
                      )}
                    </MailroomDataItem>
                  );
                })
              : null}
          </MailroomDataContainer>
        ) : null}
      </div>
    </ExpandedMailroomContainer>
  );
};

export default MailroomExpandContent;

const ExpandedMailroomContainer = styled("div")({
  width: "calc(100% - 41px)",
  paddingLeft: "4px",
  minHeight: "120px",
});

const MailTitleContainer = styled(Button)({
  fontFamily: "IBM Plex Mono",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "13px",
  lineHeight: "17px",
  color: "#000000",
  textTransform: "capitalize",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  maxHeight: "auto !important",
  cursor: "pointer",
  borderRadius: "8px",
  padding: "0 !important",
  "&:hover": {
    backgroundColor: "#eeeeee",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#BEABF5",
  },
});
const MailroomDataContainer = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  textTransform: "capitalize",
  paddingBottom: "12px",
  zIndex: 2,
});

const MailroomDataItem = styled("div")({
  flex: 1,
  fontFamily: "IBM Plex Mono",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "11px",
  lineHeight: "14px",
  color: "#5628D8",
  display: "flex",
  flexDirection: "row",
  paddingTop: "10px",
  borderLeft: "0.5px solid #C8D0DE",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});
const LogoButton = styled(Button)({
  minWidth: "20px !important",
  height: "20px",
  padding: "0 !important",
  margin: "0 !important",
  borderRadius: "10px !important",
  fontStyle: "normal",
  fontWeight: "400",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#eeeeee",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#BEABF5",
  },
});
