import React, {useContext, useEffect, useState} from "react";
import { Project} from "../../../Models/project";
import {DrawingSet, Sheet, TakeOff} from "../../../Models/takeOffs";
import {Grid, Typography} from "@mui/material";
import Measurement from "./Measurements";
import TakeOffList from "./TakeOffList";
import drawingAPI from "../../../Services/DoxleAPI/drawingAPI";
import takeOffAPI from "../../../Services/DoxleAPI/takeOffAPI";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import {takeOffContextInterface, useTakeOff} from "../../Providers/TakeOffProvider";
import Loading from "../../../utilities/Lottie/Loading";
import {DropzoneArea, DropzoneDialog} from "material-ui-dropzone";
import SpecificationAPI from "../../../Services/DoxleAPI/specificationAPI";
import UploadDialog from "./UploadDialog";
import {Costcode} from "../../../Models/costcode";
interface props {
    project: Project | null
    costcode?: Costcode
}

const TakeOffs: React.FC<props> = ({
    project, costcode
}) => {
    const TakeOffContext = useTakeOff() as takeOffContextInterface
    const {
        projectId, setProjectId,
        setCostCodeId, setCurrentSheet,
        setCurrentDrawings,
        drawings, setDrawings,
        isLoadingImage, setIsLoadingImage,
        handleKeyUp
    } = TakeOffContext;
    // const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true)
    const [openDropzoneDialog, setOpenDropzoneDialog] = useState<boolean>(false)
    const [currentlyDragging, setCurrentlyDragging] = useState<boolean>(false)
    const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false)
    const [pendingFile, setPendingFile] = useState<Blob|null>(null)
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn } = authContext;

    const handleSavDrawingTitles = async (setId:string, setName: string, sheets: Sheet[]) => {
        try {
            const result = await drawingAPI.updateSet(setId, setName, sheets) as DrawingSet
            let newDrawings: DrawingSet[] = [];
            drawings.forEach((drawing: DrawingSet) => {
                if (drawing.setId === result.setId) newDrawings.push(result)
                else newDrawings.push(drawing)
            })
            setDrawings([...newDrawings])
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    const handleDeleteSheet = async (sheetId:string, deleteMeasures:boolean) => {
        try {
            const result = await drawingAPI.removeSheet(sheetId, deleteMeasures)
            if (!result) return;
            let newDrawings: DrawingSet[] = [];
            drawings.forEach((drawingSet: DrawingSet) => {
                let newSheets: Sheet[] = [];
                drawingSet.sheets.forEach((sheet: Sheet) => {
                    if (sheet.sheetId !== sheetId) newSheets.push(sheet);
                })
                newDrawings.push({...drawingSet, sheets: newSheets});
            });
            setDrawings([...newDrawings]);
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    const handleDeleteDrawing = async (setId:string, deleteMeasures:boolean) => {
        try {
            const result = await drawingAPI.removeSet(setId, deleteMeasures)
            if (!result) return;
            let newDrawings: DrawingSet[] = [];
            drawings.forEach((drawingSet: DrawingSet) => {
                if (drawingSet.setId !== setId) newDrawings.push(drawingSet);
            });
            setDrawings([...newDrawings]);
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }


    interface updateSheetProps{
        title?:string;
        scale?:number;
    }
    const updateSheets = async(sheetId: string|undefined, {title, scale }: updateSheetProps) => {
        try {
            const response = await drawingAPI.updateSheet(sheetId, {title: title, scale: scale}) as Sheet;
            let newDrawings: DrawingSet[] = []
            drawings.forEach((drawingSet: DrawingSet) =>{
                let newSheets: Sheet[] = []
                drawingSet.sheets.forEach((sheet: Sheet) => {
                    if (sheet.sheetId === response.sheetId) newSheets.push(response)
                    else newSheets.push(sheet)
                })
                drawingSet.sheets = newSheets
                newDrawings.push(drawingSet)
            })
            setDrawings([...newDrawings])
            setCurrentSheet(response)
        } catch (err){
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    const updateMultipleSheets = async(setId: string|undefined, scale: number) => {
        try {
            if (!setId) return;
            const response = await drawingAPI.updateMultiSheet(setId, scale);
            let newDrawings: DrawingSet[] = []
            if (!response) return;
            drawings.forEach((drawingSet: DrawingSet) =>{
                if (drawingSet.setId === setId) newDrawings.push(response)
                else newDrawings.push(drawingSet)
            })
            setDrawings([...newDrawings])
            setCurrentDrawings(response)
        } catch (err){
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    const handleCancelUpload = () =>{
        setPendingFile(null)
        setShowUploadDialog(false)
    }

    const handleDrawingsDrop = async (newFile: Blob) => {
        setPendingFile(newFile)
        setShowUploadDialog(true)
    }

    const handleSubmitUpload = async (drawingSetName:string, highResolution:boolean) => {
        try {
            const company:string|undefined = JSON.parse(localStorage.getItem("currentCompany") || '{}').name;
            if (!company) { console.log("Missing company name"); return; }
            if (!projectId) { console.log("Missing projectId"); return; }
            if (!pendingFile) { console.log("Missing pendingFile"); return; }
            const uploadFile = pendingFile
            setCurrentlyDragging(false);
            setPendingFile(null)
            setShowUploadDialog(false)
            setIsLoadingImage(true);
            const response = await drawingAPI.uploadDrawings({
                name: drawingSetName,
                projectId: projectId,
                companyName: company,
                file: pendingFile,
                lowRes: !highResolution ? "TRUE" : "FALSE"
            })
            console.log(response)
            if (response) {
                setDrawings([...drawings, response])
                setCurrentDrawings(response)
            }
            setIsLoadingImage(false);
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    useEffect(() => {
        if (project?.projectId) setProjectId(project.projectId)
        if (costcode?.costCodeId) setCostCodeId(costcode.costCodeId)
        console.log("costcode?.costCodeId", costcode?.costCodeId)
    }, [project, costcode])


    return (
        <>
            {isLoadingImage && <Loading/>}
            <Grid
                onKeyUp={handleKeyUp}
                container spacing={2}
                className="measurements-container"
            >
                <Grid item xs={2}>
                    <TakeOffList />
                </Grid>
                <Grid item xs={10}>
                    <div
                        onDragEnter={(e: any) => setCurrentlyDragging(true)}
                        onDragExit={(e: any) => setCurrentlyDragging(false)}
                        style={{
                        display: !isLoadingImage && drawings?.length === 0 ? "block" : "none",
                        border: currentlyDragging ? "#ccc dashed 3px" : "none"
                    }}>
                        <DropzoneArea
                            onDrop={(files: Blob[]) => handleDrawingsDrop(files[0])}
                            filesLimit={1}
                            maxFileSize={15000000}
                            acceptedFiles={['application/pdf']}
                            dropzoneText="Drag and drop a Drawing Set to Get Started"
                            showPreviews={false}
                            showPreviewsInDropzone={false}
                            showFileNamesInPreview={false}
                            showAlerts={false}
                        />
                    </div>
                    <DropzoneDialog
                        acceptedFiles={['application/pdf']}
                        cancelButtonText={"cancel"}
                        submitButtonText={"submit"}
                        maxFileSize={15000000}
                        open={openDropzoneDialog}
                        onClose={() => setOpenDropzoneDialog(false)}
                        onSave={(files: Blob[]) => {
                            console.log('Files:', files);
                            handleDrawingsDrop(files[0])
                            setOpenDropzoneDialog(false);
                        }}
                        showPreviews={true}
                        showFileNamesInPreview={true}
                        showAlerts={false}
                    />
                    { !isLoadingImage && drawings?.length > 0
                        ?
                            <Measurement
                                updateDrawings={handleSavDrawingTitles}
                                updateSheets={updateSheets}
                                setOpenDropzoneDialog={setOpenDropzoneDialog}
                                deleteDrawing={handleDeleteDrawing}
                                deleteSheet={handleDeleteSheet}
                                updateMultipleSheets={updateMultipleSheets}
                            />
                        : null
                    }
                </Grid>
            </Grid>
            <UploadDialog
                open={showUploadDialog}
                closeAction={handleCancelUpload}
                saveAction={handleSubmitUpload}
            />
        </>
    )
}

export default TakeOffs;