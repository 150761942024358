import { Input, TextField } from "@mui/material";
import React, { useState } from "react";
import { Note } from "../../../Models/note";
import { highlightMultilineText, highlightText } from "../../../utilities/Markdown/markdown";
import CustomCheckbox from "../../../utilities/CheckBox/CheckBox";

interface props{
    action: string,
    row: Note,
    setNoteTitle?: Function,
    setNoteDescription?: Function,
    openNotes: boolean | undefined,
    editMode: string | undefined,
    editIconClicked: boolean | undefined,
    setEditMode?: Function,
    handleCheckBox?: Function,
    handleSaveButton?: Function,
}

const EditNote: React.FC<props> = ({
    action,
    row,
    setNoteTitle,
    setNoteDescription,
    openNotes,
    editMode,
    editIconClicked,
    setEditMode,
    handleCheckBox,
    handleSaveButton
}) => {
    const [title, setTitle] = useState<string>(row.title)


    const handleCheckBoxClick = async (event: any, noteId: string) =>{
        if(typeof event.target.checked === "boolean" && handleCheckBox){
           console.log(noteId)
           handleCheckBox(event, noteId)
        }
    }

    const resetTitle = (event: any) =>{
        event.target.value = title
        if(setEditMode){
            setEditMode(undefined)
        }

    }

    const handleHighlightText = (inputText: string) =>{
        let outputValue = [
            <div key={row.noteId}>
                {highlightText(inputText, row.noteId)}
            </div>   
        ]
        return outputValue
    }

    const handleTitleChange = async (event: any, spec: Note)=>{
        try {
            if (event.target.value === title) 
            {
                resetTitle(event)
            } else {
                if(setNoteTitle){
                    setNoteTitle(event.target.value)
                }
                setTitle(event.target.value)
                
            }
            
        } catch(err){
            console.log(err);
            resetTitle(event)
        }
    }

   
    
    return(
        <div>
            {
            action === "checkbox"
            ?
            <CustomCheckbox 
                onClick={(event: any) => {
                    handleCheckBoxClick(event, row.noteId)
                }}
                style = {{
                    transform: "scale(0.4)"
                }}
            />     
            :
            action === "title"
            ?
            <>
                {
                editMode === row.noteId && editIconClicked
                ?
                    <>
                        <Input size="small" 
                            autoFocus
                            fullWidth
                            disableUnderline 
                            defaultValue={title} 
                            sx={{
                                height: "10px", 
                                fontFamily:'Roboto Mono',
                                fontStyle:'normal',
                                fontWeight: "400",
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#000000",
                                position: "relative"
                            }}
                           onChange={(event: any) => {
                               handleTitleChange(event, row);
                           }}
                           onKeyUp={(event: any) => {
                                if (event.keyCode === 27){resetTitle(event);}
                                if (event.keyCode === 13 && handleSaveButton){handleSaveButton()}
                            }}
                        /> 
                    </>   
                :
                    <div>
                        {/* {handleHighlightText(title)} */}
                        {title}
                    </div>
                } 
            </>
            :
                      
            <></>
            }

        </div>   
    )
}
export default EditNote