import React, { useState, useEffect } from "react";
import "./Dockets.css";
import { Company } from "../../../Models/company";
import Loading from "../../../utilities/Lottie/Loading";
import {
  Stack,
  Typography,
  Box,
  Button,
  IconButton,
  TextField,
  Tab,
  Grid,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ReactComponent as DocketsIcon } from "../../../Assets/Newspaper-alt.svg";
import { ReactComponent as SettingsIcon } from "../../../Assets/Settings.svg";
import Files from "../Files/Files";
import Axios from "axios";
import "qs";
import { Docket } from "../../../Models/dockets";
import { docketsBaseAddress } from "../../../settings";
import DocketTable from "./DocketTable";
import XeroData from "../../../Models/xeroData";

interface props {
  company: Company | undefined;
}

const Dockets: React.FC<props> = ({ company }) => {
  const [tabIndex, setTabIndex] = useState<string>("1");
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const authContext = useAuth() as authContextInterface
  // const { setLoggedIn, exchangeRefreshToken, user } = authContext

  const [reviewDockets, setReviewDockets] = useState<Docket[]>([]);
  const [successfulDockets, setSuccessfulDockets] = useState<Docket[]>([]);
  const [failedDockets, setFailedDockets] = useState<Docket[]>([]);
  const [xeroData, setXeroData] = useState<XeroData>({
    accounts: [],
    contacts: [],
    tracking_options: [],
  });

  const fetchDockets = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.get(`${docketsBaseAddress}/`);
      console.log("DOCKETS", response?.data);
      if (response?.data?.review) setReviewDockets(response?.data?.review);
      if (response?.data?.success)
        setSuccessfulDockets(response?.data?.success);
      if (response?.data?.error) setFailedDockets(response?.data?.error);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const fetchXeroData = async () => {
    try {
      const response = await Axios.get(
        `${docketsBaseAddress}/GetXeroData/`
      );
      console.log("XERO", response?.data);
      if (response?.data) setXeroData(response?.data as XeroData);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const handleSendtoXero = async () => {
    try {
      setIsLoading(true);
      const result = await Axios.post(
        `${docketsBaseAddress}/SendToXero/`,
        reviewDockets
      );
      fetchDockets();
      setIsLoading(false);
      if (result.data === "Complete") setTabIndex("3");
      else setTabIndex("4");
    } catch (err) {
      console.log(err);
    }
  };

  const handleExtract = async () => {
    try {
      //make a form data to store array of files
      setIsLoading(true);
      const bodyFormData = new FormData();
      files.map((file) => bodyFormData.append("files", file));
      const result = await Axios.post(
        docketsBaseAddress + "/Extract/",
        bodyFormData
      );
      console.log("getExtractData result", result);
      if (!result || !result?.data) return;
      //update finalReturnedData state to pass to tab awaiting review
      setReviewDockets([...reviewDockets, ...result.data]);

      //clear files
      setFiles([]);

      //switch tab
      setTabIndex("2");
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDockets();
    fetchXeroData();
  }, []);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        background: "#D5D7E3",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          m: 0,
          p: 0,
          backgroundColor: "white",
          borderRadius: "11px",
          width: "max(744px, 75vw)",
          height: "max(678px, 80vh)",
          overflow: "hidden",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Stack
          borderBottom="1px solid #EDF0F6"
          direction="row"
          justifyContent="space-between"
          padding="28px 39px 13px 30px"
        >
          <Stack direction="row">
            <DocketsIcon />
            <Stack
              direction="column"
              alignItems="center"
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "300",
              }}
            >
              <div
                style={{
                  fontSize: "13px",
                  lineHeight: "15px",
                }}
              >
                DOCKETS
              </div>
              <div
                style={{
                  fontSize: "8px",
                  lineHeight: "9px",
                  color: "#5E32DE",
                }}
              >
                v1.7
              </div>
            </Stack>
          </Stack>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="setting"
            style={{ padding: 0 }}
          >
            <SettingsIcon />
          </IconButton>
        </Stack>
        {isLoading ? (
          <Loading />
        ) : (
          <TabContext value={tabIndex}>
            <Box sx={{ px: "35px", py: "30px" }}>
              <TabList
                textColor="inherit"
                indicatorColor="primary"
                onChange={(event: React.SyntheticEvent, newValue: string) =>
                  setTabIndex(newValue)
                }
                centered
                TabIndicatorProps={{ style: { display: "none" } }}
                sx={{
                  "& .MuiTabs-flexContainer": {
                    gap: "2px",
                    justifyContent: "space-between",
                  },
                  "& .MuiButtonBase-root": {
                    textTransform: "none",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "351",
                    fontSize: "11px",
                    lineHeight: "13px",
                    minWidth: "unset",
                    padding: "0",
                    width: "calc(25% - 1px)",
                    maxWidth: "unset",
                    background: "#F1F2F4",
                    minHeight: "35px",
                    color: "#09090A",
                  },
                  "& .Mui-selected": {
                    color: "#8A51C3",
                  },
                }}
              >
                <Tab
                  key={0}
                  label="New Dockets"
                  value={"1"}
                  sx={{ borderRadius: "8px 0 0 8px" }}
                  disableRipple
                />
                <Tab
                  key={1}
                  label={`Awaiting Review (${reviewDockets.length})`}
                  value={"2"}
                  sx={{ borderRadius: "0px 0 0 0px" }}
                  disableRipple
                />
                <Tab
                  key={2}
                  label={`Successful (${successfulDockets.length})`}
                  value={"3"}
                  sx={{ borderRadius: "0px 0 0 0px" }}
                  disableRipple
                />
                <Tab
                  key={3}
                  label={`Failed (${failedDockets.length})`}
                  value={"4"}
                  sx={{ borderRadius: "0 8px 8px 0" }}
                  disableRipple
                />
              </TabList>
            </Box>

            {/* content grid */}

            <Grid sx={{ height: "100%" }}>
              {/* tab panels: */}

              {/* 1. new dockets */}
              <TabPanel
                value="1"
                sx={{
                  px: "35px",
                  pt: 0,
                  height: "calc(100% - 24px)",
                }}
              >
                <Stack
                  justifyContent="space-between"
                  direction="column"
                  height="100%"
                >
                  <Files
                    action={"dockets"}
                    // notice={notice}
                    // attachmentList={attachmentList}
                    // setAttachmentList={setAttachmentList}
                    setFileBlob={setFiles}
                    fileBlobState={files}
                  />

                  <Stack justifyContent="center" direction="row">
                    <Button
                      sx={{
                        minWidth: "131px",
                        width: "131px",
                        borderRadius: "20px",
                        background: "#754EE0",
                        textTransform: "none",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#FFFFFF",
                        padding: "12px",
                        "&:hover": {
                          background: "#552EC0",
                        },
                      }}
                      onClick={handleExtract}
                    >
                      Extract
                    </Button>
                  </Stack>
                </Stack>
              </TabPanel>

              {/* 2. awaiting review */}
              <TabPanel
                value="2"
                sx={{ width: "100%", height: "100%", m: 0, p: 0 }}
              >
                <DocketTable
                  dockets={reviewDockets}
                  setDockets={setReviewDockets}
                  setTabIndex={setTabIndex}
                  action={"review"}
                  refreshData={fetchDockets}
                  handleSendtoXero={handleSendtoXero}
                  xeroData={xeroData}
                />
              </TabPanel>

              {/* 3. approved */}
              <TabPanel
                value="3"
                sx={{ width: "100%", height: "100%", m: 0, p: 0 }}
              >
                <DocketTable
                  dockets={successfulDockets}
                  setDockets={setSuccessfulDockets}
                  setTabIndex={setTabIndex}
                  action={"success"}
                  refreshData={fetchDockets}
                  handleSendtoXero={handleSendtoXero}
                  xeroData={xeroData}
                />
              </TabPanel>

              {/* 4. void */}
              <TabPanel
                value="4"
                sx={{ width: "100%", height: "100%", m: 0, p: 0 }}
              >
                <DocketTable
                  dockets={failedDockets}
                  setDockets={setFailedDockets}
                  setTabIndex={setTabIndex}
                  action={"fail"}
                  refreshData={fetchDockets}
                  handleSendtoXero={handleSendtoXero}
                  xeroData={xeroData}
                />
              </TabPanel>
            </Grid>
          </TabContext>
        )}
      </Box>
    </div>
  );
};
export default Dockets;
