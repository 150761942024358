import React, {useState, useEffect, useRef} from "react";
import { Costcode } from "../../../Models/costcode";
import { Button, Input, ListItemButton } from "@mui/material";
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
  MobileDatePicker,
  MobileDateRangePicker,
} from "@mui/lab";
import { Dayjs } from "dayjs";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { formatScheduleDate } from "../../../utilities/constants";
import Status from "../Status/Status";
import CustomCheckbox from "../../../utilities/CheckBox/CheckBox";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import { withStyles } from "@material-ui/core/styles";
import { Permit } from "../../../Models/permit";
import checklistGroupAPI from "../../../Services/DoxleAPI/checklistGroupAPI";
import { Company } from "../../../Models/company";
import { ScheduleItem } from "../../../Models/schedules";
import ScheduleAPI from "../../../Services/DoxleAPI/scheduleAPI";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useSocket } from "../../../Services/Sockets/useSocket";
import { makeStyles, styled } from "@mui/styles";
interface props {
  costcode: Costcode;
  action: string;
  formatter: Intl.NumberFormat;
  projectStatus?: string;
  handleUpdateCostCode: Function;
}

export const addLeadingZeros = (input: number, digits: number) => {
  let num = input.toString();
  while (num.length < digits) num = "0" + num;
  return num;
};

const EditQuickCostCode: React.FC<props> = ({
  costcode,
  action,
  formatter,
  projectStatus,
  handleUpdateCostCode
}) => {
  const inputRef = useRef<undefined|HTMLInputElement>()

  const currentCompanyJSON: string | null =
    localStorage.getItem("currentCompany");
  const currentCompany: Company | null = currentCompanyJSON
    ? JSON.parse(currentCompanyJSON)
    : null;
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, user } = authContext;

  const resetValue = () => {
    if (action === "budget") {
      if (inputRef?.current) inputRef.current.value = formatter.format(parseFloat(costcode?.budget ?? "0.00"))
    }
    if (action === "qty") {
      if (inputRef?.current) inputRef.current.value = formatter.format(parseFloat(costcode?.qty ?? "0.00")).substring(1)
    }
    if (action === "unit") {
      if (inputRef?.current) inputRef.current.value = costcode?.uom ?? "ea"
    }
    if (action === "cost") {
      if (inputRef?.current) inputRef.current.value = formatter.format(parseFloat(costcode?.cost ?? "0.00"))
    }
  }

  const handleSubmit = () => {
    if (inputRef.current) {
      if (action === "budget") handleUpdateCostCode({budget: inputRef.current?.value})
      if (action === "qty") handleUpdateCostCode({qty: inputRef.current?.value})
      if (action === "unit") handleUpdateCostCode({unit: inputRef.current?.value})
      if (action === "cost") handleUpdateCostCode({cost: inputRef.current?.value})
    }
  }

  useEffect (()=> {
    if (action == "budget" && costcode.budget && inputRef.current) inputRef.current.value = formatter.format(parseFloat(costcode.budget))
    if (action == "qty" && costcode.qty && inputRef.current) inputRef.current.value = formatter.format(parseFloat(costcode.qty)).substring(1)
    if (action == "unit" && costcode.uom && inputRef.current) inputRef.current.value = costcode.uom
    if (action == "cost" && costcode.cost && inputRef.current) inputRef.current.value = formatter.format(parseFloat(costcode.cost))
  }, [costcode, costcode.budget, costcode.qty, costcode.uom, costcode.cost])

  const classes = useStyles();
  return (
    <div>
      {action === "budget" ? (
        <Input
          className="budgetInput"
          size="small"
          disableUnderline
          disabled={
            !(currentCompany?.owner && user?.userId === currentCompany.owner) &&
            (projectStatus === "AC" || projectStatus === "AR")
          }
          inputRef={inputRef}
          defaultValue={formatter.format(parseFloat(costcode?.budget ?? "0.00"))}
          sx={{
            height: "10px",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "Inter",
            fontSize: "11px",
            lineHeight: "15px",
            position: "relative",
          }}
          onDoubleClick={(event: any) => {
            if (inputRef.current) inputRef.current.value = "$"
          }}
          onBlur={handleSubmit}
          onKeyUp={(event: any) => {
            if (event.keyCode === 13) {
              handleSubmit();
            } else if (event.keyCode === 27) {
              resetValue()
            }
          }}
        />
      ) : action === "qty" ? (
        <Input
          className="budgetInput"
          size="small"
          disableUnderline
          inputRef={inputRef}
          defaultValue={formatter.format(parseFloat(costcode?.qty ?? "0.00")).substring(1)}
          sx={{
            height: "10px",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "Inter",
            fontSize: "11px",
            lineHeight: "15px",
            position: "relative",
          }}
          onDoubleClick={(event: any) => {
            if (inputRef.current) inputRef.current.value = ""
          }}
          onBlur={handleSubmit}
          onKeyUp={(event: any) => {
            if (event.keyCode === 13) {
              handleSubmit();
            } else if (event.keyCode === 27) {
              resetValue()
            }
          }}
        />
      ) : action === "unit" ? (
        <Input
          className="budgetInput"
          size="small"
          disableUnderline
          inputRef={inputRef}
          defaultValue={costcode?.uom ?? "ea"}
          sx={{
            height: "10px",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "Inter",
            fontSize: "11px",
            lineHeight: "15px",
            position: "relative",
          }}
          onDoubleClick={(event: any) => {
            if (inputRef.current) inputRef.current.value = ""
          }}
          onBlur={handleSubmit}
          onKeyUp={(event: any) => {
            if (event.keyCode === 13) {
              handleSubmit();
            } else if (event.keyCode === 27) {
              resetValue()
            }
          }}
        />
      ) : action === "cost" ? (
        <Input
          className="budgetInput"
          size="small"
          disableUnderline
          inputRef={inputRef}
          defaultValue={formatter.format(parseFloat(costcode?.cost ?? "0.00"))}
          sx={{
            height: "10px",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "Inter",
            fontSize: "11px",
            lineHeight: "15px",
            position: "relative",
          }}
          // onFocus={(event: any) => {
          //   if (inputRef.current) inputRef.current.value = ""
          // }}
          onDoubleClick={(event: any) => {
            if (inputRef.current) inputRef.current.value = ""
          }}
          onBlur={handleSubmit}
          onKeyUp={(event: any) => {
            if (event.keyCode === 13) {
              handleSubmit();
            } else if (event.keyCode === 27) {
              resetValue()
            }
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
export default React.memo(EditQuickCostCode);

const useStyles = makeStyles((theme) => ({
  dateRangePicker: {
    "& .MuiDateRangePickerDay-day.Mui-selected": {
      backgroundColor: "#7949FF",
    },
    "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
      backgroundColor: "rgba(121,73,255,0.4)",
    },
  },
}));
