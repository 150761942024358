import React, { useState, useEffect, ReactElement, useCallback } from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Snackbar from "@mui/material/Snackbar";
import Badge from "@mui/material/Badge";
import {IconButton, Stack, Chip, Typography} from "@mui/material";
import { ReactComponent as NotificationONIcon } from "../../../Assets/NotificationON.svg";
import { ReactComponent as NotificationOFFIcon } from "../../../Assets/NotificationOFF.svg";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import NotificationAPI from "../../../Services/DoxleAPI/notificationAPI";
import CloseIcon from "@mui/icons-material/Close";
import { Notification } from "../../../Models/notification";
import NotificationItem from "./NotificationItem";
import { useSocket } from "../../../Services/Sockets/useSocket";
import { Link, To } from "react-router-dom";

interface props {}

const Notifications: React.FC<props> = ({}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [openSnack, setOpenSnack] = useState<boolean>(false);
    const [snackNotif, setSnackNotif] = useState<Notification>();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseSnack = () => {
        setOpenSnack(false);
    };

    const generateLink = (notification: Notification) => {
        let page = "";
        let modal = "";
        let tab = "";
        if (notification.notice) {
            page = "NoticeBoard";
            modal = notification.notice;
        }
        if (notification.project && notification.costCode) {
            page = "Projects";
            modal = `${notification.project}/${notification.costCode}`;
        }
        if (notification.comment) tab = "comments";
        return `/${page}/${modal}/${tab}` as To;
    };

    const fetchNotifications = async (showSnack = false) => {
        console.log("fetchNotifications...");
        if (user?.userId) {
            try {
                const response = await NotificationAPI.getList(user.userId);
                console.log("notification:", response);
                setNotifications(response);
                if (showSnack && response.length > 0) {
                    setOpenSnack(true);
                    setSnackNotif(response[response.length - 1]);
                }
            } catch (err) {
                err === "AccessTokenNotFound"
                    ? setLoggedIn(false)
                    : console.error(err);
            }
        }
    };
    const handleUpdateSeen = async (notificationId: string) => {
        console.log("handleUpdateSeen...");
        if (user?.userId) {
            try {
                const response = await NotificationAPI.update(
                    notificationId,
                    true
                );
                // setUserList(response);
                console.log("notification update:", response);
                setNotifications((prevNotifs: Notification[]) =>
                    prevNotifs.filter(
                        (notif) => notif.notificationId !== notificationId
                    )
                );
            } catch (err) {
                err === "AccessTokenNotFound"
                    ? setLoggedIn(false)
                    : console.error(err);
            }
        }
    };

    const handleClearAll = async () => {
        const existingNotifications: Notification[] = notifications;
        setNotifications([]);
        setAnchorEl(null);
        for (const notification of existingNotifications) {
            handleUpdateSeen(notification.notificationId)
        }
    }

    useEffect(() => {
        fetchNotifications();
    }, []);

    //! <--- SOCKETS LISTENER
    const socket = useSocket();
    const onMessage = useCallback((message) => {
        const data = JSON.parse(message?.data);

        const serverStatus: string = data.status;
        const messageType: string = data.message_type;

        if (messageType === "Notification") {
            if (serverStatus === "SUCCESS") {
                const { notifiedUser } = data.message;

                console.log(
                    "%cDOXLE SOCKETS=RECEIVE SUCCESS DATA",
                    "background:green; color:white"
                );
                console.log("notifiedUser", notifiedUser);
                // console.log("user", user);

                if (user?.userId === notifiedUser) {
                    console.log(
                        "%cREFRESH NOTIFICATIONS",
                        "background:green; color:white"
                    );
                    // refresh notifications
                    fetchNotifications(true);
                }
            } else {
                console.log(
                    "%cDOXLE SOCKETS=DATA ERROR",
                    "background:red; color:white"
                );
            }
        }
    }, []);

    useEffect(() => {
        socket.addEventListener("message", onMessage);

        return () => {
            socket.removeEventListener("message", onMessage);
        };
    }, [socket, onMessage]);
    //! SOCKETS --->

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "7px",
                height: "fit-content",
                gap: "3px",
            }}
        >
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={openSnack}
                autoHideDuration={3000}
                onClose={handleCloseSnack}
                message={
                    snackNotif && (
                        <Link
                            to={generateLink(snackNotif)}
                            target="_blank"
                            style={{ textDecoration: "none", color: "#000000" }}
                            onClick={() => {
                                handleUpdateSeen(snackNotif.notificationId);
                                handleClose();
                            }}
                        >
                            {snackNotif.shortText}&nbsp;
                            <span style={{ color: "#5A36BE" }}>
                                {snackNotif.longText}
                            </span>
                        </Link>
                    )
                }
                sx={{
                    "& .MuiSnackbarContent-root": {
                        background: "white",
                        color: "black",
                    },
                }}
            />
            {/* Notifications Icon */}
            <div
                style={{ width: "24px", height: "24px", position: "relative" }}
            >
                {notifications.length > 0 && (
                    <Badge
                        sx={{
                            position: "absolute",
                            right: "1px",
                            background: "#F9F9FF",
                            color: "red",
                            top: "-3px",
                            padding: "0 1px",
                            height: "11px",
                            zIndex: 1,
                            fontSize: "9px",
                            justifyContent: "center",
                        }}
                    >
                        {notifications.length}
                    </Badge>
                )}
                <IconButton
                    sx={{
                        width: "24px",
                        height: "24px",
                        m: 0,
                        p: 0,
                        alignItems: "flex-start",
                    }}
                    size="large"
                    aria-label="notification of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    {notifications.length > 0 ? (
                        <NotificationONIcon />
                    ) : (
                        <NotificationOFFIcon />
                    )}
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    sx={{
                        "& .MuiList-root": {
                            p: 0,
                            paddingBottom: "50px",
                        },
                        "& .MuiMenu-paper": {
                            minWidth: "300px",
                        },
                        "& .MuiMenuItem-root": {
                            fontFamily: "Roboto Mono",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "16px",
                            alignItems: "center",
                        },
                    }}
                >
                    <MenuItem
                        sx={{
                            justifyContent: "space-between",
                            marginBottom: "25px",
                            height: "35px",
                            paddingRight: "6px",
                            "&:hover": { background: "none" },
                            cursor: "default",
                        }}
                        disableRipple
                        divider
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                            }}
                        >
                            <div
                                style={{
                                    width: "24px",
                                    height: "24px",
                                    position: "relative",
                                }}
                            >
                                {notifications.length > 0 && (
                                    <Badge
                                        sx={{
                                            position: "absolute",
                                            right: "1px",
                                            background: "white",
                                            color: "red",
                                            top: "-3px",
                                            padding: "0 1px",
                                            height: "11px",
                                            zIndex: 1,
                                            fontSize: "9px",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {notifications.length}
                                    </Badge>
                                )}
                                <NotificationOFFIcon />
                            </div>
                            NOTIFICATION CENTER
                        </div>
                        <div>
                            {notifications.length > 0 && (
                                <IconButton
                                    sx={{
                                        width: "85px",
                                        height: "24px",
                                        m: 0,
                                        p: 0,
                                        cursor: "pointer",
                                        borderRadius: 1,
                                        background: "#F5F6F7",
                                    }}
                                    size="small"
                                    onClick={handleClearAll}
                                >
                                    <Typography
                                        style={{fontFamily: "Roboto Mono", fontSize: "small", color: "#5A36BE"}}>
                                        Clear All
                                    </Typography>
                                </IconButton>
                            )}
                            &nbsp;&nbsp;
                            <IconButton
                                sx={{
                                    width: "21px",
                                    height: "21px",
                                    m: 0,
                                    p: 0,
                                    cursor: "pointer",
                                    borderRadius: 1,
                                    background: "#F5F6F7",
                                }}
                                size="small"
                                onClick={() => handleClose()}
                            >
                                <CloseIcon sx={{ transform: "scale(0.8)" }} />
                            </IconButton>
                        </div>
                    </MenuItem>

                    {notifications.map((notification: Notification) => (
                        <NotificationItem
                            key={notification.notificationId}
                            notification={notification}
                            handleClose={handleClose}
                            handleUpdateSeen={handleUpdateSeen}
                            generateLink={generateLink}
                        />
                    ))}
                </Menu>
            </div>
        </div>
    );
};
export default Notifications;
