import { Button, Container, OutlinedInput, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ResetPassword from './ResetPassword'
import oLogoIcon2 from '../../../Assets/oLogin2.svg'
import circiLogoIcon from '../../../Assets/circiLogin.svg'
import buttonLoginIcon from '../../../Assets/buttonLoginIcon.svg'
import doxleLogo from '../../../Assets/doxleLogo.svg'
import { useAuth, authContextInterface } from '../../Providers/AuthProvider'
import "./Login.scss"
interface props {
  modal: boolean
  display: boolean
}

const Login: React.FC<props> = ({ modal }) => {
  const authContext = useAuth() as authContextInterface
  const { loginWithDetails, loggedIn, user } = authContext
  const [email, setEmail] = useState<string>(modal ? user?.userName ?? '' : '')
  const [password, setPassword] = useState<string>('')
  const backgroundColor: string = modal ? 'rgba(0, 0, 0, 0.3)' : 'white'

  const handleEmailChange = async (event: any) => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = async (event: any) => {
    setPassword(event.target.value)
  }

  const handleLogin = () => {
    if (loginWithDetails({ user: email, password: password })) {
      setPassword('')
    }
  }

  return (
    // <div
    //   style={{
    //     position: 'fixed',
    //     display: loggedIn ? 'none' : 'flex',
    //     flexDirection: 'column',
    //     width: '100%',
    //     height: '100vh',
    //     zIndex: '9999',
    //     backgroundColor: backgroundColor,
    //   }}
    // >
    //   <div
    //     style={{
    //       marginTop: '40vh',
    //       marginLeft: '20vw',
    //       marginRight: '20vw',
    //       marginBottom: '40vh',
    //       display: 'flex',
    //       flexDirection: 'column',
    //     }}
    //   >
    //     <div
    //       style={{
    //         display: 'flex',
    //         justifyContent: 'center',
    //       }}
    //     >
    //       <div
    //         style={{
    //           position: 'absolute',
    //           top: '33px',
    //           right: '33px',
    //           textAlign: 'right',
    //           display: 'flex',
    //           minHeight: '300px',
    //         }}
    //       >
    //         <img
    //           src={doxleLogo}
    //           alt='o login logo'
    //           height={120}
    //           style={{ display: 'none', maxHeight: '12.4vmax' }}
    //         />
    //       </div>
    //       <div
    //         style={{
    //           maxWidth: '295px',
    //           display: 'flex',
    //           flexDirection: 'column',
    //           gap: '1px',
    //         }}
    //       >
    //         <div>
    //           <div
    //             style={{
    //               textAlign: 'center',
    //               paddingBottom: '14px',
    //               fontStyle: 'normal',
    //               fontWeight: modal ? 'bold' : 'normal',
    //               fontFamily: 'Inter',
    //               fontSize: '11px',
    //               color: modal ? 'black' : '#0044CC',
    //             }}
    //           >
    //             Login to Doxle
    //           </div>
    //           <TextField
    //             size='small'
    //             fullWidth
    //             value={email}
    //             placeholder={'username'}
    //             sx={{
    //               borderRadius: '0px 0px 0px 5px',
    //             }}
    //             InputProps={{
    //               disableUnderline: true,
    //               sx: {
    //                 fontStyle: 'normal',
    //                 fontWeight: 'normal',
    //                 fontFamily: 'Inter',
    //                 fontSize: '11px',
    //                 '& .MuiInputBase-input': {
    //                   textAlign: 'left',
    //                   backgroundColor: '#F7F8FD',
    //                   color: '#4B4B5F',
    //                   paddingBottom: '1px',
    //                   paddingTop: '1px',
    //                   paddingLeft: '25px',
    //                   height: '43px',
    //                   width: '275px',
    //                   borderRadius: '5px 5px 0px 0px',
    //                 },
    //               },
    //             }}
    //             disabled={modal}
    //             variant='filled'
    //             onChange={(event: any) => {
    //               handleEmailChange(event)
    //             }}
    //             onKeyUp={(event: any) => {
    //               if (event?.keyCode === 13) {
    //                 handleLogin()
    //               }
    //             }}
    //             autoFocus
    //           />
    //         </div>

    //         <div
    //           style={{
    //             display: 'flex',
    //             flexDirection: 'row',
    //           }}
    //         >
    //           <TextField
    //             size='small'
    //             fullWidth
    //             variant='filled'
    //             placeholder={'password'}
    //             value={password}
    //             type='password'
    //             InputLabelProps={{
    //               style: { color: '##4B4B5F' },
    //               shrink: false,
    //             }}
    //             InputProps={{
    //               disableUnderline: true,
    //               sx: {
    //                 fontStyle: 'normal',
    //                 fontWeight: 'normal',
    //                 fontFamily: 'Inter',
    //                 fontSize: '11px',
    //                 borderRadius: '0px 0px 0px 5px',
    //                 '& .MuiInputBase-input': {
    //                   textAlign: 'left',
    //                   backgroundColor: '#F7F8FD',
    //                   paddingBottom: '1px',
    //                   paddingTop: '1px',
    //                   paddingLeft: '25px',
    //                   height: '43px',
    //                   width: '275px',
    //                   borderRadius: '0px 0px 0px 5px',
    //                 },
    //               },
    //             }}
    //             onChange={(event: any) => {
    //               handlePasswordChange(event)
    //             }}
    //             onKeyUp={(event: any) => {
    //               if (event.keyCode && event.keyCode === 13) {
    //                 handleLogin()
    //               }
    //             }}
    //           />
    //           <div
    //             style={{
    //               backgroundColor: '#F7F8FD',
    //               paddingBottom: '1px',
    //               paddingTop: '10px',
    //               paddingRight: '14px',
    //               borderRadius: '0px 0px 5px 0px',
    //             }}
    //           >
    //             <img
    //               src={buttonLoginIcon}
    //               alt='button login'
    //               onClick={(event: any) => handleLogin()}
    //               height='18px'
    //               width='100%'
    //             />
    //           </div>
    //         </div>
    //         <ResetPassword email={email} modal={modal} />
    //       </div>
    //       <div
    //         style={{
    //           position: 'absolute',
    //           bottom: '22px',
    //           left: '22px',
    //           textAlign: 'left',
    //           maxHeight: '20.4vmax',
    //         }}
    //       >
    //         <img src={doxleLogo} alt='circi login logo' width={66} />
    //         <p
    //           style={{
    //             fontFamily: 'Mina',
    //             fontStyle: 'normal',
    //             fontWeight: '400',
    //             fontSize: '8px',
    //             margin: '0 0 10px 15px',
    //             lineHeight: '5px',
    //             color: '#535D74',
    //           }}
    //         >
    //           version 1.2
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <main className="home-page">
        <Container>
          <img
            src={require("../../../Assets/logo.png")}
            alt="Doxle Logo"
            className="logo"
            height={64}
          />

          <p className="iconText">Log in to Doxle</p>
          <OutlinedInput placeholder="Username"  onChange={(event: any) => {
                  handleEmailChange(event)
                }}
                onKeyUp={(event: any) => {
                  if (event?.keyCode === 13) {
                    handleLogin()
                  }
                }}
                autoFocus/>
          <OutlinedInput placeholder="Password" type="password" 
           onChange={(event: any) => {
                          handlePasswordChange(event)
                        }}
                        onKeyUp={(event: any) => {
                          if (event.keyCode && event.keyCode === 13) {
                            handleLogin()
                          }
                        }}
          />
          <Button variant="contained" color="secondary" disableElevation onClick={handleLogin}>
            Log in
          </Button>
          {/* <p className="forgotPassword">Forgot password?</p> */}
          <ResetPassword email={email} modal={modal} />
        </Container>
        <p className="version">version 1.2</p>
      </main>
    </>
  )
}
export default Login
