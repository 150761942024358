import React, { useCallback, useEffect, useRef, useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import { BasicStage } from "../../../Models/stage";
import { useDetectClickOutside } from "react-detect-click-outside";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import stageAPI from "../../../Services/DoxleAPI/stageAPI";
import { Button } from "@mui/material";
import { ProjectCashflow } from "../../../Models/project";
type Props = {
  selectedStageForEditColor:
    | {
        stage: BasicStage;
        absPosLeft: string | undefined;
        absPosRight: string | undefined;
        rowOrder: number;
      }
    | undefined;
  setselectedStageForEditColor: Function;
  projectOfStage: ProjectCashflow;
  setprojectOfStage: Function;
};

const CashflowColorBoard = ({
  selectedStageForEditColor,
  setselectedStageForEditColor,
  projectOfStage,
  setprojectOfStage,
}: Props) => {
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn } = authContext;
  // const [colorCode, setcolorCode] = useState<string | undefined>(
  //   selectedStageForEditColor?.stage.color
  // );

  //####HANDLE CLICK OUTSIDE TO CLOSE PICKER BOARD####
  const componentRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      componentRef.current &&
      !componentRef.current.contains(event.target as Node)
    ) {
      setselectedStageForEditColor(undefined);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [componentRef]);
  //##########################################
  const handleUpdateStage = async (colorCode: string) => {
    if (selectedStageForEditColor)
      try {
        const response = await stageAPI.updateStage(
          selectedStageForEditColor.stage.stageId,
          {
            color: colorCode,
          }
        );
        if (response) {
          let newStageList: BasicStage[] = [];
          projectOfStage.stages.map((stage) => {
            if (stage.stageId === selectedStageForEditColor.stage.stageId) {
              newStageList.push({ ...stage, color: colorCode });
            } else newStageList.push(stage);
          });
          setprojectOfStage({ ...projectOfStage, stages: [...newStageList] });
          setselectedStageForEditColor(undefined);
        }
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
  };
  return (
    <RootCashflowColorBoard
      ref={componentRef}
      initial={{ scaleY: 0 }}
      animate={{ scaleY: [0, 1] }}
      transition={{ scaleY: { duration: 0.2 } }}
      exit={{ scaleY: [1, 0] }}
      style={{
        left: selectedStageForEditColor?.absPosLeft,
        right: selectedStageForEditColor?.absPosRight,
      }}
    >
      <SketchPicker
        presetColors={[]}
        color={selectedStageForEditColor?.stage.color}
        width={"120px"}
        onChange={(value) => {
          handleUpdateStage(value.hex);
        }}
        onChangeComplete={(value) => {
          handleUpdateStage(value.hex);
        }}
        disableAlpha={true}
      />
      {/* <SaveButton
        onClick={handleUpdateStage}
        sx={{
          "&:hover": {
            backgroundColor: "rgb(133, 93, 248)",
            opacity: 0.8,
          },
        }}
      >
        SAVE
      </SaveButton> */}
    </RootCashflowColorBoard>
  );
};

export default React.memo(CashflowColorBoard);

const RootCashflowColorBoard = styled(motion.div)`
  position: absolute;
  background-color: white;
  border-radius: 4px;
  bottom: -164px;
  bacground-color: blue;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 10px 10px 15px 2px rgba(0, 0, 0, 0.2);
  padding-bottom: 4px;
`;

const SaveButton = styled(Button)`
  background-color: rgb(133, 93, 248);
  margin-top: 4px;
  width: 40px;
  height: 30px;
  color: white;
`;
