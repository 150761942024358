import {nanoid} from "nanoid";
import {DoxCircle, DoxLine, DoxPolygon, DoxRectangle, Sheet, TakeOff, XY} from "../../../../Models/takeOffs";
import {toFloat} from "./generalFunctions";


function calculatePolygonPerimeter(points: XY[]){
    let perimeter = 0;
    for (let i = 1; i < points.length; i++){
        perimeter += Math.sqrt(Math.pow(toFloat(points[i].x) - toFloat(points[i-1].x),2) + Math.pow(toFloat(points[i].y) - toFloat(points[i-1].y) ,2));
    }
    perimeter += Math.sqrt(Math.pow(toFloat(points[points.length - 1].x) - toFloat(points[0].x),2) + Math.pow(toFloat(points[points.length - 1].y) - toFloat(points[0].y) ,2));
    return perimeter
}

function calculatePolygonArea(points: XY[]){
    let area = 0;
    for (let i = 1; i < points.length; i++){
        area += toFloat(points[i-1].x) * toFloat(points[i].y);
        area -= toFloat(points[i-1].y) * toFloat(points[i].x);
    }
    area += toFloat(points[points.length -1].x) * toFloat(points[0].y);
    area -= toFloat(points[points.length -1].y) * toFloat(points[0].x);
    area = area / 2;
    return Math.abs(area)
}


interface polygonMouseDownProps{
    currentShape: DoxCircle|DoxLine|DoxRectangle|DoxPolygon|null
    setCurrentShape: Function
    currentUnit: "LM"|"M2"|"M3"|"EA"|undefined
    currentColour: string
    currentSheet: Sheet
    selectedTakeOff: TakeOff
    zHeight: number
    pos: { x: number, y: number}
    scale: number
    measurements: (DoxCircle|DoxLine|DoxRectangle|DoxPolygon)[]
    setMeasurements: Function
    setUnsavedMeasurements: Function;
    deduction: 1|-1;
}

const polygonMouseDown = ({
                             currentShape,
                             setCurrentShape,
                             currentUnit,
                             currentColour,
                             currentSheet,
                             selectedTakeOff,
                             zHeight,
                             pos,
                             scale,
                             measurements,
                             setMeasurements,
                             setUnsavedMeasurements,
                             deduction
                         }: polygonMouseDownProps) => {
    if (!currentShape){
        setCurrentShape({
            borderColor: deduction < 0 ? "#000" : currentColour,
            bgColor: deduction < 0 ? "#000" : currentColour,
            bgThickness: 2,
            shape: "polygon",
            id: nanoid(),
            value: 0.0,
            zMultiplier: zHeight && currentUnit === "M3" ? zHeight : 1.0,
            zValue: 0.0,
            sheet: currentSheet?.sheetId,
            takeOff: selectedTakeOff?.takeOffId,
            points: [pos, pos]
        } as DoxPolygon)
    } else {
        let currentPolygon = currentShape as DoxPolygon
        const complete = Math.abs(toFloat(currentPolygon.points[0].x) - pos.x) <=10 && Math.abs(toFloat(currentPolygon.points[0].y) - pos.y) <=10
        setCurrentShape(null)
        let newPoints: XY[] = currentPolygon.points
        complete
            ? newPoints.splice(currentPolygon.points.length -1, 0, currentPolygon.points[0])
            : newPoints.splice(currentPolygon.points.length -1, 0, pos)
        let rawValue: number = 0;
        let scaledValue: number = 0;
        if (currentUnit === "LM") {
            rawValue = calculatePolygonPerimeter(newPoints) * deduction;
            scaledValue = rawValue * scale;
        } else if (currentUnit === "M2"){
            rawValue = calculatePolygonArea(newPoints) * deduction;
            scaledValue = rawValue * Math.pow(scale, 2);
        } else if (currentUnit === "M3"){
            rawValue = calculatePolygonArea(newPoints) * deduction;
            scaledValue = rawValue * zHeight * Math.pow(scale, 2);
        } else return

        currentPolygon.points = newPoints
        currentPolygon.value = rawValue
        currentPolygon.zValue = scaledValue

        if (complete) {
            setMeasurements([...measurements, currentPolygon]);
            setUnsavedMeasurements(true);
        } else { setCurrentShape({ ...currentPolygon}) }
    }
}

interface polygonMouseMoveProps{
    currentShape: DoxCircle|DoxLine|DoxRectangle|DoxPolygon
    setCurrentShape: Function
    currentUnit: "LM"|"M2"|"M3"|"EA"|undefined
    pos: { x: number, y: number}
    scale: number
    deduction: 1|-1;
}
const polygonMouseMove = ({currentShape, setCurrentShape, currentUnit, pos, scale, deduction}: polygonMouseMoveProps) => {
    const currentPolygon = currentShape as DoxPolygon
    let newPoints: XY[] = currentPolygon.points
    newPoints.splice(currentPolygon.points.length -1, 1, pos)
    let rawValue:number = 0;
    let scaledValue : number = 0;
    if (currentUnit === "M2"){
        rawValue = calculatePolygonArea(newPoints) * deduction
        scaledValue = rawValue * Math.pow(scale, 2)
    } else if (currentUnit === "M3"){
        rawValue = calculatePolygonArea(newPoints) * deduction
        scaledValue = rawValue * toFloat(currentShape.zMultiplier) * Math.pow(scale, 2)
    } else if (currentUnit === "LM") {
        rawValue = calculatePolygonPerimeter(newPoints) * deduction
        scaledValue = rawValue * toFloat(currentShape.zMultiplier) * scale
    } else return

    setCurrentShape({
        ...currentPolygon,
        points: newPoints,
        value: rawValue,
        zValue: scaledValue
    } as DoxPolygon)
}

export {polygonMouseDown, polygonMouseMove}