import React, { useState, useEffect } from 'react'
import { IconButton, Box, Button, Stack } from '@mui/material'
import Files from '../Files/Files'
import FolderList from './FolderList'
import { FileData, Folder } from '../../../Models/storage'
import storageAPI from '../../../Services/DoxleAPI/storageAPI'
import CustomDialog from '../../../utilities/Dialog/CustomDialog'
// import EditIcon from "../../../Assets/editIcon2.svg";
// import {ReactComponent as DeleteOutlineIcon} from "../../../Assets/Trash.svg";
import WindowDimensions from '../../../utilities/WindowDimensions/windowDimension'
import { authContextInterface, useAuth } from '../../Providers/AuthProvider'
import './fileIcon.css'
import '../Storage/Storage.css'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'

interface props {
  type: 'F' | 'P'
  projectId: string | null
  costCodeId: string | null
  folderList: Folder[]
  setFolderList: Function
  initFileList: FileData[]
  handleAddFolder?: Function
  setLoading: Function
}

const Storage: React.FC<props> = ({
  type,
  projectId,
  costCodeId,
  folderList,
  setFolderList,
  initFileList,
  handleAddFolder,
  setLoading,
}) => {
  const [fileList, setFileList] = useState<FileData[]>(initFileList)
  const [currentFolderId, setCurrentFolderId] = useState<string | null>(
    folderList?.[0]?.folderId
  )
  const [currentFolderName, setCurrentFolderName] = useState<string | null>(
    folderList?.[0]?.name
  )
  const [tickedFolders, setTickedFolders] = useState<string[]>([])
  const [tickedFiles, setTickedFiles] = useState<string[]>([])
  const [editIconClicked, setEditIconClicked] = useState<boolean>(false)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const { height, width } = WindowDimensions()
  let dialogInput: string | undefined = undefined
  const authContext = useAuth() as authContextInterface
  const { setLoggedIn } = authContext

  const fetchCostCodeStorage = async () => {
    setLoading(true)
    try {
      const folderResponse: Folder[] = await storageAPI.getFolderList(
        projectId,
        costCodeId,
        type
      )
      console.log('folderResponse', folderResponse)
      setFolderList(folderResponse)
      if (folderResponse.length > 0) {
        if (!currentFolderId) setCurrentFolderId(folderResponse[0].folderId)
        if (!currentFolderName) setCurrentFolderName(folderResponse[0].name)
      }
      const fileResponse = (await storageAPI.getFileList(
        projectId,
        costCodeId,
        null
      )) as FileData[]
      setFileList(fileResponse)
    } catch (err) {
      err === 'AccessTokenNotFound' ? setLoggedIn(false) : console.error(err)
    }
    setLoading(false)
  }

  const handleRename = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    dialogInput = undefined
    setTickedFolders([])
    setTickedFiles([])
  }

  const handleEditInput = (e: any) => {
    if (e.target && e.target.value && typeof e.target.value === 'string') {
      dialogInput = e.target.value
    }
  }

  const getFileCount = (folderId: string) => {
    let fileCount = 0
    fileList.forEach((file: FileData) => {
      if (file?.folder === folderId) fileCount++
    })
    return fileCount
  }

  const handleEditSubmit = async () => {
    try {
      // rename file
      if (dialogInput && tickedFiles.length === 1) {
        const response = await storageAPI.updateFile(
          tickedFiles?.[0],
          dialogInput
        )
        const newFiles: FileData[] = []
        if (response) {
          fileList.forEach((file) => {
            if (file.fileId !== tickedFiles?.[0]) {
              newFiles.push(file)
            } else {
              newFiles.push(response as FileData)
            }
          })
          setFileList([...newFiles])
        } else {
          fetchCostCodeStorage()
        }
      }
      // rename folder
      if (dialogInput && tickedFolders.length === 1) {
        const response = await storageAPI.updateFolder(
          tickedFolders?.[0],
          dialogInput
        )
        const newFolders: Folder[] = []
        if (response) {
          folderList.forEach((folder) => {
            if (folder.folderId !== tickedFolders?.[0]) {
              newFolders.push(folder)
            } else {
              newFolders.push(response as Folder)
            }
          })
          setFolderList([...newFolders])
        }
      }

      // reset
      setDialogOpen(false)
      setTickedFolders([])
      setTickedFiles([])
    } catch (err) {
      err === 'AccessTokenNotFound' ? setLoggedIn(false) : console.error(err)
    }
  }

  const getDialogTitle = () => {
    if (tickedFiles.length === 1) {
      return 'Edit File name'
    }
    if (tickedFolders.length === 1) {
      return 'Edit Folder name'
    }
    return 'Error'
  }

  const handleDeleteEachFile = async (fileId: string) => {
    setLoading(true)
    const fileDeteled = await storageAPI.deleteFile(fileId)
    if (fileDeteled) {
      const newFileList = (await storageAPI.getFileList(
        projectId ? projectId : null,
        costCodeId ? costCodeId : null,
        null
      )) as FileData[]
      if (newFileList.length > 0) {
        setFileList([...newFileList])
        setTickedFolders([])
        setTickedFiles([])
      } else {
        setFileList([])
        setTickedFolders([])
        setTickedFiles([])
      }
    }
    setLoading(false)
  }

  const handleDeleteFolder = async (folders: string[]) => {
    setLoading(true)
    try {
      // delete Folder
      const newFolderList = (await storageAPI.deleteFolders(
        folders,
        costCodeId,
        projectId
      )) as Folder[]
      console.log('newFolderList:', newFolderList)
      if (newFolderList.length > 0) {
        setFolderList([...newFolderList])
      } else {
        fetchCostCodeStorage()
      }
    } catch (err) {
      err === 'AccessTokenNotFound' ? setLoggedIn(false) : console.error(err)
    }
    setLoading(false)
  }

  const handleDeleteFile = async (files: string[]) => {
    try {
      files.forEach((fileId) => {
        handleDeleteEachFile(fileId)
      })
    } catch (err) {
      err === 'AccessTokenNotFound' ? setLoggedIn(false) : console.error(err)
    }
  }

  useEffect(() => {
    console.log('folderList', folderList)
    fetchCostCodeStorage()
  }, [costCodeId, projectId])

  return (
    <div
      style={{
        overflowX: width < 928 ? 'auto' : 'unset',
        paddingTop: '0px',
        display: 'flex',
        height: '100%',
      }}
    >
      {/* <div
                style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "30px",
                    lineHeight: "35px",
                    color: "#000000",
                }}
            >
                {type === "F" ? "Files" : "Photos"}
            </div> */}

      <Stack width={'25%'}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '7px',
            marginBottom: '10px',
            '.MuiButton-root': {
              borderRadius: '5px',
              textTransform: 'none',
              color: 'white',
              padding: '4px 10px 4px 10px',
            },
          }}
        >
          <Button
            variant='contained'
            startIcon={<AddIcon />}
            size='small'
            sx={{
              backgroundColor: '#5A36BE',
              '&.MuiButton-contained': {
                backgroundColor: '#5A36BE',
              },
              '&.MuiButton-contained:hover': {
                backgroundColor: '#5A36BE',
                opacity: '70%',
              },
              '&.MuiButton-contained.Mui-disabled': {
                backgroundColor: '#5A36BE',
                opacity: '70%',
                color: 'white',
              },
            }}
            onClick={() => handleAddFolder && handleAddFolder()}
          >
            Folder
          </Button>
          {/* {tickedFolders.length === 1 && (
                        <Button
                            variant="contained"
                            startIcon={<EditIcon />}
                            size="small"
                            sx={{
                                backgroundColor: "#3B8EA0",
                                "&.MuiButton-contained": {
                                    backgroundColor: "#3B8EA0",
                                },
                                "&.MuiButton-contained:hover": {
                                    backgroundColor: "#3B8EA0",
                                    opacity: "70%",
                                },
                                "&.MuiButton-contained.Mui-disabled": {
                                    backgroundColor: "#3B8EA0",
                                    opacity: "70%",
                                    color: "white",
                                },
                            }}
                            disabled={tickedFolders.length === 1 ? false : true}
                            onClick={() => handleRename()}
                        >
                            Edit
                        </Button>
                    )}
                    {tickedFolders.length > 0 && (
                        <Button
                            variant="contained"
                            startIcon={<DeleteOutlineIcon />}
                            size="small"
                            sx={{
                                backgroundColor: "#CD326A",
                                "&.MuiButton-contained": {
                                    backgroundColor: "#CD326A",
                                },
                                "&.MuiButton-contained:hover": {
                                    backgroundColor: "#CD326A",
                                    opacity: "70%",
                                },
                                "&.MuiButton-contained.Mui-disabled": {
                                    backgroundColor: "#CD326A",
                                    opacity: "70%",
                                    color: "white",
                                },
                            }}
                            disabled={tickedFolders.length > 0 ? false : true}
                            onClick={handleDeleteFolder}
                        >
                            Delete
                        </Button>
                    )} */}
        </Box>

        <FolderList
          folders={folderList}
          setFolderList={setFolderList}
          currentFolderId={currentFolderId}
          setCurrentFolderId={setCurrentFolderId}
          setCurrentFolderName={setCurrentFolderName}
          tickedFolders={tickedFolders}
          setTickedFolders={setTickedFolders}
          setTickedFiles={setTickedFiles}
          handleAddFolder={handleAddFolder}
          handleDeleteFolder={handleDeleteFolder}
          setDialogOpen={setDialogOpen}
          getFileCount={getFileCount}
        />
      </Stack>

      {folderList.length > 0 && (
        <Stack width={'75%'}>
          <Files
            action='storage'
            editIconClicked={editIconClicked}
            setEditIconClicked={setEditIconClicked}
            files={fileList}
            currentFolderId={currentFolderId}
            currentFolderName={currentFolderName}
            setFileList={setFileList}
            tickedFiles={tickedFiles}
            setTickedFiles={setTickedFiles}
            setTickedFolders={setTickedFolders}
            handleRename={handleRename}
            handleDeleteFile={handleDeleteFile}
            costCodeId={costCodeId}
            type={type}
            projectId={projectId}
          />
        </Stack>
      )}

      <CustomDialog
        open={dialogOpen}
        handleClose={handleDialogClose}
        handleDialogChange={handleEditInput}
        handleAddDialog={handleEditSubmit}
        dialogTitle={getDialogTitle()}
        dialogContentText={'Enter new name'}
        action={'filename'}
      />
    </div>
  )
}

export default Storage
