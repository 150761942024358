import axios from "axios";
import { BasicCostCode, Costcode, NewCostCode } from "../../Models/costcode";
import { Stage } from "../../Models/stage";
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";
import dummy from "./dummyData";

interface GetParams {
  projectId: string;
  orderBy?: string;
  page?: number;
}

const getList = async ({
  projectId,
  orderBy = "title",
  page = 1,
}: GetParams) => {
  // Get's a list of costcodes for a given project selected with project Id
  // Order costcode with orderBy, options =  title, startDate, endDate
  // Pagination 100 costcodes per result - call for next page when scrolling
  //   let result: Costcode[] = []
  let result: Costcode[] = [];
  // if (offline){ result = dummy.costCodeListResponse.results; return result }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    let params = { project: projectId, ordering: orderBy, page: page };
    const response = await axios.get(baseAddress + "/costcode/", {
      headers: { Authorization: "Bearer " + accessToken },
      params: params,
    });
    //   result = response.data.results as Array<Costcode>;
    result = response.data.results as Array<Costcode>;
    console.log("COSTCODES", result);
  } catch (error) {
    console.error("ERROR FETCHING COSTCODES", error);
  }
  return result;
};

const getBasicList = async ({
  projectId,
  orderBy = "quick",
  page = 1,
}: GetParams) => {
  // Get's a list of costcodes for a given project selected with project Id
  // Order costcode with orderBy, options =  title, startDate, endDate
  // Pagination 100 costcodes per result - call for next page when scrolling
  //   let result: Costcode[] = []
  let result: BasicCostCode[] = [];
  // if (offline){ result = dummy.costCodeListResponse.results; return result }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    let params = { project: projectId, ordering: orderBy, page: page };
    const response = await axios.get(baseAddress + "/costcode/", {
      headers: { Authorization: "Bearer " + accessToken },
      params: params,
    });
    //   result = response.data.results as Array<Costcode>;
    result = response.data.results as BasicCostCode[];
  } catch (error) {
    console.error("ERROR FETCHING COSTCODES", error);
  }
  return result;
};

const getStagedList = async ({
  projectId,
  orderBy = "title",
  page = 1,
}: GetParams) => {
  // Get's a list of costcodes for a given project selected with project Id
  // Order costcode with orderBy, options =  title, startDate, endDate
  // Pagination 100 costcodes per result - call for next page when scrolling
  //   let result: Costcode[] = []
  let result: Stage[] = [];
  // if (offline){ result = dummy.costCodeListResponse.results; return result }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    let params = { project: projectId, ordering: "stage", page: page };
    const response = await axios.get(baseAddress + "/costcode/", {
      headers: { Authorization: "Bearer " + accessToken },
      params: params,
    });
    //   result = response.data.results as Array<Costcode>;
    result = response.data.results as Array<Stage>;
    console.log("STAGES", result);
  } catch (error) {
    console.error("ERROR FETCHING COSTCODES", error);
  }
  return result;
};

const getDetailed = async (costCodeId: string) => {
  // Gets one costcode only using the costcode's UUID
  // At this stage there is no additional information from the detailed
  // Future back end change will mean this call will get storage, specs, comments with this call
  let result: Costcode | undefined = undefined;
  if (offline) {
    /* result = dummy.costCodeFullResponse;*/ return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }

  try {
    const response = await axios.get(
      baseAddress + "/costcode/detail/" + costCodeId + "/",
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.data as Costcode;
    console.log("COSTCODE DETAILED", result);
  } catch (error) {
    console.error("ERROR FETCHING COSTCODE", error);
  }
  return result;
};

const getBilledDetails = async (costCodeId: string) => {
  let result: any | undefined = undefined;
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }

  try {
    const response = await axios.get(
      baseAddress + "/costcode/" + costCodeId + "/billed_details/",
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.data.bills;
    console.log("COSTCODE INVOICES", result);
  } catch (error) {
    console.error("ERROR FETCHING COSTCODE", error);
  }
  return result;
};

const remove = async (costCodeId: string) => {
  let result: boolean = false;
  if (offline) {
    result = true;
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }

  try {
    if (offline) {
      return {};
    }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken) {
      throw "AccessTokenNotFound";
    }

    const response = await axios.delete(
      baseAddress + "/costcode/" + costCodeId + "/",
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.status === 204;
  } catch (error) {
    console.error("ERROR DELTING COSTCODE", error);
  }
  return result;
};

const add = async (costCode: NewCostCode) => {
  // Add a new costcode
  let result: Costcode | undefined = undefined;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    const response = await axios.post(
      baseAddress + "/costcode/",
      costCode,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    const result = response.data as Costcode;
    console.log("ADDED COSTCODE", result);
  } catch (error) {
    console.error("ERROR ADDING COSTCODE", error);
  }
  return result;
};

interface UpdateBody {
  title?: string;
  budget?: string;
  startDate?: string;
  endDate?: string;
  status?: string;
  income?: boolean;
  locked?: boolean;
  budgetOverride?: boolean;
  completed?: boolean;
  assignedUsers?: string[];
  assignedContractor?: string | null;
  stage?: string;
  accountTrackingId?: string | null;
}

const update = async (
  costCodeId: string,
  {
    title,
    budget,
    startDate,
    endDate,
    status,
    income,
    locked,
    budgetOverride,
    completed,
    assignedUsers,
    assignedContractor,
    stage,
    accountTrackingId,
  }: UpdateBody
) => {
  // Update one or more property of a costcode without affecting other values

  let result: Costcode | undefined;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    let body: UpdateBody = {};
    if (title) {
      body.title = title;
    }
    if (budget) {
      body.budget = budget;
    }
    if (startDate) {
      body.startDate = startDate;
    }
    if (endDate) {
      body.endDate = endDate;
    }
    if (status) {
      body.status = status;
    }
    if (income) {
      body.income = income;
    }
    if (locked) {
      body.locked = locked;
    }
    if (budgetOverride) {
      body.budgetOverride = budgetOverride;
    }
    if (assignedUsers) {
      body.assignedUsers = assignedUsers;
    }
    if (accountTrackingId === null) {
      body.accountTrackingId = null;
    }
    if (stage) {
      body.stage = stage;
    }
    if (typeof assignedContractor !== "undefined") {
      body.assignedContractor = assignedContractor;
    }
    if (typeof completed === "boolean") {
      body.completed = completed;
    }

    const response = await axios.patch(
      baseAddress + "/costcode/" + costCodeId + "/",
      body,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.data as Costcode;
    console.log("UPDATED COSTCODE", result);
  } catch (error) {
    console.error("ERROR UPDATING COSTCODE", error);
  }
  return result;
};

interface MultiUpdateBody {
  costCodeId: string;
  title?: string;
  budget?: string;
  startDate?: string;
  endDate?: string;
  status?: string;
  income?: boolean;
  locked?: boolean;
  budgetOverride?: boolean;
  completed?: boolean;
  assignedUsers?: string[];
  assignedContractor?: string | null;
  stage?: string;
  accountTrackingId?: string | null;
}
interface MultiBody {
  costCodes: MultiUpdateBody[];
}
const updateMany = async (costCodes: MultiUpdateBody[]) => {
  // Update one or more property of a costcode without affecting other values
  interface MultiResultInterface {
    costCodes: Costcode[];
    errors: string[];
  }
  let result: MultiResultInterface = {
    costCodes: [],
    errors: [],
  };
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    let body: MultiBody = { costCodes: costCodes };
    console.log("BODY PASSED:", body);
    const response = await axios.post(
      baseAddress + "/costcode/multi_update/",
      body,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );

    result = response.data as MultiResultInterface;
    //console.log("UPDATED COSTCODE", result)
  } catch (error) {
    console.error("ERROR UPDATING COSTCODE", error);
  }
  return result;
};

interface QuickValuesBody {
  costCode: string
  qty?: number;
  uom?: string;
  cost?: number;
}
const updateQuickValues = async (body: QuickValuesBody) => {
  let result: undefined|Costcode = undefined
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) throw "AccessTokenNotFound";
  try {
    const response = await axios.post(
      baseAddress + "/costcode/quick/",
      body,
      {
        headers: {Authorization: "Bearer " + accessToken},
      }
    );
    result = response.data as Costcode;
  } catch (error) {
    console.log("UPDATE QUICK VALUES ERROR", error)
  }
  return result
}

const CostCodeAPI = {
  getList,
  getStagedList,
  getDetailed,
  getBilledDetails,
  remove,
  update,
  add,
  getBasicList,
  updateMany,
  updateQuickValues
};

export default CostCodeAPI;
