import React, { useState, useEffect } from "react";
import {
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
} from "@mui/material";
import accountingService from "../../../Services/accountingService";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import { Project } from "../../../Models/project";
import { Costcode } from "../../../Models/costcode";
import { Stage, StageWithCostCode } from "../../../Models/stage";
import { User } from "../../../Models/user";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import { formatScheduleDate } from "../../../utilities/constants";
import Loading from "../../../utilities/Lottie/Loading";
import CostCodesDetails from "../CostCodesDetails/CostCodesDetails";
// import CostCodeRow from  './CostCodeRow';
import "../CostCodes/costCode.css";
import { useStyles } from "./costcodeStyle";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
// import userAPI from "../../../Services/DoxleAPI/userAPI";
import { AddressBookCompany } from "../../../Models/addressBook";
// import addressBookAPI from "../../../Services/DoxleAPI/addressBookAPI";
import CostCodeStage from "./CostCodeStage";
import { ColumnView } from "../../../Models/columnView";
import { getCalculationColumn } from "./calculateColumn";
import stageAPI from "../../../Services/DoxleAPI/stageAPI";
import StageHeader from "./StageHeader";

interface props {
  selectedProject: Project | null;
  costCodeFilter: string | null;
  editTitleMode: boolean;
  setEditTitleMode: Function;
  users: User[];
  contractors: AddressBookCompany[];
  userFilter: string[];
  columnView: ColumnView;
}

const CostCodeStageTable: React.FC<props> = ({
  selectedProject,
  costCodeFilter,
  editTitleMode,
  setEditTitleMode,
  users,
  contractors,
  userFilter,
  columnView,
}) => {
  const [stages, setStages] = useState<Array<StageWithCostCode>>([]);
  const [openStage, setOpenStage] = useState<string>("");
  const [selectedCostCode, setSelectedCostCode] = useState<
    Costcode | undefined
  >(undefined);
  const [tabIndex, setTabIndex] = useState<string>("7");
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [budgetTotal, setBudgetTotal] = React.useState<number>(0);
  const [orderTotal, setOrderTotal] = React.useState<number>(0);
  const [actualTotal, setActualTotal] = React.useState<number>(0);
  const [runningTotal, setRunningTotal] = React.useState<number>(0);
  const [editTitleOption, setEditTitleOption] = React.useState<string | null>(
    null
  );
  const [minDate, setMinDate] = React.useState<string>("");
  const [maxDate, setMaxDate] = React.useState<string>("null");
  const unmounted = useMountStatus();
  const [loading, setLoading] = useState<boolean>(false);
  const { height, width } = WindowDimensions();
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, user } = authContext;
  const projectStatus: string = selectedProject
    ? selectedProject.projectStatus
    : "";
  const costcodes: Costcode[] =
    stages.length > 0
      ? stages
          .map((stage: StageWithCostCode) => stage?.costCodes || [])
          .reduce(function (a, b) {
            return a.concat(b);
          })
      : [];
  const costCodesIds: string[] = costcodes.map((cc) => cc.costCodeId);

  const formatter: Intl.NumberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const incrementSelectedCostCode = (inc: -1 | 1, checkOnly: boolean) => {
    if (!selectedCostCode?.costCodeId) return false;
    const costCodeIndex = costCodesIds.indexOf(selectedCostCode?.costCodeId);
    if (costCodeIndex + inc < 0 || costCodeIndex + inc >= costcodes.length)
      return false;
    if (!checkOnly) setSelectedCostCode(costcodes?.[costCodeIndex + inc]);
    return true;
  };

  interface updateTotalProps {
    budgetDelta?: number;
    orderDelta?: number;
    runningDelta?: number;
  }
  const updateTotals = ({
    budgetDelta,
    orderDelta,
    runningDelta,
  }: updateTotalProps) => {
    if (budgetDelta) {
      setBudgetTotal(budgetTotal + budgetDelta);
    }
    if (orderDelta) {
      setOrderTotal(orderTotal + orderDelta);
    }
    if (runningDelta) {
      setRunningTotal(runningTotal + runningDelta);
    }
  };

  const handleRowClick = (costcode: Costcode, tab: string) => {
    setSelectedCostCode(costcode);
    setTabIndex(tab);
    setOpenModal(true);
    if (costcode.costCodeId)
      sessionStorage.setItem("currentCostCodeId", costcode.costCodeId);
    if (costcode)
      sessionStorage.setItem("currentCostCode", JSON.stringify(costcode));
  };

  const handleClose = () => {
    setOpenModal(false);
    setSelectedCostCode(undefined);
    sessionStorage.removeItem("currentCostCode");
    sessionStorage.removeItem("currentCostCodeId");
  };

  const calculateTotals = () => {
    let startDates: number[] = [];
    let endDates: number[] = [];
    let totalBudget: number = 0;
    let totalActual: number = 0;
    let totalOrders: number = 0;
    let totalRunning: number = 0;
    stages.forEach((stage: Stage) => {
      if (!stage.costCodes) return;
      stage.costCodes.forEach((costcode: Costcode) => {
        startDates.push(Number(new Date(costcode.startDate)));
        endDates.push(Number(new Date(costcode.endDate)));
        if (costcode !== null && !costcode.income) {
          totalBudget += parseFloat(costcode.budget);
          totalActual += costcode.actual || 0;
          totalOrders += parseFloat(costcode.orders || "0");
          // if (costcode.completed && costcode.actual){
          //     totalRunning += costcode.actual
          if (costcode.completed) {
            totalRunning += costcode?.actual || 0;
          } else {
            totalRunning += Math.max(
              parseFloat(costcode.budget),
              parseFloat(costcode.orders || "0"),
              costcode.actual || 0
            );
          }
        }
      });
    });
    if (startDates.length > 0)
      setMinDate(
        formatScheduleDate(new Date(Math.min(...startDates)).toISOString())
      );
    if (endDates.length > 0)
      setMaxDate(
        formatScheduleDate(new Date(Math.max(...endDates)).toISOString())
      );
    setBudgetTotal(totalBudget);
    setOrderTotal(totalOrders);
    setActualTotal(totalActual);
    setRunningTotal(totalRunning);
  };

  const fetchActuals = async (initResponse: StageWithCostCode[]) => {
    try {
      const updatedStages = await accountingService.appendActualsStage(
        initResponse
      );
      setStages([...updatedStages]);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const fetchStages = async () => {
    setLoading(true);
    if (selectedProject) {
      try {
        const response = (await stageAPI.getStageListWithCostCodes(
          selectedProject.projectId
        )) as StageWithCostCode[];
        if (unmounted) return;
        setStages([...response]);
        // setCostCodes([...response]);
        calculateTotals();
        fetchActuals([...response]);
        calculateTotals();
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    }
    setLoading(false);
  };

  const handleDeleteCostcode = async (costcode: Costcode) => {
    if (!costcode.costCodeId) return;
    const result = await CostCodeAPI.remove(costcode.costCodeId);

    let newStages: StageWithCostCode[] = [];
    stages.forEach((stage: StageWithCostCode) => {
      let newCostCodes: Costcode[] = [];
      if (result)
        stage.costCodes.forEach((cc) => {
          if (!(cc.costCodeId === costcode.costCodeId)) newCostCodes.push(cc);
        });
      stage.costCodes = newCostCodes;
      newStages.push(stage);
    });
    setStages([...newStages]);
    setEditTitleMode(false);
    setEditTitleOption(null);
  };

  const updateOrders = (costCodeId: string, orders: string) => {
    let newStages: StageWithCostCode[] = stages;
    newStages.forEach((stage: StageWithCostCode) => {
      let newCostCodes: Costcode[] = [];
      stage.costCodes.forEach((costCode: Costcode) => {
        if (costCode.costCodeId === costCodeId) costCode.orders = orders;
        newCostCodes.push(costCode);
      });
      stage.costCodes = newCostCodes;
    });
    setStages([...newStages]);
  };

  const updateBudget = (costCodeId: string, newBudget: string) => {
    let newStages: StageWithCostCode[] = [];
    stages.forEach((stage: StageWithCostCode) => {
      let newCostCodes: Costcode[] = [];
      stage.costCodes.forEach((costCode: Costcode) => {
        if (costCode.costCodeId === costCodeId) costCode.budget = newBudget;
        newCostCodes.push(costCode);
      });
      stage.costCodes = newCostCodes;
      newStages.push(stage);
    });
    setStages([...newStages]);
  };

  useEffect(() => {
    calculateTotals();
  }, [stages]);

  useEffect(() => {
    fetchStages();
  }, [selectedProject]);


    return(
        <>
        <CostCodesDetails 
            selectedTabIndex={tabIndex}
            // setSelectedTabIndex={setTabIndex}
            selectedCostCode={selectedCostCode}
            openModal={openModal}
            closeModal={handleClose}
            selectedProjectAddress={selectedProject && selectedProject.siteAddress}
            updateBudget={updateBudget}
            updateOrders={updateOrders}
            height={height}
            width={width}
            users={users}
            contractors={contractors}
            project={selectedProject}
            incrementSelectedCostCode={incrementSelectedCostCode}
        />
        <div id="costcodeGroup">
            {                
            loading
            ?
            <>
            <div style={{marginTop:"15%"}}>
                <Loading/>
            </div>
            </>
            :
            // <TableContainer sx={{ maxHeight: width < 928 ? (height-57)+"px" : "95.5vh" }}>
            // <TableContainer sx={{ height: "calc(100vh - 108px - 48px)" }}>
            <div style={{position: "relative"}}>
                { stages.length > 0 && <StageHeader project={selectedProject} stages={stages}/>}
                <div id="sticker" style={{ background: "#D5D7E3", width: "10px", height: "16px", position: "absolute", zIndex: 900, top: 0, right: 0}}/>
                <TableContainer sx={{ height: "calc(100vh - 185px)" }}>
                    <Table size="small" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow className="tableHeadStyle" sx={{background: "#D5D7E3"}}>
                                <TableCell sx={{...useStyles.tableHeadingStyle, width: 48}}></TableCell>
                                <TableCell colSpan={2} sx={useStyles.tableHeadingStyle}
                                    style={{
                                        position: "sticky", left: 0, zIndex: 99, 
                                        textAlign: "left", paddingLeft: "16px"
                                    }} 
                                >
                                        ACCOUNTS
                                </TableCell>
                                { columnView?.notesIcon && <TableCell sx={{maxWidth: "40px", ...useStyles.tableHeadingStyle}}/>}
                                { columnView?.photoIcon && <TableCell sx={{maxWidth: "40px", ...useStyles.tableHeadingStyle}}/>}
                                { columnView?.fileIcon && <TableCell sx={{maxWidth: "40px", ...useStyles.tableHeadingStyle}}/>}
                                { columnView?.checklistIcon && <TableCell sx={{maxWidth: "40px", ...useStyles.tableHeadingStyle}}/>}
                                { columnView?.signedBills && <TableCell sx={{maxWidth: "40px", ...useStyles.tableHeadingStyle}}/>}
                                { columnView?.commentIcon && <TableCell sx={{maxWidth: "40px", ...useStyles.tableHeadingStyle}}/>}
                                { columnView?.assignee && 
                                    <TableCell sx={useStyles.tableHeadingStyle}>ASSIGNEE</TableCell>}
                                { columnView?.contractor && 
                                    <TableCell sx={useStyles.tableHeadingStyle}>CONTRACTOR</TableCell>}
                                { columnView?.budget && 
                                    <TableCell sx={useStyles.tableHeadingStyle}>BUDGET</TableCell>}
                                { columnView?.order && 
                                    <TableCell sx={useStyles.tableHeadingStyle}>ORDER</TableCell>}
                                { columnView?.billed &&
                                    <TableCell sx={useStyles.tableHeadingStyle}>BILLED</TableCell>}
                                { columnView?.running &&
                                    <TableCell sx={useStyles.tableHeadingStyle}>RUNNING</TableCell>}
                                {/*{columnView?.difference && (*/}
                                {false &&
                                    <TableCell sx={useStyles.tableHeadingStyle}>{getCalculationColumn().title}</TableCell>}
                                { columnView?.startDate && 
                                    <TableCell sx={useStyles.tableHeadingStyle}>START</TableCell>}
                                { columnView?.endDate && 
                                    <TableCell sx={useStyles.tableHeadingStyle}>FINISH</TableCell>}
                                { columnView?.days && 
                                    <TableCell sx={useStyles.tableHeadingStyle}>DAYS</TableCell>}
                                {/*<TableCell sx={useStyles.tableHeadingStyle}>STATUS</TableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody className="tableBodyStyle">
                            {stages.map((stage, i) => (
                                <CostCodeStage
                                    index={i}
                                    key={stage.title}
                                    stage={stage}
                                    openStage={openStage}
                                    setOpenStage={setOpenStage}
                                    user={user}
                                    users={users}
                                    contractors={contractors}
                                    editTitleMode={editTitleMode}
                                    setEditTitleMode={setEditTitleMode}
                                    editTitleOption={editTitleOption}
                                    setEditTitleOption={setEditTitleOption}
                                    handleDeleteCostcode={handleDeleteCostcode}
                                    updateTotals={updateTotals}
                                    projectStatus={projectStatus} 
                                    costCodeFilter={costCodeFilter} 
                                    handleRowClick={handleRowClick}
                                    userFilter={userFilter}
                                    columnView={columnView}
                                    fetchStages={fetchStages}
                                    />
                                ))
                            }
                        </TableBody>
                        <TableFooter style={{display: costCodeFilter === "clientMode" ? "none" : "table-footer-group"}}>
                            <TableRow 
                            className='tableFootStyle'
                            sx={{
                                "& .MuiTableCell-footer": {
                                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                                    borderBottom: "none"
                                }
                            }}>
                                <TableCell style={{position: "sticky", left: 0, zIndex: 99}}/>
                                <TableCell style={{position: "sticky", left: 0, zIndex: 99}} sx={{...useStyles.tableFooterTotalStyle, paddingLeft: 0, fontWeight:600}}>Total</TableCell>
                                <TableCell style={{position: "sticky", left: 0, zIndex: 99}}/>
                                { columnView?.notesIcon && <TableCell sx={{maxWidth: "40px", ...useStyles.tableFooterTotalStyle}}/>}
                                { columnView?.photoIcon && <TableCell sx={{maxWidth: "40px", ...useStyles.tableFooterTotalStyle}}/>}
                                { columnView?.fileIcon && <TableCell sx={{maxWidth: "40px", ...useStyles.tableFooterTotalStyle}}/>}
                                { columnView?.checklistIcon && <TableCell sx={{maxWidth: "40px", ...useStyles.tableFooterTotalStyle}}/>}
                                { columnView?.signedBills && <TableCell sx={{maxWidth: "40px", ...useStyles.tableFooterTotalStyle}}/>}
                                { columnView?.commentIcon && <TableCell sx={{maxWidth: "40px", ...useStyles.tableFooterTotalStyle}}/>}
                                { columnView?.assignee && <TableCell/>}
                                { columnView?.contractor && <TableCell/>}
                                { columnView?.budget && <TableCell sx={{...useStyles.tableFooterTotalStyle, textAlign: !openStage ? "center" : "unset", fontWeight:600}}>{formatter.format(budgetTotal)}</TableCell>}
                                { columnView?.order && <TableCell sx={{...useStyles.tableFooterTotalStyle, textAlign: !openStage ? "center" : "unset", fontWeight:600}}>{formatter.format(orderTotal)}</TableCell>}
                                { columnView?.billed && <TableCell sx={{...useStyles.tableFooterTotalStyle, textAlign: !openStage ? "center" : "unset", fontWeight:600}}>{formatter.format(actualTotal)}</TableCell>}
                                { columnView?.running && <TableCell sx={{...useStyles.tableFooterTotalStyle, textAlign: !openStage ? "center" : "unset", fontWeight:600}}>{formatter.format(runningTotal)}</TableCell>}
                                {/*{ columnView?.difference && <TableCell/>}*/}
                                { false && <TableCell/>}
                                { columnView?.startDate && <TableCell sx={{...useStyles.tableFooterDateTotalStyle, fontWeight:600}}>{minDate}</TableCell>}
                                { columnView?.endDate && <TableCell sx={{...useStyles.tableFooterDateTotalStyle, fontWeight:600}}>{maxDate}</TableCell>}
                                { columnView?.days && <TableCell/>}
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
            }
        </div> 
        </> 
    )
}

export default CostCodeStageTable;
