import { Box } from "@mui/material";
import { styled } from "@mui/styles";
import _ from "lodash";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Mail } from "../../../Models/mail";
import "../drawer.css";
import MailroomExpandContent from "./MailroomExpandContent";

type Props = {
  itemTitle: string;
};
const mailroomDummyData: Mail[] = [
  {
    mailId: "1",
    subject: "Brandon Yokor Windows",
    body: "Supply and install here's a bit issue at the moment with the Shanghai lockdown. No one expects it lasted so long and still ongoing. The problem is not from us, but the others like glass and hardware suppliers and powdercoating factory. We are working with our team to find out ways and estimate the impact now.We should be able to get back to you after the Easter holiday. Enjoy the holiday and speak to you soon after! ribloc drians at 6m lengths",
    assignee: "BS",
    pinned: false,
    status: "I",
    timeStamp: "24.03.2021",
    section: "projects",
  },
  {
    mailId: "2",
    subject: "Peter project",
    body: "Supply and install here's a bit issue at the moment with the Shanghai lockdown. No one expects it lasted so long and still ongoing. The problem is not from us, but the others like glass and hardware suppliers and powdercoating factory. We are working with our team to find out ways and estimate the impact now.We should be able to get back to you after the Easter holiday. Enjoy the holiday and speak to you soon after! ribloc drians at 6m lengths",
    assignee: "BS",
    pinned: true,
    status: "I",
    timeStamp: "24.03.2021",
    section: "projects",
  },
  {
    mailId: "3",
    subject: "Peter 1231123",
    body: "Supply and install here's a bit issue at the moment with the Shanghai lockdown. No one expects it lasted so long and still ongoing. The problem is not from us, but the others like glass and hardware suppliers and powdercoating factory. We are working with our team to find out ways and estimate the impact now.We should be able to get back to you after the Easter holiday. Enjoy the holiday and speak to you soon after! ribloc drians at 6m lengths",
    assignee: "BS",
    pinned: false,
    status: "I",
    timeStamp: "24.03.2021",
    section: "projects",
  },
  {
    mailId: "4",
    subject: "Fix bathroom",
    body: "Delivery is coming this friday now, please be ready on site . I will contact Maju if there are any issues and notify me if there is any issues on site, so I can cancel the order",
    assignee: "MJ",
    pinned: true,
    status: "W",
    timeStamp: "27.03.2021",
    section: "maintenance",
  },
  {
    mailId: "5",
    subject: "Fix floor",
    body: "Delivery is coming this friday now, please be ready on site . I will contact Maju if there are any issues and notify me if there is any issues on site, so I can cancel the order",
    assignee: "MJ",
    pinned: true,
    status: "W",
    timeStamp: "27.03.2021",
    section: "maintenance",
  },
  {
    mailId: "6",
    subject: "Your Cleanaway Invoice",
    body: "Your latest Cleanaway invoice is now available. Click on the link to view and/or download your invoice. Alternatively open the attachment. Please ensure payment is received by the due date.",
    assignee: "MJ",
    pinned: false,
    status: "N",
    timeStamp: "29.04.2021",
    section: "invoices",
  },
  {
    mailId: "7",
    subject: "Adbri Masonry Pty Ltd",
    body: "Your latest Cleanaway invoice is now available. Click on the link to view and/or download your invoice. Alternatively open the attachment. Please ensure payment is received by the due date.",
    assignee: "MJ",
    pinned: false,
    status: "N",
    timeStamp: "29.04.2021",
    section: "invoices",
  },
  {
    mailId: "8",
    subject: "Your Cleanaway Invoice",
    body: "Your latest Cleanaway invoice is now available. Click on the link to view and/or download your invoice. Alternatively open the attachment. Please ensure payment is received by the due date.",
    assignee: "MJ",
    pinned: false,
    status: "N",
    timeStamp: "29.04.2021",
    section: "quotes",
  },
  {
    mailId: "9",
    subject: "Adbri Masonry Pty Ltd",
    body: "Your latest Cleanaway invoice is now available. Click on the link to view and/or download your invoice. Alternatively open the attachment. Please ensure payment is received by the due date.",
    assignee: "MJ",
    pinned: false,
    status: "N",
    timeStamp: "29.04.2021",
    section: "quotes",
  },
  {
    mailId: "10",
    subject: "Your Cleanaway Invoice",
    body: "Your latest Cleanaway invoice is now available. Click on the link to view and/or download your invoice. Alternatively open the attachment. Please ensure payment is received by the due date.",
    assignee: "MJ",
    pinned: false,
    status: "N",
    timeStamp: "29.04.2021",
    section: "quotes",
  },
  {
    mailId: "11",
    subject: "Adbri Masonry Pty Ltd",
    body: "Your latest Cleanaway invoice is now available. Click on the link to view and/or download your invoice. Alternatively open the attachment. Please ensure payment is received by the due date.",
    assignee: "MJ",
    pinned: false,
    status: "N",
    timeStamp: "29.04.2021",
    section: "quotes",
  },
  {
    mailId: "12",
    subject: "Your Cleanaway Invoice",
    body: "Your latest Cleanaway invoice is now available. Click on the link to view and/or download your invoice. Alternatively open the attachment. Please ensure payment is received by the due date.",
    assignee: "MJ",
    pinned: false,
    status: "N",
    timeStamp: "29.04.2021",
    section: "orders",
  },
  {
    mailId: "13",
    subject: "Adbri Masonry Pty Ltd",
    body: "Your latest Cleanaway invoice is now available. Click on the link to view and/or download your invoice. Alternatively open the attachment. Please ensure payment is received by the due date.",
    assignee: "MJ",
    pinned: false,
    status: "N",
    timeStamp: "29.04.2021",
    section: "orders",
  },
];
const costingItems = ["Budgets", "Cashflow", "Invoices", "Profit & Loss"];
const ExpandedItem = ({ itemTitle }: Props) => {
  let sessionView = sessionStorage.getItem("defaultView");
  let convertView = sessionView
    ? sessionView === "budget"
      ? "budgets"
      : sessionView === "cashflow"
      ? "cashflow"
      : sessionView === "invoices"
      ? "invoices"
      : sessionView === "profitloss"
      ? "profit & loss"
      : null
    : null;

  const navigate = useNavigate();
  const handleCostingItemClick = (e:any, itemTitle: string) => {
    if ((e?.button === 0 && (e?.ctrlKey || e?.metaKey)) || e.button === 1) {
      if (itemTitle === "Cashflow") {
        sessionStorage.setItem("defaultView", "cashflow");
        window.open(`${window.location.host}/cashflow/`);
      }
      if (itemTitle === "Budgets") {
        sessionStorage.setItem("defaultView", "budget");
        window.open(`${window.location.host}/Projects/`);
      }
    } else {
      if (itemTitle === "Cashflow") {
        sessionStorage.setItem("defaultView", "cashflow");
        navigate("/cashflow");
      }

      if (itemTitle === "Budgets") {
        sessionStorage.setItem("defaultView", "budget");
        navigate("/Projects");
      }
    }
  };
  return (
    <ExpandedItemContainer>
      {/*Mailroom Section */}
      {itemTitle === "Mailroom" ? (
        Object.keys(splitContentData(mailroomDummyData)).map((key, index) => {
          return (
            <ExpandedMailroomContainer key={index}>
              <div className="drawerExpandItemBulletLine"></div>
              <MailroomExpandContent
                contentTitle={key}
                mailContentData={splitContentData(mailroomDummyData)[key]}
              />
            </ExpandedMailroomContainer>
          );
        })
      ) : //costings section
      itemTitle === "Costings" ? (
        <ExpandedCostingContainer>
          {costingItems.map((item, index) => {
            return index !== costingItems.length - 1 ? (
              <div
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  flexDirection: "row",
                  borderLeft: "1px solid #c8d0de",
                }}
                key={index}
              >
                <div className="drawerExpandItemBulletLine"></div>

                <CostingItem
                  style={{
                    color: convertView
                      ? convertView.toLowerCase() === item.toLowerCase()
                        ? "#5E32DE"
                        : undefined
                      : undefined,
                    cursor: "pointer",
                    zIndex: 10,
                  }}
                  onMouseDown={(e) => handleCostingItemClick(e, item)}
                >
                  {item}
                </CostingItem>
              </div>
            ) : (
              <div
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  flexDirection: "row",
                  borderLeft: "1px solid #c8d0de",
                  marginBottom: "10px",
                }}
                key={index}
              >
                <div className="drawerExpandItemBulletLine"></div>

                <CostingItem style={{ position: "relative" }}>
                  <div style={{ position: "absolute", top: 0, left: "4px" }}>
                    {item}
                  </div>
                </CostingItem>
              </div>
            );
          })}
        </ExpandedCostingContainer>
      ) : null}
    </ExpandedItemContainer>
  );
};

export default ExpandedItem;

const ExpandedItemContainer = styled("div")({
  width: "100%",
});

const ExpandedMailroomContainer = styled("div")({
  width: "100%",
  borderLeft: "1px solid #C2B8DE",
  paddingTop: "20px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
});

const ExpandedCostingContainer = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
});

const CostingItem = styled("div")({
  width: "calc(100% - 29px)",
  paddingLeft: "4px",
  fontFamily: "IBM Plex Mono",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "13px",
  lineHeight: "17px",
  /* identical to box height */

  color: "#000000",
});
//#########UTILITIES FUNCTION##############
//########### FUNCTION UTILITIES##########//

const splitContentData = (data: Mail[]) => {
  // let uniqueSection: string[] = [...new Set(data.map((item) => item.section))]
  // console.log(uniqueSection)

  let grouped = _.groupBy(data, (item) => item.section);
  return grouped;
};
