import {Autocomplete, Box, Input, TextField, Typography} from '@mui/material'
import React, {SyntheticEvent, useEffect, useState} from 'react'
import { ReactComponent as DocketIcon } from '../../../Assets/DocketsReviewIcon.svg'
import { ReactComponent as AttachmentIcon } from '../../../Assets/AttachmentReviewIcon.svg'
import {ReactComponent as TrashIcon} from "../../../Assets/Trash2.svg";
import {Docket} from "../../../Models/dockets";
import Axios from "axios";
import {docketsBaseAddress} from "../../../settings";
import {LocalizationProvider, MobileDatePicker} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import XeroData, {XeroAccount, XeroContact, XeroTrackingOption} from "../../../Models/xeroData";

type Props = {
    action: "header"|"review"|"success"|"fail";
    dockets: Docket[];
    setDockets: Function;
    docket?: Docket;
    xeroData: XeroData
}

const DocketRow = (
    {
        action,
        docket,
        dockets,
        setDockets,
        xeroData
    }: Props
) => {
  const [invoiceDate, setInvoiceDate] = useState<string|null>(docket?.invoice_date || null)
  const docketId = docket?.docket_id || ""

  const handleChange = (e: any) => {
    const { name, value } = e.target
    const newReviewData = dockets.map((data) => {
      if (data.docket_id === docketId) {
        if (name === "subtotal"){
          if (isNaN(parseFloat(value))) return data
          const floatValue = parseFloat(value)
          return {
              ...data,
              subtotal: floatValue,
              line_items: [{...data.line_items[0], Quantity: "1", LineAmount: floatValue, UnitAmount: floatValue}],
              total: Math.round((floatValue + Number.EPSILON) * 110) / 100,
              tax: Math.round((floatValue + Number.EPSILON) * 10) / 100
          }
        }
        else if (name === "invoice_date"){
            return {
                ...data,
                invoice_date: invoiceDate ? invoiceDate : "",
            }
        }
        else return { ...data, [name]: value }
      } else return data
    })
    setDockets([...newReviewData])
  }

  const handleDateChange = () => {
      const newReviewData = dockets.map((data) => {
          if (data.docket_id === docketId) return { ...data, invoice_date: invoiceDate ? invoiceDate?.substring(0,10) : "" }
          else return data
      })
      setDockets([...newReviewData])
  }

  const handleRowClick = () =>{
      if (action ==="success" && docket?.xero_id) window.open(`https://go.xero.com/AccountsPayable/Edit.aspx?shortcode=${'!WhLnS'}&InvoiceID=${docket.xero_id}`)
  }

    const handleContactChange = (e:any, value:XeroContact) => {
        const newReviewData = dockets.map((data) => {
            if (data.docket_id === docketId) {
                return {
                    ...data,
                    contact_name: value.name,
                    contact_id: value.xeroId,
                }
            } else return data
        })
        setDockets([...newReviewData])
    }

    const handleAccountChange = (e:any, value:XeroAccount) => {
        const newReviewData = dockets.map((data) => {
            if (data.docket_id === docketId) {
                return {
                    ...data,
                    line_items: [{...data.line_items[0], AccountCode: value.Code}],
                }
            } else return data
        })
        setDockets([...newReviewData])
    }

    const handleTrackingOptionChange = (e:any, value:XeroTrackingOption) => {
        const newReviewData = dockets.map((data) => {
            if (data.docket_id === docketId) {
                return {
                    ...data,
                    line_items: [{...data.line_items[0], Tracking: [{Name: "PROJECTS", Option: value.Name}]}]
                }
            } else return data
        })
        setDockets([...newReviewData])
    }

    const sx = {
        fontFamily: 'Roboto Mono',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '11px',
        lineHeight: '15px',
        textAlign: 'center',
        letterSpacing: '0.05em',
        color: '#000000',
        p: 0,
        '&:before': {
            borderBottom: 'none',
        },
    }

  const style={
      fontFamily: 'Roboto Mono',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '11px',
      lineHeight: '15px',
      letterSpacing: '0.05em',
      color: action === 'header' ? '#9b7fea' : '#000000'
  }

  //!PT-Add click function/ DocketIcon
  const handleDocketIconClick = () => {
      window.open(`${docketsBaseAddress}/${docketId}/img/`)
  }

  //!PT-Add click function/ AttachmentIcon
  const handleAttachmentIconClick = () => {
      window.open(`${docketsBaseAddress}/${docketId}/pdf/`)
  }



  const handleDeleteClick = async () => {
      try {
          console.log(docketId)
          console.log(docket)
          const response = await Axios.delete(`${docketsBaseAddress}/${docketId}/`)
          console.log(response.data)
          if (response.data.deleted) {
              const newReviewData: Docket[] = []
              dockets.forEach((data) => {
                  if (data.docket_id !== docketId) newReviewData.push(data)
              });
              setDockets([...newReviewData])
          }
      } catch (err) {
          console.log("Error deleting row", err)
      }
  }

  return (
    <Box
      sx={{
        width: '100%',
        borderBottom: '0.5px solid #E6E6E6',
        m: '0 0 3% 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: action === "success" ? "pointer" : "default"
      }}
    >
      {/*Invoice number */}
      <Box
          onClick={handleRowClick}
          sx={{
              width: '15%',
              p: '0 1% 0 1%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
          }}
      >
          {
              action !== "review"
                  ?
                  <Typography style={style}>{action === "header" ? "Invoice #" : docket?.invoice_number || ""}</Typography>
                  :
                  <Input
                      value={docket?.invoice_number || ""}
                      onChange={handleChange}
                      name='invoice_number'
                      sx={sx}
                  />
          }
      </Box>

      {/*Invoice Date*/}
      <Box
        onClick={handleRowClick}
        sx={{
          width: '15%',
          height: '100%',
          p: '0 1% 0 1%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
          {
          action !== "review"
          ?
          <Typography style={style}>{action === "header" ? "Invoice Date": docket?.invoice_date || ""}</Typography>
          :
          <LocalizationProvider
              dateAdapter={AdapterDateFns}
          >
              <MobileDatePicker
                  value={invoiceDate}
                  inputFormat="dd.MM.yyyy"
                  // label={"Start Date"}
                  onChange={(date:Date|null) => {
                      if (date) setInvoiceDate(date.toISOString())
                  }}
                  onAccept={handleDateChange}
                  // onChange={handleChange}
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          variant="standard"
                          sx={{...params.sx,
                              '& .MuiInput-root': {borderBottom: "none !important"},
                          }}
                          inputProps={{...params.inputProps, disableUnderline: true, sx: {border: "none", ...sx}}}
                          name='invoice_date'
                      />
                  )}
              />
          </LocalizationProvider>
          }
      </Box>

      {/*Supplier */}
      <Box
          onClick={handleRowClick}
          sx={{
              width: '25%',
              p: '0 1% 0 1%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
          }}
      >
          {
              action !== "review"
                  ?
                  <Typography style={style}>{action === "header" ? 'Contact' : docket?.contact_name || ""}</Typography>
                  :
                    <Autocomplete
                        onChange={handleContactChange}
                        defaultValue={xeroData.contacts.filter((contact) => contact.name === docket?.contact_name)?.[0] || null}
                        disableClearable
                        size="small"
                        options={xeroData.contacts}
                        sx={{...sx, width: "100%", border: "none"}}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name={"contact"}
                                sx={{...sx, width:"100%", border: "none"}}
                                inputProps={{...params.inputProps, disableUnderline: true, sx: {...sx, width: "100%", border: "none"}}}
                            />
                        )}
                    />
          }
      </Box>

      {/*Account code + account name */}
      <Box
        onClick={handleRowClick}
        sx={{
          width: '15%',
          p: '0 1% 0 1%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
          {
          action !== "review"
          ?
          <Typography style={style}>{action === "header" ? 'Account' : docket?.line_items?.[0]?.['AccountCode'] || ''}</Typography>
          :
          <Autocomplete
              onChange={handleAccountChange}
              defaultValue={xeroData.accounts.filter((account) => account.Code === docket?.line_items?.[0]?.['AccountCode'])?.[0] || null}
              disableClearable
              size="small"
              options={xeroData.accounts}
              getOptionLabel={(option) => option.Name}
              sx={{...sx, width: "100%", border: "none"}}
              renderInput={(params) => (
                  <TextField
                      {...params}
                      name={"contact"}
                      sx={sx}
                      inputProps={{...params.inputProps, disableUnderline: true, sx: {border: "none", ...sx}}}
                  />
              )}
          />
          }
      </Box>



      {/*Tracking Option */}
      <Box
          onClick={handleRowClick}
          sx={{
              width: '15%',
              p: '0 1% 0 1%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
          }}
      >
          {
              action !== "review"
                  ?
                  <Typography style={style}>{action === "header" ? 'PROJECT' : docket?.line_items?.[0]?.['Tracking']?.[0]?.['Option'] || ''}</Typography>
                  :
                  <Autocomplete
                      onChange={handleTrackingOptionChange}
                      defaultValue={xeroData.tracking_options.filter((option) => option.Name === docket?.line_items?.[0]?.['Tracking']?.[0]?.['Option'])?.[0] || null}
                      disableClearable
                      size="small"
                      options={xeroData.tracking_options}
                      getOptionLabel={(option) => option.Name}
                      sx={{...sx, width: "100%", border: "none"}}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              name={"contact"}
                              sx={sx}
                              inputProps={{...params.inputProps, disableUnderline: true, sx: {border: "none", ...sx}}}
                          />
                      )}
                  />
          }
      </Box>

      {/*SubTotal */}
      <Box
          onClick={handleRowClick}
          sx={{
              width: '15%',
              p: '0 1% 0 1%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
          }}
      >
          {
              action !== "review"
                  ?
                  <Typography style={style}>{action === "header" ? 'Sub-Total' : docket?.subtotal || ""}</Typography>
                  :
                  <Input
                      type={"number"}
                      value={docket?.subtotal || ""}
                      onChange={handleChange}
                      name='subtotal'
                      inputProps={{style: {textAlign: "right"}}}
                      sx={{...sx, textAlign: "right"}}
                  />
          }
      </Box>

        {/*Icons*/}
      <Box
        sx={{
          width: "10%",
          p: '0 1% 0 1%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
          {action === "header"
              ?
              null
              :
              <>
                  <DocketIcon style={{cursor: 'pointer'}} onClick={handleDocketIconClick}/>{' '}
                  <AttachmentIcon style={{cursor: 'pointer'}}  onClick={handleAttachmentIconClick} />
                  <TrashIcon style={{cursor: 'pointer'}} onClick={handleDeleteClick} />
              </>
          }
      </Box>
    </Box>
  )
}

export default DocketRow
