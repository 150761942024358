import React, { useState, useEffect } from "react";
import draftIcon from "../../../Assets/s-draft.svg";
import quoteIcon from "../../../Assets/s-quote.svg";
import estimateIcon from "../../../Assets/s-estimate.svg";
import reviewIcon from "../../../Assets/s-review.svg";
import readyIcon from "../../../Assets/s-ready.svg";
import orderedIcon from "../../../Assets/s-ordered.svg";
import completedIcon from "../../../Assets/s-completed.svg";
import inspectedIcon from "../../../Assets/s-inspected.svg";
import approvedIcon from "../../../Assets/s-approved.svg";
import naIcon from "../../../Assets/s-na.svg";
import problemIcon from "../../../Assets/s-problem.svg";
import lockedIcon from "../../../Assets/s-locked.svg";
import {
  STATUS_SYMBOL,
  STATUS_SYMBOL_WITH_LOCK,
  STATUS_SYMBOL_COLOR,
} from "../../../utilities/constants";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  Menu,
  MenuItem,
  MenuList,
  TextField,
} from "@mui/material";
import "../Status/Status.css";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import NoticeAPI from "../../../Services/DoxleAPI/noticeAPI";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import { Company } from "../../../Models/company";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as ProjectPopperIcon } from "../../../Assets/projectDropDownIcon.svg";
import { useSocket } from "../../../Services/Sockets/useSocket";

interface props {
  type: string;
  itemId?: string;
  status: string;
  setValue?: Function;
  openModal?: boolean;
}

const Status: React.FC<props> = ({
  type,
  itemId,
  status,
  setValue,
  openModal,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const currentCompanyJSON: string | null =
    localStorage.getItem("currentCompany");
  const currentCompany: Company | null = currentCompanyJSON
    ? JSON.parse(currentCompanyJSON)
    : null;
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, user } = authContext;
  const availableStatuses =
    currentCompany?.owner && user?.userId === currentCompany.owner
      ? STATUS_SYMBOL_WITH_LOCK
      : STATUS_SYMBOL;
  const statusColors = STATUS_SYMBOL_COLOR;
  const preventStatusChange = () => {
    return (
      status === "L" &&
      !(currentCompany?.owner && user?.userId === currentCompany.owner)
    );
  };
  const open = preventStatusChange() ? false : Boolean(anchorEl);
  const options = Object.values(availableStatuses);
  const statusOptions: Object = STATUS_SYMBOL;

  const socket = useSocket();

  const getIndex = (statusInput: string) => {
    var count = 0;
    for (var i in Object.keys(availableStatuses)) {
      count++;
      if (Object.keys(availableStatuses)[i] === statusInput) {
        return count;
      }
    }
  };

  const getValue = (indexInput: number | undefined, mode: string) => {
    var count = 0;
    if (indexInput) {
      if (mode === "Values") {
        for (var i in Object.values(availableStatuses)) {
          count++;
          if (count === indexInput) {
            return Object.values(availableStatuses)[i];
          }
        }
      } else if (mode === "Keys") {
        for (var j in Object.keys(availableStatuses)) {
          count++;
          if (count === indexInput) {
            return Object.keys(availableStatuses)[j];
          }
        }
      }
    }
  };

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    getIndex(status)
  );

  useEffect(() => {
    setSelectedIndex(getIndex(status));
  }, [status]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    try {
      console.log("TYPE", type);
      if (type === "costcode" && itemId) {
        console.log(Object.values(availableStatuses)[index] + " for " + itemId);
        const newStatus = Object.keys(availableStatuses)[index];
        CostCodeAPI.update(itemId, { status: newStatus });
        setSelectedIndex(index + 1);
        if (setValue) {
          setValue(newStatus);
        }

        //! KEEP USE HTTP API TO SAVE DATA TO BACKEND
        //! ADDING SOCKET ONLY FOR NOTIFICATION, SO IF SOCKET FAILED, DB STILL SAFE

        //! SOCKET SENDING
        console.log(
          "%cCLIENT = Sending update status " + newStatus,
          "background:green; color:white"
        );
        socket.send(
          JSON.stringify({
            messageType: "SocketDataUpdate",
            message: { costCodeId: itemId, status: newStatus },
          })
        );
      } else if (type === "notice" && itemId) {
        console.log(Object.values(availableStatuses)[index] + " for " + itemId);
        const newStatus = Object.keys(availableStatuses)[index];
        NoticeAPI.update(itemId, user, { status: newStatus });
        setSelectedIndex(index + 1);

        //! SOCKET SENDING
        console.log(
          "%cCLIENT = Sending update status " + newStatus,
          "background:green; color:white"
        );
        socket.send(
          JSON.stringify({
            messageType: "SocketDataUpdate",
            message: { noticeId: itemId, status: newStatus },
          })
        );
      } else if (type === "mail") {
        console.log(Object.values(availableStatuses)[index] + " for " + itemId);
        // todo: updateAPI
        setSelectedIndex(index + 1);
      }
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSelectedIndex = () => {
    if (selectedIndex) {
      return selectedIndex - 1;
    }
  };

  const getIcon = (statusInput: string | undefined) => {
    var icon = draftIcon;
    switch (statusInput) {
      case "D": {
        icon = draftIcon;
        break;
      }
      case "Q": {
        icon = quoteIcon;
        break;
      }
      case "E": {
        icon = estimateIcon;
        break;
      }
      case "W": {
        icon = reviewIcon;
        break;
      }
      case "R": {
        icon = readyIcon;
        break;
      }
      case "O": {
        icon = orderedIcon;
        break;
      }
      case "C": {
        icon = completedIcon;
        break;
      }
      case "I": {
        icon = inspectedIcon;
        break;
      }
      case "A": {
        icon = approvedIcon;
        break;
      }
      case "N": {
        icon = naIcon;
        break;
      }
      case "P": {
        icon = problemIcon;
        break;
      }
      case "L": {
        icon = lockedIcon;
        break;
      }
    }
    return icon;
  };

  return (
    <div>
      {type === "notice" ? (
        openModal ? (
          <TextField
            value={getValue(selectedIndex, "Values")}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              endAdornment: (
                <InputAdornment
                  onClick={handleClickListItem}
                  position="end"
                  sx={{ cursor: "pointer" }}
                >
                  <ProjectPopperIcon />
                </InputAdornment>
              ),
            }}
            fullWidth={true}
            sx={{ m: 0 }}
            variant="filled"
          />
        ) : (
          <List component="nav" disablePadding>
            <ListItem dense onClick={handleClickListItem} sx={{ p: 0 }}>
              {/*old status display */}
              {/* <ListItemAvatar
                sx={{
                  marginLeft: "40%",
                  minWidth: "26px",
                  cursor: "pointer",
                  border: "1px solid red",
                }}
              >
                <Avatar
                  key={"avatar"}
                  alt={getValue(selectedIndex, "Values")}
                  src={getIcon(getValue(selectedIndex, "Keys"))}
                  sx={{ height: "16px", width: "16px" }}
                />
              </ListItemAvatar> */}

              {/*New status display */}
              {/* <div
                style={{
                  width: "6px",
                  height: "6px",
                  border: `2px solid ${getStatusColor(status)}`,
                  borderRadius: "2.5px",
                }}
              ></div> */}
              {StatusIcon(status, undefined)}
            </ListItem>
          </List>
        )
      ) : type === "stage" ? (
        <List component="nav" disablePadding>
          <ListItem
            dense
            sx={{
              margin: 0,
              padding:0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <ListItemAvatar sx={{ marginLeft: "40%", minWidth: "26px" }}>
              <Avatar
                key={"avatar"}
                alt={getValue(selectedIndex, "Values")}
                src={getIcon(getValue(selectedIndex, "Keys"))}
                sx={{ height: "16px", width: "16px" }}
              />
            </ListItemAvatar> */}

            {/*New Status */}
            {StatusIcon(status, undefined)}
          </ListItem>
        </List>
      ) : (
        <List
          component="nav"
          aria-label="Status List"
          sx={{ bgcolor: "background.paper", margin: 0, padding:0, }}
          disablePadding
        >
          <ListItem
            id="lock-button"
            aria-haspopup="listbox"
            aria-controls="lock-menu"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClickListItem}
            dense
            sx={{
              height: "10px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "11px",
              lineHeight: "15px",
              color: "#0D0D0D",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {StatusIcon(status, undefined)}
          </ListItem>
        </List>
      )}
      {type !== "notice" ? (
        // <Menu
        //   id="lock-menu"
        //   anchorEl={anchorEl}
        //   open={open}
        //   onClose={handleClose}
        //   MenuListProps={{
        //     role: "listbox",
        //   }}
        // >
        //   <MenuList dense disablePadding>
        //     {options.map((option, index) => (
        //       <div key={"menu" + index}>
        //         {/*{index === 0 && <div className='popMenuHeading'>OFFICE STATUS</div>}*/}
        //         {/*/!*{index === 5 && <div className='popMenuHeading'>SITE STATUS</div>}*!/*/}
        //         {/*{index === 9 && <div className='popMenuHeading' style={{padding: "10px"}}/>}*/}
        //         <MenuItem
        //           key={option}
        //           selected={index === getSelectedIndex()}
        //           onClick={(event: any) => handleMenuItemClick(event, index)}
        //           sx={{
        //             height: "10px",
        //             fontFamily: "Roboto",
        //             fontStyle: "normal",
        //             fontWeight: 600,
        //             fontSize: "11px",
        //             lineHeight: "13px",
        //             color: Object.values(statusColors)[index],
        //             borderBottom: "0.4px solid #DFD9F1",
        //           }}
        //         >
        //           <ListItemAvatar
        //             key={"listItem" + index.toString()}
        //             sx={{ minWidth: "26px" }}
        //           >
        //             <Avatar
        //               alt={getValue(index + 1, "Values")}
        //               src={getIcon(getValue(index + 1, "Keys"))}
        //               sx={{ height: "16px", width: "16px" }}
        //             />
        //           </ListItemAvatar>
        //           {option}
        //         </MenuItem>
        //       </div>
        //     ))}
        //   </MenuList>
        // </Menu>

        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            role: "listbox",
          }}
        >
          <MenuList dense disablePadding>
            {options.map((option, index) => (
              <div key={"menu" + index}>
                {/*{index === 0 && <div className='popMenuHeading'>OFFICE STATUS</div>}*/}
                {/*/!*{index === 5 && <div className='popMenuHeading'>SITE STATUS</div>}*!/*/}
                {/*{index === 9 && <div className='popMenuHeading' style={{padding: "10px"}}/>}*/}
                <MenuItem
                  key={option}
                  selected={index === getSelectedIndex()}
                  onClick={(event: any) => handleMenuItemClick(event, index)}
                  sx={{
                    height: "10px",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "11px",
                    lineHeight: "13px",
                    // color: Object.values(statusColors)[index],
                    color: getStatusColor(getStatusValue(option)),
                    borderBottom: "0.4px solid #DFD9F1",
                  }}
                >
                  {/*Old status */}
                  {/* <ListItemAvatar
                    key={"listItem" + index.toString()}
                    sx={{ minWidth: "26px" }}
                  >
                    <Avatar
                      alt={getValue(index + 1, "Values")}
                      src={getIcon(getValue(index + 1, "Keys"))}
                      sx={{ height: "16px", width: "16px" }}
                    />
                  </ListItemAvatar> */}
                  {/*new status */}
                  {/* <div
                    style={{
                      width: "6px",
                      height: "6px",
                      border: `2px solid ${getStatusColor(
                        getStatusValue(option)
                      )}`,
                      borderRadius: "2.5px",
                      marginRight: "5px",
                    }}
                  ></div> */}
                  {StatusIcon(getStatusValue(option), 5)}
                  {option}
                </MenuItem>
              </div>
            ))}
          </MenuList>
        </Menu>
      ) : (
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            role: "listbox",
          }}
        >
          <MenuList dense disablePadding>
            {options.map((option, index) => (
              <div key={"menu" + index}>
                {/*{index === 0 && <div className='popMenuHeading'>OFFICE STATUS</div>}*/}
                {/*/!*{index === 5 && <div className='popMenuHeading'>SITE STATUS</div>}*!/*/}
                {/*{index === 9 && <div className='popMenuHeading' style={{padding: "10px"}}/>}*/}
                <MenuItem
                  key={option}
                  selected={index === getSelectedIndex()}
                  onClick={(event: any) => handleMenuItemClick(event, index)}
                  sx={{
                    height: "10px",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "11px",
                    lineHeight: "13px",
                    // color: Object.values(statusColors)[index],
                    color: getStatusColor(getStatusValue(option)),
                    borderBottom: "0.4px solid #DFD9F1",
                  }}
                >
                  {/*Old status */}
                  {/* <ListItemAvatar
                    key={"listItem" + index.toString()}
                    sx={{ minWidth: "26px" }}
                  >
                    <Avatar
                      alt={getValue(index + 1, "Values")}
                      src={getIcon(getValue(index + 1, "Keys"))}
                      sx={{ height: "16px", width: "16px" }}
                    />
                  </ListItemAvatar> */}
                  {/*new status */}
                  {/* <div
                    style={{
                      width: "6px",
                      height: "6px",
                      border: `2px solid ${getStatusColor(
                        getStatusValue(option)
                      )}`,
                      borderRadius: "2.5px",
                      marginRight: "5px",
                    }}
                  ></div> */}
                  {StatusIcon(getStatusValue(option), 5)}
                  {option}
                </MenuItem>
              </div>
            ))}
          </MenuList>
        </Menu>
      )}
    </div>
  );
};

export default React.memo(Status);

const getStatusColor = (status: string) => {
  switch (status) {
    case "D":
      return "rgba(90, 54, 190, 1)";
    case "W":
      return "#FDB704";
    case "R":
      return "#40D040";
    case "P":
      return "red";
    case "N":
      return "grey";
    default:
      return "rgba(90, 54, 190, 1)";
  }
};

const getStatusValue = (status: string) => {
  switch (status) {
    case "DRAFT":
      return "D";
    case "WORKING":
      return "W";
    case "READY":
      return "R";
    case "PROBLEM":
      return "P";
    case "N/A":
      return "N";
    default:
      return "D";
  }
};

const StatusIcon = (status: string, marginRight: number | undefined) => {
  return (
    <div
      style={{
        width: "5px",
        height: "5px",
        border: `3px solid ${getStatusColor(status)}`,
        borderRadius: "4.8px",
        marginRight: marginRight ? `${marginRight}px` : undefined,
      }}
    ></div>
  );
};
