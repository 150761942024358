import { Box, Button, styled } from '@mui/material'
import React from 'react'
import '../Mailroom.css'
import { ReactComponent as DueDateExpandLogo } from '../../../../Assets/MailroomRemoveAttachment.svg'

type Props = {}

const AttachmentContainer = (props: Props) => {
  return (
    <Box className='attachmentContainer'>
      <Box sx={{ flex: 1 }}>Attachment 1.jpg</Box>

      <Box
        sx={{
          width: '18px',

          paddingRight: '2px',
          height: 'calc(100% - 2px)',
          paddingTop: '2px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
        }}
      >
        <RemoveButton>
          <DueDateExpandLogo />
        </RemoveButton>
      </Box>
    </Box>
  )
}

export default AttachmentContainer

const RemoveButton = styled(Button)({
  minWidth: '9px !important',
  height: '9px',
  padding: '0 !important',
  margin: '0 !important',
  borderRadius: '12.5px !important',
  paddingTop: '2px',
  fontStyle: 'normal',
  fontWeight: '400',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  '&:hover': {
    backgroundColor: '#eeeeee',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#BEABF5',
  },
})
