import React, { useEffect, useRef, useState } from "react";
import useStyles from "./BillsStyle";
import BillAPI from "../../../Services/DoxleAPI/billAPI";
import {
    ButtonProps,
    styled,
    Collapse,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextareaAutosize,
    TextField,
    InputAdornment,
    Autocomplete,
    Alert, Typography,
} from "@mui/material";
import moment from "moment";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ReactComponent as TrashIcon } from "../../../Assets/Trash.svg";
import { ReactComponent as FlagIcon } from "../../../Assets/Flag-2.svg";
import { ReactComponent as LogIcon } from "../../../Assets/log.svg";
import { ReactComponent as MoreIcon } from "../../../Assets/More18.svg";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    Bill,
    BillHistoryLine,
    BillLine,
    BillStatus,
} from "../../../Models/bill";
import Lines from "./BillLine";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import {
    AddressBookCompany,
    QuickAddressBookCompany,
} from "../../../Models/addressBook";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import Emails from "../Emails/Emails";
import { ReactComponent as IconLogs } from "../../../Assets/Logs.svg";
import BillUploadDialog from "./UploadDialog";
import billAPI from "../../../Services/DoxleAPI/billAPI";
import { Pricelist } from "../../../Models/pricelist";
import CustomCheckbox from "../../../utilities/CheckBox/CheckBox";

const formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
})


const CancelButton = styled(Button)<ButtonProps>(({ theme }) => ({
    // color: theme.palette.getContrastText("#F8F8F8"),
    color: '#FFFFFF',
    backgroundColor: "#564A78",
    marginLeft:"66px",
    minWidth:"93px",
    borderRadius: "4px",
    textTransform: "none",
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: "12px",
    '&:hover': {
        backgroundColor: "#786C9A",
    }
}));


const SaveButton = styled(Button)<ButtonProps>(({ theme }) => ({
    marginLeft:"10px",
    minWidth:"93px",
    background: '#5A36BE',
    borderRadius: '4px 0 0 4px',
    textTransform: "none",
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: "12px",
    color: '#FFFFFF',
    '&:hover': {
        background: 'rgba(107, 74, 201, 0.75)',
    },
}));

const SaveMenuButton = styled(Button)<ButtonProps>(({ theme }) => ({
    marginLeft:"1px",
    minWidth:"28px",
    maxWidth:"28px",
    width:"28px",
    background: '#5A36BE',
    borderRadius: '0 4px 4px 0',
    textTransform: "none",
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: "12px",
    color: '#FFFFFF',
    '&:hover': {
        background: 'rgba(107, 74, 201, 0.75)',
    },
}));

const cleanBillLines = (billLines: BillLine[]) =>{
    let returnLines: BillLine[] = []
    billLines.forEach((line:BillLine)=>{
        if (line.description !== "") returnLines.push(line);
    })
    return returnLines
}

interface responseType {
    bill: Bill;
    orders: number;
}

interface props {
    bill: Bill;
    currentBillId: string;
    setCurrentBillId: Function;
    billList: Bill[];
    setBillList: Function;
    supplierList: AddressBookCompany[];
    priceList: Pricelist[];
    updateOrders: Function;
    costCodeId: string;
    handleBillsRowClick: Function;
    setOpenHistory: Function;
    handleAddButton: Function;
    setStatusFilter: Function;
    disableField: boolean;
    disableLineField: boolean;
    openHistory: boolean;
    logRef: React.MutableRefObject<HTMLDivElement | null>
    setIsOrderChange: Function;
    setBillEmailSent: Function;
    showEmailDialog: boolean;
    setShowEmailDialog: React.Dispatch<React.SetStateAction<boolean>>;
    isPoReview?: boolean;
}

const BillDetails = ({ 
    bill,
    currentBillId,
    setCurrentBillId,
    supplierList,
    priceList,
    updateOrders,
    costCodeId,
    billList,
    setBillList,
    handleBillsRowClick,
    setOpenHistory,
    handleAddButton,
    setStatusFilter,
    disableField,
    disableLineField,
    openHistory,
    logRef,
    setIsOrderChange,
    setBillEmailSent,
    showEmailDialog,
    setShowEmailDialog,
    isPoReview
 }: props) => {
    const [checkedLines, setCheckedLines] = useState<Array<string>>([]);
    const [flaggedLines, setFlaggedLines] = useState<Array<string>>([]);
    const [lookupPriceList, setLookupPriceList] = useState<boolean>(true);
    const [billNumber, setBillNumber] = useState<string>(bill.number);
    const [supplier, setSupplier] = useState<string>(bill.supplier);
    const [supplierId, setSupplierId] = useState<string|null>(bill.abSupplier || null );
    const [reference, setReference] = useState<string>(bill.reference || "");
    const [startDate, setStartDate] = useState<string|null>(bill.startDate);
    const [endDate, setEndDate] = useState<string|null>(bill.endDate);
    const [damages, setDamages] = useState<string>(bill.damages || "0.00");
    const [history, setHistory] = useState<BillHistoryLine[]>(bill?.history || []);
    const [damagesPeriod, setDamagesPeriod] = useState<"Day"|"Week"|"Month">(bill.damagesPeriod || "Day");
    const [paymentTerms, setPaymentTerms] = useState<string>(bill.paymentTerms);
    const [paymentTermsSuffix, setPaymentTermsSuffix] = useState<"Days"|"NET"|"EOM">(bill.paymentTermsSuffix || "Days");
    const [notes, setNotes] = useState<string>(bill.specialConditions);
    const [billStatus, setbillStatus] = useState<string>(bill.status);
    const [billLines, setbillLines] = useState<BillLine[]>(bill.lines || []);
    const [noOfLines, setNoOfLines] = useState<number>(Math.max(bill.lines ? bill.lines.length + 1 : 4, 4));
    const [writeLines, setWriteLines] = useState<BillLine[]>(cleanBillLines(bill.lines || []));
    const [subTotal, setSubTotal] = useState<number>(bill.subTotal ? bill.subTotal : 0);
    const [tax, setTax] = useState<number>(bill.tax ? bill.tax : 0);
    const [total, setTotal] = useState<number>(bill.total ? bill.total : 0);
    const [errors, setErrors] = useState<object[]>([]);
    const [overrideLines, setOverrideLines] = useState<boolean>(false)
    // const [showEmailDialog, setShowEmailDialog] = useState<boolean>(false)
    const classes = useStyles();
    const unmounted = useMountStatus();
    const [anchorElOptions, setAnchorElOptions] = React.useState<null | HTMLElement>(null);
    const openOptions = Boolean(anchorElOptions);
    const handleClickOptions = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElOptions(event.currentTarget);
    };
    const handleCloseOptions = () => {
      setAnchorElOptions(null);
    };

    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn } = authContext;


    const [displayUploadDialog, setDisplayUploadDialog] = useState<boolean>(false)
    const tableRef = useRef(null);

    const [anchorElSave, setAnchorElSave] = React.useState<null | HTMLElement>(null);
    const openSave = Boolean(anchorElSave);

    const handleClickSave = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElSave(event.currentTarget);
    };

    const ApprovedText = () =>{
        return (
            <span
                style={{
                    float: "right",
                    position: "relative",
                    top: -20,
                    backgroundColor: bill.approved ? "#edf7ed": "#fdeded",
                    color: bill.approved ? "#1E4620" :"#5F2120",
                    padding: 5,
                    fontSize: 12,
                    borderRadius: 2
                }}
            >
                {bill.approved
                    ? `Bill approved by ${bill.approvedBy}. Revoken build PO here in order to edit. ➔`
                    : `Bill was rejected by ${bill.approvedBy}.`
                }
        </span>
        )
    }

    const handleApproveReject = (approve: boolean) => {
        let newUrl: string;
        const currentUrl = window.location.href;
        if (currentUrl.search("/0/") > 0)
            newUrl = currentUrl.replace('/0/', approve ? '/1/' : '//');
        else if (currentUrl.search("/1/") > 0)
            newUrl = currentUrl.replace('/1/', approve ? '//' : '/0/');
        else newUrl = currentUrl.replace('//', approve ? '/1/' : '/0/');
        window.open(newUrl, "_self");
    }

    const revokePurchaseOrder = (bill: Bill) => {
        try {
            if (!bill?.billId) throw "billId not defined"
            const response = billAPI.revokePO(bill.billId)
            if (response)
                setBillList((currentState: Bill[]) => {
                    return [...currentState.map((b: Bill) => {
                        if (b.billId === bill.billId) return response
                        else return b
                    })]
                })
            else throw "Negative response"
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    const handleCloseSave = () => {
      setAnchorElSave(null);
    };

    const handleAddOder = () => {
        handleAddButton();
    }

    const addLog = (log: BillHistoryLine) =>{
        setHistory(prevState => [...prevState, log])
    }

    async function handleChange({ target: { name, value }, }: { target: { name: string; value: string }; }) {

        if (name !== "supplier") setIsOrderChange(true);
        if ((name === "supplier") && (value !== supplier)) setIsOrderChange(true);

        try {

            if (name === "number") {
                setBillNumber(value);
            }
            if (name === "supplier") {
                setSupplier(value);
                setSupplierId(null)
                supplierList.some((abCompany) => {
                    if (abCompany.abCompanyId && abCompany.companyName === value) {
                        setSupplierId(abCompany.abCompanyId)
                        return true
                    }
                    return false
                })
            }
            if (name === "reference") {
                setReference(value);
            }
            if (name === "specialConditions") {
                setNotes(value);
            }
            if (name === "paymentTerms") {
                setPaymentTerms(value);
            }
            if (name === "paymentTermsSuffix") {
                if (value === "Days" || value === "NET" || value === "EOM") setPaymentTermsSuffix(value);
            }
            if (name === "damages") {
                setDamages(value.toString());
            }
            if (name === "damagesPeriod" && (value === "Day" || value === "Week" || value === "Month")) {
                setDamagesPeriod(value);
            }
            if (name === "status") {
                setbillStatus(value);
                if (bill.billId && (bill.billId !== currentBillId)) {
                    console.log("here")
                    const response = await BillAPI.update(bill.billId, bill.history ? bill.history.length : 0, {status: value}) as responseType;
                    updateOrders(response.bill.costCode, response.orders.toString())
                }
            }
        }catch (err){
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    const uploadBillLines = async (file: File) => {
        let payload: any = {billId: bill.billId === "NEWBILLTEMPLATE" ? null : bill.billId }
        if (!payload.billId) {
            if (costCodeId) payload.costCode = costCodeId
            if (billNumber) payload.number = billNumber
            if (supplier) payload.supplier = supplier
            if (supplierId) payload.abSupplier = supplierId
            if (notes) payload.specialConditions = notes
            if (billStatus) payload.status = billStatus
            if (reference) payload.reference = reference
            if (damages) payload.damages = damages
            if (damagesPeriod) payload.damagesPeriod = damagesPeriod
            if (startDate) payload.startDate = startDate
            if (endDate) payload.endDate = endDate
            if (paymentTerms) payload.paymentTerms = paymentTerms
            if (paymentTermsSuffix) payload.paymentTermsSuffix = paymentTermsSuffix
        } else {
            if (bill.number !== billNumber) {payload.number = billNumber}
            if (bill.supplier !== supplier) {payload.supplier = supplier}
            if (bill.abSupplier !== supplierId) {payload.abSupplier = supplierId}
            if (bill.specialConditions !== notes) {payload.specialConditions = notes}
            if (bill.status !== billStatus) {payload.status = billStatus}
            if (bill.startDate !== startDate) {payload.startDate = startDate}
            if (bill.endDate!== endDate) {payload.endDate = endDate}
            if (bill.lines !== writeLines) {payload.lines = writeLines}
            if (bill.reference !== reference) {payload.reference = reference}
            if (bill.paymentTerms !== paymentTerms) {payload.paymentTerms = paymentTerms}
            if (bill.paymentTermsSuffix !== paymentTermsSuffix) {payload.paymentTermsSuffix = paymentTermsSuffix}
            if (bill.damages !== damages) {payload.damages = damages}
            if (bill.damagesPeriod !== damagesPeriod) {payload.damagesPeriod = damagesPeriod}
        }

        try {
            const response = await billAPI.uploadCSV(payload, overrideLines, file)
            if (response) {
                let newBills: Bill[] = []
                billList.forEach((existingBill: Bill) => {
                    if (existingBill.billId && existingBill.billId === existingBill.billId){
                        if (response.bill) {
                            newBills.push(response.bill as Bill)
                        }
                    } else {
                        newBills.push(existingBill)
                    }
                })
                updateOrders(response.bill.costCode, response.orders.toString())
                setBillList([...newBills])
            }
        }catch (err){
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }

    }

   

    const handleSave = (bill: Bill, continueEditing: boolean, newStatus?: string) => {

        const billId = bill && bill.billId ? bill.billId : "";
        const billHistoryLength = bill && bill.history ? bill.history.length : 0;

        if(billId === "" || billId === 'NEWBILLTEMPLATE'){
            addNewBill(newStatus);
        }else{
            updateBills(billId, billHistoryLength, continueEditing, newStatus);
        }

        setIsOrderChange(false);
    };



    const validateLineData = (line: BillLine, num: number) => {
        let lineErrors: object[] = []
        // console.log("validating line", line)
        // if (line.description === "") {lineErrors.push({num: "Description for line "+num +" must not be blank"}); console.log("Description for line "+num +" must not be blank");}
        if (line.unit === "") {lineErrors.push({num: "Unit for line "+num+" must not be blank"}); console.log("Unit for line "+num+" must not be blank");}
        return lineErrors
    }

    const validateBillData = () => {
        // console.log("validating data")
        setErrors([])
        let validationErrors: object[] = []
        if (billNumber === "") {validationErrors.push({billNumber: "Bill number must not be blank"}); console.log("Bill number must not be blank");}
        // if (startDate === "") {validationErrors.push({startDate: "Issue date is not correctly formatted"}); console.log("Issue date is not correctly formatted");}
        // if (endDate === "") {validationErrors.push({endDate: "Issue date is not correctly formatted"}); console.log("Issue date is not correctly formatted");}
        if (supplier === "") {validationErrors.push({supplier: "Supplier number must not be blank"}); console.log("Supplier number must not be blank");}
        if (["D", "R", "A", "I", "V", "X"].indexOf(billStatus) === -1) {validationErrors.push({status: "Incorrect status type"}); console.log("Incorrect status type");}
        // console.log("writeLines", writeLines)
        writeLines.forEach((line: BillLine, i: number) => {
            const lineError: object[] = validateLineData(line, i+1)
            validationErrors = [...validationErrors, ...lineError];
        })
        if (validationErrors.length === 0){ return true }
        setErrors(validationErrors);
        // console.log("SET ERRORS", validationErrors)
        return false;
    }

    const handleClickFlag = () => {
        let newLines: BillLine[] = []
        let newWriteLines: BillLine[] = []
        // console.log("before", billLines)
        billLines.forEach((line) =>{
            if (line.billLineId && checkedLines.indexOf(line.billLineId) !== -1) {
                line.ticked = !line.ticked;
            }
            newLines.push(line);
            if (line.description !== "") newWriteLines.push(line);
        })
        // console.log("after", newLines)
        setCheckedLines([]);
        setbillLines([...newLines]);
        setWriteLines([...newWriteLines])
    };


    const handleGeneratePDFIconClicked = async () => {
        try {
            const result = bill.billId ? await BillAPI.getPDF(bill.billId) : "false";
            if (result !== "false") window.open(result)
        }catch (err){
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }


    const selectRowDelete = () => {
        let newLines: BillLine[] = []
        let newWriteLines: BillLine[] = []
        billLines.forEach((line) =>{
            if (line.billLineId && checkedLines.indexOf(line.billLineId) === -1) {
                newLines.push(line);
                if (line.description !== "") newWriteLines.push(line);
            }
        })
        setCheckedLines([]);
        setbillLines([...newLines]);
        setWriteLines([...newWriteLines])
        setNoOfLines(noOfLines - checkedLines.length)
        // setFlaggedLines([]);
    };


    const addNewBill = async (newStatus?: string) => {
        // console.log("SAVING LINES", writeLines)
        if (!validateBillData()) { return }
        console.log("VALIDATION OK")

        let payload: Bill = {
            costCode: costCodeId,
            number: billNumber,
            emailSent: false,
            supplier: supplier,
            abSupplier: supplierId,
            accepted: false,
            specialConditions: notes,
            status: newStatus? newStatus: billStatus,
            lines: writeLines,
            reference: reference,
            damages: damages,
            damagesPeriod:damagesPeriod,
            startDate: startDate,
            endDate: endDate,
            paymentTerms: paymentTerms,
            paymentTermsSuffix: paymentTermsSuffix
        };
        try {
            const response: any = await BillAPI.add(
                payload
            ) as responseType;
            if (unmounted) return;
            if(response){
                let data = [...billList.filter((list: any) => list.billId !== "NEWBILLTEMPLATE"), response.bill];
                setBillList(data);
                // setOpen(!open);
                updateOrders(response.bill.costCode, response.orders.toString())
                setCurrentBillId("");
                setStatusFilter(newStatus? newStatus: billStatus);
            }
        } catch (err){
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }


    const updateBills = async (billId: string, billHistoryLength: number, continueEditing: boolean, newStatus?: string) => {
        writeLines.sort(function(a, b){return a.index - b.index});
        if (!validateBillData()) { return }
        let payload: any = {};
        if (bill.number !== billNumber) {payload.number = billNumber}
        if (bill.supplier !== supplier) {payload.supplier = supplier}
        if (bill.abSupplier !== supplierId) {payload.abSupplier = supplierId}
        if (bill.specialConditions !== notes) {payload.specialConditions = notes}
        if (bill.status !== billStatus) {payload.status = billStatus}
        if (newStatus) {
            if (bill.status !== newStatus) {payload.status = newStatus}
        }
        if (bill.startDate !== startDate) {payload.startDate = startDate}
        if (bill.endDate!== endDate) {payload.endDate = endDate}
        if (bill.lines !== writeLines) {payload.lines = writeLines}
        if (bill.reference !== reference) {payload.reference = reference}
        if (bill.paymentTerms !== paymentTerms) {payload.paymentTerms = paymentTerms}
        if (bill.paymentTermsSuffix !== paymentTermsSuffix) {payload.paymentTermsSuffix = paymentTermsSuffix}
        if (bill.damages !== damages) {payload.damages = damages}
        if (bill.damagesPeriod !== damagesPeriod) {payload.damagesPeriod = damagesPeriod}
        try {
            const response = await BillAPI.update(billId, billHistoryLength, payload) as responseType;
            if (unmounted) return;
            if(response){
                let newBills: any[] = []
                billList.forEach((bill: any) => {
                    if (bill && bill.billId && bill.billId === billId){
                        if (response && response.bill) {
                            newBills.push(response.bill)
                        }
                    } else {
                        newBills.push(bill)
                    }
                })
                setBillList([...newBills])
                updateOrders(response.bill.costCode, response.orders.toString())
                
                if (!continueEditing) setCurrentBillId("");
                
                if (newStatus) {
                    setStatusFilter(newStatus);
                } else {
                    setStatusFilter(bill.status);
                }
            }
        } catch (err){
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    };

    const handleClearError = (num: number) =>{
        let newErrors: object[] = []
        errors.forEach((error, i) =>{
            if (!(num === i)){
                newErrors.push(error)
            }
        })
        setErrors([...newErrors])
    }

    useEffect(() => {
        // console.log("writeLines", writeLines)
        let newSubTotal: number = 0.0;
        writeLines.forEach((line: BillLine) =>{
            if (line.lineCost && !isNaN(line.lineCost)){
                newSubTotal = parseFloat(newSubTotal.toString()) + parseFloat(line.lineCost.toString())
            }
        })
        setSubTotal(newSubTotal)
        setTax(newSubTotal * 0.1)
        setTotal(newSubTotal * 1.1)
    }, [writeLines])



    useEffect(() => {
        if (bill.lines) {
            let filledLines: BillLine[] = []
            bill.lines.forEach((line:BillLine) =>{
                if (line.description && line.description !== ""){filledLines.push(line)}
            })
            setWriteLines(filledLines)
            if (bill.lines.length >= 3) {
                setbillLines([...bill.lines, {
                    billLineId: (bill.lines.length + 1).toString(),
                    index: bill.lines.length + 1,
                    description: "",
                    itemCost: "0.00",
                    itemFormula: "",
                    quantity: "0.00",
                    quantityFormula: "",
                    lineCost: 0.0,
                    ticked: false,
                    unit: "ea"
                }])
            } else {
                let lines: BillLine[] = [...bill.lines]
                for (let i = 0; i < noOfLines - bill.lines.length; i++){
                    lines.push({
                        billLineId: (bill.lines.length + i).toString(),
                        index: bill.lines.length + i,
                        description: "",
                        itemCost: "0.00",
                        itemFormula: "",
                        quantity: "0.00",
                        quantityFormula: "",
                        lineCost: 0.0,
                        ticked: false,
                        unit: "ea"
                    })
                }
                setbillLines([...lines])
            }
        }
    }, [bill])

    useEffect(() => {
        if (billLines) {
            if (billLines.length >= 3) {
                setbillLines([...billLines, {
                    billLineId: (billLines.length + 1).toString(),
                    index: billLines.length + 1,
                    description: "",
                    itemCost: "0.00",
                    itemFormula: "",
                    quantity: "0.00",
                    quantityFormula: "",
                    lineCost: 0.0,
                    ticked: false,
                    unit: "ea"
                }])
            } else {
                let lines: BillLine[] = [...billLines]
                for (let i = 0; i <= noOfLines - billLines.length; i++){
                    lines.push({
                        billLineId: (billLines.length + i).toString(),
                        index: billLines.length + i,
                        description: "",
                        itemCost: "0.00",
                        itemFormula: "",
                        quantity: "0.00",
                        quantityFormula: "",
                        lineCost: 0.0,
                        ticked: false,
                        unit: "ea"
                    })
                }
                setbillLines([...lines])
            }
        }
    }, [noOfLines])
    
    useEffect(()=> {
        if (currentBillId === bill.billId) {
            tableRef && tableRef.current && (tableRef as any).current.scrollIntoView && (tableRef as any).current.scrollIntoView({ behavior: "smooth" });
        }
    }, [currentBillId])

    if (showEmailDialog) return (<Emails setDisplayEmail={setShowEmailDialog} bill={bill} setEmailed={setBillEmailSent} addLog={addLog} />)
    else return (
                <div ref={tableRef}>
                    {errors.length > 0 && errors.map((error, num) => (
                        <Alert
                            key={"err"+num+"-"+bill.billId}
                            severity={"error"}
                            style={{position: "fixed", top: "57px", left: "5vw", width: "85vw"}}
                            onClose={(event)=>{handleClearError(num)}}
                        >{Object.values(error).length > 0 ? Object.values(error)[0] : null}</Alert>
                    ))}

                    <BillUploadDialog
                        open={displayUploadDialog}
                        setOpen={setDisplayUploadDialog}
                        overrideLines={overrideLines}
                        setOverrideLines={setOverrideLines}
                        uploadBillLines={uploadBillLines}
                    />
                    {typeof bill.approved == "boolean" && !isPoReview && <ApprovedText/>}
                    {!isPoReview && <Box
                        sx={{position: "absolute", top: "15px", right: "20px"}}
                    >
                        <IconButton
                            id="order-button"
                            aria-controls={
                                openOptions ? "order-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openOptions ? "true" : undefined}
                            onClick={handleClickOptions}
                            // variant="contained"
                            // disableElevation
                            // endIcon={<KeyboardArrowDownIcon />}
                            sx={{
                                // textTransform: "none",
                                // background: "#5A36BE",
                                // borderRadius: "4px",
                                // "&:hover": {
                                //     background: "rgba(107, 74, 201, 0.75)",
                                // },
                            }}
                        >
                            <MoreIcon/>
                        </IconButton>
                        
                        <Menu
                            id="order-menu"
                            anchorEl={anchorElOptions}
                            open={openOptions}
                            onClose={handleCloseOptions}
                            MenuListProps={{
                                "aria-labelledby": "order-button",
                            }}
                            sx={{
                                marginTop: "4px",
                                ".MuiList-root": {
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: 300,
                                    fontSize: "11px",
                                    lineHeight: "13px",
                                    minWidth: "150px",
                                },
                                ".MuiMenuItem-root": {
                                    borderBottom: "1px solid #C7B4FC",
                                    fontFamily: "Roboto",
                                    fontStyle: "normal",
                                    fontWeight: 300,
                                    fontSize: "11px",
                                    lineHeight: "13px",
                                    color: "#8a72cf",
                                },
                                ".MuiMenuItem-root:last-child": {
                                    borderBottom: "none",
                                },
                            }}
                        >
                            <MenuItem
                                key="revokeMenuItem"
                                onClick={() => {
                                    revokePurchaseOrder(bill)
                                }}
                                sx={{
                                    display:
                                        bill.approved ? "flex" : "none",
                                }}
                            >
                                Revoke Purchase Order
                            </MenuItem>
                            <MenuItem
                                key="issueMenuItem"
                                onClick={() => {
                                    handleSave(bill, false, "I");
                                    handleCloseOptions();
                                }}
                                sx={{
                                    display:
                                        billStatus === "A" ? "flex" : "none",
                                }}
                            >
                                Issue
                            </MenuItem>
                            <MenuItem key="addAnotherMenuItem" onClick={handleAddOder}>
                                Add another
                            </MenuItem>
                            <MenuItem
                                key="revertMenuItem"
                                onClick={() => {
                                    handleSave(bill, false, "D");
                                    handleCloseOptions();
                                }}
                                sx={{
                                    display:
                                        billStatus === "D" ? "none" : "flex",
                                }}
                            >
                                Revert to draft
                            </MenuItem>
                            <MenuItem
                                key="voidMenuItem"
                                onClick={() => {
                                    handleSave(bill, false, "V");
                                    handleCloseOptions();
                                }}
                                sx={{
                                    display:
                                        billStatus === "V" ? "none" : "flex",
                                }}
                            >
                                Void
                            </MenuItem>
                            <MenuItem
                                key="downloadMenuItem"
                                onClick={() => {
                                    handleGeneratePDFIconClicked();
                                }}
                            >
                                Download PDF
                            </MenuItem>
                            <MenuItem key="emailMenuItem" onClick={() => {
                                setAnchorElOptions(null);
                                setShowEmailDialog(true)
                            }}>
                                Email Order
                            </MenuItem>
                            <MenuItem
                                key="uploadMenuItem"
                                sx={{
                                    display:
                                        ((billStatus === "D" || billStatus === "R") && !(disableLineField || bill.approved))
                                            ? "flex"
                                            : "none",
                                }}
                                onClick={() => {
                                    setDisplayUploadDialog(true);
                                }}
                            >
                                Upload lines by CSV
                            </MenuItem>
                        </Menu>
                    </Box>}


                    {/* Bill Table */}
                    <Grid container>
                            <Grid item xs={4} sx={{ paddingRight: "4%" }} className={classes.headerTblInpur}>
                                <div style={{ padding: "0 0 3px 14px" }}>Order #</div>
                                <div>
                                    <TextField
                                        value={billNumber}
                                        size="small"
                                        name={"number"}
                                        // label={"Bill No"}
                                        onChange={handleChange}
                                        style={{ width: "100%" }}
                                        InputLabelProps={{
                                            style: { color: "#646C74" },
                                        }}
                                        disabled={disableField || bill.approved === true}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4} sx={{ px: "2%" }} className={classes.headerTblInpur}>
                                <div style={{ padding: "0 0 3px 14px" }}>Supplier</div>
                                <div>
                                    <Autocomplete
                                        onInputChange={(
                                            event,
                                            newInputValue
                                        ) => {
                                            handleChange({
                                                target: {
                                                    name: "supplier",
                                                    value: newInputValue,
                                                },
                                            });
                                        }}
                                        freeSolo
                                        disableClearable
                                        value={supplier}
                                        size="small"
                                        options={supplierList.map(
                                            (option) => option.companyName
                                        )}
                                        sx={{ width: "100%", padding: 0 }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name={"supplier"}
                                                // label={"Supplier"}
                                                // onChange={handleChange}
                                                sx={{
                                                    "& .MuiOutlinedInput-input":
                                                        {
                                                            height: "30px",
                                                            paddingLeft: "14px"
                                                        },
                                                    "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall":
                                                        {
                                                            padding: 0,
                                                        },
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        color: "#646C74",
                                                        top: "5px",
                                                    },
                                                }}
                                            />
                                        )}
                                        disabled={disableField || bill.approved === true}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4} sx={{ paddingLeft: "4%" }} className={classes.headerTblInpur}>
                                <div style={{ padding: "0 0 3px 14px" }}>Reference</div>
                                <div>
                                    <TextField
                                        value={reference}
                                        size="small"
                                        // label={"Reference"}
                                        name={"reference"}
                                        onChange={handleChange}
                                        style={{ width: "100%" }}
                                        InputLabelProps={{
                                            style: { color: "#646C74" },
                                        }}
                                        disabled={disableField}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} sx={{ height: "33px" }}/>

                            <Grid item xs={3} sx={{ paddingRight: "4%" }} className={classes.headerTblInpur}>
                                <div style={{ padding: "0 0 3px 10px" }}>Start Date</div>
                                <Box
                                    className={classes.datePickerInput}
                                    sx={{
                                        ".MuiFormControl-root": {
                                            width: "100%",
                                        },
                                    }}
                                >
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <MobileDatePicker
                                            value={startDate ? startDate : null}
                                            inputFormat="dd.MM.yyyy"
                                            // label={"Start Date"}
                                            onOpen={() => setStartDate(null)}
                                            onChange={(newValue: any) => {
                                                if (newValue)
                                                    setStartDate(
                                                        newValue
                                                            .toISOString()
                                                            .substring(0, 10)
                                                    );
                                                else setStartDate(null);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                            className={classes.datePicker}
                                            InputProps={{
                                                style: { border: "none" },
                                            }}
                                            disabled={disableField || bill.approved === true}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                            <Grid item xs={3} sx={{ px: "2%" }} className={classes.headerTblInpur}>
                                <div style={{ padding: "0 0 3px 10px" }}>End Date</div>
                                <Box
                                    className={classes.datePickerInput}
                                    sx={{
                                        ".MuiFormControl-root": {
                                            width: "100%",
                                        },
                                    }}
                                >
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <MobileDatePicker
                                            // label={"End Date"}
                                            inputFormat="dd.MM.yyyy"
                                            value={endDate ? endDate : null}
                                            onOpen={() => setEndDate(null)}
                                            onChange={(newValue: any) => {
                                                if (newValue)
                                                    setEndDate(
                                                        newValue
                                                            .toISOString()
                                                            .substring(0, 10)
                                                    );
                                                else setEndDate(null);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                            className={classes.datePicker}
                                            InputProps={{
                                                style: { border: "none" },
                                            }}
                                            disabled={disableField || bill.approved === true}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                            <Grid item xs={3} sx={{ px: "2%" }} className={classes.headerTblInpur}>
                                <div style={{ padding: "0 0 3px 14px" }}>Liquidated Damages</div>
                                <div>
                                    <TextField
                                        type="number"
                                        className={classes.damages}
                                        value={damages}
                                        size="small"
                                        name={"damages"}
                                        onChange={handleChange}
                                        InputProps={{
                                            sx: {
                                                borderRadius: "6px 0px 0px 6px",
                                                borderRight: "none",
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    /
                                                </InputAdornment>
                                            ),
                                        }}
                                        disabled={disableField || bill.approved === true}
                                    />
                                    <Select
                                        variant={"outlined"}
                                        className={classes.damagesPeriod}
                                        name={"damagesPeriod"}
                                        value={damagesPeriod}
                                        onChange={handleChange}
                                        disabled={disableField || bill.approved === true}
                                    >
                                        <MenuItem key="d" value={"Day"}>
                                            Day
                                        </MenuItem>
                                        <MenuItem key="w" value={"Week"}>
                                            Week
                                        </MenuItem>
                                        <MenuItem key="m" value={"Month"}>
                                            Month
                                        </MenuItem>
                                    </Select>
                                </div>
                            </Grid>
                            <Grid item xs={3} sx={{ paddingLeft: "4%" }} className={classes.headerTblInpur}>
                                <div style={{ padding: "0 0 3px 14px" }}>Payment Terms</div>
                                <div>
                                    <TextField
                                        className={classes.damages}
                                        value={paymentTerms}
                                        size="small"
                                        type="number"
                                        name={"paymentTerms"}
                                        onChange={handleChange}
                                        disabled={disableField || bill.approved === true}
                                    />
                                    <Select
                                        variant={"outlined"}
                                        className={classes.damagesPeriod}
                                        name={"paymentTermsSuffix"}
                                        value={paymentTermsSuffix}
                                        onChange={handleChange}
                                        disabled={disableField || bill.approved === true}
                                    >
                                        <MenuItem key="d" value={"Days"}>
                                            Days
                                        </MenuItem>
                                        <MenuItem key="n" value={"NET"}>
                                            NET
                                        </MenuItem>
                                        <MenuItem key="e" value={"EOM"}>
                                            EOM
                                        </MenuItem>
                                    </Select>
                                </div>
                            </Grid>
                            <Grid item xs={12} sx={{ height: "33px" }}/>

                            <Grid item xs={12} sx={{ }} className={classes.headerTblInpur}>
                                <div style={{ padding: "0 0 3px 10px" }}>Special Instruction</div>
                                
                                    <TextareaAutosize
                                        className={
                                            classes.notesfield
                                        }
                                        minRows={1}
                                        onChange={handleChange}
                                        name={"specialConditions"}
                                        value={notes}
                                        disabled={disableField || bill.approved === true}
                                    />

                            </Grid>

                            <Grid item xs={12} sx={{ height: "12px" }}/>
                            {!isPoReview && <Grid item xs={12} style={{ display: "flex", flexDirection: "row" }} className={classes.headerTblInpur}>
                                <CustomCheckbox
                                    checked={lookupPriceList}
                                    onClick={(event: any) => setLookupPriceList(prevState => !prevState)}
                                    style={{ transform: "scale(0.7)" }}
                                />
                                <Typography style={{position: "relative", "top": 3}} className={classes.tableHeadingStyle1}>Look Up Pricelist</Typography>
                            </Grid>}

                    </Grid>

                    <Box sx={{ margin: "30px 0 0 0" }}>
                        {checkedLines.length !== 0 ? (
                            <div
                                style={{
                                    paddingLeft: "20px",
                                }}
                            >
                                <IconButton
                                    size="small"
                                    style={{
                                        background: "#D6303A",
                                        borderRadius: "50px",
                                    }}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor: "#D6303A",
                                        },
                                    }}
                                    className={classes.deleteFlagIcon}
                                >
                                    <TrashIcon onClick={selectRowDelete} />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    style={{
                                        background: "#FFC804",
                                        borderRadius: "50px",
                                    }}
                                    className={classes.deleteFlagIcon}
                                >
                                    <FlagIcon onClick={handleClickFlag} />
                                </IconButton>
                            </div>
                        ) : null}
                        <Table
                            size="small"
                            stickyHeader
                            aria-label="sticky details table"
                            className={classes.tableBordered}
                        >
                            <TableHead>
                                <TableRow className="tableHeadStyle">
                                    <TableCell
                                        className={classes.tableHeadingStyle1}
                                    ></TableCell>
                                    <TableCell
                                        className={classes.tableHeadingStyle1}
                                    >
                                        Description
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeadingStyle1}
                                    >
                                        Qty
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeadingStyle1}
                                    >
                                        UOM
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeadingStyle1}
                                    >
                                        Unit Price
                                    </TableCell>
                                    <TableCell
                                        className={classes.tableHeadingStyle1}
                                    >
                                        Subtotal
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody
                                sx={{
                                    borderCollapse: "separate !important",
                                    "& td": {
                                        background: "white",
                                        border: "1px solid #F5F6F7",
                                        borderWidth: "1px",
                                        boxSizing: "border-box",
                                    },
                                    "& tr td:first-of-type ": {
                                        borderRadius: "4px 0 0 4px",
                                    },
                                    "& tr td:last-child ": {
                                        borderRadius: "0 4px 4px 0",
                                    },
                                    // "& td": {
                                    //     background: "white",
                                    //     border: "1px solid #DAD2EE",
                                    //     borderWidth: "1px 0 0 0",
                                    //     boxSizing: "border-box",
                                    // },
                                    // "& tr:first-of-type td:first-of-type ": {
                                    //     borderRadius: "4px 0 0 0",
                                    // },
                                    // "& tr:first-of-type td:last-child ": {
                                    //     borderRadius: "0 4px 0 0",
                                    // },
                                    // "& tr:last-child td:first-of-type ": {
                                    //     borderRadius: "0 0 0 4px",
                                    // },
                                    // "& tr:last-child td:last-child ": {
                                    //     borderBottomRightRadius: "4px",
                                    // },
                                    // "& tr:last-child td": {
                                    //     borderBottomWidth: "1px",
                                    // },
                                    // "& td:first-of-type ": {
                                    //     borderLeftWidth: "1px",
                                    // },
                                    // "& td:last-child ": {
                                    //     borderRightWidth: "1px",
                                    // },
                                }}
                            >
                                {billLines
                                    ? billLines.map(
                                          (line: BillLine, i: number) => {
                                              let rowSubTotal =
                                                  parseFloat(
                                                      line.quantity as string
                                                  ) *
                                                  parseFloat(
                                                      line.itemCost as string
                                                  );
                                              return (
                                                  <Lines
                                                      key={line.billLineId}
                                                      line={line}
                                                      lineIndex={i}
                                                      setWriteLines={
                                                          setWriteLines
                                                      }
                                                      writeLines={writeLines}
                                                      noOfLines={
                                                          billLines.length
                                                      }
                                                      setNoOfLines={
                                                          setNoOfLines
                                                      }
                                                      checkedLines={
                                                          checkedLines
                                                      }
                                                      setCheckedLines={
                                                          setCheckedLines
                                                      }
                                                      rowSubTotal={rowSubTotal}
                                                      disableLineField={
                                                          disableLineField || bill.approved === true
                                                      }
                                                      isPoReview={
                                                          isPoReview || bill.approved === true
                                                      }
                                                      setIsOrderChange={setIsOrderChange}
                                                      priceList={priceList}
                                                      lookUpPricelist={lookupPriceList}
                                                  />
                                              );
                                          }
                                      )
                                    : null}

                                    <TableRow>
                                        <TableCell colSpan={6} style={{background: "none"}}/>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell style={{background: "none"}} colSpan={4}/>
                                        <TableCell style={{background: "none"}} className={classes.footerTaxSection}>
                                            Subtotal
                                        </TableCell>
                                        <TableCell  className={classes.tableBodyStyle2}
                                            style={{
                                                background: "none",
                                                color: disableLineField || bill.approved === true
                                                    ? "rgba(0, 0, 0, 0.7)"
                                                    : "rgba(0, 0, 0, 1)",
                                            }}
                                        >
                                            {subTotal
                                                ? formatter.format(subTotal)
                                                : "$0.00"}
                                            {/* ${parseFloat(subTotal).toFixed(2)} */}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{background: "none"}} colSpan={4}/>
                                        <TableCell style={{background: "none"}} className={classes.footerTaxSection}>
                                            Gst 10%
                                        </TableCell>
                                        <TableCell  className={classes.tableBodyStyle2}
                                            style={{
                                                background: "none",
                                                color: disableLineField || bill.approved === true
                                                    ? "rgba(0, 0, 0, 0.7)"
                                                    : "rgba(0, 0, 0, 1)",
                                            }}
                                        >
                                            {tax ? formatter.format(tax) : "$0.00"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{background: "none"}} colSpan={4}/>
                                        <TableCell className={classes.footerTaxSection} style={{
                                            background: "none",
                                            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
                                            }} 
                                        >
                                            Total
                                        </TableCell>
                                        <TableCell  className={classes.tableBodyStyle2} style={{
                                            background: "none",
                                            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                            color: disableLineField || bill.approved === true
                                                ? "rgba(0, 0, 0, 0.7)"
                                                : "rgba(0, 0, 0, 1)",
                                            }}
                                        >
                                            {total
                                                ? formatter.format(total)
                                                : "$0.00"}
                                        </TableCell>
                                    </TableRow>

                            </TableBody>
                        </Table>
                        <br></br>
                        <br></br>
                        <Grid
                            container
                            sx={{
                                paddingBottom: "50px",
                            }}
                        >
                            <Grid item xs={6} ref={logRef}>
                                <Table
                                    sx={{
                                        borderCollapse: "separate !important",
                                        "& td": {
                                            background: "white",
                                            border: "1px solid #F5F6F7",
                                            borderWidth: "1px 0 0 0",
                                            boxSizing: "border-box",
                                            p: "10px",
                                        },
                                        "& tr:first-of-type td:first-of-type ":
                                            {
                                                borderRadius: "4px 4px 0 0",
                                                borderBottomWidth: "1px",
                                            },
                                        "& tr:last-child td:first-of-type ": {
                                            borderRadius: "0 0 0 4px",
                                        },
                                        "& tr:last-child td:last-child ": {
                                            borderBottomRightRadius: "4px",
                                        },
                                        "& tr:last-child td": {
                                            borderBottomWidth: "1px",
                                        },
                                        "& td:first-of-type ": {
                                            borderLeftWidth: "1px",
                                        },
                                        "& td:last-child ": {
                                            borderRightWidth: "1px",
                                        },
                                    }}
                                >
                                    <TableBody>
                                        <TableRow>
                                            {!isPoReview && <TableCell colSpan={3}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                        fontFamily: "Roboto",
                                                        fontStyle: "normal",
                                                        fontWeight: 400,
                                                        fontSize: "12px",
                                                        lineHeight: "14px",
                                                        color: "#5A36BE",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            gap: "4px",
                                                        }}
                                                    >
                                                        <IconLogs />
                                                        View Logs
                                                    </div>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() =>
                                                            setOpenHistory(
                                                                !openHistory
                                                            )
                                                        }
                                                    >
                                                        {openHistory ? (
                                                            <KeyboardArrowUpIcon />
                                                        ) : (
                                                            <KeyboardArrowDownIcon />
                                                        )}
                                                    </IconButton>
                                                </div>
                                            </TableCell>}
                                        </TableRow>
                                        {openHistory && history.map((his: BillHistoryLine, i: number) => {
                                            return (
                                                <TableRow
                                                    key={
                                                        "#" +
                                                        i +
                                                        his.id
                                                    }
                                                    sx={{
                                                        display:
                                                            openHistory
                                                                ? "table-row"
                                                                : "none",
                                                    }}
                                                >
                                                    <TableCell>
                                                        {typeof his?.user !== "string" && his?.user?.firstName && his?.user?.lastName
                                                            ? his.user.firstName+" "+his.user.lastName
                                                            : "n/a"}
                                                    </TableCell>
                                                    <TableCell>
                                                        {`${moment(
                                                            his.timeStamp
                                                        ).format(
                                                            "DD/MM/yy"
                                                        )} at ${moment(
                                                            his.timeStamp
                                                        ).format(
                                                            "LT"
                                                        )}`}
                                                    </TableCell>
                                                    <TableCell>
                                                        {his.longText}
                                                    </TableCell>
                                                </TableRow>
                                              );
                                        })}
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                style={{
                                    textAlign: "right",
                                    display:
                                        billStatus === "I" ||
                                        billStatus === "X" ||
                                        billStatus === "V"
                                            ? "none"
                                            : "unset",
                                }}
                                className={classes.belowButtonClass}
                            >
                                <CancelButton onClick={() => !isPoReview
                                    ? handleBillsRowClick(bill)
                                    : handleApproveReject(false)
                                }>
                                    {!isPoReview
                                        ? "Cancel"
                                    : bill?.approved === false
                                        ? "Undo"
                                        : "Reject"
                                    }
                                </CancelButton>

                                <SaveButton
                                    onClick={() => !isPoReview
                                        ? handleSave(bill, false)
                                        : handleApproveReject(true)
                                    }
                                >
                                    {!isPoReview
                                        ? "Save"
                                        : bill?.approved === true
                                            ? "Undo"
                                            : "Approve"
                                    }
                                </SaveButton>
                                {!isPoReview && <SaveMenuButton
                                    id="save-button"
                                    aria-controls={
                                        openSave ? "save-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                        openSave ? "true" : undefined
                                    }
                                    variant="contained"
                                    disableElevation
                                    onClick={handleClickSave}
                                >
                                    <KeyboardArrowDownIcon/>
                                </SaveMenuButton>
                                }
                                {!isPoReview && <Menu
                                    id="Save-menu"
                                    anchorEl={anchorElSave}
                                    open={openSave}
                                    onClose={handleCloseSave}
                                    MenuListProps={{
                                        "aria-labelledby": "save-button",
                                    }}
                                    sx={{
                                        marginTop: "4px",
                                        ".MuiList-root": {
                                            background:
                                                "rgba(107, 74, 201, 0.75)",
                                            borderRadius: "0px 0px 4px 4px",
                                            fontFamily: "Roboto",
                                            fontStyle: "normal",
                                            fontWeight: 300,
                                            fontSize: "11px",
                                            lineHeight: "13px",
                                            color: "#FFFFFF",
                                            minWidth: "150px",
                                        },
                                        ".MuiMenuItem-root": {
                                            borderBottom: "1px solid #C7B4FC",
                                            fontFamily: "Roboto",
                                            fontStyle: "normal",
                                            fontWeight: 300,
                                            fontSize: "11px",
                                            lineHeight: "13px",
                                            color: "#FFFFFF",
                                        },
                                        ".MuiMenuItem-root:last-child": {
                                            borderBottom: "none",
                                        },
                                    }}
                                >
                                    {billStatus === "D" && [
                                        <MenuItem
                                            key="0"
                                            onClick={() => {
                                                handleSave(bill, true);
                                                handleCloseSave();
                                            }}
                                        >
                                            Save (continue editing)
                                        </MenuItem>,
                                        <MenuItem
                                            key="1"
                                            onClick={() => {
                                                handleSave(bill, false);
                                                handleCloseSave();
                                            }}
                                        >
                                            Save as draft
                                        </MenuItem>,
                                        <MenuItem
                                            key="2"
                                            onClick={() => {
                                                handleSave(bill, false, "R");
                                                handleCloseSave();
                                            }}
                                        >
                                            Save & submit for review
                                        </MenuItem>,
                                        <MenuItem
                                            key="3"
                                            onClick={() => {
                                                handleSave(bill, false, "A");
                                                handleCloseSave();
                                            }}
                                        >
                                            Save & approve
                                        </MenuItem>,
                                        <MenuItem
                                            key="4"
                                            onClick={() => {
                                                handleSave(bill, false, "I");
                                                handleCloseSave();
                                            }}
                                        >
                                            Issue Order
                                        </MenuItem>,
                                    ]}
                                    {billStatus === "R" && [
                                        <MenuItem
                                            key="5"
                                            onClick={() => {
                                                handleSave(bill, false, "A");
                                                handleCloseSave();
                                            }}
                                        >
                                            Save & approve
                                        </MenuItem>,
                                        <MenuItem
                                            key="6"
                                            onClick={() => {
                                                handleSave(bill, false, "I");
                                                handleCloseSave();
                                            }}
                                        >
                                            Issue Order
                                        </MenuItem>,
                                    ]}
                                    {billStatus === "A" && (
                                        <MenuItem
                                            key="7"
                                            onClick={() => {
                                                handleSave(bill, false, "I");
                                                handleCloseSave();
                                            }}
                                        >
                                            Issue Order
                                        </MenuItem>
                                    )}
                                    {(billStatus === "I" ||
                                        billStatus === "A") && [
                                        <MenuItem
                                            key="8"
                                            onClick={() => {
                                                handleSave(bill, false, "D");
                                                handleCloseSave();
                                            }}
                                        >
                                            Revert to draft
                                        </MenuItem>,
                                        <MenuItem
                                            key="9"
                                            onClick={() => {
                                                handleSave(bill, false, "V");
                                                handleCloseSave();
                                            }}
                                        >
                                            Void Order
                                        </MenuItem>,
                                    ]}
                                </Menu>}
                            </Grid>
                        </Grid>
                    </Box>
                </div>
               
    );
};

export default BillDetails;
