import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import searchIcon from "../../../Assets/cc-search.svg";
import {
  Collapse,
  Box,
  Typography,
  Dialog,
  Button,
  Tab,
  IconButton,
  Breadcrumbs,
  MenuItem,
  Select,
  Chip,
  TextField,
  InputAdornment,
  AppBar,
  Toolbar,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import useStyles from "./CostCodesDetailsStyle";
import { Close } from "@mui/icons-material";
import { MoreVert, AddCircle, NavigateNextSharp } from "@mui/icons-material";
import { Costcode } from "../../../Models/costcode";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Bill, BillLine } from "../../../Models/bill";
import { Specification } from "../../../Models/specification";
import { Folder, FileData } from "../../../Models/storage";
import { Company } from "../../../Models/company";
import Specifications from "../Specifications/Specifications";
import Quotes from "../Quotes/Quotes";
import Storage from "../Storage/Storage";
import Bills from "../Bills/Bills";
import Checklists from "../Checklists/Checklists";
import InputDialog from "../../../utilities/InputDialog/inputDialog";
import storageAPI from "../../../Services/DoxleAPI/storageAPI";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import FolderAddIcon from "../../../Assets/Folder - Plus.svg";
import { ReactComponent as MeasureIconON } from "../../../Assets/RulerON.svg";
import { ReactComponent as MeasureIcon } from "../../../Assets/RulerOff.svg";
import { ReactComponent as NotesIcon } from "../../../Assets/c-notes.svg";
import { ReactComponent as ChecklistIcon } from "../../../Assets/c-check.svg";
import { ReactComponent as CommentIcon } from "../../../Assets/c-comment.svg";
import { ReactComponent as EmailIcon } from "../../../Assets/c-email.svg";
import { ReactComponent as FilesIcon } from "../../../Assets/c-file.svg";
import { ReactComponent as HistoryIcon } from "../../../Assets/c-history.svg";
import { ReactComponent as InvoiceIcon } from "../../../Assets/c-invoice.svg";
import { ReactComponent as OrderIcon } from "../../../Assets/c-order.svg";
import { ReactComponent as PhotoIcon } from "../../../Assets/c-photo.svg";
import { ReactComponent as PriceIcon } from "../../../Assets/c-price.svg";
import { ReactComponent as QuoteIcon } from "../../../Assets/c-quote.svg";
import { ReactComponent as SpecsIcon } from "../../../Assets/c-spec.svg";
import { ReactComponent as SupplierIcon } from "../../../Assets/c-supplier.svg";
import { ReactComponent as PrevIcon } from "../../../Assets/ChevronsRight.svg";
import { ReactComponent as NextIcon } from "../../../Assets/ChevronsLeft.svg";
import { ReactComponent as NotesIconON } from "../../../Assets/c-notes-on.svg";
import { ReactComponent as ChecklistIconON } from "../../../Assets/c-check-on.svg";
import { ReactComponent as CommentIconON } from "../../../Assets/c-comment-on.svg";
import { ReactComponent as EmailIconON } from "../../../Assets/c-email-on.svg";
import { ReactComponent as FilesIconON } from "../../../Assets/c-file-on.svg";
import { ReactComponent as HistoryIconON } from "../../../Assets/c-history-on.svg";
import { ReactComponent as InvoiceIconON } from "../../../Assets/c-invoice-on.svg";
import { ReactComponent as OrderIconON } from "../../../Assets/c-order-on.svg";
import { ReactComponent as PhotoIconON } from "../../../Assets/c-photo-on.svg";
import { ReactComponent as PriceIconON } from "../../../Assets/c-price-on.svg";
import { ReactComponent as QuoteIconON } from "../../../Assets/c-quote-on.svg";
import { ReactComponent as SpecsIconON } from "../../../Assets/c-spec-on.svg";
import { ReactComponent as SupplierIconON } from "../../../Assets/c-supplier-on.svg";

import { ReactComponent as DrawerIcon } from "../../../Assets/ChevronCircle.svg";

import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import Comments from "../Comments/Comments";
import { Comment } from "../../../Models/comments";
import checklistAPI from "../../../Services/DoxleAPI/checklistAPI";
import { Checklist } from "../../../Models/checklist";
import costCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import AddIcon from "@mui/icons-material/Add";
import Notes from "../Notes/Notes";
import { Permit } from "../../../Models/permit";
import checklistGroupAPI from "../../../Services/DoxleAPI/checklistGroupAPI";
import { User } from "../../../Models/user";
import { AddressBookCompany } from "../../../Models/addressBook";
import CustomizedCircularProgress from "../../../utilities/Progress/CustomizedCircularProgress";
import AlertDialog from "../../../utilities/Dialog/AlertDialog";
import { TakeOffProvider } from "../../Providers/TakeOffProvider";
import TakeOffs from "../Measurements/TakeOffs";
import { Project } from "../../../Models/project";
import specAPI from "../../../Services/DoxleAPI/specificationAPI";
import noteAPI from "../../../Services/DoxleAPI/noteAPI";
import Invoices from "../Invoices/Invoices";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "4px",
  border: "1px solid #D6D9DF",
  backgroundColor: "white",
  margin: "40px 0 40px 0",
  width: "100%",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface props {
  selectedTabIndex: string;
  // setSelectedTabIndex:  React.Dispatch<React.SetStateAction<string>>;
  selectedCostCode?: Costcode;
  openModal: boolean;
  closeModal: () => void;
  selectedProjectAddress: string | null;
  updateBudget: Function;
  updateOrders: Function;
  height: number;
  width: number;
  users?: User[];
  company?: Company;
  contractors?: AddressBookCompany[];
  project: Project | null;
  incrementSelectedCostCode: Function;
}

const CostCodesDetails: React.FC<props> = ({
  selectedTabIndex,
  // setSelectedTabIndex,
  selectedCostCode,
  openModal,
  closeModal,
  selectedProjectAddress,
  updateBudget,
  updateOrders,
  height,
  width,
  users,
  company,
  contractors,
  project,
  incrementSelectedCostCode,
}) => {
  interface TabMenu {
    description: string;
    count?: string;
    icon?: JSX.Element;
    iconON?: JSX.Element;
  }
  const [costcode, setCostcode] = useState<Costcode | undefined>(
    selectedCostCode
  );
  const tabs: TabMenu[] = [
    {
      description: "Notes",
      count: costcode?.counts?.notesCount.toString() || "0",
      icon: <NotesIcon />,
      iconON: <NotesIconON />,
    },
    {
      description: "Specifications",
      count: costcode?.counts?.specificationsCount.toString() || "0",
      icon: <SpecsIcon />,
      iconON: <SpecsIconON />,
    },
    {
      description: "Files",
      count: costcode?.counts?.filesCount.toString() || "0",
      icon: <FilesIcon />,
      iconON: <FilesIconON />,
    },
    // {
    //   description: "Photos",
    //   count: costcode?.counts?.photosCount.toString() || "0",
    //   icon: <PhotoIcon />,
    //   iconON: <PhotoIconON />,
    // },
    {
      description: "Suppliers",
      count: costcode?.counts?.suppliersCount.toString() || "0",
      icon: <SupplierIcon />,
      iconON: <SupplierIconON />,
    },
    {
      description: "Quotes",
      count: costcode?.counts?.quotesCount.toString() || "0",
      icon: <QuoteIcon />,
      iconON: <QuoteIconON />,
    },
    {
      description: "Pricelist",
      count: costcode?.counts?.pricelistCount.toString() || "0",
      icon: <PriceIcon />,
      iconON: <PriceIconON />,
    },
    {
      description: "Orders",
      count: costcode?.bills?.length.toString() || "0",
      icon: <OrderIcon />,
      iconON: <OrderIconON />,
    },
    {
      description: "Invoices ",
      count: costcode?.counts?.invoicesCount?.toString() || "0",
      icon: <InvoiceIcon />,
      iconON: <InvoiceIconON />,
    },
    {
      description: "Checklist",
      count: costcode?.counts?.checklistCount.toString() || "0",
      icon: <ChecklistIcon />,
      iconON: <ChecklistIconON />,
    },
    {
      description: "Emails",
      count: costcode?.counts?.emailsCount.toString() || "0",
      icon: <EmailIcon />,
      iconON: <EmailIconON />,
    },
    {
      description: "Comments",
      count: costcode?.counts?.commentsCount.toString() || "0",
      icon: <CommentIcon />,
      iconON: <CommentIconON />,
    },
    {
      description: "Take Offs",
      count: costcode?.counts?.takeOffCount?.toString() || "0",
      icon: <MeasureIcon />,
      iconON: <MeasureIconON />,
    },
    {
      description: "History",
      count: costcode?.counts?.historyCount.toString() || "0",
      icon: <HistoryIcon />,
      iconON: <HistoryIconON />,
    },
  ];

  const [tabIndex, setTabIndex] = useState<string>(selectedTabIndex);
  const [billList, setBillList] = useState<Array<Bill> | any>([]);
  const [folderList, setFolderList] = useState<Array<Folder>>([]);
  const [folderPhotoList, setFolderPhotoList] = useState<Array<Folder>>([]);
  const [fileList, setFileList] = useState<Array<FileData>>([]);
  const [specList, setSpecList] = useState<Array<Specification> | any>([]);
  const [checkListGroups, setCheckListGroups] = useState<Array<Permit>>([]);
  const [openDrawer, setOpenDrawer] = useState<boolean>(true);
  const [commentList, setCommentList] = useState<Array<Comment>>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean | undefined>(undefined);
  const [budget, setBudget] = useState<string>(
    selectedCostCode ? selectedCostCode.budget : "0"
  );
  const [addNoteMode, setAddNoteMode] = useState<boolean>(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState<boolean>(false);
  const [isOrderChange, setIsOrderChange] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [nextAction, setNextAction] = useState<string>("1");

  const costCodeId = selectedCostCode?.costCodeId || "";
  const title = selectedCostCode?.title || "";
  const costCodeStatus = selectedCostCode?.status || "";

  const classes = useStyles();
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, user } = authContext;

  const AddSupplier = async () => {
    console.log("add supplier test");
  };

  const handleDiscard = () => {
    if (nextAction === "CLOSECOSTCODEDETAILS") {
      closeModal();
    } else {
      setTabIndex(nextAction);
    }
    setAlertDialogOpen(false);
    setIsOrderChange(false);
  };

  const handleCheckOrderChanges = (newIndex: string, e: any) => {
    if (e?.keyCode && e?.keyCode === 27 && tabIndex === "13") return;
    if (isOrderChange) {
      // show alert
      setAlertDialogOpen(true);
      setNextAction(newIndex);
    } else {
      if (newIndex === "CLOSECOSTCODEDETAILS") {
        closeModal();
      } else {
        setTabIndex(newIndex);
      }
    }
  };
  const handleCloseAlert = () => {
    setAlertDialogOpen(false);
  };

  const handleCloseModal = () => {
    setSpecList([]);
    setCostcode(undefined);
    setBillList([]);
    setFileList([]);
    setFolderList([]);
    setFolderPhotoList([]);
    setDialogOpen(false);
    setTabIndex(tabIndex);
  };

  const handleFolderCreation = async (input: string) => {
    try {
      const response = (await storageAPI.createFolder(
        input,
        null,
        costCodeId,
        "F"
      )) as Folder;
      setFolderList((preList) => [...preList, response]);
      setDialogOpen(false);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const handleFolderPhotoCreation = async (input: string) => {
    try {
      const response = (await storageAPI.createFolder(
        input,
        null,
        costCodeId,
        "P"
      )) as Folder;
      setFolderPhotoList((preList) => [...preList, response]);
      setDialogOpen(false);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  const handleAddButton = () => {
    if (tabIndex === "5") {
      AddSupplier();
    }
    if (tabIndex === "3") {
      setDialogOpen(true);
    } // storage
    if (tabIndex === "4") {
      setDialogOpen(true);
    } // storage photos
    // if (tabIndex === "1"){ setAddNoteMode(true); }
  };

  const getAddButtonText = () => {
    if (tabIndex === "5") {
      return "Supplier";
    }
    // if (tabIndex === "1"){ return "Note" }
  };

  const fetchTabSpecificData = async (costcodeId: string, tabIndex: string) => {
    if (!costcodeId) return;
    console.log(tabIndex);
    if (tabIndex === "1") {
      // const notes = noteAPI.getList(undefined, costCodeId)
    }
    if (tabIndex === "2") {
      setLoading(true);
      const specs = await specAPI.getList(costCodeId);
      setSpecList([...specs]);
      setLoading(false);
    }
    if (tabIndex === "3") {
      /* fetchFiles */
    }
    // if (tabIndex === "4") {
    //   /* fetchPhotos */
    // }
    if (tabIndex === "4") {
      /* fetchSuppliers */
    }
    if (tabIndex === "5") {
      /* fetchQuotes */
    }
    if (tabIndex === "6") {
      /* fetchPricelist */
    }
    if (tabIndex === "7") {
      /* fetchOrders */
    }
    if (tabIndex === "8") {
      /* fetchInvoices */
    }
    if (tabIndex === "9") {
      /* fetchChecklist */
    }
    if (tabIndex === "10") {
      /* fetchEmails */
    }
    if (tabIndex === "11") {
      /* fetchComments */
    }
    if (tabIndex === "12") {
      /* fetchTakeOffs */
    }
    if (tabIndex === "13") {
      /* fetchHistory */
    }
  };

  const fetchCostCodesDetails = async (costcodeId: string) => {
    setLoading(true);
    try {
      const response: Costcode = (await CostCodeAPI.getDetailed(
        costcodeId
      )) as Costcode;
      if (response) {
        setCostcode(response);
      }
      setBillList(response?.bills || []);
      setFileList(response?.files || []);
      setSpecList(response?.specifications || []);
      setCommentList(response?.comments || []);
      setBudget(response.budget);
      fetchTabSpecificData(costcodeId, tabIndex);
      setLoading(false);
      return response;
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      setLoading(false);
    }
    return undefined;
  };

  useEffect(() => {
    if (!openModal) {
      handleCloseModal();
    }
  }, [openModal]);

  useEffect(() => {
    if (selectedCostCode) setBudget(selectedCostCode.budget);
    if (selectedCostCode?.costCodeId)
      fetchCostCodesDetails(selectedCostCode.costCodeId);
  }, [selectedCostCode]);

  useEffect(() => {
    // console.log(selectedTabIndex);
    setTabIndex(selectedTabIndex);
  }, [selectedTabIndex]);



  return (
    <Dialog
      fullScreen
      open={openModal}
      // onClose={closeModal}
      onClose={(e: {}) => handleCheckOrderChanges("CLOSECOSTCODEDETAILS", e)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
      TransitionComponent={Transition}
      sx={{ ".MuiDialog-paper": { background: "#F1F2F5" } }}
      onKeyUp={(event: any) => {
        if (event.keyCode === 27) {
          if (tabIndex === "13") return;
          else if (tabIndex === "7")
            handleCheckOrderChanges("CLOSECOSTCODEDETAILS", event);
          else closeModal();
        }
      }}
    >
      {!selectedCostCode ? (
        <></>
      ) : (
        <>
          <AppBar
            sx={{
              position: "relative",
              backgroundColor: "#F1F2F5",
              color: "black",
              maxHeight: "43px",
              px: "2px",
            }}
            elevation={0}
          >
            <Toolbar>
              <Typography
                style={{
                  fontFamily: "Roboto Mono",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "11px",
                  lineHeight: "15px",
                  color: "#7C768D",
                  flex: 1,
                  textTransform: "capitalize",
                }}
              >
                {company ? company.name : "Byson Group"} /{" "}
                {selectedProjectAddress} / {title} /{" "}
                {tabs[parseInt(tabIndex) - 1].description}
              </Typography>
              <IconButton
                disabled={!incrementSelectedCostCode(-1, true)}
                edge="start"
                color="inherit"
                onClick={() => {
                  incrementSelectedCostCode(-1, false);
                }}
                aria-label="close"
                sx={{
                  marginLeft: "6px",
                  marginRight: "6px",
                  "&:hover": { borderRadius: "11px" },
                }}
              >
                <PrevIcon style={{ color: "purple" }} />
                <Typography>Previous</Typography>
              </IconButton>
              <IconButton
                disabled={!incrementSelectedCostCode(1, true)}
                edge="start"
                color="inherit"
                onClick={() => {
                  incrementSelectedCostCode(1, false);
                }}
                aria-label="close"
                sx={{
                  marginLeft: "6px",
                  marginRight: "6px",
                  "&:hover": { borderRadius: "11px" },
                }}
              >
                <Typography>Next</Typography>
                <NextIcon style={{ color: "purple" }} />
              </IconButton>
              <IconButton
                edge="start"
                color="inherit"
                // onClick={closeModal}
                onClick={(e) =>
                  handleCheckOrderChanges("CLOSECOSTCODEDETAILS", e)
                }
                aria-label="close"
                sx={{ marginLeft: "6px", "&:hover": { borderRadius: "11px" } }}
              >
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Box
            sx={{
              marginTop: "1%",
              marginBottom: "26px",
              alignSelf: "center",
              backgroundColor: "white",
              width: "calc(100% - 52px)",
              height: "100%",
              // borderRadius: "11px",
              overflow: "hidden",
              marginLeft: "26px",
              marginRight: "26px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                typography: "body1",
                paddingTop: "0px",
                display: "flex",
                position: "relative",
              }}
            >
              {!openDrawer && (
                <IconButton
                  onClick={() => setOpenDrawer(true)}
                  sx={{
                    "&:hover": { background: "white" },
                    position: "absolute",
                    left: "0",
                    top: "0",
                    zIndex: "999",
                    background: "white",
                  }}
                >
                  <DrawerIcon style={{ transform: "rotate(180deg)" }} />
                </IconButton>
              )}

              <TabContext value={tabIndex}>
                {/* LEFT PANEL */}
                <Slide
                  direction="right"
                  in={openDrawer}
                  mountOnEnter
                  unmountOnExit
                >
                  <Box
                    sx={{
                      minWidth: "187px",
                      borderLeft: "1px solid white",
                      borderRight: "1px solid #EDF0F6",
                      padding: "0",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        marginBottom: "5px",
                      }}
                    >
                      <IconButton
                        onClick={() => setOpenDrawer(false)}
                        sx={{ "&:hover": { background: "none" } }}
                      >
                        <DrawerIcon />
                      </IconButton>
                    </Box>

                    <TabList
                      orientation="vertical"
                      variant="scrollable"
                      className={classes.tabBtnHeader}
                      indicatorColor="primary"
                      textColor="inherit"
                      onChange={(
                        event: React.SyntheticEvent,
                        newValue: string
                      ) => {
                        // setSelectedTabIndex(newValue);
                        handleCheckOrderChanges(newValue, event);
                      }}
                    >
                      {tabs.map((tab: TabMenu, i) => (
                        <Tab
                          key={i}
                          label={`${tab.description}${
                            tab.count ? `(${tab.count})` : ""
                          }`}
                          value={(i + 1).toString()}
                          icon={
                            tabIndex === (i + 1).toString()
                              ? tab.iconON
                              : tab.icon
                          }
                          iconPosition="start"
                          disabled={i === 4 || i === 5 || i === 9 || i === 12}
                        />
                      ))}
                    </TabList>
                  </Box>
                </Slide>

                {/* RIGHT PANEL */}
                <Box
                  sx={{
                    background: "white",
                    width: "100%",
                    overflowY: "auto",
                    position: "relative",
                  }}
                >
                  {loading && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        zIndex: 999,
                      }}
                    >
                      <CustomizedCircularProgress />
                    </Box>
                  )}

                  {tabIndex === "5" ? (
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      size="small"
                      sx={{
                        position: "absolute",
                        top: "30px",
                        right: "50px",
                        zIndex: 99,
                        textTransform: "none",
                        background: "#5A36BE",
                        borderRadius: "4px",
                        "&:hover": {
                          background: "rgba(107, 74, 201, 0.75)",
                        },
                      }}
                      onClick={handleAddButton}
                    >
                      Add {getAddButtonText()}
                    </Button>
                  ) : null}

                  <TabPanel
                    value="1"
                    style={{ paddingBottom: "0px", height: "55vh" }}
                  >
                    {/* <Notes costCode={costcode} addNoteMode={addNoteMode} setAddNoteMode={setAddNoteMode} closeModal={closeModal}/> */}
                    <Notes costCode={costcode} setLoading={setLoading} />
                  </TabPanel>
                  <TabPanel value="2">
                    <Specifications
                      costcode={costcode}
                      specList={specList}
                      setLoading={setLoading}
                    />
                  </TabPanel>
                  <TabPanel value="3" sx={{ height: "calc(100% - 50px)" }}>
                    <Storage
                      type="F"
                      projectId={null}
                      costCodeId={costCodeId}
                      folderList={folderList}
                      setFolderList={setFolderList}
                      initFileList={fileList}
                      handleAddFolder={handleAddButton}
                      setLoading={setLoading}
                    />
                  </TabPanel>
                  {/*<TabPanel value="4" sx={{ height: "calc(100% - 50px)" }}>*/}
                  {/*  <Storage*/}
                  {/*    type="P"*/}
                  {/*    projectId={null}*/}
                  {/*    costCodeId={costCodeId}*/}
                  {/*    folderList={folderPhotoList}*/}
                  {/*    setFolderList={setFolderPhotoList}*/}
                  {/*    initFileList={fileList}*/}
                  {/*    handleAddFolder={handleAddButton}*/}
                  {/*    setLoading={setLoading}*/}
                  {/*  />*/}
                  {/*</TabPanel>*/}
                  <TabPanel value="4">
                    <Quotes costcode={selectedCostCode} />
                  </TabPanel>
                  <TabPanel value="7" sx={{ padding: "0 6% 5% 3%" }}>
                    <Bills
                      costCodeId={costCodeId}
                      costCodeTitle={title}
                      costCodeStatus={costCodeStatus}
                      billList={billList}
                      setBillList={setBillList}
                      updateOrders={updateOrders}
                      budget={budget}
                      updateBudget={updateBudget}
                      setBudget={setBudget}
                      setLoading={setLoading}
                      isOrderChange={isOrderChange}
                      setIsOrderChange={setIsOrderChange}
                    />
                  </TabPanel>
                  <TabPanel value="8">
                    <Invoices costCodeId={costCodeId} setLoading={setLoading} />
                  </TabPanel>
                  <TabPanel value="9">
                    <Checklists
                      costCodeId={costCodeId}
                      users={users}
                      contractors={contractors}
                      setLoading={setLoading}
                    />
                  </TabPanel>
                  <TabPanel
                    value="11"
                    style={{ paddingBottom: "0px", height: "80vh" }}
                  >
                    <Comments
                      costCodeId={costCodeId}
                      commentList={commentList}
                      setCommentList={setCommentList}
                      closeModal={closeModal}
                      userList={users}
                    />
                  </TabPanel>
                  <TabPanel
                    value="12"
                    style={{ paddingBottom: "0px", height: "80vh" }}
                  >
                    <TakeOffProvider>
                      <TakeOffs project={project} costcode={costcode} />
                    </TakeOffProvider>
                  </TabPanel>
                </Box>
              </TabContext>
            </Box>

            {/* tabs */}
            {dialogOpen ? (
              <InputDialog
                open={dialogOpen}
                title={tabIndex === "3" || tabIndex === "4" ? "New Folder" : ""}
                body={""}
                closeBtn={"Cancel"}
                closeAction={handleCancel}
                saveBtn={"Save"}
                saveAction={
                  tabIndex === "3"
                    ? handleFolderCreation
                    : tabIndex === "4"
                    ? handleFolderPhotoCreation
                    : handleCancel
                }
              />
            ) : (
              <></>
            )}

            <AlertDialog
              open={alertDialogOpen}
              handleClose={handleCloseAlert}
              handleDiscard={handleDiscard}
            />
          </Box>
        </>
      )}
    </Dialog>
  );
};

export default CostCodesDetails;
