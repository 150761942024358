import React, { useState, useEffect } from "react";
import { Mention, MentionsInput } from "react-mentions";
import mentionsStyle from "./inputPriceStyle";
import { Pricelist } from "../../../Models/pricelist";

interface SuggestionDataItem {
    id: string | number;
    display?: string;
}

interface props {
    priceList: Pricelist[];
    description: string;
    updateUnitPrice: Function;
    onDescriptionChange: any;
    setDescription: Function;
}

const BillInputPriceTags: React.FC<props> = ({
    priceList,
    description,
    updateUnitPrice,
    onDescriptionChange,
    setDescription,
}) => {
    const [data, setData] = useState<SuggestionDataItem[]>([]);
    const onChange = (e: any) => {
        e.target.name = "description";
        onDescriptionChange(e);
    };

    useEffect(() => {
        const newData: any = priceList?.map((price, i) => {
            return {
                id: i,
                display: `${price.description}  $${price.price}`,
                description: `${price.description}`,
            };
        });
        setData(newData);
    }, [priceList]);

    return (
        <MentionsInput
            name="description"
            value={description}
            onChange={onChange}
            a11ySuggestionsListLabel={"Suggested mentions"}
            style={{ ...mentionsStyle, control: { ...mentionsStyle.control } }}
        >
            <Mention
                markup="#[__display__]"
                displayTransform={(id, display) => display?.split("$")[0]}
                data={data}
                onAdd={(id, display) => {
                    // console.log(id,display,"inserted",priceList[id as number]);
                    setDescription(display?.split("$")[0])
                    updateUnitPrice(priceList[id as number].price);
                }}
                trigger=""
                style={{
                    // backgroundColor: "#e4dafe",
                    fontSize: "11px",
                }}
            />
        </MentionsInput>
    );
};

export default BillInputPriceTags;
