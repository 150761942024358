import { ButtonProps, styled, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, TextField, Button } from "@mui/material";
import React from "react";

interface props{
    open: boolean,
    handleClose: React.Dispatch<React.SetStateAction<any>>
    handleDialogChange: React.Dispatch<React.SetStateAction<any>>
    handleAddDialog: React.Dispatch<React.SetStateAction<any>>
    dialogTitle: string
    dialogContentText: string
    action: string
}

const CustomDialog: React.FC<props> = ({
    open,
    handleClose,
    handleDialogChange,
    handleAddDialog,
    dialogTitle,
    dialogContentText,
    action
}) => {

    const CancelButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText("#F8F8F8"),
        backgroundColor: "#F8F8F8",
        marginLeft:"66px",
        minWidth:"93px",
        borderRadius: "20px",
        textTransform: "none",
        fontStyle: "normal",
        fontWeight: "normal",
        fontFamily:"Inter",
        fontSize: "11px",
        '&:hover': {
            backgroundColor: "#F8F8F8",
        }
    }));


    const AddButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText("#0044CC"),
        backgroundColor: "#0044CC",
        marginLeft:"66px",
        minWidth:"93px",
        borderRadius: "20px",
        textTransform: "none",
        fontStyle: "normal",
        fontWeight: "normal",
        fontFamily:"Inter",
        fontSize: "11px",
        '&:hover': {
            backgroundColor: "#0044CC",
        },
    }));

    return(
        <div>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleClose}>
                <DialogTitle sx={{
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontFamily:"Inter",
                    fontSize: "11px"
                }}>{dialogTitle}</DialogTitle>
                <DialogContent>
                <DialogContentText sx={{
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontFamily:"Inter",
                            fontSize: "11px"
                        }}>
                    {dialogContentText}
                </DialogContentText>
                {action === "addNotice"
                ?
                <TextField
                    InputProps={{
                        disableUnderline: true,
                        sx:{
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontFamily:"Inter",
                            fontSize: "11px"
                        }
                    }}
                    autoFocus
                    margin="dense"
                    type="text"
                    fullWidth
                    variant="standard"
                    onKeyUp={(event: any) => {if(event.keyCode && event.keyCode === 13){handleAddDialog(event) }}}
                    onChange={(event: any) => {handleDialogChange(event)}}
                />
                :
                action === "filename"
                ?
                <TextField
                    InputProps={{
                        disableUnderline: true,
                        sx:{
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontFamily:"Inter",
                            fontSize: "11px"
                        }
                    }}
                    autoFocus
                    margin="dense"
                    type="text"
                    fullWidth
                    variant="standard"
                    onKeyUp={(event: any) => {if(event.keyCode && event.keyCode === 13){handleAddDialog(event)}}}
                    onChange={(event: any) => {handleDialogChange(event)}}
                />
                :
                <></>
                }
                </DialogContent>
                <DialogActions>
                <CancelButton onClick={handleClose}>Cancel</CancelButton>
                <AddButton onClick={handleAddDialog}>OK</AddButton>
                </DialogActions>
            </Dialog>
        </div>   
    )
}
export default CustomDialog