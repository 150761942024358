import React, { useState } from 'react'
import {
  Dialog,
  Box,
  Typography,
  Slide,
  IconButton,
  AppBar,
  Toolbar,
} from '@mui/material'
import useStyles from './projectStyles'
import { Close } from '@mui/icons-material'
import useMountStatus from '../../../utilities/Helper/checkMountStatus'
import Storage from '../Storage/Storage'
import { Company } from '../../../Models/company'
import { Project } from '../../../Models/project'
import { FileData, Folder } from '../../../Models/storage'
import CustomizedCircularProgress from '../../../utilities/Progress/CustomizedCircularProgress'
import { authContextInterface, useAuth } from '../../Providers/AuthProvider'
import InputDialog from '../../../utilities/InputDialog/inputDialog'
import storageAPI from '../../../Services/DoxleAPI/storageAPI'
import Notes from '../Notes/Notes'
import { TransitionProps } from '@mui/material/transitions'
import AddProjectModal from "./addProject";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

interface props {
  openModal: boolean
  closeModal: () => void
  action: string
  projectSelected: Project | null
  setProjectSelected: React.Dispatch<React.SetStateAction<Project|null>>
  projects: Project[]
  setProjects:  React.Dispatch<React.SetStateAction<Project[]>>
  company: Company | undefined
}

const ProjectModal: React.FC<props> = ({
  openModal,
  closeModal,
  action,
  projectSelected,
  setProjectSelected,
  projects,
  setProjects,
  company,
}) => {
  const [folderList, setFolderList] = useState<Array<Folder>>([])
  const [fileList, setFileList] = useState<Array<FileData>>([])

  const [dialogOpen, setDialogOpen] = useState<boolean | undefined>(undefined)

  const [loading, setLoading] = useState<boolean>(true)
  const unmounted = useMountStatus()
  const classes = useStyles()
  const authContext = useAuth() as authContextInterface
  const { setLoggedIn } = authContext

  const getActionBreadcrumb = (action: string) => {
    if (action === 'storage') return 'Files';
    if (action === 'photo') return 'Photos';
    if (action === 'notes') return 'Notes';
    if (action === 'editProject') return 'Edit Project';
    if (action === 'newProject') return 'Add Project';
  }

  const breadcrumbs = [
    <Typography style={{ color: '#000' }} key={0} className={classes.heading}>
      Byson Group
    </Typography>,
    <Typography key={1} className={classes.heading}>
      Projects
    </Typography>,
    <Typography key={2} className={classes.heading}>
      {getActionBreadcrumb(action)}
    </Typography>,
  ]

  const handleAddButton = () => {
    setDialogOpen(true)
  }

  const handleFolderCreation = async (input: string) => {
    try {
      const response = (await storageAPI.createFolder(
        input,
        projectSelected?.projectId || null,
        null,
        'F'
      )) as Folder
      setFolderList((preList) => [...preList, response])
      setDialogOpen(false)
    } catch (err) {
      err === 'AccessTokenNotFound' ? setLoggedIn(false) : console.error(err)
    }
  }

  const handleCancel = () => {
    setDialogOpen(false)
  }

  return (
    <Dialog
      fullScreen
      open={openModal}
      onClose={closeModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      disableAutoFocus
      TransitionComponent={Transition}
      sx={{ '.MuiDialog-paper': { background: '#F1F2F5' } }}
      onKeyUpCapture={(e) => {
          if (e.key === '"Escape"' || e.keyCode === 27) closeModal()
      }}
    >
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: '#F1F2F5',
          color: 'black',
          maxHeight: '43px',
          px: '2px',
        }}
        elevation={0}
      >
        <Toolbar>
          <Typography
            style={{
              fontFamily: 'Roboto Mono',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '11px',
              lineHeight: '15px',
              color: '#7C768D',
              flex: 1,
              textTransform: 'capitalize',
            }}
          >
            {company ? company.name : 'Byson Group'} /{' '}
            {projectSelected?.siteAddress} / {getActionBreadcrumb(action)}
          </Typography>
          <IconButton
            edge='start'
            color='inherit'
            onClick={closeModal}
            aria-label='close'
            sx={{ '&:hover': { borderRadius: '11px' } }}
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          marginTop: '1%',
          marginBottom: '26px',
          alignSelf: 'center',
          width: 'calc(100% - 100px)',
          height: '100%',
          // borderRadius: "11px",
          overflow: 'hidden',
          marginLeft: '26px',
          marginRight: '26px',
          padding: '24px',
          background: 'white',
          overflowY: 'auto',
        }}
      >
        {action === 'storage' || action === 'photo' ? (
          <Storage
            type={'F'}
            projectId={
              projectSelected?.projectId ? projectSelected.projectId : null
            }
            costCodeId={null}
            folderList={folderList}
            setFolderList={setFolderList}
            initFileList={fileList}
            handleAddFolder={handleAddButton}
            setLoading={setLoading}
          />
        ) : action === 'notes' && projectSelected ? (
          <Notes project={projectSelected} setLoading={setLoading} />
        ) : action === 'editProject' || action === 'newProject' ? (
            <AddProjectModal
                action={action}
                currentProject={projectSelected}
                setProjectSelected={setProjectSelected}
                projects={projects}
                setProjects={setProjects}
                closeModal={closeModal}
                setLoading={setLoading}
            />
        ) : (
          <></>
        )}

        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              zIndex: 999,
            }}
          >
            <CustomizedCircularProgress />
          </Box>
        )}
      </Box>
      {dialogOpen && (
        <InputDialog
          open={dialogOpen}
          title={'New Folder'}
          body={''}
          closeBtn={'Cancel'}
          closeAction={handleCancel}
          saveBtn={'Save'}
          saveAction={handleFolderCreation}
        />
      )}
    </Dialog>

  )
}

export default ProjectModal
