import React, { useState, useEffect, useRef } from "react";
import { Notice } from "../../../Models/notice";
import "../Notices/Notices.css";
import { Grid, TextField, Box, Button} from "@mui/material";
import checklistGroupAPI from "../../../Services/DoxleAPI/checklistGroupAPI";
import {Permit} from "../../../Models/permit";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import ChecklistTable from "./ChecklistTable";
import { ReactComponent as PlusCircle } from "../../../Assets/PlusCircleWhite2.svg";
import CustomizedCircularProgress from "../../../utilities/Progress/CustomizedCircularProgress";

interface props {
    notice: Notice;
}

const ChecklistContainer: React.FC<props> = ({
                                                 notice,
                                             }) => {
    const [checklistLists, setChecklistLists] = useState<Permit[]>([]);
    const [listTitle, setListTitle] = useState<string>("");
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;
    const [ loading, setLoading ] = useState<boolean>(true);
    const ref = useRef(null);

    const deleteChecklistList = async (checklistGroupId: string|undefined) => {
        try {
            if (!checklistGroupId) return;
            const response = await checklistGroupAPI.remove(checklistGroupId)
            if (!response) return;
            let newLists: Permit[] = []
            checklistLists.forEach((list: Permit) => {
                if (checklistGroupId !== list.checklistGroupId) newLists.push(list)
            })
            setChecklistLists([...newLists])
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    const handleListTitleChange = (e:any) =>{
        setListTitle(e.target.value)
    }

    const handleListTitleKeyUp = async (e:any) =>{
        try{
            if (!user?.userId) return;
            if (e.keyCode === 13) {
                const response = await checklistGroupAPI.add(
                    {
                        title: listTitle,
                        checklistGroupId: "",
                        notice: notice.noticeId,
                        timeStamp: new Date().toISOString(),
                        startDate: new Date().toISOString().substring(0, 10),
                        endDate: new Date().toISOString().substring(0, 10),
                    }, user.userId
                )
                if (response) setChecklistLists([...checklistLists, response]);
                setListTitle("");
            }
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }


    const fetchPermits = async() => {
        try {
            const response = await checklistGroupAPI.getList(null,  notice.noticeId, null) as Permit[];
            console.log(response)
            // if (unmounted) return;
            setChecklistLists([...response]);
            setLoading(false);
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    useEffect(() => {
        fetchPermits();
    },[notice]);

    return (
        <>
            {loading &&
                <Box sx={{ position: "absolute", top: '50%', left: '50%', zIndex: 999}}>
                    <CustomizedCircularProgress/>
                </Box>
            }
            
            <Grid container paddingX="50px">
                <Grid item xs={7}>
                    {checklistLists.map((list: Permit) => (
                        <ChecklistTable
                            key={list.checklistGroupId}
                            checklistList={list}
                            deleteChecklistList={deleteChecklistList}
                        />
                        ))
                    }

                    <Grid container
                        sx={{
                            paddingTop: "20px",
                            marginBottom: "50px",
                        }}
                    >
                        <Grid item xs={1} sx={{height: "24px"}}/>
                        <Grid item xs={10} 
                        >
                            <TextField
                                inputRef={ref}
                                placeholder="Add List"
                                value={listTitle}
                                onChange={handleListTitleChange}
                                onKeyUp={handleListTitleKeyUp}                            
                                variant="standard"
                                sx={{
                                    color: "#5A36BE",
                                }}
                                InputProps={{
                                    disableUnderline: true,
                                    sx:{
                                        color: "#5A36BE",
                                        fontWeight: 600,
                                        fontSize: "12px",
                                        fontFamily: 'Roboto Mono',
                                        fontStyle: 'normal',
                                        lineHeight: '16px',
                                        "& .MuiInputBase-input":{
                                            textAlign: "left",
                                            paddingBottom: "1px",
                                            paddingTop:"1px",
                                            maxHeight:"28px",
                                            width:"70vw",
                                            // border: "white",
                                            borderRadius:"5px 5px 0px 0px"
                                        },
                                        "& .MuiInputLabel-formControl":{
                                            color:"#9974fe",
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {color: "#9974fe"},
                                    sx: {
                                        top: "-10px",
                                        "& .MuiInputLabel-shrink":{
                                            top: "0px !important",
                                        }
                                    },
                                }}
                            />

                        </Grid>
                        <Grid item xs={1}/>
                    </Grid>

                </Grid>
                <Grid item xs={3} />
                <Grid item xs={2} display="flex" justifyContent="flex-end" alignItems="start">
                    

                    <Button 
                        sx={{background: "#754EE0", borderRadius: "12px", paddingY: "10px", paddingX: "30px",
                            "&:hover" : {background: "#855EF0"}
                        }} 
                        variant="contained" 
                        startIcon={<PlusCircle />}
                        onClick={() => ref.current && (ref as any).current.focus()}
                    >
                        Add List
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default ChecklistContainer;