import { useSocket } from "./../Sockets/useSocket";
import { Pricelist } from "./../../Models/pricelist";
import { baseAddress } from "../../settings";
import axios from "axios";
import { result } from "lodash";
import { offline } from "../../settings";
import cookieService from "../cookieService";
export interface PricelistResponse {
  items: Pricelist[];
  nextPage: number|null
}

const getList = async (page: number) => {
  let result: PricelistResponse = {items: [], nextPage: null};
  const accessToken = cookieService.get("access_token");

  //* IF OFFLINE THEN RETURN EMPTY ARRAY
  if (offline) return result;

  //* IF NO ACCESS TOKEN , THROW ERROR
  if (!accessToken) throw "AccessTokenNotFound";

  try {
    let params: any = {page: page};

    const response = await axios.get(baseAddress + "/pricelist/", {
      headers: { Authorization: "Bearer " + accessToken },
      params: params,
    });
    result.items = response?.data?.results ? response.data.results as Pricelist[] : [];
    result.nextPage = response?.data?.next ? ++page : null;
    console.warn(result);
    return result;
  } catch (error) {
    console.error("ERROR FETCHING PRICELIST" + error);
    return [];
  }
};

// const add = (pricelist: any) => {
//   try {
//     // const socket = useSocket();
//     // socket.send(
//     //   JSON.stringify({
//     //     type: "Pricelist",
//     //     message: pricelist,
//     //   })
//     );
//   } catch (error) {
//     console.error("SOCKET ERROR ADDING" + error);
//   }
// };

const PricelistAPI = {
  getList,
};
export default PricelistAPI;
