import {nanoid} from "nanoid";
import {DoxCircle, DoxLine, DoxPolygon, DoxRectangle, Sheet, TakeOff} from "../../../../Models/takeOffs";
import {toFloat} from "./generalFunctions";

interface circleMouseDownProps{
    currentShape: DoxCircle|DoxLine|DoxRectangle|DoxPolygon|null
    setCurrentShape: Function
    currentUnit: "LM"|"M2"|"M3"|"EA"|undefined
    currentColour: string
    currentSheet: Sheet
    selectedTakeOff: TakeOff
    zHeight: number
    pos: { x: number, y: number}
    scale: number
    measurements: (DoxCircle|DoxLine|DoxRectangle|DoxPolygon)[]
    setMeasurements: Function
    setUnsavedMeasurements: Function
    deduction: 1|-1;
}

const circleMouseDown = ({
                             currentShape,
                             setCurrentShape,
                             currentUnit,
                             currentColour,
                             currentSheet,
                             selectedTakeOff,
                             zHeight,
                             pos,
                             scale,
                             measurements,
                             setMeasurements,
                             setUnsavedMeasurements,
                             deduction
                         }: circleMouseDownProps) => {
    if (!currentShape){
        setCurrentShape({
            borderColor: deduction < 0 ? "#000" : currentColour,
            bgColor: deduction < 0 ? "#000" : currentColour,
            bgThickness: 2,
            shape: "circle",
            id: nanoid(),
            value: 0.0,
            zMultiplier: zHeight && currentUnit === "M3" ? zHeight : 1.0,
            zValue: 0.0,
            sheet: currentSheet?.sheetId,
            takeOff: selectedTakeOff?.takeOffId,
            start: { x: pos.x, y: pos.y },
            radius: 0.0
        })
    } else {
        const currentCircle = currentShape as DoxCircle
        setCurrentShape(null)
        let radius:number = Math.sqrt(Math.pow(toFloat(currentCircle.start.x) - pos.x, 2) + Math.pow(toFloat(currentCircle.start.y) - pos.y, 2))
        let rawValue: number = 0;
        let scaledValue: number = 0;
        if (currentUnit === "LM") {
            rawValue = Math.PI * radius * 2 * deduction;
            scaledValue = rawValue * scale;
        } else if (currentUnit === "M2"){
            rawValue = Math.PI * Math.pow(radius,2) * deduction;
            scaledValue = rawValue  * Math.pow(scale, 2);
        } else if (currentUnit === "M3"){
            rawValue = Math.PI * Math.pow(radius,2) * deduction;
            scaledValue = rawValue * zHeight * Math.pow(scale, 2);
        } else return
        const finishedCircle: DoxCircle = {
            ...currentCircle,
            radius: radius,
            value: rawValue,
            zValue: scaledValue
        } as DoxCircle
        setMeasurements([...measurements, finishedCircle])
        setUnsavedMeasurements(true)
    }
}

interface circleMouseMoveProps{
    currentShape: DoxCircle|DoxLine|DoxRectangle|DoxPolygon
    setCurrentShape: Function
    currentUnit: "LM"|"M2"|"M3"|"EA"|undefined
    pos: { x: number, y: number}
    scale: number
    deduction: 1|-1;
}
const circleMouseMove = ({currentShape, setCurrentShape, currentUnit, pos, scale, deduction}: circleMouseMoveProps) => {
    const currentCircle = currentShape as DoxCircle
    let radius:number = Math.sqrt(Math.pow(toFloat(currentCircle.start.x) - pos.x, 2) + Math.pow(toFloat(currentCircle.start.y) - pos.y, 2))
    let rawValue: number = 0;
    let scaledValue: number = 0;
    if (currentUnit === "LM") {
        rawValue = Math.PI * radius * 2 * deduction;
        scaledValue = rawValue * scale;
    } else if (currentUnit === "M2"){
        rawValue = Math.PI * Math.pow(radius,2) * deduction;
        scaledValue = rawValue * Math.pow(scale, 2);
    } else if (currentUnit === "M3"){
        rawValue = Math.PI * Math.pow(radius,2);
        scaledValue = rawValue * toFloat(currentShape.zMultiplier) * Math.pow(scale, 2);
    } else return

    setCurrentShape({
        ...currentCircle,
        radius: radius,
        value: rawValue,
        zValue: scaledValue
    } as DoxCircle)
}

export {circleMouseDown, circleMouseMove}