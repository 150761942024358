import {nanoid} from "nanoid";
import {DoxCircle, DoxLine, DoxPolygon, DoxRectangle, Sheet, TakeOff} from "../../../../Models/takeOffs";
import {toFloat} from "./generalFunctions";

interface lineMouseDownProps{
    currentShape: DoxCircle|DoxLine|DoxRectangle|DoxPolygon|null;
    setCurrentShape: Function;
    currentUnit: "LM"|"M2"|"M3"|"EA"|undefined;
    currentColour: string;
    currentSheet: Sheet;
    selectedTakeOff: TakeOff|null;
    zHeight: number;
    pos: { x: number, y: number};
    scale: number;
    toString: Function;
    measurements: (DoxCircle|DoxLine|DoxRectangle|DoxPolygon)[];
    setMeasurements: Function;
    setUnsavedMeasurements: Function;
    scalingMode: boolean;
    scaleLine: DoxLine|null;
    setScaleLine: Function;
    deduction: 1|-1;
}

const lineMouseDown = ({
                             currentShape,
                             setCurrentShape,
                             currentUnit,
                             currentColour,
                             currentSheet,
                             selectedTakeOff,
                             zHeight,
                             pos,
                             scale,
                             measurements,
                             setMeasurements,
                             setUnsavedMeasurements,
                             scalingMode,
                             scaleLine,
                             setScaleLine,
                             deduction
                         }: lineMouseDownProps) => {
    console.log('currentShape', currentShape)
    console.log('scaleLine', scaleLine)
    if (!(selectedTakeOff || scalingMode)) return;
    if (!currentShape && !scaleLine ){
        setCurrentShape({
            borderColor: deduction < 0 && !scalingMode ? "#000" : currentColour,
            bgColor: deduction < 0 && !scalingMode ? "#000" : currentColour,
            bgThickness: 2,
            shape: "line",
            id: nanoid(),
            value: 0.0,
            zMultiplier: (currentUnit === "M2" && zHeight) ? zHeight : 1.0,
            zValue: 0.0,
            sheet: currentSheet?.sheetId,
            takeOff: selectedTakeOff?.takeOffId,
            start: { x: pos.x, y: pos.y },
            end: { x: pos.x, y: pos.y }
        })
    }
    else {
        const currentLine = currentShape as DoxLine
        setCurrentShape(null)
        let rawValue:number = Math.sqrt(Math.pow(toFloat(currentLine.start.x) - pos.x, 2) + Math.pow(toFloat(currentLine.start.y) - pos.y, 2)) * deduction
        const finishedShape: DoxLine = {
            ...currentLine,
            end: { x: pos.x, y: pos.y },
            value: rawValue,
            zValue: rawValue * toFloat(currentLine.zMultiplier) * scale
        } as DoxLine
        if (scalingMode) {
            setScaleLine(finishedShape);
            setCurrentShape(null);
        } else {
            setMeasurements([...measurements, finishedShape]);
            setUnsavedMeasurements(true)
        }
    }
}

interface lineMouseMoveProps{
    currentShape: DoxCircle|DoxLine|DoxRectangle|DoxPolygon;
    setCurrentShape: Function;
    currentUnit: "LM"|"M2"|"M3"|"EA"|undefined;
    pos: { x: number, y: number};
    scale: number;
    scalingMode: boolean;
    deduction: 1|-1;
}
const lineMouseMove = ({currentShape, setCurrentShape, currentUnit, pos, scale, scalingMode, deduction}: lineMouseMoveProps) => {
    const currentLine = currentShape as DoxLine
    let rawValue:number = Math.sqrt(Math.pow(toFloat(currentLine.start.x) - pos.x, 2) + Math.pow(toFloat(currentLine.start.y) - pos.y, 2))
    setCurrentShape({
        ...currentLine,
        end: { x: pos.x, y: pos.y },
        value: scalingMode ? rawValue : rawValue * deduction,
        zValue: scalingMode ? rawValue * toFloat(currentLine.zValue) * scale : rawValue * toFloat(currentLine.zValue) * scale * deduction
    } as DoxLine)
}

export {lineMouseDown, lineMouseMove}