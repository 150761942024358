import {DoxCircle, DoxLine, DoxPolygon, DoxRectangle, Sheet, TakeOff, XY} from "../../../Models/takeOffs";
import React, {useState} from "react";
import {Circle, Line, Rect, Text} from "react-konva";
import {toFloat} from "./konvaFunctions/generalFunctions";
import {Html} from "react-konva-utils";

interface props {
    shape: DoxPolygon | DoxLine | DoxRectangle | DoxCircle;
    // currentUnit: "LM"|"M2"|"M3"|"EA"|undefined;
    // currentSheetId: string|undefined;
    // drawing? : true;
    // label?: boolean;
    // zoom: number;
    // index: number;
    // showLabelIndex: number;
    // setShowLabelIndex: Function
}

const KonvaInteractiveLabel: React.FC<props> = ({shape}) => {

//     const [opacity, setOpacity] = useState<number>(0.7)
//     const shapeType: "polygon"|"line"|"rectangle"|"circle" = shape.shape
//     let points: number[] = []
//     let x: number = 0
//     let y: number = 0
//     let width: number = 0
//     let height: number = 0
//     let radius: number = 0
//     if (shape.shape === "line") {
//         if (typeof shape.start.x === "string") shape.start.x = parseFloat(shape.start.x);
//         if (typeof shape.start.y === "string") shape.start.y = parseFloat(shape.start.y);
//         if (typeof shape.end.x === "string") shape.end.x = parseFloat(shape.end.x);
//         if (typeof shape.end.y === "string") shape.end.y = parseFloat(shape.end.y);
//         points = [ shape.start.x, shape.start.y, shape.end.x, shape.end.y ]
//     }
//     if (shape.shape === "polygon") {
//         shape.points.forEach((pair: XY) => {
//             points.push(typeof pair.x === "string" ? pair.x = parseFloat(pair.x) : pair.x);
//             points.push(typeof pair.y === "string" ? pair.y = parseFloat(pair.y) : pair.y);
//         })
//     }
//     else if (shape.shape === "rectangle") {
//         x = (typeof shape.start.x === "string") ? parseFloat(shape.start.x) : shape.start.x;
//         y = (typeof shape.start.y === "string") ? parseFloat(shape.start.y) : shape.start.y;
//         width = (typeof shape.end.x === "string") ? parseFloat(shape.end.x) - x : shape.end.x - x;
//         height = (typeof shape.end.y === "string") ? y+ parseFloat(shape.end.y) - y:  shape.end.y - y;
//
//     }
//     else if (shape.shape === "circle") {
//         x = (typeof shape.start.x === "string") ? parseFloat(shape.start.x) : shape.start.x;
//         y = (typeof shape.start.y === "string") ? parseFloat(shape.start.y) : shape.start.y;
//         radius = (typeof shape.radius === "string") ? parseFloat(shape.radius) : shape.radius;
//     }
//     return (
//         !currentSheetId || currentSheetId !== shape.sheet || showLabelIndex === index?
//             null
//             :
return (
    // !currentSheetId || currentSheetId !== shape.sheet || showLabelIndex === index?
    //     null
    //     :
        <Html
            divProps={{
                style: {
                    position: 'absolute',
                    top: 10,
                    left: 10
                }
            }}
        >

        </Html>
)
}
//     )
// }

export default KonvaInteractiveLabel;