import { Project } from "../../../Models/project";
import { User } from "../../../Models/user";
import { AddressBookCompany } from "../../../Models/addressBook";
import { ColumnView } from "../../../Models/columnView";
import React from "react";
import { Stage } from "../../../Models/stage";
import { Grid, Table, TableCell, Typography } from "@mui/material";
import { toFloat } from "../Measurements/konvaFunctions/generalFunctions";
import { TableRow } from "@material-ui/core";

interface props {
  project: Project | null;
  stages: Stage[];
}

const formatter: Intl.NumberFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const getPercentage = (
  stageValue: string | number | undefined,
  contractValue: string | number | undefined
) => {
  // console.log("stageValue", stageValue)
  // console.log("contractValue", contractValue)
  // console.log("percentage", Math.round( toFloat(stageValue) * 100 / toFloat(contractValue)))
  return Math.round((toFloat(stageValue) * 100) / toFloat(contractValue));
};

const toMonth = (month: undefined | string) => {
  const months = [
    "",
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  return months[toFloat(month)];
};

const StageHeader: React.FC<props> = ({ project, stages }) => (
  <Table style={{ marginTop: "24px", marginBottom: "24px" }}>
    {/*<Grid container>*/}
    <TableRow>
      <TableCell
        style={{
          minWidth: "150px !important",
          width: "12.5% !important",
          // minWidth: 100 / (stages.length+1) +"% !important",
          // maxWidth: 100 / (stages.length+1) +"% !important",
          color: "#42395B",
          fontFamily: "IBM Plex Mono",
          fontWeight: "300",
          fontSize: "10px",
          textAlign: "center",
          whiteSpace: "nowrap",
          padding: "0px 16px",
        }}
      >
        CONTRACT
      </TableCell>
      {/*//!BEN- Peter changed this line to disable stage final and developer costs*/}
      {stages
        .filter(
          (stage) =>
            stage.title.toLowerCase() !== "developer costs" &&
            stage.title.toLowerCase() !== "variations"
        )
        .map((stage: Stage) => (
          <TableCell
            key={stage.stageId + "_head"}
            style={{
              minWidth: "150px !important",
              width: "12.5% !important",
              // minWidth: 100 / (stages.length+1) +"% !important",
              // maxWidth: 100 / (stages.length+1) +"% !important",
              color: "#42395B",
              fontFamily: "IBM Plex Mono",
              fontWeight: "300",
              fontSize: "10px",
              textAlign: "center",
              whiteSpace: "nowrap",
              padding: "0px 16px",
            }}
          >
            {`${stage.title.toUpperCase()} (${getPercentage(
              stage.value,
              project?.contractPrice
            )} %)`}
          </TableCell>
        ))}
    </TableRow>
    <TableRow>
      {/*</Grid>*/}
      {/*<Grid container style={{backgroundColor: "white"}}>*/}
      <TableCell
        key={"contract_data"}
        style={{
          minWidth: "150px !important",
          width: "12.5% !important",
          // minWidth: 100 / (stages.length+1) +"% !important",
          // maxWidth: 100 / (stages.length+1) +"% !important",
          textAlign: "center",
          borderRight: "#855CF8 1px solid",
          backgroundColor: "white",
          whiteSpace: "nowrap",
          padding: "0px 16px",
        }}
      >
        <Typography style={{ fontFamily: "Roboto Mono", fontSize: "12px" }}>
          {" "}
          {formatter.format(toFloat(project?.contractPrice))}{" "}
        </Typography>
        {/*<br/>*/}
        <Typography
          style={{
            color: "#BD2B2B",
            fontFamily: "Roboto Mono",
            fontSize: "11px",
          }}
        >
          {" "}
          {`(${project?.endDate?.substring(8, 10)}-${toMonth(
            project?.endDate?.substring(5, 7)
          )}-${project?.endDate?.substring(0, 4)})`}{" "}
        </Typography>
      </TableCell>
      {/*BEN- Peter changed this line to disable stage final and developer costs*/}
      {stages
        .filter(
          (stage) =>
            stage.title.toLowerCase() !== "developer costs" &&
            stage.title.toLowerCase() !== "variations"
        )
        .map((stage: Stage, i: number) => (
          <TableCell
            key={stage.stageId + "_data"}
            style={{
              minWidth: "150px !important",
              width: "12.5% !important",
              // minWidth: 100 / (stages.length+1) +"% !important",
              // maxWidth: 100 / (stages.length+1) +"% !important",
              textAlign: "center",
              borderRight:
                i + 1 !== stages.length ? "#855CF8 1px solid" : "none",
              backgroundColor: "white",
              whiteSpace: "nowrap",
              padding: "0px 16px",
            }}
          >
            <Typography style={{ fontFamily: "Roboto Mono", fontSize: "12px" }}>
              {" "}
              {formatter.format(toFloat(stage?.value))}
            </Typography>
            {/*<br/>*/}
            <Typography
              style={{
                color: "#BD2B2B",
                fontFamily: "Roboto Mono",
                fontSize: "11px",
              }}
            >
              {" "}
              {`(${stage?.targetDate?.substring(8, 10)}-${toMonth(
                stage?.targetDate?.substring(5, 7)
              )}-${stage?.targetDate?.substring(0, 4)})`}{" "}
            </Typography>
          </TableCell>
        ))}
    </TableRow>
    {/*</Grid>*/}
  </Table>
);

export default StageHeader;
