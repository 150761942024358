import React, {useState, useEffect} from "react";
import {BillLine} from "../../../Models/bill";
import useStyles from "./BillsStyle";
import {Autocomplete, Input, MenuItem, Select, TableCell, TableRow, TextField} from "@mui/material";
import CustomCheckbox from "../../../utilities/CheckBox/CheckBox";
import {ReactComponent as FlagIcon} from "../../../Assets/Flag-2.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BillInputPriceTags from "./BillInputPriceTags";
import { Pricelist } from "../../../Models/pricelist";
interface props{
    line: BillLine
    lineIndex: number;
    writeLines: BillLine[];
    setWriteLines: Function;
    noOfLines: number;
    setNoOfLines: Function;
    checkedLines: string[];
    setCheckedLines: Function;
    rowSubTotal: number;
    disableLineField: boolean;
    setIsOrderChange: Function;
    priceList: Pricelist[];
    lookUpPricelist: boolean
    isPoReview?: boolean;
}

const uomOptions =  [
    "ea",
    "lm",
    "m2",
    "m3",
    "hrs",
    "days",
    "per 1000",
    "lengths",
    "sheets",
    "box",
    "pk",
    "tonne",
    "allowance"
]


const Lines = ({
                   line,
                   lineIndex,
                   writeLines,
                   setWriteLines,
                   noOfLines,
                   setNoOfLines,
                   checkedLines,
                   setCheckedLines,
                   rowSubTotal,
                   disableLineField,
                   setIsOrderChange,
                   priceList,
                   lookUpPricelist,
                   isPoReview
               }: props) => {

    const formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });

    const [unitPrice, setUnitPrice] = useState<string | number>(line.itemCost);
    const [unitPriceInput, setUnitPriceInput] = useState<string | number>(
        formatter.format(parseFloat(line.itemCost as string))
        );
    const [uom, setUOM] = useState<string>(line.unit);
    const [qty, setQty] = useState<string | number>(line.quantity);
    const [description, setDescription] = useState<string>(line.description);
    const [widthOfText, setWidthOfText] = useState<any>((description.length+1)* 6);
    const [rowSub, setRowSubTotal] = useState(rowSubTotal);
    const lineId = line.billLineId || ""
    const classes = useStyles();

    const resetItemCost = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value = formatter.format(parseFloat(unitPrice as string));
        setRowSubTotal(parseFloat(qty as string) * parseFloat(unitPrice as string));
    };

    const updateUnitPriceInputValue = (price = unitPrice) => {
        setUnitPrice(price);
        setUnitPriceInput(formatter.format(parseFloat(price as string)));
        setRowSubTotal(parseFloat(qty as string) * parseFloat(price as string));
        setIsOrderChange(true);
        const event:any = {target: {name: "", value: ""}};
        event.target.name = "itemCost";
        event.target.value = price;
        updateWriteLines(event) // update total
    }

    async function handleChangeBillDetail(event: any, i: any) {
        if (event.target.name === "description") {
            setDescription(event.target.value);
            if(widthOfText === 100){
                return;
            }
            setWidthOfText((description.length+1)* 6);
            if (i + 1 === noOfLines) {
                setNoOfLines(noOfLines+1)
            }
            setIsOrderChange(true);

            // on cleared description
            if (event.target.value === "") {
                setUnitPrice(0);
                setUnitPriceInput(formatter.format(0));
                setQty(0);
                setRowSubTotal(0);
            }
        }
        if (event.target === "uom") {
            setUOM(event.value)
        }
        if (event.target.name === "quantity") {
            setQty(event.target.value);
            setRowSubTotal(parseFloat(event.target.value as string) * parseFloat(unitPrice as string));
            setIsOrderChange(true);
        }
        if (event.target.name === "itemCost") {
            try {
                let itemCostInput = event.target.value.replaceAll('$','').replaceAll(',','');
                if (isNaN(parseFloat(itemCostInput)) || unitPrice === itemCostInput)
                {
                    resetItemCost(event)
                } else {
                    setUnitPrice(itemCostInput);
                    setRowSubTotal(parseFloat(qty as string) * parseFloat(itemCostInput as string));
                    event.target.value = formatter.format(parseFloat(itemCostInput as string));
                }
            } catch(err){
                console.log(err);
                resetItemCost(event)
            }
            setIsOrderChange(true);
        }
        updateWriteLines(event)
    }

    const updateWriteLines = (event: any) => {
        let updateLines: BillLine[] = []
        writeLines.forEach((writeLine: BillLine) => {
            if (writeLine.billLineId !== line.billLineId && writeLine.description !== '') {
                updateLines.push(writeLine)
            }
        })
        // console.log('line.billLineId', description !== '')
        if (!(event.target.name === "description" && event.target.value === '')
            ||
            (event.target.name !== "description" && description !== '' && !isNaN(parseFloat(qty as string)) && !isNaN(parseFloat(unitPrice as string))))
        {
            const updateDescription: string = event.target.name === "description" ? event.target.value : description;
            const updateItemCost: number = event.target.name === "itemCost" ? parseFloat(event.target.value.replaceAll('$','').replaceAll(',','')) : parseFloat(unitPrice as string);
            const updateUOM: string = event.target === "uom" ? event.value : uom;
            const updateQty: number = event.target.name === "quantity" ? parseFloat(event.target.value.replaceAll(',','')) : parseFloat(qty as string);
            const lineCost =
                event.target.name === "itemCost" ? updateItemCost * parseFloat(qty as string) :
                    event.target.name === "quantity" ? updateQty * parseFloat(unitPrice as string) :
                        parseFloat(qty as string) * parseFloat(unitPrice as string);
            updateLines.push({
                billLineId: line.billLineId,
                index: line.index,
                description: updateDescription,
                itemCost: updateItemCost,
                itemFormula: "",
                quantity: updateQty,
                quantityFormula: "",
                lineCost: lineCost,
                ticked: false,
                unit: updateUOM,
            })
        }
        // console.log(updateLines)
        setWriteLines([...updateLines])
    }

    const handleCheckBoxClick = (event: any,) => {
        console.log(lineId)
        if (lineId === "") {return}
        if (event.target.checked && checkedLines.indexOf(lineId) === -1){
            event.target.checked  ? setCheckedLines([...checkedLines, line.billLineId]) : console.log("checked but in not array")
        } else if (!event.target.checked && checkedLines.length === 1) {
            setCheckedLines([])
        } else  if (!event.target.checked && checkedLines.indexOf(lineId) !== -1) {
            let newLines: string[] = []
            checkedLines.forEach((id)=>{ if (id !== lineId) newLines.push(id) })
            setCheckedLines([...newLines])
        }
    };

    return (
        <TableRow className='tableRowStyle' key={line.billLineId} style={{opacity: "1"}}>
            <TableCell style={{textAlign: "center", width: "50px", borderRightWidth: 0}} className={classes.tableBodyStyle2}>
                <CustomCheckbox
                    onClick={(event: any) => {
                        handleCheckBoxClick(event);
                    }}
                    style={{
                        transform: "scale(0.8)",
                    }}
                    checked={(checkedLines.indexOf(line.billLineId || "") !==-1 )}
                    disabled={disableLineField || (description === "" && parseFloat(qty as string) === 0 && parseFloat(unitPrice as string) === 0)}
                />
            </TableCell>
            <TableCell
                style={{ borderLeftWidth: 0}} className={line.ticked ? [classes.tableBodyTitleFieldStyle, classes.descriptionInputAdjustment, classes.yellowFlagged].join(" ") : [classes.tableBodyTitleFieldStyle, classes.descriptionInputAdjustment].join(" ")}>
                <div style={{width: "100%"}}>
                    <div style={{display: 'inline'}}>
                        {
                            (!isPoReview) && lookUpPricelist
                            ?
                                <BillInputPriceTags
                                    priceList={priceList}
                                    description={description}
                                    updateUnitPrice={updateUnitPriceInputValue}
                                    onDescriptionChange={(event: any) => handleChangeBillDetail(event, lineIndex)}
                                    setDescription={setDescription}
                                />
                            :
                                <TextField
                                    sx={{
                                        // height: "15px",
                                        width: `100%`,
                                        border: "none",
                                        fontFamily: "Roboto Mono !important",
                                        fontStyle: "normal !important",
                                        fontWeight: 400 + "!important",
                                        fontSize: "11px !important",
                                        lineHeight: "12px !important",
                                        // color: "#000000 !important",
                                        "& .MuiInput-root" : {
                                            fontFamily: "Roboto Mono !important",
                                            fontStyle: "normal !important",
                                            fontWeight: 400 + "!important",
                                            fontSize: "11px !important",
                                        }
                                    }}
                                    multiline
                                    name="description"
                                    InputProps={{ disableUnderline: true }}
                                    id={'description' + lineIndex}
                                    value={description}
                                    className={classes.inputDescription}
                                    onChange={(event: any) => handleChangeBillDetail(event, lineIndex)}
                                    variant="standard"
                                    disabled={disableLineField}
                                />

                        }
                    </div>
                    <div style={{display: 'inline'}}>
                        {
                            line.ticked && line.checked
                                ?
                                <span style={{position: "absolute"}}>
                                  <FlagIcon style={{background: "#FFC804", borderRadius: "50px"}}
                                            className={classes.flagTrueIconShow}/>
                                </span>
                                :
                                null
                        }
                    </div>
                </div>
            </TableCell>
            <TableCell
                className={line.ticked && line.checked ? [classes.tableBodyStyle2, classes.yellowFlagged].join(" ") : classes.tableBodyStyle2}
                style={{width: "70px", textAlign: "center"}}>
                <Input
                    size="small"
                    disableUnderline
                    name="quantity"
                    type="number"
                    defaultValue={parseFloat(qty as string)}
                    sx={{
                        height: "10px",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontFamily: "Inter",
                        fontSize: "11px",
                        lineHeight: "15px",
                        color: "#0D0D0D",
                        position: "relative",
                        "& .Mui-disabled": {WebkitTextFillColor: "rgba(0, 0, 0, 0.7)"}
                    }}
                    onFocus = {(event: any) => {
                        if (parseFloat(qty as string) === 0) { event.target.value = "" }
                    }}
                    onBlur = {(event: any) => {
                        if (parseFloat(qty as string) === 0) { event.target.value = parseFloat(qty as string) }
                    }}
                    onChange={(event: any) => handleChangeBillDetail(event, lineIndex)}
                    disabled={disableLineField}
                    />
            </TableCell>
            <TableCell className={classes.tableBodyStyle2}
                       style={{/*borderRight: "0.3px solid rgba(224, 224, 224, 1)",*/ textAlign: "center", width: "30px"}}>
                <Autocomplete
                    selectOnFocus
                    // freeSolo
                    disableClearable
                    clearOnBlur
                    autoSelect
                    value={uom ? uom : "ea"}
                    key={lineIndex}
                    onInputChange={(event: any, newInputValue: string) => {
                        if (uomOptions.indexOf(newInputValue) !== -1){
                            handleChangeBillDetail({target: "uom", value: newInputValue}, lineIndex)
                        };
                    }}
                    options={uomOptions}
                    // isOptionEqualToValue={(option, value) => option === value || option.toLowerCase() === value.toLowerCase() }
                    isOptionEqualToValue={(option, value) => true}
                    className={line.ticked && line.checked ? [classes.select, classes.yellowFlagged].join(" ") : classes.select}
                    popupIcon={<KeyboardArrowDownIcon />}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            sx={{ "&::placeholder": { color: "green" },
                                "& .MuiOutlinedInput-root":{ width: "80px", height: "31px", marginTop: "-15px"},
                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {borderWidth: "0px",},
                                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {borderWidth: "0px"},
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": { borderWidth: "0px"},
                                "& .MuiOutlinedInput-input": {color: "#000000", borderWidth: "0px", fontSize: "11px", fontWeight: "bold", fontFamily:"Inter" },
                                "&:hover .MuiOutlinedInput-input": { borderWidth: "0px" },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": { borderWidth: "0px"},
                                "& .MuiSvgIcon-root": { paddingTop: "8px" },
                                "& .Mui-disabled": {WebkitTextFillColor: "rgba(0, 0, 0, 0.5)"}
                            }}
                        />}
                    disabled={disableLineField}
                />
            </TableCell>
            <TableCell
                className={line.ticked && line.checked ? [classes.tableBodyStyle2, classes.yellowFlagged].join(" ") : classes.tableBodyStyle2}
                style={{textAlign: "center", width: "80px"}}>
                <Input size="small"
                       disableUnderline
                       name="itemCost"
                    // startAdornment={"$"}
                    // type="number"
                       value={unitPriceInput}
                       onChange={(e)=> {
                        let inputVal = e.target.value;
                        if (!isNaN(inputVal as unknown as number)) setUnitPriceInput(inputVal);
                        }}
                       onFocus={(e)=> setUnitPriceInput('')}
                       onBlur={(e) => {
                        let itemCostInput = e.target.value.replaceAll('$','').replaceAll(',','');
                        if (itemCostInput) {
                            updateUnitPriceInputValue(itemCostInput);
                        } else {
                            updateUnitPriceInputValue(); // re set to previous value
                        }
                       }}
                       sx={{
                           height: "10px",
                           fontStyle: "normal",
                           fontWeight: "normal",
                           fontFamily: "Inter",
                           fontSize: "11px",
                           lineHeight: "15px",
                           color: "#0D0D0D",
                           position: "relative",
                           "& .Mui-disabled": {WebkitTextFillColor: "rgba(0, 0, 0, 0.7)"}
                       }}
                       disabled={disableLineField}
                    // onChange={(event: any) => handleChangeBillDetail(event, lineIndex)}
                />
            </TableCell>
            <TableCell
                className={line.ticked && line.checked ? [classes.tableBodyStyle2, classes.yellowFlagged].join(" ") : classes.tableBodyStyle2}
                style={{width: "100px", textAlign: "center",
                       color: disableLineField? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 1)"
                }}>
                {
                    isNaN(rowSub as number) ? "$0.00" : formatter.format(rowSub)
                }
            </TableCell>
        </TableRow>
    );
}

export default Lines