import React, {useState, useEffect, useRef} from "react";
import { useNavigate, useParams } from "react-router-dom";
import billAPI from "../../../Services/DoxleAPI/billAPI";
import {Bill} from "../../../Models/bill";
import Alert from '@mui/material/Alert';
import BillDetails from "../Bills/BillDetails";
import {styled} from "@mui/material/styles";
import '../../../App.css'
import {Typography} from "@mui/material";
import Loading from "../../../utilities/Lottie/Loading";
interface props {}

const PurchaseOrderResponse: React.FC<props> = ({}) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [bill, setBill] = useState<Bill|undefined>(undefined)
    const [error, setError] = useState<string>("")
    const allParams = useParams();
    const [billId, action, versionCheck, email] = allParams["*"]?.split("/") || [];
    const logRef:any = useRef()

    const BillContainer = styled("div")(({ theme }) => ({
        position: "relative",
        borderRadius: 15,
        backgroundColor: '#f5f6f7',
        width: "90%",
        margin: '20px auto 20px auto',
        padding: '50px'
    }));

    const billRequest = async () =>{
        try{
            const response = await  billAPI.billResponse({ billId, action, versionCheck, email })
            console.log('response', response?.error?.response?.data)
            if (response?.bill) setBill(response.bill)
            if (response?.error){
                if (response?.error?.response?.data) setError(response?.error?.response?.data)
                else setError(response?.error as string)
            }
        } catch (err) {
            console.error(err)
            setError(err as string)
        }
        setLoading(false)
    }

    useEffect(() => {
        billRequest()
    }, [])
    if (loading) return (<Loading/>)
    return(
        <>
            {bill && <Alert
                sx={{
                    width: '93%',
                    margin: '20px auto 20px auto',
                    borderRadius: 2
                }}
                severity={
                    bill?.approved === true
                        ?"success"
                        :bill?.approved === false
                            ? "error"
                            : "warning"
                }>
                {
                    bill?.approved === true
                        ? "The Purchase Order Has Been Successfully Approved"
                        :bill?.approved === false
                            ? "The Purchase Order Has Been Rejected"
                            : "Your response has been removed"
                }
            </Alert>}
        <BillContainer>
            { error && !bill &&
                <Typography sx={{textAlign: 'center'}}>{error}</Typography>
            }
            { bill && bill.billId &&
                <BillDetails
                    handleBillsRowClick={()=>{}}
                    setOpenHistory={()=>{}}
                    disableField={true}
                    disableLineField={true}
                    openHistory={false}
                    logRef={logRef}
                    setBillEmailSent={()=>{}}
                    showEmailDialog={false}
                    setShowEmailDialog={()=>{}}
                    bill={bill}
                    currentBillId={bill.billId}
                    setCurrentBillId={()=>{}}
                    billList={[bill]}
                    setBillList={()=>{}}
                    updateOrders={()=>{}}
                    costCodeId={""}
                    supplierList={[]}
                    priceList={[]}
                    handleAddButton={()=>{}}
                    setStatusFilter={()=>{}}
                    setIsOrderChange={()=>{}}
                    isPoReview={true}
                />
            }
        </BillContainer>
        </>
    )
}

export default PurchaseOrderResponse