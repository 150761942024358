import React from "react";
import Badge from "@mui/material/Badge";
import { Costcode } from "../../../Models/costcode";
import { Notice } from "../../../Models/notice";
import {Permit} from "../../../Models/permit";
import {Checklist} from "../../../Models/checklist";
import { Button } from "@mui/material";
import { ReactComponent as CommentPlusIcon } from "../../../Assets/Comment-Plus.svg";
import { ReactComponent as CommentTextIcon } from "../../../Assets/Comment-Text.svg";
import { ReactComponent as NoticeCommentPlusIcon } from "../../../Assets/NoticeCommentPlus.svg";
import { ReactComponent as NoticeCommentTextIcon } from "../../../Assets/NoticeCommentText.svg";

interface props {
  costCode?: Costcode;
  notice?: Notice;
  permit?: Permit;
  checklist?: Checklist;
  clickFunction: Function;
}

const AddComment: React.FC<props> = ({ costCode, notice, permit, checklist, clickFunction }) => {
  return (
    <>
      <Badge
        // style={{ transform: "scale(0.8)", cursor: "pointer", top: "-8px", left: "15px",
        //   background: "#E801DE", color: "white"
        // }}


        sx={
          notice?
          {
          "& .MuiBadge-badge" : {
            background: "#E801DE",
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "8px",
            lineHeight: "8px",
            height: "12px",
            width: "9px",
            minWidth: "9px",
            top: "-6px",
            left: "0px",
          }}
          :
          { transform: "scale(0.8)", cursor: "pointer", color: "#FF7C04", top: "-8px", left: "22px", background: "none",}
      }
        color={notice?"primary":undefined}
        badgeContent={
          costCode
            ? costCode.commentCount
              ? costCode.commentCount
              : 0
            : notice
            ? notice.comments
              ? notice.comments.length
              : 0
            : permit
              ? permit.comments
                  ? permit.comments.length
                  : 0
              : checklist
              ? checklist.comments
                  ? checklist.comments.length
                  : 0
              : 0
        }
        onClick={(event: any) => {
          clickFunction(
            costCode ? costCode : notice ? notice : permit ? permit : checklist,
            costCode ? "6" : notice ? "Comments" : "0"
          );
        }}
        // color={costCode?"error":"default"}
      >
      </Badge>
      <Button
        variant="text"
        disableRipple
        sx={{ m:0, p:0, minWidth: "5px", position: notice? "absolute" : "relative"}}
        onClick={()=> {
          clickFunction(
            costCode ? costCode : notice ? notice : permit ? permit : checklist,
            costCode ? "6" : notice ? "Comments" : "0"
          );
        }}
      >
          {
            notice?.comments
                ?
                notice.comments.length > 0
                    ? <NoticeCommentTextIcon
                        style={{transform: "scale(0.7)", color: notice?.comments ? "#7B758D": "" }}
                    />
                    :<NoticeCommentPlusIcon
                        style={{transform: "scale(0.7)", color: notice?.comments ? "rgb(232, 1, 222)": "" }}
                    />
                : (permit?.comments && permit.comments.length > 0)
                || (checklist?.comments && checklist.comments.length > 0)
                || (costCode?.commentCount && costCode.commentCount > 0)
              ? <CommentTextIcon
                  style={{transform: "scale(0.7)"}}
                />
              : <CommentPlusIcon
                    style={{transform: "scale(0.7)"}}
                />
          }
      </Button>
    </>
  );
};

export default AddComment;