import {nanoid} from "nanoid";
import {DoxCircle, DoxLine, DoxPolygon, DoxRectangle, Sheet, TakeOff} from "../../../../Models/takeOffs";
import {toFloat} from "./generalFunctions";

interface rectangleMouseDownProps{
    currentShape: DoxCircle|DoxLine|DoxRectangle|DoxPolygon|null
    setCurrentShape: Function
    currentUnit: "LM"|"M2"|"M3"|"EA"|undefined
    currentColour: string
    currentSheet: Sheet
    selectedTakeOff: TakeOff
    zHeight: number
    pos: { x: number, y: number}
    scale: number
    toString: Function
    measurements: (DoxCircle|DoxLine|DoxRectangle|DoxPolygon)[]
    setMeasurements: Function
    setUnsavedMeasurements: Function;
    deduction: 1|-1;
}

const rectangleMouseDown = ({
                             currentShape,
                             setCurrentShape,
                             currentUnit,
                             currentColour,
                             currentSheet,
                             selectedTakeOff,
                             zHeight,
                             pos,
                             scale,
                             toString,
                             measurements,
                             setMeasurements,
                             setUnsavedMeasurements,
                             deduction
                         }: rectangleMouseDownProps) => {
    if (!currentShape){
        setCurrentShape({
            borderColor: deduction < 0 ? "#000" : currentColour,
            bgColor: deduction < 0 ? "#000" : currentColour,
            bgThickness: 2,
            shape: "rectangle",
            id: nanoid(),
            value: 0.0,
            zMultiplier: zHeight && currentUnit === "M3" ? zHeight : 1.0,
            zValue: 0.0,
            sheet: currentSheet?.sheetId,
            takeOff: selectedTakeOff?.takeOffId,
            start: { x: pos.x, y: pos.y },
            end: { x: pos.x, y: pos.y }
        })
    } else {
        const currentRectangle = currentShape as DoxRectangle
        setCurrentShape(null)
        let rawValue:number = 0;
        let scaledValue : number = 0;
        if (currentUnit === "M2"){
            rawValue = (Math.abs(toFloat(currentRectangle.start.x) - pos.x) * Math.abs(toFloat(currentRectangle.start.y) - pos.y)) * deduction
            scaledValue = rawValue * Math.pow(scale, 2)
        } else if (currentUnit === "M3"){
            rawValue = (Math.abs(toFloat(currentRectangle.start.x) - pos.x) * Math.abs(toFloat(currentRectangle.start.y) - pos.y)) * deduction
            scaledValue = rawValue * zHeight * Math.pow(scale, 2)
        } else if (currentUnit === "LM") {
            rawValue = (Math.abs(toFloat(currentRectangle.start.x) - pos.x) + Math.abs(toFloat(currentRectangle.start.y) - pos.y)) * 2 * deduction
            scaledValue = rawValue * scale
        } else return

        const finishedRectangle: DoxRectangle = {
            ...currentRectangle,
            end: { x: pos.x, y: pos.y },
            value: rawValue,
            zValue: scaledValue
        } as DoxRectangle
        setMeasurements([...measurements, finishedRectangle])
        setUnsavedMeasurements(true)
    }
}

interface rectangleMouseMoveProps{
    currentShape: DoxCircle|DoxLine|DoxRectangle|DoxPolygon
    setCurrentShape: Function
    currentUnit: "LM"|"M2"|"M3"|"EA"|undefined
    pos: { x: number, y: number}
    scale: number
    deduction: 1|-1
}
const rectangleMouseMove = ({currentShape, setCurrentShape, currentUnit, pos, scale, deduction}: rectangleMouseMoveProps) => {
    const currentRectangle = currentShape as DoxRectangle
    let rawValue:number = 0;
    let scaledValue : number = 0;
    if (currentUnit === "M2"){
        rawValue = (Math.abs(toFloat(currentRectangle.start.x) - pos.x) * Math.abs(toFloat(currentRectangle.start.y) - pos.y)) * deduction
        scaledValue = rawValue * Math.pow(scale, 2)
    } else if (currentUnit === "M3"){
        rawValue = (Math.abs(toFloat(currentRectangle.start.x) - pos.x) * Math.abs(toFloat(currentRectangle.start.y) - pos.y)) * deduction
        scaledValue = rawValue * toFloat(currentShape.zMultiplier) * Math.pow(scale, 3)
    } else if (currentUnit === "LM") {
        rawValue = (Math.abs(toFloat(currentRectangle.start.x) - pos.x) + Math.abs(toFloat(currentRectangle.start.y) - pos.y)) * 2 * deduction
        scaledValue = rawValue * scale
    } else return

    setCurrentShape({
        ...currentRectangle,
        end: { x: pos.x, y: pos.y },
        value: rawValue,
        zValue: scaledValue
    } as DoxRectangle)
}

export {rectangleMouseDown, rectangleMouseMove}