import axios from "axios";
import CookieService from './cookieService';
import { offline } from "../settings";
import { baseAddress } from "../settings";
import {Costcode} from '../Models/costcode'
import {Stage, StageWithCostCode} from '../Models/stage'


const getProjectProfitLoss = async () => {
    let result: any = {}
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    if (offline) { return result}
    try {
      const projectId:string = localStorage.getItem("currentProjectId") || ""
      const companyId:string = localStorage.getItem("currentCompanyId") || ""
      const response = await axios.post(
        baseAddress+"/plugin/accounts/project_p_l/",
        { project: projectId, company: companyId },
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      )
      result = response.status === 200 ? response.data : {}
      console.log("ACTUALS", response.data)
    } catch (error) {
      console.error("ERROR FETCHING ACTUALS, RETURNING {}", error)
    }
    return result
}

const appendActuals = async (costCodes: Costcode[]) => {
  const actuals = await getProjectProfitLoss()
  if (!(actuals.costs && actuals.income)) { return costCodes}
  for (var i = 0; i < costCodes.length; i++){
    if (costCodes[i].accountTrackingId){
      if (costCodes[i].income){
        for(var a = 0; a < actuals.income.length; a++){
          if (actuals.income[a].id === costCodes[i].accountTrackingId){
            costCodes[i].actual = actuals.income[a].costs;
            break
          }
        }
      } else{
        for(var a = 0; a < actuals.costs.length; a++){
          if (actuals.costs[a].id === costCodes[i].accountTrackingId){
            costCodes[i].actual = actuals.costs[a].costs;
            break
          }
        }
      }
    }
  }
  // console.log("UPDATED COSTCODES", costCodes)
  return costCodes
}

const appendActualsStage = async (stages: StageWithCostCode[]) => {
    const actuals = await getProjectProfitLoss()
    if (!(actuals.costs && actuals.income)) {
        return stages
    }
    stages.forEach(stage => {
        stage.actual = 0;
        stage.running = 0;
        for (let i = 0; i < (stage?.costCodes).length; i++) {
            if (stage.costCodes[i].accountTrackingId) {
                if (stage.costCodes[i].income) {
                    for (let a = 0; a < actuals.income.length; a++) {
                        if (actuals.income[a].id === stage.costCodes[i].accountTrackingId) {
                            stage.costCodes[i].actual = actuals.income[a].costs;
                            break
                        }
                    }
                } else {
                    for (let a = 0; a < actuals.costs.length; a++) {
                        if (actuals.costs[a].id === stage.costCodes[i].accountTrackingId) {
                            stage.costCodes[i].actual = actuals.costs[a].costs;
                            stage.actual +=  actuals.costs[a].costs;
                            if (stage.costCodes[i].completed) stage.running += actuals.costs[a].costs
                            // else if (stage.costCodes[i].orders) stage.running += Math.max(actuals.costs[a].costs, parseFloat(stage.costCodes[i].orders), parseFloat(stage.costCodes[i].budget))
                            else stage.running += Math.max(actuals.costs[a].costs, parseFloat(stage.costCodes[i].budget));
                            break;
                        }
                    }
                }
            }
        }
    })

    // console.log("UPDATED COSTCODES", costCodes)
    return stages
}

const accountingService = {
    getProjectProfitLoss,
    appendActuals,
    appendActualsStage
}

export default accountingService