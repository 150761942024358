import React, {createContext, useContext, useEffect, useState, useMemo} from "react"
import axios from "axios";
import {baseAddress} from "../../settings";
import CookieService from "../../Services/cookieService";
import {User} from "../../Models/user"
import {DoxCircle, DoxLine, DoxPolygon, DoxRectangle, DrawingSet, Sheet, TakeOff} from "../../Models/takeOffs";
import takeOffAPI from "../../Services/DoxleAPI/takeOffAPI";
import {authContextInterface, useAuth} from "./AuthProvider";
import drawingAPI from "../../Services/DoxleAPI/drawingAPI";
import {toFloat} from "../Content/Measurements/konvaFunctions/generalFunctions";
import {Project} from "../../Models/project";
import {Costcode} from "../../Models/costcode";
import TakeOffAPI from "../../Services/DoxleAPI/takeOffAPI";

export interface takeOffContextInterface {
    projectId: string|undefined
    setProjectId: React.Dispatch<React.SetStateAction<string|undefined>>;
    costCodeId: string|undefined;
    setCostCodeId: React.Dispatch<React.SetStateAction<string|undefined>>;

    takeOffs: TakeOff[];
    setTakeOffs:React.Dispatch<React.SetStateAction<TakeOff[]>>;
    selectedTakeOff: TakeOff|null;
    setSelectedTakeOff: React.Dispatch<React.SetStateAction<TakeOff|null>>;
    editTakeOffMode: boolean;
    setEditTakeOffMode: React.Dispatch<React.SetStateAction<boolean>>;
    measurements: (DoxCircle|DoxLine|DoxRectangle|DoxPolygon)[];
    setMeasurements: React.Dispatch<React.SetStateAction<(DoxCircle|DoxLine|DoxRectangle|DoxPolygon)[]>>;
    currentShape: null|DoxCircle|DoxLine|DoxRectangle|DoxPolygon;
    setCurrentShape: React.Dispatch<React.SetStateAction<null|DoxCircle|DoxLine|DoxRectangle|DoxPolygon>>;
    unsavedMeasurements: boolean;
    setUnsavedMeasurements: React.Dispatch<React.SetStateAction<boolean>>;

    drawings: DrawingSet[];
    setDrawings: React.Dispatch<React.SetStateAction<DrawingSet[]>>;
    currentDrawings: DrawingSet|undefined;
    setCurrentDrawings: React.Dispatch<React.SetStateAction<DrawingSet|undefined>>;
    currentSheet: Sheet|undefined;
    setCurrentSheet: React.Dispatch<React.SetStateAction<Sheet|undefined>>;

    tool: "Polygon"|"Rectangle"|"Circle"|"Line"|"Selector";
    setTool: React.Dispatch<React.SetStateAction<"Polygon"|"Rectangle"|"Circle"|"Line"|"Selector">>;
    currentUnit: "LM"|"M2"|"M3"|"EA";
    setCurrentUnit: React.Dispatch<React.SetStateAction<"LM"|"M2"|"M3"|"EA">>;
    displayLabels: boolean;
    setDisplayLabels: React.Dispatch<React.SetStateAction<boolean>>;
    deduction: boolean;
    setDeduction: React.Dispatch<React.SetStateAction<boolean>>;
    currentColour: string;
    setCurrentColour: React.Dispatch<React.SetStateAction<string>>;
    zHeight:number;
    setZHeight: React.Dispatch<React.SetStateAction<number>>;

    scalingMode: boolean
    setScalingMode: React.Dispatch<React.SetStateAction<boolean>>;
    scaleLine: null|DoxLine
    setScaleLine:  React.Dispatch<React.SetStateAction<null|DoxLine>>;
    scaleLength: number
    setScaleLength: React.Dispatch<React.SetStateAction<number>>;

    saveMeasurements: () => void;
    currentValue: number;
    isLoadingImage: boolean;
    setIsLoadingImage:  React.Dispatch<React.SetStateAction<boolean>>;

    unitChangeConfirmed: boolean;
    setUnitChangeConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
    dialogTakeOff: TakeOff|null;
    setDialogTakeOff: React.Dispatch<React.SetStateAction<TakeOff|null>>;
    editUnit: "LM"|"M2"|"M3"|"EA";
    setEditUnit: React.Dispatch<React.SetStateAction<"LM"|"M2"|"M3"|"EA">>;
    editDescription: string;
    setEditDescription: React.Dispatch<React.SetStateAction<string>>;
    handleUpdateTakeOff: () => void;
    handleKeyUp: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

const TakeOffContext = createContext({});

const  TakeOffProvider = (children: any) => {
    const [projectId, setProjectId] = useState<string|undefined>(undefined)
    const [costCodeId, setCostCodeId] = useState<string|undefined>(undefined)

    const [takeOffs, setTakeOffs] = useState<Array<TakeOff>>([]);
    const [selectedTakeOff, setSelectedTakeOff] = useState<TakeOff|null>(null);
    const [editTakeOffMode, setEditTakeOffMode] = useState<boolean>(false);
    const [measurements, setMeasurements] = useState<(DoxCircle|DoxLine|DoxRectangle|DoxPolygon)[]>(selectedTakeOff?.measurements || []);
    const [currentShape, setCurrentShape] = useState<null|DoxCircle|DoxLine|DoxRectangle|DoxPolygon>(null);

    const [unsavedMeasurements, setUnsavedMeasurements] = useState<boolean>(false);

    const [drawings, setDrawings] = useState<Array<DrawingSet>>([]);
    const [currentDrawings, setCurrentDrawings] = useState<DrawingSet|undefined>(drawings?.[0]);
    const [currentSheet, setCurrentSheet] = useState<Sheet|undefined>(drawings?.[0]?.sheets?.[0]);

    const [tool, setTool] = useState<"Polygon"|"Rectangle"|"Circle"|"Line"|"Selector">("Line");
    const [currentUnit, setCurrentUnit] = useState<"LM"|"M2"|"M3"|"EA">("LM");
    const [displayLabels, setDisplayLabels] = useState<boolean>(true);
    const [deduction, setDeduction] = useState<boolean>(false);
    const [currentColour, setCurrentColour] = useState<string>("#ff0000");
    const [zHeight, setZHeight] = useState<number>(1);

    const [scalingMode, setScalingMode] = useState<boolean>(false);
    const [scaleLine, setScaleLine] = useState<null|DoxLine>(null);
    const [scaleLength, setScaleLength] = useState<number>(0.0);

    const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);

    const [unitChangeConfirmed, setUnitChangeConfirmed] = useState<boolean>(false);
    const [dialogTakeOff, setDialogTakeOff] = useState<TakeOff|null>(null);
    const [editUnit, setEditUnit] = useState<"LM"|"M2"|"M3"|"EA">(dialogTakeOff?.unit || "LM");
    const [editDescription, setEditDescription] = useState<string>(dialogTakeOff?.description || "");

    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn } = authContext;

    const fetchDrawings = async(projectId: string) => {
        setIsLoadingImage(true)
        try {
            const response = await drawingAPI.getList(projectId) as DrawingSet[];
            if (response) setDrawings([...response]);
            if (response?.[0]) setCurrentDrawings(response?.[0]);
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
        setIsLoadingImage(false)
    }

    const fetchMeasurements = async(takeOffId:string) => {
        try {
            if (!takeOffId) return
            const response = await takeOffAPI.getMeasurements(takeOffId) as TakeOff;
            setTakeOffs((prevTakeOffs:TakeOff[]) => {
                return [...prevTakeOffs.map((takeOff: TakeOff) =>{
                    if (takeOffId === takeOff.takeOffId) return response
                    else return takeOff
                })]
            })
            setSelectedTakeOff({...response})
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    const fetchTakeOffList = async(projectId:string|undefined, costCodeId:string|undefined) => {
        try {
            console.log('fetchTakeOffList', `PROJECT: ${projectId} PROJECT: ${costCodeId}`)
            const response = await takeOffAPI.getList(projectId, costCodeId);
            if (response) setTakeOffs([...response]);
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    const saveMeasurements = async () => {
        if (!selectedTakeOff) return;
        const result = await takeOffAPI.update(selectedTakeOff.takeOffId, { measurements: measurements})
        if (result) {
            if (result.errors.length > 0) console.error(result.errors)
            setTakeOffs((prevTakeOffs: TakeOff[]) => {
                return [...prevTakeOffs.map((takeOff: TakeOff) => {
                    if (takeOff.takeOffId === result.takeOff.takeOffId) return result.takeOff
                    else return takeOff
                })]
            })
            setSelectedTakeOff(result.takeOff)
            setMeasurements(result.takeOff?.measurements ? [...result.takeOff.measurements]  : []);
            setUnsavedMeasurements(false);
        }
    }

    const updateTakeOff = async (takeOffId: string, description: string|undefined, unit: "LM"|"M2"|"M3"|"EA"|undefined) => {
        try {
            console.log("updateTakeOff", `takeOffId: ${takeOffId}\ndescription: ${description}\nunit: ${unit}`)
            const totalValue = unit === undefined ? undefined : 0.0;
            const updateMeasurements = unit === undefined || (selectedTakeOff && selectedTakeOff.unit === unit) ? measurements : [];
            const result = await takeOffAPI.update(takeOffId, {description, totalValue, unit, measurements: updateMeasurements});
            if (result) {
                let updatedTakeOffs: TakeOff[] = [];
                takeOffs.forEach((takeOff) => {
                    if (takeOff.takeOffId === takeOffId) updatedTakeOffs.push(result.takeOff);
                    else updatedTakeOffs.push(takeOff);
                })
                setUnsavedMeasurements(false)
                setTakeOffs([...updatedTakeOffs]);
                setSelectedTakeOff(result.takeOff);
                if (result.takeOff.measurements) setMeasurements(result.takeOff.measurements);
                setCurrentUnit(result.takeOff.unit)
            }
            setEditTakeOffMode(false)
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
    }

    const handleUpdateTakeOff = async () => {
        console.log("handleUpdateTakeOff", dialogTakeOff)
        if (!dialogTakeOff) return;
        if (dialogTakeOff.takeOffId === "new") {
            if (!projectId) return;
            try{
                const result = await TakeOffAPI.add({
                    takeOffId: "new",
                    index: takeOffs.length,
                    description: editDescription ? editDescription : "New Measurement",
                    totalValue: 0,
                    unit: editUnit,
                    measurements: measurements,
                    project: projectId,
                    costCode: dialogTakeOff.costCode,
                })
                console.log('result', result)
                if (result) {
                    console.log('setting Take Offs', [...takeOffs.filter((to: TakeOff) => to.takeOffId !== "new"), result]);
                    setEditTakeOffMode(false);
                    setTakeOffs([...takeOffs.filter((to: TakeOff) => to.takeOffId !== "new"), result]);
                    setUnsavedMeasurements(false)
                    setSelectedTakeOff(result);
                    setDialogTakeOff(result);
                    setCurrentUnit(result.unit);
                }
            } catch (err) {
                err === "AccessTokenNotFound"
                    ? setLoggedIn(false)
                    : console.error(err);
            }
        } else {
            const description = editDescription && editDescription !== dialogTakeOff.description ? editDescription : undefined
            if (unitChangeConfirmed || (dialogTakeOff?.measurements && dialogTakeOff.measurements.length === 0 )) updateTakeOff(dialogTakeOff.takeOffId, description, editUnit)
            else updateTakeOff(dialogTakeOff.takeOffId, description, undefined)
        }
    }

    const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode !== 27) return
        if (currentShape && currentShape.shape !== "polygon") setCurrentShape(null)
        else {
            let points = currentShape?.points || []
            if (points.length > 2) {
                points.splice(points.length - 2, 1);
                setCurrentShape((existingShape) => {
                    let newShape = existingShape as DoxPolygon
                    return {...newShape, points: points}
                });
            } else {
                setCurrentShape(null)
            }
        }
    }


    const currentValue: number = useMemo(() => {
        let result: number  = 0;
        measurements.forEach((shape) => {
            if (!isNaN(toFloat(shape.zValue))) result += toFloat(shape.zValue)
        });
        if (currentShape && !isNaN(toFloat(currentShape.zValue))) result += toFloat(currentShape.zValue)
        if (currentShape && currentSheet) {
            if (currentSheet.sheetId !== currentShape.sheet) {
                drawings.forEach((drawing: DrawingSet) => {
                    drawing.sheets.forEach((sheet:Sheet) => {
                        if (sheet.sheetId === currentShape.sheet) setCurrentSheet(sheet)
                    })
                })
            }
        } else if (currentSheet &&  measurements.length > 0) {
            if (currentSheet.sheetId !== measurements?.[measurements.length - 1]?.sheet) {
                drawings.forEach((drawing: DrawingSet) => {
                    drawing.sheets.forEach((sheet:Sheet) => {
                        if (sheet.sheetId === measurements[measurements.length - 1]?.sheet) setCurrentSheet(sheet)
                    })
                })
            }
        }
        return result;
    }, [measurements, currentShape])

    useEffect(() => {
        if (!selectedTakeOff) return;
        if (selectedTakeOff.measurements === undefined) {
            fetchMeasurements(selectedTakeOff.takeOffId);
        } else setMeasurements(selectedTakeOff.measurements);
        if (selectedTakeOff.unit === "EA") setTool("Selector")
        else if (tool === "Selector" || (tool === "Line" && currentUnit === "M3")) setTool("Polygon")
    }, [selectedTakeOff])

    useEffect(() => {
        if(currentDrawings) return;
        setCurrentDrawings(drawings?.[0])
    }, [drawings])

    useEffect(() => {
        setCurrentSheet(currentDrawings?.sheets?.[0])
    }, [currentDrawings])

    useEffect(() => {
        if (!projectId) return;
        setSelectedTakeOff(null)
        // setIsLoadingImage(true)
        fetchDrawings(projectId);
        fetchTakeOffList(projectId, costCodeId)
    }, [projectId, costCodeId])

    useEffect(() => {
        if (!scalingMode) return;
        setEditTakeOffMode(false);
        setSelectedTakeOff(null)
    }, [scalingMode])


    const takeOffContextValue: takeOffContextInterface = {
        projectId, setProjectId,
        costCodeId, setCostCodeId,
        takeOffs, setTakeOffs,
        selectedTakeOff, setSelectedTakeOff,
        editTakeOffMode, setEditTakeOffMode,
        measurements, setMeasurements,
        currentShape, setCurrentShape,
        unsavedMeasurements, setUnsavedMeasurements,
        drawings, setDrawings,
        currentDrawings, setCurrentDrawings,
        currentSheet, setCurrentSheet,
        tool, setTool,
        currentUnit, setCurrentUnit,
        displayLabels, setDisplayLabels,
        deduction, setDeduction,
        currentColour, setCurrentColour,
        zHeight, setZHeight,
        scalingMode, setScalingMode,
        scaleLine, setScaleLine,
        scaleLength, setScaleLength,
        currentValue, saveMeasurements,
        isLoadingImage, setIsLoadingImage,
        dialogTakeOff, setDialogTakeOff,
        editUnit, setEditUnit,
        editDescription, setEditDescription,
        unitChangeConfirmed, setUnitChangeConfirmed,
        handleUpdateTakeOff, handleKeyUp
    };



    return(
        <TakeOffContext.Provider value={takeOffContextValue} {...children} />
    )
}

const useTakeOff = () => useContext(TakeOffContext);

export { TakeOffProvider, useTakeOff }