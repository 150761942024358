import React, {useEffect, useRef, useState} from "react";
import useStyles from "./BillsStyle";
import {
    Alert,
    IconButton,
    TableCell,
    TableRow,
    Collapse, Typography
} from "@mui/material";
import moment from "moment";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {LocalizationProvider, MobileDatePicker} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {ReactComponent as TrashIcon} from "../../../Assets/Trash.svg";
import {ReactComponent as FlagIcon} from "../../../Assets/Flag-2.svg";
import {ReactComponent as LogIcon} from "../../../Assets/log.svg";
import {Bill, BillHistoryLine, BillLine, BillStatus} from "../../../Models/bill";
import Lines from './BillLine';
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import CustomCheckbox from "../../../utilities/CheckBox/CheckBox";
import {AddressBookCompany, QuickAddressBookCompany} from "../../../Models/addressBook";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import { ReactComponent as EmailIcon} from "../../../Assets/Email.svg";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ReactComponent as EmailSent} from "../../../Assets/EmailSent.svg";
import Emails from "../Emails/Emails";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {ReactComponent as IconLogs} from "../../../Assets/Logs.svg";
import BillUploadDialog from "./UploadDialog";
import billAPI from "../../../Services/DoxleAPI/billAPI";
import BillDetails from "./BillDetails";
import AlertDialog from '../../../utilities/Dialog/AlertDialog';
import { Pricelist } from "../../../Models/pricelist";

interface responseType {
    bill: Bill;
    orders: number;
}



const formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
})

interface props{
    bill: Bill;
    currentBillId: string;
    setCurrentBillId: Function;
    billList: Bill[]
    setBillList: Function;
    tickedBills: string[];
    setTickedBills: Function;
    updateOrders: Function;
    costCodeId: string;
    supplierList: AddressBookCompany[];
    priceList: Pricelist[]
    handleAddButton: Function;
    setStatusFilter: Function;
    setLoading: Function;
    isOrderChange: boolean;
    setIsOrderChange: Function;
}



const BillRow = ({
                     bill,
                     currentBillId,
                     setCurrentBillId,
                     billList,
                     setBillList,
                     tickedBills,
                     setTickedBills,
                     updateOrders,
                     costCodeId,
                     supplierList,
                     priceList,
                     handleAddButton,
                     setStatusFilter,
                     isOrderChange,
                     setIsOrderChange,
                 }: props) => {
    const [openHistory, setOpenHistory] = useState<boolean>(false);
    const [billEmailSent, setBillEmailSent] = useState<boolean>(bill.emailSent);
    // const [billStatus, setbillStatus] = useState<string>(bill.status);
    // const [billLines, setbillLines] = useState<BillLine[]>(bill.lines || []);
    // const [noOfLines, setNoOfLines] = useState<number>(Math.max(bill.lines ? bill.lines.length + 1 : 4, 4));
    // const [errors, setErrors] = useState<object[]>([]);

    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn } = authContext;
    const disableField: boolean = (bill.status === "I" || bill.status === "X" || bill.status === "V");
    const disableLineField: boolean = (bill.status === "I" || bill.status === "X" || bill.status === "V");
    // const [disableField, setDisableField] = useState<boolean>(false)
    // const [disableLineField, setDisableLineField] = useState<boolean>(false)
    const [showEmailDialog, setShowEmailDialog] = useState<boolean>(false)
    
    // const [overrideLines, setOverrideLines] = useState<boolean>(false)
    const classes = useStyles();
    const unmounted = useMountStatus();
    
    const [ alertDialogOpen, setAlertDialogOpen] = useState<boolean>(false);
    const [ nextBill, setNextBill ] = useState<string>("");


    const handleCheckBoxClick = (e: any, billId: string) =>{
        if (e.target && typeof e.target.checked !== "undefined"){
            let bills = tickedBills
            const i = bills.indexOf(billId)
            if (e.target.checked) {
                if (i === -1){ bills.push(billId) }
            } else {
                if (i !== -1){ bills.splice(i, 1); }
            }
            setTickedBills([...bills])
        }
    }

    // const setFieldsBasesOnStatus = () => {
    //     if (bill.status === "I" || bill.status === "X" || bill.status === "V") {
    //         setDisableField(true);
    //         setDisableLineField(true);
    //     }else{
    //         setDisableField(false);
    //         setDisableLineField(false);
    //     }
    //     // if (bill.status === "A" || bill.status === "I" || bill.status === "X" || bill.status === "V")
    //     //     setDisableLineField(true)
    //     // else
    //     //     setDisableLineField(false);
    // }

    const getSignOffInitials = () => {
        if (bill.signedOff) {
            return `${bill.signedOff.firstName.substring(0,1).toUpperCase()}${bill.signedOff.lastName.substring(0,1).toUpperCase()}`
        }
        return ""
    }

    // useEffect(() => {
    //     setFieldsBasesOnStatus()
    // }, [bill.status])
    //
    // useEffect(() => {
    //     setFieldsBasesOnStatus()
    // }, [])


    // useEffect(() => {
    //     console.log("startDate", startDate)
    // }, [startDate])
    // useEffect(() => {
    //     console.log("endDate", endDate)
    // }, [endDate])


    const handleDiscard = () => {

        if(currentBillId === nextBill){
            setCurrentBillId("");
        } else {
            setCurrentBillId(nextBill);
        }

        setAlertDialogOpen(false);
        setIsOrderChange(false);
    }
    
    const handleBillsRowClick = (clickBill: Bill) => {
        if (clickBill.billId) handleCheckOrderChanges(clickBill.billId);
    }

    const handleCheckOrderChanges = (billId: string) => {
        // console.log('handleCheckOrderChanges')
        // console.log('isOrderChange', isOrderChange)
        if (isOrderChange) {
            // show alert
            setAlertDialogOpen(true);
            setNextBill(billId);
        } else {
            if(currentBillId === billId){
                setCurrentBillId("");
            } else {
                setCurrentBillId(billId);
            }
        }
      }
    
      const handleCloseAlert = () => {
        setAlertDialogOpen(false)
      }

    // useEffect(() => {
    //     if (currentBillId !== "" && openHistory) {
    //         executeScroll()
    //     }
    // }, [currentBillId, openHistory])
    

    const logRef = useRef<null | HTMLDivElement>(null); 

    const executeScroll = () => {
        logRef!.current!.scrollIntoView()
    }
 

    return (
        <React.Fragment key={"Fragment-" + bill.billId}>
            <TableRow key={bill.billId+"-Row1"}
                sx={{
                    "& > *": { borderBottom: "unset" },
                    // display: currentBillId === ""? "":"none",
                    // borderBottom: "1px solid #eff1f5",
                    height: "47px",
                    minHeight: "47px",
                    cursor: "pointer",
                    backgroundColor: bill.signedOff ? "#e6ffe6" : "#F5F6F7"
                }}
            >
                <TableCell sx={{width: "10px", cursor: 'default'}}>
                    <CustomCheckbox
                        checked={(tickedBills.indexOf(bill?.billId || "") >= 0)}
                        onClick={(event: any) => { handleCheckBoxClick(event, bill.billId || "") }}
                        style = {{ transform: "scale(0.8)", width: "100%", height: "100%", cursor: "pointer"}}
                    />
                </TableCell>
                <TableCell onClick={(event: any) => (handleBillsRowClick(bill))}>{bill.number}</TableCell>
                <TableCell onClick={(event: any) => (handleBillsRowClick(bill))}>{moment(bill.issueDate).format("DD.MM.YY")}</TableCell>
                <TableCell onClick={(event: any) => (handleBillsRowClick(bill))}>{bill.supplier}</TableCell>
                <TableCell
                    style={{ paddingLeft: "1px", paddingRight: "1px", wordBreak: "break-all" }}
                    onClick={(event: any) => handleBillsRowClick(bill)}
                >
                    {bill.reference}
                </TableCell>
                <TableCell onClick={(event: any) => (handleBillsRowClick(bill))}>{formatter.format(bill.subTotal as number)}</TableCell>
                <TableCell className="paid" onClick={(event: any) => (handleBillsRowClick(bill))}>{bill.paid}</TableCell>
                <TableCell className="balance" onClick={(event: any) => (handleBillsRowClick(bill))}>{bill.balance}</TableCell>
                {/* <TableCell className={classes.tableBodyStyle}>
                    <IconButton 
                        size="small"
                        onClick={() => {
                            handleBillsRowClick(bill)
                            setOpenHistory(true)
                        }}
                    >
                        <LogIcon />
                    </IconButton>
                </TableCell> */}
                <TableCell className={classes.signOffStyle} onClick={(event: any) => (handleBillsRowClick(bill))}>
                    <Typography sx={{color: "red", fontFamily: "DearJoe4"}}>
                        {getSignOffInitials()}
                    </Typography>
                </TableCell>
                <TableCell className={classes.tableBodyStyle}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleBillsRowClick(bill)}
                        sx={{
                            color: "#000000",
                            textAlign: "right",
                        }}
                        disableFocusRipple
                        disableRipple
                    >
                        <EmailIcon
                            onClick={(e: any) => {setShowEmailDialog(true)}}
                            style={{transform: "scale(0.8)"}}
                        />
                        <CheckCircleIcon
                            onClick={(e: any) => {setShowEmailDialog(true)}}
                            style={{
                                position: "relative",
                                top: "8px",
                                left: "-14px",
                                transform: "scale(0.6)",
                                color: billEmailSent ? "limegreen" : "rgba(0,0,0,0)",

                            }}
                        />

                        &nbsp;
                        {currentBillId === bill.billId ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
            </TableRow>

            <TableRow key={bill.billId + "-Row2"} sx={{ backgroundColor: "#F5F6F7"}}>
            
                <TableCell
                    style={{
                        padding: 0,
                        margin: 0,
                        background: "#F5F6F7",
                        borderRadius: "4px",
                        position: "relative"
                    }}
                    colSpan={10}
                >
                    <Collapse
                        in={currentBillId === bill.billId}
                        timeout="auto"
                        unmountOnExit
                        style={{ width: "calc(100% - 100px)", overflow: "hidden", padding: "40px 60px 40px 40px"}}
                        // style={{ width: "calc(100% - 100px)", overflow: "hidden", padding: "40px 60px 40px 40px", backgroundColor: "#f9fafa"}}
                    >


                        {/* Bill detail table*/}

                        <BillDetails 
                            bill={bill}
                            currentBillId={currentBillId}
                            setCurrentBillId={setCurrentBillId}
                            supplierList={supplierList}
                            priceList={priceList}
                            updateOrders={updateOrders}
                            costCodeId={costCodeId}
                            billList={billList}
                            setBillList={setBillList}
                            handleBillsRowClick={handleBillsRowClick}
                            setOpenHistory={setOpenHistory}
                            handleAddButton={handleAddButton}
                            setStatusFilter={setStatusFilter}
                            disableField={disableField}
                            disableLineField={disableLineField}
                            openHistory={openHistory}
                            logRef={logRef}
                            setIsOrderChange={setIsOrderChange}
                            setBillEmailSent={setBillEmailSent}
                            showEmailDialog={showEmailDialog}
                            setShowEmailDialog={setShowEmailDialog}
                        />
                    </Collapse>
                    <AlertDialog open={alertDialogOpen} 
                        handleClose={handleCloseAlert} 
                        handleDiscard={handleDiscard}
                    />
                </TableCell>
            </TableRow>
            
        </React.Fragment>
    );
};

export default BillRow