export const titleCaseMultilineHTML = (input: string) => {
    let inputArray = input.split("\n");
    let output = [];

    for (let i in inputArray) {
        let inputLine = titleCase(inputArray[i]) as string;
        inputLine = inputLine + '<br/>'
        output.push(
            <div key={i} dangerouslySetInnerHTML={{__html: inputLine}} />
        );
    }
    return output;
};

export const titleCaseMultiline = (input: string) => {
    let inputArray = input.split("\n");
    let output = [];

    for (let i in inputArray) {
        output.push(
            <div key={i}>
                {titleCase(inputArray[i])}
                {"\n"}
            </div>
        );
    }

    return output;
};

export const titleCase = (str: string) => {
    try {
        if (str !== undefined && str.length > 0) {
            var splitStr = str.toLowerCase().split(" ");
            for (var i = 0; i < splitStr.length; i++) {
                if (splitStr[i].charAt(0) === "@") {
                    splitStr[i] =
                        "@" +
                        splitStr[i].charAt(1).toUpperCase() +
                        splitStr[i].substring(2);
                } else {
                    splitStr[i] =
                        splitStr[i].charAt(0).toUpperCase() +
                        splitStr[i].substring(1);
                }
            }
            return splitStr.join(" ");
        }
    } catch (err) {
        console.error("TITLE CASE EXCEPTION: " + err);
    }
};

export default titleCase;
