import React, { useEffect } from "react";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ICONS } from "../../../Assets/index.js";
interface props {
  toolColor: string,
  setToolColor: Function
}

const ColourMenu: React.FC<props> = ({
  toolColor,
  setToolColor
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const setColor = (color: string) => {
    setToolColor(color)
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{minWidth:"100%"}}
      >
        <img src={ICONS.Brush} className={toolColor!=="yellow"?"invert":""} alt="colour" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem sx={{background: "rgba(119,68,255,0.8)", "&:hover" : {background: "#ded2ff"}}}
          onClick={()=>setColor("#7744ff")}><img src={ICONS.Brush} className="invert" alt="purple" /></MenuItem>
        <MenuItem sx={{background: "rgba(0,0,255,0.8)", "&:hover" : {background: "rgba(82,82,255,0.4)"}}}
          onClick={()=>setColor("#0000FF")}><img src={ICONS.Brush} className="invert" alt="blue" /></MenuItem>
        <MenuItem sx={{background: "rgba(255,0,0,0.8)", "&:hover" : {background: "rgba(255,64,64,0.4)"}}}
          onClick={()=>setColor("#FF0000")}><img src={ICONS.Brush} className="invert" alt="red" /></MenuItem>
        <MenuItem sx={{background: "rgba(0,255,0,0.8)", "&:hover" : {background: "#00FF0066"}}}
          onClick={()=>setColor("#00FF00")}><img src={ICONS.Brush} className="invert" alt="green" /></MenuItem>
        <MenuItem sx={{background: "rgba(255,255,0,0.8)", "&:hover" : {background: "#FFFF0066"}}}
          onClick={()=>setColor("#FFFF00")}><img src={ICONS.Brush} className="" alt="yellow" /></MenuItem>
      </Menu>
    </div>
  );
};

export default ColourMenu;
