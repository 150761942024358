import { truncate } from "fs/promises";

export const offline: boolean = false;
const local: boolean = false;
export const baseAddress: string = local
  ? "http://127.0.0.1:8000/api"
  : "https://app.doxle.com/api";
export const socketAddress: string = local
  ? "ws://127.0.0.1:9000/ws"
  : "wss://app.doxle.com/ws";
export const docketsBaseAddress: string = local
  ? "http://127.0.0.1:5000"
  : "http://192.168.1.113:5000";
