import React, { useState, useEffect, useRef } from "react";
import useStyles from "./BillsStyle";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography, List, ListItem, ListItemButton, ListItemText, 
  TextField, InputAdornment,
  Stack, Box, Chip} from "@mui/material";import { Bill, BillStatus } from "../../../Models/bill";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import BillRow from './BillRow'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutlined';
import {AddressBookCompany, QuickAddressBookCompany} from "../../../Models/addressBook";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import BillAPI from "../../../Services/DoxleAPI/billAPI";
import PricelistAPI, {PricelistResponse} from "../../../Services/DoxleAPI/pricelistAPI";
import AddressBookAPI from "../../../Services/DoxleAPI/addressBookAPI";
import { Pricelist } from "../../../Models/pricelist";
import { Costcode } from '../../../Models/costcode';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import {ReactComponent as AddIcon} from "../../../Assets/Plus - Circle - White.svg";
import { costcodeData } from "../../../utilities/costcodeData";
import CookieService from "../../../Services/cookieService";
import AlertDialog from '../../../utilities/Dialog/AlertDialog';
import CustomCheckbox from "../../../utilities/CheckBox/CheckBox";
import {useSocket} from "../../../Services/Sockets/useSocket";

interface IconStlye {
  color: string;
  cursor: string;
  bg: string;
}
interface responseType {
  bill: Bill;
  orders: number;
}
interface props {
  costCodeId: string;
  costCodeTitle: string;
  costCodeStatus: string;
  billList: Bill[];
  setBillList: React.Dispatch<any>;
  updateOrders: Function;
  budget: string;
  updateBudget: Function;
  setBudget: Function;
  setLoading: Function;
  isOrderChange: boolean;
  setIsOrderChange: Function;
}

const Bills: React.FC<props> = ({
  costCodeId, costCodeTitle, costCodeStatus, billList, setBillList, updateOrders, budget,
  updateBudget, setBudget, setLoading, isOrderChange, setIsOrderChange,
}) => {
  const classes = useStyles();
  // const [currentRow, setCurrentRow] = useState<string>("");
  const [currentBillId, setCurrentBillId] = useState<string>("");
  const [deleteStyle, setDeleteStyle] = useState<IconStlye>({color: "#eff1f5", bg: "#eff1f5", cursor:"default"})
  const [tickedBills, setTickedBills] = useState<string[]>([]);
  const [supplierList, setSupplierList] = useState<AddressBookCompany[]>([]);
  const unmounted = useMountStatus();
  const tableRef = useRef(null);
  const {height, width} = WindowDimensions();
  const [statusFilter, setStatusFilter] = useState<string>("D");
  interface IStatus { D: number, R: number, A: number, I: number, X: number, V: number};
  const [billCount, setbillCount] = useState<IStatus>({ D: 0, R: 0, A: 0, I: 0, X: 0, V: 0});
  const [editBudgetMode, setEditBudgetMode] = useState<boolean>(false);
  const [budgetInput, setBudgetInput] = useState<string>("");
  const [budgetError, setBudgetError] = useState<boolean>(false);
  const [displayLockError, setDisplayLockError] = useState<boolean>(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState<boolean>(false);
  const [ nextStatusTab, setNextStatusTab ] = useState<string>("1");

  const [fetchPriceListPage, setFetchPriceListPage] = useState<number|null>(1);
  const [costCodePricelist, setCostCodePricelist] = useState<Array<Pricelist>>([]);

  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, user } = authContext;

  const scrollToBottom = () => {
    tableRef && tableRef.current && (tableRef as any).current.scrollIntoView && (tableRef as any).current.scrollIntoView({ behavior: "smooth" });
  };

  const theme = createTheme({
    palette: {
      secondary: {
        main: '#7B758D',
        contrastText: '#7B758D',
      },
      success: {
        main: '#27BF36',
        contrastText: '#27BF36',
      },
    },
  });


  const getDetailedBillList = async () => {
    try{
      const response = await BillAPI.getList({costCodeId: costCodeId}) as Bill[];
      setBillList([...response]);
      setCurrentBillId('');
    }catch (err){
      if (err === "AccessTokenNotFound") {
        setLoggedIn(false)
        await new Promise(resolve => setTimeout(resolve, 1000));
        if (CookieService.get('access_token')) {
          getDetailedBillList();
        }
      } else console.log(err);
    }
  }

  const getSupplierList = async () => {
    try {
      const response = await AddressBookAPI.getList(undefined) as AddressBookCompany[]
      setSupplierList([...response])
    }catch (err){
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  }

  const getCostcodePricelist = async (page: number) => {
    try {
      const response = await PricelistAPI.getList(page) as PricelistResponse;
      if (response.items.length > 0) {
        let newCostcodePricelist:Pricelist[] = []
        response.items.forEach((item:Pricelist) => {
          if (item.costCode === costCodeTitle) newCostcodePricelist.push(item)
        })
        console.log(`downloaded ${newCostcodePricelist.length} price tag for ${costCodeTitle}`)
        setCostCodePricelist((currentList:Pricelist[]) =>[...currentList, ...newCostcodePricelist]);
      }
      setFetchPriceListPage(response.nextPage);
    } catch (error) {
      error === "AccessTokenNotFound" ? setLoggedIn(false) : console.error();
    }
  };

  const getCcNumber = () => {
    let ccNumber = costCodeTitle.substring(0, costCodeTitle.indexOf(" "))
    if (isNaN(parseFloat(ccNumber))) {return ""}
    return ccNumber
  }

  const leadingZeros = (num: number, places: number) => {
    const numZeroes = places - num.toString().length + 1;
    if (numZeroes > 0) {
        return Array(+numZeroes).join("0") + num;
    }
    return num
  }

  const getNewBillNumber = (index: number) => {
    const prefix: string =  sessionStorage.getItem("projectPrefix") || "";
    const costCodeNumber: string =  getCcNumber()+"."
    const number = leadingZeros(index + 1, 3)
    return prefix+costCodeNumber+number
  }

  let dummyBill: Bill = {
    number: '',
    issueDate: new Date().toString(),
    emailSent: false,
    supplier: '',
    accepted: false,
    specialConditions: "",
    status: "D",
    billId: 'NEWBILLTEMPLATE',
    costCode: '',
    subTotal: 0,
    tax: 0,
    total: 0,
    internalNotes: "",
    abSupplier: '',
    lines: [],
    history: [],
    startDate: null,
    endDate: null,
    paymentTerms: '30',
    paymentTermsSuffix: "Days"
  };

  const handleAddBill = () => {
    setStatusFilter("D");
    let newBill = dummyBill
    if (costCodeId){
        newBill.number = getNewBillNumber(billList.length)
        newBill.costCode = costCodeId
    }
    let data = [...billList, newBill];
    setBillList(data);  
  }


  const handleDeleteBills = async () => {
    try {
        let successful: string[] = [];
        for (let b = 0; b < tickedBills.length; b++) {
          const response = await BillAPI.remove(tickedBills[b])
          if (response.success) {
            successful.push(tickedBills[b])
            if (b === tickedBills.length - 1) {
              updateOrders(costCodeId, response.orders.toString())
            }
          }
        }
        let bills: Bill[] = []
        billList.forEach(bill => {
          if (successful.indexOf(bill.billId || "") === -1) {
            bills.push(bill)
          }
          setBillList([...bills]);
          setTickedBills([]);
          setCurrentBillId('');
        })
    }catch (err){
        if (err === "AccessTokenNotFound") {
          setLoggedIn(false)
          await new Promise(resolve => setTimeout(resolve, 1000));
          if (CookieService.get('access_token')) {
            handleDeleteBills();
          }
        } else console.log(err);
    }
  }
  const socket = useSocket();
  const handleSubmitBudget = () => {
    try {
        const trimmedBudget = parseFloat(budgetInput.replaceAll('$', '').replaceAll(',', ''));
        if (isNaN(trimmedBudget)) { setBudgetError(true);  return; }
        setBudgetError(false);
        // CostCodeAPI.update(costCodeId, {budget: trimmedBudget.toString()});
        socket.send(
            JSON.stringify({
              messageType: "CostCodeUpdate",
              message: {
                costCodeId: costCodeId,
                budget: trimmedBudget,
              },
            })
        );

      setBudget(trimmedBudget.toString());
        setEditBudgetMode(false);
        updateBudget(costCodeId, trimmedBudget.toString())
    }catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  }
  

  const handleUpdateStatus = async (forward: boolean) => {

    setLoading(true)

    const statuses = ['D', 'R', 'A', 'I']
    const indexStatus = statuses.findIndex((statusCode) => statusCode === statusFilter)
    const nextStatus = forward ? statuses[indexStatus + 1] : statuses[indexStatus - 1]

    let updatedBills: Bill[] = []
    for (const bill of billList) {
      let updateBill = bill;
      for (const tickBillId of tickedBills) {
        if (tickBillId === bill.billId) {

          try {
            if (bill.billId) {
              const response = await BillAPI.update(bill.billId, bill.history ? bill.history.length : 0, {status: nextStatus}) as responseType;
              updateOrders(response.bill.costCode, response.orders.toString())
            }
          }catch (err){
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
          }

          updateBill = { ...updateBill, status : nextStatus }

        }
      }
      updatedBills.push(updateBill)
    }
    
    setLoading(false)
    setBillList([...updatedBills]);
    setTickedBills([])
    setStatusFilter(nextStatus);
    setCurrentBillId('');

  }

  const formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  const handleSignOff = async () => {
    try {
      let orders: number
      const promises = billList.map(async (bill: Bill) => {
        if (!bill.billId) return bill;
        if (tickedBills.indexOf(bill.billId) < 0) return bill;
        let response
        if (bill?.signedOff && bill?.signedOff.userId === user?.userId) response = await BillAPI.update(bill?.billId, bill.history ? bill.history.length : 0, {signedOff: null})
        else response = await BillAPI.update(bill?.billId, bill.history ? bill.history.length : 0, {signedOff: user?.userId || null})
        if (response) {
          orders = response.orders
          return response.bill
        }
        return bill
      })
      const updatedBills = await Promise.all(promises)
      console.log(updatedBills)
      setBillList([...updatedBills])
      setTickedBills([])
    }catch (err){
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  }

  const handleCheckBoxClick = (event:any) => {
    console.log(event)
    if (tickedBills.length > 0) setTickedBills([])
    else {
      let billIds: string[] = []
      billList.forEach((bill:Bill) => {
        if (bill.billId && bill.status === statusFilter) billIds.push(bill.billId)
      })
      console.log(billIds)
      setTickedBills([...billIds])
    }
  }

  const handleDiscard = () => {
    setStatusFilter(nextStatusTab);
    setTickedBills([]);
    setCurrentBillId('');
    setAlertDialogOpen(false);
    setIsOrderChange(false);
}

  const getSignOffText = () => {
    let unsign: boolean = false
    let sign: boolean = false
    billList.forEach((bill: Bill) =>{
      if (tickedBills.indexOf(bill?.billId || "") !== -1) {
        if (bill?.signedOff?.userId === user?.userId) unsign = true
        else sign = true
      }
    })
    if (unsign && !sign) return "UNSIGN BILLS"
    if (!unsign && sign) return "SIGN OFF BILLS"
    return ""
  }

  const handleCheckOrderChanges = (code:string) => {
    // console.log('handleCheckOrderChanges')
    // console.log('isOrderChange', isOrderChange)
    if (isOrderChange) {
        // show alert
        setAlertDialogOpen(true);
        setNextStatusTab(code);
    } else {
        setStatusFilter(code);
        setTickedBills([]);
        setCurrentBillId('');
    }
  }

  const handleCloseAlert = () => {
    setAlertDialogOpen(false)
  }

  useEffect(() => {
    //scrollToBottom()
    getDetailedBillList()
    getSupplierList()
  }, []);

  useEffect(() => {
    if (tickedBills.length > 0){
      setDeleteStyle({color: "white", bg: "#D6303A", cursor:"pointer"})
    } else {
      setDeleteStyle({color: "#eff1f5", bg: "#eff1f5", cursor:"default"})
    }
  }, [tickedBills])

  useEffect(() => {
    const countStatuses = { D: 0, R: 0, A: 0, I: 0, X: 0, V: 0} 
    billList.forEach(bill => {
      if (bill.status === "D") countStatuses.D++;
      if (bill.status === "R") countStatuses.R++;
      if (bill.status === "A") countStatuses.A++;
      if (bill.status === "I") countStatuses.I++;
      if (bill.status === "X") countStatuses.X++;
      if (bill.status === "V") countStatuses.V++;

      if (bill.billId === 'NEWBILLTEMPLATE') setCurrentBillId('NEWBILLTEMPLATE');
    })
    setbillCount(countStatuses)
    

  }, [billList])

  // delete template
  useEffect(() => {
    if (!currentBillId || currentBillId !== 'NEWBILLTEMPLATE') {
      setBillList((bills:Bill[]) => bills.filter((bill:Bill)=> bill.billId !== 'NEWBILLTEMPLATE'))
    }
  }, [currentBillId])

  useEffect(() => {
    if (fetchPriceListPage) getCostcodePricelist(fetchPriceListPage);
  }, [fetchPriceListPage]);


  return (
      <div style={{
        overflowX: width < 928 ? "auto" : "unset",
        paddingTop: "0px",
      }}>

        <>
          <nav style={{
            marginTop: "30px",
            display: 'flex',
            justifyContent: "space-between"
          }}>
            <List sx={{
              display: 'flex',
              flexDirection: 'row',
              padding: 0,
              width: "fit-content", 
              overflow: "hidden",
              background: "none",
              ".MuiListItem-root" : {
                backgroundColor: "#F5F6F7",
                border: "1px solid white",
                height: "37px",
                paddingTop: 0,
                paddingBottom: 0,
                whiteSpace: "nowrap"
              },
              ".MuiListItem-root:first-of-type": {
                borderRadius: "4px 0px 0px 4px",
              },
              ".MuiListItem-root:last-child": {
                borderRadius: "0px 4px 4px 0px",
              },
              ".MuiListItemButton-root" : {
                paddingLeft: "30px",
                paddingRight: "30px",
                height: "100%"
              },
              ".MuiTypography-root" : {
                fontFamily: 'Roboto Mono',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px",  
              },
              ".MuiListItemButton-root.Mui-selected" : {
                color: '#5A36BE',
                background: "none",
                height: "34px",
                opacity: 1
              },
            }}>
              {
                ["D", "R", "A", "I", "X", "V"].map(code => 
                <ListItem key={code} disablePadding>
                  <ListItemButton 
                    onClick={() => {
                      handleCheckOrderChanges(code)
                      // setStatusFilter(code);
                      // setTickedBills([]);
                      // setCurrentBillId('');
                    }}
                    selected={statusFilter === code? true : false}
                    disabled={statusFilter === code? true : false}
                    disableRipple
                  >
                    <ListItemText sx={{textTransform: "capitalize"}}
                    primary={
                        code === "D" ? BillStatus.D.toLowerCase() + (billCount.D > 0? " (" + billCount.D + ")": ""):
                        code === "R" ? BillStatus.R.toLowerCase() + (billCount.R > 0? " (" + billCount.R + ")": ""):
                        code === "A" ? BillStatus.A.toLowerCase() + (billCount.A > 0? " (" + billCount.A + ")": ""):
                        code === "I" ? BillStatus.I.toLowerCase() + (billCount.I > 0? " (" + billCount.I + ")": ""):
                        code === "X" ? BillStatus.X.toLowerCase() + (billCount.X > 0? " (" + billCount.X + ")": ""):
                        code === "V" ? BillStatus.V.toLowerCase() + (billCount.V > 0? " (" + billCount.V + ")": ""):
                        "Drafts"
                      }
                      />
                  </ListItemButton>
                </ListItem>
                )
              }
            </List>
            <Stack sx={{
              alignItems: "center",
              fontFamily: 'Roboto Mono',
              fontStyle: 'normal',
              fontWeight: '400',
              }}>
                <div style={{margin:0, padding: 0, fontSize: "11px", lineHeight: "15px"}}>Budget</div>
                <Box sx={{m:0, p: 0, fontSize: "11px", lineHeight: "15px"}}>
                  {
                    editBudgetMode
                    ?
                    <TextField
                        error={budgetError}
                        autoFocus
                        defaultValue={budget}
                        variant="standard"
                        onChange={(e) => setBudgetInput(e.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        onKeyUp={(e:any) => {
                            if (e?.keyCode === 13) handleSubmitBudget();
                            if (e?.keyCode === 27) setEditBudgetMode(false);
                        }}
                        onBlur={(e:any) => setEditBudgetMode(false) }
                        // sx={{marginLeft: "Calc(min(25vw, 500px))", marginTop:"10px"}}
                    />
                    :
                    <>
                      <div style={{margin:0, padding: 0, fontSize: "14px", lineHeight: "18px", 
                        color: "#4C2BA7", textDecoration: "underline"}}
                        onDoubleClick={(e:any) => {
                            console.log("ondbc")
                            if (costCodeStatus !== "L") setEditBudgetMode(true)
                            else setDisplayLockError(true)
                        }}>{formatter.format(parseFloat(budget))}</div>
                      {displayLockError ?
                        <Chip
                            variant="outlined"
                            color="error"
                            label="Budget cannot be changed as the costcode is locked"
                            sx={{position: "fixed", top: "6px", right: "100px"}}
                            onClick={(e: any) => setDisplayLockError(false)}
                        /> : null
                      }
                    </>
                            
                  }
              </Box>
            </Stack>
          </nav>

          {/* <IconButton
              size="small"
              style={{ marginLeft: "15px", background: deleteStyle.bg, borderRadius: "50px",  width: "24px !important",  transform: "scale(0.9)",
                height: "24px !important", margin: "8px 3px !important", cursor: deleteStyle.cursor, color: deleteStyle.color}}
              onClick={(event:any)=> handleDeleteBills()}
          >
            <DeleteOutlineIcon style={{color: deleteStyle.color,  transform: "scale(0.9)"}} />
          </IconButton> */}

          <div style={{padding: "20px 0", display: "flex", gap: "13px", height: "32px", justifyContent: "space-between"}}>
            <div style={{display: "flex", gap: "13px"}}>
              {
              tickedBills.length > 0 && 
              (statusFilter === "D" || statusFilter === "R" || statusFilter === "A" || statusFilter === "I") &&
              <ThemeProvider theme={theme}>
                  <Button
                      variant="outlined"
                      size="small"
                      color="secondary"
                      onClick={()=> handleUpdateStatus(false)}
                      style={{ display: statusFilter === "D" ? "none" : "unset" }}
                  >
                      {statusFilter === "R"
                          ? "REVERT TO DRAFT"
                          : statusFilter === "A"
                          ? "REVERT TO REVIEW"
                          : "REVERT TO APPROVED"}
                  </Button>
                  <Button
                      variant="outlined"
                      size="small"
                      color="success"
                      onClick={()=> handleUpdateStatus(true)}
                      style={{
                        display: statusFilter === "I" ? "none" : "unset",
                      }}
                  >
                      {statusFilter === "D"
                          ? "SUMBIT TO REVIEW"
                          : statusFilter === "R"
                          ? "APPROVE"
                          : statusFilter === "A"
                          ? "ISSUE"
                          : ""}
                  </Button>
                  <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      style={{
                        display: getSignOffText() && (statusFilter === "A" || statusFilter === "I") ? "unset" : "none",
                      }}
                      onClick={handleSignOff}
                  >
                    {getSignOffText()}
                  </Button>
              </ThemeProvider>
              }
            </div>
            <div style={{display: "flex", gap: "13px", paddingLeft: 0, paddingRight: 0}}>
              { tickedBills.length > 0 && 
              <Button variant="contained" startIcon={<DeleteOutlineIcon />} size="small"
                sx={{
                  textTransform: 'none',
                  background: '#CD326A80',
                  borderRadius: '4px',
                  "&:hover": {
                      background: '#CD326A',
                  }
                }}
                onClick={(event:any)=> handleDeleteBills()}
                >
                Delete Order
              </Button>
              }
              
              <Button variant="contained" startIcon={<AddIcon />}
                sx={{
                    textTransform: 'none',
                    background: '#4C2BA780',
                    borderRadius: '4px',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '9px',
                    lineHeight: '11px',
                    height: '20px',
                    "&:hover": {
                        background: '#4C2BA7',
                    }
                }}
                onClick={handleAddBill}
                disabled={currentBillId === "NEWBILLTEMPLATE"}
                >
                Order
              </Button>   
            </div>
          </div>
        </>

        


        <TableContainer>
          <Table
              size="small"
              aria-label="details table"
              className={classes.rmvStickHeader}
              style={{
                paddingTop:"50px",
              }}
          >
            <TableHead>
              <TableRow className="billTableHeadStyle">
                <TableCell style={{width: "10px", border: "none"}}>
                  <CustomCheckbox
                      checked={(tickedBills.length > 0)}
                      onClick={handleCheckBoxClick}
                      style = {{ transform: "scale(0.8)", width: "100%", height: "100%", cursor: "pointer"}}
                  />
                </TableCell>
                <TableCell className={classes.tableHeadingStyle}>Order #</TableCell>
                <TableCell className={classes.tableHeadingStyle}>Date</TableCell>
                <TableCell className={classes.tableHeadingStyle}>Supplier</TableCell>
                <TableCell className={classes.tableHeadingStyle}>Reference</TableCell>
                <TableCell className={classes.tableHeadingStyle}>Amount</TableCell>
                <TableCell className={classes.tableHeadingStyle}>Paid</TableCell>
                <TableCell className={classes.tableHeadingStyle}>Balance</TableCell>
                <TableCell className={classes.tableHeadingStyle}>Signed Off</TableCell>
                <TableCell className={classes.tableHeadingStyle}/>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableDataStyle}>
              {billList.length > 0
                  ? billList
                  .filter(
                    (bill) => bill.status === statusFilter
                  )
                  .map((bill) => (
                      <BillRow
                          key={"BillRow" + bill.billId}
                          bill={bill}
                          currentBillId={currentBillId}
                          setCurrentBillId={setCurrentBillId}
                          billList={billList}
                          setBillList={setBillList}
                          tickedBills={tickedBills}
                          setTickedBills={setTickedBills}
                          updateOrders={updateOrders}
                          costCodeId={costCodeId}
                          supplierList={supplierList}
                          priceList={costCodePricelist}
                          handleAddButton={handleAddBill}
                          setStatusFilter={setStatusFilter}
                          setLoading={setLoading}
                          isOrderChange={isOrderChange}
                          setIsOrderChange={setIsOrderChange}
                      />
                  ))
                  : null}
            </TableBody>
          </Table>
        </TableContainer>

        <AlertDialog open={alertDialogOpen} 
          handleClose={handleCloseAlert} 
          handleDiscard={handleDiscard}
        />
      </div>
)};
export default Bills;