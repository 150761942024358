import React from 'react';
import '../HelloComponents/Hello.css';

const Hello: React.FC = () => {

    return(
        <h1>Hello</h1>
    )
}

export default Hello