import React, { useRef, useEffect, useState, useCallback } from "react";
import "./Measurement.css";
// import { Draggable } from "react-drag-reorder";
import {
    Box, Typography,
} from "@mui/material";

import {Stage, Layer, Image, Line, Rect, Circle, Text, FastLayer} from "react-konva";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import {TakeOff, DrawingSet, Sheet, DoxCircle, DoxLine, DoxRectangle, DoxPolygon, XY} from "../../../Models/takeOffs";
import ImageCustom from "./ImageCustom"
import { nanoid } from 'nanoid'
import {circleMouseDown, circleMouseMove} from './konvaFunctions/circle'
import {rectangleMouseDown, rectangleMouseMove} from "./konvaFunctions/rectangle";
import {lineMouseDown, lineMouseMove} from "./konvaFunctions/line";
import {polygonMouseDown, polygonMouseMove} from "./konvaFunctions/polygon";
import {toFloat} from "./konvaFunctions/generalFunctions";
import DoxleShape from "./DoxleShape";
import {takeOffContextInterface, useTakeOff} from "../../Providers/TakeOffProvider";
import KonvaInteractiveLabel from "./KonvaInteractiveLabel";
import {SingleMeasureContextInterface, useSingleMeasurementContext} from "./SingleMeasurements";
interface props {
    single?: true;
}
const KonvaComponent = ({single}: props) => {
    const TakeOffContext = useTakeOff() as takeOffContextInterface
    const SingleMeasureContext = useSingleMeasurementContext() as SingleMeasureContextInterface;
    const {
        setUnsavedMeasurements,
        selectedTakeOff, editTakeOffMode,
        measurements, setMeasurements,
        currentShape, setCurrentShape,
        currentSheet, setScalingMode,
        tool, displayLabels,
        currentUnit,
        currentColour,
        zHeight,
        scalingMode,
        scaleLine, setScaleLine,
        deduction
    } = single ? SingleMeasureContext : TakeOffContext;
    const [image, setImage] = useState<string>(currentSheet ? currentSheet.url : "");
    const [height, setHeight] = useState<number>(currentSheet ? parseInt(currentSheet.height) : 0);
    const [width, setWidth] = useState<number>(currentSheet ? parseInt(currentSheet.width) : 0);
    const [scale, setScale] = useState<number>(currentSheet ? parseFloat(currentSheet.scale) : 0)
    const [showLabelIndex, setShowLabelIndex] = useState<number>(-1)
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [stageState, setStageState] = useState<any>(
        {
            stageScale: 1,
            stageX: 0,
            stageY: 0
        }
    );

    const handleMouseUp = (e: any) => {
        if (isDragging) return;
        if (e?.evt?.button && e?.evt?.button !== 0)  return;
        // if (editTakeOffMode) return;
        if (!currentSheet?.sheetId) return;
        if(!selectedTakeOff && !scalingMode)  return;
        const pos = e.target.getStage().getRelativePointerPosition();
        if (pos.x < 0 || pos.x > width || pos.y < 0 || pos.y > height) return;
        if (scalingMode || tool === "Line") {
            lineMouseDown({
                currentShape,
                setCurrentShape,
                currentUnit,
                currentColour,
                currentSheet,
                selectedTakeOff,
                zHeight,
                pos,
                scale,
                measurements,
                setMeasurements,
                setUnsavedMeasurements,
                scalingMode,
                scaleLine,
                setScaleLine,
                deduction: deduction ? -1 : 1
            })
        } else if (tool === "Rectangle") {
            if(!selectedTakeOff) return;
            rectangleMouseDown({
                currentShape,
                setCurrentShape,
                currentUnit,
                currentColour,
                currentSheet,
                selectedTakeOff,
                zHeight,
                pos,
                scale,
                measurements,
                setMeasurements,
                setUnsavedMeasurements,
                deduction: deduction ? -1 : 1
            })
        } else if (tool === "Circle") {
            if(!selectedTakeOff) return;
            circleMouseDown({
                currentShape,
                setCurrentShape,
                currentUnit,
                currentColour,
                currentSheet,
                selectedTakeOff,
                zHeight,
                pos,
                scale,
                measurements,
                setMeasurements,
                setUnsavedMeasurements,
                deduction: deduction ? -1 : 1
            })
        } else if (tool === "Polygon") {
            if(!selectedTakeOff) return;
            polygonMouseDown({
                currentShape,
                setCurrentShape,
                currentUnit,
                currentColour,
                currentSheet,
                selectedTakeOff,
                zHeight,
                pos,
                scale,
                measurements,
                setMeasurements,
                setUnsavedMeasurements,
                deduction: deduction ? -1 : 1
            })
        } else if(tool === "Selector" && currentUnit==="EA") {
            if(!selectedTakeOff) return;
            setMeasurements([...measurements, {
                borderColor: currentColour,
                bgColor: currentColour,
                bgThickness: 2,
                shape: "circle",
                id: nanoid(),
                value: !deduction ? 1 : -1,
                zMultiplier: zHeight ? zHeight : 1.0,
                zValue: !deduction ? 1 : -1,
                sheet: currentSheet?.sheetId,
                takeOff: selectedTakeOff?.takeOffId,
                start: { x: pos.x, y: pos.y },
                radius: 5.0,
            }])
            setUnsavedMeasurements(true)
        }
    };

    const handleMouseMove = (e: any) => {
        if (!currentShape || isDragging) return;
        const stage = e.target.getStage();
        const pos = stage.getRelativePointerPosition();
        if (pos.x < 0 || pos.x > width || pos.y < 0 || pos.y > height) return;
        if (scalingMode || tool === "Line") {
            lineMouseMove({currentShape, setCurrentShape, currentUnit, pos, scale, scalingMode, deduction: deduction ? -1 : 1})
        } else if (tool === "Rectangle") {
            rectangleMouseMove({currentShape, setCurrentShape, currentUnit, pos, scale, deduction: deduction ? -1 : 1})
        } else if (tool === "Circle") {
            circleMouseMove({currentShape, setCurrentShape, currentUnit, pos, scale, deduction: deduction ? -1 : 1})
        } else if (tool === "Polygon") {
            polygonMouseMove({currentShape, setCurrentShape, currentUnit, pos, scale, deduction: deduction ? -1 : 1})
        }
    };

    const handleMouseWheel = (e: any) => {
        e.evt.preventDefault();

        const scaleBy = 1.05;
        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
        };

        const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

        setStageState({
            stageScale: newScale,
            stageX: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
            stageY: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale
        });
    }

    useEffect(() => {
        setCurrentShape(null)
    }, [scalingMode])

    useEffect(() => {
        if (!selectedTakeOff) setMeasurements([])
        else {
            //fetchMeasurements
            if (selectedTakeOff.measurements) setMeasurements([...selectedTakeOff.measurements])
        }
    }, [selectedTakeOff])

    useEffect(() => {
        if (!selectedTakeOff) setMeasurements([])
        else {
            //fetchMeasurements
            if (selectedTakeOff.measurements) setMeasurements([...selectedTakeOff.measurements])
        }
    }, [selectedTakeOff])

    useEffect(() => {
        setImage(currentSheet?.url ? currentSheet.url : "")
        setHeight(currentSheet ? parseInt(currentSheet.height) : 0);
        setWidth(currentSheet ? parseInt(currentSheet.width) : 0);
        setScale(currentSheet ? parseFloat(currentSheet.scale) : 0)
        console.log('currentSheet', parseFloat(currentSheet?.scale || "0"))
        if (currentSheet && parseFloat(currentSheet.scale) === 0) setScalingMode(true)
        else if (currentSheet && parseFloat(currentSheet.scale) !== 0 && scalingMode) setScalingMode(false)
    }, [currentSheet])

    useEffect(() => {
        console.log('displayLabels', displayLabels)
    }, [displayLabels])

    return (
        <>
            {   image
                ?
                <Box
                    className="stage-canvas"
                    // onKeyDown={handleKeyDown}
                    style={{height: "85vh"}}
                    // ref={stageCanvasRef}
                >
                    {!selectedTakeOff && !scalingMode
                        ?
                        <Typography
                            align={"center"}
                            style={{
                                position: "relative",
                                top: "40%",
                                fontSize: "50px",
                                fontWeight: "800",
                                fontFamily: "Roboto",
                                color: "rgba(255, 0, 0, 0.5)",
                                zIndex:"1000",
                                marginTop: "-50px"
                            }}
                        > Add or select a take off to begin</Typography>
                        :
                        null
                    }
                    <Stage
                        style={{cursor: "crosshair"}}
                        width={width}
                        height={height}
                        onWheel={handleMouseWheel}
                        onMouseUp={handleMouseUp}
                        onMouseMove={handleMouseMove}
                        scaleX={stageState.stageScale}
                        scaleY={stageState.stageScale}
                        x={stageState.stageX}
                        y={stageState.stageY}
                        draggable
                        onDragStart={(e: any) => {
                            setIsDragging(true)
                        }}
                        onDragEnd={() => {/*setDraggable(false);*/
                            setIsDragging(false)
                        }}
                    >
                        <Layer>
                            <ImageCustom src={image} height={height} width={width} />
                            {!scalingMode
                                ?
                                [...measurements].map((shape: DoxCircle | DoxLine | DoxRectangle | DoxPolygon, index: number) =>
                                    <DoxleShape key={shape.id} shape={shape} currentUnit={currentUnit} index={index}
                                                setShowLabelIndex={setShowLabelIndex} showLabelIndex={showLabelIndex}
                                                currentSheetId={currentSheet?.sheetId} zoom={stageState.stageScale}/>
                                )
                                : null
                            }
                            {
                                currentShape
                                    ? <DoxleShape key={"currentShape"} shape={currentShape} currentUnit={scalingMode ? "LM" : currentUnit}
                                                  setShowLabelIndex={setShowLabelIndex} showLabelIndex={showLabelIndex}
                                                  currentSheetId={currentSheet?.sheetId} drawing zoom={stageState.stageScale} index={-1}/>
                                    : scaleLine ?
                                        <DoxleShape key={"scaleLine"} shape={scaleLine} currentUnit={scalingMode ? "LM" : currentUnit}
                                                    setShowLabelIndex={setShowLabelIndex} showLabelIndex={showLabelIndex}
                                                    currentSheetId={currentSheet?.sheetId} zoom={stageState.stageScale} index={-1} drawing/>
                                        : null
                            }
                        </Layer>
                        {displayLabels
                            ?
                            <Layer>
                                {!scalingMode
                                    ?
                                    [...measurements].map((shape: DoxCircle | DoxLine | DoxRectangle | DoxPolygon, index: number) =>
                                        <DoxleShape
                                            key={shape.id+"_Label"}
                                            shape={shape} currentUnit={currentUnit}
                                            currentSheetId={currentSheet?.sheetId}
                                            label={true} index={index}
                                            zoom={stageState.stageScale}
                                            showLabelIndex={showLabelIndex}
                                            setShowLabelIndex={setShowLabelIndex}
                                        />
                                    )
                                    : null
                                }
                                {
                                    currentShape ?
                                    <DoxleShape
                                        key={currentShape.id+"_Label"}
                                        shape={currentShape} currentUnit={currentUnit}
                                        currentSheetId={currentSheet?.sheetId}
                                        label={true} index={-1}
                                        zoom={stageState.stageScale}
                                        showLabelIndex={showLabelIndex}
                                        setShowLabelIndex={setShowLabelIndex}
                                    />
                                    : null
                                }
                            </Layer>
                            :
                            null
                        }
                        {showLabelIndex > 0
                            ? <KonvaInteractiveLabel
                                shape={measurements?.[showLabelIndex]}
                            />
                            : null
                        }
                    </Stage>
                </Box>
                :
                <Typography>Upload a plan</Typography>
            }
        </>
    );
};

export default KonvaComponent;