import ProjectRow from './ProjectRow'
import styles from './adminTable.module.css'
import NoticeAPI from '../../../Services/DoxleAPI/noticeAPI'
import ProjectAPI from '../../../Services/DoxleAPI/projectAPI'
import { useEffect, useState, useContext } from 'react'
import { Company } from '../../../Models/company'
import { NoticeCategory } from '../../../Models/notice'
import { ProjectPermits } from '../../../Models/project'
import WindowDimensions from '../../../utilities/WindowDimensions/windowDimension'
import NoticesDetails from '../Notices/NoticesDetails'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { ReactComponent as DropDownGreenIcon } from '../../../Assets/DropDownGreen.svg'
import Loading from '../../../utilities/Lottie/Loading'
import { useNavigate } from 'react-router-dom'
import { NoticesContext, INoticesContext } from '../Notices/Notices'
import { User } from '../../../Models/user'
import { Notice } from '../../../Models/notice'

type TNoticeViewMode = 'list' | 'table' | 'board'

interface props {
  company: Company | undefined
  noticeViewMode: TNoticeViewMode
  setNoticeViewMode: React.Dispatch<React.SetStateAction<TNoticeViewMode>>
}

const AdminTable: React.FC<props> = ({
  company,
  noticeViewMode,
  setNoticeViewMode,
}) => {
  const [adminCategories, setAdminCategories] = useState<NoticeCategory[]>([])
  const [projectData, setProjectData] = useState<ProjectPermits[]>([])
  const [loading, setLoading] = useState(false)
  const { height, width } = WindowDimensions()
  const [userList, setUserList] = useState<Array<User>>([])
  const [categoryList, setCategoryList] = useState<NoticeCategory[]>([])
  const [selectedNotice, setSelectedNotice] = useState<Notice | object>({})
  const [selectedNoticeTab, setSelectedNoticeTab] = useState<string>('Notice')
  const [selectedNoticeAddress, setSelectedNoticeAddress] = useState<string>('')

  const context = useContext(NoticesContext)
  const { openModal, setOpenModal, notices, setNotices, forceUpdate } =
    context as INoticesContext

  const companyId = company?.companyId!

  // Assigning the amount of columns in the table body based on the length of the 'adminCategories' array.
  const columnLength = adminCategories.length

  const fetchProjectDataAndNoticeCategories = async () => {
    setLoading(true)
    try {
      const response: any = await ProjectAPI.getListWithPermits(companyId)

      if (response) {
        setProjectData(response)
      } else {
        console.log('no response')
      }
    } catch (err) {
      console.log('ERROR FETCHING NOTICES')
    }

    try {
      const response: any = await NoticeAPI.getCategories(companyId, true)

      if (response) {
        setAdminCategories(response)
      }
    } catch (err) {
      console.log('ERROR FETCHING NOTICES')
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchProjectDataAndNoticeCategories()
  }, [companyId])

  const navigate = useNavigate()

  const closeModal = () => {
    setOpenModal(false)
    navigate('/NoticeBoard')
  }

  const handleChangeNoticeView = (value: string) => {
    if (value === 'list' || value === 'table' || value === 'board') {
      setNoticeViewMode(value)
      console.log(noticeViewMode)
      localStorage.setItem('currentNoticeViewMode', value)
      console.log(localStorage)
    }
  }

  return (
    <div style={{ position: 'relative', overflow: 'scroll' }}>
      <div className={styles.container}>
        <div className={styles.bannerContainer}>
          <Select
            labelId='daily-view-select-label'
            id='daily-view-select'
            IconComponent={DropDownGreenIcon}
            value={noticeViewMode}
            onChange={(value) => handleChangeNoticeView(value.target.value)}
            sx={{
              background: '#F3F1FC',
              fontFamily: 'IBM Plex Sans',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '10px',
              lineHeight: '14px',
              minWidth: '87px',
              position: 'absolute',
              right: '100px',
              top: '20px',
              height: '35px',
              color: '#A282FC',
              // width: "114px",
              '& fieldset': {
                border: 'none',
              },
              '& .MuiSelect-icon': {
                top: 'calc(50% - 0.4em)',
              },
              borderRadius: '7px',

              textTransform: 'capitalize',
            }}
          >
            <MenuItem
              value={'list'}
              sx={{
                fontFamily: 'IBM Plex Sans',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '10px',
                lineHeight: '14px',
                height: '30px',
                paddingLeft: '5px !important',
                paddingRight: '5px !important',
                borderRadius: '5px',

                marginBottom: '5px',
                color: noticeViewMode === 'list' ? '#7949FF' : 'black',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                textTransform: 'capitalize',
              }}
            >
              List View
            </MenuItem>
            <MenuItem
              value={'table'}
              sx={{
                fontFamily: 'IBM Plex Sans',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '10px',
                lineHeight: '14px',
                height: '30px',
                paddingLeft: '5px !important',
                paddingRight: '5px !important',
                borderRadius: '5px',

                marginBottom: '5px',
                color: noticeViewMode === 'table' ? '#7949FF' : 'black',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                textTransform: 'capitalize',
              }}
            >
              Table View
            </MenuItem>
            <MenuItem
              value={'board'}
              sx={{
                fontFamily: 'IBM Plex Sans',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '10px',
                lineHeight: '14px',
                height: '30px',
                paddingLeft: '5px !important',
                paddingRight: '5px !important',
                borderRadius: '5px',

                marginBottom: '5px',
                color: noticeViewMode === 'board' ? '#7949FF' : 'black',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                textTransform: 'capitalize',
              }}
            >
              Board View
            </MenuItem>
          </Select>
          <div className={styles.headerContainer}>
            <h1 className={styles.header}>Admin</h1>
            <h3 className={styles.versionText}>Version 1.0</h3>
          </div>
        </div>
        <div className={styles.tableContainer}>
          <table className={styles['admin-table']}>
            <thead>
              <tr>
                <th></th>
                {adminCategories.map((adminCategory: NoticeCategory) => (
                  <th>{adminCategory.categoryTitle}</th>
                ))}
                <th></th>
              </tr>
            </thead>
            {loading ? <Loading /> : null}
            <tbody>
              {projectData!.map((project?: ProjectPermits) => (
                <ProjectRow
                  project={project}
                  columnLength={columnLength}
                  setSelectedNotice={setSelectedNotice}
                  setSelectedNoticeAddress={setSelectedNoticeAddress}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {
        <NoticesDetails
          selectedNotice={selectedNotice}
          selectedNoticeTab={selectedNoticeTab}
          openModal={openModal}
          closeModal={closeModal}
          height={height}
          width={width}
          userList={userList}
          categoryList={categoryList}
          setCategoryList={setCategoryList}
          notices={notices}
          setNotices={setNotices}
          forceUpdate={forceUpdate}
          company={company}
          projectSiteAddress={selectedNoticeAddress}
        />
      }
    </div>
  )
}

export default AdminTable
