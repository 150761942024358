import React, { useEffect, useState } from "react";
import { Project } from "../../../Models/project";
import { IconButton, Stack, Typography } from "@mui/material";
import settingsIcon from "../../../Assets/GearPurple.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Badge from "@mui/material/Badge";
import { ReactComponent as NotificationOFFIcon } from "../../../Assets/NotificationOFF.svg";
import { ReactComponent as ProjectPopperIcon } from "../../../Assets/projectDropDownIcon.svg";
// import {alertContextInterface, useAlerts} from "../../Providers/AlertProvider";

interface props {
  setProjectSelected: React.Dispatch<React.SetStateAction<Project | null>>;
  projects: Project[];
  projectSelected: Project | null;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectSelectDropDown: React.FC<props> = ({
  setProjectSelected,
  projects,
  projectSelected,
  setLoading,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [filterType, setFilterType] = React.useState<null | "year" | "status">(null);
  const [filter, setFilter] = React.useState<null | number | string>(null);

  // const [filterStatus, setFilterStatus] = React.useState<null | string>(projectSelected?.projectStatus || "AC");
  const allYears = [
    ...new Set(
      projects.map((p) => {
        return new Date(p.startDate).getFullYear();
      })
    ),
  ].sort();
  const allStatuses = [
    ...new Set(
      projects.map((p) => {
        return p.projectStatus;
      })
    ),
  ].sort();
  const colWidth =
    Math.max(...projects.map((el) => el.siteAddress.length)) * 10;
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProjectClick = (e: any, project: Project) => {
    if ((e?.button === 0 && (e?.ctrlKey || e?.metaKey)) || e?.button === 1){
        window.open(`${window.location.host}/Projects/${project.projectId}/`);
        return;
    } else if (e?.button === 0 && !(e?.ctrlKey || e?.metaKey)) {
        setAnchorEl(null);
        setLoading(true);
        localStorage.setItem("currentProject", JSON.stringify(project));
        localStorage.setItem("currentProjectId", project.projectId);
        if (project.projectPrefix)
            sessionStorage.setItem("projectPrefix", project.projectPrefix);
        else if (project.siteAddress)
            sessionStorage.setItem(
                "projectPrefix",
                project.siteAddress.replace(/ /g, "").substring(0, 7).toUpperCase()
            );
        setProjectSelected(project);
        localStorage.setItem("currentProject", JSON.stringify(project));
    }
  };

  const handleFilterItemClicked = (
    filterType: "year" | "status" | null,
    filterOption: string | number | null
  ) => {
    if (
      (filterType === "year" && typeof filterOption === "number") ||
      filterOption === null
    )
      setFilter((prevFilter) =>
        prevFilter !== filterOption ? filterOption : null
      );
    if (
      (filterType === "status" && typeof filterOption === "string") ||
      filterOption === null
    )
      setFilter((prevFilter) =>
        prevFilter !== filterOption ? filterOption : null
      );
    if (filterType === null) setFilter(null);
  };

  useEffect(() => {setFilter(projectSelected?.projectStatus || null)}, [projectSelected])

  return (
    <>
      <IconButton
        sx={{ height: "24px", m: 0, p: 0, marginTop: "10px" }}
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Typography style={{ fontFamily: "Roboto Mono", fontSize: "11px" }}>
          {projectSelected?.siteAddress}
        </Typography>
        &nbsp; &nbsp; &nbsp;
        <ProjectPopperIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiList-root": {
            p: 0,
          },
          "& .MuiMenu-paper": {
            minWidth: colWidth + "px",
          },
          "& .MuiMenuItem-root": {
            fontFamily: "Roboto Mono",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "16px",
            alignItems: "center",
          },
        }}
      >
        <MenuItem
          sx={{
            justifyContent: "space-between",
            marginBottom: "10px",
            height: "35px",
            paddingRight: "6px",
            "&:hover": { background: "none" },
            cursor: "default",
          }}
          disableRipple
          divider
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            PROJECTS
          </div>
          <div>
            <IconButton
              sx={{
                width: "21px",
                height: "21px",
                m: 0,
                p: 0,
                cursor: "pointer",
                borderRadius: 1,
                background: "#F5F6F7",
              }}
              size="small"
              onClick={handleClose}
            >
              <CloseIcon sx={{ transform: "scale(0.8)" }} />
            </IconButton>
          </div>
        </MenuItem>

        <MenuItem
          key={"yearFilterHeader"}
          sx={{
            color: "#754EE0",
            cursor: "default",
            "&:hover": { background: "none" },
          }}
          divider
          disableRipple
        >
          Filter By Year
        </MenuItem>
        {allYears.map((year) => {
          return [
            null,
            ...projects.filter(
              (project) => new Date(project.startDate).getFullYear() === filter
            ),
          ].map((project: Project | null, num: number) => {
            if (project === null) {
              return (
                <MenuItem
                  key={year}
                  onClick={() => handleFilterItemClicked("year", year)}
                  sx={{
                    letterSpacing: "-0.5px",
                    fontSize: "x-small",
                    color: "#754EE0",
                    background: filter === year ? "#f1f2f5" : "none",
                  }}
                >
                  &nbsp;{year}
                </MenuItem>
              );
            } else if (filter === year) {
              console.log("project", project);
              return (
                <MenuItem
                  key={year + project.projectId}
                  onMouseUp={(event: any) => {
                    handleProjectClick(event, project);
                  }}
                >
                  &nbsp;&nbsp;&nbsp;{project.siteAddress}
                </MenuItem>
              );
            }
            return null;
          });
        })}
        <br key={"line"} />
        <MenuItem
          key={"statusFilterHeader"}
          sx={{
            color: "#754EE0",
            cursor: "default",
            "&:hover": { background: "none" },
          }}
          divider
          disableRipple
        >
          Filter By Status
        </MenuItem>
        {allStatuses.map((status) => {
          return [
            null,
            ...projects.filter((project) => project.projectStatus === filter),
          ].map((project: Project | null, num: number) => {
            if (project === null) {
              return (
                <MenuItem
                  key={status}
                  onClick={() => handleFilterItemClicked("status", status)}
                  sx={{
                    letterSpacing: "-0.5px",
                    fontSize: "x-small",
                    color: "#754EE0",
                    background: filter === status ? "#f1f2f5" : "none",
                  }}
                >
                  &nbsp;
                  {status === "AC"
                    ? "Active Projects"
                    : status === "TN"
                    ? "Tendering"
                    : status === "TM"
                    ? "Project Templates"
                    : "Archived Projects"}
                </MenuItem>
              );
            } else if (filter === status) {
              return (
                <MenuItem
                  key={status + project.projectId}
                  onMouseUp={(event: any) => {
                    handleProjectClick(event, project);
                  }}
                >
                  &nbsp;&nbsp;&nbsp;{project.siteAddress}
                </MenuItem>
              );
            }
            return null;
          });
        })}
        <br key={"line2"} />
        <MenuItem
          key={"allHeader"}
          divider
          sx={{ color: "#754EE0", "&:hover": { background: "none" } }}
          onClick={() => handleFilterItemClicked(null, null)}
        >
          All Projects
        </MenuItem>
        {filter === null &&
          projects.map((project: Project, num: number) => (
            <MenuItem
              key={project.projectId}
              onMouseUp={(event: any) => {
                handleProjectClick(event, project);
              }}
            >
              &nbsp;&nbsp;&nbsp;{project.siteAddress}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default ProjectSelectDropDown;
