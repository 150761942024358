import React, { useState } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
// import arrowLeftImage from '../../Assets/ChevronsLeft.svg';
import arrowLeftImage from "../../Assets/Menu.svg";
import arrowRightImage from "../../Assets/ChevronsRight.svg";
import { ReactComponent as ExpandIcon } from "../../Assets/DrawerExpand.svg";
import { ReactComponent as CloseIcon } from "../../Assets/DrawerClose.svg";
// import arrowRightImage from '../../Assets/Menu.svg';
import DrawerNavigationItems from "./DrawerNavigationItems";
// import doxleLogo from '../../Assets/doxleLogo.svg';
import doxleLogo from "../../Assets/doxleLogoPurple.svg";
import ViewUser from "../Content/Users/ViewUser";
import { Company } from "../../Models/company";
import DrawerExpand from "./DrawerExpand/DrawerExpand";
import { AnimatePresence, motion } from "framer-motion";
interface props {
  company?: Company | undefined;
  setDisplayDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  width: number;
}
let sessionView = sessionStorage.getItem("defaultView");
const MenuDrawer: React.FC<props> = ({ company, setDisplayDrawer, width }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [activeSublist, setActiveSublist] = useState<number | undefined>(
    sessionView ? (sessionView === "budget" || sessionView==="cashflow" ? 2 : 0) : 0
  );

  const drawerWidth = 240;

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop: string) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const MenuToggleButton = styled(Button)({
    display: "flex",
    flexDirection: "column",
    padding: 0,
    minWidth: "22px !important",
    fontFamily: "IBM Plex Sans Condensed",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "6px",
    lineHeight: "8px",
    color: "#89819E",
    marginLeft: "4px",
    border: "none",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "transparent",
    height: "30px",
    textTransform: "capitalize",
  });
  const theme = useTheme();

  const removeDrawer = () => {
    setDisplayDrawer(false);
  };

  const switchDrawer = (opened: boolean, activeMenu: number) => {
    setOpen(opened);
    setActiveSublist(activeMenu);
  };

  //####################Control switching drawer mode view#####
  const [drawerMode, setDrawerMode] = useState<"expand" | "minimised">(
    "minimised"
  );

  const toggleDrawer = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const getDisplay = () => {
    if (open && width >= 928) {
      return "";
    } else {
      return "none";
    }
  };

  return (
    <Box
      sx={{
        flex: "none",
        width: open
          ? drawerMode === "minimised"
            ? width < 928
              ? "50px"
              : "93px"
            : "368px"
          : "0px",
      }}
    >
      
        {drawerMode === "minimised" ? (
          <Drawer
            variant="permanent"
            open={open}
            sx={{
              "& .MuiPaper-root": {
                position: "fixed",
                // marginLeft: width < 928 ? "2%":"1%",
                // marginLeft: open?"17px":"0",
                marginLeft: "0",
                // marginTop: "25px",
                // borderRadius: "12px",
                // width: open
                //     ? width < 928 ? "50px":"280px"
                //     : width < 928 ? "50px":"93px",
                width: width < 928 ? "50px" : "93px",
                overflow: "hidden",
                // height: "calc(100% - 25px - 25px)",
                // height: !open &&  width < 928 ? "37px": "calc(100% - 25px - 25px)",
                // height: !open &&  width < 928 ? "37px": "100%",
                height: !open ? "37px" : "100%",
                justifyContent: "space-between",
                background: !open ? "none" : "#ffffff",
                border: "none",
              },
            }}
          >
            <div id="menuTopPosition">
              <DrawerHeader
                sx={{
                  marginTop: "6px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "9px",
                  justifyContent: "flex-start",
                }}
              >
                {!open ? (
                  <div
                    style={{
                      alignSelf: open ? "end" : "flex-start",
                    }}
                  >
                    {/* <IconButton onClick={toggleDrawer} sx={{background:"#F8F8F8", borderRadius:"7px", width: "19px", height: "19px"}}> */}

                    <IconButton
                      onClick={toggleDrawer}
                      sx={{
                        width: "24px",
                        height: "24px",
                        marginRight: !open ? "4%" : "none",
                      }}
                    >
                      <img
                        src={open ? arrowRightImage : arrowLeftImage}
                        alt={open ? "Arrow Right" : "Arrow Left"}
                      />
                    </IconButton>
                  </div>
                ) : (
                  <div
                    style={{
                      alignSelf: open ? "end" : "flex-start",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {/* <IconButton onClick={toggleDrawer} sx={{background:"#F8F8F8", borderRadius:"7px", width: "19px", height: "19px"}}> */}
                    <MenuToggleButton onClick={() => setDrawerMode("expand")}>
                      <ExpandIcon />
                      Expand
                    </MenuToggleButton>

                    <MenuToggleButton onClick={toggleDrawer}>
                      <CloseIcon />
                      Close
                    </MenuToggleButton>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  {/* <ViewCompany selectedCompany={company} displayValue={getDisplay()}/> */}
                  <img
                    src={doxleLogo}
                    alt="doxle"
                    style={{
                      justifyContent: "space-between",
                      height: width < 928 ? "36px" : "56px",
                    }}
                  />
                </div>
              </DrawerHeader>
              {/* {open && width >= 928 ? <Divider /> : <></>}
              <div style={{paddingTop:"8px" }}>
                  <DrawerNavigationItems open={width < 928 ? false:open} setDisplayDrawer={switchDrawer} activeSublist={activeSublist} foot={false}/>
              </div> */}
            </div>

            <div id="menuCenterPosition">
              <div style={{ paddingTop: "8px" }}>
                <DrawerNavigationItems
                  open={false}
                  setDisplayDrawer={switchDrawer}
                  activeSublist={activeSublist}
                  setActiveSublist={setActiveSublist}
                  foot={false}
                />
              </div>
            </div>

            <div id="menuBottomPosition">
              <DrawerHeader sx={{ justifyContent: "center" }}>
                <ViewUser />
              </DrawerHeader>

              {/* 
            <div style={{paddingBottom:"10px" }}>
                <DrawerNavigationItems open={width < 928 ? false:open} setDisplayDrawer={switchDrawer} activeSublist={activeSublist} foot={true}/>
            </div>

            {open && width >= 928 ? <Divider /> : <></>}
            <DrawerHeader sx={{
              justifyContent:"flex-start"}}>
              {open && width >= 928 ? <div className="drawerFooter" style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft:"-19px"
                      }}>
                        <div style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "120px"
                        }}>
                          <img src={doxleLogo} 
                              alt='doxle' 
                              style={{ 
                                  paddingLeft:"5px",
                                  height:"95px",
                                  paddingTop:"25px"
                                }}
                                  />
                          <p style={{fontFamily: 'Mina',
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "7px",
                              lineHeight: "11px",
                              color: "#535D74"}}>version 1.2</p>
                        </div>
                        <div>
                          <ViewUser />
                        </div>                        
                      </div>
                    : 
                    <div style={{ 
                      bottom:"0px"}}>
                        <img src={doxleLogo} 
                            alt='doxle' 
                            style={{ 
                            justifyContent:"space-between", 
                            height: width < 928 ? "36px": "auto"
                          }}/>
                          <p style={{
                              fontFamily: 'Mina',
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: width < 928 ? "7px":"8px",
                              margin: width < 928 ? "0 0 20px 0" : "0 0 20px 15px",
                              lineHeight: "5px",
                              color: "#535D74"}}>version 1.2</p>
                    </div>
                      
                          }
            </DrawerHeader>    */}
            </div>
          </Drawer>
        ) : (<AnimatePresence>
          <DrawerExpand
            setDrawerMode={setDrawerMode}
            setOpenDrawer={setOpen}
            clickedIdentifier={activeSublist}
            setActiveSublist={setActiveSublist}
          /> </AnimatePresence>
        )}
     
    </Box>
  );
};

export default MenuDrawer;
