import React from "react";
import { Typography, Grid, IconButton, TextField } from "@mui/material";
import { AddressBookContact } from "../../../Models/addressBook";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

interface props {
  contact: AddressBookContact;
  abCompanyContacts: Array<AddressBookContact>;
  setAbCompanyContacts: Function;
  setContactsToDelete: Function;
}

const ContactDetails: React.FC<props> = ({
  contact,
  abCompanyContacts,
  setAbCompanyContacts,
  setContactsToDelete,
}) => {
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    keyName: string
  ) => {
    setAbCompanyContacts((prevAbContacts: AddressBookContact[]) =>
      prevAbContacts.map((abContact: AddressBookContact) => {
        if (abContact.abContactId === contact.abContactId) {
          return {
            ...abContact,
            [keyName]: e.target.value,
          };
        } else {
          return abContact;
        }
      })
    );
  };

  const handleDeleteContact = () => {
    setAbCompanyContacts((prevAbContacts: AddressBookContact[]) =>
      prevAbContacts.filter(
        (abContact: AddressBookContact) =>
          abContact.abContactId !== contact.abContactId
      )
    );
    setContactsToDelete((prevContacts: AddressBookContact[]) => [
      ...prevContacts,
      contact,
    ]);
  };
  return (
    <>
      <Grid item xs={4} sx={{ mt: 2 }}>
        <Typography>Contact Person</Typography>
      </Grid>
      <Grid item xs={7} sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              label="First Name"
              variant="outlined"
              value={contact.firstName}
              onChange={(e) => handleValueChange(e, "firstName")}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              value={contact.lastName}
              onChange={(e) => handleValueChange(e, "lastName")}
              sx={{ ml: 1 }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Phone"
              variant="outlined"
              value={contact.phone}
              onChange={(e) => handleValueChange(e, "phone")}
            />
            <TextField
              label="Mobile"
              variant="outlined"
              value={contact.mobile}
              onChange={(e) => handleValueChange(e, "mobile")}
              sx={{ ml: 1 }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Email"
              variant="outlined"
              value={contact.email}
              onChange={(e) => handleValueChange(e, "email")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} sx={{ mt: 2 }}>
        <IconButton
          onClick={handleDeleteContact}
          size="small"
          sx={{
            color: "#96A2BE",
            background: "#FFFFFF",
            borderRadius: "7px",
            "&:hover": { color: "black" },
          }}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </Grid>
    </>
  );
};

export default ContactDetails;
