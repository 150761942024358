import React, { useState, useEffect } from "react";
import { Company } from "../../../Models/company";
import {
  Modal,
  Box,
  Typography,
  Grid,
  IconButton,
  TextField,
  Button,
  Link,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import AddressBookAPI from "../../../Services/DoxleAPI/addressBookAPI";
import {
  AddressBookCompany,
  AddressBookContact,
} from "../../../Models/addressBook";
import ContactDetails from "./ContactDetails";
import { ConstructionOutlined } from "@mui/icons-material";

interface props {
  company: Company | undefined;
  openModal: boolean;
  closeModal: () => void;
  width: number;
  selectedAbCompany?: AddressBookCompany;
}

const AddressBookDetails: React.FC<props> = ({
  company,
  openModal,
  closeModal,
  width,
  selectedAbCompany,
}) => {
  // const [isContactEdited, setIsContactEdited] = useState<boolean>(false);
  const [abCompany, setAbCompany] = useState<AddressBookCompany>({
    userCompany: String(company?.companyId),
    companyName: "",
    phone: "",
    email: "",
    companyAbn: "",
    addressL1: "",
    addressL2: "",
    addressCity: "",
    addressPostCode: "",
    addressState: "",
  });
  const [abCompanyContacts, setAbCompanyContacts] = useState<
    Array<AddressBookContact>
  >([]);
  const [contactsToDelete, setContactsToDelete] = useState<
    Array<AddressBookContact>
  >([]);

  const [addNewAbCompany, setAddNewAbCompany] = useState<boolean>(false);

  const addAddressBookContact = async (contact: AddressBookContact) => {
    try {
      const response = await AddressBookAPI.addContact(contact);
    } catch (err) {
      console.log("ERROR!!! ADDING ADRESS BOOK CONTACT" + err);
    }
  };

  const updateAddressBookContact = async (contact: AddressBookContact) => {
    try {
      const response = await AddressBookAPI.updateContact(
        contact.abContactId,
        contact
      );
      console.log("address contact response:", response);
    } catch (err) {
      console.log("ERROR!!! UPDATE ADRESS BOOK CONTACT" + err);
    }
  };

  const deleteAddressBookContact = async (contact: AddressBookContact) => {
    const isOldContact = contact.abContactId?.substring(0, 3) !== "ADD";
    if (isOldContact) {
      try {
        const response = await AddressBookAPI.removeContact(
          contact.abContactId
        );
        console.log("address contact response:", response);
      } catch (err) {
        console.log("ERROR!!! UPDATE ADRESS BOOK CONTACT" + err);
      }
    }
    setContactsToDelete([]);
  };

  const updateCompanyContacts = () => {
    abCompanyContacts.forEach((contact: AddressBookContact) => {
      const isNewContact = contact.abContactId?.substring(0, 3) === "ADD";
      if (isNewContact) {
        addAddressBookContact(contact);
      } else {
        updateAddressBookContact(contact);
      }
    });
    contactsToDelete.forEach((contact: AddressBookContact) => {
      deleteAddressBookContact(contact);
    });
  };

  const handleSave = async () => {
    //* COMPANY ALREADY EXISTS - UPDATE
    if (selectedAbCompany) {
      // update existing address book company
      try {
        const response = await AddressBookAPI.update(
          abCompany.abCompanyId,
          abCompany
        );
      } catch (err) {
        console.log("ERROR!!! UPDATING ADRESS BOOK COMPANY" + err);
      }
      updateCompanyContacts();
    }

    //* NEW COMPANY - ADD
    else {
      console.log("New Company to add" + abCompany.companyName);
      // add new address book company
      setAddNewAbCompany(true);
      try {
        const response = await AddressBookAPI.add(abCompany);
        console.log("response?.abCompanyId", response?.abCompanyId);
        if (response?.abCompanyId) {
          setAbCompanyContacts((prevContacts) =>
            prevContacts.map((contact: AddressBookContact) => ({
              ...contact,
              abCompany: response?.abCompanyId,
            }))
          );
        }
      } catch (err) {
        console.log("ERROR!!! ADDING ADRESS BOOK COMPANY" + err);
      }
    }
    closeModal();
  };

  const handleDelete = async () => {
    if (selectedAbCompany) {
      try {
        const response = await AddressBookAPI.remove(abCompany.abCompanyId);
      } catch (err) {
        console.log("ERROR!!! UPDATING ADRESS BOOK COMPANY" + err);
      }
      abCompanyContacts.forEach((contact: AddressBookContact) => {
        deleteAddressBookContact(contact);
      });
    }
    closeModal();
  };

  const addPerson = () => {
    setAbCompanyContacts((prevAbContacts) => [
      ...prevAbContacts,
      {
        abContactId: "ADD-" + String(new Date().getTime()),
        userCompany: abCompany.userCompany,
        abCompany: abCompany.abCompanyId,
        firstName: "",
        lastName: "",
        phone: "",
        mobile: "",
        email: "",
      },
    ]);
  };

  const fetchAddressBookContact = async () => {
    try {
      console.log("FETCHING ADDRESSBOOK CONTACT");
      const response = await AddressBookAPI.getContactList();
      console.log("LENGTH  RESULT: ", response.length);
      console.log("AddressBook CONTACT API Response RESULT: ", response);

      setAbCompanyContacts(
        response.filter(
          (contact: AddressBookContact) =>
            contact.abCompany === selectedAbCompany?.abCompanyId
        )
      );
    } catch (err) {
      console.log("ERROR!!!  = GETTING LIST OF ADRESS BOOK CONTACT" + err);
    }
  };

  useEffect(() => {
    if (selectedAbCompany) {
      setAbCompany(selectedAbCompany);
      fetchAddressBookContact();
      console.log(selectedAbCompany);
    }
  }, [selectedAbCompany]);

  useEffect(() => {
    if (addNewAbCompany) {
      abCompanyContacts.forEach((contact) => {
        if (contact.abCompany) {
          addAddressBookContact(contact);
          setAddNewAbCompany(false);
        }
      });
    }
  }, [abCompanyContacts, addNewAbCompany]);

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
    >
      <Box
        className={"address-book"}
        sx={{ width: width < 760 ? width + "px" : 760 }}
      >
        <Box sx={{ pl: "58px", pr: "36px" }}>
          <Grid container spacing={2} sx={{ background: "#FAFAFA" }}>
            <Grid item xs={10}>
              <Typography variant="h6" sx={{ padding: "10px 0 10px 7px" }}>
                {selectedAbCompany ? "Edit Contact" : "Add Contact"}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ textAlign: "right" }}>
              <IconButton
                onClick={closeModal}
                size="small"
                sx={{
                  color: "#96A2BE",
                  background: "#FFFFFF",
                  borderRadius: "7px",
                  "&:hover": { color: "black" },
                }}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        {/* content grid */}

        <Grid
          sx={{
            background: "#ffffff",
            overflowY: "auto",
            height: "calc(100% - 46px)",
            pl: "58px",
            pr: "36px",
            pt: "20px",
            pb: "30px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography>Company Name</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                // variant={
                //     isContactEdited ? "outlined" : "standard"
                // }
                value={abCompany.companyName}
                onChange={(e) => {
                  setAbCompany({
                    ...abCompany,
                    companyName: e.target.value,
                  });
                }}
                // InputProps={{
                //     readOnly: isContactEdited ? false : true,
                //   }}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography>Company ABN</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                value={abCompany.companyAbn}
                onChange={(e) => {
                  setAbCompany({
                    ...abCompany,
                    companyAbn: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography>Company Email</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                value={abCompany.email}
                onChange={(e) => {
                  setAbCompany({
                    ...abCompany,
                    email: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography>Company Phone</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                value={abCompany.phone}
                onChange={(e) => {
                  setAbCompany({
                    ...abCompany,
                    phone: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography>Address</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                sx={{ width: "90%" }}
                value={abCompany.addressL1}
                onChange={(e) => {
                  setAbCompany({
                    ...abCompany,
                    addressL1: e.target.value,
                  });
                }}
              />
              <TextField
                variant="outlined"
                sx={{ width: "90%" }}
                value={abCompany.addressL2}
                onChange={(e) => {
                  setAbCompany({
                    ...abCompany,
                    addressL2: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={8}>
              <TextField
                label="City"
                variant="outlined"
                value={abCompany.addressCity}
                onChange={(e) => {
                  setAbCompany({
                    ...abCompany,
                    addressCity: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={8}>
              <TextField
                label="Post/Zip Code"
                variant="outlined"
                value={abCompany.addressPostCode}
                onChange={(e) => {
                  setAbCompany({
                    ...abCompany,
                    addressPostCode: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={8}>
              <TextField
                label="State"
                variant="outlined"
                value={abCompany.addressState}
                onChange={(e) => {
                  setAbCompany({
                    ...abCompany,
                    addressState: e.target.value,
                  });
                }}
              />
            </Grid>

            {abCompanyContacts
              .filter(
                (contact) =>
                  contact.abCompany === selectedAbCompany?.abCompanyId
              )
              .map((contact) => (
                <ContactDetails
                  key={contact.abContactId}
                  contact={contact}
                  abCompanyContacts={abCompanyContacts}
                  setAbCompanyContacts={setAbCompanyContacts}
                  setContactsToDelete={setContactsToDelete}
                />
              ))}

            <Grid item xs={4}></Grid>
            <Grid item xs={8}>
              <Link onClick={addPerson} sx={{ cursor: "pointer" }}>
                Add contact person
              </Link>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: selectedAbCompany
                  ? "space-between"
                  : "flex-end",
              }}
            >
              {selectedAbCompany && (
                <Button
                  variant="contained"
                  onClick={handleDelete}
                  color="error"
                >
                  DELETE
                </Button>
              )}
              <Button variant="contained" onClick={handleSave}>
                SAVE
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default AddressBookDetails;
