import {DoxCircle, DoxLine, DoxPolygon, DoxRectangle, Sheet, TakeOff, XY} from "../../../Models/takeOffs";
import React, {useState} from "react";
import {Circle, Line, Rect, Text} from "react-konva";
import {toFloat} from "./konvaFunctions/generalFunctions";
import {Html} from 'react-konva-utils';

interface props {
    shape: DoxPolygon | DoxLine | DoxRectangle | DoxCircle;
    currentUnit: "LM"|"M2"|"M3"|"EA"|undefined;
    currentSheetId: string|undefined;
    drawing? : true;
    label?: boolean;
    zoom: number;
    index: number;
    showLabelIndex: number;
    setShowLabelIndex: Function
}

const DoxleShape: React.FC<props> = ({shape , currentUnit, currentSheetId, drawing, label, zoom, index, showLabelIndex, setShowLabelIndex}) => {
    const [opacity, setOpacity] = useState<number>(0.7)
    const shapeType: "polygon"|"line"|"rectangle"|"circle" = shape.shape
    let points: number[] = []
    let x: number = 0
    let y: number = 0
    let width: number = 0
    let height: number = 0
    let radius: number = 0
    if (shape.shape === "line") {
        if (typeof shape.start.x === "string") shape.start.x = parseFloat(shape.start.x);
        if (typeof shape.start.y === "string") shape.start.y = parseFloat(shape.start.y);
        if (typeof shape.end.x === "string") shape.end.x = parseFloat(shape.end.x);
        if (typeof shape.end.y === "string") shape.end.y = parseFloat(shape.end.y);
        points = [ shape.start.x, shape.start.y, shape.end.x, shape.end.y ]
    }
    if (shape.shape === "polygon") {
        shape.points.forEach((pair: XY) => {
            points.push(typeof pair.x === "string" ? pair.x = parseFloat(pair.x) : pair.x);
            points.push(typeof pair.y === "string" ? pair.y = parseFloat(pair.y) : pair.y);
        })
    }
    else if (shape.shape === "rectangle") {
        x = (typeof shape.start.x === "string") ? parseFloat(shape.start.x) : shape.start.x;
        y = (typeof shape.start.y === "string") ? parseFloat(shape.start.y) : shape.start.y;
        width = (typeof shape.end.x === "string") ? parseFloat(shape.end.x) - x : shape.end.x - x;
        height = (typeof shape.end.y === "string") ? y+ parseFloat(shape.end.y) - y:  shape.end.y - y;

    }
    else if (shape.shape === "circle") {
        x = (typeof shape.start.x === "string") ? parseFloat(shape.start.x) : shape.start.x;
        y = (typeof shape.start.y === "string") ? parseFloat(shape.start.y) : shape.start.y;
        radius = (typeof shape.radius === "string") ? parseFloat(shape.radius) : shape.radius;
    }
    if (!label) return(
        !currentSheetId || currentSheetId !== shape.sheet?
            null
        : shape.shape === "line" || shape.shape === "polygon" ?
            drawing && shape.shape === "polygon"
                ?
                    <>
                    <Circle
                        onMouseEnter={() => {
                            if (!drawing) setOpacity(1)
                        }}
                        onMouseLeave={() => setOpacity(0.7)}
                        x={points[0]}
                        y={points[1]}
                        fill={"green"}
                        opacity={opacity}
                        radius={10}
                        key={shape.id+"home"}/>
                        {/*onClick={() => setShowLabelIndex((currentIndex: number) => {*/}
                        {/*    if (index === currentIndex) return -1*/}
                        {/*    return index*/}
                        {/*})}*/}
                    <Line
                        onMouseEnter={() => {
                            if (!drawing) setOpacity(1)
                        }}
                        onMouseLeave={() => setOpacity(0.7)}
                        key={shape.id}
                        points={points}
                        stroke={shape.bgColor}
                        fill={currentUnit === "LM" ? undefined : shape.bgColor}
                        strokeWidth={4 / zoom }                        lineCap="square"
                        closed={shape.shape === "polygon"}
                        opacity={opacity}
                        // onClick={() => setShowLabelIndex((currentIndex: number) => {
                        //     if (index === currentIndex) return -1
                        //     return index
                        // })}
                    />
                    </>
                :
                    <Line
                        onMouseEnter={() => {
                            if (!drawing) setOpacity(1)
                        }}
                        onMouseLeave={() => setOpacity(0.7)}
                        key={shape.id}
                        points={points}
                        stroke={shape.bgColor}
                        fill={currentUnit === "LM" ? undefined : shape.bgColor}
                        strokeWidth={4 / zoom }                        lineCap="square"
                        closed={shape.shape === "polygon"}
                        opacity={opacity}
                        // onClick={() => setShowLabelIndex((currentIndex: number) => {
                        //     if (index === currentIndex) return -1
                        //     return index
                        // })}
                    />
        : shape.shape === "rectangle" ?
            <Rect
                onMouseEnter={() => {
                    if (!drawing) setOpacity(1)
                }}
                onMouseLeave={() => setOpacity(0.7)}
                x={x}
                y={y}
                fill={currentUnit === "LM" ? undefined : shape.bgColor}
                opacity={opacity}
                width={width}
                height={height}
                stroke={shape.bgColor}
                strokeWidth={4 / zoom }
                key={shape.id}
                // onClick={() => setShowLabelIndex((currentIndex: number) => {
                //     if (index === currentIndex) return -1
                //     return index
                // })}
            />
            : shape.shape === "circle" ?
                <Circle
                    onMouseEnter={() => {
                        if (!drawing) setOpacity(1)
                    }}
                    onMouseLeave={() => setOpacity(0.7)}
                    x={x}
                    y={y}
                    fill={currentUnit === "LM" ? undefined : shape.bgColor}
                    opacity={opacity}
                    radius={radius}
                    stroke={shape.bgColor}
                    strokeWidth={4 / zoom }
                    key={shape.id}
                    // onClick={() => setShowLabelIndex((currentIndex: number) => {
                    //     if (index === currentIndex) return -1
                    //     return index
                    // })}
                />
            :
                null
    )

    else return (
            <>
                <Rect
                    x={points.length > 2 ? points[0] - 60 : x - 60 - radius}
                    y={points.length > 2 ? points[1] - 15 : y - 15 - radius}
                    fill={'#000'}
                    opacity={0.7}
                    width={80}
                    height={20}
                    key={shape.id}
                    draggable={true}
                    cornerRadius={5}
                />
                <Text
                    x={points.length > 2 ? points[0] - 60 : x - 60 - radius}
                    y={points.length > 2 ? points[1] - 20 : y - 20 - radius}
                    text={`${Math.round(toFloat(shape.zValue) * 100) / 100} ${currentUnit ? currentUnit.toLowerCase() : ""}`}
                    fontSize={15}
                    fontFamily={"Roboto"}
                    fill={'#FFF'}
                    width={120}
                    padding={10}
                    align={'centre'}
                />
            </>
        )
}

export default DoxleShape;