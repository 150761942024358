import axios from "axios"
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";
import { User } from "../../Models/user";

//? return the ChecklistItems for the particular costcode
const getList = async (company?: string, project?: string)  => {
    let result: User[] = []
    if (offline){ return result}
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }

    try {
        let params:any = {};
        if (company) {params.company=company}
        if (project) {params.project=project}

        const response = await axios.get(
            baseAddress+"/user/",
            {
                headers: { Authorization: "Bearer " + accessToken },
                params: params

            }
        )
        if (response.data)
        result = response.data as Array<User>;
        // console.log("USERS:", result)
    } catch (error) {
        console.error("ERROR FETCHING USERS", error)
    }
    return result
}

const UserAPI = {
    getList,
};

export default UserAPI