const slashStyle = {
    color: "#E9121F"
}

const atStyle = {
    color: "#0044CC"
}

const boldStyle = {
    fontWeight: "bold"
}

const replaceJSX = (str: string, replacement: any) => {
    const result: string[] = [];
    const keys = Object.keys(replacement);

    //console.log(str, replacement)

    let keyValueRegex: RegExp = /\d*\b/g;
    let keyMatches: any[] = [];
    let keyValues: any[] = [];
    
    for(let i in keys){
        keyMatches.push(keys[i].match(keyValueRegex))
    }
    
    for(let i in keyMatches){
        keyValues.push({id: keyMatches[i][1], keyItems: keys[i]})
    }
    
    const arrSort = keyValues.sort(function(a,b) {
        return a.id - b.id;
    });

    for(let i in keys){
        keys[i] = arrSort[i].keyItems
    }

    const getRegExp = () => {
        const regexp: string[] = [];
        keys.forEach((key) => regexp.push(`{${key}}`));
        return new RegExp(regexp.join('|'));
    };
    str.split(getRegExp()).forEach((item, i) => {
        result.push(item, replacement[keys[i]]);
    });
    return result;
};

//@[^\s]+\b|\/\/[^\.]+\b|\*[^\s]+\b
export const highlightText = (inputText: string, key: string) => {
    const boldRegex: RegExp = /\*[^\s]+\b/gmi;
    const atRegex: RegExp = /@[^\s]+\b/gmi;
    const slashRegex: RegExp = /\/\/[^\.^\*\@]+\b/gmi;
    const spaceRegex: RegExp = /\s/gmi;
    const newLineRegex: RegExp = /\n/gmi;

    if(inputText){
        inputText = inputText.replaceAll(newLineRegex, "\x0A");
        inputText = inputText.replaceAll(spaceRegex, "\xa0");

        let replacementObject: any = {};
        
        let boldArray = [...inputText.matchAll(boldRegex) as unknown as Array<string>];
        let atArray = [...inputText.matchAll(atRegex) as unknown as Array<string>];
        let slashArray = [...inputText.matchAll(slashRegex) as unknown as Array<string>]
      //  let spaceArray = [...inputText.matchAll(spaceRegex) as unknown as Array<string>]

        
        const boldMatches: any = inputText.matchAll(boldRegex);
        const boldIndexes = [];

        const atMatches: any = inputText.matchAll(atRegex);
        const atIndexes = [];

        const slashMatches: any = inputText.matchAll(slashRegex);
        const slashIndexes = [];

       // const spaceMatches: any = inputText.matchAll(spaceRegex);
      //  const spaceIndexes = [];

        for (const match of boldMatches) {
            boldIndexes.push(match.index);
        }
        for (const match of atMatches) {
            atIndexes.push(match.index);
        }
        for (const match of slashMatches) {
            slashIndexes.push(match.index);
        }
       // for (const match of spaceMatches) {
         //   spaceIndexes.push(match.index);
       // }

        if(boldArray.length !== 0){

            let matchesArray = boldArray.map(item => (item?.[0]));
            let starRegex: RegExp = /[^\*]/gmi;

            for(let i in matchesArray){
                let stringValue = matchesArray[i];
                inputText = inputText.replace(stringValue, '{spanBold'+boldIndexes[i]+'}')
                replacementObject['spanBold'+boldIndexes[i]] = [<span key={key} style={boldStyle}>{matchesArray[i].match(starRegex)?.join("")}</span>]
            }
        }
        
        if(atArray.length !== 0){

            let matchesArray = atArray.map(item => (item?.[0]));
            for(let i in matchesArray){
                let stringValue = matchesArray[i];
                inputText = inputText.replace(stringValue, '{spanAt'+atIndexes[i]+'}')
                replacementObject['spanAt'+atIndexes[i]] = [<span key={key} style={atStyle}>{matchesArray[i]}</span>]
            }
        }
        
        if(slashArray.length !== 0){

            let matchesArray = slashArray.map(item => (item?.[0]));

            for(let i in matchesArray){
                let stringValue = matchesArray[i];
                //let spaceMatches = matchesArray[i].match(spaceRegex)
                //console.log(spaceMatches)
                //console.log(stringValue)
                let newStringValue = stringValue.replaceAll(spaceRegex, "\xa0");
                //console.log(newStringValue);
                //console.log(inputText)
                inputText = inputText.replaceAll(spaceRegex, "\xa0");
                inputText = inputText.replace(newStringValue, '{spanSlash'+slashIndexes[i]+'}')
                //console.log(inputText)
                replacementObject['spanSlash'+slashIndexes[i]] = [<span key={key} style={slashStyle}>{newStringValue}</span>]
            }
            
        }
        //console.log(encodeURI('\n'))

        /*
        if(spaceArray.length !== 0){

            let matchesArray = spaceArray.map(item => (item[0]));


            for(let i in matchesArray){
                let stringValue = matchesArray[i];
                inputText = inputText.replace(stringValue, '{spanSpace'+spaceIndexes[i]+'}')
                replacementObject['spanSpace'+spaceIndexes[i]] = [<span key={key}>&nbsp;</span>]
            }
            
        } */
        
        return replaceJSX(inputText,replacementObject)
    }
}

export const highlightMultilineText = (input: string, key: string) => {

    let inputArray = input.split("\n");
    //console.log(inputArray)
    let output = [];

    for(let i in inputArray){
       /* console.log(
            <>
            <div>
                {highlightText(inputArray[i], key)}{'\n'}
            </div>
            </>
               
        )*/
        output.push(
        <div key={i}>
            {highlightText(inputArray[i], key)}{'\n'}
        </div>
        )
    }

    return output

}