import React, { useState } from "react";
import { Company } from "../../../Models/company";
import { Box } from "@mui/material";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import "./Mailroom.css";
import { Mail } from "../../../Models/mail";
import MailroomSideMenu from "./MailroomSideMenu";
import MailroomMainView from "./MailroomMainView";

interface props {
  company: Company | undefined;
}

const mailroomDummyData: Mail[] = [
  {
    mailId: "1",
    subject: "Brandon Yokor Windows",
    body: "Supply and install here's a bit issue at the moment with the Shanghai lockdown. No one expects it lasted so long and still ongoing. The problem is not from us, but the others like glass and hardware suppliers and powdercoating factory. We are working with our team to find out ways and estimate the impact now.We should be able to get back to you after the Easter holiday. Enjoy the holiday and speak to you soon after! ribloc drians at 6m lengths",
    assignee: "BS",
    pinned: false,
    status: "I",
    timeStamp: "24.03.2021",
    section: "projects",
  },
  {
    mailId: "2",
    subject: "Peter project",
    body: "Supply and install here's a bit issue at the moment with the Shanghai lockdown. No one expects it lasted so long and still ongoing. The problem is not from us, but the others like glass and hardware suppliers and powdercoating factory. We are working with our team to find out ways and estimate the impact now.We should be able to get back to you after the Easter holiday. Enjoy the holiday and speak to you soon after! ribloc drians at 6m lengths",
    assignee: "BS",
    pinned: true,
    status: "I",
    timeStamp: "24.03.2021",
    section: "projects",
  },
  {
    mailId: "3",
    subject: "Peter 1231123",
    body: "Supply and install here's a bit issue at the moment with the Shanghai lockdown. No one expects it lasted so long and still ongoing. The problem is not from us, but the others like glass and hardware suppliers and powdercoating factory. We are working with our team to find out ways and estimate the impact now.We should be able to get back to you after the Easter holiday. Enjoy the holiday and speak to you soon after! ribloc drians at 6m lengths",
    assignee: "BS",
    pinned: false,
    status: "I",
    timeStamp: "24.03.2021",
    section: "projects",
  },
  {
    mailId: "4",
    subject: "Fix bathroom",
    body: "Delivery is coming this friday now, please be ready on site . I will contact Maju if there are any issues and notify me if there is any issues on site, so I can cancel the order",
    assignee: "MJ",
    pinned: true,
    status: "W",
    timeStamp: "27.03.2021",
    section: "maintenance",
  },
  {
    mailId: "5",
    subject: "Fix floor",
    body: "Delivery is coming this friday now, please be ready on site . I will contact Maju if there are any issues and notify me if there is any issues on site, so I can cancel the order",
    assignee: "MJ",
    pinned: true,
    status: "W",
    timeStamp: "27.03.2021",
    section: "maintenance",
  },
  {
    mailId: "6",
    subject: "Your Cleanaway Invoice",
    body: "Your latest Cleanaway invoice is now available. Click on the link to view and/or download your invoice. Alternatively open the attachment. Please ensure payment is received by the due date.",
    assignee: "MJ",
    pinned: false,
    status: "N",
    timeStamp: "29.04.2021",
    section: "invoices",
  },
  {
    mailId: "7",
    subject: "Adbri Masonry Pty Ltd",
    body: "Your latest Cleanaway invoice is now available. Click on the link to view and/or download your invoice. Alternatively open the attachment. Please ensure payment is received by the due date.",
    assignee: "MJ",
    pinned: false,
    status: "N",
    timeStamp: "29.04.2021",
    section: "invoices",
  },
];

const MailItem = [];
const Mailroom: React.FC<props> = ({ company }) => {
  const unmounted = useMountStatus();
  const [currentMail, setcurrentMail] = useState<Mail | undefined>(
    mailroomDummyData[0]
  );

  return (
    <Box className="rootContainer">
      {/*Side Menu Section */}
      {/* <Box className="sideMenuContainer">
        <MailroomSideMenu
          mailroomData={mailroomDummyData}
          setcurrentMail={setcurrentMail}
        />
      </Box> */}

      {/*Main Mailroom View Section */}
      <Box className="mailroomMainViewContainer">
        <MailroomMainView selectedMail={currentMail} />
      </Box>
    </Box>
  );
};
export default Mailroom;
