import axios from "axios"
import { Company } from '../../Models/company';
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";
import dummy from './dummyData';


const getList = async ()  => {
    let result: Company[] = []
    if (offline){ return dummy.companyListResponse.results; }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        const response = await axios.get(
            baseAddress+"/company/",
            {
                headers: { Authorization: "Bearer " + accessToken },
                params: {ordering: "name"}
            }
        )
        result = response.data.results as Array<Company>;
        console.log("COMPANIES", result)
    } catch (error) {
        console.error("ERROR FETCHING COMPANIES", error)
    }
    return result
}

const getDetailed = async (companyId: string)  => {
  // Gets one project only using the project's UUID
  // At this stage there is no additional information from the detailed
  // Future back end change will mean this call will get coordinates, users, ?costcodes?
  let result: Company | undefined = undefined;
  if (offline){ return dummy.companyDetailResponse; }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  try {
    const response = await axios.get(
      baseAddress+"/company/detail/"+companyId+"/",
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    )
    result = response.data.results as Company;
    console.log("PROJECT", result)
  } catch (error) {
      console.error("ERROR FETCHING PROJECT", error)
  }
  return result
}

  interface UpdateBody {  
    name?: string, 
    phone?: string, 
    email?: string, 
    companyAbn?: string,
    addressL1?: string,
    addressL2?: string,
    addressCity?: string,
    addressPostCode?: string,
    addressState?: string,
    addressCountry?: string,
    logo?: string,
  }

const update = async (companyId: string, {
name,
phone,
email,
companyAbn,
addressL1,
addressL2,
addressCity,
addressPostCode,
addressState,
addressCountry,
logo,
}: UpdateBody)  => {
    // Update one or more property of a company without affecting other values
    let result: Company | undefined = undefined;
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
      let body: UpdateBody = {}
      if (name) {body.name = name;}
      if (phone) {body.phone = phone;}
      if (email) {body.email = email;}
      if (companyAbn) {body.companyAbn = companyAbn;}
      if (addressL1) {body.addressL1 = addressL1;}
      if (addressL2) {body.addressL2 = addressL2;}
      if (addressCity) {body.addressCity = addressCity;}
      if (addressPostCode) {body.addressPostCode = addressPostCode;}
      if (addressState) {body.addressState = addressState;}
      if (addressCountry) {body.addressCountry = addressCountry;}
      if (logo) {body.logo = logo;}
      console.log(body);
      const response = await axios.patch(
        baseAddress+"/company/"+companyId+"/",
        body,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
      )
      result = response.data.results as Company;
      console.log("UPDATED COMPANY", result)
    } catch (error) {
        console.error("ERROR UPDATING COMPANY", error)
    }
    return result
}

const CompanyAPI = {
  getList,
  getDetailed,
  update,
};

export default CompanyAPI
