import React from 'react'

type Props = {
  isLinked: boolean
}

const ButtonLinked = ({ isLinked }: Props) => {
  return (
    <div
      style={{
        width: '98px',
        height: '23px',
        backgroundColor: isLinked ? '#7949FF' : 'red',
        borderRadius: '9px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'IBM Plex Mono',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '17px',
        color: '#FFFFFF',
      }}
    >
      {isLinked ? 'Linked' : 'Not Linked'}
    </div>
  )
}

export default ButtonLinked
