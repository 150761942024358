import React, { useEffect, useState } from "react";
import { Company } from "../../../Models/company";
import Loading from "../../../utilities/Lottie/Loading";
import { Stack, Typography, Box } from "@mui/material";
import "./TimeTable.css";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ReactComponent as DoxleLogoDog } from "../../../Assets/doxleLogoDog.svg";
import { ReactComponent as DropDownGreenIcon } from "../../../Assets/DropDownGreen.svg";
import { ReactComponent as DropDownWhiteIcon } from "../../../Assets/DropDownWhite.svg";

import MyView from "./MyView/MyView";
import NoticeAPI from "../../../Services/DoxleAPI/noticeAPI";
import UserAPI from "../../../Services/DoxleAPI/userAPI";
import { User } from "../../../Models/user";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import TeamView from "./TeamView/TeamView";
import titleCase from "../../../utilities/Helper/titleCase";
import { IUserWithTask, TDailySelect } from "./CommonResources";
import {NoticeCategory} from "../../../Models/notice";

interface props {
  company: Company | undefined;
  noticeViewMode: "list" | "table" | "board";
  setNoticeViewMode: Function;
  categoryList: NoticeCategory[];
  setCategoryList: Function;
  noticeCategoryFilter: NoticeCategory | null;
  setNoticeCategoryFilter: Function;
}

const TimeTable: React.FC<props> = ({
  company,
  noticeViewMode,
  setNoticeViewMode,
  categoryList,
  setCategoryList,
  noticeCategoryFilter,
  setNoticeCategoryFilter,
}) => {
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, user } = authContext;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const [categorySelect, setCategorySelect] = React.useState<string>("Doxle");
  // const [teamSelect, setTeamSelect] = React.useState<"Team View" | "My View">(
  //   "Team View"
  // );

  const [currentSelectedUser, setCurrentSelectedUser] = useState<
    IUserWithTask | undefined
  >(undefined);
  const [dailySelect, setDailySelect] =
    React.useState<TDailySelect>("Daily View");

  const handleChangeCategorySelect = (event: SelectChangeEvent) => {
    console.log("VALUE:", event.target.value);
    if (event.target.value === "All Categories") setNoticeCategoryFilter(null);
    else setNoticeCategoryFilter(event.target.value as string);
  };
  // const handleChangeTeamSelect = (event: SelectChangeEvent) => {
  //   setTeamSelect(event.target.value as string);
  // };
  const handleChangeDailySelect = (event: SelectChangeEvent) => {
    setDailySelect(event.target.value as TDailySelect);
  };

  //#################Fetch UserList#####################
  const [userList, setUserList] = useState<User[] | undefined>(undefined);
  const fetchUsers = async () => {
    if (company) {
      //console.log('FETCH USER')
      try {
        const response = await UserAPI.getList(company.companyId);
        //console.log(response)
        if (response.length > 0) setUserList([...response]);
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    }
  };
  //fetch user list only in initial render
  useEffect(() => {
    fetchUsers();
  }, [company]);

  //#########################Control Notice Board View Mode####################
  const navigate = useNavigate();
  // const [noticeViewMode, setNoticeViewMode] = useState<
  //   "list" | "table" | "board"
  // >("table");

  const handleChangeNoticeView = (value: string) => {
    if (value === "list" || value === "table" || value === "board") {
      setNoticeViewMode(value);
      localStorage.setItem("currentNoticeViewMode", value);
    }
  };

  // useEffect(() => {
  //   if (noticeViewMode === "list") navigate("/NoticeBoard");
  // }, [noticeViewMode]);
  return (
    <div
      style={{
        height: "calc(100% - 6px)",
        width: "92%",
        padding: "3px 4% 3px 4%",
        margin: "0",
        //background: "#D5D7E3",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "768px",
      }}
    >
      <Box
        sx={{
          m: 0,
          p: 0,
          width: "100%",
          height: "100%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        {/*TOP MENU */}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems={"center"}
          padding="0px 20px 0px 0px"
          className="top-menu-timetable"
        >
          {/* <Stack
            direction="column"
            sx={{
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Stack direction="row" gap="10px">
              <DoxleLogoDog />
              <Stack
                direction="column"
                alignItems="center"
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "300",
                }}
              >
                <div>
                  <Typography
                    sx={{
                      color: "#7949FF",
                      fontFamily: "Nostromo Regular",
                      fontSize: "21px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: 1,
                    }}
                  >
                    TIME TABLE
                  </Typography>
                </div>
                <div>
                  <Typography
                    sx={{
                      color: "#9F9AAA",
                      fontFamily: "Nostromo Regular",
                      fontSize: "9px",
                      fontStyle: "normal",
                      fontWeight: "500",
                    }}
                  >
                    VERSION 1.0
                  </Typography>
                </div>

                <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={categorySelect}
                  onChange={handleChangeCategorySelect}
                  IconComponent={DropDownWhiteIcon}
                  sx={{
                    alignSelf: "flex-start",
                    marginTop: "17px",
                    background: "#A192FD",
                    fontFamily: "Roboto Mono",
                    fontStyle: "normal",
                    fontWeight: "400",
                    color: "white",
                    border: "none",
                    height: "22px",
                    width: "114px",
                    "& fieldset": {
                      border: "none",
                    },
                    borderRadius: "7px",
                    "& .MuiSelect-icon": {
                      top: "calc(50% - 0.2em)",
                    },
                  }}
                >
                  <MenuItem value={"All Categories"}>All Categories</MenuItem>
                  <MenuItem value={"Doxle"}>Doxle</MenuItem>
                </Select>
              </Stack>
            </Stack>
          </Stack> */}

          {/*Category section */}
          <Box
            sx={{
              height: "100%",
              width: "calc(50% - 10px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              paddingLeft: "10px",
            }}
          >
            <Typography
              sx={{
                color: "#7949FF",
                fontFamily: "Nostromo Regular",
                fontSize: "21px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: 1,
              }}
            >
              TIME TABLE
            </Typography>

            <Select
              labelId="category-select-label"
              id="category-select"
              value={
                noticeCategoryFilter?.categoryTitle ? noticeCategoryFilter.categoryTitle : "All Categories"
              }
              onChange={handleChangeCategorySelect}
              IconComponent={DropDownWhiteIcon}
              sx={{
                alignSelf: "flex-start",
                marginTop: "8px",
                background: "#A192FD",
                fontFamily: "IBM Plex Sans",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "18px",
                color: "white",
                border: "none",
                height: "22px",
                width: "auto",
                marginLeft: "10px",
                "& fieldset": {
                  border: "none",
                },
                borderRadius: "7px",
                "& .MuiSelect-icon": {
                  top: "calc(50% - 0.2em)",
                },
                boxShadow: "0px 5px 5px -3px rgb(0 0 0 / 20%)",
              }}
            >
              {categoryList.map((item, index) => (
                <MenuItem
                  key={item.noticeCategoryId}
                  value={item.categoryTitle}
                  style={{
                    fontFamily: "IBM Plex Mono",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "16px",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                >
                  {item.noticeCategoryId !== noticeCategoryFilter?.noticeCategoryId ? `${index + 1}) ` : null}
                  {titleCase(item.categoryTitle)}
                </MenuItem>
              ))}
              <MenuItem
                value={"All Categories"}
                style={{
                  fontFamily: "IBM Plex Mono",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "16px",
                }}
              >
                All Categories
              </MenuItem>
            </Select>
          </Box>

          {/*Selection for Time Table View */}
          <Box
            sx={{
              minWidth: 120,
              width: "50%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",

              height: "100%",
            }}
          >
            {/* <Select
              labelId="team-view-select-label"
              id="team-view-select"
              value={teamSelect}
              onChange={handleChangeTeamSelect}
              IconComponent={DropDownGreenIcon}
              sx={{
                background: "#F3F1FC",
                fontFamily: "Nostromo Regular",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "10px",
                lineHeight: "14px",
                border: "none",
                color: "#226622",
                height: "35px",
                marginRight: "27px",
                "& fieldset": {
                  border: "none",
                },
                borderRadius: "7px",
                "& .MuiSelect-icon": {
                  top: "calc(50% - 0.2em)",
                },
              }}
            >
              <MenuItem value={"Team View"}>Team View</MenuItem>
              <MenuItem value={"My View"}>My View</MenuItem>
            </Select> */}
            {/* Notice View Control */}

            <Select
              labelId="daily-view-select-label"
              id="daily-view-select"
              IconComponent={DropDownGreenIcon}
              value={noticeViewMode}
              onChange={(value) => handleChangeNoticeView(value.target.value)}
              sx={{
                background: "#F3F1FC",
                fontFamily: "IBM Plex Sans",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "10px",
                lineHeight: "14px",
                minWidth: "87px",
                marginRight: "27px",
                height: "35px",
                color: "#A282FC",
                // width: "114px",
                "& fieldset": {
                  border: "none",
                },
                "& .MuiSelect-icon": {
                  top: "calc(50% - 0.4em)",
                },
                borderRadius: "7px",

                textTransform: "capitalize",
              }}
            >
              <MenuItem
                value={"list"}
                sx={{
                  fontFamily: "IBM Plex Sans",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "14px",
                  height: "30px",
                  paddingLeft: "5px !important",
                  paddingRight: "5px !important",
                  borderRadius: "5px",

                  marginBottom: "5px",
                  color: noticeViewMode === "list" ? "#7949FF" : "black",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "capitalize",
                }}
              >
                List View
              </MenuItem>
              <MenuItem
                value={"table"}
                sx={{
                  fontFamily: "IBM Plex Sans",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "14px",
                  height: "30px",
                  paddingLeft: "5px !important",
                  paddingRight: "5px !important",
                  borderRadius: "5px",

                  marginBottom: "5px",
                  color: noticeViewMode === "table" ? "#7949FF" : "black",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "capitalize",
                }}
              >
                Table View
              </MenuItem>
              <MenuItem
                value={"board"}
                sx={{
                  fontFamily: "IBM Plex Sans",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "14px",
                  height: "30px",
                  paddingLeft: "5px !important",
                  paddingRight: "5px !important",
                  borderRadius: "5px",

                  marginBottom: "5px",
                  color: noticeViewMode === "board" ? "#7949FF" : "black",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "capitalize",
                }}
              >
                Board View
              </MenuItem>
            </Select>

            <Select
              labelId="daily-view-select-label"
              id="daily-view-select"
              value={dailySelect}
              onChange={handleChangeDailySelect}
              IconComponent={DropDownGreenIcon}
              sx={{
                background: "#F3F1FC",
                fontFamily: "IBM Plex Sans",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "10px",
                lineHeight: "14px",
                minWidth: "87px",
                height: "35px",
                color: "#A282FC",
                // width: "114px",
                "& fieldset": {
                  border: "none",
                },
                "& .MuiSelect-icon": {
                  top: "calc(50% - 0.4em)",
                },
                borderRadius: "7px",

                textTransform: "capitalize",
              }}
            >
              <MenuItem
                sx={{
                  fontFamily: "IBM Plex Sans",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "14px",
                  height: "30px",
                  paddingLeft: "5px !important",
                  paddingRight: "5px !important",
                  borderRadius: "5px",

                  marginBottom: "5px",
                  color: dailySelect === "Daily View" ? "#7949FF" : "black",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "capitalize",
                }}
                value={"Daily View"}
              >
                Daily View
              </MenuItem>
              <MenuItem
                sx={{
                  fontFamily: "IBM Plex Sans",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "14px",
                  height: "30px",
                  paddingLeft: "5px !important",
                  paddingRight: "5px !important",
                  borderRadius: "5px",

                  marginBottom: "5px",
                  color: dailySelect === "Weekly View" ? "#7949FF" : "black",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  textTransform: "capitalize",
                }}
                value={"Weekly View"}
              >
                Weekly View
              </MenuItem>
            </Select>
          </Box>

          {/* <Select
            labelId="category-select-label"
            id="category-select"
            value={categorySelect}
            onChange={handleChangeCategorySelect}
            IconComponent={DropDownWhiteIcon}
            sx={{
              alignSelf: "flex-start",

              background: "#A192FD",
              fontFamily: "Roboto Mono",
              fontStyle: "normal",
              fontWeight: "400",
              color: "white",
              border: "none",
              height: "22px",
              width: "114px",
              "& fieldset": {
                border: "none",
              },
              borderRadius: "7px",
              "& .MuiSelect-icon": {
                top: "calc(50% - 0.2em)",
              },
            }}
          >
            <MenuItem value={"All Categories"}>All Categories</MenuItem>
            <MenuItem value={"Doxle"}>Doxle</MenuItem>
          </Select> */}
        </Stack>

        {/*DATA DISPLAY SECTION */}
        {!currentSelectedUser ? (
          userList ? (
            <TeamView
              dailySelect={dailySelect}
              viewType={
                dailySelect.toLowerCase() === "daily view" ? "daily" : "weekly"
              }
              company={company}
              userList={userList}
              categorySelect={noticeCategoryFilter}
              setCurrentSelectedUser={setCurrentSelectedUser}
            />
          ) : null
        ) : (
          <MyView
            dailySelect={dailySelect}
            currentSelectedUser={currentSelectedUser}
            setCurrentSelectedUser={setCurrentSelectedUser}
          />
        )}
      </Box>
    </div>
  );
};
export default TimeTable;
