import React from 'react';
import '../ErrorPages/ErrorPage.css';

const ErrorPage: React.FC = () => {

    return(
        <h1>Error 404</h1>
    )
}

export default ErrorPage
