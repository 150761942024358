import React, { useState, useEffect } from "react";
import "../Notices/Notices.css";
import { Grid, IconButton} from "@mui/material";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import {Checklist} from "../../../Models/checklist";
import ChecklistAPI from "../../../Services/DoxleAPI/checklistAPI";
import checklistAPI from "../../../Services/DoxleAPI/checklistAPI";
import {ReactComponent as TrashIcon} from "../../../Assets/Trash2.svg";
import {ReactComponent as EditIcon} from "../../../Assets/editIcon3.svg";
import {ReactComponent as CheckIcon} from "../../../Assets/CheckFill.svg";
import {ReactComponent as UncheckIcon} from "../../../Assets/ChekOpen.svg";
import titleCase from "../../../utilities/Helper/titleCase"
interface props {
    checklistItem: Checklist;
    deleteChecklistItem: Function
}




const ChecklistItem: React.FC<props> = ({
                                            checklistItem,
                                            deleteChecklistItem
                                        }) => {
    const [hovering, setHovering] = useState<boolean>(false);
    const [editState, setEditState] = useState<boolean>(false);
    const [question, setQuestion] = useState<string>(checklistItem.question);
    const [answer, setAnswer] = useState<boolean>(checklistItem.answer === "YES");
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;

    const handleTickChange = async (event: any, checklistId: string) => {
        try {
            if (!user?.userId) return;
            const updateAnswer = answer ? null : "YES";
            const response = await ChecklistAPI.updateChecklist(checklistId,{answer: updateAnswer}, user.userId ) as Checklist;
            if (response.answer === "YES") setAnswer(true)
            else setAnswer(false)
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    const handleDoubleClick = (e:any) => {
        setEditState(true)
    }

    const handleQuestionChange = (e:any) =>{
        setQuestion(e.target.value)
    }

    const handleQuestionUpdate = async (checklistId:string|undefined) =>{
        try {
            if (!user?.userId) return;
            if (!checklistId) return;
            const response = await checklistAPI.updateChecklist(
                checklistId,
                {question: question,},
                user.userId
            )
            setEditState(false)
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    const handleQuestionKeyUp = async (e: any, checklistId:string|undefined) =>{
        if (e.keyCode !== 13) return;
        if (!checklistId) return;
        handleQuestionUpdate(checklistId)
    }

    useEffect(() => {
    },[]);

    return (
        <Grid
            key={checklistItem.checklistId}
            container
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            sx={{
                minHeight: "35px",
                background: "#F1F2F5",
                borderRadius: "5px",
                my: '2px',
            }}
            alignItems="center"
        >
            <Grid item xs={1} display="flex" justifyContent="center" height="100%" alignItems="center">
                <IconButton
                    disableRipple
                    sx={{
                        "&:hover": {background: "none"},
                        m: 0, p: 0, paddingLeft: "16px",
                        width: "100%", height: "16px", 
                        transform: "scale(1.6)"
                    }}
                    onClick={(event: any) => handleTickChange(event, checklistItem.checklistId || "")}
                >
                    {answer ? (
                        <CheckIcon />
                    ) : (
                        <UncheckIcon />
                    )}
                </IconButton>
            </Grid>
            <Grid item xs={10} onDoubleClick={handleDoubleClick} 
                sx={{
                    color: "#000000",
                    fontFamily: 'Roboto Mono',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    fontSize: "11px",
                    lineHeight: '13px',
                    py: '5px'
                }}
                onBlur={() => setEditState(false)}
            >
            {
                !editState
                    ?
                    <>{titleCase(question)}</>
                    :
                    <TextareaAutosize
                        autoFocus
                        value={question}
                        onChange={handleQuestionChange}
                        onKeyUp={(event:any) => handleQuestionKeyUp(event, checklistItem.checklistId)}
                        onBlur={() => handleQuestionUpdate(checklistItem.checklistId)}
                        style={{
                            width:"100%",
                            textAlign: "left",
                            color: "#000000",
                            fontFamily: 'Roboto Mono',
                            fontStyle: 'normal',
                            fontWeight: 300,
                            fontSize: "11px",
                            letterSpacing: "normal",
                            lineHeight: '13px',                
                            padding: 0,
                            margin: 0,
                            border:"none",
                            outline:"none",
                            background:"none",
                        }}
                    />
            }
            </Grid>
            <Grid item xs={1}>
                {hovering && !editState &&
                    <EditIcon
                        onClick={() => setEditState(true)}
                        style={{cursor: "pointer", marginTop: "2px", color: "#6d4dc5"}}
                    />
                }
                {hovering && !editState &&
                    <TrashIcon
                        onClick={() => deleteChecklistItem(checklistItem.checklistId)}
                        style={{cursor: "pointer", marginTop: "2px"}}
                    />
                }
            </Grid>
        </Grid>
    );
};

export default ChecklistItem;