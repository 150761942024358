import React, { useState, useEffect, useCallback, createContext } from "react";
import { Company } from "../../../Models/company";
import { Notice, NoticeCategory } from "../../../Models/notice";
import { User } from "../../../Models/user";
import NoticeAPI, {
  GetListParams,
  NoticeResponse,
} from "../../../Services/DoxleAPI/noticeAPI";
import {
  IconButton,
  Box,
  Tabs,
  Tab,
  Breadcrumbs,
  Link,
  Typography,
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  DialogTitle,
  DialogContent,
  Dialog,
  CircularProgress,
  TextField,
  Input,
  DialogActions,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Chip,
} from "@mui/material";
import { useStyles } from "./noticesStyle";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import EditNotice from "../Notices/EditNotice";
import AddNotice from "../Notices/AddNotice";
import { ReactComponent as TrashIcon } from "../../../Assets/Trash.svg";
import noticeAPI from "../../../Services/DoxleAPI/noticeAPI";
import UserAPI from "../../../Services/DoxleAPI/userAPI";
import Loading from "../../../utilities/Lottie/Loading";
import CategoryNavigation from "../../Menu/CategoryNavigation";
import FilterNavigation from "../../Menu/FilterNavigation";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import searchIcon from "../../../Assets/searchBlue.svg";
import NoticesDetails from "./NoticesDetails";
import NoticeRow from "./NoticeRow";
import AutocompleteCustom from "../../../utilities/Autocomplete/Autocomplete";
import CustomizedCircularProgress from "../../../utilities/Progress/CustomizedCircularProgress";
import { ColumnView } from "../../../Models/columnView";
import { useSocket } from "../../../Services/Sockets/useSocket";
import Notifications from "../Notifications/Notifications";
import { useNavigate, useParams } from "react-router-dom";
import { Pricelist } from "../../../Models/pricelist";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminTable from "../AdminTable/AdminTable";
import TimeTable from "../TimeTable/TimeTable";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

interface IconStlye {
  color: string;
  cursor: string;
}
interface props {
  company: Company | undefined;
}

type TNoticeViewMode = "list" | "table" | "board";

export interface INoticesContext {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal: Function;
  userList: User[];
  categoryList: NoticeCategory[];
  setCategoryList: React.Dispatch<React.SetStateAction<NoticeCategory[]>>;
  notices: Notice[];
  setNotices: React.Dispatch<React.SetStateAction<Notice[]>>;
  forceUpdate: Function;
  noticeViewMode: TNoticeViewMode;
  setNoticeViewMode: React.Dispatch<React.SetStateAction<TNoticeViewMode>>;
}

export const NoticesContext = createContext({});

const getInitialDateFilter = () => {
  const sessionStorageValue = sessionStorage.getItem("noticePeriodFilter");
  if (
    sessionStorageValue === "today" ||
    sessionStorageValue === "week" ||
    sessionStorageValue === "2week" ||
    sessionStorageValue === "month"
  )
    return sessionStorageValue;
  return null;
};

const Notices: React.FC<props> = ({ company }) => {
  const initialTabValue = sessionStorage.getItem("noticeTabValue");
  const [tabValue, setTabValue] = useState<string>(
    initialTabValue ? initialTabValue : "active"
  );
  const [fetchPage, setFetchPage] = useState<number | null>(1);
  const [notices, setNotices] = useState<Array<Notice>>([]);
  const [userList, setUserList] = useState<Array<User>>([]);
  const [descriptionColour, setDescriptionColour] = useState<string>();
  const [tickedNotices, setTickedNotices] = useState<string[]>([]);
  const [categoryList, setCategoryList] = useState<NoticeCategory[]>([]);
  const categoryFilterStr = sessionStorage.getItem("noticeCategoryFilter");
  const [noticeCategoryFilter, setNoticeCategoryFilter] =
    useState<NoticeCategory | null>(
      categoryFilterStr
        ? (JSON.parse(categoryFilterStr) as NoticeCategory)
        : null
    );
  const [noticePeriodFilter, setNoticePeriodFilter] = useState<
    "today" | "week" | "2week" | "month" | null
  >(getInitialDateFilter());
  const [deleteStyle, setDeleteStyle] = useState<IconStlye>({
    color: "#FFF",
    cursor: "default",
  });
  const unmounted = useMountStatus();
  const [loading, setLoading] = useState<boolean>(false);
  const [circleLoading, setCircleLoading] = useState<boolean>(true);
  const { height, width } = WindowDimensions();
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, user } = authContext;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedNotice, setSelectedNotice] = useState<Notice | object>({});
  const [reLoad, setReLoad] = useState<boolean>(false);
  const [noticeTab, setNoticeTab] = useState<string>("1");
  const [action, setAction] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [newCategory, setNewCategory] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string | null>(null);
  const [columnView, setColumnView] = useState<ColumnView>({
    assignee: true,
    startDate: true,
    endDate: true,
  });
  const sessionUserFilterStr = sessionStorage.getItem("noticeUserFilter");
  const [userFilter, setUserFilter] = React.useState<string[]>(
    sessionUserFilterStr ? (JSON.parse(sessionUserFilterStr) as string[]) : []
  );
  const [selectedCategoryForDelete, setselectedCategoryForDelete] = useState<
    string | null
  >(null);
  const [noticeViewMode, setNoticeViewMode] = useState<TNoticeViewMode>(
    // localStorage.getItem("currentNoticeViewMode")
    //   ? (localStorage.getItem("currentNoticeViewMode") as TNoticeViewMode)
    //   :
    "list"
  );
  const navigate = useNavigate();
  const allParams = useParams();
  const [goToNoticeId, goToTab] = allParams["*"]?.split("/") || [];


  const handleFilterUserClick = (userId: string) => {
    if (userFilter.includes(userId)) {
      setUserFilter((users) => users.filter((user) => user !== userId));
    } else {
      setUserFilter((users) => [...users, userId]);
    }
  };

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    ///fetch notice when switch between active and archive tab
    // if (
    //   (newValue === "active" && tabValue === "archive") ||
    //   (newValue === "archive" && tabValue === "active")
    // ) {
    //   fetchNotices(1, "all");
    //   setNoticeCategoryFilter(null);
    // }
    setTabValue(newValue);
  };

  const addNewNotice = (newNotice: Notice) => {
    let updatedNotices: Notice[] = [...notices, newNotice];
    setNotices(updatedNotices);
  };
  const handleSelectCategory = (category: NoticeCategory | null) => {
    // setCircleLoading(true);
    setNoticeCategoryFilter(category);
  };

  const isThisPeriod = (
    notice: Notice,
    filter: "today" | "week" | "2week" | "month"
  ) => {
    const date = new Date();
    const dateToday = `${date.getFullYear()}-${(
      "0" +
      date.getMonth() +
      1
    ).slice(-2)}-${date.getDate()}`;
    const dueToday: boolean = notice.dueDate?.substring(0, 10)
      ? dateToday === notice.dueDate?.substring(0, 10)
      : false;

    const dueThisMonth: boolean = notice.dueDate
      ? new Date(notice.dueDate).getMonth() === date.getMonth()
      : false;

    const end = notice.dueDate
      ? new Date(notice.dueDate || "").getTime()
      : date.getTime();
    const endsThisWeek = notice.dueDate
      ? end - date.getTime() > 0 &&
        end - date.getTime() < 1000 * 60 * 60 * 24 * 7
      : false;
    const endsThisFortnight = notice.dueDate
      ? end - date.getTime() > 0 &&
        end - date.getTime() < 1000 * 60 * 60 * 24 * 14
      : false;

    return (
      (filter === "today" && dueToday) ||
      (filter === "week" && (endsThisWeek || dueToday)) ||
      (filter === "2week" && (endsThisFortnight || dueToday)) ||
      (filter === "month" && dueThisMonth)
    );
  };

  const handleDelete = async () => {
    try {
      if (tickedNotices.length > 0) {
        let newNotices: Notice[] = [];
        for (let n = 0; n < notices.length; n++) {
          if (tickedNotices.indexOf(notices[n].noticeId) === -1) {
            newNotices.push(notices[n]);
            continue;
          }
          if (
            notices[n].category === "ARCHIVED" &&
            !((await noticeAPI.remove(notices[n].noticeId)) as boolean)
          ) {
            newNotices.push(notices[n]);
            continue;
          }
          if (notices[n].category !== "ARCHIVED") {
            const response = (await noticeAPI.update(
              notices[n].noticeId,
              user,
              { category: "ARCHIVED" }
            )) as Notice;
            newNotices.push(response);
            continue;
          }
        }
        setNotices([...newNotices]);
        setTickedNotices([]);
      }
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const breadcrumbs = [
    <Link
      key="1"
      color="inherit"
      href="/company"
      onClick={handleClick}
      underline="none"
      display={"none"}
    >
      {company ? company.name : "Byson Group"}
    </Link>,
    <Typography
      key="2"
      sx={{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "16px",
        paddingBottom: "0px",
        paddingLeft: "30px",
        color: "#96A2BE",
      }}
    >
      Doxle.
      <span style={{ color: "#1521B6" }}>Noticeboard</span>
    </Typography>,
  ];

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !circleLoading && fetchPage) fetchNotices(fetchPage);
  };

  const populateEmptyRows = () => {
    let rows = Math.round(
      Math.max(5, window.innerHeight / 33 - notices.length)
    );
    let count = 0;
    let row = [];
    while (count < rows) {
      row.push([
        <TableRow className="tableRowStyle" key={count}>
          <AddNotice
            company={company}
            categoryList={categoryList}
            action="row"
            addNewNotice={addNewNotice}
            notices={notices}
            setNotices={setNotices}
            noticeCategoryFilter={noticeCategoryFilter}
            noticePeriodFilter={noticePeriodFilter}
            tabValue={tabValue}
            columnView={columnView}
            userFilter={userFilter}
          />
        </TableRow>,
      ]);
      count++;
    }

    return row;
  };

  const handleCheckBoxClick = (e: any, noticeId: string) => {
    if (e.target && typeof e.target.checked !== "undefined") {
      let notices = tickedNotices;
      const i = notices.indexOf(noticeId);
      if (e.target.checked) {
        if (i === -1) {
          notices.push(noticeId);
        }
      } else {
        if (i !== -1) {
          notices.splice(i, 1);
        }
      }
      setTickedNotices([...notices]);
    }
  };

  const handleRowClick = (notice: Notice, tab: string) => {
    setSelectedNotice(notice);
    tab ? setNoticeTab(tab) : setNoticeTab("Notice");
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    navigate("/NoticeBoard");
  };

  const fetchNotices = async (page: number, data = "all") => {
    // console.log("fetchNotices...", company);
    setCircleLoading(true);
    const archived =
      data === "archive" || tabValue === "archive" ? true : false;
    // console.log("archived?", archived);
    if (!company)
      company = JSON.parse(localStorage.getItem("currentCompany") || "{}");
    if (!company) return;
    try {
      let params: GetListParams = {
        company: company.companyId,
        ordering: "time_stamp",
        page: page,
        page_size: 40,
        is_archived: archived,
        category_id__is_permit: "false",
      };
      if (userFilter.length > 0) params.assigned_users = userFilter.toString();
      if (tabValue === "assigned" && user?.userId)
        params.assigned_users = params.assigned_users
          ? params.assigned_users + "," + user.userId
          : user.userId;
      if (noticeCategoryFilter?.noticeCategoryId)
        params.category_id = noticeCategoryFilter.noticeCategoryId;
      if (tabValue === "self" && user?.userId) params.creator = user.userId;
      if (statusFilter) params.status = statusFilter;
      if (noticePeriodFilter) params.due_by = noticePeriodFilter;
      const response = (await NoticeAPI.getList(params)) as NoticeResponse;
      if (unmounted) return;
      if (response.items.length > 0) {
        setNotices((currentList: Notice[]) => [
          ...currentList,
          ...response.items,
        ]);
        setFetchPage(response.nextPage);
        // console.log("NOTICE OF PAGE ", fetchPage, ":", response.items);

        // //extract category data
        // let tempList: string[] = [];
        // notices.concat(response.items).forEach((notice: Notice) => {
        //   //check if category is already in the list or not and should be case sensitive
        //   let isCateExist: boolean = tempList.some((item, index) => {
        //     if (notice.category?.toLowerCase() === item.toLowerCase())
        //       return true;
        //   });
        //   if (
        //     notice.category &&
        //     notice.isArchived === archived &&
        //     !isCateExist
        //   ) {
        //     tempList.push(notice.category);
        //   }
        // });
        // tempList.sort((a, b) => a.localeCompare(b));
        // setCategoryList([...tempList]);
      }
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
    setCircleLoading(false);
  };

  const forceUpdate = () => {
    setReLoad(true);
  };

  const fetchUsers = async () => {
    if (company) {
      try {
        const response = await UserAPI.getList(company.companyId);
        setUserList(response);
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    }
  };

  const fetchCategories = async () => {
    if (company) {
      try {
        const response = await NoticeAPI.getCategories(company.companyId);
        setCategoryList(response.filter((cat) => !cat.isPermit));
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    }
  };

  const handleAddCategory = () => {
    setAction("addNoticeCategory");
    setDialogOpen(true);
  };

  const handleDeleteCategory = (selectedCategory: string) => {
    setAction("deleteNoticeCategory");
    setselectedCategoryForDelete(selectedCategory);
    setDialogOpen(true);
  };

  const handleDeleteSingleNotice = (selectedNotice: Notice) => {
    setAction("deleteNotice");
    setSelectedNotice(selectedNotice);
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
    setAction("");
    setSelectedNotice({});
    setselectedCategoryForDelete(null);
  };

  const handleDialogSubmit = async () => {
    setCircleLoading(true);
    setDialogOpen(false);

    if (action === "addNoticeCategory") {
      const result = await NoticeAPI.addCategory({
        company: company?.companyId || "",
        noticeCategoryId: "",
        categoryTitle: newCategory,
        isArchived: false,
        isPermit: false,
        index: categoryList.length,
      });
      if (result) {
        setCategoryList((prevState) => [...prevState, result]);
        setNoticeCategoryFilter(result);
        setNewCategory("");
      }
    }
    if (action === "deleteNoticeCategory") {
      setNoticeCategoryFilter(null);
      setselectedCategoryForDelete(null);
      if (selectedCategoryForDelete) {
        const result = await NoticeAPI.editCategory(
          selectedCategoryForDelete,
          undefined,
          true
        );
      }
      setCategoryList([
        ...categoryList.filter(
          (category) => category.noticeCategoryId !== selectedCategoryForDelete
        ),
      ]);
      let filteredNotice: Notice[] = notices.filter(
        (notice: Notice) =>
          notice.category?.toLowerCase() ===
          selectedCategoryForDelete?.toLowerCase()
      );
      for (const notice of filteredNotice) {
        await handleUpdateNotice(notice.noticeId, true);
      }
      forceUpdate();
    }

    if (action === "deleteNotice") {
      setAction("");
      setSelectedNotice({});

      handleDeleteNotice((selectedNotice as Notice).noticeId);
    }
    setCircleLoading(false);
  };
  const handleUpdateNotice = async (noticeId: string, isArchived?: boolean) => {
    try {
      if (isArchived) {
        const response = await NoticeAPI.update(noticeId, user, {
          isArchived: isArchived,
        });
      }
    } catch (error) {
      error === "AccessTokenNotFound"
        ? setLoggedIn(false)
        : console.error(error);
    }
  };
  const handleDeleteNotice = async (noticeId: string) => {
    try {
      const response = await noticeAPI.remove(noticeId);
      if (response)
        setNotices([
          ...notices.filter((notice) => notice.noticeId !== noticeId),
        ]);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const handleEditInput = (event: any) => {
    setNewCategory(event.target.value);
  };

  // const filterbyUser = (notice: Notice) => {
  //   if (userFilter.length > 0) {
  //     let found: boolean = false;
  //     userFilter.forEach((selectedUser) => {
  //       if (
  //         user &&
  //         user.userId &&
  //         notice.assignedUsers &&
  //         notice.assignedUsers.findIndex((au) => au.userId === selectedUser) !==
  //           -1
  //       ) {
  //         found = true;
  //       }
  //     });
  //     return found;
  //   } else {
  //     return true;
  //   }
  // };

  useEffect(() => {
    if (tickedNotices.length > 0) {
      setDeleteStyle({ color: "#0044CC", cursor: "pointer" });
    } else {
      setDeleteStyle({ color: "#FFF", cursor: "default" });
    }
  }, [tickedNotices]);

  useEffect(() => {
    if (noticeCategoryFilter)
      sessionStorage.setItem(
        "noticeCategoryFilter",
        JSON.stringify(noticeCategoryFilter)
      );
    else sessionStorage.removeItem("noticeCategoryFilter");
  }, [noticeCategoryFilter]);

  useEffect(() => {
    if (noticePeriodFilter)
      sessionStorage.setItem("noticePeriodFilter", noticePeriodFilter);
    else sessionStorage.removeItem("noticePeriodFilter");
  }, [noticePeriodFilter]);

  useEffect(() => {
    if (tabValue) sessionStorage.setItem("noticeTabValue", tabValue);
    else sessionStorage.removeItem("noticeTabValue");
  }, [tabValue]);

  useEffect(() => {
    if (userFilter)
      sessionStorage.setItem("noticeUserFilter", JSON.stringify(userFilter));
    else sessionStorage.removeItem("noticeUserFilter");
  }, [userFilter]);

  useEffect(() => {
    if (statusFilter)
      sessionStorage.setItem("statusFilter", JSON.stringify(statusFilter));
    else sessionStorage.removeItem("statusFilter");
  }, [statusFilter]);

  useEffect(() => {
    fetchUsers();
    fetchCategories();
    setReLoad(false);
  }, [company, unmounted, reLoad]);

  useEffect(() => {
    setNotices([]);
    setFetchPage(null);
    fetchNotices(1);
  }, [tabValue, userFilter, statusFilter, noticeCategoryFilter]);

  //! <--- SOCKETS LISTENER
  const socket = useSocket();
  const onMessage = useCallback(
    (message) => {
      const data = JSON.parse(message?.data);

      const serverStatus: string = data.status;
      const messageType: string = data.message_type;

      if (messageType === "SocketDataUpdate") {
        if (serverStatus === "SUCCESS") {
          const { noticeId, status, assignedUsers, startDate, dueDate } =
            data.message;

          console.log(
            "%cDOXLE SOCKETS=RECEIVE SUCCESS DATA",
            "background:green; color:white"
          );

          console.log("assignedUsers", assignedUsers);

          let newNotices: Notice[] = notices;
          newNotices.forEach((notice: Notice) => {
            if (notice.noticeId === noticeId) {
              console.log("updating notice", noticeId);
              if (status) notice.status = status;
              if (assignedUsers) notice.assignedUsers = assignedUsers;
              if (startDate) notice.startDate = startDate;
              if (dueDate) notice.dueDate = dueDate;
              console.log("assignedUsers", assignedUsers);
            }
          });

          setNotices([...newNotices]);
        } else {
          console.log(
            "%cDOXLE SOCKETS=DATA ERROR",
            "background:red; color:white"
          );
        }
      }
    },
    [notices]
  );

  useEffect(() => {
    socket.addEventListener("message", onMessage);

    return () => {
      socket.removeEventListener("message", onMessage);
    };
  }, [socket, onMessage]);
  //! SOCKETS --->

  const getSingleNotice = async (noticeId: string, goToTab: string) => {
    const notice = await NoticeAPI.getDetailed(noticeId)
    if (notice) {
      if (goToTab) handleRowClick(notice, "Comments");
      else handleRowClick(notice, "Notice");
    }
  }


  useEffect(() => {
    // check params from url and go to specific details and tab
    let goToNotice: Notice|undefined = undefined
    if (goToNoticeId && notices.length > 0) {
      goToNotice = notices.filter(
        (notice: Notice) => notice.noticeId === goToNoticeId
      )?.[0];
      if (!goToNotice) {
        getSingleNotice(goToNoticeId, goToTab)
        return
      }
      if (goToTab) {
        handleRowClick(goToNotice, "Comments");
      } else {
        handleRowClick(goToNotice, "Notice");
      }
    }
  }, [notices, goToNoticeId, goToTab]);

  const handleChangeNoticeView = (value: string) => {
    if (value === "list" || value === "table" || value === "board") {
      setNoticeViewMode(value);
      localStorage.setItem("currentNoticeViewMode", value);
    }
  };

  // useEffect(() => {
  //   if (noticeViewMode === "table" || noticeViewMode === "board")
  //     navigate("/Timetable");
  // }, [noticeViewMode]);

  const noticesValue: INoticesContext = {
    loading,
    setLoading,
    openModal,
    setOpenModal,
    closeModal: handleClose,
    userList,
    categoryList,
    setCategoryList,
    notices,
    setNotices,
    forceUpdate,
    noticeViewMode,
    setNoticeViewMode,
  };

  if (noticeViewMode === "list") {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          background: "#D5D7E3",
          paddingLeft: "4%",
          paddingRight: "4%",
          boxSizing: "border-box",
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            <NoticesDetails
              selectedNotice={selectedNotice}
              selectedNoticeTab={noticeTab}
              openModal={openModal}
              closeModal={handleClose}
              height={height}
              width={width}
              userList={userList}
              categoryList={categoryList}
              setCategoryList={setCategoryList}
              notices={notices}
              setNotices={setNotices}
              forceUpdate={forceUpdate}
              company={company}
              noticeViewMode={noticeViewMode}
            />

            <div
              style={{
                paddingBottom: "3px",
                width: "100%",
              }}
            >
              <CategoryNavigation
                categories={categoryList}
                setCategoryList={setCategoryList}
                selectedCategory={noticeCategoryFilter}
                handleSelectCategory={handleSelectCategory}
                handleAddCategory={handleAddCategory}
                handleDeleteCategory={handleDeleteCategory}
              />
            </div>

            <div className="menuBar">
              <Box
                style={{
                  height: "32px",
                  paddingBottom: "0px ",
                  paddingTop: "0px",
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  style={{ height: "10px" }}
                  sx={{
                    minHeight: "32px",
                    background: "#F9F9FF",
                    "& .MuiButtonBase-root": {
                      minHeight: "32px",
                      borderRight: "1px solid #D6D9DF",
                      fontFamily: "IBM Plex Mono",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "11px",
                      lineHeight: "15px",
                      color: "#000000",
                    },
                    ".MuiButtonBase-root.MuiTab-root.Mui-selected": {
                      color: "#754EE0",
                    },
                    "& .MuiTabs-indicator": {
                      background: "none",
                    },
                  }}
                >
                  <Tab value="active" label="Live" sx={useStyles.noticeTabs} />
                  <Tab
                    value="assigned"
                    label="My Notices"
                    sx={useStyles.noticeTabs}
                  />
                  <Tab
                    value="self"
                    label="Created By"
                    sx={useStyles.noticeTabs}
                  />
                  <Tab
                    value="follow"
                    label="Follow Up"
                    sx={useStyles.noticeTabs}
                  />
                  <Tab
                    value="archive"
                    label="Archived"
                    sx={useStyles.noticeTabs}
                  />
                </Tabs>
              </Box>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  marginRight: "10px",
                }}
              >
                {/*VIEW CONTOL DROPDOWN */}
                <div>
                  <Select
                    labelId="daily-view-select-label"
                    id="daily-view-select"
                    value={noticeViewMode}
                    onChange={(value) =>
                      handleChangeNoticeView(value.target.value)
                    }
                    sx={{
                      background: "#E9EBEF",
                      fontFamily: "Nostromo Regular",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "10px",
                      lineHeight: "14px",
                      height: "20px",
                      color: "#7949FF",
                      // width: "114px",
                      "& fieldset": {
                        border: "none",
                      },
                      borderRadius: "5px",
                      p: "0 8px 0 8px",
                      textTransform: "uppercase",
                    }}
                    IconComponent={() => null}
                    inputProps={{ sx: { padding: "0 !important" } }}
                  >
                    <MenuItem
                      value={"list"}
                      sx={{
                        fontFamily: "Nostromo Regular",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "10px",
                        lineHeight: "14px",
                        height: "20px",
                        paddingLeft: "5px !important",
                        paddingRight: "5px !important",
                        borderRadius: "5px",
                        textTransform: "uppercase",
                        marginBottom: "5px",
                        color: noticeViewMode === "list" ? "#7949FF" : "black",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      List View
                    </MenuItem>
                    <MenuItem
                      value={"table"}
                      sx={{
                        fontFamily: "Nostromo Regular",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "10px",
                        lineHeight: "14px",
                        height: "20px",
                        paddingLeft: "5px !important",
                        paddingRight: "5px !important",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "5px",

                        textTransform: "uppercase",
                        marginBottom: "5px",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      Table View
                    </MenuItem>
                    <MenuItem
                      value={"board"}
                      sx={{
                        fontFamily: "Nostromo Regular",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "10px",
                        lineHeight: "14px",
                        height: "20px",
                        paddingLeft: "5px !important",
                        paddingRight: "5px !important",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "5px",

                        textTransform: "uppercase",
                        marginBottom: "5px",
                        color: "black",
                      }}
                    >
                      Board View
                    </MenuItem>
                  </Select>
                </div>

                {/* <AddNotice company={company} action={"dialog"} categoryList={categoryList} addNewNotice={addNewNotice} notices={notices} setNotices={setNotices} noticeFilter={noticeFilter}/> */}
                {/* <img style={{cursor: "pointer", marginLeft: "16px"}} src={searchIcon} alt="search notice" width="24px" height="24px"/> */}
                <FilterNavigation
                  menu={"notice"}
                  noticePeriodFilter={noticePeriodFilter}
                  setNoticePeriodFilter={setNoticePeriodFilter}
                  setAction={setAction}
                  setDialogOpen={setDialogOpen}
                  columnView={columnView}
                  setColumnView={setColumnView}
                  userList={userList}
                  userFilter={userFilter}
                  handleFilterUserClick={handleFilterUserClick}
                  statusFilter={statusFilter}
                  setStatusFilter={setStatusFilter}
                />
                <Notifications />
              </div>
            </div>

            <div
              id="noticesGroup"
              style={{
                position: "relative",
                zIndex: "1",
              }}
            >
              {circleLoading && (
                <>
                  {/* <Box sx={{ position: "absolute", top: '50%', left: '50%', zIndex: 999}}>
                            <CustomizedCircularProgress/>
                        </Box> */}
                  <Box sx={{ zIndex: 999 }}>
                    <Loading />
                  </Box>
                </>
              )}
              {(noticePeriodFilter ||
                statusFilter ||
                userFilter.length > 0) && (
                <div
                  id="filter-display"
                  style={{
                    background: "#f9f9ff",
                    width: "100%",
                    height: 30,
                    borderRadius: 3,
                    marginBottom: 10,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <FilterAltIcon
                    style={{
                      color: "#855cf8",
                      position: "relative",
                      top: 2,
                      marginLeft: 10,
                      marginRight: 10,
                    }}
                  />
                  {noticePeriodFilter && (
                    <Chip
                      style={{
                        height: 25,
                        position: "relative",
                        top: 2,
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                      label={
                        noticePeriodFilter === "today"
                          ? "Due Today"
                          : noticePeriodFilter === "week"
                          ? "Due This Week"
                          : noticePeriodFilter === "2week"
                          ? "Due This Fortnight"
                          : "Due This Month"
                      }
                      onDelete={() => {
                        setNoticePeriodFilter(null);
                      }}
                    />
                  )}
                  {statusFilter && (
                    <Chip
                      style={{
                        height: 25,
                        position: "relative",
                        top: 2,
                        marginLeft: 10,
                        marginRight: 10,
                        color:
                          statusFilter === "D"
                            ? "#5a36be"
                            : statusFilter === "W"
                            ? "#fdb704"
                            : statusFilter === "R"
                            ? "#40d040"
                            : statusFilter === "P"
                            ? "#ff0000"
                            : "#808080",
                      }}
                      label={
                        statusFilter === "D"
                          ? "Draft"
                          : statusFilter === "W"
                          ? "Working"
                          : statusFilter === "R"
                          ? "Ready"
                          : statusFilter === "P"
                          ? "Problem"
                          : "N/A"
                      }
                      onDelete={() => {
                        setStatusFilter(null);
                      }}
                    />
                  )}
                  {userFilter.length > 0 &&
                    userFilter.map((user) => {
                      const userObj = userList.filter(
                        (u) => user === u.userId
                      )?.[0];
                      return (
                        <Chip
                          style={{
                            height: 25,
                            position: "relative",
                            top: 2,
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                          label={userObj?.firstName}
                          onDelete={() =>
                            setUserFilter((prevState) =>
                              prevState.filter((u) => u !== user)
                            )
                          }
                        />
                      );
                    })}
                </div>
              )}
              {/*<div*/}
              {/*  id="sticker-scrollbar"*/}
              {/*  style={{*/}
              {/*    background: "#D5D7E3",*/}
              {/*    width: "16px",*/}
              {/*    height: "15px",*/}
              {/*    position: "absolute",*/}
              {/*    zIndex: 900,*/}
              {/*    top: 0,*/}
              {/*    right: 0,*/}
              {/*  }}*/}
              {/*/>*/}
              <div style={{ overflow: "auto" }}>
                <TableContainer
                  sx={{
                    height: "calc(100vh - 85px)",
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                  }}
                  onScroll={handleScroll}
                >
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="sticky table"
                    sx={{
                      tableLayout: "fixed",
                    }}
                  >
                    <TableHead
                      sx={{
                        height: "15px",
                        "& .MuiTableCell-root": {
                          borderBottom: "2px solid rgba(224, 224, 224, 1)",
                          fontFamily: "Roboto",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "7px",
                          color: "#754EE0",
                          height: "15px",
                          padding: "0px",
                          margin: "0px",
                          border: "none",
                          background: "#D5D7E3",
                        },
                      }}
                    >
                      <TableRow>
                        <TableCell
                          style={{ width: "14px" }}
                          sx={useStyles.tableCheckboxStyle}
                        />
                        <TableCell sx={useStyles.tableCheckboxStyle} />
                        <TableCell
                          sx={useStyles.tableHeadingDescriptionFieldStyle}
                          colSpan={3}
                        >
                          NOTICES
                        </TableCell>
                        <TableCell
                          sx={useStyles.tableHeadingStyle}
                          style={{ width: "35px", padding: 0 }}
                        ></TableCell>
                        <TableCell
                          sx={useStyles.tableHeadingStyle}
                          style={{ width: "25px", padding: 0 }}
                        ></TableCell>
                        {columnView.assignee && (
                          <TableCell
                            sx={useStyles.tableHeadingStyle}
                            style={{ width: "80px" }}
                          >
                            PEOPLE
                          </TableCell>
                        )}
                        {columnView.startDate && (
                          <TableCell
                            sx={useStyles.tableHeadingStyle}
                            style={{ width: "75px" }}
                          >
                            START DATE
                          </TableCell>
                        )}
                        {columnView.endDate && (
                          <TableCell
                            sx={useStyles.tableHeadingStyle}
                            style={{ width: "75px" }}
                          >
                            DUE DATE
                          </TableCell>
                        )}
                        {/* <TableCell
                        sx={useStyles.tableHeadingStyle}
                        style={{ width: "75px", padding: 0 }}
                      ></TableCell> */}
                      </TableRow>
                    </TableHead>

                    <TableBody className="tableBodyStyle">
                      {notices.map(
                        (notice: Notice) =>
                          (!noticePeriodFilter ||
                            isThisPeriod(notice, noticePeriodFilter)) && (
                            <NoticeRow
                              key={notice.noticeId}
                              notice={notice}
                              categoryList={categoryList}
                              notices={notices}
                              setNotices={setNotices}
                              setOpenModal={setOpenModal}
                              setSelectedNotice={setSelectedNotice}
                              descriptionColour={descriptionColour}
                              userList={userList}
                              handleRowClick={handleRowClick}
                              forceUpdate={forceUpdate}
                              columnView={columnView}
                              handleDeleteSingleNotice={
                                handleDeleteSingleNotice
                              }
                            />
                          )
                      )}
                      {populateEmptyRows()}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </>
        )}

        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>
            {action === "addNoticeCategory"
              ? "Add a category"
              : action === "deleteNoticeCategory"
              ? "Are you sure you wish to delete this category?"
              : action === "deleteNotice"
              ? "Are you sure you wish to delete this notice?"
              : ""}
          </DialogTitle>
          <DialogContent>
            {action === "addNoticeCategory" ? (
              <Input
                autoFocus
                type="text"
                fullWidth
                onChange={handleEditInput}
                value={newCategory}
              />
            ) : action === "deleteNoticeCategory" ? (
              <div>
                <div style={{ marginBottom: "10px" }}>
                  {selectedCategoryForDelete}
                </div>
                <div
                  style={{
                    color: "red",
                    fontStyle: "italic",
                    fontSize: "0.7em",
                  }}
                >
                  (all items in this category will be deleted)
                </div>
              </div>
            ) : action === "deleteNotice" ? (
              <div>
                <div style={{ marginBottom: "10px" }}>
                  {(selectedNotice as Notice).description}
                </div>
                <div
                  style={{
                    color: "red",
                    fontStyle: "italic",
                    fontSize: "0.7em",
                  }}
                >
                  this notice will be delete <b>"permanent"</b>
                </div>
              </div>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleDialogSubmit}>
              {action === "addNoticeCategory"
                ? "Add"
                : action === "deleteNoticeCategory"
                ? "Delete"
                : action === "deleteNotice"
                ? "Delete"
                : ""}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else if (noticeViewMode === "table") {
    return (
      <TimeTable
        company={company}
        noticeViewMode={noticeViewMode}
        setNoticeViewMode={setNoticeViewMode}
        categoryList={categoryList}
        setCategoryList={setCategoryList}
        noticeCategoryFilter={noticeCategoryFilter}
        setNoticeCategoryFilter={setNoticeCategoryFilter}
      />
    );
  } else if (noticeViewMode === "board") {
    return (
      <NoticesContext.Provider value={noticesValue}>
        <AdminTable
          company={company}
          noticeViewMode={noticeViewMode}
          setNoticeViewMode={setNoticeViewMode}
        />
      </NoticesContext.Provider>
    );
  } else {
    return (
      <TimeTable
        company={company}
        noticeViewMode={noticeViewMode}
        setNoticeViewMode={setNoticeViewMode}
        categoryList={categoryList}
        setCategoryList={setCategoryList}
        noticeCategoryFilter={noticeCategoryFilter}
        setNoticeCategoryFilter={setNoticeCategoryFilter}
      />
    );
  }
};
export default Notices;
