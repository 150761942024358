import React, { useState } from "react";
import yellowBall from "../../../Assets/SOCKET_CONNECTING_BALL.svg";
import greenBall from "../../../Assets/SOCKET_OPEN_BALL.svg";
import redBall from "../../../Assets/SOCKET_CLOSED_BALL.svg";

import { useSocket } from "../../../Services/Sockets/useSocket";

export let SOCKET_STATUS: string = "CONNECTING";

interface props {}

const SocketBalls: React.FC<props> = ({}) => {
  const socket = useSocket();
  let socketBall: string | undefined = undefined;
  // const [socketBall, setSocketBall] = useState<string>(blackBall);

  //* CONNECTING
  if (socket.readyState === 0) {
    socketBall = yellowBall;
    // setSocketBall(blackBall);
  }
  //*OPEN
  else if (socket.readyState === 1) {
    socketBall = greenBall;
    SOCKET_STATUS = "OPEN";
    // setSocketBall(greenBall);
  }
  //* CLOSING
  else if (socket.readyState === 2) {
    socketBall = redBall;
    SOCKET_STATUS = "CLOSING";
    // setSocketBall(redBall);
  }
  //* CLOSED
  else if (socket.readyState === 3) {
    socketBall = redBall;
    SOCKET_STATUS = "CLOSED";
    // setSocketBall(redBall);
  }
  //*OTHER
  else {
  }
  return (
    <>
      <img width="13" height="13" src={socketBall} alt="socketStatus" />
    </>
  );
};

export default SocketBalls;
