import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Notice } from "../../../../Models/notice";
import { User } from "../../../../Models/user";
import { ReactComponent as AddNoticeIcon } from "../../../../Assets/addNoticeBell.svg";
import { ReactComponent as EditNoticeIcon } from "../../../../Assets/noticeEditPen.svg";
import { AnimatePresence, motion } from "framer-motion";
import { convertColorValueByNoticeStatus } from "./TeamViewNoticeItem";

import { useDetectClickOutside } from "react-detect-click-outside";
import moment from "moment";
type Props = {
  notices: Notice[];
  user: User;
  timeValue: number;
  setcurrentMenuCell: Function;
  currentSelectedNotice: Notice | undefined;
  setCurrentSelectedNotice: Function;
  setCurrentDraggedNotice: Function;
  setCurrentUserRow: Function;
};

const NoticeListPopoverDetail = ({
  notices,
  user,
  timeValue,
  setcurrentMenuCell,
  currentSelectedNotice,
  setCurrentSelectedNotice,
  setCurrentDraggedNotice,
  setCurrentUserRow,
}: Props) => {
  const refDetectClickOutside = useRef<HTMLDivElement>(null);

  //detect click outside to close noticepopover
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        refDetectClickOutside.current &&
        !refDetectClickOutside.current.contains(event.target as Node)
      ) {
        setcurrentMenuCell(undefined);
        setCurrentSelectedNotice(undefined);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refDetectClickOutside]);

  //detect esc keydown to close notice popover
  useEffect(() => {
    function handleEscKeyDown(event: KeyboardEvent) {
      setCurrentSelectedNotice(undefined);
    }
    document.addEventListener("keydown", handleEscKeyDown);
    return () => {
      document.removeEventListener("keydown", handleEscKeyDown);
    };
  }, []);

  //handle click on each notice
  const handleClickNotice = (notice: Notice) => {
    if (
      notice.noticeId !== currentSelectedNotice?.noticeId ||
      !currentSelectedNotice
    )
      setCurrentSelectedNotice(notice);
    else setCurrentSelectedNotice(undefined);
  };

  const handleEditClick = useCallback(
    (notice: Notice, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  
      event.preventDefault();
      setCurrentDraggedNotice(notice);
      setCurrentUserRow(user);
      setcurrentMenuCell(undefined);
      setCurrentSelectedNotice(undefined);
    },
    []
  );
  return (
    <Box
      className="notice-list-popover-root"
      ref={refDetectClickOutside}
      sx={{ position: "relative", zIndex: 1000 }}
    >
      {/*User & Date Display Display */}
      <Box
        sx={{
          display: "flex",
          height: "50px",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "50%",
            height: "100%",
            justifyContent: "flex-start",
            alignItems: "center",
            display: "flex",
          }}
        >
          <span className="notice-list-popover-user-text">
            {user.firstName} {user.lastName}
          </span>
        </Box>

        <Box
          sx={{
            width: "50%",
            height: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            display: "flex",
          }}
        >
          <span className="notice-list-popover-time-text">
            {moment().format("DD").toString()}{" "}
            {moment().format("dddd").toString()} -
            {timeValue <= 12 ? timeValue : Math.abs(12 - timeValue)}:00{" "}
            {timeValue < 12 ? "am" : "pm"}
          </span>
        </Box>
      </Box>

      {/*Notices Display */}
      <Box
        sx={{
          flex: 1,
          flexDirection: "column",
          width: "100%",
          overflow: currentSelectedNotice ? "hidden" : "auto",
          marginBottom: "5px",
        }}
        onClick={
          currentSelectedNotice
            ? () => setCurrentSelectedNotice(undefined)
            : undefined
        }
      >
        {notices.map((notice, index) => {
          return (
            <motion.div
              initial={{ borderWidth: 0 }}
              whileHover={currentSelectedNotice ? undefined : { zoom: 1.1 }}
              animate={
                currentSelectedNotice?.noticeId === notice.noticeId
                  ? { zoom: 1.3, borderWidth: 0.5 }
                  : { zoom: 1, borderWidth: 0 }
              }
              whileTap={{
                opacity: 0.6,
              }}
              transition={{
                duration: 0.1,
                opacity: { duration: 0.3 },
              }}
              key={`${notice.noticeId}#${index}`}
              style={{
                borderStyle: "solid",
                borderColor: "white",
                height: "32px",
                width:
                  currentSelectedNotice &&
                  currentSelectedNotice.noticeId === notice.noticeId
                    ? "calc(100% - 12px)"
                    : "calc(100% - 10px)",
                borderRadius: "14px",
                backgroundColor: convertColorValueByNoticeStatus(notice.status),
                padding: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginBottom: "10px",
                cursor: "pointer",
                zIndex:
                  currentSelectedNotice &&
                  currentSelectedNotice.noticeId === notice.noticeId
                    ? 5
                    : 0,
                filter: currentSelectedNotice
                  ? currentSelectedNotice.noticeId !== notice.noticeId
                    ? "blur(3px)"
                    : undefined
                  : undefined,
                pointerEvents: currentSelectedNotice
                  ? currentSelectedNotice.noticeId !== notice.noticeId
                    ? "none"
                    : undefined
                  : undefined,
                overflowY: "scroll",
              }}
              onClick={() => handleClickNotice(notice)}
            >
              <Typography
                className="notice-list-description-text-style"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "85%",
                }}
              >
                {notice.description}
              </Typography>

              <motion.div
                whileHover={{ scale: 1.2 }}
                style={{
                  width: "calc(15% - 5px)",
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  zIndex: 1,
                }}
                onClick={(event) => {
                  handleEditClick(notice, event);
                }}
              >
                <EditNoticeIcon />
              </motion.div>
            </motion.div>
          );
        })}
      </Box>

      {/* Add Notice Display */}
      {/* <motion.div
        whileHover={{ opacity: 0.8 }}
        whileTap={{ scale: 1.3 }}
        style={{
          display: "flex",
          height: "20px",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          filter: currentSelectedNotice ? "blur(3px)" : undefined,
        }}
      >
        <AddNoticeIcon />
        <Typography
          sx={{
            marginLeft: "5px !important",
            fontFamily: "Nostromo Regular",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "16px",
          

            textAlign: "center",

            color: "#FFFFFF",
          }}
        >
          ADD NOTICE
        </Typography>
      </motion.div> */}

      {/*Blurring Mask when selecting a notice */}
      {/* <AnimatePresence>
        {currentSelectedNotice ? (
          <motion.div
         
            style={{
              width: "100%",
              height: "calc(100% - 50px)",
              position: "absolute",
              bottom: 0,
              left: 0,
              filter: "blur(10px)",
              zIndex: 4,
              background: "rgba(133, 93, 248, 0.9)",
            }}
            onClick={() => setCurrentSelectedNotice(undefined)}
          ></motion.div>
        ) : null}
      </AnimatePresence> */}
    </Box>
  );
};

export default NoticeListPopoverDetail;
