import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import TakeOffRow from './takeOffRow';
import {DoxCircle, DoxLine, DoxPolygon, DoxRectangle, TakeOff} from "../../../Models/takeOffs";
import { ICONS } from "../../../Assets/index.js";
import takeOffAPI from "../../../Services/DoxleAPI/takeOffAPI";
import {takeOffContextInterface, useTakeOff} from "../../Providers/TakeOffProvider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TakeOffAPI from "../../../Services/DoxleAPI/takeOffAPI";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import { Draggable } from "react-drag-reorder";
import CustomCheckbox from "../../../utilities/CheckBox/CheckBox";
import ClearIcon from '@mui/icons-material/Clear';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DrawingAPI from "../../../Services/DoxleAPI/drawingAPI";

const TakeOffList: React.FC = () => {

    const TakeOffContext = useTakeOff() as takeOffContextInterface
    const {
        projectId, costCodeId,
        takeOffs, setTakeOffs,
        editTakeOffMode, setEditTakeOffMode,
        unsavedMeasurements, setUnsavedMeasurements,
        selectedTakeOff, setSelectedTakeOff,
        setCurrentUnit, saveMeasurements,
        dialogTakeOff, setDialogTakeOff,
        editUnit, setEditUnit,
        setEditDescription, setUnitChangeConfirmed,
        handleUpdateTakeOff
    } = TakeOffContext;

    const [dialogType, setDialogType] = useState<"UNIT"|"DELETE"|"UNSAVED"|null>(null);
    const [checkedTakeOffs, setCheckedTakeOffs] = useState<string[]>([]);
    const [draggingMode, setDraggingMode] = useState<boolean>(false);
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn } = authContext;

    const handleDiologClose = () =>{
        if(!dialogTakeOff) {
            setDialogTakeOff(null);
            return
        }
        if (dialogType === "UNIT"){
            setUnitChangeConfirmed(false)
            if (
                dialogTakeOff.unit === "LM" ||
                dialogTakeOff.unit === "M2" ||
                dialogTakeOff.unit === "M3" ||
                dialogTakeOff.unit === "EA"
            ) setEditUnit(dialogTakeOff.unit)
        }
        if (dialogType === "UNSAVED") {
            setUnsavedMeasurements(false)
            setSelectedTakeOff(dialogTakeOff)
            setCurrentUnit(dialogTakeOff.unit)
            if (editTakeOffMode && selectedTakeOff?.takeOffId !== dialogTakeOff.takeOffId) setEditTakeOffMode(false)
            // if (dialogTakeOff.takeOffId === "new") addNewRow();
            setDialogTakeOff(null)
        }
        setDialogType(null)
    }

    const handleDialogConfirm = () =>{
        if(!dialogTakeOff && dialogType !== "DELETE") {
            setDialogTakeOff(null);
            return;
        }
        if (dialogType === "UNIT") setUnitChangeConfirmed(true);
        if (dialogType === "DELETE") deleteCheckedTakeOffs();
        if (dialogType === "UNSAVED") saveMeasurements();
        setDialogType(null)
    }

    const handlePositionChange = (currentPos:any , newPos:any) => {
        const takeOffIds = takeOffs.map((takeOff: TakeOff) => takeOff.takeOffId)
        let newOrder: string[] = []
        for (let i = 0; i < takeOffIds.length; i++){
            if (newPos < currentPos) {
                if (i < newPos || i > currentPos) newOrder.push(takeOffIds[i])
                else if (i === newPos) newOrder.push(takeOffIds[currentPos])
                else newOrder.push(takeOffIds[i-1])
            } else if (currentPos < newPos){
                if (i < currentPos || i > newPos) newOrder.push(takeOffIds[i])
                else if (i === newPos) newOrder.push(takeOffIds[currentPos])
                else newOrder.push(takeOffIds[i+1])
            }
        }
        setCheckedTakeOffs([...newOrder])
    };

    const deleteCheckedTakeOffs = async () => {
        setDialogType(null)
        setSelectedTakeOff(null);
        setCurrentUnit("LM")
        let result:boolean|{} = true;
        let deletedTakeOffs: string[] = []
        for (const takeOffId of checkedTakeOffs) {
            if (takeOffId !== "new") result = await takeOffAPI.remove(takeOffId);
            if (result === true) deletedTakeOffs.push(takeOffId)
        }
        const updatedTakeOffs: TakeOff[]  = takeOffs.filter(takeOff => !(deletedTakeOffs.includes(takeOff.takeOffId)));
        setTakeOffs([...updatedTakeOffs]);
        setCheckedTakeOffs([])
    }

    const confirmDragPositions = async () => {
        try {
            const response = await TakeOffAPI.setOrder(checkedTakeOffs)
            if (response) {
                let newTakeOffs: TakeOff[] = []
                checkedTakeOffs.forEach((id) => {
                    for (const takeOff of takeOffs) {
                        if (takeOff.takeOffId === id) {
                            newTakeOffs.push(takeOff)
                            break
                        }
                    }
                })
                setTakeOffs([...newTakeOffs])
            }
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
        setDraggingMode(false)
        setCheckedTakeOffs([])
    }

    const handleDeleteIconClicked = () => {
        setDialogType("DELETE");
    }

    const addNewRow = () => {
        if (!projectId) return;
        if (selectedTakeOff && unsavedMeasurements) {
            setDialogTakeOff(selectedTakeOff);
            setDialogType("UNSAVED");
            return;
        }

        let createNew = true;
        takeOffs.forEach((takeOff) => {
            if (takeOff.takeOffId === "new") createNew = false
        })
        if (!createNew) return;
        const newTakeOff: TakeOff = {
            takeOffId: "new",
            index: takeOffs?.length,
            description: "New Measurement",
            totalValue: 0.0,
            unit: "M2",
            measurements: [],
            project: projectId,
            costCode: costCodeId ? costCodeId : null,
        }
        if (unsavedMeasurements) {
            setDialogTakeOff(newTakeOff)
            setDialogType("UNSAVED");
            return;
        }
        setTakeOffs([...takeOffs, newTakeOff]);
        setSelectedTakeOff(newTakeOff)
        setDialogTakeOff(newTakeOff)
        setEditTakeOffMode(true)
    }

    const getDeleteDialog = () => {
        if (checkedTakeOffs.length > 1) return "Delete Selected Take Offs?"
        else if (checkedTakeOffs.length === 1) {
            const checkedTakeOff = takeOffs.filter(tO => tO.takeOffId === checkedTakeOffs[0])
            if (checkedTakeOff.length === 1) return `Delete take off:  ${checkedTakeOff[0].description}?`
        }
        return "You Should Not Be Seeing This"
    }

    useEffect(() => {
        console.log('costcodeId', costCodeId)
    }, [costCodeId])

    useEffect(() => {
        console.log('editUnit', editUnit)
    }, [editUnit])


    useEffect(() => {
        if(dialogTakeOff) setEditUnit(dialogTakeOff.unit)
        else if (selectedTakeOff) setEditUnit(selectedTakeOff.unit)
    }, [dialogTakeOff])

    return (
        <Paper >
            <div className="tableHeader" >
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={1} className="measurementHead">
                                { (takeOffs.length === 0 || !draggingMode) &&
                                    <CustomCheckbox
                                        style={{transform: 'scale(0.6)'}}
                                        checked={checkedTakeOffs.length === takeOffs.length}
                                        onClick={(e:any) => {
                                            if (e?.target?.checked) setCheckedTakeOffs([...takeOffs.map(tO => tO.takeOffId)])
                                            else setCheckedTakeOffs([])
                                        }}
                                    />
                                }
                            </Grid>
                            <Grid item xs={6} className="measurementHead"> Take Offs </Grid>
                            <Grid item xs={5} style={{textAlign: 'right'}}>
                                {(draggingMode || editTakeOffMode) &&
                                    <IconButton style={{marginTop: "-5px", cursor: "pointer"}}
                                                onClick={() => {
                                                    setCheckedTakeOffs([])
                                                    setDraggingMode(false)
                                                    setEditTakeOffMode(false)
                                                }}
                                    >
                                        <ClearIcon
                                            className="verticallyCenterStyle tableHeaderIcons"
                                            style={{ color:"#9974fe" }}
                                        />
                                    </IconButton>
                                }
                                {(draggingMode || editTakeOffMode) &&
                                    <IconButton style={{marginTop: "-5px", cursor: "pointer"}}
                                                onClick={() => {
                                                    if (editTakeOffMode) handleUpdateTakeOff()
                                                    else confirmDragPositions()
                                                }}
                                    >
                                            <ICONS.checkIcon
                                                className="verticallyCenterStyle tableHeaderIcons"
                                                style={{ color:"#9974fe" }}
                                            />
                                    </IconButton>
                                }
                                {!(editTakeOffMode || draggingMode) && checkedTakeOffs.length === 0 &&
                                    <IconButton style={{marginTop: "-5px"}}
                                                onClick={() => {
                                                    setDraggingMode(true)
                                                    setCheckedTakeOffs([...takeOffs.map(to => to.takeOffId)])
                                                }}
                                                disabled={editTakeOffMode}>
                                        <CompareArrowsIcon
                                            className="verticallyCenterStyle tableHeaderIcons"
                                            style={{ transform: "rotate(90deg)", color:"#9974fe" }}
                                        />
                                    </IconButton>
                                }
                                {!(editTakeOffMode || draggingMode) && checkedTakeOffs.length === 0 &&
                                    <IconButton style={{marginTop: "-5px"}}
                                                onClick={addNewRow}
                                                disabled={editTakeOffMode}>
                                        <img
                                            className="verticallyCenterStyle tableHeaderIcons"
                                            src={ICONS.plusSquareIcon}
                                            alt="measurement add icon"
                                        />
                                    </IconButton>
                                }
                                {!(editTakeOffMode || draggingMode) && checkedTakeOffs.length > 0 &&
                                    <IconButton
                                        onClick={handleDeleteIconClicked}
                                        style={{marginTop: "-5px"}}
                                    >
                                        <img
                                            className="tableBodyIcons"
                                            src={ICONS.trashIcon}
                                            alt="trash icon"
                                        />
                                    </IconButton>
                                }
                                {!(editTakeOffMode || draggingMode) && checkedTakeOffs.length === 1 &&
                                    <IconButton
                                        onClick={(e:any) => {
                                            const takeOff = takeOffs.filter(tO => tO.takeOffId === checkedTakeOffs?.[0])?.[0] || null
                                            setSelectedTakeOff(takeOff)
                                            setDialogTakeOff(takeOff)
                                            setEditTakeOffMode(true)
                                        }}
                                        style={{marginTop: "-5px"}}
                                    >
                                        <img
                                            className="verticallyCenterStyle tableHeaderIcons"
                                            src={ICONS.editIcon}
                                            alt="measurement edit icon"
                                        />
                                    </IconButton>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

            <TableContainer component={Paper} style={{overflowY: 'scroll', height: "88vh"}}>
                <Table aria-label="spanning table"  >
                    <TableBody>
                        {!draggingMode
                            ?
                                takeOffs.map((takeOff: TakeOff, i: number) => {
                                    return (<TakeOffRow
                                        key={takeOff.takeOffId+'edit'}
                                        idx={i}
                                        takeOff={takeOff}
                                        editMode={editTakeOffMode}
                                        setEditMode={setEditTakeOffMode}
                                        setDialogType={setDialogType}
                                        setDialogTakeOff={setDialogTakeOff}
                                        setEditDescription={setEditDescription}
                                        editUnit={editUnit}
                                        setEditUnit={setEditUnit}
                                        handleUpdateTakeOff={handleUpdateTakeOff}
                                        checkedTakeOffs={checkedTakeOffs}
                                        setCheckedTakeOffs={setCheckedTakeOffs}
                                        draggable={false}
                                    />)
                                })
                            :
                            <Draggable onPosChange={handlePositionChange}>
                                {takeOffs?.map((takeOff: TakeOff, i: number) => {
                                    return (<TakeOffRow
                                        key={takeOff.takeOffId + 'drag'}
                                        idx={i}
                                        takeOff={takeOff}
                                        editMode={editTakeOffMode}
                                        setEditMode={setEditTakeOffMode}
                                        setDialogType={setDialogType}
                                        setDialogTakeOff={setDialogTakeOff}
                                        setEditDescription={setEditDescription}
                                        editUnit={editUnit}
                                        setEditUnit={setEditUnit}
                                        handleUpdateTakeOff={handleUpdateTakeOff}
                                        checkedTakeOffs={checkedTakeOffs}
                                        setCheckedTakeOffs={setCheckedTakeOffs}
                                        draggable={true}
                                    />)
                                })}
                            </Draggable>
                        }
                        {/*<Draggable>*/}
                        {/*    {takeOffs?.map((takeOff: TakeOff, i: number) => {*/}
                        {/*        return (<TakeOffRow*/}
                        {/*            key={takeOff.takeOffId}*/}
                        {/*            idx={i}*/}
                        {/*            takeOff={takeOff}*/}
                        {/*            editMode={editTakeOffMode}*/}
                        {/*            setEditMode={setEditTakeOffMode}*/}
                        {/*            setDialogType={setDialogType}*/}
                        {/*            setDialogTakeOff={setDialogTakeOff}*/}
                        {/*            setEditDescription={setEditDescription}*/}
                        {/*            editUnit={editUnit}*/}
                        {/*            setEditUnit={setEditUnit}*/}
                        {/*            handleUpdateTakeOff={handleUpdateTakeOff}*/}
                        {/*        />)*/}
                        {/*    })}*/}
                        {/*</Draggable>*/}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                disableEscapeKeyDown
                open={dialogType === "DELETE" || (dialogTakeOff !==null && dialogType !== null)}
                onClose={(e:any, reason) => {
                    if (reason !==  "backdropClick") handleDiologClose()
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogType === "UNSAVED"
                        ? "Save Changes?"
                        : "Are you sure?"
                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogType === "UNIT"
                            ? "Changing Unit will clear existing measurements"
                            : dialogType === "DELETE"
                                ? getDeleteDialog()
                                : "You have unsaved measurements. Save changes?"
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDiologClose}>No</Button>
                    <Button onClick={handleDialogConfirm} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        </Paper>
    )
}

export default TakeOffList