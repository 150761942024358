import { styled, ButtonProps, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, TextField, Button } from "@mui/material";
import React from "react";

interface input {
    dialogText: string;
    handleInputChange: React.Dispatch<React.SetStateAction<any>>;
    inputType?: string;
}

interface props{
    open: boolean,
    dialogTitle: string
    inputs: input[]
    handleClose: React.Dispatch<React.SetStateAction<any>>
    handleSubmit: React.Dispatch<React.SetStateAction<any>>
}

const MultiDialog: React.FC<props> = ({
    open,
    dialogTitle,
    inputs,
    handleClose,
    handleSubmit,
}) => {

    const CancelButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText("#F8F8F8"),
        backgroundColor: "#F8F8F8",
        marginLeft:"66px",
        minWidth:"93px",
        borderRadius: "20px",
        textTransform: "none",
        fontStyle: "normal",
        fontWeight: "normal",
        fontFamily:"Inter",
        fontSize: "11px",
        '&:hover': {
            backgroundColor: "#F8F8F8",
        }
    }));
        
    
    const AddButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText("#0044CC"),
        backgroundColor: "#0044CC",
        marginLeft:"66px",
        minWidth:"93px",
        borderRadius: "20px",
        textTransform: "none",
        fontStyle: "normal",
        fontWeight: "normal",
        fontFamily:"Inter",
        fontSize: "11px",
        '&:hover': {
            backgroundColor: "#0044CC",
        },
    }));


    return(
        <div>
            <Dialog open={open} onClose={handleClose} 
                fullWidth
                maxWidth="sm">
                <DialogTitle sx={{
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontFamily:"Inter",
                    fontSize: "11px"
                }}>{dialogTitle}</DialogTitle>
                
                {inputs.map((inp, index) => (
                    <DialogContent  key={"Text"+index.toString()}>
                        <DialogContentText
                            sx={{
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontFamily:"Inter",
                                fontSize: "11px"
                            }}>
                            {inp.dialogText}
                        </DialogContentText>
                        <TextField
                            InputProps={{ 
                                disableUnderline: true,
                                sx:{
                                    border: "1px solid #eff1f5",
                                    borderRadius: "3px",
                                    fontStyle: "normal",
                                    fontWeight: "normal",
                                    fontFamily:"Inter",
                                    fontSize: "11px"                               
                                }
                            }}
                            autoFocus={index === 0}
                            margin="dense"
                            type={inp.inputType ? inp.inputType : "text"}
                            fullWidth
                            variant="standard"
                            onKeyUp={(event:any) => {if (event.keyCode && event.keyCode === 13){handleSubmit(event) }}}
                            onChange={(event: any) => {inp.handleInputChange(event)}}
                        />
                    </DialogContent>
                ))}
                <DialogActions>
                <CancelButton onClick={handleClose}>Cancel</CancelButton>
                <AddButton onClick={handleSubmit}>Add</AddButton>
                </DialogActions>
            </Dialog>
        </div>   
    )
}
export default MultiDialog