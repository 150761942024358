import React, { createContext, useContext, useState, useEffect } from 'react'
import DepositEstimatorStage from '../../../Models/DeppositEstimatorModel'
import { depositData } from './baseExampleObject'

export interface DepositStage {
  councilPermit: { amount: string | undefined; extraDetails: any[] }
  protectionWorks: { amount: string | undefined; extraDetails: any[] }
  telstraIncluded: { amount: string | undefined; extraDetails: any[] }
  electricityPit: { amount: string | undefined; extraDetails: any[] }
  waterTapping: { amount: string | undefined; extraDetails: any[] }
  demolition: { amount: string | undefined; extraDetails: any[] }
  warrantyInsurance: { amount: string | undefined; extraDetails: any[] }
  buildingPermit: { amount: string | undefined; extraDetails: any[] }
}

export interface BaseStage {
  sitePreparation: { amount: string | undefined; extraDetails: any[] }
  shoringAndUderpinning: { amount: string | undefined; extraDetails: any[] }
  piling: { amount: string | undefined; extraDetails: any[] }
  basement: { amount: string | undefined; extraDetails: any[] }
  retainingWall: { amount: string | undefined; extraDetails: any[] }
  drainage: { amount: string | undefined; extraDetails: any[] }
  concreteReinforcement: { amount: string | undefined; extraDetails: any[] }
  baseContigency: { amount: string | undefined; extraDetails: any[] }
}
export interface EstimatorProviderInterface {
  finalData: FinalData | {}
  updateFinalData: Function
}

export const initialDepositValues: DepositStage = {
  councilPermit: { amount: '', extraDetails: [] },
  protectionWorks: { amount: '', extraDetails: [] },
  telstraIncluded: { amount: '', extraDetails: [] },
  electricityPit: { amount: '', extraDetails: [] },
  waterTapping: { amount: '', extraDetails: [] },
  demolition: { amount: '', extraDetails: [] },
  warrantyInsurance: { amount: '', extraDetails: [] },
  buildingPermit: { amount: '', extraDetails: [] },
}
export interface FinalData {
  deposit: DepositStage | undefined
}
type Props = {}

const EstimatorContext = createContext({})

export const EstimatorProvider = (children: any) => {
  const [finalData, setFinalData] = useState<FinalData | {}>({
    deposit: {},
  })

  const updateFinalData = (
    updatedSection: 'deposit' | 'contract' | 'base',
    updatedData: DepositStage | BaseStage
  ) => {
    if (updatedSection === 'deposit') {
      setFinalData({
        ...finalData,
        [updatedSection]: updatedData as DepositStage,
      })
    }
  }

  const estimatorContextValue: EstimatorProviderInterface = {
    finalData,
    updateFinalData,
  }

  return (
    <EstimatorContext.Provider value={estimatorContextValue} {...children} />
  )
}
export const useEstimator = () => useContext(EstimatorContext)
