import { Box, Stack } from "@mui/system";
import React from "react";
import { Notice } from "../../../../Models/notice";
import { ReactComponent as UnexpandIcon } from "../../../../Assets/noticeDetailDeexpand.svg";
import { motion } from "framer-motion";
import { convertColorValueByNoticeStatus } from "./TeamViewNoticeItem";
import { Typography } from "@mui/material";
import { formatDate } from "../../../../utilities/FunctionUtilities";
type Props = {
  notice: Notice;
};

const ExpandedNoticeDetail = ({ notice }: Props) => {
  return (
    <Box className="expanded-notice-detail-root">
      <Box
        sx={{
          marginBottom: "15px",
          width: "100%",
          height: "50px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <span
          style={{
            fontFamily: "IBM Plex Mono",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "9px",
            lineHeight: "12px",
            color: "#117B5F",
            marginBottom: "5px",
            textTransform: "capitalize",
          }}
        >
          From {notice.creator?.firstName}:
        </span>

        <span
          style={{
            textTransform: "capitalize",
            fontFamily: "IBM Plex Sans",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#FEFFFF",
          }}
        >
          {notice.description}
        </span>
      </Box>
      {notice.comments && notice.comments.length > 0 ? (
        <Box
          sx={{
            marginBottom: "30px",
            padding: "5px",
            overflow: "scroll",
            flex: 1,
            flexDirection: "column",
          }}
        >
          {notice.comments.map((comment, index) => (
            <Box
              key={comment.commentId}
              sx={{
                marginBottom: "10px",
                padding: "5px",
                width: "100%",
                height: "30%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  height: "15px",
                  textDecoration: "underline",
                  color: "#117B5F",
                  fontSize: "12px",
                  fontWeight: 300,
                  fontFamily: "IBM Plex Mono",
                  fontStyle: "normal",
                  lineHeight: "16px",
                }}
              >
                {comment.user?.firstName} {comment.user?.lastName} wrote:
              </span>
              <span
                style={{
                  width: "100%",
                  flex: 1,
                  color: "rgba(43, 45, 44, 0.9)",
                  fontSize: "10px",
                  fontWeight: 400,
                  fontFamily: "IBM Plex Mono",
                  fontStyle: "normal",
                  lineHeight: "13px",
                }}
              >
                {comment.commentText}
              </span>
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            marginBottom: "30px",
            padding: "5px",

            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontFamily: "IBM Plex Mono",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "20px",
            lineHeight: "13px",

            textAlign: "center",
          }}
        >
          NO COMMENT{" "}
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "20px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            fontFamily: "IBM Plex Mono",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "10px",
            lineHeight: "13px",
            color: "#117B5F",
            textAlign: "center",
          }}
        >
          {formatDate(notice.timeStamp, "yyyy-MM-dd", "fullDate")}
        </span>
      </Box>

      {/*Deexpand icon */}
      <motion.div
        whileHover={{ y: [0, -5, -5, 0] }}
        whileTap={{ x: [0, "-100%"] }}
        transition={{
          y: { duration: 0.6 },
        }}
        style={{
          display: "flex",
          width: "40px",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          right: 0,
          height: "30%",
          top: "35%",
          borderTopLeftRadius: "10px",
          borderBottomLeftRadius: "10px",
        }}
      >
        <UnexpandIcon />
      </motion.div>
    </Box>
  );
};

export default ExpandedNoticeDetail;
