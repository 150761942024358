import React, { useState, useEffect, FormEvent } from "react";
import { ButtonProps, styled, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from "@mui/material";

interface props {
    open: boolean;
    title: string;
    body: string;
    closeBtn: string;
    closeAction: Function;
    saveBtn: string;
    saveAction: Function;
}


const InputDialog: React.FC<props> = (
    {open, title, body, closeBtn, closeAction, saveBtn, saveAction}
) => {
    const CancelButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText("#F8F8F8"),
        backgroundColor: "#F8F8F8",
        marginLeft:"66px",
        minWidth:"93px",
        borderRadius: "20px",
        textTransform: "none",
        fontStyle: "normal",
        fontWeight: "normal",
        fontFamily:"Inter",
        fontSize: "11px",
        '&:hover': {
            backgroundColor: "#F8F8F8",
        }
    }));
        
    
    const AddButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText("#0044CC"),
        backgroundColor: "#0044CC",
        marginLeft:"66px",
        minWidth:"93px",
        borderRadius: "20px",
        textTransform: "none",
        fontStyle: "normal",
        fontWeight: "normal",
        fontFamily:"Inter",
        fontSize: "11px",
        '&:hover': {
            backgroundColor: "#0044CC",
        },
    }));

    const [openState, setOpen] = useState<boolean>(open);
    const [inputValue, setValue] = useState<string>("");
    const handleClose = () => {
        closeAction()
        setValue("")
        setOpen(false);
    };

    const handleValueChange = (e: FormEvent<HTMLFormElement>) => {
        const target = e.target as any;
        if (target.value) { setValue(target.value) }
    };

    const handleSaveBtnClick = (e: any) =>{
        saveAction(inputValue)
        setValue("")
        setOpen(false);
    }


    useEffect(() => {

    }, [])

    return(
        <React.Fragment>
            <Dialog open={openState} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText> {body} </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="dialogInput"
                        fullWidth
                        variant="standard"
                        onChange={(event: any) => {handleValueChange(event)}}
                        onKeyUp={(event:any) => {if (event.keyCode && event.keyCode === 13){handleSaveBtnClick(event) }}}
                    />
                </DialogContent>
                <DialogActions>
                    <AddButton onClick={(event:any) => handleSaveBtnClick(event)}>{saveBtn}</AddButton>
                    <CancelButton onClick={handleClose}>{closeBtn}</CancelButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default InputDialog