import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    headerTop: {
        backgroundColor: '#F8F9FC',
        height: '75px',
        borderRadius: '14px 14px 0px 0px'
    },
    heading: {
        fontFamily: 'Inter !important',
        fontStyle: "normal !important",
        fontWeight: 500 + "!important",
        fontSize: "17px !important",
        color: "#000 !important",
    },
    closeIcon: {
        lineHeight: '22px !important',
        color: '#0030A8 !important',
    },
    electrical: {
        '&MuiTypography-root':{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
        },
        padding: '5px 25px',
        color: '#0044CC',
        width: "50%",
    },
    dollar: {
        '&MuiTypography-root':{
            fontFamily: 'Inter',
            lineHeight: '19px',
            fontSize: '16px',
            fontWeight: 500,
        },
        fontStyle: 'normal',
        color: '#0044CC',
        position: "relative",
        top: "10px",
        // right: "65px",
        right: "110px",
        width: "50%",
        textAlign: "right",
    },
    date: {
        borderRadius: '3px',
        background: '#F5F6F7',
        width: '240px',
        fontStyle: 'normal',
        color: '#96A2BE',
    },
    tabBtnHeader: {
        '& button': {
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
            opacity: 1,
            textTransform: 'none',
        },

    },
    addButton: {
        '&MuiButton-root':{
            fontSize: "12px",
            fontFamily: 'Inter',
            fontStyle: "normal",
            lineHeight: "15px",
            fontWeight: 600,
            color: "#0044CC !important",
            // position: "relative",
            borderRadius: "7px",

        },
        background: "#ECECF4 !important",
        // width: "98px",
        height: "26px",
        right: 0,
        top: "25px",
        left: "72%",
        zIndex: 999,
        color: "#0044cc",
    },
    headrRightSec:{
        position:"relative",
        top: "25px",
        textAlign: "right",
        right: "5px",
    }
}));