import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { Pricelist } from "../../../Models/pricelist";
import "./pricelist.css";

interface props {
  // key: string;
  item: Pricelist;
  handleSelectPricelist: Function;
}

const PricelistItem: React.FC<props> = ({ item, handleSelectPricelist }) => {
  return (
    <>
      <TableRow style={{ height: "80%", width: "90%", cursor: "pointer"}} onClick={() => handleSelectPricelist(item)}>
        <TableCell
          style={{
            marginTop: "15%",
            fontSize: "16",
            fontWeight: "bold",
            color: "#855cf8",
          }}
        >
          <p>{item.description}</p>
          <p
            style={{ fontSize: "12px", color: "#000000", fontWeight: "normal" }}
          >
            {item.supplier}
          </p>
        </TableCell>

        <TableCell
          style={{
            // textAlign: "right",
            color: "#FF7C04",
            fontSize: "27px",
            width: "10%",
          }}
        >
          ${item.price}
        </TableCell>
      </TableRow>
    </>
  );
};

export default PricelistItem;
