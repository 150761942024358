import Cookie from "universal-cookie"

const cookie = new Cookie();

class CookieService {
    get(key: string){
        return cookie.get(key)
    }

    set(key: string, value: string, options: Object){
        return cookie.set(key, value, options)
    }

    remove(key: string){
        return cookie.remove(key)
    }
}

export default new CookieService();