import React, { useState, useEffect } from "react";
import "../Menu/filterNavigation.css";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { ReactComponent as NoticePopperIcon } from "../../Assets/noticeDropDownIcon.svg";
import { ReactComponent as AddIcon } from "../../Assets/Plus-purple.svg";
import CloseIcon from "@mui/icons-material/Close";
import titleCase from "../../utilities/Helper/titleCase";
import CategoryMenuItem from "./CategoryMenuItem";
import {NoticeCategory} from "../../Models/notice";

interface props {
  categories: NoticeCategory[];
  setCategoryList: Function;
  selectedCategory: NoticeCategory | null;
  handleSelectCategory: Function;
  handleAddCategory: Function;
  handleDeleteCategory: Function;
}

const CategoryNavigation: React.FC<props> = ({
  categories,
  setCategoryList,
  selectedCategory,
  handleSelectCategory,
  handleAddCategory,
  handleDeleteCategory,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickAdd = () => {
    handleAddCategory();
    handleClose();
  };

  const handleClickItem = (category: NoticeCategory|null) => {
    if (category) handleSelectCategory(category);
    else handleSelectCategory(null);
    handleClose();
  };

  const handleClickDelete = (categoryname: any) => {
    handleDeleteCategory(categoryname);
    handleClose();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        paddingTop: "7px",
        height: "fit-content",
        gap: "3px",
        alignItems: "center",
      }}
    >
      <div
        style={{
          fontFamily: "Nostromo Regular",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "19px",
          color: "#855CF8",
        }}
      >
        {titleCase(selectedCategory ? selectedCategory.categoryTitle : "All Categories")}
      </div>
      <div style={{ width: "24px", height: "24px" }}>
        <IconButton
          sx={{ width: "24px", height: "24px", m: 0, p: 0 }}
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <NoticePopperIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{
            "& .MuiList-root": {
              p: 0,
            },
            "& .MuiMenu-paper": {
              minWidth: "185px",
            },
            "& .MuiMenuItem-root": {
              fontFamily: "'IBM Plex Mono'",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              alignItems: "center",
            },
          }}
        >
          <MenuItem
            key={"CategoryHeaderClose"}
            disableRipple
            sx={{
              justifyContent: "end",
              height: "35px",
              paddingRight: "6px",
              "&:hover": { background: "none" },
            }}
          >
            <IconButton
              sx={{
                width: "21px",
                height: "21px",
                m: 0,
                p: 0,
                cursor: "pointer",
                borderRadius: 1,
                background: "#F5F6F7",
              }}
              size="small"
              onClick={handleClose}
            >
              <CloseIcon sx={{ transform: "scale(0.8)" }} />
            </IconButton>
          </MenuItem>

          <MenuItem key={"CategoryHeader"} sx={{ color: "#754EE0" }}>
            Category
          </MenuItem>

          {categories.map((category: NoticeCategory, i) => (
            <CategoryMenuItem
              key={category.noticeCategoryId}
              category={category}
              categories={categories}
              setCategoryList={setCategoryList}
              index={i}
              handleClose={handleClose}
              handleMenuItemClick={handleClickItem}
              handleDeleteCategory={handleClickDelete}
            />
          ))}

          <MenuItem
            key={"CategoryViewAllMenu"}
            onClick={() => handleClickItem(null)}
            style={{
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "10px",
              lineHeight: "12px",
              color: "#B0BBD4",
              paddingLeft: "39px",
            }}
          >
            View All Categories
          </MenuItem>
          <MenuItem
            key={"CategoryAddMenu"}
            style={{
              marginTop: "70px",
              background: "#F1F2F5",
              justifyContent: "center",
              color: "#754EE0",
              height: "35px",
            }}
            onClick={handleClickAdd}
          >
            <AddIcon />
            Add Category
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};
export default CategoryNavigation;
