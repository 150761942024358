import React, { useState, useEffect } from 'react';

const WindowDimensions = () => {

    const getWindowDimensions = () => {
        let { innerWidth: width, innerHeight: height } = window;
        
        // for all iPads, treat as iPad Air 820x1180 dimension
        if (navigator.userAgent.match(/iPad/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
          width = 820;
          height = 1180;
        }

        return {
            width,
            height
        }
    }
    
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        const handleResize = () => {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    return windowDimensions
}

export default WindowDimensions