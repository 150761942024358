import { styled, alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

interface props {
  searchText: Function;
  addButton: Function;
}

const SearchBar = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha("#D6D9DF", 0.75),
  "&:hover": {
    backgroundColor: alpha("#D6D9DF", 0.5),
  },
  marginLeft: 0,
  width: "30%",
  // [theme.breakpoints.up("sm")]: {
  //     marginLeft: theme.spacing(1),
  //     width: "auto",
  // },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",

    // [theme.breakpoints.up("sm")]: {
    //     width: "30ch",
    //     "&:focus": {
    //         width: "50ch",
    //     },
    // },
  },
}));

const AddressBookSearch: React.FC<props> = ({ searchText, addButton }) => {
  return (
    <Paper sx={{ flexGrow: 0, mb: 3 }}>
      <Toolbar>
        <SearchBar>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search by company name "
            inputProps={{ "aria-label": "search" }}
            onChange={(event: any) => searchText(event)}
          />
        </SearchBar>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          size="small"
          sx={{
            position: "absolute",
            top: "27%",
            right: "2%",
            // zIndex: 99,
            textTransform: "none",
            background: "#5A36BE",
            borderRadius: "4px",
            "&:hover": {
              background: "rgba(107, 74, 201, 0.75)",
            },
          }}
          onClick={() => addButton()}
        >
          Add Contact
        </Button>
      </Toolbar>
    </Paper>
  );
};

export default AddressBookSearch;
