import React, { useEffect, useState } from "react";
import { Project } from "../../../Models/project";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import { Stage } from "../../../Models/stage";
import { Costcode } from "../../../Models/costcode";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import stageAPI from "../../../Services/DoxleAPI/stageAPI";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import {
  Button,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useStyles } from "../CostCodes/costcodeStyle";
import { getCalculationColumn } from "../CostCodes/calculateColumn";
import CostCodeRow from "../CostCodes/CostCodeRow";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Calendar from "../../../Assets/Calendar.svg";
import { ReactComponent as TrashIcon } from "../../../Assets/Trash2.svg";
import { toFloat } from "../Measurements/konvaFunctions/generalFunctions";
import { useNavigate } from "react-router-dom";
import Loading from "../../../utilities/Lottie/Loading";

interface props {
  project: Project | null;
}

const EditStage: React.FC<props> = ({ project }) => {
  const [stages, setStages] = useState<Array<Stage>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { setLoggedIn, user } = useAuth() as authContextInterface;
  const unmounted = useMountStatus();
  const navigate = useNavigate();
  const fetchStages = async () => {
    setLoading(true);
    if (project) {
      try {
        const response = (await stageAPI.getStageList(
          project.projectId
        )) as Stage[];
        if (unmounted) return;
        setStages([...response]);
      } catch (err) {
        err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
      }
    }
    setLoading(false);
  };

  const handleValueChange = (stageId: string, value: string) => {
    setStages((prevStages) => {
      return [
        ...prevStages.map((stage) => {
          if (stage.stageId !== stageId) return stage;
          return { ...stage, value: toFloat(value) };
        }),
      ];
    });
  };

  const handleDateChange = (stageId: string, date: string | null) => {
    setStages((prevStages) => {
      return [
        ...prevStages.map((stage) => {
          if (stage.stageId !== stageId || date === null) return stage;
          return {
            ...stage,
            targetDate: new Date(date).toISOString().substring(0, 10),
          };
        }),
      ];
    });
  };

  const handleSubmit = async () => {
    try {
      const { stageResponse, errors } = await stageAPI.updateMultipleStages(
        stages
      );
      if (errors.length === 0) setStages([...stageResponse]);
      else console.error(errors);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const handleBackNavigateToCashflow = () => {
    navigate("/cashflow");
  };
  useEffect(() => {
    fetchStages();
  }, [project]);

  useEffect(() => {
    console.log("UE stages", stages);
  }, [stages]);

  return (
    <TableContainer
      sx={{ height: "calc(100vh - 48px - 48px)", backgroundColor: "white" }}
    >
      <Table size="small" stickyHeader aria-label="sticky table">
        {/*<TableHead>*/}
        {/*    <TableRow className="tableHeadStyle" sx={{background: "#D5D7E3"}}>*/}

        {/*    </TableRow>*/}
        {/*</TableHead>*/}
        <TableBody className="tableBodyStyle">
          {stages.map((stage) => (
            <TableRow>
              <TableCell>{stage.title}</TableCell>
              <TableCell>
                <TextField
                  autoFocus
                  type={"number"}
                  value={toFloat(stage.value)}
                  margin="dense"
                  id="dialogInput"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    handleValueChange(stage.stageId, e.target.value);
                  }}
                />
              </TableCell>
              <TableCell>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    openTo="day"
                    value={stage?.targetDate || null}
                    inputFormat="dd.MM.yyyy"
                    onChange={(newValue) => {}}
                    onAccept={(date) => handleDateChange(stage.stageId, date)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          endAdornment: <img src={Calendar} />,
                        }}
                        // style={textStyle}
                        // className={classes.InputFieldsText}
                        id="bootstrap-input"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell>
              <Button style={{ float: "right" }} onClick={handleSubmit}>
                Save Changes
              </Button>

              <Button
                style={{ float: "right" }}
                onClick={handleBackNavigateToCashflow}
              >
                Go To Cashflow
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>

      {loading && <Loading />}
    </TableContainer>
  );
};

export default EditStage;
