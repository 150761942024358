import React from "react";
import {ListItem, TextField} from "@mui/material";
import {INoteLine} from "../../../Models/note";
import React_2 from "framer-motion/dist/framer-motion";
import {useState, useRef} from 'react'
import NoteAPI from "../../../Services/DoxleAPI/noteAPI";

interface props {
    line: INoteLine
    i: number
    setLines: React.Dispatch<React.SetStateAction<INoteLine[]>>
}

const NoteLine: React.FC<props> = ({line, i, setLines}:props) => {
    const [editState, setEditState] = useState<boolean>(false)
    const textRef: any = useRef()
    console.log(line)

    const handleKeyUp = async (e: any) => {
        if (e.key === "Enter" || e.keyCode === 13) await saveLine()
        if (e.key === "Escape" || e.keyCode === 27) {
            if (textRef?.current?.value) textRef.current.value = line.text;
            setEditState(false)
        }
    }

    const saveLine = async () => {
        console.log("textRef?.current?.value", textRef?.current?.value)
        console.log("line?.lineId", line?.lineId)
        console.log("textRef", textRef)
        if (!textRef?.current?.value) return;
        if (!line?.lineId) return;
        const response = await NoteAPI.updateLines(line.lineId, textRef.current.value)
        if (response) setLines((currentLines: INoteLine[]) => {
            return [...currentLines.map((l: INoteLine) =>{
                if (l.lineId === line.lineId) return response
                else return l
            })]
        })
        setEditState(false)
    }

    if (!editState) return (
        <ListItem
            key={line.lineId + "-#" + i}
            onClick={() =>setEditState(true)}
            sx={{
                fontFamily: "Roboto Mono",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#000000",
                background: "white",
                borderRadius: "4px",
                mb: "2px",
                pl: "10px",
            }}
        >
            {line.text}
        </ListItem>
    )

    else return (
        <ListItem
            key={line.lineId + "-#" + i}
            onClick={() =>setEditState(true)}
            sx={{
                fontFamily: "Roboto Mono",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#000000",
                background: "white",
                borderRadius: "4px",
                mb: "2px",
                pl: "10px",
            }}
        >
            <TextField
                variant="standard"
                placeholder={"Add Line ..."}
                defaultValue={line.text}
                onKeyUp={(e: any) =>
                    handleKeyUp(e)
                }
                sx={{width: "100%",}}
                inputRef={textRef}
                InputProps={{
                    disableUnderline: true,
                    sx: {
                        fontFamily: "Roboto Mono",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "10px",
                        lineHeight: "13px",
                        width: "100%",
                        "& .MuiInputBase-input": {
                            textAlign: "left",
                            // color:"#",
                            paddingBottom: "1px",
                            paddingTop: "1px",
                            maxHeight: "28px",
                            width: "100%",
                            // border: "white",
                            borderRadius:
                                "5px 5px 0px 0px",
                        },
                        "& .MuiInputLabel-formControl":
                            {
                                color: "#9974fe",
                            },
                    },
                }}
                InputLabelProps={{
                    style: { color: "#9974fe" },
                    sx: {
                        top: "-10px",
                        "& .MuiInputLabel-shrink": {
                            top: "0px !important",
                        },
                    },
                }}
            />
        </ListItem>
    )
}
export default NoteLine