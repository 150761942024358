import { Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DocketRow from './DocketRow'
import Axios from 'axios'
import { docketsBaseAddress } from "../../../settings"
import {Docket} from "../../../Models/dockets";
import XeroData from "../../../Models/xeroData";

type Props = {
  action: "review"|"success"|"fail";
  setTabIndex: Function;
  dockets: Docket[];
  setDockets: Function;
  refreshData: Function;
  handleSendtoXero: Function;
  xeroData: XeroData
}

const DocketTable = ({
    action,
    dockets,
    setTabIndex,
    setDockets,
    refreshData,
    handleSendtoXero,
    xeroData
}: Props) => {

    const clearAllDockets = async () => {
        try {
            for (let i =0; i < dockets.length; i++){
                const response = await Axios.delete(`${docketsBaseAddress}/${dockets?.[i]?.docket_id}/`)
            }
            refreshData(action)
        } catch (err) {
            console.log(err)
        }
    }

    const handleButton =  () => {
        if (action === "review") handleSendtoXero()
        else clearAllDockets()
    }


  return (
    <Box
      sx={{
        width: '92%',
        p: '0 4%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '85%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          overflow: 'scroll',
        }}
      >
        <DocketRow
            key={'docketHeader'}
            action={"header"}
            dockets={dockets}
            setDockets={setDockets}
            xeroData={xeroData}
        />
        {dockets
          ? dockets.map((docket, index) => (
              <DocketRow
                key={docket?.docket_id || index}
                action={action}
                dockets={dockets}
                setDockets={setDockets}
                docket={docket}
                xeroData={xeroData}
              />
            ))
          : null}
      </Box>

      {/*Button approve */}
      <Box
        sx={{
          width: '100%',
          height: '15%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Button
          onClick={handleButton}
          sx={{
            width: '131px',
            height: '45px',
            backgroundColor: '#754EE0',
            borderRadius: '20px',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '19px',
            textAlign: 'center',
            letterSpacing: '0.05em',
            color: '#FFFFFF',
            textTransform: 'none',
          }}
        >
          {action !== 'review' ? "Clear All" : "Send To Xero"}
        </Button>
      </Box>
    </Box>
  )
}

export default DocketTable
