import React from 'react'
import { ReactComponent as ClearLinkIcon } from '../../../../Assets/ClearLink.svg'
import { BasicCostCode } from '../../../../Models/costcode'

type Props = {
  isLinked: boolean
  unlinkFunction: Function
  costcode: BasicCostCode
}

const ButtonClearLink = ({ isLinked, unlinkFunction, costcode }: Props) => {
  return isLinked ? (
    <div
      onClick={() => unlinkFunction(costcode)}
      style={{
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: '12px',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        width: '140px',
        cursor: 'pointer',
      }}
    >
      <ClearLinkIcon />
      <div
        style={{
          fontFamily: 'IBM Plex Mono',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '13px',
          lineHeight: '17px',
          color: '#7949FF',
          height: '100%',
          paddingLeft: '5px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flex: 1,
        }}
      >
        Clear Link
      </div>
    </div>
  ) : (
    <div style={{ width: '140px', paddingLeft: '12px' }}></div>
  )
}

export default ButtonClearLink
