import React, { useState, useEffect } from 'react'
import CustomCheckbox from '../../../utilities/CheckBox/CheckBox'
import { Box, Grid, Button } from '@mui/material'
import { Folder } from '../../../Models/storage'
import storageAPI from '../../../Services/DoxleAPI/storageAPI'
import { Card } from 'react-bootstrap'
import { ReactComponent as FolderIcon } from '../../../Assets/Folder.svg'
import { ReactComponent as FolderOpenIcon } from '../../../Assets/FolderOpen.svg'
import FolderMenu from './FolderMenu'

interface props {
  folders: Folder[]
  currentFolderId: string | null
  setCurrentFolderId: Function
  setCurrentFolderName: Function
  setFolderList: Function
  tickedFolders: string[]
  setTickedFolders: Function
  setTickedFiles: Function
  handleAddFolder?: Function
  handleDeleteFolder: Function
  setDialogOpen: Function
  getFileCount: Function
}

const FolderList: React.FC<props> = ({
  folders,
  currentFolderId,
  setCurrentFolderId,
  setCurrentFolderName,
  setFolderList,
  tickedFolders,
  setTickedFolders,
  setTickedFiles,
  handleAddFolder,
  handleDeleteFolder,
  setDialogOpen,
  getFileCount,
}) => {
  const handleFolderClick = (e: any, folderId: string, folderName: string) => {
    if (e.target && typeof e.target.checked !== 'undefined') {
      return
    }
    setCurrentFolderId(folderId)
    setCurrentFolderName(folderName)
  }

  const getClassName = (folderId: string) => {
    let className = 'folderDiv'
    if (currentFolderId && currentFolderId === folderId) {
      className = 'folderDiv selected'
    }
    return className
  }

  const getChecked = (folderId: string) => {
    return tickedFolders.indexOf(folderId) !== -1
  }

  const handleCheckBox = (e: any, folderId: string) => {
    setTickedFiles([])
    if (e.target && typeof e.target.checked !== 'undefined') {
      let folders = tickedFolders
      const i = folders.indexOf(folderId)
      if (e.target.checked) {
        if (i === -1) {
          folders.push(folderId)
        }
      } else {
        if (i !== -1) {
          folders.splice(i, 1)
        }
      }
      setTickedFolders([...folders])
    }
  }

  const handleRenameFolder = (folderId: string) => {
    setTickedFolders([folderId])
    setTickedFiles([])
    setDialogOpen(true)
  }

  const handleDelete = (folderId: string) => {
    handleDeleteFolder([folderId])
  }

  useEffect(() => {}, [])

  return (
    <React.Fragment>
      {folders.length === 0 && (
        <Box sx={{ marginY: '15px' }}>
          <div
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '19px',
              color: '#000000',
            }}
          >
            Create a folder to get started
          </div>
        </Box>
      )}

      <Box
        sx={{
          flexGrow: 1,
          paddingRight: '15px',
          paddingTop: '15px',
        }}
      >
        {folders &&
          folders.map((folder) => (
            <Box
              key={folder.folderId}
              sx={{
                boxSizing: 'border-box',
                alignItems: 'center',
                height: '33px',
                cursor: 'pointer',
                background: '#F5F6F7',
                borderRadius: '4px',
                overflow: 'hidden',
                marginBottom: '11px',
                padding: '0px',
                paddingLeft: '10px',
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
              }}
              onClick={(event: any) =>
                handleFolderClick(event, folder.folderId, folder.name)
              }
            >
              {/* <CustomCheckbox
                                        checked={getChecked(folder.folderId)}
                                        onClick={(event: any) =>
                                            handleCheckBox(
                                                event,
                                                folder.folderId
                                            )
                                        }
                                        style={{
                                            position: "relative",
                                            top: "-3px",
                                            transform: "scale(0.8)",
                                        }}
                                    /> */}
              <Box sx={{ width: '16px', height: '16px' }}>
                {currentFolderId === folder.folderId ? (
                  <FolderOpenIcon />
                ) : (
                  <FolderIcon />
                )}
              </Box>

              <div
                style={{
                  fontFamily: 'Roboto Mono',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px',
                  lineHeight: '12px',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  color:
                    currentFolderId === folder.folderId ? '#4C2BA7' : '#000000',
                }}
              >
                {`${folder.name} (${getFileCount(folder.folderId)})`}
              </div>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: '2px',
                }}
              >
                <FolderMenu
                  handleRename={() => handleRenameFolder(folder.folderId)}
                  handleDelete={() => handleDelete(folder.folderId)}
                />
              </Box>
            </Box>
          ))}
      </Box>
    </React.Fragment>
  )
}

export default FolderList
