import axios from "axios";
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";
import {Specification} from "../../Models/specification";
import {Image} from "../../Models/image";
import { Costcode } from "../../Models/costcode";

const getList = async (costCodeId:string)  => {
  // !if (offline){ return dummy.billsListResponse.results; }
  // Get's a list of costcodes for a given project selected with project Id
  // Order costcode with orderBy, options =  title, startDate, endDate 
  // Pagination 100 costcodes per result - call for next page when scrolling
  let result: Specification[] = []
  if (offline){ return result; }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  try {
    let params = { cost_code: costCodeId}
    const response = await axios.get(
      baseAddress+"/specifications/",
      {
        headers: { Authorization: "Bearer " + accessToken },
        params: params
      }
    )
    console.log("SPECIFICATIONS", response.data)
    result = response.status === 200 ? response.data.results as Specification[] : [];
    console.log("SPECIFICATIONS", result)
  } catch (error) {
    console.log("ERROR FETCHING SPECS", error)
  }
  return result;
}

const addSpec = async (spec: Specification)  => {
  // Get's a list of costcodes for a given project selected with project Id
  // Order costcode with orderBy, options =  title, startDate, endDate 
  // Pagination 100 costcodes per result - call for next page when scrolling
  let result: Specification | undefined = undefined;
  if (offline){ return result}
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }

  try {
    const response = await axios.post(
      baseAddress+"/specifications/new/", spec,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    )
    result = response.status == 201 ? response.data : undefined
    console.error("SPECIFICATION ADDED", result)
  } catch (error) {
    console.error("ERROR ADDING SPEC", error)
  }
  return result
}

interface UpdateBody {  
  title?: string, 
  description?: string,
  index?: number;
  images?: Image[];
  approved?: boolean;
  timestamp?: string;
  costCode?: Costcode;
}

const updateSpec = async (specId: string, 
  { title, description, index, images, approved, timestamp, costCode}: UpdateBody)  => {
  let result: Specification | undefined = undefined;
  if (offline){ return result }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }

  try {
    let body: UpdateBody = {}
    if (title) {body.title = title;}
    if (description) {body.description = description;}
    if (index) {body.index = index;}
    if (images) {body.images = images;}
    if (approved) {body.approved = approved;}
    if (timestamp) {body.timestamp = timestamp;}
    if (costCode) {body.costCode = costCode;}
    const response = await axios.patch(
        baseAddress+"/specifications/"+specId+"/", body,
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
    )
    result = response.status == 200 ? response.data: undefined;
    console.log('SPEC UPDATED', result)
  } catch (e) {
    console.error("ERROR UPDATING SPEC", e)
  }
  return result
}


const addSpecImages = async (specId: string, files: Blob[], setProgress: Function)  => {
  interface Result {images: Image[]; errors: string[]}
  let result:Result = {images: [], errors: []}
  if (offline){ return result}
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  try {
    let company: string = JSON.parse(localStorage.getItem("currentCompany") || '{}').companyId;
    if (company === undefined || company === "") result.errors.push("Could not find companyId")

    let project: string = JSON.parse(localStorage.getItem("currentProject") || '{}').projectId;
    if (project === undefined || project === "") result.errors.push("Could not find companyId")

    let costCode: string = JSON.parse(sessionStorage.getItem("currentCostCode") || '{}').title;
    if (costCode === undefined || costCode === "") result.errors.push("Could not find companyId")

    if (company === "" || project === "" || costCode === "") { return result }

    const formData = new FormData();
    formData.append("company", company);
    formData.append("project", project);
    formData.append("costCodeTitle", costCode);
    formData.append("specificationId", specId);
    files.forEach(file =>{
      formData.append("images", file)
    })

    const response = await axios.post(
        baseAddress+"/specifications/img/", formData,
        {
          headers: { Authorization: "Bearer " + accessToken },
          onUploadProgress: progressEvent =>setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        }
    )
    result.images = response.status == 201 || response.status == 207 ? response.data.images as Image[] : []
    result.errors = response.status == 207 ? response.data.errors as string[] : []
    console.log(result.images.length + "IMAGES SAVED SUCCESSFULLY", result.images)
    console.log(result.errors.length + "ERRORS SAVING IMAGES", result.errors)
  } catch (error: any) {
    result.errors = error.status == 400 ? error.data.errors as string[] : []
    console.log("ERROR ADDING SPEC IMAGE", error)
  }
  return result
}

const removeSpec = async (specId: string)  => {
  let result:boolean = false;
  if (offline){ return result}
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  try {
    const response = await axios.delete(
        baseAddress+"/specifications/"+specId+"/",
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
    )
    result = response.status == 204;
  } catch (error) {
    console.log("ERROR REMOVING SPEC", error)
  }
  return result
}

const removeSpecImage = async (imageId: string)  => {
  let result:boolean = false;
  if (offline){ return result}
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }

  try {
    const response = await axios.delete(
        baseAddress+"/specifications/img/"+imageId+"/",
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
    )
    result = response.status == 204;
  } catch (error) {
    console.log("ERROR REMOVING SPEC IMAGE", error)
  }
  return result;
}

const getProjectPDF = async  (projectId:string) => {
  let result:string = ""
  if (offline){ return result; }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  try {
    const response = await axios.get(
        baseAddress+"/specifications/proj-pdf/"+projectId+"/",
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
    )
    console.log("SPECIFICATIONS", response.data)
    result = response.status === 200 ? response.data : "";
    console.log("SPECIFICATIONS", result)
  } catch (error) {
    console.log("ERROR FETCHING SPECS", error)
  }
  return result;
}


const getCostCodePDF = async  (costCodeId:string) => {
  let result:string = ""
  if (offline){ return result; }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  try {
    const response = await axios.get(
        baseAddress+"/specifications/cc-pdf/"+costCodeId+"/",
        {
          headers: { Authorization: "Bearer " + accessToken },
        }
    )
    console.log("SPECIFICATIONS", response.data)
    result = response.status === 200 ? response.data : "";
    console.log("SPECIFICATIONS", result)
  } catch (error) {
    console.log("ERROR FETCHING SPECS", error)
  }
  return result;
}


const specAPI = {
  getList,
  addSpec,
  addSpecImages,
  updateSpec,
  removeSpec,
  removeSpecImage,
  getProjectPDF,
  getCostCodePDF
}

export default specAPI