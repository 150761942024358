import axios from "axios"
import {Project, NewProject, ProjectPermits} from '../../Models/project';
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";
import dummy from './dummyData';
import {AddressBookCompany, AddressBookContact} from "../../Models/addressBook";

interface GetParams {
	company?: string
	ordering?: string,
}

const getList = async ({company, ordering = "end_date"}: GetParams)  => {
	// Get's a list of projects for a given company selected with companyId
	// Order projects with orderBy, options =  site_address, start_date, project_status
	// Pagination 100 costcodes per result - call for next page when scrolling
	let result: Project[] = []
	if (offline) return result;
	const accessToken: string = CookieService.get("access_token");
	if (!accessToken){
		throw "AccessTokenNotFound"
	}

	try {
		let getParams: GetParams = {ordering: ordering};
		if (company) {getParams.company = company;}
		const response = await axios.get(
			baseAddress+"/project/",
			{
				headers: { Authorization: "Bearer " + accessToken },
				params: getParams
			}
		)
		result = response.status === 200 ? response.data.results as Project[]: [];
		// console.log("PROJECTS", result)
	} catch (error) {
		console.log("ERROR FETCHING PROJECTS", error)
	}

	return result
}

const getDetailed = async (projectId: any)  => {
	// Gets one project only using the project's UUID
	// At this stage there is no additional information from the detailed
	// Future back end change will mean this call will get coordinates, users, ?costcodes?
	let result: Project | undefined = undefined;
	if (offline) return result;
	const accessToken: string = CookieService.get("access_token");
	if (!accessToken){
		throw "AccessTokenNotFound"
	}

	try {
		const response = await axios.get(
			baseAddress+"/project/detail/"+projectId+"/",
			{
				headers: { Authorization: "Bearer " + accessToken },
			}
		)
		result = response.status === 200 ? response.data as Project: undefined;
		// console.log("PROJECT", result,response)
	} catch (error) {
		console.log("ERROR FETCHING PROJECT", error)
	}

	return result
}

const remove = async (projectId: string)  => {
	/// Permanently delete project by uuid
	let result: boolean = false;
	if (offline){
		result = true; return result
	}
	const accessToken: string = CookieService.get("access_token");
	if (!accessToken){
		throw "AccessTokenNotFound"
	}

	try {
		const response = await axios.delete(
			baseAddress+"/project/"+projectId+"/",
			{
				headers: { Authorization: "Bearer " + accessToken },
			}
		)

		result = response.status === 204
		console.log("REMOVED COSTCODE", result)
	} catch (error) {
		console.error("ERROR DELETING PROJECT", error)
	}

	return result
}

const add = async (project: NewProject)  => {
	// Add a new project
	let result: Project | undefined = undefined
	if (offline){return result}
	const accessToken: string = CookieService.get("access_token");
	if (!accessToken){
		throw "AccessTokenNotFound"
	}
	project.budget = project.budget.replaceAll('$','').replaceAll(',','')
	project.contractPrice = project.contractPrice.replaceAll('$','').replaceAll(',','')
	try {
		const response = await axios.post(
			baseAddress+"/project/",
			project,
			{
				headers: { Authorization: "Bearer " + accessToken },
			}
		)
		result = response.status === 201 ? response.data as Project : undefined
		// console.log("ADDED PROJECT", result)
	} catch (error) {
		console.error("ERROR ADDING PROJECT", error)
	}
	return result
}

interface UpdateBody {
	siteAddress?: string;
	contractPrice?: number;
	budget?: number;
	startDate?: string;
	endDate?: string;
	projectPrefix?: string;
	ownerIsCompany?: boolean;
	projectStatus?: string; // Options: 'AC' = 'Active'; 'AR' = 'Archive'; 'TN' = 'Tender'; 'TM' = 'Template'
	ownerName?: string;
	ownerAbn?: string;
	ownerEmail?: string;
	ownerPhone?: string;
	trackingId?: string | null;
}

const update = async (projectId: string, {
	siteAddress,
	contractPrice,
	budget,
	startDate,
	endDate,
	projectStatus,
	projectPrefix,
	ownerName,
	ownerAbn,
	ownerEmail,
	ownerPhone,
trackingId
}: UpdateBody)  => {
	// Update one or more property of a project without affecting other values
	let result: Project | undefined = undefined
	if (offline){
		return result
	}
	const accessToken: string = CookieService.get("access_token");
	if (!accessToken){
		throw "AccessTokenNotFound"
	}

	try {
		let body: UpdateBody = {}
		if (siteAddress) {body.siteAddress = siteAddress;}
		if (contractPrice) {body.contractPrice = contractPrice;}
		if (budget) {body.budget = budget;}
		if (startDate) {body.startDate = startDate;}
		if (endDate) {body.endDate = endDate;}
		if (projectStatus) {body.projectStatus = projectStatus;}
		if (ownerName) {body.ownerName = ownerName;}
		if (ownerAbn) {body.ownerAbn = ownerAbn;}
		if (ownerEmail) {body.ownerEmail = ownerEmail;}
		if (ownerPhone) {body.ownerPhone = ownerPhone;}
		if (projectPrefix) {body.projectPrefix = projectPrefix;}
		if (trackingId) {body.trackingId = trackingId;}
		console.log(body);
		const response = await axios.patch(
			baseAddress+"/project/"+projectId+"/",
			body,
			{
				headers: { Authorization: "Bearer " + accessToken },
			}
		)
		result = response.status === 200 ? response.data as Project: undefined;
		console.log("UPDATED PROJECT", result)
	} catch (error) {
		console.error("ERROR UPDATING PROJECT", error)
	}
	return result
}

const exportCostings = async (projectId:string)  => {
	// Get's a list of projects for a given company selected with companyId
	// Order projects with orderBy, options =  site_address, start_date, project_status
	// Pagination 100 costcodes per result - call for next page when scrolling
	let result: string|undefined = undefined
	if (offline) return result;
	const accessToken: string = CookieService.get("access_token");
	if (!accessToken){
		throw "AccessTokenNotFound"
	}

	try {
		const response = await axios.get(
			baseAddress+"/project/export/",
			{
				headers: { Authorization: "Bearer " + accessToken },
				params: {project: projectId}
			}
		)
		if(response.status === 200) result = response.data as string
		// console.log("PROJECTS", result)
	} catch (error) {
		console.log("ERROR EXPORTING PROJECT", error)
	}

	return result
}


const getListWithPermits = async (company:string)  => {
	// Get's a list of projects for a given company selected with companyId
	// Order projects with orderBy, options =  site_address, start_date, project_status
	// Pagination 100 costcodes per result - call for next page when scrolling
	let result: ProjectPermits[] = []
	if (offline) return result;
	const accessToken: string = CookieService.get("access_token");
	if (!accessToken){
		throw "AccessTokenNotFound"
	}

	try {
		const response = await axios.get(
			baseAddress+"/project/permits/",
			{
				headers: { Authorization: "Bearer " + accessToken },
				params: {company: company}
			}
		)
		result = response.status === 200 ? response.data as ProjectPermits[]: [];
		console.log("PROJECTS", result)
	} catch (error) {
		console.log("ERROR FETCHING PROJECTS", error)
	}

	return result
}

const ProjectAPI = {
	getList,
	getDetailed,
	remove,
	update,
	add,
	exportCostings,
	getListWithPermits
};

export default ProjectAPI