import React from "react";
import { useEffect, useState, useCallback } from "react";
import { TableRow, TableCell, Typography } from "@mui/material";
import { Costcode } from "../../../Models/costcode";
import { User } from "../../../Models/user";
import LinkIcon from '@mui/icons-material/Link';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import dayjs from "dayjs";
// import useMountStatus  from '../../../utilities/Helper/checkMountStatus';
import EditCostCode from "../CostCodes/EditCostCode";
import "../CostCodes/costCode.css";
import { useStyles } from "./costcodeStyle";
import comments from "../Comments/Comments";
import AutocompleteCustom from "../../../utilities/Autocomplete/Autocomplete";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import userAPI from "../../../Services/DoxleAPI/userAPI";
import costCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import listIcon from "../../../Assets/ListOrdered.svg";
import listIconHighlight from "../../../Assets/ListOrderedHighlight.svg";
import fileAgreementIcon from "../../../Assets/FileAgreement.svg";
import fileTextIcon from "../../../Assets/FileText.svg";
import photosIcon from "../../../Assets/photos.svg";
import Badge from "@mui/material/Badge";
import EditPermit from "../Permits/EditPermit";
import { ReactComponent as TrashIcon } from "../../../Assets/Trash2.svg";
import AddContractor from "../Contractor/AddContractor";
import { AddressBookCompany } from "../../../Models/addressBook";
import CostCodeMenu from "../../Menu/CostCodeMenu";
import { ColumnView } from "../../../Models/columnView";
import CommentsPopover from "../Comments/CommentsPopover";
import { getCalculationColumn, calculateValue } from "./calculateColumn";
import { toFloat } from "../Measurements/konvaFunctions/generalFunctions";
import { DateRange } from "@mui/lab";
import { Dayjs } from "dayjs";
import { useSocket } from "../../../Services/Sockets/useSocket";
import BudgetChip from "./BudgetChip";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import { Profiler } from "react";
import EditQuickCostCode from "./EditCostCode";
import {DisplayMenuState} from "./QuickCostCodeTable";
interface props {
  initCostCode: Costcode;
  projectStatus: string;
  handleTakeOffClick: React.Dispatch<React.SetStateAction<Costcode | undefined>>;
  setDisplayMenu: React.Dispatch<React.SetStateAction<null|DisplayMenuState>>
}


const QuickCostCodeRow: React.FC<props> = ({
  initCostCode,
  projectStatus,
  handleTakeOffClick,
  setDisplayMenu
}) => {
  const [costcode, setCostcode] = useState<Costcode>(initCostCode);
  const title = initCostCode.title
  const budget = initCostCode.budget
  const [income, setIncome] = useState<boolean>(initCostCode.income);
  const [status, setStatus] = useState<string>(initCostCode.status);
  const completed= initCostCode.completed;

  const formatter: Intl.NumberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  interface UpdateQuickCostCode {
    qty: string;
    unit: string;
    cost: string;
    budget: string;
  }

  const handleUpdateCostCode = async (
    {
      qty,
      unit,
      cost,
      budget
    }: UpdateQuickCostCode
  ) => {
    let response: any
    let body: any = {}
    try {
      console.log(unit)
      body.costCode = costcode.costCodeId;
      if (qty) {
        const qtyNum: number = Math.round(parseFloat(
          qty.replace(/[a-zA-Z]/g, '').replaceAll('$', '').replaceAll(',', '')
        ) * 10000) / 10000;
        if (!isNaN(qtyNum)) body.qty = qtyNum;
      }
      if (unit) body.uom = unit;
      if (cost) {
        const costNum: number = Math.round(parseFloat(
          cost.replace(/[a-zA-Z]/g, '').replaceAll('$', '').replaceAll(',', '')
        ) * 100) / 100;
        if (!isNaN(costNum)) body.cost =costNum;
      }
      console.log(body)
      if (body.qty || body.uom || body.cost) response = await CostCodeAPI.updateQuickValues(body);
      if (budget) {
        const budgetNum: number = Math.round(parseFloat(
          budget.replace(/[a-zA-Z]/g, '').replaceAll('$', '').replaceAll(',', '')
        ) * 100) / 100;
        if (!isNaN(budgetNum))response = await CostCodeAPI.update(
          costcode?.costCodeId,
          {
            budget: budgetNum.toString()
          }
        );
      }
      setCostcode({...response})
    } catch (err) {
      console.log(err)
    }
  }

  const getRowClasses = () => {
    let rowClass: string = "tableRowStyle";
    if (completed) {
      rowClass += " strikeout";
    }
    if (income) {
      rowClass += " income";
    }
    return rowClass;
  };

  const getTextColor = () => {
    if (status === "N") return "#5e5f5f";
    return "#0D0D0D";
  };


  return (
    <>
      <TableRow
        className={getRowClasses()}
        key={initCostCode.costCodeId}
        style={{
          opacity: status === "N" ? "0.5" : status === "C" ? "0.7" : "1",
          display:  "table-row",
        }}
      >
        <TableCell sx={{...useStyles.tableBodyStatusFieldStyle, width: 48}}>
          <EditCostCode
            action={"status"}
            costcode={costcode}
            formatter={formatter}
            setValue={setStatus}
            textColor={getTextColor}
          />
        </TableCell>
        <TableCell
          className="strikeCell"
          style={{
            cursor: "default",
            position: "sticky",
            left: 44,
            zIndex: 49,
            background: "white",
          }}
          sx={{ color: getTextColor(), ...useStyles.tableBodyTitleFieldStyle }}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <p
              style={{
                display: "flex",
                width: "max-content",
                margin: 0,
                padding: 0,
                paddingLeft: 0,
                alignItems: "center",
                cursor: "pointer",
                height: "100%",
                zIndex: "49",
              }}
            >
              {title}
            </p>
          </div>
        </TableCell>
        <TableCell
          className="strikeCell"
          sx={{
            color: getTextColor(),
            ...useStyles.tableBodyBudgetFieldStyle,
            borderLeft: "0.3px solid rgb(224, 224, 224)",
          }}
        >
          <EditQuickCostCode
            action={"qty"}
            costcode={costcode}
            formatter={formatter}
            projectStatus={projectStatus}
            handleUpdateCostCode={handleUpdateCostCode}
          />
        </TableCell>

        <TableCell
          className="strikeCell"
          sx={{ color: getTextColor(), ...useStyles.tableBodyStyle }}
          style={{
            borderRight: "0.3px solid rgba(224, 224, 224, 1)",
            maxWidth: "40px",
            cursor: "pointer"
          }}
          onClick={()=>{handleTakeOffClick(costcode)}}
        >
          <SquareFootIcon style={{transform: "scale(0.8)"}} />
        </TableCell>
        <TableCell
          className="strikeCell"
          sx={{ color: getTextColor(), ...useStyles.tableBodyStyle }}
          style={{
            borderRight: "0.3px solid rgba(224, 224, 224, 1)",
            maxWidth: "40px",
            cursor: "pointer"
          }}
          onClick={(event:React.MouseEvent<HTMLTableCellElement, MouseEvent>)=>{setDisplayMenu({
            costCode: costcode,
            action: "takeoff",
            anchorEl: event.target
          })}}
        >
          <LinkIcon style={{transform: "scale(0.8)"}} />
        </TableCell>


        <TableCell
          className="strikeCell"
          sx={{
            color: getTextColor(),
            ...useStyles.tableBodyBudgetFieldStyle,
            borderLeft: "0.3px solid rgb(224, 224, 224)",
          }}
        >
          <EditQuickCostCode
            action={"unit"}
            costcode={costcode}
            formatter={formatter}
            projectStatus={projectStatus}
            handleUpdateCostCode={handleUpdateCostCode}
          />
        </TableCell>



        {/* Budget Cell */}
        <TableCell
          className="strikeCell"
          sx={{
            color: getTextColor(),
            ...useStyles.tableBodyBudgetFieldStyle,
            borderLeft: "0.3px solid rgb(224, 224, 224)",
          }}
        >
          <EditQuickCostCode
            action={"cost"}
            costcode={costcode}
            formatter={formatter}
            projectStatus={projectStatus}
            handleUpdateCostCode={handleUpdateCostCode}
          />
        </TableCell>

        <TableCell
          className="strikeCell"
          sx={{ color: getTextColor(), ...useStyles.tableBodyStyle }}
          style={{
            borderRight: "0.3px solid rgba(224, 224, 224, 1)",
            maxWidth: "40px",
            cursor: "pointer"
          }}
          onClick={(event:React.MouseEvent<HTMLTableCellElement, MouseEvent>)=>{setDisplayMenu({
            costCode: costcode,
            action: "pricelist",
            anchorEl: event.target
          })}}
        >
          <LinkIcon
            style={{transform: "scale(0.8)"}}

          />
        </TableCell>

        {/* Budget Cell */}
        <TableCell
          className="strikeCell"
          sx={{
            color: getTextColor(),
            ...useStyles.tableBodyBudgetFieldStyle,
            borderLeft: "0.3px solid rgb(224, 224, 224)",
          }}
        >
          <EditQuickCostCode
            action={"budget"}
            costcode={costcode}
            formatter={formatter}
            projectStatus={projectStatus}
            handleUpdateCostCode={handleUpdateCostCode}
          />
        </TableCell>

      </TableRow>
    </>
  );
};

export default QuickCostCodeRow;
