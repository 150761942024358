import React, { useState } from "react";
import "./PricelistForm.css";
import {
    Stack,
    Typography,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
} from "@mui/material";
import { useSocket } from "../../../Services/Sockets/useSocket";
import { Pricelist } from "../../../Models/pricelist";
import { Company } from "../../../Models/company";
import { ReactComponent as TrashIcon } from "../../../Assets/Trash-black.svg";
import {toFloat} from "../Measurements/konvaFunctions/generalFunctions";

interface props {
    closeModal: Function;
    companyId: string;
    selectedPricelist?: Pricelist;
}

const formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
})

const PricelistForm: React.FC<props> = ({
    companyId,
    closeModal,
    selectedPricelist,
}) => {
    const [supplier, setSupplier] = useState<string>(
        selectedPricelist?.supplier || ""
    );
    const [account, setAccount] = useState<string>(
        selectedPricelist?.costCode || ""
    );
    const [description, setDescription] = useState<string>(
        selectedPricelist?.description || ""
    );
    const [price, setPrice] = useState<string>(formatter.format(toFloat(selectedPricelist?.price || 0)));
    const [uom, setUom] = useState<string>(selectedPricelist?.uom || "");
    const [line1, setLine1] = useState<string>("");
    const [line2, setLine2] = useState<string>("");
    const [line3, setLine3] = useState<string>("");
    const [line4, setLine4] = useState<string>("");

    const socket = useSocket();

    const handleAddPricelist = () => {
        console.log("Adding Pricelist");
        console.log("-------------------------");
        console.log("Supplier =  " + supplier);
        console.log("Account  = " + account);
        console.log("Description =  " + description);
        console.log("Price =  " + price);
        console.log("UOM =  " + uom);
        console.log("Line1 =" + line1);
        console.log("Line2 =" + line2);
        console.log("Line3 =" + line3);
        console.log("Line4 =" + line4);

        const newPricelist: Pricelist = {
            costCode: account,
            description: description,
            supplier: supplier,
            uom: uom,
            price: toFloat(price.replaceAll('$','').replaceAll(',','')),
            companyId: companyId,
            pricelistId: "",
            supplierId: "",
            timeStamp: "",
            path: "",
            thumbPath: "",
        };

        //! SOCKET SENDING, LISTENING IN Pricelists.tsx
        console.log(
            "%c CLIENT = Sending Pricelist " + newPricelist,
            "background:green; color:white"
        );

        socket.send(
            JSON.stringify({
                messageType: "Pricelist",
                message: newPricelist,
            })
        );

        closeModal();
    };

    const handleUpdatePricelist = () => {
        console.log("Update Pricelist");
        console.log("----------------");
        console.log(selectedPricelist);

        const updatePricelist: Pricelist = {
            costCode: account,
            description: description,
            supplier: supplier,
            uom: uom,
            price: toFloat(price.replaceAll('$','').replaceAll(',','')),
            companyId: companyId,
            pricelistId: selectedPricelist?.pricelistId || "",
            supplierId: "",
            timeStamp: "",
            path: "",
            thumbPath: "",
        };

        //! SOCKET SENDING, LISTENING IN Pricelists.tsx
        console.log(
            "%c CLIENT = Sending Updated Pricelist " + updatePricelist,
            "background:green; color:white"
        );

        socket.send(
            JSON.stringify({
                messageType: "PricelistUpdate",
                message: updatePricelist,
            })
        );

        closeModal();
    };

    const handleDeletePricelist = () => {
        console.log("Delete Pricelist");
        console.log("----------------");
        console.log("pricelistId =  " + selectedPricelist?.pricelistId);

        //! SOCKET SENDING, LISTENING IN Pricelists.tsx
        console.log(
            "%c CLIENT = Sending Delete Pricelist " +
                selectedPricelist?.pricelistId,
            "background:green; color:white"
        );

        socket.send(
            JSON.stringify({
                messageType: "PricelistDelete",
                message: selectedPricelist,
            })
        );

        closeModal();
    };

    return (
        <>
            <Box
                sx={{
                    marginTop: "1%",
                    marginBottom: "1%",
                    paddingBottom: "5%",
                    alignSelf: "center",
                    backgroundColor: "white",
                    borderRadius: "11px",
                    width: "98%",
                    height: "92%",
                    overflow: "hidden",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                    }}
                >
                    <Typography
                        color="#000000"
                        variant="h5"
                        style={{
                            margin: "90px 60px 10px 60px",
                            borderBottom: "1.5px solid #96A2BE",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "36px",
                            lineHeight: "42px",
                        }}
                    >
                        {selectedPricelist ? "Edit Pricelist" : "Add Pricelist"}
                    </Typography>
                    <Typography
                        variant="caption"
                        style={{
                            marginLeft: "60px",
                            fontFamily: "Roboto",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "16px",
                            color: "#29334A",
                        }}
                    >
                        {selectedPricelist
                            ? "Please update price and supplier information"
                            : "Please enter new price and supplier information"}
                    </Typography>

                    <form id="pricelist-form" noValidate autoComplete="off">
                        <Stack
                            sx={{ p: "50px 60px 0 60px" }}
                            direction="row"
                            justifyContent="space-between"
                        >
                            <Stack sx={{ width: "40%" }}>
                                <Typography variant="caption">
                                    Supplier <sup className="purple"> *</sup>
                                </Typography>
                                <TextField
                                    id="filled-supplier"
                                    value={supplier}
                                    onChange={(e) => {
                                        setSupplier(e.target.value);
                                    }}
                                    fullWidth={true}
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                            </Stack>
                            <Stack sx={{ width: "40%" }}>
                                <Typography variant="caption">
                                    Account <sup className="purple"> *</sup>
                                </Typography>
                                <TextField
                                    id="filled-account"
                                    value={account}
                                    onChange={(e) => {
                                        setAccount(e.target.value);
                                    }}
                                    fullWidth={true}
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                            </Stack>
                        </Stack>

                        <Stack
                            sx={{ p: "50px 60px 0 60px" }}
                            direction="row"
                            justifyContent="space-between"
                        >
                            <Stack sx={{ width: "100%" }}>
                                <Typography variant="caption">
                                    Description <sup className="purple"> *</sup>
                                </Typography>
                                <TextField
                                    id="filled-description"
                                    value={description}
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                    }}
                                    fullWidth={true}
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                            </Stack>
                        </Stack>

                        <Stack
                            sx={{ p: "50px 60px 0 60px" }}
                            direction="row"
                            justifyContent="space-between"
                        >
                            <Stack sx={{ width: "40%" }}>
                                <Typography variant="caption">
                                    Price <sup className="purple"> *</sup>
                                </Typography>
                                <TextField
                                    id="filled-price"
                                    value={price}
                                    onChange={(e) => {
                                        setPrice(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        setPrice(formatter.format(toFloat(e.target.value.replaceAll('$', '').replaceAll(',', ''))));
                                    }}
                                    fullWidth={true}
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                            </Stack>
                            <Stack sx={{ width: "40%" }}>
                                <Typography variant="caption">
                                    UOM <sup className="purple"> *</sup>
                                </Typography>
                                <TextField
                                    id="filled-uom"
                                    value={uom}
                                    onChange={(e) => {
                                        setUom(e.target.value);
                                    }}
                                    fullWidth={true}
                                    variant="filled"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                            </Stack>
                        </Stack>

                        <Box sx={{ p: "50px 60px" }}>
                            <Typography variant="caption">Lines</Typography>
                            <Table
                                id="pricelist-lines"
                                sx={{
                                    borderCollapse: "separate !important",
                                    "& td": {
                                        background: "#F8F8F8",
                                        border: "1px solid #D6D9DF",
                                        borderWidth: "1px 0 0 0",
                                        boxSizing: "border-box",
                                        p: "10px",
                                        height: "46px",
                                    },
                                    "& tr:first-of-type td:first-of-type ": {
                                        borderRadius: "6px 6px 0 0",
                                    },
                                    "& tr:last-child td:first-of-type ": {
                                        borderRadius: "0 0 0 6px",
                                    },
                                    "& tr:last-child td:last-child ": {
                                        borderBottomRightRadius: "6px",
                                    },
                                    "& tr:last-child td": {
                                        borderBottomWidth: "1px",
                                    },
                                    "& td:first-of-type ": {
                                        borderLeftWidth: "1px",
                                    },
                                    "& td:last-child ": {
                                        borderRightWidth: "1px",
                                    },
                                    "& .MuiTextField-root": {
                                        border: "none",
                                    },
                                }}
                            >
                                <TableBody>
                                    <TableRow key="line1">
                                        <TableCell>
                                            <TextField
                                                value={line1}
                                                onChange={(e) => {
                                                    setLine1(e.target.value);
                                                }}
                                                fullWidth={true}
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="line2">
                                        <TableCell>
                                            <TextField
                                                value={line2}
                                                onChange={(e) => {
                                                    setLine2(e.target.value);
                                                }}
                                                fullWidth={true}
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="line3">
                                        <TableCell>
                                            <TextField
                                                value={line3}
                                                onChange={(e) => {
                                                    setLine3(e.target.value);
                                                }}
                                                fullWidth={true}
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="line4">
                                        <TableCell>
                                            <TextField
                                                value={line4}
                                                onChange={(e) => {
                                                    setLine4(e.target.value);
                                                }}
                                                fullWidth={true}
                                                variant="standard"
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </form>
                </div>

                <Stack
                    sx={{ p: "90px 60px 0 60px" }}
                    direction="column"
                    justifyContent="space-between"
                >
                    <Stack sx={{ width: "100%", mb: "10px" }}>
                        <Typography
                            variant="caption"
                            style={{ color: "#855cf8" }}
                        >
                            <sup>*</sup> required field
                        </Typography>
                    </Stack>
                    <Stack
                        sx={{ width: "100%" }}
                        direction="row"
                        justifyContent="flex-end"
                        spacing="11px"
                    >
                        {selectedPricelist && (
                            <Button onClick={() => handleDeletePricelist()}>
                                <TrashIcon />
                            </Button>
                        )}
                        <Button
                            onClick={() => closeModal()}
                            style={{
                                color: "#0B0B0B",
                                backgroundColor: "#D6D9DF",
                                minWidth: "188px",
                                width: "188px",
                                borderRadius: "9px",
                                textTransform: "none",
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "18px",
                                lineHeight: "21px",
                                padding: "14px",
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            onClick={() =>
                                selectedPricelist
                                    ? handleUpdatePricelist()
                                    : handleAddPricelist()
                            }
                            style={{
                                minWidth: "188px",
                                width: "188px",
                                borderRadius: "9px",
                                background: "#754EE0",
                                textTransform: "none",
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "18px",
                                lineHeight: "21px",
                                color: "#FFFFFF",
                                padding: "14px",
                            }}
                        >
                            {selectedPricelist ? "Save" : "Add"}
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </>
    );
};
export default PricelistForm;
