import { TableContainer, Table, TableBody, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Note } from "../../../Models/note";
import { Costcode } from "../../../Models/costcode";
import { Project } from "../../../Models/project";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import MultiDialog from "../../../utilities/Dialog/MultiDialog";
import { ReactComponent as EditIcon } from "../../../Assets/editIcon2.svg";
import { ReactComponent as TrashIcon } from "../../../Assets/Trash.svg";
import NoteAPI from "../../../Services/DoxleAPI/noteAPI";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutlined";
import { ReactComponent as AddIcon } from "../../../Assets/Plus - Circle - White.svg";
import NoteRow from "./NoteRow";

interface props {
    project?: Project | undefined;
    costCode?: Costcode | undefined;
    setLoading: Function;
}

const Notes: React.FC<props> = ({ project, costCode, setLoading }) => {
    const [notesList, setNotesList] = useState<Note[]>([]);
    const [newTitle, setNewTitle] = useState<string>("");
    const [editMode, setEditMode] = useState<string | undefined>(undefined);
    const [openNotesStatus, setOpenSpecsStatus] = useState(false);
    const [tickedNotes, setTickedNotes] = useState<string[]>([]);
    const [selectedNoteId, setSelectedNoteId] = useState<string>();
    const [specTitle, setNoteTitle] = useState(undefined);
    const [editIconClicked, setEditIconClicked] = useState(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const unmounted = useMountStatus();
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;

    const fetchNotes = async () => {
        setLoading(true);
        try {
            const response = await NoteAPI.getList(
                project?.projectId,
                costCode?.costCodeId
            );
            setNotesList([...response]);
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
        setLoading(false);
    };

    const handleAddNote = async () => {
        setLoading(true);
        if (user?.userId) {
            try {
                const result = (await NoteAPI.add({
                    project: project?.projectId,
                    costCode: costCode?.costCodeId,
                    title: newTitle,
                    // body: noteBody,
                    userId: user.userId,
                })) as Note;
                setNotesList([...notesList, result]);
                setDialogOpen(false);
            } catch (err) {
                err === "AccessTokenNotFound"
                    ? setLoggedIn(false)
                    : console.error(err);
            }
        }
        setLoading(false);
    };

    const handleTitleChange = (e: any) => {
        if (e.target && e.target.value) {
            setNewTitle(e.target.value);
        }
    };

    const handleClose = () => {
        setNewTitle("");
        setDialogOpen(false);
    };

    const handleEditIcon = () => {
        if (editIconClicked) {
            handleSaveButton();
            return;
        }
        if (tickedNotes.length === 1) {
            setSelectedNoteId(tickedNotes?.[0]);
            setEditMode(tickedNotes?.[0]);
            setEditIconClicked(true);
        } else {
            setEditIconClicked(false);
        }
    };

    const handleCheckBox = (e: any, specId: string) => {
        //setEditIconClicked(false)
        setTickedNotes([]);
        if (e.target && typeof e.target.checked !== "undefined") {
            let specs = tickedNotes;
            if (specs) {
                const i = specs.indexOf(specId);
                if (e.target.checked) {
                    setOpenSpecsStatus(true);

                    if (i === -1) {
                        specs.push(specId);
                    }
                } else {
                    if (tickedNotes.length === 1) {
                        setOpenSpecsStatus(false);
                    }

                    if (i !== -1) {
                        specs.splice(i, 1);
                    }
                }
                setTickedNotes([...specs]);
            }
        }

        if (e.target.checked) {
            if (tickedNotes.length === 1) {
                setSelectedNoteId(specId);
                setEditMode(specId);
            } else {
                setEditMode(undefined);
                setEditIconClicked(false);
            }
        } else {
            setEditMode(undefined);
            setEditIconClicked(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            let successful: string[] = [];
            console.log(tickedNotes);
            if (tickedNotes) {
                for (let b = 0; b < tickedNotes.length; b++) {
                    if (await NoteAPI.remove(tickedNotes[b])) {
                        successful.push(tickedNotes[b]);
                    }
                }
                let notes: Note[] = [];
                notesList.forEach((note) => {
                    if (successful.indexOf(note.noteId || "") === -1) {
                        notes.push(note);
                    }
                    setNotesList([...notes]);
                    setTickedNotes([]);
                });
            }
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
        setLoading(false);
    };

    const handleSaveButton = async () => {
        setLoading(true);
        if (user?.userId) {
            try {
                if (selectedNoteId) {
                    const selectedNote = notesList.filter(
                        (n: Note) => n.noteId === selectedNoteId
                    )[0];
                    console.log("selectedNote", selectedNote);
                    let body: any = {};

                    if (specTitle !== undefined) {
                        body.title = specTitle;
                    } else {
                        body.title = selectedNote.title;
                    }
                    body.creatorId = selectedNote.createdBy.userId;
                    body.costCode = selectedNote.costCode;
                    body.userId = user.userId;
                    console.log(body);

                    if (body.title) {
                        const response = await NoteAPI.update(
                            selectedNoteId,
                            body
                        );
                        console.log("response", response);
                        if (response) {
                            let specIndex = notesList.findIndex(
                                (row) => row.noteId === response.noteId
                            );
                            notesList[specIndex] = response;
                            setNotesList([...notesList]);
                        }
                    }
                }

                setEditMode(undefined);
                setEditIconClicked(false);
            } catch (err) {
                err === "AccessTokenNotFound"
                    ? setLoggedIn(false)
                    : console.error(err);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchNotes();
    }, [costCode]);

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    justifyContent: "flex-end",
                    width: "100%",
                    height: "27px",
                    maxHeight: "27px",
                }}
            >
                <Button
                    variant="contained"
                    startIcon={<EditIcon />}
                    size="small"
                    // disabled={tickedNotes.length !== 1}
                    sx={{
                        display: tickedNotes.length === 1 ? "flex" : "none",
                        textTransform: "none",
                        background: "#36bfa8",
                        borderRadius: "4px",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "9px",
                        lineHeight: "11px",
                        padding: "4px 9px",
                        "&:hover": {
                            background: "#3dd9bf",
                        },
                    }}
                    onClick={(event: any) => handleEditIcon()}
                >
                    {!editIconClicked ? "Edit Note" : "Confirm"}
                </Button>
                <Button
                    variant="contained"
                    startIcon={<DeleteOutlineIcon />}
                    size="small"
                    // disabled={tickedNotes.length === 0}
                    sx={{
                        display: tickedNotes.length > 0 ? "flex" : "none",
                        textTransform: "none",
                        background: "#CD326A",
                        borderRadius: "4px",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "9px",
                        lineHeight: "11px",
                        padding: "4px 9px",
                        "&:hover": {
                            background: "#EF548C",
                        },
                    }}
                    onClick={(event: any) => handleDelete()}
                >
                    Delete Note
                </Button>

                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    size="small"
                    sx={{
                        textTransform: "none",
                        background: "#4C2BA780",
                        borderRadius: "4px",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "9px",
                        lineHeight: "11px",
                        padding: "4px 9px",
                        "&:hover": {
                            background: "#4C2BA7",
                        },
                    }}
                    onClick={(event: any) => setDialogOpen(true)}
                >
                    Note
                </Button>
            </div>

            <TableContainer sx={{ paddingTop: "39px" }}>
                <Table
                    size="small"
                    aria-label="collapsible table"
                    sx={{
                        backgroundColor: "#FFFFFF",
                    }}
                >
                    <TableBody
                        sx={{
                            "& > tr": {
                                background: "#F5F6F7",
                                borderBottom: "2px solid white",
                                "& > td:first-of-type ": {
                                    borderRadius: "4px 0 0 4px",
                                },
                                "& > td:last-child ": {
                                    borderRadius: "0 4px 4px 0",
                                },
                                "& > td": {
                                    background: "#F5F6F7",
                                },
                            },
                        }}
                    >
                        {notesList ? (
                            notesList.map((note: Note, index: number) => (
                                <NoteRow
                                    key={note.noteId}
                                    action="specs"
                                    row={note}
                                    index={index + 1}
                                    costcode={costCode}
                                    editMode={editMode}
                                    setEditMode={setEditMode}
                                    editIconClicked={editIconClicked}
                                    openNotesStatus={openNotesStatus}
                                    handleCheckBox={handleCheckBox}
                                    setNoteTitle={setNoteTitle}
                                    handleSaveButton={handleSaveButton}
                                />
                            ))
                        ) : (
                            <></>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <MultiDialog
                open={dialogOpen || false}
                dialogTitle="Add New Note"
                inputs={[
                    {
                        dialogText: "",
                        handleInputChange: handleTitleChange,
                    },
                ]}
                handleClose={handleClose}
                handleSubmit={handleAddNote}
            />
        </div>
    );
};
export default Notes;
