import { TextField } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { User } from "../../../../Models/user";
import { ITimeRange, populateTimeRow } from "../CommonResources";
import { makeStyles } from "@material-ui/core/styles";

type Props = {
  selectedUser: User;
  rowSize: number;
  boundLeft: number;
  setcurrentHoverUser: Function;
  setonAddNoticeMode: Function;
  pageTimeRange: ITimeRange;
  setcurrentMousePosX: Function;
  setaddedTimeValue: Function;
  addedTimeValue: ITimeRange | undefined;
  handleAddNoticeAPI: Function;
  viewType: "daily" | "weekly";
};
const useStyles = makeStyles((theme) => ({
  label: {
    align: "center",
  },
}));
const TeamViewAddNoticeRow = ({
  selectedUser,
  rowSize,
  boundLeft,
  setcurrentHoverUser,
  setonAddNoticeMode,
  pageTimeRange,
  setcurrentMousePosX,
  setaddedTimeValue,
  addedTimeValue,
  handleAddNoticeAPI,
  viewType,
}: Props) => {
  const classes = useStyles();
  const paddingOfRow: number = 5;
  const widthRatioOfCell: number =
    1 / (pageTimeRange.end - pageTimeRange.start + 1);
  const widthOfACell =
    (1 / (pageTimeRange.end - pageTimeRange.start + 1)) * rowSize;
  const [currentCellHover, setcurrentCellHover] = useState<number | undefined>(
    undefined
  );

  const [onDraggingMode, setonDraggingMode] = useState<boolean>(false);
  const [startPosOfDragDiv, setstartPosOfDragDiv] = useState<
    string | undefined
  >(undefined);
  const [widthOfDragDiv, setwidthOfDragDiv] = useState<string | undefined>(
    undefined
  );

  const [displayDivSpecs, setdisplayDivSpecs] = useState<
    { width: string; absPos: string } | undefined
  >(undefined);
  const [selectedCell, setselectedCell] = useState<number>(0);

  const [description, setDescription] = useState<string>("");
  //handle mousedown when start dragging on certain cell to trigger dragging mode
  const handleMouseDown = (
    mouseDownEvent: React.MouseEvent,
    cellOrder: number
  ) => {
    setonDraggingMode(true);
    setselectedCell(cellOrder);
    const startPos: number = widthOfACell * cellOrder + paddingOfRow; //will be on left edge of the selected cell (start time) + paddingleft of that cell

    const startWidth: number = mouseDownEvent.pageX - boundLeft - startPos + 5;
    const startPosXOfMouse = mouseDownEvent.pageX;
    setstartPosOfDragDiv(`${(startPos / rowSize) * 100}%`);
    setwidthOfDragDiv(`${(startWidth / rowSize) * 100}%`);

    setcurrentMousePosX(mouseDownEvent.pageX);
    function onMouseMove(mouseMoveEvent: any) {
      setcurrentMousePosX(mouseMoveEvent.pageX);
      setwidthOfDragDiv(startWidth - startPosXOfMouse + mouseMoveEvent.pageX);
    }
    function onMouseUp(mouseUpEvent: any) {
      document.body.removeEventListener("mousemove", onMouseMove);
      //this ratio is calculated when users release mouse
      //ratio = (*Final width since mouse released* / *total width size of each row in px* / *width size of each cell* )
      const endRatio = Math.ceil(
        (startWidth - startPosXOfMouse + mouseUpEvent.pageX) /
          rowSize /
          widthRatioOfCell
      );

      console.log("DUE RATIO:", endRatio);
      //the width of notice item will be automatically stretched to fill the rest width of the cell where the endpoint of mouse fell into that cell
      //ex: if endpoint of mouse fall into half width of cell "10am" and the notice start in at cell "7am", the full width of notice item will be "10 - 7 + 1" * *width of each cell* - *total padding size*
      // setwidthOfDragDiv(`${((ratio * widthOfACell - 30) / rowSize) * 100}%`);

      //only update if the width ratio is different with the start width ratio, i.e when dragging happen, users actually dragged the item and update a new width
      // ex: when user start dragging, then starting point of mouse fell into cell "8am", the endpoint when release mouse fell into cell "10am"=> trigger api update function
      if (mouseUpEvent.pageX !== startPosXOfMouse) {
        setdisplayDivSpecs({
          width: `${((endRatio * widthOfACell - 30) / rowSize) * 100}%`,
          absPos: `${(startPos / rowSize) * 100}%`,
        });
        setdisplayDivSpecs({
          width: `${((endRatio * widthOfACell - 30) / rowSize) * 100}%`,
          absPos: `${(startPos / rowSize) * 100}%`,
        });
        setaddedTimeValue({
          start: populateTimeRow(pageTimeRange)[cellOrder],
          end: populateTimeRow(pageTimeRange)[cellOrder] + endRatio - 1,
        });
        setstartPosOfDragDiv(undefined);
        setwidthOfDragDiv(undefined);
      } else {
        setstartPosOfDragDiv(undefined);
        setwidthOfDragDiv(undefined);
        setonDraggingMode(false);
      }

      setcurrentMousePosX(undefined);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  };

  //handle key esc to exit add notice mode
  const handleKeyDown = (ev: globalThis.KeyboardEvent) => {
    if (ev.key === "Escape") {
      setcurrentHoverUser(undefined);
      setonAddNoticeMode(undefined);
      setaddedTimeValue(undefined);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);
  return (
    <motion.div
      initial={{ flex: 0 }}
      animate={{ flex: 1 }}
      transition={{ width: { duration: 0.2, ease: "easeInOut" } }}
      className="teamview-add-notice-row-root"
      style={{ position: "relative" }}
    >
      {onDraggingMode ? (
        startPosOfDragDiv && widthOfDragDiv ? (
          <AnimatePresence>
            <motion.div
              exit={{ scaleX: [1, 0] }}
              animate={{ scaleX: [0, 1] }}
              transition={{ scaleX: { duration: 0.1 } }}
              style={{
                backgroundColor: "#7949ff",
                width: widthOfDragDiv,
                // position: (notice.start && daily) ? 'absolute' : 'relative',
                // left: notice.start ? notice.start : 0,
                position: "absolute",
                left: startPosOfDragDiv, //
                top: `${paddingOfRow}px`,
                borderRadius: "28px",
                cursor: "e-resize",
                height: `calc(100% - ${paddingOfRow * 2}px)`,
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
              onMouseDown={(event) => handleMouseDown(event, selectedCell)}
            ></motion.div>
          </AnimatePresence>
        ) : displayDivSpecs ? (
          <AnimatePresence>
            <motion.div
              exit={{ scaleX: [1, 0] }}
              animate={{ scaleX: [0, 1] }}
              transition={{ scaleX: { duration: 0.1 } }}
              style={{
                backgroundColor: "#7949ff",
                width: displayDivSpecs.width,
                // position: (notice.start && daily) ? 'absolute' : 'relative',
                // left: notice.start ? notice.start : 0,
                position: "absolute",
                left: displayDivSpecs.absPos, //
                top: `${paddingOfRow}px`,
                borderRadius: "28px",
                cursor: "e-resize",
                height: `calc(100% - ${paddingOfRow * 2}px)`,
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <TextField
                value={description}
                onChange={(event) => setDescription(event.currentTarget.value)}
                autoFocus={true}
                placeholder="New Description"
                sx={{
                  flex: 1,
                  border: 0,
                  padding: "0 0 0 15px",
                  "& .MuiInputBase-input": {
                    flex: 1,
                    height: "20px",
                    padding: 0,
                    fontFamily: "IBM Plex Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#FFFFFF",
                  },
                  "& .MuiOutlinedInput-input": {
                    flex: 1,
                    height: "20px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    display: "none",
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                  },
                }}
                onKeyDown={(event) => {
                  if (event.key === "Escape") {
                    setonDraggingMode(false);
                    setDescription("");
                  } else if (event.key === "Enter") {
                    if (description && addedTimeValue) {
                      handleAddNoticeAPI(
                        description,
                        addedTimeValue.start,
                        addedTimeValue.end
                      );
                      setcurrentHoverUser(undefined);
                      setonAddNoticeMode(undefined);
                      setaddedTimeValue(undefined);
                    }
                  }
                }}
                // onKeyDown={(event) => {
                //   if (event.key === "Escape") setonEditDescriptionMode(false);
                //   else if (event.key === "Enter") {
                //     if (newDescription) {
                //       editNotice({ description: newDescription });
                //       setNewDescription("");
                //     }
                //     setonEditDescriptionMode(false);
                //   }
                // }}
                // value={newDescription}
                // onChange={(event) => setNewDescription(event.currentTarget.value)}
              />
            </motion.div>
          </AnimatePresence>
        ) : null
      ) : (
        populateTimeRow(pageTimeRange).map((timeValue, timeIndex) => {
          return (
            <motion.div
              className="add-notice-time-cell"
              whileHover={{ opacity: 1 }}
              key={`addNotice-${timeValue}#${timeIndex}`}
              style={{
                display: "flex",
                cursor: !onDraggingMode ? "pointer" : "e-resize",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: `${100 / populateTimeRow(pageTimeRange).length}%`,
              }}
              onMouseEnter={() => setcurrentCellHover(timeValue)}
              onMouseLeave={() => setcurrentCellHover(undefined)}
              onMouseDown={(event) => handleMouseDown(event, timeIndex)}
            >
              {viewType === "daily"
                ? timeValue <= 12
                  ? timeValue
                  : timeValue - 12
                : timeValue}
              {viewType === "daily" ? (timeValue >= 12 ? "Pm" : "Am") : null}
            </motion.div>
          );
        })
      )}
    </motion.div>
  );
};

export default TeamViewAddNoticeRow;
