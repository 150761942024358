import React, { useState, useEffect, DragEvent } from "react";
import "./Checklist.css";
import { User } from "../../../Models/user";
import { Collapse, Grid, IconButton, Typography, Box } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import { Checklist } from "../../../Models/checklist";
import ChecklistAPI from "../../../Services/DoxleAPI/checklistAPI";
import { Comment } from "../../../Models/comments";
import { AddressBookCompany } from "../../../Models/addressBook";
import { ReactComponent as TrashIcon } from "../../../Assets/Trash2.svg";
import { ReactComponent as CheckIcon } from "../../../Assets/CheckFill.svg";
import { ReactComponent as UncheckIcon } from "../../../Assets/ChekOpen.svg";
import { ReactComponent as CommentIcon } from "../../../Assets/CheckComment.svg";
import { ReactComponent as AttachmentIcon } from "../../../Assets/CheckAttach.svg";
import { ReactComponent as UserIcon } from "../../../Assets/CheckUser.svg";
import titleCase from "../../../utilities/Helper/titleCase";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DropzoneArea } from "material-ui-dropzone";
import { Image } from "../../../Models/image";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutlined";
import AnswerIcons from "./AnswerIcons";
import Comments from "../Comments/Comments";
import CustomizedCircularProgress from "../../../utilities/Progress/CustomizedCircularProgress";
import AddComment from "../Comments/AddComment";

interface props {
    checklist: Checklist;
    deleteChecklistItem: Function;
    users?: User[];
    contractors?: AddressBookCompany[];
}

const ChecklistItem: React.FC<props> = ({
    checklist,
    deleteChecklistItem,
    users,
    contractors,
}) => {
    const [checklistItem, setChecklistItem] = useState<Checklist>(checklist);
    const [hovering, setHovering] = useState<boolean>(false);
    const [editState, setEditState] = useState<boolean>(false);
    const [question, setQuestion] = useState<string>(checklistItem.question);
    const [answer, setAnswer] = useState<boolean>(
        checklistItem.answer === "YES"
    );
    const [openSpecs, setOpenSpecs] = useState(false);
    const [images, setImages] = useState<Image[]>(
        checklistItem.images ? checklistItem.images : []
    );

    const [currentlyDragging, setCurrentlyDragging] = useState<boolean>(false);
    const [currentlyUploading, setCurrentlyUploading] =
        useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [imagesLength, setImagesLength] = useState<number>(4);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [commentList, setCommentList] = useState<Array<Comment>>(
        checklistItem.comments ? checklistItem.comments  : []
    );
    const [loading, setLoading] = useState<boolean>(false);

    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;

    const handleTickChange = async (event: any, checklistId: string) => {
        try {
            if (!user?.userId) return;
            const updateAnswer = answer ? null : "YES";
            const response = (await ChecklistAPI.updateChecklist(
                checklistId,
                { answer: updateAnswer },
                user.userId
            )) as Checklist;
            if (response.answer === "YES") {
                setAnswer(true);
                setChecklistItem({ ...checklistItem, answer: "YES" });
            } else {
                setAnswer(false);
                setChecklistItem({ ...checklistItem, answer: null });
            }
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
    };

    const handleDoubleClick = (e: any) => {
        setEditState(true);
    };

    const handleQuestionChange = (e: any) => {
        setQuestion(e.target.value);
    };

    const handleQuestionUpdate = async (checklistId: string | undefined) => {
        try {
            if (!user?.userId) return;
            if (!checklistId) return;
            const response = await ChecklistAPI.updateChecklist(
                checklistId,
                { question: question },
                user.userId
            );
            setEditState(false);
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
    };

    const handleFileDrop = async (
        newFiles: Blob[],
        checklistId: string | undefined
    ) => {
        try {
            if (!checklistId) return;
            if (newFiles.length === 0) return;
            setCurrentlyUploading(true);
            setCurrentlyDragging(false);
            setLoading(true)
            const response = await ChecklistAPI.addChecklistImage(
                checklistId,
                newFiles,
                setProgress
            );
            console.log(response);
            if (response) {
                if (images && images.length > 0) {
                    setImages([...images, ...response.images]);
                } else {
                    setImages([...response.images]);
                }
            }
            setCurrentlyUploading(false);
            setLoading(false)
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
    };

    const handleDeleteImage = async (image: Image, checklist: Checklist) => {
        try {
            const response = await ChecklistAPI.removeChecklistImage(
                image.imageId
            );
            if (response && checklist.images) {
                let newImages: Image[] = [];
                images.forEach((img: Image) => {
                    if (img.imageId !== image.imageId) {
                        newImages.push(img);
                    }
                });
                setImages([...newImages]);
            }
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
    };

    const handleCheckListChange = async (
        checklistInput: Checklist,
        value: "YES" | "NO" | "NA"
    ) => {
        try {
            const setValue = value === checklistInput.answer ? null : value;
            if (!checklistInput.checklistId) return;
            if (!user?.userId) {
                console.log("User not found");
                setLoggedIn(false);
                return;
            }
            const result = (await ChecklistAPI.updateChecklist(
                checklistInput.checklistId,
                { answer: setValue },
                user.userId
            )) as Checklist;
            setChecklistItem({ ...checklistItem, answer: result.answer });
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
    };

    const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setCurrentlyDragging(true);
    };

    const handleDragExit = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setCurrentlyDragging(false);
    };

    const handleQuestionKeyUp = async (
        e: any,
        checklistId: string | undefined
    ) => {
        if (e.keyCode !== 13) return;
        if (!checklistId) return;
        handleQuestionUpdate(checklistId);
    };

    useEffect(() => {}, []);

    return (
        <>
            <Grid
                key={checklistItem.checklistId}
                container
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                sx={{
                    minHeight: "35px",
                    background: "#F1F2F5",
                    borderRadius: "5px",
                    mt: "3px",
                    mb: "1px",
                }}
                alignItems="center"
            >
                <Grid
                    item
                    xs={1}
                    display="flex"
                    justifyContent="center"
                    height="100%"
                >
                    <IconButton
                        disableRipple
                        sx={{
                            "&:hover": { background: "none" },
                            m: 0,
                            p: 0,
                            paddingLeft: "16px",
                            width: "100%",
                            height: "100%",
                            transform: "scale(1.6)"
                        }}
                        onClick={(event: any) =>
                            handleTickChange(
                                event,
                                checklistItem.checklistId || ""
                            )
                        }
                    >
                        {answer ? <CheckIcon /> : <UncheckIcon />}
                    </IconButton>
                </Grid>
                <Grid
                    item
                    xs={6}
                    onDoubleClick={handleDoubleClick}
                    sx={{
                        color: "#000000",
                        fontFamily: "Roboto Mono",
                        fontStyle: "normal",
                        fontWeight: 300,
                        fontSize: "11px",
                        lineHeight: "13px",
                        py: "5px",
                    }}
                    onBlur={() => setEditState(false)}
                >
                    {!editState ? (
                        <>{titleCase(question)}</>
                    ) : (
                        <TextareaAutosize
                            autoFocus
                            value={question}
                            onChange={handleQuestionChange}
                            onKeyUp={(event: any) =>
                                handleQuestionKeyUp(
                                    event,
                                    checklistItem.checklistId
                                )
                            }
                            onBlur={() =>
                                handleQuestionUpdate(checklistItem.checklistId)
                            }
                            style={{
                                width: "100%",
                                textAlign: "left",
                                color: "#000000",
                                fontFamily: "Roboto Mono",
                                fontStyle: "normal",
                                fontWeight: 300,
                                fontSize: "11px",
                                letterSpacing: "normal",
                                lineHeight: "13px",
                                padding: 0,
                                margin: 0,
                                border: "none",
                                outline: "none",
                                background: "none",
                            }}
                        />
                    )}
                </Grid>
                <Grid item xs={1} gap={"5px"} display="flex">
                    <UserIcon />
                    <AttachmentIcon />
                    {checklistItem.comments && checklistItem.comments?.length === 0 
                        ? <CommentIcon />
                        : <AddComment checklist={checklistItem} clickFunction={() => setOpenSpecs(true)} />
                    }
                </Grid>
                <Grid item xs={1}>
                    {hovering && !editState ? (
                        <TrashIcon
                            onClick={() =>
                                deleteChecklistItem(checklistItem.checklistId)
                            }
                            style={{ cursor: "pointer", marginTop: "2px" }}
                        />
                    ) : null}
                </Grid>
                <Grid item xs={2}>
                    {
                        <AnswerIcons
                            checklist={checklistItem}
                            handleCheckListChange={handleCheckListChange}
                            tableMode={true}
                        />
                    }
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpenSpecs(!openSpecs)}
                        sx={{
                            color: "#000000",
                            textAlign: "right",
                        }}
                    >
                        {openSpecs ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </Grid>
            </Grid>
            <Collapse
                in={openSpecs}
                timeout="auto"
                unmountOnExit
                style={{ width: "100%", overflow: "hidden" }}
            >
                <Grid
                    container
                    sx={{
                        margin: 0,
                        background: "#F1F2F5",
                        borderRadius: "5px",
                    }}
                    onDragEnter={handleDragEnter}
                    onDragExit={handleDragExit}
                    onMouseLeave={handleDragExit}
                    // onDragLeave={handleDragExit}
                >            
                    <Grid item xs={12} sx={{ borderTop: "1px solid white",}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1.2}
                            padding="20px"
                            position="relative"
                        >
                            {loading && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        zIndex: 999,
                                    }}
                                >
                                    <CustomizedCircularProgress />
                                </Box>
                            )}
                            {currentlyDragging ||
                            currentlyUploading ||
                            images.length === 0 ? (
                                <Grid item xs={12} className="checklist-item">
                                    {currentlyUploading ? (
                                        <Typography textAlign="center">
                                            Uploading ({progress} %)
                                        </Typography>
                                    ) : (
                                        <DropzoneArea
                                            onDrop={(files: Blob[]) =>
                                                handleFileDrop(
                                                    files,
                                                    checklistItem.checklistId
                                                )
                                            }
                                            // filesLimit={10}
                                            maxFileSize={15000000}
                                            dropzoneText="Drag and Drop File(s)"
                                            showPreviews={false}
                                            showPreviewsInDropzone={false}
                                            showFileNamesInPreview={false}
                                            showAlerts={false}
                                        />
                                    )}
                                </Grid>
                            ) : null}
                            {images ? (
                                images.map((image: Image, i: number) => (
                                    <Grid
                                        item
                                        key={image.imageId}
                                        xs={
                                            imagesLength === 1
                                                ? 12
                                                : imagesLength === 2 ||
                                                  imagesLength === 4
                                                ? 6
                                                : 4
                                        }
                                        onMouseEnter={() => setEditMode(true)}
                                        onMouseLeave={() => setEditMode(false)}
                                        sx={{ position: "relative" }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <img
                                                src={image.url}
                                                alt="main"
                                                className="specImg"
                                            />
                                            {editMode ? (
                                                <div
                                                    className="deleteSpecImage"
                                                    style={{
                                                        position: "absolute",
                                                        top: "3px",
                                                        right: "3px",
                                                    }}
                                                >
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() =>
                                                            handleDeleteImage(
                                                                image,
                                                                checklistItem
                                                            )
                                                        }
                                                        sx={{
                                                            color: "#0044CC",
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <DeleteOutlineIcon
                                                            style={{
                                                                color: "#0044cc",
                                                                transform:
                                                                    "scale(0.8)",
                                                            }}
                                                        />
                                                    </IconButton>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </Grid>
                                ))
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ borderTop: "1px solid white", paddingTop: "20px"}}
                        >
                            <Comments
                                checklistId={checklistItem.checklistId}
                                commentList={commentList}
                                setCommentList={setCommentList}
                                userList={users}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Collapse>
        </>
    );
};

export default ChecklistItem;
