import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    tableHeadingStyle: {
        paddingRight: 0,
        paddingLeft: 0,
        "& > tr": {
            "& > th": {
                fontFamily: "Roboto !important",
                fontStyle: "normal !important",
                fontWeight: 500 + "!important",
                fontSize: "11px !important",
                lineHeight: "12px !important",
                color: "#6A3CEC !important",
                background: "#EFF1F5 !important",
                borderBottom: "0px !important",
                textAlign: "center",
                height: "29px",
                padding: 0,
            },
            "& > th:first-of-type ": {
                borderRadius: "5px 0 0 5px",
                paddingLeft: "21px",
                textAlign: "left",
            },
            "& > th:last-child ": { borderRadius: "0 5px 5px 0" },
        },
    },
    tableBodyStyle: {
        height: "10px",
        fontFamily: "Roboto !important",
        fontStyle: "normal !important",
        fontWeight: 500 + "!important",
        fontSize: "11px !important",
        lineHeight: "12px !important",
        color: "#000000",
        padding: "0px",
        textAlign: "center",
        position: "relative",
        width: "88px",
    },
    tableDataStyle: {
        "& > tr > td:first-of-type": {
            textAlign: "left !important",
        },
        "& > tr > td": {
            textAlign: "center",
        },
        "& > tr": {
            borderBottom: "1px solid #E6E6E6",
        },
        "& tr": {
            "& td": {
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "11px",
                lineHeight: "13px",
                color: "#000000",
            },
        },
    },
}));
