import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Project, ProjectCashflow } from "../../../Models/project";
import { BasicStage } from "../../../Models/stage";
import {
  checkEqualMonthWithoutDate,
  getLastDateOfMonth,
  getMonthName,
  IMonthInfo,
} from "../../../utilities/FunctionUtilities";
import CashflowColorBoard from "./CashflowColorBoard";
import { getStageColor } from "./CashflowCommonResources";
import CashflowContextMenu from "./CashflowContextMenu";

type Props = {
  project: ProjectCashflow;
  rowOrder: number;
  monthRange: IMonthInfo[];
  widthSizeOfDivider: number;
};
export interface IContextMenuPos {
  left: number;
  top: number;
}
const CashflowTableRow = ({
  project,
  rowOrder,
  monthRange,
  widthSizeOfDivider,
}: Props) => {
  interface IselectedStageForEditColor {
    stage: BasicStage;
    absPosLeft: string | undefined;
    absPosRight: string | undefined;
    rowOrder: number;
  }
  //########################### STATES ######################################
  const [selectedStageForEditColor, setselectedStageForEditColor] = useState<
    IselectedStageForEditColor | undefined
  >(undefined);

  const [projectOfStage, setprojectOfStage] =
    useState<ProjectCashflow>(project);

  interface IDatarowPos {
    width: number;
    height: number;
    boundLeft: number;
    boundTop: number;
  }
  const [dataRowPos, setdataRowPos] = useState<IDatarowPos | undefined>(
    undefined
  );

  const [contextMenuPos, setcontextMenuPos] = useState<
    IContextMenuPos | undefined
  >(undefined);

  //##########################################################################
  const handleClickStagePayment = (
    stage: BasicStage,
    absPosLeft: string | undefined,
    absPosRight: string | undefined,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.nativeEvent.button === 0) {
      if (
        selectedStageForEditColor &&
        selectedStageForEditColor.stage.stageId === stage.stageId
      )
        setselectedStageForEditColor(undefined);
      else
        setselectedStageForEditColor({
          stage: stage,
          absPosLeft: absPosLeft,
          absPosRight: absPosRight,
          rowOrder: rowOrder,
        });
    } else if (event.nativeEvent.button === 2) {
      event.preventDefault();
      if (dataRowPos) {
        setcontextMenuPos({
          left: event.nativeEvent.clientX - dataRowPos.boundLeft,
          top: event.nativeEvent.clientY - dataRowPos.boundTop,
        });
      }
    }
  };

  const cashflowDataRowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cashflowDataRowRef.current) {
      setdataRowPos({
        width: cashflowDataRowRef.current?.getBoundingClientRect()
          .width as number,
        height: cashflowDataRowRef.current?.getBoundingClientRect()
          .height as number,
        boundLeft: cashflowDataRowRef.current?.getBoundingClientRect()
          .left as number,
        boundTop: cashflowDataRowRef.current?.getBoundingClientRect()
          .top as number,
      });
    }
  }, [cashflowDataRowRef.current]);
  return (
    <RootCashflowTableRow
      key={`project#${project.projectId}-row#${rowOrder}`}
      className="cashflow-table-data-row"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        opacity: {
          duration: 0.8,
          ease: "circInOut",
        },
      }}
      style={{
        filter: selectedStageForEditColor
          ? selectedStageForEditColor.rowOrder !== rowOrder
            ? "blur(3px)"
            : undefined
          : undefined,
      }}
    >
      <TableTitleCell>
        {rowOrder + 1}. {project.siteAddress}
      </TableTitleCell>

      <TableDataRow ref={cashflowDataRowRef} style={{ position: "relative" }}>
        {monthRange.map((month, monthIndex) => {
          // console.log("ROW MONTH:", getMonthName(month.monthValue, "full"));
          return (
            <TableDataCell
              key={`dataCell#${month.monthValue}-${monthIndex}`}
              style={{
                width: `calc((100% - ${
                  widthSizeOfDivider * (monthRange.length - 1)
                }px)/3 - 10px)`,
                borderRight:
                  monthIndex < monthRange.length - 1
                    ? `${widthSizeOfDivider}px solid #E9E1FF`
                    : undefined,
                padding: "0 5px 0 5px",
                zIndex: contextMenuPos ? 0 : undefined,
              }}
            >
              {projectOfStage.stages.map((stage, stageIndex) => {
                if (
                  checkEqualMonthWithoutDate(
                    new Date(stage.targetDate),
                    new Date(month.yearValue, month.monthValue, 15)
                  ) === true
                ) {
                  return (
                    <>
                      <StagePaymentContainer
                        whileTap={{ opacity: 0.7 }}
                        whileHover={{ scale: 1.2, zIndex: 100 }}
                        key={`$project#${stage.project}-${stage.stageId}-${stageIndex}`}
                        transition={{
                          opacity: { duration: 0.4 },
                          scale: { duration: 0.4 },
                        }}
                        style={{
                          left:
                            new Date(stage.targetDate).getDate() < 22
                              ? `calc(${
                                  (new Date(stage.targetDate).getDate() /
                                    getLastDateOfMonth(month.monthValue)) *
                                  100
                                }% )`
                              : undefined,
                          pointerEvents:
                            contextMenuPos || selectedStageForEditColor
                              ? "none"
                              : undefined,
                          backgroundColor: stage.color,
                          cursor: "pointer",
                          right:
                            new Date(stage.targetDate).getDate() > 22
                              ? `calc(${
                                  (getLastDateOfMonth(month.monthValue) -
                                    new Date(stage.targetDate).getDate()) /
                                  getLastDateOfMonth(month.monthValue)
                                }% + 2px)`
                              : undefined,
                        }}
                        onClick={(event) =>
                          handleClickStagePayment(
                            stage,
                            new Date(stage.targetDate).getDate() < 22
                              ? `calc(${
                                  (new Date(stage.targetDate).getDate() /
                                    getLastDateOfMonth(month.monthValue)) *
                                  100
                                }%)`
                              : undefined,
                            new Date(stage.targetDate).getDate() > 22
                              ? `calc(${
                                  (getLastDateOfMonth(month.monthValue) -
                                    new Date(stage.targetDate).getDate()) /
                                  getLastDateOfMonth(month.monthValue)
                                }% + 2px)`
                              : undefined,
                            event
                          )
                        }
                        onContextMenu={(event) => {
                          handleClickStagePayment(
                            stage,
                            new Date(stage.targetDate).getDate() < 22
                              ? `calc(${
                                  (new Date(stage.targetDate).getDate() /
                                    getLastDateOfMonth(month.monthValue)) *
                                  100
                                }%)`
                              : undefined,
                            new Date(stage.targetDate).getDate() > 22
                              ? `calc(${
                                  (getLastDateOfMonth(month.monthValue) -
                                    new Date(stage.targetDate).getDate()) /
                                  getLastDateOfMonth(month.monthValue)
                                }% + 2px)`
                              : undefined,
                            event
                          );
                        }}
                      >
                        <PaymentAmountText>
                          ${parseInt(stage.value.toString()).toLocaleString()}
                        </PaymentAmountText>
                        <StageTitle>{stage.title}</StageTitle>
                      </StagePaymentContainer>
                      <AnimatePresence>
                        {selectedStageForEditColor &&
                          selectedStageForEditColor.stage.stageId ===
                            stage.stageId && (
                            <CashflowColorBoard
                              selectedStageForEditColor={
                                selectedStageForEditColor
                              }
                              setselectedStageForEditColor={
                                setselectedStageForEditColor
                              }
                              projectOfStage={projectOfStage}
                              setprojectOfStage={setprojectOfStage}
                            />
                          )}
                      </AnimatePresence>
                    </>
                  );
                }
              })}
            </TableDataCell>
          );
        })}

        <AnimatePresence>
          {contextMenuPos && (
            <CashflowContextMenu
              selectedProject={project}
              contextMenuPos={contextMenuPos}
              setcontextMenuPos={setcontextMenuPos}
            />
          )}
        </AnimatePresence>
      </TableDataRow>
    </RootCashflowTableRow>
  );
};

export default React.memo(CashflowTableRow);

const RootCashflowTableRow = styled(motion.div)`
  display: flex;
  flex-direction: row;
  width: 100%;

  border-bottom: 1px solid #cbdae2;
`;

const TableTitleCell = styled.div`
  height: 100%;
  width: calc(18% - 10px);
  padding-left: 10px;
  max-width: 300px;
  min-width: 250px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #272a43;
`;

const TableDataRow = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  border-left: 1px solid #cbdae2;
`;
const TableDataCell = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const StagePaymentContainer = styled(motion.div)`
  border-radius: 7px;
  width: 94px;
  height: 34px;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: calc(100% -34px) / 2;
`;

const PaymentAmountText = styled.div`
  text-align: center;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  height: 50%;
  width: 100%;
  color: #ffffff;
`;

const StageTitle = styled.div`
  font-family: "Nostromo Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */
  color: #000000;
  text-overflow: elipsis;
  overflow: hidden;
  height: 50%;
  width: 100%;
  white-space: nowrap;
  text-align: center;
`;

const ColorPickerContainer = styled(motion.div)`
  height: 0;
  width: 100%;
  position: absolute;
`;
