import React, { ReactNode, useEffect, useState } from 'react'
import '../AccountMapping.css'
import Dropdown from 'react-dropdown'
import { ReactComponent as DropdownCostcodeDown } from '../../../../Assets/DropdownCostcodeDisable.svg'

import { ReactComponent as DropdownCostcodeBlackDown } from '../../../../Assets/Chevrondownblack.svg'
import { XeroCostcodeData } from '../../../../Models/xeroData'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { IUnlinkedItem } from '../AccountMappingApp'
import { BasicCostCode } from '../../../../Models/costcode'
import { ClickAwayListener } from '@mui/base'

type Props = {
  costcode: BasicCostCode
  isLinked: boolean
  linkedItem: XeroCostcodeData | undefined
  xeroCostcodeData: XeroCostcodeData[]
  matchedItems: string | undefined
  unlinkedItems: IUnlinkedItem[]
  setUnlinkedItems: Function
}

const DropdownCostcodeLink = ({
  isLinked,
  linkedItem,
  xeroCostcodeData,
  matchedItems,
  unlinkedItems,
  setUnlinkedItems,
  costcode,
}: Props) => {
  interface IDropdownItems {
    label: ReactNode
    value: string
    className: string
  }
  const [costcodeOptions, setCostcodeOptions] = useState<IDropdownItems[]>([])

  //####################AUTOCOMPLETE CONTROL#########
  interface IAutocompleteItem {
    label: string
    id: string
  }

  //state to store all options of autocomplete, initial value is formated from xeroCostcodeData
  const [autocompleteOptions, setAutocompleteOptions] = useState<
    IAutocompleteItem[]
  >(
    xeroCostcodeData.map((data) => {
      return { label: data.Name, id: data.AccountID }
    })
  )

  //to control current selectedValue of autocomplete
  //initial value will be the matchedItem which is got by comparing costcode title and xeroCoostcodeData name
  const [selectedValue, setselectedValue] = useState<IAutocompleteItem | null>(
    matchedItems
      ? autocompleteOptions.filter((option) => option.id === matchedItems)[0]
      : { label: 'Choose an account', id: 'UNLINKED' }
  )

  //handle selected another item from autocomplete
  const handleSelectAutocompleteItem = (option: IAutocompleteItem) => {
    setselectedValue(option)

    if (unlinkedItems.length > 0) {
      let newUnlinkedItemList: IUnlinkedItem[] = []
      unlinkedItems.map((item) => {
        if (item.costcode.costCodeId === costcode.costCodeId)
          newUnlinkedItemList.push({
            ...item,
            trackingId: option.id,
          })
        else newUnlinkedItemList.push(item)
      })

      setUnlinkedItems([...newUnlinkedItemList])
    } else {
      setUnlinkedItems([
        ...unlinkedItems,
        { costcode: costcode, trackingId: option.id },
      ])
    }
  }

  //#######################################################

  const handleSelectDropdownItem = (item: string) => {}

  return isLinked ? (
    <div
      style={{
        width: '260px',
        height: '30px',
        backgroundColor: 'transparent',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #DCDEEA',
        cursor: 'pointer',
      }}
    >
      {/*dropdown Container */}
      <div
        className='costcodeDropdownRootContainer'
        style={{ color: isLinked ? ' #9596A0' : 'red' }}
      >
        <Dropdown
          disabled={isLinked}
          className='dropdownCostcodeStyle'
          controlClassName='controlDropdownStyle'
          menuClassName='menuCostcodeDropdownStyle'
          // placeholderClassName='placeholderDropdownStyle'
          options={costcodeOptions}
          onChange={(item) => handleSelectDropdownItem(item.value)}
          value={linkedItem ? linkedItem.Name : ''}
          placeholder={linkedItem ? linkedItem.Name : ''}
        />
      </div>

      {/*Icon Container */}
      <div
        style={{
          width: '29px',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderLeft: '1px solid #DCDEEA',
        }}
      >
        <DropdownCostcodeDown />
      </div>
    </div>
  ) : (
    <div
      style={{
        width: '260px',
        height: '30px',
        backgroundColor: 'transparent',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <div style={{ height: '100%', flex: 1 }}>
        <Autocomplete
          freeSolo
          options={autocompleteOptions}
          sx={{
            height: '30px',
            '.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input':
              { padding: 0 },
            '& .mui-23-listbox': {
              backgroundColor: 'blue',
            },
          }}
          renderOption={(props: any, option) => {
            return (
              <div
                onClick={() => {
                  handleSelectAutocompleteItem(option)
                }}
                key={option.id + option.label}
                style={{
                  width: '260px',
                  height: '30px',
                  backgroundColor: 'white',
                  borderBottom: '1px solid #DCDEEA',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  fontFamily: 'IBM Plex Mono',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '13px',
                  lineHeight: '17px',
                  paddingLeft: '10px',
                  /* identical to box height */
                  cursor: 'pointer',
                }}
              >
                {option.label}
              </div>
            )
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              key={params.id}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              sx={{
                border: '1px solid #DCDEEA',
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',

                '.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root': {
                  paddingLeft: '10px !important',
                  cursor: 'pointer',
                },
                '& .MuiAutocomplete-inputRoot': { paddingRight: 0 },
                '& .css-ymft0r-MuiFormControl-root-MuiTextField-root': {
                  height: '30px',
                  paddingLeft: '10px !important',
                  position: 'unset',
                },
                '.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root': {
                  content: 'none',
                  display: 'none',
                  backgroundColor: 'transparent',
                },
                '.css-146uvw0-MuiFormControl-root-MuiTextField-root': {
                  border: 'none',
                },
                '& .MuiOutlinedInput-root': {
                  padding: 0,
                  height: '30px',
                },
                '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                  padding: 0,
                },
                '& .MuiAutocomplete-input': {
                  fontFamily: 'IBM Plex Mono',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '13px',
                  lineHeight: '17px',
                  /* identical to box height */
                  color: '#000000',
                },
                '.css-1ohjnjt-MuiFormControl-root-MuiTextField-root': {
                  height: '30px',
                },
                '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                  display: 'none',
                },
                '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                  paddingLeft: '10px',
                },
              }}
            />
          )}
          value={selectedValue}
          // inputValue={inputSelectedValue}
          // onInputChange={(event, newInputValue) => {
          //   setInputSelectedValue(newInputValue)
          // }}
        />
      </div>
      {/*Icon Container */}
      <div
        style={{
          width: '29px',
          height: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #DCDEEA',
          borderLeft: 'none',
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
        }}
      >
        <DropdownCostcodeBlackDown />
      </div>
    </div>
  )
}

export default DropdownCostcodeLink
