import React from 'react';
import { useEffect, useState, useCallback} from 'react';
import {
    TableBody,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableFooter,
    TextField,
    Divider, ListItemButton
} from '@mui/material';
import accountingService from '../../../Services/accountingService';
import { Project } from "../../../Models/project";
import { User } from "../../../Models/user";
import useMountStatus  from '../../../utilities/Helper/checkMountStatus';
import Loading from '../../../utilities/Lottie/Loading';
import PermitModal from './PermitModal';
import PermitRow from  './PermitRow';
import "../CostCodes/costCode.css";
import { useStyles } from './permitStyle';
import WindowDimensions from '../../../utilities/WindowDimensions/windowDimension';
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import userAPI from "../../../Services/DoxleAPI/userAPI";
import {Permit} from "../../../Models/permit";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {LocalizationProvider, MobileDatePicker} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {formatScheduleDate} from "../../../utilities/constants";
import {Checklist} from "../../../Models/checklist";
import {Comment} from "../../../Models/comments";
import ChecklistAPI from "../../../Services/DoxleAPI/checklistAPI";
import checklistGroupAPI from "../../../Services/DoxleAPI/checklistGroupAPI";
import addressBookAPI from "../../../Services/DoxleAPI/addressBookAPI";
import {AddressBookCompany} from "../../../Models/addressBook";
import { useSocket } from "../../../Services/Sockets/useSocket";



interface props{
    selectedProject: Project | null;
    editTitleMode: boolean;
    setEditTitleMode: Function;
    permitFilter: string | null;
}

interface commentView{checklist?: Checklist, permit?: Permit}

const PermitTable: React.FC<props> = ({
    selectedProject,
    editTitleMode,
    setEditTitleMode,
    permitFilter,
}) => {
    const [permits, setPermits] = useState<Array<Permit>>([]);
    const [users, setUsers] = useState<Array<User>>([]);
    const [contractors, setContractors] = useState<Array<AddressBookCompany>>([]);
    const [selectedPermit, setSelectedPermit] = useState<Permit | undefined >(undefined);
    const [tabIndex, setTabIndex] = useState<string>('4');
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [commentView, setCommentView] = React.useState<commentView>({});
    const [showAddPermitDialog, setShowAddPermitDialog] = React.useState<boolean>(false);
    const unmounted = useMountStatus();
    const [loading, setLoading] = useState<boolean>(false);
    const {height, width} = WindowDimensions();
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;
    const [addTitle, setAddTitle] = useState<string>('');
    const [addStartDate, setAddStartDate] = useState<string>(new Date().toISOString().substring(0,10));
    const [addEndDate, setAddEndDate] = useState<string>(new Date().toISOString().substring(0,10));
    const [addQuestions, setAddQuestions] = useState<string[]>(['']);

    const formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    })


    const handleRowClick = (permit: Permit, tab: string) => {
        if (permit.checklistGroupId===selectedPermit?.checklistGroupId) setSelectedPermit(undefined);
        else setSelectedPermit(permit);
        // setOpenModal(true);
    }

    const handleClose = () => {
        setOpenModal(false);
        setSelectedPermit(undefined);
        setShowAddPermitDialog(false)
        setAddTitle('')
        setAddStartDate(new Date().toISOString().substring(0,10))
        setAddEndDate(new Date().toISOString().substring(0,10))
        setAddQuestions([''])
        setCommentView({})
    }

    const handleAddPermit = async () => {
        if (!(selectedProject?.projectId && user?.userId)) return
        const result = await checklistGroupAPI.add({
            checklistGroupId: "",
            project: selectedProject.projectId,
            title: addTitle,
            timeStamp: new Date().toISOString(),
            startDate: addStartDate,
            endDate: addEndDate,
            addQuestions: addQuestions,
        }, user.userId) as Permit
        if (result) setPermits([...permits, result])
    }

    const fetchPermits = async() => {
        setLoading(true)
        if(selectedProject){
            try {
                const response = await checklistGroupAPI.getList(selectedProject.projectId, null, null) as Permit[];
                if (unmounted) return;
                console.log(response)
                setPermits([...response]);
            } catch (err) {
                err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
            }
        }
        setLoading(false)
    }

    const fetchUsers = async() => {
        if(selectedProject){
            try {
                const response = await userAPI.getList(undefined, selectedProject.projectId) as User[];
                if (unmounted) return;
                console.log("response user perit table", response)
                setUsers([...response])
            } catch (err) {
                err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
            }
        }
    }

    const handleAddPermitClicked = () => {
        setShowAddPermitDialog(true)
    }
    const handleAddItem = () => {
        setAddQuestions([...addQuestions, ''])
    }

    const handleQuestionChange = (i: number, value: string)=> {
        let updatedQuestions: string[] = []
        addQuestions.forEach((question:string, ind) =>{
            if (ind === i) updatedQuestions.push(value)
            else updatedQuestions.push(question)
        })
        setAddQuestions(updatedQuestions)
    }

    const handleCommentIconClicked = ({checklist, permit} : commentView) =>{
        setCommentView({checklist, permit})
        setOpenModal(true);
    }

    const handleDateChange = (type:string, valueInput:Date | null) => {
        if (valueInput && type === "start") setAddStartDate(valueInput.toISOString().substring(0,10))
        if (valueInput && type === "end") setAddEndDate(valueInput.toISOString().substring(0,10))
    }

    const handleDeletePermit = async (clickedPermit: Permit) =>{
        if (!clickedPermit.checklistGroupId) return;
        const result = await checklistGroupAPI.remove(clickedPermit.checklistGroupId)
        if (!result) return
        let updatedPermitList: Permit[] = []
        permits.forEach((permit: Permit) => {
            if (permit.checklistGroupId !== clickedPermit.checklistGroupId) updatedPermitList.push(permit)
        })
        setPermits([...updatedPermitList])
    }

    const fetchContractors = async() => {
        if(selectedProject){
            try {
                const response = await addressBookAPI.getList(selectedProject.company) as AddressBookCompany[];
                if (unmounted) return;
                setContractors([...response])
            } catch (err) {
                err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
            }
        }
    }

    useEffect(() => {
        fetchPermits();
        fetchUsers();
        fetchContractors();
    },[selectedProject]);

    
    //! <--- SOCKETS LISTENER
    const socket = useSocket();
    const onMessage = useCallback(
        (message) => {
        const data = JSON.parse(message?.data);

        const serverStatus: string = data.status;
        const messageType: string = data.message_type;

        if (messageType === "SocketDataUpdate") {
            if (serverStatus === "SUCCESS") {
                const { startDate, endDate, assignedUsers, assignedContractor} = data.message;
                const { checklistId, checklistGroupId} = data.message;
        
                if (checklistId || checklistGroupId) {
                    console.log(
                        "%cDOXLE SOCKETS=RECEIVE SUCCESS DATA",
                        "background:green; color:white"
                    );
                    let newPermits: Permit[] = permits
                    newPermits.forEach((permit: Permit) => {
                        if (permit.checklistItems) {
                            let newChecklists: Checklist[] = []
                            permit.checklistItems.forEach((checklist: Checklist) => {
                                if (checklist.checklistId === checklistId) {
                                    console.log("updating checklist", checklistId)
                                    if (assignedUsers) checklist.assignedUsers = assignedUsers;
                                    if (assignedContractor) checklist.assignedContractor = assignedContractor;
                                    if (assignedContractor === null) checklist.assignedContractor = undefined;
                                }
                                newChecklists.push(checklist)
                            })
                            permit.checklistItems = newChecklists
                        }
                        if (permit.checklistGroupId === checklistGroupId) {
                            console.log("updating permit", checklistGroupId)
                            if (startDate) permit.startDate = startDate;
                            if (endDate) permit.endDate = endDate;
                        }
                    });
                    setPermits([...newPermits])
                }
    
            } else {
              console.log(
                "%cDOXLE SOCKETS=DATA ERROR",
                "background:red; color:white"
              );
            }
          }
        
        },
        [permits]
    );

    useEffect(() => {
        socket.addEventListener("message", onMessage);

        return () => {
        socket.removeEventListener("message", onMessage);
        };
    }, [socket, onMessage]);
    //! SOCKETS --->

    return(
        <>
        <PermitModal
            commentView={commentView}
            openModal={openModal}
            closeModal={handleClose}
            selectedProjectAddress={selectedProject && selectedProject.siteAddress}
            height={height}
            width={width}
            users={users}
        />
        <div id="costcodeGroup">
            <div>
                {                
                loading
                ?
                <>
                <div style={{marginTop:"15%"}}>
                    <Loading/>
                </div>
                </>
                :
                // <TableContainer sx={{ maxHeight: width < 928 ? (height-57)+"px" : "95.5vh" }}>
                // <TableContainer sx={{ height: "calc(100vh - 108px - 48px)" }}>
                <>
                <TableContainer sx={{ height: "calc(100vh - 48px - 48px)" }}>
                    <Table size="small" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow className="tableHeadStyle" sx={{background: "none"}}>
                                <TableCell sx={useStyles.tableHeadingStyle}>#</TableCell>
                                <TableCell style={{position: "sticky", left: 32, zIndex: 99}} sx={useStyles.tableHeadingCostcodeFieldStyle}>PERMIT</TableCell>
                                <TableCell sx={{maxWidth: "40px", ...useStyles.tableHeadingStyle}}/>
                                <TableCell sx={{maxWidth: "40px", ...useStyles.tableHeadingStyle}}/>
                                <TableCell sx={useStyles.tableHeadingStyle}>ASSIGNEE</TableCell>
                                <TableCell sx={useStyles.tableHeadingStyle}>CONTRACTOR</TableCell>
                                <TableCell sx={useStyles.tableHeadingStyle}>START</TableCell>
                                <TableCell sx={useStyles.tableHeadingStyle}>DUE</TableCell>
                                <TableCell sx={useStyles.tableHeadingStyle}>STATUS</TableCell>
                                <TableCell sx={useStyles.tableHeadingStyle}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="tableBodyStyle">
                        { 
                            permits.map((permit: Permit) => (
                                <PermitRow
                                    key={permit.checklistGroupId}
                                    initPermit={permit}
                                    selectedPermit={selectedPermit}
                                    onPermitClicked={handleRowClick}
                                    handleCommentIconClicked={handleCommentIconClicked}
                                    users={users}
                                    handleDeletePermit={handleDeletePermit}
                                    editTitleMode={editTitleMode}
                                    setEditTitleMode={setEditTitleMode}
                                    filter={permitFilter}
                                    contractors={contractors}
                                />
                            ))
                        }
                            { !selectedPermit ?
                                <TableRow>
                                    <TableCell colSpan={10} onClick={handleAddPermitClicked} style={{
                                        cursor: "pointer",
                                        textAlign: "center",
                                        position: "sticky",
                                        left: 0,
                                        zIndex: 49,
                                        background: "white",
                                        opacity: "0.7",
                                        color: "#5a36be"
                                    }}>ADD A PERMIT</TableCell>
                                </TableRow>
                            : null
                            }
                        </TableBody>

                    </Table>
                </TableContainer>
                <Dialog
                open={showAddPermitDialog}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                >
                    <DialogTitle id="scroll-dialog-title">New Permit</DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText
                            id="scroll-dialog-description"
                            tabIndex={-1}
                            style={{alignContent: 'right'}}
                        >
                            <TextField
                                label={'Permit Title'}
                                value={addTitle}
                                fullWidth
                                onChange={(event: any) => setAddTitle(event?.target?.value)}
                            />
                            <Divider sx={{margin: '10px'}}/>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    label={"Start Date"}
                                    openTo="day"
                                    inputFormat="dd.MM.yyyy"
                                    value={new Date(addStartDate)}
                                    onChange={(valueInput: Date | null) => handleDateChange("start", valueInput)}
                                    renderInput={(params) => <TextField style={{width:"50%"}} {...params}  />}
                                />
                                <MobileDatePicker
                                    label={"Due Date"}
                                    openTo="day"
                                    inputFormat="dd.MM.yyyy"
                                    value={new Date(addEndDate)}
                                    onChange={(valueInput: Date | null) => handleDateChange("end", valueInput)}
                                    renderInput={(params) => <TextField style={{width:"50%"}} {...params}  />}
                                />
                            </LocalizationProvider>

                            <Divider sx={{margin: '10px'}}/>
                            {
                                addQuestions.map((question: string, i:number) =>(
                                    <TextField
                                        key={"question"+i}
                                        label={'Question '+i}
                                        value={question}
                                        fullWidth
                                        multiline
                                        onChange={(event: any) => handleQuestionChange(i, event?.target?.value)}
                                    />
                                ))
                            }
                            <Button onClick={handleAddItem}>Add Item</Button>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleAddPermit}>Add</Button>
                    </DialogActions>

                </Dialog>
                </>
                }
            </div>
        </div> 
        </> 
    )
}

export default PermitTable