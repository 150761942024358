import {Notice, NoticeCategory} from "../../../Models/notice";
import { User } from "../../../Models/user";
import Status from "../Status/Status";
import Pin from "../Pins/Pin";
import {
  Input,
  TextField,
  Autocomplete,
  ListItemButton,
  Button,
  Typography,
} from "@mui/material";
import AutocompleteCustom from "../../../utilities/Autocomplete/Autocomplete";
import React, { useState, useEffect } from "react";
import NoticeAPI from "../../../Services/DoxleAPI/noticeAPI";
import { highlightText } from "../../../utilities/Markdown/markdown";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import noticeAPI from "../../../Services/DoxleAPI/noticeAPI";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArchiveIcon from "../../../Assets/Archive.svg";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateRangePicker,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { formatScheduleDate } from "../../../utilities/constants";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import CustomCheckbox from "../../../utilities/CheckBox/CheckBox";
import { ReactComponent as ProjectPopperIcon } from "../../../Assets/projectDropDownIcon.svg";
import DeleteIcon from "../../../Assets/Trash-blue.svg";
import MarkIcon from "../../../Assets/mark-blue.svg";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as PlusCircle } from "../../../Assets/PlusCircleWhite.svg";
import titleCase from "../../../utilities/Helper/titleCase";
import { useSocket } from "../../../Services/Sockets/useSocket";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";
import { Dayjs } from "dayjs";
import { addLeadingZeros } from "../CostCodes/EditCostCode";
import { makeStyles } from "@mui/styles";
interface props {
  notice: Notice;
  setNotice?: React.Dispatch<React.SetStateAction<Notice>>;
  action: string;
  descriptionColour?: string | undefined;
  setDescriptionColour?: React.Dispatch<React.SetStateAction<string | undefined>>;
  categoryList: NoticeCategory[];
  setCategoryList?: React.Dispatch<React.SetStateAction<NoticeCategory[]>>;
  notices: Notice[];
  setNotices: React.Dispatch<React.SetStateAction<Notice[]>>;
  userList?: User[];
  menuClose?: Function;
  forceUpdate?: Function;
  closeModal?: Function;
  completed?: boolean;
  setCompleted?: Function;
  openModal?: boolean;
  dateRange?: DateRange<Dayjs>;
  setDateRangeValue?: Function;
  handleDeleteSingleNotice?: Function;
}

const EditNotice: React.FC<props> = ({
  notice,
  setNotice,
  action,
  descriptionColour,
  setDescriptionColour,
  categoryList,
  setCategoryList,
  notices,
  setNotices,
  userList,
  menuClose,
  forceUpdate,
  closeModal,
  completed,
  setCompleted,
  openModal,
  dateRange,
  setDateRangeValue,
  handleDeleteSingleNotice,
}) => {
  const [description, setDescription] = useState<string>(notice.description);
  const [pinned, setPinned] = useState<boolean>(notice.pinned);
  const [privated, setPrivated] = useState<boolean>(notice.isPrivate);
  const [startDate, setStartDate] = useState<string | null>(notice.startDate || null);
  const [dueDate, setDueDate] = useState<string | null>(notice.dueDate || null);
  const [displayInputField, setDisplayInputField] = useState<string | undefined>(undefined);
  const [category, setCategory] = useState<NoticeCategory|undefined>(
      categoryList.filter(cat => cat.noticeCategoryId === notice.categoryId)?.[0]
  );
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, user } = authContext;
  const { height, width } = WindowDimensions();
  const limitAssignee = width < 928 ? 1 : 2;

  const socket = useSocket();

  // const editDescriptionState = (event: any) => {
  //   event.target.value = description;
  // };

  // const resetDescription = (event: any) => {
  //   event.target.value = description;
  //   setDisplayInputField(undefined);
  // };

  // const handleHighlightText = (inputText: string) => {
  //   let outputValue = [
  //     <div
  //       key={notice.noticeId}
  //       style={{
  //         color:
  //           descriptionColour === notice.noticeId || notice.pinned
  //             ? "#E9121F"
  //             : "black",
  //       }}
  //     >
  //       {highlightText(inputText, notice.noticeId)}
  //     </div>,
  //   ];
  //   return outputValue;
  // };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
    if (setNotice) {
      setNotice((prev: Notice) => ({
        ...prev,
        description: event.target.value,
      }));
    }
  };

  const notifyAssignedUsersViaSocket = (assignedUserIds: string[]) => {
    // send notification for users via socket
    console.log(
      "%c CLIENT = Sending notification for " +
        assignedUserIds.length +
        " users",
      "background:green; color:black"
    );
    assignedUserIds.forEach((userAssigned) => {
      socket.send(
        JSON.stringify({
          messageType: "Notification",
          message: { notifiedUser: userAssigned },
        })
      );
    });
  };

  const updateAssignedUsersViaSocket = (assignedUsers: any) => {
    // send notification for users via socket
    console.log(
      "%c CLIENT = Sending update assignee for notice " + notice.noticeId,
      "background:green; color:black"
    );

    socket.send(
      JSON.stringify({
        messageType: "SocketDataUpdate",
        message: {
          noticeId: notice.noticeId,
          assignedUsers: assignedUsers,
        },
      })
    );
  };

  const handleEditAssignedUsers = async (objectInput: any) => {
    try {
      const assignedUsers = objectInput.value;
      const assignedUserIds: string[] = [];
      assignedUsers.forEach((user: User) => {
        user.userId ? assignedUserIds.push(user.userId) : console.log("");
      });
      let response = (await noticeAPI.update(notice.noticeId, user, {
        assignedUsers: assignedUserIds,
      })) as Notice;
      let updatedNotices: Notice[] = [];
      notices.forEach((notice) => {
        if (notice.noticeId === response.noticeId) {
          response.assignedUsers = assignedUsers;
          updatedNotices.push(response);
        } else {
          updatedNotices.push(notice);
        }
      });
      setNotices([...updatedNotices]);
      console.log("[...updatedNotices]", [...updatedNotices]);

      if (assignedUserIds.length > 0)
        notifyAssignedUsersViaSocket(assignedUserIds);
      updateAssignedUsersViaSocket(assignedUsers);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const handlePinChange = async (event: any, notice: Notice) => {
    try {
      console.log(false + " for " + notice.noticeId);
      await NoticeAPI.update(notice.noticeId, user, {
        pinned: !pinned,
      });
      setPinned(!pinned);
      if (setDescriptionColour !== undefined) {
        setDescriptionColour(undefined);
      }
      let noticeIndex = notices.findIndex(
        (noticeItem) => noticeItem.noticeId === notice.noticeId
      );
      notices[noticeIndex].pinned = !pinned;
      setNotices([...notices]);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }

    if (menuClose) menuClose();
  };

  // const handleDeleteNotice = async (noticeId: string) => {
  //   try {
  //     await noticeAPI.remove(noticeId);
  //   } catch (err) {
  //     err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
  //   }
  // };

  const handleMarkChange = async (event: any, notice: Notice) => {
    try {
      console.log(
        "change isComplete " + { completed } + " for " + notice.noticeId
      );
      await NoticeAPI.update(notice.noticeId, user, {
        isComplete: !completed,
      });
      if (setCompleted) setCompleted(!completed);
      let noticeIndex = notices.findIndex(
        (noticeItem) => noticeItem.noticeId === notice.noticeId
      );
      notices[noticeIndex].isComplete = !completed;
      setNotices([...notices]);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
    if (menuClose) menuClose();
  };

  const handlePrivateChange = async (notice: Notice) => {
    try {
      console.log(
        "change isprivated " + { privated } + " for " + notice.noticeId
      );
      await NoticeAPI.update(notice.noticeId, user, {
        isPrivate: !privated,
      });
      setPrivated(!privated);
      let noticeIndex = notices.findIndex(
        (noticeItem) => noticeItem.noticeId === notice.noticeId
      );
      notices[noticeIndex].isPrivate = !privated;
      setNotices([...notices]);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
    if (menuClose) menuClose();
  };

  const handleArchiveChange = async (event: any, notice: Notice) => {
    if (menuClose) menuClose();
    try {
      await NoticeAPI.update(notice.noticeId, user, {
        isArchived: !notice.isArchived,
      });
      let noticeIndex = notices.findIndex(
        (noticeItem) => noticeItem.noticeId === notice.noticeId
      );
      notices[noticeIndex].isArchived = !notice.isArchived;
      setNotices([...notices]);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const handleFollowupChange = async (event: any, notice: Notice) => {
    if (menuClose) menuClose();
    try {
      await NoticeAPI.update(notice.noticeId, user, {
        isFollowup: !notice.isFollowup,
      });
      let noticeIndex = notices.findIndex(
        (noticeItem) => noticeItem.noticeId === notice.noticeId
      );
      notices[noticeIndex].isFollowup = !notice.isFollowup;
      setNotices([...notices]);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const handleEditCategory = async (value: NoticeCategory, noticeId: string) => {
    try {
      const noticeCategoryIds = categoryList.map(cat=>cat.noticeCategoryId)
      if (noticeCategoryIds.indexOf(value.noticeCategoryId) >=0){
        const result = await NoticeAPI.update(noticeId, user, {
          categoryId: value.noticeCategoryId,
        });

      } else {
        const result = await NoticeAPI.addCategory({
          noticeCategoryId: "",
          company: notice.company,
          categoryTitle: value.categoryTitle,
          isArchived: false,
          isPermit: false,
          index: categoryList.length
        })
        if (result) {
          const noticeResult = await NoticeAPI.update(noticeId, user, {
            categoryId: result.noticeCategoryId,
          });
          setCategory(result);
          if (setCategoryList) setCategoryList(prevState => [...prevState, result])
        }
      }
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }

    if (forceUpdate) forceUpdate(); // re-fetch notices

    if (menuClose) menuClose(); // close menu
    if (closeModal) closeModal(); // close modal
  };

  const handleOnClick = (e: any) => {
    e?.target?.children?.[0]?.click();
  };

  const handleClickAdornment = (e: any) => {
    if (!e?.currentTarget?.previousSibling?.click) return;
    e?.currentTarget?.previousSibling?.click();
  };

  const updateDateViaSocket = (startDate: any, dueDate: any) => {
    //! SOCKET SENDING
    console.log(
      "%cCLIENT = Sending notice date update ",
      "background:green; color:white"
    );
    socket.send(
      JSON.stringify({
        messageType: "SocketDataUpdate",
        message: {
          noticeId: notice.noticeId,
          startDate,
          dueDate,
        },
      })
    );
  };

  const handleDateNotice = async (dateInput: DateRange<Dayjs>) => {
    if (!(dateInput?.[0]?.date() && dateInput?.[1]?.date())) return;
    const startDateInput = `${dateInput[0]?.year()}-${addLeadingZeros(
      dateInput[0]?.month() + 1,
      2
    )}-${addLeadingZeros(dateInput[0]?.date(), 2)}`;
    const dueDateInput = `${dateInput[1]?.year()}-${addLeadingZeros(
      dateInput[1]?.month() + 1,
      2
    )}-${addLeadingZeros(dateInput[1]?.date(), 2)}`;

    console.log(startDateInput);
    console.log(dueDateInput);
    let newStartDate = new Date(startDateInput);
    let newEndDate = new Date(dueDateInput);

    //set default value of hour is 9am
    newStartDate.setHours(9);
    newEndDate.setHours(9);
    try {
      let response = (await noticeAPI.update(notice.noticeId, user, {
        startDate: newStartDate.toISOString(),
        dueDate: newEndDate.toISOString(),
      })) as Notice;
      if (response) {
        let noticeIndex = notices.findIndex(
          (noticeItem) => noticeItem.noticeId === notice.noticeId
        );
        notices[noticeIndex].startDate = response.startDate;
        setNotices([...notices]);
        updateDateViaSocket(response.startDate, null);
      }
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const handleEditDueDateNotice = async (noticeId: string) => {
    console.log("dueDate", dueDate);
    if (notice.dueDate === dueDate) {
      console.log("same as current");
      return;
    }
    try {
      let response = (await noticeAPI.update(notice.noticeId, user, {
        dueDate: dueDate,
      })) as Notice;
      if (response) {
        let noticeIndex = notices.findIndex(
          (noticeItem) => noticeItem.noticeId === notice.noticeId
        );
        notices[noticeIndex].dueDate = response.dueDate;
        setNotices([...notices]);
        updateDateViaSocket(null, response.dueDate);
      }
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const handleSubmitNotice = async () => {
    try {
      const response = (await NoticeAPI.update(notice.noticeId, user, {
        description: notice.description,
      })) as Notice;

      if (response) {
        const noticeIndex = notices.findIndex(
          (noticeItem) => noticeItem.noticeId === notice.noticeId
        );
        notices[noticeIndex].description = notice.description;
        setNotices([...notices]);
        if (closeModal) closeModal();
      }
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };


  const getFirstLine = () => {
    const splitDescription = description.split("/n");
    return splitDescription[0]
  }


  useEffect(() => {
    setPrivated(notice.isPrivate);
  }, [notice.isPrivate]);
  useEffect(() => {
    setStartDate(notice.startDate || null);
  }, [notice.startDate]);
  useEffect(() => {
    setDueDate(notice.dueDate || null);
  }, [notice.dueDate]);

  const classes = useStyles();
  return (
    <>
      {action === "description" ? (
        <Input
          fullWidth
          disableUnderline
          value={titleCase(description)}
          onChange={handleDescriptionChange}
          sx={{
            lineHeight: "1.5em",
            margin: 0,
            border: "1px solid #D6D9DF",
            borderRadius: "6px",
            background: "#F8F8F8",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "14px",
            padding: "14px 16px 14px 16px",
            position: "relative",
            minHeight: "127px",
            color:
              descriptionColour === notice.noticeId || notice.pinned
                ? "#E9121F"
                : "black",
            "& .MuiInputBase-input": {
              display: "flex",
              alignSelf: "start",
            },
          }}
          multiline={true}
          // readOnly={displayInputField === notice.noticeId? true : false}
          // defaultValue={notice.description}
          // endAdornment={"Press 'Enter' to save"}
          // endAdornment={(
          //     <InputAdornment
          //         position="end"
          //         sx={{ cursor: "pointer" }}
          //     >
          //         "Press 'Ctrl + Enter' to save"
          //     </InputAdornment>
          // )}
          // maxRows={5}
          // autoFocus
          // onFocus= {(event: any) => {
          //     editDescriptionState(event)
          // }}
          // onBlur= {(event: any) => {
          //     resetDescription(event)
          // }}
          // onKeyUp={(event: any) => {
          //     if (event.ctrlKey && event.keyCode === 13) {    // Ctrl + Enter to save
          //         handleDescriptionChange(event, notice, "description");
          //     }
          //     else if (event.keyCode === 27){resetDescription(event);}
          // }}
        />
      ) : action === "category" ? (
        <div>
          <Autocomplete
            disableClearable
            clearOnBlur
            clearOnEscape
            selectOnFocus
            value={category}
            options={categoryList}
            isOptionEqualToValue={(option, value) =>
              option.noticeCategoryId === value.noticeCategoryId
            }
            popupIcon={<ProjectPopperIcon />}
            getOptionLabel={(option) => option.categoryTitle}
            onChange={(event: any, value: NoticeCategory) => {
              handleEditCategory(value, notice.noticeId);
            }}
            renderInput={(params) => (
              <TextField
                variant="filled"
                {...params}
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                sx={{
                  "&::placeholder": {
                    color: "green",
                  },
                  "& .MuiFilledInput-root": {
                    width: "100%",
                    padding: "0",
                    border: "1px solid #D6D9DF",
                    borderRadius: "6px",
                    background: "#F8F8F8",
                    px: "14px",
                    py: "16px",
                    borderWidth: "0px",
                    textAlign: "left",
                  },
                  "& .MuiFilledInput-input": {
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    px: "14px",
                    py: "16px",
                  },
                  "& .MuiAutocomplete-input": {
                    textOverflow: "unset",
                    minWidth: "50px",
                  },
                }}
              />
            )}
            sx={{
              "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
                textOverflow: "unset",
                minWidth: "140px",
                padding: "0",
                textAlign: "left",
              },
              "& .MuiOutlinedInput-root": {
                padding: "0 5px",
                minHeight: "40px",
              },
              "& .MuiAutocomplete-option": {
                width: "500px",
              },
            }}
          />
        </div>
      ) : action === "assignee" ? (
        <>
          {notice.assignedUsers ? (
            notice.assignedUsers.length > limitAssignee ? (
              <div
                style={{
                  fontFamily: "Raleway",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "7px",
                  color: "#927CD2",
                  position: "absolute",
                  right: "4px",
                  top: "0px",
                }}
              >
                +{notice.assignedUsers.length}
              </div>
            ) : null
          ) : null}
          {openModal ? (
            <AutocompleteCustom
              action={"notice"}
              users={userList ? userList : []}
              assignedUsers={notice.assignedUsers}
              notice={notice}
              handleEditAssignedUsers={handleEditAssignedUsers}
              openModal={openModal}
            />
          ) : (
            <div
              style={{
                height: "33px",
                overflow: "hidden",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "200px",
              }}
            >
              <AutocompleteCustom
                action={"notice"}
                users={userList ? userList : []}
                assignedUsers={notice.assignedUsers}
                notice={notice}
                handleEditAssignedUsers={handleEditAssignedUsers}
                openModal={openModal}
              />
            </div>
          )}
        </>
      ) : action === "startDate" ? (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateRangePicker
              className={classes.dateRangePicker}
              value={dateRange || [null, null]}
              onChange={(newValue) => {
                if (setDateRangeValue) {
                  setDateRangeValue(newValue);
                }
              }}
              onAccept={handleDateNotice}
              // renderInput={(params) => <TextField {...params} />}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <ListItemButton
                  onClick={(event: any) => {
                    handleOnClick(event);
                  }}
                  sx={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    justifyContent: "center",
                  }}
                >
                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    {...inputProps}
                  />
                  {InputProps?.endAdornment}
                  {dateRange?.[0]
                    ? dateRange[0].isValid()
                      ? `${addLeadingZeros(
                          dateRange[0]?.date(),
                          2
                        )}.${addLeadingZeros(
                          dateRange[0]?.month() + 1,
                          2
                        )}.${dateRange[0]?.year().toString().substring(2)}`
                      : "-"
                    : "-"}
                </ListItemButton>
              )}
            />
          </LocalizationProvider>
        </>
      ) : action === "duedate" ? (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateRangePicker
              className={classes.dateRangePicker}
              value={dateRange || [null, null]}
              onChange={(newValue) => {
                if (setDateRangeValue && newValue !== dateRange) {
                  setDateRangeValue(newValue);
                }
              }}
              onAccept={handleDateNotice}
              // renderInput={(params) => <TextField {...params} />}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <ListItemButton
                  onClick={(event: any) => {
                    handleOnClick(event);
                  }}
                  sx={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    justifyContent: "center",
                  }}
                >
                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    {...inputProps}
                  />
                  {InputProps?.endAdornment}
                  {dateRange?.[1]
                    ? dateRange[1].isValid()
                      ? `${addLeadingZeros(
                          dateRange[1]?.date(),
                          2
                        )}.${addLeadingZeros(
                          dateRange[1]?.month() + 1,
                          2
                        )}.${dateRange[1]?.year().toString().substring(2)}`
                      : "-"
                    : "-"}
                </ListItemButton>
              )}
            />
          </LocalizationProvider>
        </>
      ) : action === "pin" ? (
        <MenuItem onClick={(event: any) => handlePinChange(event, notice)}>
          <Pin pinned={pinned} action="notice" />
        </MenuItem>
      ) : action === "mark" ? (
        // <IconButton size="small" onClick={(event: any) => handleMarkChange(event, notice)}>
        //     {completed? <MarkIcon/> : <UnmarkIcon/>}
        // </IconButton>
        <CustomCheckbox
          checked={completed}
          onClick={(event: any) => handleMarkChange(event, notice)}
          style={{ transform: "scale(0.7)" }}
        />
      ) : action === "private" ? (
        <>
          <CustomCheckbox
            checked={privated}
            onClick={(event: any) => handlePrivateChange(notice)}
            style={{ transform: "scale(0.7)" }}
          />
          <Typography
            variant="caption"
            sx={{ ml: 0, mt: 4, mb: 0, cursor: "pointer" }}
            onClick={(event: any) => handlePrivateChange(notice)}
          >
            Make Private
          </Typography>
        </>
      ) : action === "archive" ? (
        <MenuItem onClick={(event: any) => handleArchiveChange(event, notice)}>
          <ListItemIcon>
            <img src={ArchiveIcon} alt="Archive" />
          </ListItemIcon>
          <ListItemText primary={notice.isArchived ? "Unarchive" : "Archive"} />
        </MenuItem>
      ) : action === "followup" ? (
        <MenuItem onClick={(event: any) => handleFollowupChange(event, notice)}>
          <ListItemIcon>
            <img src={ArchiveIcon} alt="Follow Up" />
          </ListItemIcon>
          <ListItemText
            primary={notice.isFollowup ? "Un Follow Up" : "Follow Up"}
          />
        </MenuItem>
      ) : action === "status" ? (
        notice.status ? (
          <Status
            type={"notice"}
            itemId={notice.noticeId ? notice.noticeId : ""}
            status={notice.status}
            openModal={openModal}
          />
        ) : (
          <></>
        )
      ) : action === "submit" ? (
        <Button
          startIcon={<PlusCircle />}
          onClick={handleSubmitNotice}
          style={{
            minWidth: "188px",
            borderRadius: "9px",
            background: "#754EE0",
            textTransform: "none",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "21px",
            color: "#FFFFFF",
            padding: "14px",
          }}
        >
          Update
        </Button>
      ) : action === "deleteNotice" && handleDeleteSingleNotice ? (
        <MenuItem
          onClick={(event: any) => {
            handleDeleteSingleNotice(notice);
            if (menuClose) menuClose();
          }}
        >
          <ListItemIcon>
            <img src={DeleteIcon} alt="Follow Up" />
          </ListItemIcon>
          <ListItemText primary={"Delete"} />
        </MenuItem>
      ) : action === "markComplete" ? (
        <MenuItem onClick={(event: any) => handleMarkChange(event, notice)}>
          <ListItemIcon>
            <img src={MarkIcon} alt="Mark Complete" />
          </ListItemIcon>
          <ListItemText primary={"Mark Complete"} />
        </MenuItem>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditNotice;

const useStyles = makeStyles((theme) => ({
  dateRangePicker: {
    "& .MuiDateRangePickerDay-day.Mui-selected": {
      backgroundColor: "#7949FF",
    },
    "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
      backgroundColor: "rgba(121,73,255,0.4)",
    },
  },
}));
