import { Paper, TableContainer, Table, TableHead, TableCell, TableRow, TableBody } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Costcode } from "../../../Models/costcode";
import CustomCollapsibleTable from "../../../utilities/CollapsibleTable/CollapsibleTable";
import useMountStatus  from '../../../utilities/Helper/checkMountStatus';
import {getQuotes} from '../../../utilities/api';
import { Quote } from "../../../Models/quote";

interface props{
    costcode: Costcode | object
}

const Quotes: React.FC<props> = ({
    costcode
}) => {

    const [quotes, setQuotes] = useState<Array<Quote>>()
    const unmounted = useMountStatus();

    const fetchQuotes = async() => {
        try {
            const response = await getQuotes();
            if (unmounted) return;
            setQuotes(response);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchQuotes();
    }, [costcode, unmounted]); //pass dependencies
    
    return(
        <div>
            <TableContainer sx={{
                minHeight:"90vh",
                paddingTop:"39px"
            }}>
                <Table size="small" aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell sx={{
                                fontFamily:"Inter",
                                fontWeight: "400",
                                fontSize: "10px",
                                lineHeight: "12px",
                                color: "#96A2BE",
                                textAlign: "left",
                                paddingLeft: "42px"
                            }}>
                                Suppliers
                            </TableCell>
                            <TableCell sx={{
                                fontFamily:"Inter",
                                fontWeight: "400",
                                fontSize: "10px",
                                lineHeight: "12px",
                                color: "#96A2BE",
                                textAlign: "center"
                            }}>
                                Date Emailed
                            </TableCell>
                            <TableCell sx={{
                                fontFamily:"Inter",
                                fontWeight: "400",
                                fontSize: "10px",
                                lineHeight: "12px",
                                color: "#96A2BE",
                                textAlign: "center"
                            }}>
                                Date Responded
                            </TableCell>
                            <TableCell sx={{
                                fontFamily:"Inter",
                                fontWeight: "400",
                                fontSize: "10px",
                                lineHeight: "12px",
                                color: "#96A2BE",
                                textAlign: "center"
                            }}>
                                Price
                            </TableCell>
                            <TableCell sx={{
                                fontFamily:"Inter",
                                fontWeight: "400",
                                fontSize: "10px",
                                lineHeight: "12px",
                                color: "#96A2BE",
                                textAlign: "center"
                            }}>
                                Actions
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{
                        backgroundColor:"#FFFFFF"
                    }}>
                    {quotes 
                    ? 
                        quotes.map((quote: any, index: number) => (
                                <CustomCollapsibleTable 
                                    action="quotes" 
                                    key={(index+1).toString()} 
                                    index={index+1}
                                    row={quote} 
                                    costcode={costcode} />                            
                                    ))
                    :
                        <></>
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>   
    )
}
export default Quotes