import CompanyAPI from "./DoxleAPI/companyAPI";
import ProjectAPI from "./DoxleAPI/projectAPI";
import { Company } from "../Models/company";
import { Project } from "../Models/project";

const getFirstCompany= async () => {
    let currentCompany: Company;
    if (localStorage.getItem("currentCompany")) {
        currentCompany = JSON.parse(localStorage.getItem("currentCompany") || '{}');
    } else {
        const companies = await CompanyAPI.getList() as Company[];
        currentCompany = companies?.[0];
        if (currentCompany) localStorage.setItem("currentCompany", JSON.stringify(currentCompany));
        if (currentCompany?.companyId) localStorage.setItem("currentCompanyId", currentCompany.companyId);
    }
    return currentCompany
}

const getFirstProject = async () => {
    let currentProject: Project | null;
    if (localStorage.getItem("currentProject")) {
        currentProject = JSON.parse(localStorage.getItem("currentProject") || '{}');
    } else {
        const currentCompany: Company = await getFirstCompany();
        const companyId: string = currentCompany?.companyId;
        const projects = await ProjectAPI.getList({company: companyId}) as Project[];
        currentProject = projects?.[0] ? projects[0] : null;
        if (currentProject) localStorage.setItem("currentProject", JSON.stringify(currentProject));
        if (currentProject?.projectId) localStorage.setItem("currentProjectId", currentProject.projectId);
    }
    if (currentProject?.siteAddress) sessionStorage.setItem("siteAddress", currentProject.siteAddress);
    if (currentProject?.projectPrefix) sessionStorage.setItem("projectPrefix", currentProject.projectPrefix);
    else if (currentProject?.siteAddress) sessionStorage.setItem("projectPrefix", currentProject.siteAddress.replace(/ /g,'').substring(0,7).toUpperCase());
    return currentProject
}

function getProjectAddress(){
    const currentProject = JSON.parse(localStorage.getItem("currentProject") || '{"siteAddress": "Project Not Found"}');
    const siteAddress: string = currentProject?.siteAddress;
    return siteAddress
}

const Common = {
    getFirstCompany,
    getFirstProject,
    getProjectAddress
}
export default Common