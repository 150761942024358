import { useLottie } from "lottie-react";
// import animationData from "./lotties/doxle-loader.json"; // wagging tail
import animationData from "./lotties/Loading.json"; // running doggie

interface props {}

const Loading: React.FC<props> = () => {
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };

  const style = {
    position: "absolute" as "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    zIndex: 10,
  };

  const { View } = useLottie(options, { width: "116px" });

  return <div style={style}>{View}</div>;
};

export default Loading;
