export default {
    width: "100%",
    control: {
        backgroundColor: "#F0ECFA",
        borderRadius: "6px",
        fontFamily: "Roboto Mono",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "18px",
        color: "#000000",
    },

    "&multiLine": {
        control: {
            // minHeight: 63,
        },
        highlighter: {
            padding: "13px",
        },
        input: {
            padding: "13px",
            paddingLeft: "16px",
            border: "none",
            outline: "none",
        },
        "input:focus": {
            outline: "none",
        },
    },

    "&singleLine": {
        display: "inline-block",
        width: 180,

        highlighter: {
            padding: 1,
            border: "2px inset transparent",
        },
        input: {
            padding: 1,
            border: "2px inset",
        },
    },

    suggestions: {
        // top: 'unset',
        // bottom: '37px',
        list: {
            backgroundColor: "white",
            border: "1px solid rgba(0,0,0,0.15)",
            fontFamily: "Roboto Mono",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
        },
        item: {
            padding: "5px 15px",
            borderBottom: "1px solid rgba(0,0,0,0.15)",
            "&focused": {
                backgroundColor: "#e4dafe",
            },
        },
    },
};
