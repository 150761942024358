import { motion } from "framer-motion";
import styled from "styled-components";

export const RootCashflowContextMenu = styled(motion.div)<{
  topPos: number;
  leftPos: number;
}>`
  width: 140px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 12px;
  position: absolute;
  top: ${(p) => p.topPos}px;
  left: ${(p) => p.leftPos}px;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  cursor: pointer;
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.2);
  z-index: 200;
`;
