import React, { useState, useEffect } from "react";
import {Notice, NoticeCategory} from "../../../Models/notice";
import EditNotice from "../Notices/EditNotice";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import NoteIcon from "../../../Assets/Note.svg";
import { IconButton } from "@mui/material";
import { ReactComponent as MoreIcon } from "../../../Assets/MoreVert.svg";

interface props {
  notice: Notice;
  categoryList: NoticeCategory[];
  notices: Notice[];
  setNotices: React.Dispatch<React.SetStateAction<Notice[]>>;
  setOpenModal: Function;
  setSelectedNotice: Function;
  completed: boolean;
  setCompleted: Function;
  forceUpdate: Function;
  handleDeleteSingleNotice: Function;
}

const NoticeMenu: React.FC<props> = ({
  notice,
  categoryList,
  notices,
  setNotices,
  setOpenModal,
  setSelectedNotice,
  completed,
  setCompleted,
  forceUpdate,
  handleDeleteSingleNotice,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [checked, setChecked] = React.useState<boolean>(completed);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setChecked(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAttachClick = () => {
    setAnchorEl(null);
    setOpenModal(true);
    setSelectedNotice(notice);
  };

  useEffect(() => {
    if (!open && !completed) setChecked(false);
  }, [anchorEl]);

  return (
    <>
      <IconButton
        size="small"
        id="basic-button"
        aria-controls={open ? "basic-menu" + notice.noticeId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleMenuClick}
        disableRipple
        sx={{
          width: "24px",
          height: "24px",
          m: "-10px 3px 0 3px",
          p: 0,
          "&:hover": { background: "none" },
          position: "relative",
        }}
      >
        <MoreIcon />
      </IconButton>

      <Menu
        id={"basic-menu" + notice.noticeId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiMenu-paper": {
            borderRadius: "21px",
            padding: "38px 0 38px 0",
          },
          "& .MuiMenu-list": { padding: "0" },
          "& .MuiMenu-list li": {
            marginBottom: "20px",
            padding: "5px 38px 5px 23px",
            color: "#000000",
            fontSize: "12px",
            fontWeight: "500",
          },
        }}
      >
        <EditNotice
          notice={notice}
          action={"pin"}
          categoryList={categoryList}
          notices={notices}
          setNotices={setNotices}
          menuClose={handleMenuClose}
        />

        <MenuItem onClick={() => handleAttachClick()}>
          <ListItemIcon>
            <img src={NoteIcon} alt="Attach File" />
          </ListItemIcon>
          <ListItemText primary="Attach File" />
        </MenuItem>

        <EditNotice
          notice={notice}
          action={"archive"}
          categoryList={categoryList}
          notices={notices}
          setNotices={setNotices}
          menuClose={handleMenuClose}
          forceUpdate={forceUpdate}
        />

        <EditNotice
          notice={notice}
          action={"followup"}
          categoryList={categoryList}
          notices={notices}
          setNotices={setNotices}
          menuClose={handleMenuClose}
          forceUpdate={forceUpdate}
        />

        <EditNotice
          notice={notice}
          action={"deleteNotice"}
          categoryList={categoryList}
          notices={notices}
          setNotices={setNotices}
          menuClose={handleMenuClose}
          forceUpdate={forceUpdate}
          handleDeleteSingleNotice={handleDeleteSingleNotice}
        />

        <EditNotice
          notice={notice}
          action={"markComplete"}
          categoryList={categoryList}
          notices={notices}
          setNotices={setNotices}
          menuClose={handleMenuClose}
          forceUpdate={forceUpdate}
          setCompleted={setCompleted}
          completed={completed}
        />
      </Menu>
    </>
  );
};

export default NoticeMenu;
