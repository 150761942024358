import React from 'react'
import './AccountMapping.css'
import { ReactComponent as XeroLogo } from '../../../Assets/xeroCircle.svg'

type Props = {}

const XeroLinkScreen = (props: Props) => {
  return (
    <div className='rootContainer'>
      <div className='mainContainer'>
        {/*Xero section */}
        <div className='xeroContainer'>
          <XeroLogo />

          <a href='' className='anchorTagStyle'>
            Link a xero account for this company
          </a>
        </div>
      </div>
    </div>
  )
}

export default XeroLinkScreen
