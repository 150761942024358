import React, { useState, useEffect } from 'react'
import IconItem from '../Menu/IconItem'
import {
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Tooltip,
} from '@mui/material'
import {
  DRAWER_LABELS,
  DRAWER_FOOTER_LABELS,
  DRAWER_SUBLIST_BUILDER_LABELS,
} from '../../utilities/constants'
import './drawer.css'
import { useNavigate } from 'react-router-dom'
import WindowDimensions from '../../utilities/WindowDimensions/windowDimension'
import { useLocation } from 'react-router-dom'

interface props {
  open: boolean
  setDisplayDrawer?: Function
  activeSublist: number | undefined
  setActiveSublist: Function
  foot: boolean
}

const DrawerNavigationItems: React.FC<props> = ({
  open,
  setDisplayDrawer,
  setActiveSublist,
  activeSublist,
  foot,
}) => {
  const [openSubList, setOpenSubList] = useState<number | undefined>(
    activeSublist
  )
  const [clickedIdentifier, setClickedIdentifier] = useState<
    number | undefined
  >(activeSublist)
  const { height, width } = WindowDimensions()

  const listItems = Object.values(DRAWER_LABELS)
  const listFooterItems = Object.values(DRAWER_FOOTER_LABELS)
  const subListItemsBuilder = Object.values(DRAWER_SUBLIST_BUILDER_LABELS)

  const { pathname } = useLocation()

  const navigate = useNavigate()

  const listMenu = foot ? listFooterItems : listItems

  const handleOnClick = (identifier: number, text: string, e: any) => {
    if (e?.button === 0 && !(e?.ctrlKey || e?.metaKey)) {
      if (open) {
        setOpenSubList(undefined)
      } else {
        setOpenSubList(identifier)
      }
      setClickedIdentifier(identifier)
      setActiveSublist(identifier)
      console.log('CURRENT IDENTIFIER:', identifier)
      if (text === 'Costings' || text === 'Projects') navigate('/')
      else if (text) navigate('/' + text.replaceAll(' ', ''))
      // if(setDisplayDrawer && identifier == 3){
      //     setDisplayDrawer(true, identifier)
      // }
      if (open && setDisplayDrawer) {
        setDisplayDrawer(false, identifier)
      }
      return
    }
    if ((e?.button === 0 && (e?.ctrlKey || e?.metaKey)) || e.button === 1) {
      window.open('/' + text.replaceAll(' ', ''))
    }
  }

  useEffect(() => {
    const pagename =
      pathname === '/' ? 'Projects' : pathname.replaceAll('/', '')
    setClickedIdentifier(
      listItems.findIndex((val) => val.replaceAll(' ', '') === pagename)
    )
  }, [])

  return (
    <List dense sx={{ paddingBottom: '0px' }}>
      {listMenu.map((text, index) => (
        <div
          key={text}
          style={{
            paddingBottom: foot ? '20px' : '5px',
          }}
        >
          <Tooltip
            title={text}
            placement='right'
            disableHoverListener={open}
            arrow
          >
            <ListItemButton
              key={text}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                py: '17px',
                px: 2.5,
                marginLeft: 0,
              }}
              onMouseDown={(e) => handleOnClick(index, text, e)}
            >
              <IconItem
                open={open}
                text={text}
                index={index}
                clickedIdentifier={clickedIdentifier}
              />
              {/* <ListItemText primary={text} 
                                sx={{ 
                                    opacity: open ? 1 : 0,
                                    "& .MuiTypography-root":{ 
                                        fontFamily:"Inter",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize: "13px",
                                        color: index === clickedIdentifier ?  "#0044CC" : "#212325",
                                        paddingRight: "0px"
                                    }
                            }}/> */}
            </ListItemButton>
          </Tooltip>
          <Collapse
            in={openSubList === index && open}
            timeout='auto'
            unmountOnExit
          >
            {index === 3 ? (
              <List component='div' disablePadding dense>
                {subListItemsBuilder.map((sublistText, sublistIndex) => (
                  <ListItemButton
                    sx={{ pl: 4 }}
                    key={sublistText}

                    onMouseDown={(e) =>
                      handleOnClick(
                        parseInt(index + '.' + sublistIndex),
                        text + '/' + sublistText,
                        e
                      )
                    }
                  >
                    <div
                      style={{ paddingLeft: '50px', paddingRight: '8px' }}
                    ></div>
                    <ListItemText
                      primary={sublistText}
                      sx={{
                        '& .MuiTypography-root': {
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: '500',
                          fontSize: '13px',
                          color: '#212325',
                          textAlign: 'left',
                          paddingRight: '39px',
                        },
                      }}
                    />
                  </ListItemButton>
                ))}
              </List>
            ) : (
              <></>
            )}
          </Collapse>
        </div>
      ))}
    </List>
  )
}
export default DrawerNavigationItems
