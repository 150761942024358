import React, { useState, useEffect } from "react";
import { Popover, Stack, Box } from "@mui/material";
import { Comment } from "../../../Models/comments";
import titleCase from "../../../utilities/Helper/titleCase";
import { Costcode } from "../../../Models/costcode";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
interface props {
    costCodeId?: string;
    commentList?: Comment[];
    anchorEl: HTMLElement | null;
    handlePopoverClose: Function;
}

const CommentsPopover: React.FC<props> = ({
    costCodeId,
    commentList,
    anchorEl,
    handlePopoverClose,
}) => {
    const [comments, setComments] = useState<Array<Comment>>([]);
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;

    const open = Boolean(anchorEl);

    const fetchCostCodesDetails = async (costcodeId: string) => {
        try {
            const response: Costcode = (await CostCodeAPI.getDetailed(
                costcodeId
            )) as Costcode;
            if (response.comments) {
                setComments(response.comments);
            }
        } catch (err) {
            err === "AccessTokenNotFound"
                ? setLoggedIn(false)
                : console.error(err);
        }
    };

    useEffect(() => {
        if (anchorEl !== null) {
            if (costCodeId) fetchCostCodesDetails(costCodeId);
            if (commentList) setComments(commentList);
        }
    }, [anchorEl]);

    return (
        <Popover
            id="mouse-over-popover"
            sx={{ pointerEvents: "none" }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            onClose={() => handlePopoverClose()}
            disableRestoreFocus
        >
            <Stack
                direction="column"
                sx={{
                    fontFamily: "Roboto Mono",
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "10px",
                    lineHeight: "9px",
                    color: "#000000",
                }}
            >
                {comments.map((comment, i) => (
                    <Stack
                        key={"commentpop-#" + i}
                        direction="row"
                        alignItems="center"
                        borderBottom="1px solid #D5D7E3"
                        p="10px"
                        width="100%"
                    >
                        <Box
                            sx={{
                                fontWeight: "400",
                                color: "white",
                                background: "#927CD2",
                                borderRadius: "30px",
                                py: "3px",
                                px: "9px",
                            }}
                        >
                            {/* {comment.user?.firstName.charAt(0) + "" + comment.user?.lastName.charAt(0)} */}
                            {comment.user?.firstName}
                        </Box>
                        <Box
                            sx={{
                                maxWidth: "160px",
                                marginLeft: "5px",
                            }}
                        >
                            {titleCase(comment.commentText)}
                        </Box>
                    </Stack>
                ))}
            </Stack>
        </Popover>
    );
};

export default CommentsPopover;
