import React, { useState, useRef } from "react";
import "../Notices/Notices.css";
import { Grid, Typography, TextField, Box} from "@mui/material";
import checklistGroupAPI from "../../../Services/DoxleAPI/checklistGroupAPI";
import {Permit} from "../../../Models/permit";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import {Checklist} from "../../../Models/checklist";
import checklistAPI from "../../../Services/DoxleAPI/checklistAPI";
import ChecklistItem from "./ChecklistItem";
import { ReactComponent as TrashIcon } from "../../../Assets/Trash2.svg";
import { ReactComponent as PlusCircle } from "../../../Assets/PlusCircleGrey.svg";
import { ReactComponent as EditIcon } from "../../../Assets/editIcon3.svg";


interface props {
    checklistList: Permit;
    deleteChecklistList: Function;
}




const ChecklistTable: React.FC<props> = ({
                                             checklistList,
                                             deleteChecklistList,
                                         }) => {
    const [editState, setEditState] = useState<boolean>(false);
    const [hovering, setHovering] = useState<boolean>(false);
    const [questions, setQuestions] = useState<Checklist[]>(checklistList?.checklistItems ? checklistList?.checklistItems : []);
    const [title, setTitle] = useState<string>(checklistList.title);
    const [newQuestion, setNewQuestion] = useState<string>("");
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;
    const newItemRef: any = useRef()
    // const groupTitleRef: any = useRef()
    const handleQuestionChange = (e:any) =>{
        setNewQuestion(e.target.value)
    }

    const deleteChecklistItem = async (checklistId: string|undefined) => {
        try {
            if (!checklistId) return;
            const response = await checklistAPI.remove(checklistId)
            if (!response) return;
            let newQuestions: Checklist[] = []
            questions.forEach((question: Checklist) => {
                if (checklistId !== question.checklistId) newQuestions.push(question)
            })
            setQuestions([...newQuestions])
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    const handleTitleUpdate = async (checklistGroupId:string) =>{
        if (!user?.userId) { console.log("No UserId"); return; }
        try {
            const response = await checklistGroupAPI.update(checklistGroupId,
                {
                    title: title,
                    userId: user.userId
                })
            if (response) setEditState(false);
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    const handleTitleKeyUp = (e:any, checklistGroupId:string) =>{
        if (e.keyCode !== 13) return;
        handleTitleUpdate(checklistGroupId)
    }

    const handleQuestionKeyUp = async (e:any, checklistGroupId:string) =>{
        // console.log(e)
        if (!user?.userId) return;
        if (e.keyCode !== 13) return;
        try {
            const response = await checklistAPI.addChecklist(
                {
                    question: newQuestion,
                    answer: null,
                    // notice: notice.noticeId,
                    timeStamp: new Date().toISOString(),
                    permit: checklistGroupId
                }, user.userId)
            if (response) setQuestions([...questions, response])
            setNewQuestion("")
        } catch (err) {
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
        }
    }

    return (
        <Grid container
              onMouseEnter={() => setHovering(true)}
              onMouseLeave={() => setHovering(false)}
              sx={{
                  paddingTop: "20px",
                  marginBottom: "50px",
              }}
        >
            <Grid item xs={1} sx={{height: "24px"}}/>
            <Grid item xs={10} 
                onDoubleClick={() => setEditState(true)}
                onBlur={() => setEditState(false)}
            >
                {
                    !editState
                        ?
                        <Typography style={{
                            color: "#5A36BE",
                            fontWeight: 600,
                            fontSize: "12px",
                            fontFamily: 'Roboto Mono',
                            fontStyle: 'normal',
                            lineHeight: '16px',
                        }}
                        >{title}</Typography>
                        :
                        <TextField
                            variant="standard"
                            value={title}
                            onChange={(e: any) => setTitle(e.target.value)}
                            onKeyUp={(e:any) => handleTitleKeyUp(e, checklistList.checklistGroupId)}
                            onBlur={() => handleTitleUpdate(checklistList.checklistGroupId)}
                            autoFocus
                            // inputRef={groupTitleRef}
                            sx={{
                                color: "#5A36BE",
                            }}
                            InputProps={{
                                disableUnderline: true,
                                sx:{
                                    color: "#5A36BE",
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    fontFamily: 'Roboto Mono',
                                    fontStyle: 'normal',
                                    lineHeight: '16px',
                                    "& .MuiInputBase-input":{
                                        textAlign: "left",
                                        paddingBottom: "1px",
                                        paddingTop:"1px",
                                        maxHeight:"28px",
                                        width:"70vw",
                                        // border: "white",
                                        borderRadius:"5px 5px 0px 0px"
                                    },
                                    "& .MuiInputLabel-formControl":{
                                        color:"#9974fe",
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {color: "#9974fe"},
                                sx: {
                                    top: "-10px",
                                    "& .MuiInputLabel-shrink":{
                                        top: "0px !important",
                                    }
                                },
                            }}
                        />
                }

            </Grid>
            <Grid item xs={1}>
                {hovering && !editState &&
                    <EditIcon
                        onClick={() => {
                            setEditState(true)
                        }}
                        style={{ cursor: "pointer", marginTop: '2px' }}
                    />
                }
                {hovering && !editState &&
                    <TrashIcon
                        onClick={() => deleteChecklistList(checklistList.checklistGroupId)}
                        style={{cursor: "pointer"}}
                    />
                }
            </Grid>
            
            {questions.map((checklist: Checklist) => (
                <ChecklistItem key={checklist.checklistId} checklistItem={checklist} deleteChecklistItem={deleteChecklistItem}/>
            ))}
            
            
            <Grid item xs={12}>
                <Box display="flex" flexDirection="row" alignItems="center" height="24px" gap="5px" py="5px" px="25px">
                    <div>
                        <PlusCircle
                            style={{width: "21px", height: "21px"}}
                            onClick={()=> {
                                if (newItemRef?.current?.focus) newItemRef.current.focus()
                            }}
                        />
                    </div>
                    <TextField
                        variant="standard"
                        placeholder={"Add Another Item Here ..."}
                        value={newQuestion}
                        onChange={handleQuestionChange}
                        onKeyUp={(e:any) => handleQuestionKeyUp(e, checklistList.checklistGroupId)}
                        sx={{
                            color: "#9974fe",
                            borderRadius:"0px 0px 0px 5px"
                        }}
                        inputRef={newItemRef}
                        InputProps={{
                            disableUnderline: true,
                            sx:{
                                fontFamily: 'Roboto Mono',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: "10px",
                                lineHeight: '13px',   
                                "& .MuiInputBase-input":{
                                    textAlign: "left",
                                    // color:"#",
                                    paddingBottom: "1px",
                                    paddingTop:"1px",
                                    maxHeight:"28px",
                                    width:"70vw",
                                    // border: "white",
                                    borderRadius:"5px 5px 0px 0px"
                                },
                                "& .MuiInputLabel-formControl":{
                                    color:"#9974fe",
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {color: "#9974fe"},
                            sx: {
                                top: "-10px",
                                "& .MuiInputLabel-shrink":{
                                    top: "0px !important",
                                }
                            },
                        }}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default ChecklistTable;