import { Permit } from "../../../Models/permit";
import { Input, ListItemButton} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {formatScheduleDate} from "../../../utilities/constants";
import Status from '../Status/Status';
import CustomCheckbox from '../../../utilities/CheckBox/CheckBox';
import { useState, useEffect } from "react";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import { withStyles } from "@material-ui/core/styles";
import checklistGroupAPI from "../../../Services/DoxleAPI/checklistGroupAPI";
import {Checklist} from "../../../Models/checklist";
import checklistAPI from "../../../Services/DoxleAPI/checklistAPI";
import {Comment} from "../../../Models/comments";
import {User} from "../../../Models/user";
import {AddressBookCompany} from "../../../Models/addressBook";
import { useSocket } from "../../../Services/Sockets/useSocket";
interface props{
    permit?: Permit;
    checklist?: Checklist;
    action: string;
    value?: string;
    setValue: Function;
    exitEditTitleMode?: Function;
}

const EditPermit: React.FC<props> = (
    {
        permit,
        checklist,
        action,
        value,
        setValue,
        exitEditTitleMode
    }) => {
    const [permitTitle, setPermitTitle] = useState<string>(permit?.title || "");
    const [checklistQuestion, setChecklistQuestion] = useState<string>(checklist?.question || "");
    const [startDate, setStartDate] = useState<string>(value ? new Date(value).toISOString().substring(0,10) : new Date().toISOString().substring(0,10));
    const [endDate, setEndDate] = useState<string>(value ? new Date(value).toISOString().substring(0,10) : new Date().toISOString().substring(0,10));
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;

    const socket = useSocket();

    const handlePermitTitleChange = async (event: React.ChangeEvent<HTMLInputElement>, permit: Permit) => {
        try {
            console.log(event.target.value)
            if (!user?.userId) {console.log("User not found"); setLoggedIn(false); return;}
            if (event.target.value === permitTitle) {
                if (exitEditTitleMode) exitEditTitleMode();
                return;
            }
            const response = await checklistGroupAPI.update(permit.checklistGroupId, {title: event.target.value, userId: user.userId});
            if (response) {
                setValue(response.title);
                setPermitTitle(response.title);
                if (exitEditTitleMode) exitEditTitleMode();
            }
        } catch(err){
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
            resetTitle(event)
        }
    };

    const editTitleState = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value = ''
    };

    const resetTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value = permitTitle;
    };

    const handleChecklistQuestionChange = async (event: React.ChangeEvent<HTMLInputElement>, checklist: Checklist|undefined) => {
        try {
            if (action === "checklistQuestion") {
                if (!checklist?.checklistId) return;
                if (!user?.userId) { console.log("User not found"); setLoggedIn(false); return; }
                const response = await checklistAPI.updateChecklist(checklist.checklistId, {question: event.target.value}, user.userId);
                if (response) {
                    setValue(response.question);
                    setChecklistQuestion('');
                }
            } else if (action === "addChecklist") {
                if (!user?.userId) { console.log("User not found"); setLoggedIn(false); return; }
                if (!permit?.checklistGroupId) { console.log("Permit Id not found"); return; }
                const response = await checklistAPI.addChecklist( {
                    question: event.target.value,
                    answer: null,
                    permit: permit.checklistGroupId,
                }, user.userId);
                if (response) {
                    setValue(response);
                    setChecklistQuestion('');
                }
            }
        } catch(err){
            err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
            resetTitle(event)
        }
    };

    const editQuestionState = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value = ''
    };

    const resetQuestion = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value = permitTitle;
    };

    const handleOnClick = (e: any) =>{
        e?.target?.children?.[0]?.click()
    }

    const updateDateViaSocket = (checklistGroupId: string, startDate:any, endDate: any) => {
        //! SOCKET SENDING
        console.log(
            "%cCLIENT = Sending startDate/endDate update ", "background:green; color:white"
        );
        socket.send(
            JSON.stringify({
                messageType: "SocketDataUpdate",
                message: {
                    checklistGroupId, 
                    startDate,
                    endDate,
                },
            })
        );
    }

    const handleEditStartDate = async (input:any) =>{
        if (input){
            console.log(permit)
            console.log(input)
            const startDateInput = input.toISOString().substring(0,10);
            console.log(startDateInput)
            if (startDate === startDateInput) {return}
            try {
                if (!user?.userId) {console.log("User not found"); setLoggedIn(false); return;}
                if (!permit?.checklistGroupId) {console.log("checklistGroupId not found"); return;}
                const response = await checklistGroupAPI.update(permit?.checklistGroupId, {startDate: startDateInput, userId: user.userId});
                if (response){
                    setValue(response.startDate);
                    setStartDate(response.startDate);
                    updateDateViaSocket(permit.checklistGroupId, response.startDate, null)
                }
            } catch (err) {
                err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
            }
        }
    }

    const handleEditEndDate = async (input:any) =>{
        if (input){console.log(input)
            console.log(permit)
            console.log(input)
            const endDateInput = input.toISOString().substring(0,10);
            console.log(endDateInput)
            if (endDate === endDateInput) {return}
            try {
                if (!user?.userId) {console.log("User not found"); setLoggedIn(false); return;}
                if (!permit?.checklistGroupId) {console.log("checklistGroupId not found"); return;}
                const response = await checklistGroupAPI.update(permit?.checklistGroupId, {endDate: endDateInput, userId: user.userId});
                if (response){
                    setValue(response.endDate);
                    setEndDate(response.endDate);
                    updateDateViaSocket(permit.checklistGroupId, null, response.endDate)
                }
            } catch (err) {
                err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
            }
        }
    }

    useEffect(() => {
        if (value) {
            if (action === "startDate") setStartDate(new Date(value).toISOString().substring(0,10))
            if (action === "endDate") setEndDate(new Date(value).toISOString().substring(0,10))
        }
    }, [value])

    return(
        <div>
            {
                action === "permitTitle"
                    ?
                    <Input className="permitTitle" size="small"
                           disableUnderline
                           defaultValue={permitTitle}
                           sx={{
                               height: "10px",
                               fontStyle: "normal",
                               fontWeight: "normal",
                               fontFamily:"Inter",
                               fontSize: "11px",
                               lineHeight: "15px",
                               // color: textColor(),
                               position: "relative"
                           }}
                           onFocus= {(event: any) => {
                               editTitleState(event);
                           }}
                           onBlur= {(event: any) => {
                               resetTitle(event)
                           }}
                           onKeyUp={(event: any) => {
                               if (event.keyCode === 13 && permit){ handlePermitTitleChange(event, permit); }
                               else if (event.keyCode === 27){resetTitle(event);}
                           }}/>
                    :
                    action === "checklistQuestion"
                    ?
                        <Input className="checklistQuestion" size="small"
                               disableUnderline
                               defaultValue={checklistQuestion}
                               sx={{
                                   height: "10px",
                                   fontStyle: "normal",
                                   fontWeight: "normal",
                                   fontFamily:"Inter",
                                   fontSize: "11px",
                                   lineHeight: "15px",
                                   // color: textColor(),
                                   position: "relative"
                               }}
                               onFocus= {(event: any) => {
                                   editQuestionState(event);
                               }}
                               onBlur= {(event: any) => {
                                   resetQuestion(event)
                               }}
                               onKeyUp={(event: any) => {
                                   if (event.keyCode === 13 && checklist){ handleChecklistQuestionChange(event, checklist); }
                                   else if (event.keyCode === 27){resetQuestion(event);}
                           }}/>
                    :
                    action === "startDate"
                        ?
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    openTo="day"
                                    value={new Date(startDate)}
                                    inputFormat="dd.MM.yyyy"
                                    onChange={(valueInput: Date | null) => handleEditStartDate(valueInput)}
                                    renderInput={({ inputRef, inputProps, InputProps }) =>
                                        <ListItemButton
                                            onClick={(event:any)=>{handleOnClick(event)}}
                                            sx={{paddingTop: "0px", paddingBottom: "0px"}}
                                        >
                                            <input style={{display: "none"}} ref={inputRef} {...inputProps} />
                                            {InputProps?.endAdornment}
                                            {formatScheduleDate(new Date(startDate).toISOString())}
                                        </ListItemButton> }
                                />
                            </LocalizationProvider>
                        :
                        action === "endDate"
                        ?
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    openTo="day"
                                    value={new Date(endDate)}
                                    onChange={(valueInput: Date | null) => handleEditEndDate(valueInput)}
                                    renderInput={({ inputRef, inputProps, InputProps }) =>
                                        <ListItemButton
                                            onClick={(event:any)=>{handleOnClick(event)}}
                                            sx={{paddingTop: "0px", paddingBottom: "0px"}}
                                        >
                                            <input style={{display: "none"}} ref={inputRef} {...inputProps} />
                                            {InputProps?.endAdornment}
                                            { formatScheduleDate(new Date(endDate).toISOString())}
                                        </ListItemButton> }
                                />
                            </LocalizationProvider>
                        :
                        action === "addChecklist"
                        ?
                            <Input className="checklistQuestion" size="small"
                                   disableUnderline
                                   defaultValue={checklistQuestion}
                                   sx={{
                                       height: "10px",
                                       fontStyle: "normal",
                                       fontWeight: "normal",
                                       fontFamily:"Inter",
                                       fontSize: "11px",
                                       lineHeight: "15px",
                                       // color: textColor(),
                                       position: "relative"
                                   }}
                                   autoFocus
                                   onFocus= {(event: any) => {
                                       editQuestionState(event);
                                   }}
                                   onBlur= {(event: any) => {
                                       resetQuestion(event)
                                   }}
                                   onKeyUp={(event: any) => {
                                       if (event.keyCode === 13){ handleChecklistQuestionChange(event, undefined); }
                                       else if (event.keyCode === 27){resetQuestion(event);}
                                   }}
                            />
                        :
                        <></>
            }

        </div>


    )
}
export default EditPermit