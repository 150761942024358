import axios from "axios";
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";
import dummy from './dummyData';
import {FileData, Folder} from "../../Models/storage";
import {Costcode} from "../../Models/costcode";

const getFileList = async (projectId: string|null, costCodeId: string|null, noticeId: string|null)  => {
  let result: FileData[]  = []
  if (offline){ return result }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }

  try {
    let params: object;
    if (costCodeId) params = { cost_code: costCodeId}
    else if (projectId) params = { project: projectId}
    else if (noticeId) params = { notice: noticeId}
    else { console.log("GET FILE LIST: Neither costCode nor project nor notice ID given"); return result; }
    const response = await axios.get(
      baseAddress+"/storage/file/",
      {
        headers: { Authorization: "Bearer " + accessToken },
        params: params
      }
    )
    result = response.status === 200 ? response.data as Array<FileData> : []
    console.log("CC FILES", result);
  } catch (error) {
      console.error("ERROR FETCHING FILES", error)
  }
  return result
}


const getFolderList = async (projectId: string | null, costCodeId:string | null, type:"F"|"P")  => {
    let result: Folder[]  = []
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {

        let params:any
        if (costCodeId) params= { cost_code: costCodeId, ordering: "name", type: type}
        else if (projectId) params= { project: projectId, ordering: "name", type: type}
        else { console.log("GET FOLDER LIST: Neither costCode nor project ID given"); return result; }
        const response = await axios.get(
            baseAddress+"/storage/folder/",
            {
                headers: { Authorization: "Bearer " + accessToken },
                params: params
            }
        )
        result = response.status === 200 ? response.data as Array<Folder> : []
        console.log("CC FOLDERS", result);
    } catch (error) {
        console.error("ERROR FETCHING FOLDERS", error)
    }
    return result
}


const createFolder = async (folderName: string, projectId: string|null, costCodeId:string|null, type:"F"|"P") => {
    let result: Folder | undefined = undefined
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }

    try {
      const response = await axios.post(
          baseAddress+"/storage/folder/",
          {name: folderName , costCode: costCodeId, project: projectId, type: type},
          {
            headers: { Authorization: "Bearer " + accessToken },
          }
      )
      result = response.status === 201 ? response.data as Folder : undefined
      console.log("ADDED FOLDER", result)
    } catch (error) {
      console.error("ERROR CREATING FOLDER", error)
    }
    return result
}


const uploadFiles = async (files: Blob[], currentFolder: string, setProgress: Function)  => {
  interface Result{files: FileData[]; errors:string[]}
  let result: Result = {files: [], errors: []}
  if (offline){ return result }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken){ throw "AccessTokenNotFound" }
  try {
    const formData = new FormData()
    formData.append("folderId", currentFolder);
    files.forEach(function(file){
        formData.append("files", file);
    })
    const response = await axios.post(
      baseAddress+"/storage/file/", formData,
      {
        headers: { Authorization: "Bearer " + accessToken },
          onUploadProgress: progressEvent =>setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      }
    )
    if(response.data && response.data.files){
        console.log("UPLOADED FILES", response.data.files)
        result.files = response.data.files as FileData[]
    }
    if(response.data && response.data.errors){
        console.log("UPLOAD ERRORS", response.data.errors)
        result.errors = response.data.files as string[]
    }
  } catch (error) {
      console.error("ERROR SAVING FILES", error)
  }
  return result
}


const uploadNoticeFiles = async (files: Blob[], setProgress: Function, noticeId: string)  => {
    interface Result{files: FileData[]; errors:string[]}
    let result: Result = {files: [], errors: []}
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        const formData = new FormData()
        formData.append("noticeId", noticeId);
        files.forEach(function(file){
            formData.append("files", file);
        })
        const response = await axios.post(
            baseAddress+"/storage/file/", formData,
            {
                headers: { Authorization: "Bearer " + accessToken },
                onUploadProgress: progressEvent =>setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }
        )
        if(response.data && response.data.files){
            console.log("UPLOADED FILES", response.data.files)
            result.files = response.data.files as FileData[]
        }
        if(response.data && response.data.errors){
            console.log("UPLOAD ERRORS", response.data.errors)
            result.errors = response.data.files as string[]
        }
    } catch (error) {
        console.error("ERROR SAVING FILES", error)
    }
    return result
}


const deleteFolders = async (folders: string[], costCodeId: string|null, projectId: string|null) => {
    let result: Folder[] = [];
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        let params: object = {folders}
        if (costCodeId) params = { ...params, costCodeId}
        if (projectId) params = { ...params, projectId}

        console.log("params", params)
    
        const response = await axios.post(
            baseAddress + "/storage/folder/delete/",
            params,
            {
                headers: {Authorization: "Bearer " + accessToken},
            }
        )
        console.log("DeleteFolder", response.data)
        result = response.status === 200 ? response.data as Folder[] : []
    } catch (error){
        console.error("ERROR DELETING FOLDERS", error)
    }
    return result
}

const deleteFile = async (fileId: string) => {
    let result: boolean = false;
    if (offline){ result = true; return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        const response = await axios.delete(
            baseAddress + "/storage/file/"+fileId+"/",
            {
                headers: {Authorization: "Bearer " + accessToken},
            }
        )
        result = response.status === 204
        console.log("DeleteFile", result)
    } catch (error){
        console.error("ERROR DELETING FILE", error)
    }
    return result
}

const updateFolder = async (folderId: string, name: string) => {
    let result: Folder | undefined = undefined
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        const response = await axios.patch(
            baseAddress + "/storage/folder/"+folderId+"/",
            {"name": name},
            {
                headers: {Authorization: "Bearer " + accessToken},
            }
        )
        result = response.status === 200 ? response.data as Folder : undefined
        console.log("updateFolder", result)
    } catch (error){
        console.error("ERROR RENAMING FOLDERS", error)
    }
    return result
}

const updateFile = async (fileId: string, name: string) => {
    let result: FileData | undefined = undefined;
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        const response = await axios.patch(
            baseAddress + "/storage/file/"+fileId+"/",
            {name: name},
            {
                headers: {Authorization: "Bearer " + accessToken},
            }
        )
        result = response.data as FileData
        console.log("updateFolder", result)
    } catch (error){
        console.error("ERROR RENAMING FOLDERS", error)
    }
    return result
}

const storageAPI = {
  getFileList,
  getFolderList,
  uploadFiles,
  createFolder,
  deleteFolders,
  deleteFile,
  updateFolder,
  updateFile,
  uploadNoticeFiles,
}

export default storageAPI