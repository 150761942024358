import React from 'react'
import { BasicCostCode } from '../../../../Models/costcode'
import { XeroCostcodeData } from '../../../../Models/xeroData'
import '../AccountMapping.css'
import { IUnlinkedItem } from '../AccountMappingApp'
import ButtonClearLink from './ButtonClearLink'
import ButtonLinked from './ButtonLinked'
import DropdownCostcodeLink from './DropdownCostcodeLink'

type Props = {
  costcode: BasicCostCode
  xeroCostcodeData: XeroCostcodeData[]
  isLinked: boolean
  linkedItem: XeroCostcodeData
  unlinkedItems: IUnlinkedItem[]
  setUnlinkedItems: Function
  unlinkFunction: Function
}

const CostcodeMappingRow = ({
  costcode,
  xeroCostcodeData,
  isLinked,
  linkedItem,
  unlinkedItems,
  setUnlinkedItems,
  unlinkFunction,
}: Props) => {
  console.log('RERENDER ROW')
  const getMatchedItemForUnlinkedItem = () => {
    let matchedItem: string | undefined
    unlinkedItems.map((item) => {
      if (item.costcode.costCodeId === costcode.costCodeId) {
        matchedItem = item.trackingId
      }
    })
    return matchedItem
  }
  return (
    <div
      style={{
        width: '100%',
        height: '50px',
        borderBottom: '1px solid #CBDAE2',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: -50,
      }}
    >
      {/*costcode Title */}
      <div className='costcodeTitleStyle'>{costcode.title}</div>

      {/*costcode link dropdown */}
      <div className='costcodeLinkDropdown'>
        <DropdownCostcodeLink
          isLinked={isLinked}
          linkedItem={linkedItem}
          xeroCostcodeData={xeroCostcodeData}
          matchedItems={getMatchedItemForUnlinkedItem()}
          unlinkedItems={unlinkedItems}
          setUnlinkedItems={setUnlinkedItems}
          costcode={costcode}
        />
      </div>

      {/*link action container */}

      <div className='costcodeLinkActionContainer'>
        <ButtonLinked isLinked={isLinked} />

        <ButtonClearLink
          isLinked={isLinked}
          unlinkFunction={unlinkFunction}
          costcode={costcode}
        />
      </div>
    </div>
  )
}

export default React.memo(CostcodeMappingRow)
