import React, {useRef, useEffect, useState, useCallback, createContext, useContext} from "react";
import "./Measurement.css";
import {Button, Paper} from "@mui/material";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import { TakeOff, DrawingSet, Sheet, DoxLine, DoxPolygon, DoxRectangle, DoxCircle } from "../../../Models/takeOffs";
import ToolBar from "./ToolBar";
import ScaleBar from "./ScaleBar";
import DrawingsNavbar from "./DrawingsNavBar";
import KonvaComponent from "./Konva";
import DrawingPlaceholder from "./DrawingPlaceholder";
import {Navbar} from "react-bootstrap";
import Loading from "../../../utilities/Lottie/Loading";
import DrawingSetDialog from "./DrawingSetDialog";
import drawingAPI from "../../../Services/DoxleAPI/drawingAPI";
import {takeOffContextInterface, useTakeOff} from "../../Providers/TakeOffProvider";
import {Costcode} from "../../../Models/costcode";
import TakeOffAPI from "../../../Services/DoxleAPI/takeOffAPI";
import {DropzoneDialog} from "material-ui-dropzone";
import UploadDialog from "./UploadDialog";
import {toFloat} from "./konvaFunctions/generalFunctions";
import CostCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
export 	interface SingleMeasureContextInterface {
	drawings: DrawingSet[]
	currentUnit:  "LM" | "M2" | "M3" | "EA";
	currentDrawings: DrawingSet | undefined;
	setCurrentDrawings: React.Dispatch<React.SetStateAction<DrawingSet | undefined>>;
	currentSheet: Sheet | undefined;
	setCurrentSheet: React.Dispatch<React.SetStateAction<Sheet | undefined>>;
	measurements: (DoxCircle | DoxLine | DoxRectangle | DoxPolygon)[];
	setMeasurements: React.Dispatch<React.SetStateAction<(DoxCircle | DoxLine | DoxRectangle | DoxPolygon)[]>>;
	unsavedMeasurements: boolean;
	setUnsavedMeasurements: React.Dispatch<React.SetStateAction<boolean>>;
	editDrawingDialogOpen: boolean;
	setEditDrawingDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
	tool: "Polygon" | "Rectangle" | "Circle" | "Line" | "Selector";
	setTool: React.Dispatch<React.SetStateAction<"Polygon" | "Rectangle" | "Circle" | "Line" | "Selector">>;
	displayLabels: boolean;
	setDisplayLabels: React.Dispatch<React.SetStateAction<boolean>>;
	deduction: boolean;
	setDeduction: React.Dispatch<React.SetStateAction<boolean>>;
	currentColour: string;
	setCurrentColour: React.Dispatch<React.SetStateAction<string>>;
	zHeight: number;
	setZHeight: React.Dispatch<React.SetStateAction<number>>;
	scalingMode: boolean;
	setScalingMode: React.Dispatch<React.SetStateAction<boolean>>;
	scaleLine: DoxLine | null;
	setScaleLine: React.Dispatch<React.SetStateAction<DoxLine | null>>;
	scaleLength: number;
	setScaleLength: React.Dispatch<React.SetStateAction<number>>;
	isLoadingImage: boolean;
	setIsLoadingImage: React.Dispatch<React.SetStateAction<boolean>>;
	handleUpdateTakeOff: () => void;
	selectedTakeOff: TakeOff;
	currentShape: DoxCircle | DoxLine | DoxRectangle | DoxPolygon | null;
	setCurrentShape: React.Dispatch<React.SetStateAction<DoxCircle | DoxLine | DoxRectangle | DoxPolygon | null>>;
	editTakeOffMode: false;
}
interface props {
	costcode: Costcode;
	drawings: DrawingSet[];
	setDrawings: Function;
	existingTakeOff: TakeOff|null;
	setTakeOffs: React.Dispatch<React.SetStateAction<TakeOff[]>>
	setCostCodes: React.Dispatch<React.SetStateAction<Costcode[]>>
	setCostcode: React.Dispatch<React.SetStateAction<Costcode|undefined>>
}

const SingleMeasureContext = createContext({});

const SingleMeasurement: React.FC<props> = ({
											costcode,
											drawings,
											setDrawings,
											existingTakeOff,
											setTakeOffs,
											setCostCodes,
											setCostcode
}) => {
	const currentUnit = existingTakeOff?.unit ?? costcode?.uom?.toUpperCase() === "LM"
		? "LM"
		: costcode?.uom?.toUpperCase() === "M2"
		? "M2"
		: costcode?.uom?.toUpperCase() === "M3"
		? "M3"
		: "EA"
	;
	const [takeOff, setTakeOff] = useState<TakeOff>(existingTakeOff ?? {
		takeOffId: costcode.costCodeId,
		index: 0,
		description: costcode.title,
		totalValue: 0,
		unit: currentUnit,
		measurements: [],
		project: costcode.project,
		costCode: costcode.costCodeId,
	})
	const [openDropzoneDialog, setOpenDropzoneDialog] = useState(false)
	const [showUploadDialog, setShowUploadDialog] = useState(false)
	const [pendingFile, setPendingFile] = useState<Blob|null>(null)
	const [currentDrawings, setCurrentDrawings] = useState<DrawingSet|undefined>(drawings?.[0]);
	const [currentSheet, setCurrentSheet] = useState<Sheet|undefined>(drawings?.[0]?.sheets?.[0]);
	const [measurements, setMeasurements] = useState<(DoxCircle|DoxLine|DoxRectangle|DoxPolygon)[]>([])
	const [unsavedMeasurements, setUnsavedMeasurements] = useState<boolean>(false)
	const [editDrawingDialogOpen, setEditDrawingDialogOpen] = useState<boolean>(false);
	const [tool, setTool] = useState<"Polygon"|"Rectangle"|"Circle"|"Line"|"Selector">(
		currentUnit === "EA" ? "Selector" : "Rectangle");
	const [currentShape, setCurrentShape] = useState<null|DoxCircle|DoxLine|DoxRectangle|DoxPolygon>(null);
	const [displayLabels, setDisplayLabels] = useState<boolean>(true);
	const [deduction, setDeduction] = useState<boolean>(false);
	const [currentColour, setCurrentColour] = useState<string>("#ff0000");
	const [zHeight, setZHeight] = useState<number>(1);

	const [scalingMode, setScalingMode] = useState<boolean>(false);
	const [scaleLine, setScaleLine] = useState<null|DoxLine>(null);
	const [scaleLength, setScaleLength] = useState<number>(0.0);

	const [isLoadingImage, setIsLoadingImage] = useState<boolean>(false);

	const {setLoggedIn} = useAuth() as authContextInterface
	const handleEditSheetClicked = () => {
		if (!currentDrawings) return;
		setEditDrawingDialogOpen(true);
	}

	const handleUndoIconClicked = () => {
		if (!(takeOff && measurements)) { console.log("Missing selectedTakeOff && measurements"); return;}
		setMeasurements([...measurements.slice(0,-1)])
		setUnsavedMeasurements(true)
	}

	interface updateSheetProps{
		title?:string;
		scale?:number;
	}

	const handleUpdateTakeOff = async () => {

		if (!existingTakeOff)
			try{
				let totalValue = 0;
				measurements.forEach(measure => {
					totalValue += toFloat(measure.zValue)
				});
				const totalValueStr = totalValue.toPrecision(10)
				const result = await TakeOffAPI.add({
					takeOffId: costcode.costCodeId ,
					index: 0,
					description: costcode.title,
					totalValue: Math.round(parseFloat(totalValueStr) * 1000) /1000,
					unit: currentUnit,
					measurements: measurements,
					project: costcode.project,
					costCode: costcode.costCodeId,
				})
				const costCodeResult = await CostCodeAPI.updateQuickValues({
					costCode: costcode.costCodeId,
					qty: Math.round((result?.totalValue ?? totalValue) * 1000) /1000
				})
				console.log('result', result)
				if (result) {
					setTakeOffs((prevState) => [...prevState, result])
					setCostCodes((prevState) => [...prevState.map(cc =>
						cc.costCodeId === costCodeResult?.costCodeId ? costCodeResult : cc)
					])
					setCostcode(undefined)
				}
			} catch (err) {
				err === "AccessTokenNotFound"
					? setLoggedIn(false)
					: console.error(err);
			}
		else
			try{
				let totalValue = 0;
				measurements.forEach(measure => {
					totalValue += toFloat(measure.zValue)
				});
				const totalValueStr = totalValue.toPrecision(10)
				const result = await TakeOffAPI.update(existingTakeOff!.takeOffId, {
					description: costcode.title,
					totalValue: Math.round(parseFloat(totalValueStr) * 1000) /1000,
					unit: currentUnit,
					measurements: measurements,
				})
				const costCodeResult = await CostCodeAPI.updateQuickValues({
					costCode: costcode.costCodeId,
					qty: Math.round((result?.takeOff?.totalValue ?? totalValue) * 1000) /1000,
				})
				if (result) {
					setTakeOffs((prevState) => [...prevState.map(to =>
						to.takeOffId === existingTakeOff?.takeOffId ? result.takeOff : to
					)])
					setCostCodes((prevState) => [...prevState.map(cc =>
						cc.costCodeId === costCodeResult?.costCodeId ? costCodeResult : cc)
					])
					setCostcode(undefined)
				}
			} catch (err) {
				err === "AccessTokenNotFound"
					? setLoggedIn(false)
					: console.error(err);
			}
	}

	const updateSheets = async(sheetId: string|undefined, {title, scale }: updateSheetProps) => {
		try {
			const response = await drawingAPI.updateSheet(sheetId, {title: title, scale: scale}) as Sheet;
			let newDrawings: DrawingSet[] = []
			drawings.forEach((drawingSet: DrawingSet) =>{
				let newSheets: Sheet[] = []
				drawingSet.sheets.forEach((sheet: Sheet) => {
					if (sheet.sheetId === response.sheetId) newSheets.push(response)
					else newSheets.push(sheet)
				})
				drawingSet.sheets = newSheets
				newDrawings.push(drawingSet)
			})
			setDrawings([...newDrawings])
			setCurrentSheet(response)
		} catch (err){
			err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
		}
	}
	const updateMultipleSheets = async(setId: string|undefined, scale: number) => {
		try {
			if (!setId) return;
			const response = await drawingAPI.updateMultiSheet(setId, scale);
			let newDrawings: DrawingSet[] = []
			if (!response) return;
			drawings.forEach((drawingSet: DrawingSet) =>{
				if (drawingSet.setId === setId) newDrawings.push(response)
				else newDrawings.push(drawingSet)
			})
			setDrawings([...newDrawings])
			setCurrentDrawings(response)
		} catch (err){
			err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
		}
	}

	const updateDrawings = async (setId:string, setName: string, sheets: Sheet[]) => {
		try {
			const result = await drawingAPI.updateSet(setId, setName, sheets) as DrawingSet
			let newDrawings: DrawingSet[] = [];
			drawings.forEach((drawing: DrawingSet) => {
				if (drawing.setId === result.setId) newDrawings.push(result)
				else newDrawings.push(drawing)
			})
			setDrawings([...newDrawings])
		} catch (err) {
			err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
		}
	}

	const deleteDrawing = async (setId:string, deleteMeasures:boolean) => {
		try {
			const result = await drawingAPI.removeSet(setId, deleteMeasures)
			if (!result) return;
			let newDrawings: DrawingSet[] = [];
			drawings.forEach((drawingSet: DrawingSet) => {
				if (drawingSet.setId !== setId) newDrawings.push(drawingSet);
			});
			setDrawings([...newDrawings]);
		} catch (err) {
			err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
		}
	}

	const deleteSheet = async (sheetId:string, deleteMeasures:boolean) => {
		try {
			const result = await drawingAPI.removeSheet(sheetId, deleteMeasures)
			if (!result) return;
			let newDrawings: DrawingSet[] = [];
			drawings.forEach((drawingSet: DrawingSet) => {
				let newSheets: Sheet[] = [];
				drawingSet.sheets.forEach((sheet: Sheet) => {
					if (sheet.sheetId !== sheetId) newSheets.push(sheet);
				})
				newDrawings.push({...drawingSet, sheets: newSheets});
			});
			setDrawings([...newDrawings]);
		} catch (err) {
			err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
		}
	}

	const handleCancelUpload = () =>{
		setPendingFile(null)
		setShowUploadDialog(false)
	}

	const handleDrawingsDrop = async (newFile: Blob) => {
		setPendingFile(newFile)
		setShowUploadDialog(true)
	}

	const handleSubmitUpload = async (drawingSetName:string, highResolution:boolean) => {
		try {
			const company:string|undefined = JSON.parse(localStorage.getItem("currentCompany") || '{}').name;
			if (!company) { console.log("Missing company name"); return; }
			if (!pendingFile) { console.log("Missing pendingFile"); return; }
			const uploadFile = pendingFile
			// setCurrentlyDragging(false);
			setPendingFile(null)
			setShowUploadDialog(false)
			setIsLoadingImage(true);
			const response = await drawingAPI.uploadDrawings({
				name: drawingSetName,
				projectId: costcode.project,
				companyName: company,
				file: pendingFile,
				lowRes: !highResolution ? "TRUE" : "FALSE"
			})
			console.log(response)
			if (response) {
				setDrawings([...drawings, response])
				setCurrentDrawings(response)
			}
			setIsLoadingImage(false);
		} catch (err) {
			err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
		}
	}

	const singleMeasureContextValue: SingleMeasureContextInterface = {
		drawings,
		currentUnit,
		currentDrawings,
		setCurrentDrawings,
		currentSheet,
		setCurrentSheet,
		measurements,
		setMeasurements,
		unsavedMeasurements,
		setUnsavedMeasurements,
		editDrawingDialogOpen,
		setEditDrawingDialogOpen,
		currentShape,
		setCurrentShape,
		tool,
		setTool,
		displayLabels,
		setDisplayLabels,
		deduction,
		setDeduction,
		currentColour,
		setCurrentColour,
		zHeight,
		setZHeight,
		scalingMode,
		setScalingMode,
		scaleLine,
		setScaleLine,
		scaleLength,
		setScaleLength,
		isLoadingImage,
		setIsLoadingImage,
		handleUpdateTakeOff,
		selectedTakeOff: takeOff,
		editTakeOffMode: false,
	};

	return (
		<SingleMeasureContext.Provider value={singleMeasureContextValue}>
			<div style={{display: "flex", flexDirection: "column"}}>
				<div style={{width: "100%"}}>
					<Button
						style={{
							float: "right",
							position: "relative",
							top: 50,
							right: 10,
							background: "#f9f9ff",
							color: "#9974fe",
						}}
						onClick={()=>setCostcode(undefined)}
					>
						<ArrowBackIcon />
						Back
					</Button>
				</div>

			<div tabIndex={0} style={{outline:"none"}}>

				<Paper style={{overflow: "hidden"}}>
					<DrawingsNavbar
						handleEditSheetClicked={handleEditSheetClicked}
						handleUndoIconClicked={handleUndoIconClicked}
						setOpenDropzoneDialog={setOpenDropzoneDialog}
						single={true}
					/>
					{scalingMode && currentSheet
						? <ScaleBar	updateSheets={updateSheets} updateMultipleSheets={updateMultipleSheets} single={true}	/>
						:
						null
					}
					<ToolBar single={true} />
					{
						currentDrawings && currentSheet
						?
							<KonvaComponent single={true} />
						: <DrawingPlaceholder/>
					}
				</Paper>
				{currentDrawings
					?
					<DrawingSetDialog
						open={editDrawingDialogOpen}
						closeAction={() => setEditDrawingDialogOpen(false)}
						saveAction={updateDrawings}
						drawingSet={currentDrawings}
						deleteDrawing={deleteDrawing}
						deleteSheet={deleteSheet}
					/>
					:
					null
				}
			</div>
			{openDropzoneDialog && <DropzoneDialog
				acceptedFiles={['application/pdf']}
				cancelButtonText={"cancel"}
				submitButtonText={"submit"}
				maxFileSize={15000000}
				open={true}
				onClose={() => setOpenDropzoneDialog(false)}
				onSave={(files: Blob[]) => {
					console.log('Files:', files);
					handleDrawingsDrop(files[0])
					setOpenDropzoneDialog(false);
				}}
				showPreviews={true}
				showFileNamesInPreview={true}
				showAlerts={false}
			/>}
			{ showUploadDialog &&
				<UploadDialog
					open={true}
					closeAction={handleCancelUpload}
					saveAction={handleSubmitUpload}
				/>
			}
			</div>
		</SingleMeasureContext.Provider>
	)
};

export default SingleMeasurement;
export const useSingleMeasurementContext = () => useContext(SingleMeasureContext);
