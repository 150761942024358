import axios from "axios";
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";
import {
  AddressBookCompany,
  AddressBookContact,
} from "../../Models/addressBook";
import { Company } from "../../Models/company";

interface GetParams {}

//GET THE LIST OF ALL ADDRESS BOOK COMPANIES
const getList = async (companyId: string | undefined) => {
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    let result: AddressBookCompany[] = [];
    let params: any = {};
    if (companyId) params.company = companyId;
    const url = baseAddress + "/ab/company/";
    const response = await axios.get(url, {
      headers: { Authorization: "Bearer " + accessToken },
      params: params,
    });
    if (response?.data)
      result = response.data as AddressBookCompany[];
    return result;
  } catch (error:any) {
    console.log("Get List Address Book Error:", error);
    console.log(error?.request )
    console.log(error?.message )
    console.log(error?.config )
    console.log(error?.toJSON() )

  }
};

const getDetailed = async (addressBookCompanyId: string) => {
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) throw "AccessTokenNotFound"
  try {
    let result: AddressBookCompany| undefined = undefined;
    const url = baseAddress + "/ab/company/"+addressBookCompanyId+"/";
    const response = await axios.get(url, {
      headers: { Authorization: "Bearer " + accessToken },
    });
    if (response.status === 200) result = response.data as AddressBookCompany;
    return result;
  } catch (error:any) {
    console.log("Get List Address Book Company Error:", error);
  }
};

//ADDS A NEW ADDRESS BOOK COMPANY
const add = async (addressBookCompany: AddressBookCompany) => {
  console.log("Adding Company: " + addressBookCompany.companyName);
  let result: AddressBookCompany | undefined = undefined;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }

  try {
    // //* SOCKET REQUEST
    // SocketService.sendMessage(addressBookCompany);

    // //* HTTP REQUEST
    const response = await axios.post(
      baseAddress + "/ab/company/",
      addressBookCompany,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );

    result =
      response.status === 201
        ? (response.data as AddressBookCompany)
        : undefined;
    // console.log("ADDED NEW ADDRESS BOOK", result);
  } catch (error) {
    console.error("ERROR ADDING ADDRESS BOOK", error);
  }
  return result;
};

interface UpdateBody {
  companyName?: string;
  companyAbn?: string;
  email?: string;
  phone?: string;
  timeStamp?: string;
}

//UPDATE ADDRESS BOOK COMPANY
const update = async (
  companyId: string | undefined,
  addressBookCompany: AddressBookCompany
) => {
  console.log("Company to update", addressBookCompany);
  // Update one or more property of a company without affecting other values
  // Only pass in what is needed to be updated
  let result: Company | undefined = undefined;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }

  try {
    const response = await axios.patch(
      baseAddress + "/ab/company/" + companyId + "/",
      addressBookCompany,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.status === 200 ? (response.data as Company) : undefined;
    return result;
  } catch (error) {
    console.error("ERROR UPDATING ADDRESS BOOK COMPANY", error);
    return undefined;
  }
};

//DELETE ADDRESS BOOK COMPANY
const remove = async (addressBookCompanyId: string | undefined) => {
  /// Permanently delete ab company by uuid
  let result: boolean = false;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    const response = await axios.delete(
      baseAddress + "/ab/company/" + addressBookCompanyId + "/",
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.status === 204;
    return result;
  } catch (error) {
    console.error("ERROR DELETING ADDRESS BOOK COMPANY", error);
    return undefined;
  }
};

//GET THE LIST OF ALL ADDRESS BOOK COMPANIES
const getContactList = async () => {
  try {
    console.log("Trying to get Address Book Contact List:");
    const accessToken: string = CookieService.get("access_token");
    console.log("AccessToken: " + accessToken);
    if (!accessToken) {
      throw "AddressBookContact - AccessTokenNotFound";
    }

    const url = baseAddress + "/ab/contact/";
    console.log("URL: " + url);
    const response = await axios.get(url, {
      headers: { Authorization: "Bearer " + accessToken },
    });

    return response.data["results"];
  } catch (error) {
    console.log("Get List Address Book Contact Error:", error);
    return undefined;
  }
};

//ADDS A NEW ADDRESS BOOK CONTACT
const addContact = async (addressBookContact: AddressBookContact) => {
  console.log("Adding Contact: " + addressBookContact);
  let result: AddressBookContact | undefined = undefined;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }

  try {
    const response = await axios.post(
      baseAddress + "/ab/contact/",
      addressBookContact,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result =
      response.status === 201
        ? (response.data as AddressBookContact)
        : undefined;
    console.log("ADDED NEW ADDRESS BOOK CONTACT", result);
  } catch (error) {
    console.error("ERROR ADDING ADDRESS BOOK CONTACT", error);
  }
  return result;
};

//UPDATE ADDRESS BOOK CONTACT
const updateContact = async (
  contactId: string | undefined,
  addressBookContact: AddressBookContact
) => {
  // Update one or more property of a contact without affecting other values
  // Only pass in what is needed to be updated
  let result: Company | undefined = undefined;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }

  try {
    const response = await axios.patch(
      baseAddress + "/ab/contact/" + contactId + "/",
      addressBookContact,
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.status === 200 ? (response.data as Company) : undefined;
  } catch (error) {
    console.error("ERROR UPDATING ADDRESS BOOK CONTACT", error);
  }
  return result;
};

//DELETE ADDRESS BOOK CONTACT
const removeContact = async (contactId: string | undefined) => {
  /// Permanently delete ab contact by uuid
  let result: boolean = false;
  if (offline) {
    return result;
  }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) {
    throw "AccessTokenNotFound";
  }
  try {
    const response = await axios.delete(
      baseAddress + "/ab/contact/" + contactId + "/",
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    );
    result = response.status === 204;
  } catch (error) {
    console.error("ERROR DELETING ADDRESS BOOK CONTACT", error);
  }
  return result;
};

const AddressBookAPI = {
  getList,
  add,
  update,
  remove,
  getContactList,
  addContact,
  updateContact,
  removeContact,
  getDetailed
};

export default AddressBookAPI;
