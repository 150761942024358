import axios from "axios";
import {
    DoxCircle,
    DoxLine,
    DoxPolygon,
    DoxRectangle,
    DrawingSet,
    TakeOff,
    TakeOffMeasurement
} from '../../Models/takeOffs';
import CookieService from "../cookieService";
import { offline, baseAddress } from "../../settings";
import {Costcode, NewCostCode} from "../../Models/costcode";
import dummy from "./dummyData";

interface Params {
    project?: string;
    cost_code?: string;
}
const getList = async (projectId: string|undefined, costCodeId: string|undefined)  => {
    // Get's a list of take offs for a given project selected with project Id
    let result: TakeOff[] = []
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        let params: Params = {}
        if (!costCodeId && !projectId) return result;
        if (!costCodeId && projectId) params.project = projectId
        else if (costCodeId) params.cost_code = costCodeId
        const response = await axios.get(
            baseAddress+"/takeoff/",
            {
                headers: { Authorization: "Bearer " + accessToken },
                params: params
            }
        )
        result = response.data.results as Array<TakeOff>;
        console.log("TAKEOFF SETS", response.data)
    } catch (error) {
        console.error("ERROR FETCHING TAKEOFF SETS", error)
    }
    return result
}


const getMeasurements = async (takeOffId: string)  => {
    // Get's a list of take offs for a given project selected with project Id
    let result: TakeOff | undefined = undefined
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        const response = await axios.get(
            baseAddress+"/takeoff/"+takeOffId+"/",
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.data as TakeOff;
        console.log("MEASUREMENT SETS", response.data)
    } catch (error) {
        console.error("ERROR MEASUREMENT SETS", error)
    }
    return result
}

const add = async (takeOff: TakeOff)  => {
    let result: TakeOff | undefined = undefined;
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken) { throw "AccessTokenNotFound" }
    try {
        const response = await axios.post(
            baseAddress+"/takeoff/new/",
            takeOff,
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.data as TakeOff
        console.log("TAKEOFF", result)
    } catch (error) {
        console.error("ERROR ADD TAKEOFF", error)
    }
    return result
}

const remove = async (takeOffId: string)  => {
    let result: boolean = false;
    if (offline){ result = true; return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken) { throw "AccessTokenNotFound" }

    try {
        if (offline){ return {} }
        const accessToken: string = CookieService.get("access_token");
        if (!accessToken){ throw "AccessTokenNotFound" }

        const response = await axios.delete(
            baseAddress+"/takeoff/"+takeOffId+"/",
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.status === 204;
    } catch (error) {
        console.error("ERROR DELETING TAKEOFF", error)
    }
    return result
}

interface UpdateBody {
    description?: string;
    totalValue?: number|string;
    unit?: string;
    measurements?: (DoxCircle|DoxLine|DoxRectangle|DoxPolygon)[];
}

const update = async (
    takeOffId: string,
    { description, totalValue, unit, measurements,}: UpdateBody
) => {
    interface resultInterface{
        takeOff: TakeOff,
        errors: string[]
    }
    let result: resultInterface | undefined;
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        let body: UpdateBody = {}
        if (description) {body.description = description;}
        if (totalValue) {body.totalValue = totalValue;}
        if (unit) {body.unit = unit;}
        if (measurements) {body.measurements = measurements;}
        console.log("body", body);
        const response = await axios.patch(
            baseAddress+"/takeoff/"+takeOffId+"/",
            body,
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.data as resultInterface;
        console.log("UPDATED TAKEOFF", result)
    } catch (error) {
        console.error("ERROR UPDATING TAKEOFF", error)
    }
    return result
}

const setOrder = async (
    takeOffIds: string[]
) => {
    let result: boolean = true;
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        const response = await axios.post(
            baseAddress+"/takeoff/set_order/",
            {order: takeOffIds},
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.status === 204;
    } catch (error) {
        console.error("ERROR UPDATING ORDER", error)
    }
    return result;
}


const takeOffAPI = {
    getList,
    getMeasurements,
    add,
    remove,
    update,
    setOrder
};

export default takeOffAPI