import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

const CheckBoxIcon = styled('span')(({ theme }) => ({
    borderRadius: 6,
    border: "1.5px solid #9974FE",
    width: 15,
    height: 15,
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    }
}));

const CheckBoxCheckedIcon = styled(CheckBoxIcon)({
    backgroundColor: '#9974FE',
    '&:before': {
      display: 'block',
      width: 15,
      height: 15,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
});

const CustomCheckbox = (props: CheckboxProps) => {
  return (
    <Checkbox
      sx={{
        'padding':'0',
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<CheckBoxCheckedIcon />}
      icon={<CheckBoxIcon />}
      inputProps={{ 'aria-label': 'Checkbox' }}
      {...props}
    />
  );
}

export default CustomCheckbox