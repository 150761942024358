import { checkEqualDateWithoutTime } from "./../../../utilities/FunctionUtilities";
import { BasicStage } from "./../../../Models/stage";
import { Costcode } from "../../../Models/costcode";
import { ScheduleItem } from "../../../Models/schedules";
import { convertWeekDaysValueToText } from "../../../utilities/FunctionUtilities";

export const getAllMonthContract = (startDate: string, endDate: string) => {
  let start: Date = new Date(startDate);
  let end: Date = new Date(endDate);

  let months: number[] = [];

  while (start <= end) {
    months.push(start.getMonth());
    start.setMonth(start.getMonth() + 1);
  }

  return months;
};

export const convertMonthToText = (month: number) => {
  //!month number should follow value of Date type (0-11) to get correct result
  switch (month) {
    case 0:
      return "Jan";
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    case 11:
      return "Dec";
    default:
      return "Inv";
  }
};

export interface IStagePayment {
  stageName: string;
  paymentAmount: number;
  paymentdate: string;
}

function getTimeBetweenDates(startDate: string, endDate: string): number {
  let start: Date = new Date(startDate);
  let end: Date = new Date(endDate);

  return end.getTime() - start.getTime();
}

export const getPositionOfStageMilestone = (
  stagePayment: BasicStage,
  startDateContract: string,
  endDateContract: string
): number => {
  let totalTime: number = getTimeBetweenDates(
    startDateContract,
    endDateContract
  );
  let paymentTime: number = getTimeBetweenDates(
    startDateContract,
    stagePayment.targetDate
  );

  return (paymentTime / totalTime) * 100;
};

export const checkScheduleDataType = (
  data: ScheduleItem | Costcode
): "schedule" | "costcode" => {
  if ("scheduleId" in data) return "schedule";
  else return "costcode";
};

interface IScheduleDataCheckProps {
  itemChecked: ScheduleItem | Costcode;
  returnData: "title" | "dueDate" | "startDate";
}
export const getScheduleData = ({
  itemChecked,
  returnData,
}: IScheduleDataCheckProps): string => {
  if (checkScheduleDataType(itemChecked) === "schedule") {
    if (returnData === "title") {
      return (itemChecked as ScheduleItem).title;
    } else if (returnData === "startDate") {
      return (itemChecked as ScheduleItem).startDate;
    } else return (itemChecked as ScheduleItem).endDate;
  } else {
    if (returnData === "title") {
      return (itemChecked as Costcode).title;
    } else if (returnData === "startDate") {
      return (itemChecked as Costcode).startDate;
    } else return (itemChecked as Costcode).endDate;
  }
};
export const checkDateInRange = (
  start: Date,
  end: Date,
  itemChecked: Date
): boolean => {
  if (
    (itemChecked >= start && itemChecked <= end) ||
    checkEqualDateWithoutTime(itemChecked, start) ||
    checkEqualDateWithoutTime(itemChecked, end)
  )
    return true;
  else return false;
};
