import { Box } from "@mui/material";
import { styled } from "@mui/styles";
import React from "react";
import { Mail } from "../../../Models/mail";
import { ReactComponent as MailroomLogo } from "../../../Assets/DrawerMailrooomClicked.svg";

import { ReactComponent as CostingsLogo } from "../../../Assets/DrawerCostingClicked.svg";

import { ReactComponent as EstimatorLogo } from "../../../Assets/POSEstimator.svg";

import { ReactComponent as NoticeLogo } from "../../../Assets/Clipboard-alt.svg";
import ExpandedItem from "./ExpandedItem";
import { useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

type Props = {
  expand: boolean;
  itemTitle: string;
  setActiveSublist: Function;
  identifierValue: number;
};

const DrawerItem = ({
  expand,
  itemTitle,
  setActiveSublist,
  identifierValue,
}: Props) => {
  const { pathname } = useLocation();

  const mouseDownHandler = (e:any) => {
    if (e?.button === 0 && !(e?.ctrlKey || e?.metaKey)) {
      if (!expand) setActiveSublist(identifierValue);
      else {
        setActiveSublist(undefined);
        if (itemTitle === 'Costings') navigate("/");
        else navigate("/" + itemTitle.replaceAll(" ", ""));
      }
      return
    }
    if ((e?.button === 0 && (e?.ctrlKey || e?.metaKey)) || e.button === 1) {
      if (itemTitle === 'Costings') window.open(`${window.location.host}/`);
      window.open("/" + itemTitle.replaceAll(" ", ""));
    }
  }

  const navigate = useNavigate();
  return (
    <DrawerItemContainer>
      {/*Title Section */}
      <ItemTitle onMouseDown={mouseDownHandler} >
        {IconTitle(itemTitle)}
        <Box>{itemTitle}</Box>
      </ItemTitle>

      {/*Item Expand Section */}
      <AnimatePresence>
        {expand ? (
          <motion.div
            initial={{ scaleY: 0.5 }}
            animate={{ scaleY: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ scaleY: [1, 0] }}
          >
            <ExpandSectionContainer>
              <ExpandedItem itemTitle={itemTitle} />
            </ExpandSectionContainer>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </DrawerItemContainer>
  );
};

export default DrawerItem;

const DrawerItemContainer = styled(Box)({
  backgroundColor: "white",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "8px 0 4px 0",
  borderBottom: "1px solid rgba(194, 184, 222, 0.5)",
});

const ItemTitle = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  fontFamily: "Nostromo Regular",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "22px",
  color: "#5E32DE",
  cursor: "pointer",
});

const ExpandSectionContainer = styled(Box)({
  width: "calc(100% - 10px)",
  marginLeft: "10px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  fontFamily: "Nostromo Regular",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "22px",
  color: "#5E32DE",
});
//#############UTILITIES FUNCTION#################
const IconTitle = (title: string) => {
  switch (title) {
    case "Mailroom":
      return <MailroomLogo />;
    case "Estimator":
      return <EstimatorLogo />;
    case "Costings":
      return <CostingsLogo />;
    case "Notice Board":
      return <NoticeLogo />;
    default:
      return <MailroomLogo />;
  }
};
