import { useCallback, useEffect } from "react";
import { Notification } from "../../Models/notification";
import { baseAddress, offline } from "../../settings";
import CookieService from "../cookieService";
import axios from "axios";


const getList = async (userId: string) => {

  let result: Notification[] = []
  if (offline) return result;
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) throw "AccessTokenNotFound";
  try {
    let params = { user: userId, seen: false}
    // let params = { user: userId}
    console.log('params', params)
    const response = await axios.get(
      baseAddress + "/notification/",
      {
        headers: { Authorization: "Bearer " + accessToken },
        params: params
      }
    )
    console.log(response.data)
    result = response.status === 200 ? (response.data.results as Notification[]) : [];
  } catch (error) {
    return result
  }
  return result;
};

interface Payload {
  title?: string;
  body?: string;
  lastModifiedBy: string;
  lastModified: string;
  createdBy?: string;
  costCode?: string;
  lines: string[];
}

const update = async (
  notificationId: string,
  seen: boolean,
) => {
  // Update one or more property of a notice without affecting other values
  // Only pass in what is needed to be updated
  let result: Notification | undefined = undefined
  if (offline) { return result }
  const accessToken: string = CookieService.get("access_token");
  if (!accessToken) { throw "AccessTokenNotFound" }

  try {
    // let payload: Payload = {
    //   lastModifiedBy: userId,
    //   lastModified: new Date().toISOString(),
    //   createdBy: creatorId,
    //   lines: lines
    // }
    // if (title) { payload.title = title; }
    // if (body) { payload.body = body; }
    // if (costCode) { payload.costCode = costCode; }

    console.log('noteId', notificationId);
    console.log('seen', seen);
    const response = await axios.patch(
      baseAddress + "/notification/" + notificationId + "/",
      { seen: seen },
      {
        headers: { Authorization: "Bearer " + accessToken },
      }
    )
    result = response.status === 200 ? response.data as Notification : undefined;
  } catch (error) {
    console.error("ERROR UPDATING NOTE", error)
  }
  return result
}

const NotificationAPI = {
  getList,
  update,
};

export default NotificationAPI;
