import {Box, IconButton, MenuItem, Select, TableCell, TableRow, TextField, Tooltip, Typography} from "@mui/material";
import React, {MouseEventHandler, useEffect, useState} from "react";
import {DoxCircle, DoxLine, DoxPolygon, DoxRectangle, TakeOff} from "../../../Models/takeOffs";
import { ICONS } from "../../../Assets/index.js";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {takeOffContextInterface, useTakeOff} from "../../Providers/TakeOffProvider";
import TakeOffAPI from "../../../Services/DoxleAPI/takeOffAPI";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import CustomCheckbox from "../../../utilities/CheckBox/CheckBox";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

interface props {
    idx: number
    takeOff: TakeOff;
    editMode: boolean;
    setEditMode: Function;
    setDialogType: Function;
    setDialogTakeOff: Function;
    setEditDescription: Function;
    editUnit: "LM"|"M2"|"M3"|"EA"
    setEditUnit: Function;
    handleUpdateTakeOff: Function
    checkedTakeOffs: string[]
    setCheckedTakeOffs: React.Dispatch<React.SetStateAction<string[]>>,
    draggable: boolean
}

const TakeOffRow: React.FC<props> = ({
                                         idx,
                                         takeOff,
                                         editMode,
                                         setEditMode,
                                         setDialogType,
                                         setDialogTakeOff,
                                         setEditDescription,
                                         editUnit,
                                         setEditUnit,
                                         handleUpdateTakeOff,
                                         checkedTakeOffs,
                                         setCheckedTakeOffs,
                                         draggable
                                     }) => {
    const TakeOffContext = useTakeOff() as takeOffContextInterface
    const {
        projectId, costCodeId,
        takeOffs, setTakeOffs,
        selectedTakeOff, setSelectedTakeOff,
        setCurrentUnit,
        currentValue,
        unsavedMeasurements
    } = TakeOffContext;
    const authContext = useAuth() as authContextInterface;

    const handleCheckBoxClick = (e: any) => {
        if (e?.target?.checked) setCheckedTakeOffs([...checkedTakeOffs, takeOff.takeOffId])
        else {
            const i = checkedTakeOffs.indexOf(takeOff.takeOffId)
            if (i >=0) checkedTakeOffs.splice(i, 1)
            const newIds = checkedTakeOffs
            setCheckedTakeOffs([...newIds])
        }
    }

    const handleRowClick = (e:any) =>{
        if (unsavedMeasurements && (
                (selectedTakeOff && selectedTakeOff?.takeOffId !== takeOff.takeOffId)
                || e.detail === 2
        )) {
            setDialogTakeOff(takeOff)
            setDialogType("UNSAVED");
            return;
        }
        if (!selectedTakeOff || selectedTakeOff?.takeOffId !== takeOff.takeOffId) {
            setSelectedTakeOff(takeOff)
            setCurrentUnit(takeOff.unit)
        }
        if (editMode && selectedTakeOff?.takeOffId !== takeOff.takeOffId) setEditMode(false)
        if (e.detail === 2) {
            setDialogTakeOff(takeOff)
            setEditMode(true)
        }// double click
    }

    const handleDeleteMeasurement = () =>{
        setDialogTakeOff(takeOff)
        setDialogType("DELETE");
    }


    return (
        <>
        <TableRow
            key={takeOff.takeOffId}
            className={`no-cursor ${selectedTakeOff?.takeOffId === takeOff.takeOffId ? 'active' : ''}`}
        >
            <TableCell  style={{width: '5%'}}>
                {
                    draggable
                    ?
                        <DragIndicatorIcon style={{color: "#A593D8"}}/>
                    :
                        <CustomCheckbox
                            style={{transform: 'scale(0.6)'}}
                            checked={checkedTakeOffs.includes(takeOff.takeOffId)}
                            onClick={handleCheckBoxClick}
                        />
                }
            </TableCell>
            <TableCell style={{}} onClick={handleRowClick}>
                {selectedTakeOff?.takeOffId === takeOff.takeOffId && editMode ? (
                    <Box>
                        <TextField
                            className="measurementName"
                            defaultValue={takeOff.description}
                            onKeyDownCapture={(e) => {
                                if (e.keyCode === 13) handleUpdateTakeOff()
                            }}
                            autoFocus
                            onChange={(e) => setEditDescription(e.target.value)}
                            // onFocus={(e) => e.target.value = ""}
                            // onBlur={(e) => e.target.value = takeOff.description}
                        />
                    </Box>
                ) : (
                    <Typography style={{fontSize: 'small', fontFamily: 'Roboto Mono'}}>
                        {idx + 1} &nbsp; {takeOff.description}
                    </Typography>
                )}
                {!costCodeId && takeOff?.costCodeTitle &&
                    <Typography style={{fontSize: 'x-small', fontFamily: 'Roboto Mono'}}>
                        {takeOff?.costCodeTitle}
                    </Typography>
                }
            </TableCell>
            <TableCell className="textColor" style={{width: '80px', textAlign: 'right'}} onClick={handleRowClick}>
                {(selectedTakeOff?.takeOffId !== takeOff.takeOffId || !editMode) &&
                    <Typography id="measurement" style={{fontSize: 'small', fontFamily: 'Roboto Mono', width: '100%'}}>
                        {selectedTakeOff?.takeOffId === takeOff.takeOffId
                            ? `${currentValue.toFixed(3)} ${takeOff.unit.toLowerCase()}`
                            : `${takeOff.totalValue} ${takeOff.unit.toLowerCase()}`
                        }
                    </Typography>
                }
                {selectedTakeOff?.takeOffId === takeOff.takeOffId && editMode &&
                    <Select
                        onKeyDownCapture={(e) => {
                            if (e.keyCode === 13) handleUpdateTakeOff()
                        }}
                        className="unit textColor"
                        onChange={(e) => {
                            if (
                                takeOff.takeOffId !== "new" &&
                                e.target.value !== takeOff.unit &&
                                takeOff.measurements &&
                                takeOff.measurements.length > 0
                            ) setDialogType("UNIT")
                            if (
                                e.target.value === "LM" ||
                                e.target.value === "M2" ||
                                e.target.value === "M3" ||
                                e.target.value === "EA"
                            ) setEditUnit(e.target.value)
                        }}
                        value={editUnit}
                        style={{width: "100%"}}
                    >
                        <MenuItem value="EA">EA</MenuItem>
                        <MenuItem value="LM">LM</MenuItem>
                        <MenuItem value="M2">M2</MenuItem>
                        <MenuItem value="M3">M3</MenuItem>
                    </Select>
                }
            </TableCell>
        </TableRow>
        {/*<TableRow*/}
        {/*    key={takeOff.takeOffId+'2'}*/}
        {/*    className={`no-cursor ${selectedTakeOff?.takeOffId === takeOff.takeOffId ? 'active' : ''}`}*/}
        {/*>*/}
        {/*    <TableCell align="right" className="textColor" style={{width: '10%'}}>*/}

        {/*    </TableCell>*/}
        {/*    <TableCell align="right" className="textColor" style={{width: '20%'}}>*/}
        {/*        {selectedTakeOff?.takeOffId === takeOff.takeOffId && editMode === true ? (*/}
        {/*            <Box>*/}
        {/*                <Select*/}
        {/*                    className="unit"*/}
        {/*                    onChange={(e) => {*/}
        {/*                        if (*/}
        {/*                            takeOff.takeOffId !== "new" &&*/}
        {/*                            e.target.value !== takeOff.unit &&*/}
        {/*                            takeOff.measurements &&*/}
        {/*                            takeOff.measurements.length > 0*/}
        {/*                        ) setDialogType("UNIT")*/}
        {/*                        if (*/}
        {/*                            e.target.value === "LM" ||*/}
        {/*                            e.target.value === "M2" ||*/}
        {/*                            e.target.value === "M3" ||*/}
        {/*                            e.target.value === "EA"*/}
        {/*                        ) setEditUnit(e.target.value)*/}
        {/*                    }}*/}
        {/*                    value={editUnit}*/}
        {/*                    style={{width: "100%"}}*/}
        {/*                >*/}
        {/*                    <MenuItem value="EA">EA</MenuItem>*/}
        {/*                    <MenuItem value="LM">LM</MenuItem>*/}
        {/*                    <MenuItem value="M2">M2</MenuItem>*/}
        {/*                    <MenuItem value="M3">M3</MenuItem>*/}
        {/*                </Select>*/}
        {/*            </Box>*/}
        {/*        ) : (*/}
        {/*            <Box className="unit">*/}
        {/*                {takeOff.unit}*/}
        {/*            </Box>*/}
        {/*        )}*/}
        {/*    </TableCell>*/}
        {/*    /!*<TableCell align="right" style={{width: '10%'}}>*!/*/}
        {/*    /!*    {selectedTakeOff?.takeOffId === takeOff.takeOffId && editMode ? (*!/*/}
        {/*    /!*            <IconButton onClick={() => handleUpdateTakeOff()}>*!/*/}
        {/*    /!*                <Tooltip title="Delete Measurement">*!/*/}
        {/*    /!*                    <ICONS.checkIcon />*!/*/}
        {/*    /!*                </Tooltip>*!/*/}
        {/*    /!*            </IconButton>*!/*/}
        {/*    /!*    ) : (*!/*/}
        {/*    /!*        <IconButton*!/*/}
        {/*    /!*            style={{display: selectedTakeOff?.takeOffId === takeOff.takeOffId ? "unset" : "none"}}*!/*/}
        {/*    /!*            onClick={() => {handleDeleteMeasurement()}}*!/*/}
        {/*    /!*        >*!/*/}
        {/*    /!*            <Tooltip title="Delete Measurement">*!/*/}
        {/*    /!*                <img*!/*/}
        {/*    /!*                    className="tableBodyIcons"*!/*/}
        {/*    /!*                    src={ICONS.trashIcon}*!/*/}
        {/*    /!*                    alt="trash icon"*!/*/}
        {/*    /!*                />*!/*/}
        {/*    /!*            </Tooltip>*!/*/}
        {/*    /!*        </IconButton>*!/*/}
        {/*    /!*    )}*!/*/}
        {/*    /!*</TableCell>*!/*/}

        {/*</TableRow>*/}
        </>
    );
}

export default TakeOffRow

// {editMode ?
//     <>
//         {newRow.map((element: any, idx: any) => {
//             return (
//                 <TableRow className="no-cursor newMeasurementRow" key={idx}>
//                     <TableCell className="dragContent" style={{width: '10%'}}>
//                         <img
//                             className="tableBodyIcons"
//                             src={ICONS.modulesIcon}
//                             alt="modules icon"
//                         />
//                     </TableCell>
//                     <TableCell style={{width: '50%'}}>
//                         <Box>
//                             <TextField
//                                 className="measurementName"
//                                 defaultValue={element.description}
//                                 onChange={(e) => setNewName(e.target.value)}
//                                 onFocus={(e) => e.target.value = ""}
//                                 onBlur={(e) => e.target.value = element.description}
//                             />
//                         </Box>
//                     </TableCell>
//                     <TableCell align="right" className="textColor" style={{width: '10%'}}>
//                         <Box id="measurement">
//                             {element.totalValue}
//                         </Box>
//                     </TableCell>
//                     <TableCell align="right" className="textColor" style={{width: '20%'}}>
//                         <Box>
//                             <Select
//                                 className="unit"
//                                 onChange={(e) => {
//                                     setCurrUnit(e.target.value)
//                                     setNewUnit(e.target.value)
//                                 }}
//                                 defaultValue={element.unit}
//                                 style={{width: "100%"}}
//                             >
//                                 <MenuItem value="EA">ea</MenuItem>
//                                 <MenuItem value="LM">lm</MenuItem>
//                                 <MenuItem value="M2">m2</MenuItem>
//                                 <MenuItem value="M3">m3</MenuItem>
//                             </Select>
//                         </Box>
//                     </TableCell>
//                     <TableCell align="right" style={{width: '10%'}}>
//                         <ICONS.checkIcon className="checkIcon" onClick={saveNewRow}/>
//                     </TableCell>
//                 </TableRow>
//             )
//         })}
//     </>
//     : null}