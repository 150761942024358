import React, { useCallback, useEffect, useState } from "react";
import "./App.css";
import Companies from "./components/Content/Companies/Companies";
import MenuDrawer from "./components/Menu/MenuDrawer";
import { Company } from "../src/Models/company";
import { Routes, Route } from "react-router-dom";
import ErrorPage from "../src/components/Content/ErrorPages/ErrorPage";
import Login from "../src/components/Content/Login/Login";
import Hello from "../src/components//Content/HelloComponents/Hello";
import Projects from "../src/components//Content/Projects/Projects";
import Notices from "../src/components//Content/Notices/Notices";
import Estimator from "../src/components//Content/Estimator/Estimator";
import Builder from "../src/components//Content/Builder/Builder";
import Dockets from "../src/components//Content/Dockets/Dockets";
import { DRAWER_LABELS, DRAWER_FOOTER_LABELS } from "./utilities/constants";
import {
  XeroFirstAuth,
  CallBack,
} from "./components/Content/AccountMapping/xeroAuth";
import AccountMappingApp from "./components/Content/AccountMapping/AccountMappingApp";
import WindowDimensions from "./utilities/WindowDimensions/windowDimension";
import {
  useAuth,
  authContextInterface,
} from "./components/Providers/AuthProvider";
import Measurements from "../src/components/Content/Measurements/Measurements";
import AddressBookTable from "./components/Content/AddressBook/AddressBookTable";
import Pricelists from "./components/Content/Pricelist/Pricelists";
import { SocketProvider } from "./Services/Sockets/socketContext";
import { useSocket } from "./Services/Sockets/useSocket";
import SocketAPI from "./Services/DoxleAPI/socketAPI";
import LoginStyle from "./components/Login/LoginStyle";
import {
  EstimatorProviderInterface,
  useEstimator,
} from "./components/Content/Estimator/EstimatorProvider";
import NewLogin from "./components/Login/NewLogin";
import { Snackbar } from "@mui/material";
import XeroLinkScreen from "./components/Content/AccountMapping/XeroLinkScreen";
import { XeroProvider } from "./components/Providers/XeroProvider";
import EstimatorJSON from "./components/EstimatorJSON";
import Mailroom from "./components/Content/Mailroom/Mailroom";
import TimeTable from "./components/Content/TimeTable/TimeTable";
import ProjectTimelineGrid from "./components/Content/ProjectTimeline/ProjectTimelineGrid";
import TimelineMatrixTable from "./components/Content/TimelineMatrix/TimelineMatrixTable";
import CashflowTable from "./components/Content/Cashflow/CashflowTable";
// import {alertContextInterface, useAlerts} from "./components/Providers/AlertProvider";

const App: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [company, setCompany] = useState<Company>();
  const [allowLogin, setAllowLogin] = useState<boolean>(false);
  const authContext = useAuth() as authContextInterface;
  const { loggedIn, firstAuth } = authContext;
  const { height, width } = WindowDimensions();
  const estimatorContext = useEstimator() as EstimatorProviderInterface;
  const handleDrag = (e: any) => {
    e.preventDefault();
  };

  // const { AlertDisplay } = useAlerts() as alertContextInterface;

  // ! <--- SOCKETS
  const socket = useSocket();

  const onMessage = useCallback((message) => {
    const data = JSON.parse(message?.data);
    const serverStatus: string = data.status;
    const receivedMessage = data.message;

    if (serverStatus === "SUCCESS") {
      console.log("%c" + receivedMessage, "background:yellow; color:black");
    } else {
      console.log("%c" + receivedMessage, "background:red; color:white");
    }
  }, []);

  useEffect(() => {
    socket.addEventListener("message", onMessage);

    return () => {
      socket.removeEventListener("message", onMessage);
    };
  }, [socket, onMessage]);

  //! SOCKETS --->

  return (
    <SocketProvider>
      {
        <>
          <div
            className="App"
            onDragOverCapture={handleDrag}
            onDropCapture={handleDrag}
          >
            {/* Modal component is always rendered = visibility set depending on initial opening & logged in state*/}
           {firstAuth && <Login modal={!firstAuth} display={loggedIn} /> } 
            {
              !firstAuth ? ( //If user has initially logged in app is also rendered
                <>
                  <MenuDrawer
                    company={company}
                    setDisplayDrawer={setOpen}
                    width={width}
                  />

                  <div
                    className="section2"
                    style={{
                      width: "100%",
                      height: "100vh",
                      // marginRight: width < 928 ? "2%" : "2%",
                      // marginLeft: width < 928 ? "0px" : "129px",
                      // height: "calc(100vh - 25px - 25px)",
                      // marginRight: "76px",
                      // marginTop: "25px",
                      // borderRadius: "12px",
                      overflow: "hidden",
                      background: "white",
                      // marginLeft: !open ? "4%" : "none",
                      margin: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <Companies initialiseCompany={setCompany} />

                      <Routes>
                        {/* <Route
                          path='/estimatorJSON'
                          //element={<Mailroom company={company} />}
                          //element={<EstimatorJSON />}
                        /> */}
                        <Route
                          path="/"
                          // element={<Projects company={company} />}
                          element={<ProjectTimelineGrid company={company} />}
                        />
                        {/* <Route path="/Builder/Projects" element={<Projects company={company}/>}/> */}
                        <Route
                          path="/Projects"
                          element={<Projects company={company} />}
                        />
                        <Route
                          path="/Projects/*"
                          element={<Projects company={company} />}
                        />
                        <Route
                          path={
                            "/" +
                            Object.values(DRAWER_LABELS)[0].replaceAll(
                              " ",
                              ""
                            ) +
                            "/*"
                          }
                          element={<Mailroom company={company} />}
                        />
                        {/* <Route
                          path={
                            '/' +
                            Object.values(DRAWER_LABELS)[0].replaceAll(
                              ' ',
                              ''
                            ) +
                            '/*'
                          }
                          element={<Notices company={company} />}
                        /> */}
                        {/* <Route
                          path={
                            '/' +
                            Object.values(DRAWER_LABELS)[1].replaceAll(' ', '')
                          }
                          element={<Builder company={company} />}
                        />
                        {/* <Route
                          path={
                            '/' +
                            Object.values(DRAWER_LABELS)[2].replaceAll(' ', '')
                          }
                          element={<Dockets company={company} />}
                        /> 
                        <Route
                          path={
                            '/' +
                            Object.values(DRAWER_LABELS)[1].replaceAll(' ', '')
                          }
                          element={<Estimator company={company} />}
                        />
                        {/* <Route
                          path={
                            '/' +
                            Object.values(DRAWER_LABELS)[2].replaceAll(' ', '')
                          }
                          element={<Dockets company={company} />}
                        /> */}
                        <Route
                          path={"/docket"}
                          element={<Dockets company={company} />}
                        />
                        <Route
                          path={
                            "/" +
                            Object.values(DRAWER_LABELS)[2].replaceAll(" ", "")
                          }
                          element={<Projects company={company} />}
                        />
                        {/* <Route
                          path={
                            '/' +
                            Object.values(DRAWER_LABELS)[3].replaceAll(' ', '')
                          }
                          element={<Estimator company={company} />}
                        /> */}
                        <Route
                          path={
                            "/" +
                            Object.values(DRAWER_LABELS)[3].replaceAll(" ", "") +
                            "/*"
                          }
                          element={<Notices company={company} />}
                        />
                        {/* <Route
                          path={"/Timetable"}
                          element={<TimeTable company={company} />}
                        /> */}

                        <Route
                          path={
                            "/" +
                            Object.values(DRAWER_LABELS)[1].replaceAll(" ", "")
                          }
                          element={<Mailroom company={company} />}
                        />

                        {/* <Route path={"/" + Object.values(DRAWER_LABELS)[3].replaceAll(" ", "")} element={<Builder company={company}/>}/> */}
                        {/* <Route path={"/" + Object.values(DRAWER_LABELS)[4].replaceAll(" ", "")} element={<PriceSpace company={company}/>}/> */}
                        <Route
                          path={"/" + Object.values(DRAWER_FOOTER_LABELS)[0]}
                          element={<AddressBookTable company={company} />}
                        />
                        <Route
                          path="/pricelist"
                          element={
                            <Pricelists
                              company={company}
                              selectedProject={null}
                            />
                          }
                        />

                        <Route
                          path="/timeline1.1"
                          element={<ProjectTimelineGrid company={company} />}
                        />
                        {/* <Route
                          path="/timelinemMatrix"
                          element={<TimelineMatrixTable />}
                        /> */}
                        <Route
                          path="/cashflow"
                          element={<CashflowTable company={company} />}
                        />

                        <Route path="/xero-first" element={<XeroFirstAuth />} />

                        <Route path="/xero-link" element={<XeroLinkScreen />} />
                        <Route path="/xero-callback" element={<CallBack />} />
                        {/* <Route path='/xero' element={<AccountMappingApp />} /> */}

                        <Route path="*" element={<ErrorPage />} />
                        <Route path="/hello" element={<Hello />} />
                        {/*<Route*/}
                        {/*  path="/Builder/Measurements"*/}
                        {/*  element={<Measurements company={company} />}*/}
                        {/*/>*/}
                      </Routes>
                    </>
                  </div>
                </>
              ) : null // don't render app if first login screen is being displayed
            }
          </div>
          {/*<AlertDisplay/>*/}
        </>
      }
    </SocketProvider>
  );
};

export default App;
