import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { Notification } from "../../../Models/notification";
import { Link, To } from "react-router-dom";

interface props {
    notification: Notification;
    handleClose: Function;
    handleUpdateSeen: Function;
    generateLink: Function;
}

const NotificationItem: React.FC<props> = ({
    notification,
    handleClose,
    handleUpdateSeen,
    generateLink,
}) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    return (
        <MenuItem
            key={notification.notificationId + "item"}
            sx={{
                color: "#000000",
                background: "#F1F2F4",
                margin: "10px 30px",
                borderRadius: "11px",
                overflow: "hidden",
                cursor: "default",
                padding: "10px 30px 10px 16px",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Link
                to={generateLink(notification)}
                target="_blank"
                style={{ textDecoration: "none", color: "#000000" }}
                onClick={() => {
                    handleUpdateSeen(notification.notificationId);
                    handleClose();
                }}
            >
                {notification.shortText}&nbsp;
                <span style={{ color: "#5A36BE" }}>
                    {notification.longText}
                </span>
            </Link>
            {isHovered && (
                <IconButton
                    sx={{
                        width: "16px",
                        height: "16px",
                        m: 0,
                        p: 0,
                        cursor: "pointer",
                        borderRadius: 1,
                        position: "absolute",
                        right: 4,
                    }}
                    size="small"
                    onClick={() =>
                        handleUpdateSeen(notification.notificationId)
                    }
                >
                    <CloseIcon sx={{ transform: "scale(0.5)" }} />
                </IconButton>
            )}
        </MenuItem>
    );
};
export default NotificationItem;
