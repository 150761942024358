import {
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch
} from "@mui/material";
import React, {useState} from "react";
import {DropzoneArea} from "material-ui-dropzone";

interface props {
    open: boolean;
    setOpen: Function;
    overrideLines: boolean;
    setOverrideLines: Function;
    uploadBillLines: Function;
}

const BillUploadDialog = (
    {
        open,
        setOpen,
        overrideLines,
        setOverrideLines,
        uploadBillLines
    }: props
) => {
    const [file, setFile] = useState<File |undefined>(undefined);

    const handleSubmit = async () => {
        if (!file) return;
        await uploadBillLines(file);
        setFile(undefined)
    }

    const handleClose = () =>{
        setFile(undefined)
        setOpen(false)
    }

    const handleToggleOverrideLines = (event: React.ChangeEvent<HTMLInputElement>) =>{
        setOverrideLines(event.target.checked)
    }

    return (
        <Dialog

            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Upload Bill Lines</DialogTitle>
            <DialogContent>
                <DialogContentText
                    onClick={()=> window.open('https://doxleexpress.s3.ap-southeast-2.amazonaws.com/BillLineTemplate.csv')}
                    style={{cursor:'pointer'}}
                >
                    Drag a CSV into the area below or click here to download a template CSV file.
                </DialogContentText>
                {file &&
                    <DialogContentText
                        onClick={()=> setFile(undefined)}
                        style={{cursor:'pointer'}}
                    >
                        {file?.name}
                    </DialogContentText>
                }
                <div style={{display: file ? "none" : "flex"}}>
                <DropzoneArea
                    onDrop={(files: Blob[]) => {
                        console.log(files);
                        setFile(files?.[0] as File)
                    }}
                    filesLimit={1}
                    maxFileSize={5000000}
                    acceptedFiles={["text/csv"]}
                    dropzoneText="Drag CSV here"
                    showPreviews={false}
                    showPreviewsInDropzone={false}
                    showFileNamesInPreview={false}
                    showAlerts={false}
                />
                </div>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                    }}
                >
                    <FormControlLabel
                        sx={{ mt: 1 }}
                        control={
                            <Switch checked={overrideLines} onChange={handleToggleOverrideLines}/>
                        }
                        label="Override existing lines"
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={!file} onClick={handleSubmit}>Upload & Save</Button>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default BillUploadDialog;