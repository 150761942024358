import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Costcode } from "../../../Models/costcode";
import { Project, ProjectCashflow } from "../../../Models/project";
import { ScheduleItem } from "../../../Models/schedules";
import ScheduleAPI from "../../../Services/DoxleAPI/scheduleAPI";
import {
  checkEqualDateWithoutTime,
  getAllWeekDaysOfDate,
  IDateInfo,
} from "../../../utilities/FunctionUtilities";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import Loading from "../../../utilities/Lottie/Loading";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import { scheduleDummies } from "./Dummies";
import {
  checkScheduleDataType,
  getScheduleData,
} from "./ProjectTimelineCommonResources";

type Props = {
  project: ProjectCashflow;
};

const ProjectCardScheduleTable = ({ project }: Props) => {
  const unmounted = useMountStatus();
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn } = authContext;
  //##############################################################

  const [currentWeekDates, setcurrentWeekDates] = useState<IDateInfo[]>(
    getAllWeekDaysOfDate(new Date())
  );
  const [scheduleItems, setscheduleItems] = useState<
    Array<ScheduleItem | Costcode> | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchSchedules = async () => {
    setLoading(true);

    try {
      const response = await ScheduleAPI.getList({
        project: project.projectId,
        start: currentWeekDates[0].fullDay.toISOString().substring(0, 10),
        days: 7,
      });
      if (unmounted) return;
      let data: (Costcode | ScheduleItem)[] = [
        ...response.costCodes,
        ...response.scheduleItems,
      ];

      setscheduleItems(data);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchSchedules();
  }, [project]);
  const populateEmptyRows = (currentWeekDates: IDateInfo[]) => {
    let numOfRows: JSX.Element[] = [];
    for (let i = 0; i <= 30; i++)
      numOfRows.push(
        <ScheduleRowContainer className="project-timeline-schedule-table-rows">
          <ScheduleTitleCell></ScheduleTitleCell>
          <ScheduleDataRow>
            {currentWeekDates.map((dateValue, index) => {
              return (
                <ScheduleDataCell
                  key={`project#${project.projectId}#emptyRow#${dateValue}-${index}-row#${i}`}
                  style={{ width: `${100 / currentWeekDates.length}%` }}
                ></ScheduleDataCell>
              );
            })}
          </ScheduleDataRow>
        </ScheduleRowContainer>
      );
    return numOfRows;
  };
  return (
    <RootProjectCardScheduleTable>
      <ScheduleTableHeaderContainer
        className="project-timeline-schedule-table-rows"
        style={{ filter: loading ? "blur(3px)" : undefined }}
      >
        <HeaderTitleContainer>TIMELINE MATRIX</HeaderTitleContainer>
        <HeaderDaysRow>
          {currentWeekDates.map((dateValue, index) => {
            return (
              <HeaderDayCell
                key={`dateValue#${index}`}
                style={{ width: `${100 / currentWeekDates.length}%` }}
              >
                {dateValue.dayValue < 10
                  ? `0${dateValue.dayValue}`
                  : dateValue.dayValue}

                <WeekDateText>{dateValue.dateInWeek}</WeekDateText>
              </HeaderDayCell>
            );
          })}
        </HeaderDaysRow>
      </ScheduleTableHeaderContainer>

      <ScheduleTableDataContainer
        style={{
          overflow:
            loading ||
            !scheduleItems ||
            (scheduleItems && scheduleItems.length === 0)
              ? "hidden"
              : "scroll",
        }}
      >
        {scheduleItems && scheduleItems.length > 0
          ? scheduleItems.map((scheduleItem, scheduleIndex) => {
              return (
                <ScheduleRowContainer
                  key={`schedule#${
                    checkScheduleDataType(scheduleItem) === "schedule"
                      ? (scheduleItem as ScheduleItem).scheduleId
                      : (scheduleItem as Costcode).costCodeId
                  }-${scheduleIndex}`}
                  className="project-timeline-schedule-table-rows"
                >
                  <ScheduleTitleCell>
                    {getScheduleData({
                      itemChecked: scheduleItem,
                      returnData: "title",
                    })}
                  </ScheduleTitleCell>
                  {populateScheduleData(currentWeekDates, scheduleItem)}
                </ScheduleRowContainer>
              );
            })
          : null}
        {populateEmptyRows(currentWeekDates)}

        {loading ? (
          <LoadingContainer>
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                top: 0,
                left: 0,
                filter: "blur(1px)",
                zIndex: 2,
                backgroundColor: "rgba(214, 217, 223,0.2)",
                opacity: 0.85,
              }}
            ></div>
            <Loading />
          </LoadingContainer>
        ) : null}

        {scheduleItems && scheduleItems.length === 0 ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "rgba(214, 217, 223,0.2)",
            }}
          >
            NO SCHEDULES THIS WEEK
          </div>
        ) : null}
      </ScheduleTableDataContainer>
      {/* ) : (
        <ScheduleTableDataContainer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          NO SCHEDULES THIS WEEK
        </ScheduleTableDataContainer>
      )} */}
    </RootProjectCardScheduleTable>
  );
};

export default ProjectCardScheduleTable;

const RootProjectCardScheduleTable = styled.div`
  width: 100%;
  height: 68%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justfy-content: center;
  align-items: center;
`;
const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justfy-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;
const ScheduleTableHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justfy-content: center;
  align-items: center;
  border-top: 0.5px solid #cbdae2;
  border-bottom: 0.5px solid #cbdae2;
`;

const HeaderTitleContainer = styled.div`
  width: calc(35% - 10px);
  height: 100%;
  max-width: 165px;
  padding-left: 10px;
  display: flex;
  justfy-content: flex-start;
  align-items: center;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7949ff;
  text-transform: uppercase;
`;

const HeaderDaysRow = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
`;
const HeaderDayCell = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-left: 0.5px solid #cbdae2;
  font-family: "Nostromo Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: #3c374f;
`;

const WeekDateText = styled.div`
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% + 0.8em);
  font-family: "Nostromo Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #855df8;
  text-transform: uppercase;
`;

const ScheduleTableDataContainer = styled.div`
  flex-direction: column;

  width: 100%;
  flex: 1;
  position: relative;
`;

const ScheduleRowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 0.5px solid #cbdae2;
  z-index: 1;
`;
const ScheduleTitleCell = styled.div`
  width: calc(35% - 10px);
  height: 100%;
  max-width: 165px;
  padding-left: 10px;
  display: flex;
  justfy-content: flex-start;
  align-items: center;
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: #272a43;
  text-transform: capitalise;
`;
const ScheduleDataRow = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const ScheduleDataCell = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border-left: 0.5px solid #cbdae2;
`;

const DueDateDisplay = styled.div`
  background: #855df8;
  border-radius: 14px;
  height: 80%;
  width: 45%;
  z-index: 1;
`;

const ActualCompletedDateDisplay = styled.div`
  background: #ff7c04;
  border-radius: 14px;
  height: 80%;
  width: 45%;

  z-index: 2;
`;

const populateScheduleData = (
  currentWeekDates: IDateInfo[],
  scheduleItem: ScheduleItem | Costcode
): JSX.Element => {
  let scheduleDueDate: Date = new Date(
    getScheduleData({ itemChecked: scheduleItem, returnData: "dueDate" })
  );

  let scheduleCompleteDate: Date | undefined =
    checkScheduleDataType(scheduleItem) === "schedule"
      ? (scheduleItem as ScheduleItem).completed &&
        (scheduleItem as ScheduleItem).completedDate
        ? new Date((scheduleItem as ScheduleItem).completedDate as string)
        : undefined
      : (scheduleItem as Costcode).completed &&
        (scheduleItem as Costcode).completedDate
      ? new Date((scheduleItem as Costcode).completedDate as string)
      : undefined;

  return (
    <ScheduleDataRow>
      {currentWeekDates.map((dateValue, index) => {
        return (
          <ScheduleDataCell
            key={`dataRow#${index}`}
            style={{ width: `${100 / currentWeekDates.length}%` }}
          >
            {checkEqualDateWithoutTime(dateValue.fullDay, scheduleDueDate) ? (
              <DueDateDisplay />
            ) : null}

            {scheduleCompleteDate &&
            checkEqualDateWithoutTime(
              dateValue.fullDay,
              scheduleCompleteDate
            ) ? (
              <ActualCompletedDateDisplay
                style={{
                  marginLeft: checkEqualDateWithoutTime(
                    scheduleCompleteDate,
                    scheduleDueDate
                  )
                    ? "-14%"
                    : undefined,
                }}
              />
            ) : null}
          </ScheduleDataCell>
        );
      })}
    </ScheduleDataRow>
  );
};
