import { Button, InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/material";
import React, { useState } from "react";
import searchIcon from "../../../Assets/search.svg";
import AddIcon from "@mui/icons-material/Add";
import { Company } from "../../../Models/company";
import { Project } from "../../../Models/project";

interface props {
  setOpenPricelistModal: Function;
  searchString: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
}
const PricelistSearchBar: React.FC<props> = ({
  setOpenPricelistModal,
  searchString,
  setSearchString
}) => {


  const [searchBoxBackground, setSearchBoxBackground] = useState("#F9F9FF");
  const addPricelist = () => {
    setOpenPricelistModal(true);
  };


  return (
    <>

      <Box
        sx={{
          width: "100%",
          height: "55px",
          backgroundColor: searchBoxBackground,
          marginBottom: "2px",
          // textAlign: "center",
        }}
        onClick={() => setSearchBoxBackground("white")}
      >
        <TextField
          value={searchString}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSearchString(e.target.value)}
          sx={{
            width: "80%",
            background: searchBoxBackground,
            color: "#927CD2",

            ".MuiInputBase-root": {
              borderRadius: "none",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            // alignItems: "center",
          }}
          InputLabelProps={{
            style: {
              color: "#927CD2",
              fontFamily: "Roboto",
              fontSize: "9px",
              fontWeight: "100",
            },
          }}
          InputProps={{
            style: {
              color: "#927CD2",
              fontWeight: "500",
              backgroundColor: searchBoxBackground,
              width: "80%",
            },
            startAdornment: (
              <InputAdornment position="start">
                <img src={searchIcon} />
              </InputAdornment>
            ),
            // disableUnderline: true,
          }}
          placeholder="Search ..."
        />
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          size="small"
          sx={{
            marginTop: "0.6%",
            right: "4%",
            position: "absolute",
            alignItems: "center",
            textTransform: "none",
            background: "#5A36BE",
            borderRadius: "4px",
            "&:hover": {
              background: "rgba(107, 74, 201, 0.75)",
            },
          }}
          onClick={() => addPricelist()}
        >
          Add Pricelist
        </Button>
      </Box>
    </>
  );
};

export default PricelistSearchBar;
