import React from "react";
import { Notice } from "../../../Models/notice";
import "../Notices/Notices.css";
import { Grid } from "@mui/material";
import { User } from "../../../Models/user";
import moment from "moment";
interface props {
    notice: Notice;
    userList?: User[];
}

const NoticeHistory: React.FC<props> = ({ notice, userList }) => {
    const getUserName = (userId: string) => {
        if (userList) {
            const user = userList.filter((user) => user.userId === userId)[0];
            return user.firstName + " " + user.lastName;
        }
        return null;
    };

    return (
        <>
            {notice.history?.map((story, i) => (
                <Grid
                    key={"history-#" + i}
                    container
                    sx={{
                        ml: "50px",
                        mb: "2px",
                        width: "70%",
                        minHeight: "35px",
                        background: "#F1F2F5",
                        borderRadius: "5px",
                        color: "#000000",
                        fontFamily: "Roboto Mono",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                    }}
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={1}
                        sx={{ display: "flex", justifyContent: "center", p: 1 }}
                    >
                        {i + 1})
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        sx={{
                            justifyContent: "center",
                            color: "#5A36BE",
                            fontWeight: "500",
                            p: 1,
                        }}
                    >
                        {story.user && getUserName(story.user)}
                    </Grid>
                    <Grid item xs={3} sx={{ p: 1 }}>
                        {`${moment(story.timeStamp).format("L")} at ${moment(
                            story.timeStamp
                        ).format("LT")}`}
                    </Grid>
                    <Grid item xs={6} sx={{ p: 1 }}>
                        <span style={{ color: "#FF7C04" }}>
                            {story.shortText}
                        </span>{" "}
                        {story.longText}
                    </Grid>
                </Grid>
            ))}
        </>
    );
};

export default NoticeHistory;
