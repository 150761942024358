import React from 'react';
import { useEffect, useState } from 'react';
import {
    TableBody,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableFooter,
    TextField,
    Input
} from '@mui/material';
import accountingService from '../../../Services/accountingService';
import CostCodeAPI from '../../../Services/DoxleAPI/costCodeAPI';
import { Project } from "../../../Models/project";
import { Costcode } from "../../../Models/costcode";
import { User } from "../../../Models/user";
import useMountStatus  from '../../../utilities/Helper/checkMountStatus';
import {formatScheduleDate} from "../../../utilities/constants";
import Loading from '../../../utilities/Lottie/Loading';
import CostCodesDetails from '../CostCodesDetails/CostCodesDetails';
import CostCodeRow from  '../CostCodes/CostCodeRow';
import "../CostCodes/costCode.css";
import { useStyles } from '../CostCodes/costcodeStyle';
import WindowDimensions from '../../../utilities/WindowDimensions/windowDimension';
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import userAPI from "../../../Services/DoxleAPI/userAPI";
import {AddressBookCompany} from "../../../Models/addressBook";
import addressBookAPI from "../../../Services/DoxleAPI/addressBookAPI";
import { ColumnView } from "../../../Models/columnView";
import projects from "../Projects/Projects";
import ScheduleAPI from "../../../Services/DoxleAPI/scheduleAPI";
import {ScheduleItem} from "../../../Models/schedules";
import ScheduleRow from "./ScheduleRow";
import scheduleAPI from "../../../Services/DoxleAPI/scheduleAPI";
import {ReactComponent as PrevIcon} from '../../../Assets/ChevronsRight.svg';
import {ReactComponent as NextIcon} from '../../../Assets/ChevronsLeft.svg';
interface props{
    selectedProject: Project | null,
    costCodeFilter: string | null;
    editTitleMode: boolean;
    setEditTitleMode: Function;
    users: User[];
    contractors: AddressBookCompany[];
    userFilter: string[];
    columnView: ColumnView;
}

const compare = (a:Costcode|ScheduleItem, b:Costcode|ScheduleItem) => {
    if (a.startDate < b.startDate) return -1
    else if (a.startDate > b.startDate) return 1
    return 0
}

const TimeLineTable: React.FC<props> = ({
                                            selectedProject,
                                            costCodeFilter,
                                            editTitleMode,
                                            setEditTitleMode,
                                            users,
                                            contractors,
                                            userFilter,
                                            columnView
                                        }) => {
    const [baseDate, setBaseDate] = React.useState<Date>(new Date());
    const [allItems, setAllItems] = useState<Array<Costcode|ScheduleItem>>([]);
    const [selectedCostCode, setSelectedCostCode] = useState<Costcode | undefined >(undefined);
    const [tabIndex, setTabIndex] = useState<string>('4');
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [budgetTotal, setBudgetTotal] = React.useState<number>(0);
    const [orderTotal, setOrderTotal] = React.useState<number>(0);
    const [runningTotal, setRunningTotal] = React.useState<number>(0);
    const [editTitleOption, setEditTitleOption] = React.useState<string|null>(null);
    const [newScheduleDescription, setNewScheduleDescription] = useState<boolean>(false);
    const unmounted = useMountStatus();
    const [loading, setLoading] = useState<boolean>(false);
    const {height, width} = WindowDimensions();
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;
    const projectStatus:string = selectedProject ? selectedProject.projectStatus : "";
    const overrideColumnView = {
        notesIcon: false,
        fileIcon: false,
        photoIcon: false,
        checklistIcon: false,
        commentIcon: false,
        assignee: columnView.assignee,
        contractor: columnView.contractor,
        budget: false,
        order: false,
        difference: false,
        startDate: true,
        endDate: true,
        days: true,
        status: false,
    }

    interface updateTotalProps {
        budgetDelta?: number;
        orderDelta?: number;
        runningDelta?: number;
    }

    const startsThisWeek = (startDate: string) => {
        const today = new Date().getTime();
        const start = new Date(startDate).getTime();
        return ( start - today < 1000 * 60 * 60 * 24 * 7)
    };

    const getColSpan = () => {
        let colSpan = 3;
        if (overrideColumnView.assignee) colSpan++
        if (overrideColumnView.contractor) colSpan++
        return colSpan
    };

    const updateTotals = ({budgetDelta, orderDelta, runningDelta}:updateTotalProps) => {
        if (budgetDelta) { setBudgetTotal(budgetTotal + budgetDelta)}
        if (orderDelta) { setOrderTotal(orderTotal + orderDelta)}
        if (runningDelta) { setRunningTotal(runningTotal + runningDelta)}
    }

    const handleRowClick = (costcode: Costcode, tab: string) => {
        setSelectedCostCode(costcode);
        setTabIndex(tab);
        setOpenModal(true);
        if (costcode.costCodeId) sessionStorage.setItem('currentCostCodeId', costcode.costCodeId);
        if (costcode) sessionStorage.setItem('currentCostCode', JSON.stringify(costcode));
    }

    const handleClose = () => {
        setOpenModal(false);
        setSelectedCostCode(undefined);
        sessionStorage.removeItem('currentCostCode');
        sessionStorage.removeItem('currentCostCodeId');

    }

    const fetchSchedules = async() => {
        setLoading(true)
        if(selectedProject){
            try {
                const response = await ScheduleAPI.getList({project: selectedProject?.projectId, start: baseDate.toISOString().substring(0, 10)});
                if (unmounted) return;
                let data: (Costcode|ScheduleItem)[] = [...response.costCodes, ...response.scheduleItems]
                data.sort(compare);
                setAllItems(data);
            } catch (err) {
                err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
            }
        }
        setLoading(false)
    }


    const handleDeleteScheduleItem =  async (schedule:ScheduleItem) =>{
        if (!schedule.scheduleId) return;
        const result = await ScheduleAPI.remove(schedule.scheduleId)
        let newItems: (ScheduleItem|Costcode)[] = []
        if (result) allItems.forEach((item) => {
            if ( !("scheduleId" in item) || ("scheduleId" in item && item.scheduleId) !== schedule.scheduleId) newItems.push(item)
        })
        console.log("newItems", newItems)
        setAllItems([...newItems])
        setEditTitleMode(false)
        setEditTitleOption(null)
    }

    const handleAddSchedule = async (value: string) =>{
        if (!selectedProject?.projectId) return;
        const response = await scheduleAPI.add({
            scheduleId: "",
            project: selectedProject.projectId,
            title: value,
            startDate: new Date().toISOString().substring(0, 10),
            endDate: new Date().toISOString().substring(0, 10),
            completed: false,
            assignedUsers: []
        })
        if (!response) return;
        setAllItems([...allItems, response]);
        // let data: (Costcode|ScheduleItem)[] = [...allItems, response]
        // data.sort(compare);
        // setAllItems([...data]);
    }


    useEffect(() => {
        fetchSchedules();
    },[selectedProject, baseDate]);


    return(
        <>
            <CostCodesDetails
                selectedTabIndex={tabIndex}
                // setSelectedTabIndex={setTabIndex}
                selectedCostCode={selectedCostCode}
                openModal={openModal}
                closeModal={handleClose}
                selectedProjectAddress={selectedProject && selectedProject.siteAddress}
                updateBudget={()=>{}}
                updateOrders={()=>{}}
                height={height}
                width={width}
                project={selectedProject}
                incrementSelectedCostCode={()=>{}}
            />
            <div id="costcodeGroup">
                {
                    loading
                        ?
                        <>
                            <div style={{marginTop:"15%"}}>
                                <Loading/>
                            </div>
                        </>
                        :
                        // <TableContainer sx={{ maxHeight: width < 928 ? (height-57)+"px" : "95.5vh" }}>
                        // <TableContainer sx={{ height: "calc(100vh - 108px - 48px)" }}>
                        <div style={{position: "relative"}}>
                            <div id="sticker" style={{ background: "#D5D7E3", width: "10px", height: "16px", position: "absolute", zIndex: 900, top: 0, right: 0}}/>
                            <TableContainer sx={{ height: "calc(100vh - 48px - 48px)" }}>
                                <Table size="small" stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow className="tableHeadStyle" sx={{background: "#D5D7E3"}}>
                                            <TableCell style={{position: "sticky", left: 0, zIndex: 99}}  sx={useStyles.tableHeadingStyle}/>
                                            <TableCell style={{position: "sticky", left: 44, zIndex: 99, textAlign: 'left'}} sx={useStyles.tableHeadingStyle} >ACCOUNTS</TableCell>
                                            { overrideColumnView?.assignee &&
                                                <TableCell sx={useStyles.tableHeadingStyle}>ASSIGNEE</TableCell>}
                                            { overrideColumnView?.contractor &&
                                                <TableCell sx={useStyles.tableHeadingStyle}>CONTRACTOR</TableCell>}
                                            { overrideColumnView?.startDate &&
                                                <TableCell sx={useStyles.tableHeadingStyle}>START</TableCell>}
                                            { overrideColumnView?.endDate &&
                                                <TableCell sx={useStyles.tableHeadingStyle}>FINISH</TableCell>}
                                            { overrideColumnView?.days &&
                                                <TableCell sx={useStyles.tableHeadingStyle}>DAYS</TableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="tableBodyStyle">
                                        <TableRow>
                                            <TableCell
                                                onClick={() => setBaseDate((date: Date) => new Date(date.getTime() - (7 * 24 * 60 * 60 * 1000)) )}
                                                colSpan={1}
                                                style={{
                                                    backgroundColor:"white",
                                                    textAlign: "center",
                                                    color: "#5E32DE",
                                                    cursor: "pointer",
                                                    fontFamily: "Roboto Mono",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    fontSize: "15px",
                                                    lineHeight: "21px",
                                                }}
                                            >
                                                <PrevIcon/>
                                            </TableCell>
                                            <TableCell
                                                colSpan={getColSpan()}
                                                style={{
                                                    backgroundColor:"white",
                                                    textAlign: "center",
                                                    color: "#5E32DE",
                                                    fontFamily: "Roboto Mono",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    fontSize: "15px",
                                                    lineHeight: "21px",
                                                }}
                                            >
                                                {`WEEK OF ${formatScheduleDate(baseDate.toISOString())} TO ${formatScheduleDate(new Date(baseDate.getTime() + (7 * 24 * 60 * 60 * 1000)).toISOString())}`}
                                            </TableCell>
                                            <TableCell
                                                onClick={() => setBaseDate((date: Date) => new Date(date.getTime() + (7 * 24 * 60 * 60 * 1000)) )}
                                                colSpan={1}
                                                style={{
                                                    backgroundColor:"white",
                                                    textAlign: "center",
                                                    color: "#5E32DE",
                                                    cursor: "pointer",
                                                    fontFamily: "Roboto Mono",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    fontSize: "15px",
                                                    lineHeight: "21px",
                                                }}
                                            >
                                                <NextIcon/>
                                            </TableCell>
                                        </TableRow>
                                        {allItems.map((item:Costcode|ScheduleItem) => {
                                            if ("costCodeId" in item){
                                                if (!startsThisWeek(item.startDate)) return null
                                                const costcode = item as Costcode
                                                return (<CostCodeRow
                                                    key={costcode.costCodeId}
                                                    initCostCode={costcode}
                                                    onCostCodeClicked={handleRowClick}
                                                    updateTotals={updateTotals}
                                                    users={users}
                                                    editTitleMode={editTitleMode}
                                                    setEditTitleMode={setEditTitleMode}
                                                    editTitleOption={editTitleOption}
                                                    setEditTitleOption={setEditTitleOption}
                                                    handleDeleteCostcode={()=>{}}
                                                    contractors={contractors}
                                                    projectStatus={projectStatus}
                                                    openStage={true}
                                                    columnView={overrideColumnView}
                                                />)
                                            }
                                            else {
                                                if (!startsThisWeek(item.startDate)) return null
                                                const schedule = item as ScheduleItem
                                                return (
                                                    <ScheduleRow
                                                        key={schedule.scheduleId}
                                                        initScheduleItem={schedule}
                                                        users={users}
                                                        editTitleMode={editTitleMode}
                                                        setEditTitleMode={setEditTitleMode}
                                                        editTitleOption={editTitleOption}
                                                        setEditTitleOption={setEditTitleOption}
                                                        handleDeleteScheduleItem={handleDeleteScheduleItem}
                                                        contractors={contractors}
                                                        openStage={true}
                                                        columnView={overrideColumnView}
                                                    />
                                                )
                                            }})
                                        }
                                        <TableRow>
                                            <TableCell
                                                colSpan={18}
                                                style={{
                                                    backgroundColor:"white",
                                                    textAlign: "center",
                                                    color: "#5E32DE",
                                                    fontFamily: "Roboto Mono",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    fontSize: "15px",
                                                    lineHeight: "21px",
                                                }}
                                            >
                                                {`WEEK OF ${formatScheduleDate(new Date(baseDate.getTime() + (7 * 24 * 60 * 60 * 1000)).toISOString())} TO ${formatScheduleDate(new Date(baseDate.getTime() + (14 * 24 * 60 * 60 * 1000)).toISOString())}`}
                                            </TableCell>
                                        </TableRow>
                                        {allItems.map((item:Costcode|ScheduleItem) => {
                                            if ("costCodeId" in item){
                                                if (startsThisWeek(item.startDate)) return null
                                                const costcode = item as Costcode
                                                return (<CostCodeRow
                                                    key={costcode.costCodeId}
                                                    initCostCode={costcode}
                                                    onCostCodeClicked={handleRowClick}
                                                    updateTotals={updateTotals}
                                                    users={users}
                                                    editTitleMode={editTitleMode}
                                                    setEditTitleMode={setEditTitleMode}
                                                    editTitleOption={editTitleOption}
                                                    setEditTitleOption={setEditTitleOption}
                                                    handleDeleteCostcode={()=>{}}
                                                    contractors={contractors}
                                                    projectStatus={projectStatus}
                                                    openStage={true}
                                                    columnView={overrideColumnView}
                                                />)
                                            }
                                            else {
                                                if (startsThisWeek(item.startDate)) return null
                                                const schedule = item as ScheduleItem
                                                return (
                                                    <ScheduleRow
                                                        key={schedule.scheduleId}
                                                        initScheduleItem={schedule}
                                                        users={users}
                                                        editTitleMode={editTitleMode}
                                                        setEditTitleMode={setEditTitleMode}
                                                        editTitleOption={editTitleOption}
                                                        setEditTitleOption={setEditTitleOption}
                                                        handleDeleteScheduleItem={handleDeleteScheduleItem}
                                                        contractors={contractors}
                                                        openStage={true}
                                                        columnView={overrideColumnView}
                                                    />
                                                )
                                            }})
                                        }
                                        <TableRow>
                                            <TableCell
                                                colSpan={18}
                                                style={{
                                                    backgroundColor:"white",
                                                    textAlign: "center",
                                                    cursor: newScheduleDescription ? "default" : "pointer",
                                                    color: "#5E32DE",
                                                    fontFamily: "Roboto Mono",
                                                    fontStyle: "normal",
                                                    fontWeight: 400,
                                                    fontSize: "13px",
                                                    lineHeight: "16px",
                                                }}
                                                onClick={(e:any) => {if (!newScheduleDescription) setNewScheduleDescription(true)}}
                                            >
                                                { newScheduleDescription
                                                    ?
                                                    <Input size="small"
                                                           endAdornment={"Press 'Enter' to save"}
                                                           fullWidth
                                                           disableUnderline
                                                           defaultValue={""}
                                                           autoFocus
                                                           sx={{
                                                               height: "10px",
                                                               fontStyle: "normal",
                                                               fontWeight: "normal",
                                                               fontFamily: "Inter",
                                                               fontSize: "11px",
                                                               lineHeight: "15px",
                                                               position: "relative",
                                                               color: "black"
                                                           }}
                                                           onBlur={(event: any) => {
                                                               setNewScheduleDescription(false);
                                                           } }
                                                           onKeyUp={(event: any) => {
                                                               if (event.keyCode === 13) {
                                                                   handleAddSchedule(event.target.value);
                                                                   event.target.value = "";
                                                               }
                                                               else if (event.keyCode === 27) { setNewScheduleDescription(false); }
                                                           } } />
                                                    :
                                                    "Add New Schedule Item"
                                                }
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                }
            </div>
        </>
    )
}

export default TimeLineTable