import React from "react";
import { ReactComponent as TrendUp } from "../../../Assets/TrendUp.svg";
import { ReactComponent as TrendDown } from "../../../Assets/TrendDown.svg";
import "../CostCodes/costCode.css";

import {Chip} from "@mui/material";

interface props{
    value: number;
    income: boolean
}

const iconStyle = {
    position: "relative",
    top: "2px",
    paddingLeft: "3px"
}

const BudgetChip: React.FC<props> = ({value, income}) => (
    <span
        style={{
            marginLeft: "15px",
            whiteSpace: "nowrap",
            padding: "1px 4px 2px 4px",
            maxHeight: "15px",
            borderRadius: "4px",
            backgroundColor: value === 0 ? undefined : (!income && value < 0) || (income && value > 0) ? "#aee5cb" : "#ff9c9c",
            fontFamily: "IBM Plex Mono",
            fontSize: "10px",
            fontWeight: 600,
            color: value === 0 ? undefined : (!income && value < 0) || (income && value > 0) ? "#217A50" : "#BD2B2B"
        }}
    >
        {`${(value > 0) ? "+" : ""}${Math.round(value)}`}
        {
            value === 0 ? undefined : (!income && value < 0) || (income && value > 0)
                ?
                <TrendUp style={{
                    position: "relative",
                    top: "3px",
                    paddingRight: "2px",
                    transform: "scale(1, -1)"
                }} />
                :
                <TrendDown style={{
                    position: "relative",
                    top: "3px",
                    paddingRight: "2px",
                }} />
        }
    </span>
)

export default BudgetChip

//        sx={{
//             backgroundColor: value === 0 ? undefined : value > 0 ? "#aee5cb" : "#ff9c9c",
//             fontFamily: "IBM Plex Mono",
//             fontSize: "10px",
//             color: value === 0 ? undefined : value > 0 ? "#217A50" : "#BD2B2B"
//         }}
//         icon={ value === 0 ? undefined : value > 0 ? <TrendUp /> : <TrendDown />}
//         label={`${value > 0 ? "+" : ""}${value}`}