export const useStyles = {
    tableHeadingStyle: {
        height: "15px",
        fontFamily: 'Roboto Mono',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: "7px",
        lineHeight: "9px",
        color: "#5A36BE",
        textAlign: "center",
        padding: "0px",
        margin: "0px",
        border: "none",
    },
    tableHeadingDropdownFieldStyle: {
        borderBottom: "none", 
        paddingBottom: "0px",
        height: "2px"
    },
    tableHeadingFilterStyle: {
        borderBottom: "none", 
        paddingBottom: "0px",
        paddingTop: "2px",
        height: "2px",
        paddingRight: "0px"
    },
    tableHeadingDescriptionFieldStyle: {
        height: "15px",
        fontFamily: 'Roboto Mono',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: "7px",
        lineHeight: "9px",
        color: "#5A36BE",
        padding: "0px",
        margin: "0px",
        paddingLeft: "20px",
        border: "none",
    },
    tableBodyStyle: {
        height: "33px", 
        fontStyle: "normal",
        fontFamily:"Roboto",
        fontWeight: "normal",
        fontSize: "11px",
        color: "#0D0D0D",
        padding: "0px",
        textAlign: "center",
        position: "relative",
        width: "88px",
        overflow: "hidden",
    },
    tableBodyTitleFieldStyle: {
        minWidth: "150px",
        height: "33px", 
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '11px',
        color: "#0D0D0D",
        padding: "0px",
        paddingLeft: "5px",
        // borderRight: "0.3px solid rgba(224, 224, 224, 1)",
        position: "relative",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    tableCheckboxStyle: {
        height: "33px",
        fontStyle: "normal",
        fontFamily:"Roboto",
        fontWeight: "normal",
        fontSize: "11px",
        color: "#0D0D0D",
        padding: "0px",
        textAlign: "center",
        position: "relative",
        width: "30px"
    },
    noticeTabs: {
        fontFamily: 'Roboto',
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "11px",
        paddingBottom: "0px",
        paddingTop:"0px",
        color: "#96A2BE",
        textTransform: "none"
    }
};