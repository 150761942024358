import React, { useState, useEffect } from "react";
import { Company } from "../../../Models/company";
import AddressBookAPI from "../../../Services/DoxleAPI/addressBookAPI";
import {
  TableBody,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  responsiveFontSizes,
  Button,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { useStyles } from "./AddressBookStyle";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import { AddressBookCompany } from "../../../Models/addressBook";
import "./AddressBook.css";
import AddressBookDetails from "./AddressBookDetails";
import AddressBookSearch from "./AddressBookSearch";
import AddIcon from "@mui/icons-material/Add";
import { Address } from "cluster";

// import { DataGridPro } from '@mui/x-data-grid-pro';
// import { useDemoData } from '@mui/x-data-grid-generator';

interface props {
  company: Company | undefined;
}

const AddressBookTable: React.FC<props> = ({ company }) => {
  // console.log("props");
  const [addressBookCompanies, setAddressBookCompanies] = useState<
    Array<AddressBookCompany>
  >([]);
  const [backupCompanies, setBackupCompanies] = useState<
    Array<AddressBookCompany>
  >([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedAbCompany, setSelectedAbCompany] =
    useState<AddressBookCompany>();
  const unmounted = useMountStatus();
  const { height, width } = WindowDimensions();

  const handleAddButton = () => {
    setOpenModal(true);
    setSelectedAbCompany(undefined);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedAbCompany(undefined);
    fetchAddressBookCompanies();
  };

  // FUNCTION TO GET ALL THE ADDRESS BOOK COMPANIES
  const fetchAddressBookCompanies = async () => {
    try {
      console.log("FETCHING ADDRESSBOOK COMPANIES");
      const response = (await AddressBookAPI.getList(
        company?.companyId
      )) as AddressBookCompany[];
      // console.log("LENGTH  RESULT: ", response.length);
      // console.log("AddressBook API Response RESULT: ", response);
      if (unmounted) return;
      setAddressBookCompanies([...response]);
      setBackupCompanies([...response]);

      console.log("Length: " + addressBookCompanies.length);
    } catch (err) {
      console.log("ERROR!!!  = GETTING LIST OF ADRESS BOOK COMPANIES" + err);
    }
  };

  useEffect(() => {
    fetchAddressBookCompanies();
  }, []);

  const handleSearchOnChange = (event: any) => {
    const searchText = event.target.value;
    // console.log("Handling Search Change: " + event.target.value);
    //* WE BACK UP ALL THE COMPANIES

    // console.log(addressBookCompanies);
    // console.log("Now Backup");
    // console.log(backupCompanies);
    // console.log("Length-SearchText " + searchText.length);
    //* THERE IS A CHAR (OR) STRING SO WE NEED TO MATCH
    if (searchText.length !== 0) {
      let filteredCompanies = addressBookCompanies.filter((company) => {
        return company.companyName
          .toUpperCase()
          .includes(searchText.toUpperCase());
      });
      // console.log("Filtered Search Length: " + filteredCompanies.length);

      //* SET THE NEW SEARCHED ARRAY

      setAddressBookCompanies([...filteredCompanies]);
    }
    //* NO CHARS IN THE TEXTFIELD - SO DISPLAY ALL THE COMPANIES
    else {
      // console.log("Setting Backup Companies");
      // console.log(backupCompanies);
      setAddressBookCompanies([...backupCompanies]);
    }
  };

  //!mui table
  return (
    <div style={{marginLeft: "4%", marginRight: "2%"}}>
      <AddressBookDetails
        key={selectedAbCompany?.abCompanyId}
        company={company}
        openModal={openModal}
        closeModal={handleCloseModal}
        width={width}
        selectedAbCompany={selectedAbCompany}
      />

      <div style={{ marginTop: "1%" }}>
        {/* ADD NEW CONTACT BUTTON */}
        <AddressBookSearch
          searchText={handleSearchOnChange}
          addButton={handleAddButton}
        />
        {/* <Button
          variant="contained"
          startIcon={<AddIcon />}
          size="small"
          sx={{
            position: "absolute",
            top: "9.5%",
            right: "3%",
            // zIndex: 99,
            textTransform: "none",
            background: "#5A36BE",
            borderRadius: "4px",
            "&:hover": {
              background: "rgba(107, 74, 201, 0.75)",
            },
          }}
          onClick={handleAddButton}
        >
          Add New Contact
        </Button> */}
      </div>

      <div style={{ overflow: "auto" }}>
        <TableContainer
          sx={{
            // height: "calc(100vh-100px)",
            maxHeight: "80rem",
            borderBottom: "1px solid rgba(224,224,224,1)",
            overflow: "auto",
          }}
          component={Paper}
        >
          <Table
            sx={{ tableLayout: "fixed" }}
            size="small"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead
              sx={{
                "& .MuiTableCell-root": {
                  borderBottom: "2px solid rgba(224, 224, 224, 1)",
                  fontFamily: "Roboto",
                  fontStyle: "bold",
                  fontWeight: "500",
                  fontSize: "14px",
                  color: "#754EE0",
                  height: "35px",
                  paddingTop: "3px",
                  paddingBottom: "0px",
                  background: "white",
                },
              }}
            >
              <TableRow>
                <TableCell>Contacts</TableCell>

                <TableCell align="center">Phone</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">ABN</TableCell>
                <TableCell align="center">Address</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {addressBookCompanies.length === 0 ? (
                <Typography
                  noWrap
                  gutterBottom
                  align="left"
                  color="secondary"
                  variant="subtitle1"
                >
                  No matching company
                </Typography>
              ) : (
                addressBookCompanies
                  ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
                  .map((row: AddressBookCompany, index) => (
                    <TableRow
                      key={row.abCompanyId}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      onClick={() => {
                        setOpenModal(true);
                        setSelectedAbCompany(row);
                      }}
                    >
                      <TableCell
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                          fontStyle: "bold",
                          fontWeight: "600",
                          color: "#754EE0",
                        }}
                        component="th"
                        scope="row"
                      >
                        {row.companyName}
                      </TableCell>
                      <TableCell align="center">{row.phone}</TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">{row.companyAbn}</TableCell>
                      <TableCell align="center">{row.addressL1}</TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* </div> */}
    </div>
  );
};

export default AddressBookTable;
