import React, {createContext, useContext, useEffect, useState} from "react"
import axios from "axios";
import {baseAddress} from "../../settings";
import CookieService from "../../Services/cookieService";
import {User} from "../../Models/user"

export interface authContextInterface {
    loginWithDetails: Function;
    setLoggedIn: Function;
    firstAuth: boolean;
    loggedIn: boolean;
    user: User | undefined;
    exchangeRefreshToken: Function;
    checkAuth: Function;
}

const getInitialUser = () => {
    try{
        console.log("GET USER")
        const userStr = localStorage.getItem("currentUser") || "";
        const user: User | undefined = userStr ? JSON.parse(userStr) : undefined;
        console.log("USER GET:",user)
        return user
    } catch (e) {
        console.log(e)
        return undefined
    }
}

const AuthContext = createContext({});

const  AuthProvider = (children: any) => {
    const [loggedIn, setLoggedIn] = useState<boolean>(CookieService.get('access_token') !== undefined);
    const [refreshToken, setRefreshToken] = useState<string | undefined>(CookieService.get('refresh_token') || undefined);
    const [firstAuth, setFirstAuth] = useState<boolean>(CookieService.get('access_token') === undefined);
    const [user, setUser] = useState<User |undefined>(getInitialUser());
    let timer:NodeJS.Timeout | null = null;

    useEffect(() => {
        if (!user){
            setFirstAuth(true)
            setLoggedIn(false)
        }
    }, []);

    useEffect(() => {
        if (timer !== null) { clearTimeout(timer) }
        if (refreshToken) {
            timer = setTimeout(() => {
                exchangeRefreshToken();
            }, 900000);
        }
    }, [refreshToken]);

    useEffect( () => {
        if (!loggedIn) {
            if (refreshToken) exchangeRefreshToken();
        }
        else if( user === undefined ){
            setUser(JSON.parse(localStorage.getItem("currentUser") || "{}"));
        }
    }, [loggedIn]);

    interface LoginDetails {
        user: string,
        password: string
    }

    const loginWithDetails = async (login: LoginDetails) => {
        try {
            const requestBody: any = {
                "grant_type": "password",
                "client_id": "b5Y0MqZdwi3NMdaEcJSJWIPSGBm3hr0NTMQT4RUK",
                "client_secret": "TDOIue9kSmQUXV9JVe4cUHWcRnN7CZdflDGuNir4khFrhwI43pBpYbn3ZM4w2xfY4TK91QApEGT91oeDcz8UVOjYIOVVAKsb2KgzOwYTLwE3AzZdeI5Jh6RnOijeb3tp",
                "username": login.user,
                "password": login.password
            };
            let formBody: string[] = []
            for (let property in requestBody){
                let encodedKey = encodeURIComponent(property);
                let encodedValue = encodeURIComponent(requestBody[property]);
                formBody.push(encodedKey + "=" + encodedValue);
            }
            const formBodyStr: string = formBody.join("&");
            const response = await axios.post(baseAddress+"/token/", formBodyStr, {
                headers: { 'Content-Type': "application/x-www-form-urlencoded", 'Accept': '*/*' }
            });
            if (response && response.data && response.data.access_token && response.data.refresh_token){
                let date = new Date();
                date.setTime(date.getTime() + 3600000)
                const options = { path: '/', expires: date };
                const refresh_options = { path: '/' };
                CookieService.set('access_token', response.data.access_token, options);
                CookieService.set('refresh_token', response.data.refresh_token, refresh_options);
                setRefreshToken(response?.data?.refresh_token)
                if (response?.data?.user as User) {
                    setUser(response?.data?.user as User)
                    localStorage.setItem("currentUser", JSON.stringify(response?.data?.user))
                }
                setLoggedIn(true)
                setFirstAuth(false)
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('ERROR', error);
            setLoggedIn(false)
            return false;
        }
    }

    const checkAuth = async () => {
        if (CookieService.get('access_token')) return true;
        if (refreshToken || CookieService.get('refresh_token')) return await exchangeRefreshToken();
        return false;
    }

    const exchangeRefreshToken = async () => {
        if (!refreshToken && CookieService.get('refresh_token')) setRefreshToken(CookieService.get('refresh_token'))
        if (refreshToken) {
            try{
                const requestBody: any = {
                    "grant_type": "refresh_token",
                    "client_id": "b5Y0MqZdwi3NMdaEcJSJWIPSGBm3hr0NTMQT4RUK",
                    "client_secret": "TDOIue9kSmQUXV9JVe4cUHWcRnN7CZdflDGuNir4khFrhwI43pBpYbn3ZM4w2xfY4TK91QApEGT91oeDcz8UVOjYIOVVAKsb2KgzOwYTLwE3AzZdeI5Jh6RnOijeb3tp",
                    "refresh_token": refreshToken,
                };
                let formBody: string[] = []
                for (let property in requestBody){
                    let encodedKey = encodeURIComponent(property);
                    let encodedValue = encodeURIComponent(requestBody[property]);
                    formBody.push(encodedKey + "=" + encodedValue);
                }
                const formBodyStr: string = formBody.join("&");
                const response = await axios.post(baseAddress+"/o/token/", formBodyStr, {
                    headers: { 'Content-Type': "application/x-www-form-urlencoded", 'Accept': '*/*' }
                });
                if (response && response.data && response.data.access_token && response.data.refresh_token){
                    let date = new Date();
                    date.setTime(date.getTime() + 3600000)
                    const options = { path: '/', expires: date };
                    const refresh_options = { path: '/' };
                    CookieService.set('access_token', response.data.access_token, options);
                    CookieService.set('refresh_token', response.data.refresh_token, refresh_options);
                    setRefreshToken(response?.data?.refresh_token)
                    setLoggedIn(true)
                    setFirstAuth(false)
                    if (response?.data?.user as User) {
                        setUser(response?.data?.user as User)
                        localStorage.setItem("currentUser", JSON.stringify(response?.data?.user))
                    }
                    return true
                } else {
                    throw response;
                }
            } catch (error) {
                console.error('ERROR', error);
                setRefreshToken(undefined)
                return false
            }
        } else {
            return false
        }
    }

    const authContextValue: authContextInterface = {
        loginWithDetails,
        setLoggedIn,
        firstAuth,
        loggedIn,
        user,
        exchangeRefreshToken,
        checkAuth
    };

    return(
        <AuthContext.Provider value={authContextValue} {...children} />
    )
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }