import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
    headerTop: {

    },
    heading: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#000000',
    },
    subHeading: {
        fontFamily: 'Roboto',
        fontStyle: 'light',
        fontWeight: '200',
        fontSize: '14px',
        lineHeight: '19px',
        color: '#b0b0b0',
    },
    closeIcon: {
        lineHeight: '22px !important',
        color: '#black !important',
    },
    electrical: {
        '&MuiTypography-root':{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19px',
        },
        padding: '5px 25px',
        color: '#0044CC',
        width: "50%",
    },
    dollar: {
        '&MuiTypography-root':{
            fontFamily: 'Inter',
            lineHeight: '19px',
            fontSize: '16px',
            fontWeight: 500,
        },
        fontStyle: 'normal',
        color: '#0044CC',
        position: "relative",
        top: "10px",
        // right: "65px",
        right: "110px",
        width: "50%",
    textAlign: "right",
    },
    date: {
        borderRadius: '3px',
        background: '#F5F6F7',
        width: '240px',
        fontStyle: 'normal',
        color: '#96A2BE',   
    },
    tabBtnHeader: {
        '& button': {
            fontFamily: 'Roboto Mono',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: "11px",
            lineHeight: "15px",
            color: "#000000",
            opacity: 1,
            textTransform: 'none',
            justifyContent: 'flex-start',
            minHeight: '32px',
            paddingLeft: '20px',
            borderBottom: "1px solid #E6E6E6"
        },
        '& button.Mui-selected': {
            color: "#5A36BE",
            background: "#E2E5ED"
        },
        '& .MuiTabs-indicator' : {
            display: 'none'
        }
    },
    addButton: {
        '&MuiButton-root':{
            fontSize: "12px",
            fontFamily: 'Inter',
            fontStyle: "normal",
            lineHeight: "15px",
            fontWeight: 600,
            color: "#0044CC !important",
            // position: "relative",
            borderRadius: "7px",    

        },
        background: "#ECECF4 !important",
        // width: "98px",
        height: "26px",
        right: 0,
        zIndex: 999,
        color: "#0044cc",
    },
    headrRightSec:{
        position:"relative",
        top: "5px",
        textAlign: "right",
        right: "5px",
    }
}));