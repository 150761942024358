import React, { useCallback, useEffect, useRef, useState } from "react";
import { Typography, Grid, Box, TextField, Stack } from "@mui/material";
import { authContextInterface, useAuth } from "../../../Providers/AuthProvider";
import moment from "moment";
import NoticeItem from "../NoticeItem";
import NoticeAPI from "../../../../Services/DoxleAPI/noticeAPI";
import { Company } from "../../../../Models/company";
import {Notice, NoticeCategory} from "../../../../Models/notice";
import { User } from "../../../../Models/user";
import {
  convertUTCDate,
  formatDate,
  getStartAndEndDateCurrentWeek,
} from "../../../../utilities/FunctionUtilities";
import { ReactComponent as MaximizeIcon } from "../../../../Assets/MaximizeNoticeItem.svg";
import { ReactComponent as AddNoticeIcon } from "../../../../Assets/addNoticeBell.svg";
import { ReactComponent as PreviousIcon } from "../../../../Assets/previousPurple.svg";
import { ReactComponent as NextIcon } from "../../../../Assets/nextPurple.svg";
import { ReactComponent as PreviousWeekIcon } from "../../../../Assets/previousWeekPurple.svg";
import { ReactComponent as NextWeekIcon } from "../../../../Assets/nextWeekPurple.svg";
import { ReactComponent as PreviousPageIcon } from "../../../../Assets/previousPagePurple.svg";
import { ReactComponent as NextPageIcon } from "../../../../Assets/nextPagePurple.svg";
import {
  IUserWithTask,
  convertDateAndTime,
  TimeRow,
  getTimeRangeNotice,
  distributeNoticeForUsers,
  getDateRangeNotice,
  ITimeRange,
  ITimeRow,
  TDailySelect,
  getWeekDates,
  populateEmptyRow,
} from "../CommonResources";
import TeamViewNoticeItem, {
  convertColorValueByNoticeStatus,
} from "./TeamViewNoticeItem";
import { AnimatePresence, motion } from "framer-motion";
import { filter, initial } from "lodash";
import Loading from "../../../../utilities/Lottie/Loading";
import TeamViewNoticeDataRow from "./TeamViewNoticeDataRow";
import TeamViewAddNoticeRow from "./TeamViewAddNoticeRow";
import { previousDay } from "date-fns";

interface props {
  notice?: any;
  dailySelect: TDailySelect;
  viewType: "daily" | "weekly";
  company: Company | undefined;
  userList: User[];
  categorySelect: NoticeCategory | null;
  setCurrentSelectedUser: Function;
}

const TeamView: React.FC<props> = ({
  notice,
  dailySelect,
  viewType,
  company,
  userList,
  categorySelect,
  setCurrentSelectedUser,
}) => {
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, user } = authContext;

  const [loading, setloading] = useState<boolean>(false);
  const [userWithNoticeList, setUserWithNoticeList] = useState<
    IUserWithTask | undefined
  >(undefined);
  const [currentPage, setCurrentPage] = useState<"page1" | "page2">("page1");

  const [today, settoday] = useState<Date>(new Date());
  const weekDates = getWeekDates(today);
  const weekdays = moment.weekdays();

  //====================CONTROL POPOVER#################
  const [currentMouseOverElement, setCurrentMouseOverElement] =
    useState<HTMLElement | null>(null);
  const handlePopoverOpen = (rowFocused: IUserWithTask) => {
    setUserWithNoticeList(rowFocused);
  };
  const handlePopoverClose = () => {
    setUserWithNoticeList(undefined);
  };

  //############FETCHING NOTICES BASED ON VIEW#########
  const [notices, setnotices] = useState<Notice[]>([]);

  const fetchNoticesWithDate = async () => {
    setloading(true);

    try {
      if (dailySelect === "Daily View") {
        setnotices([]);
        let result: any = await NoticeAPI.getListTimeLineWithDate({
          companyId: company?.companyId as string,
          startDate: formatDate(today, "yyyy-MM-dd"),
          endDate: formatDate(today, "yyyy-MM-dd"),
        });
        //console.log('RESULT IN FRONTEND:', result)
        setnotices([...result.items]);
      } else {
        setnotices([]);
        const startAndEndDateCurrentWeek: { start: string; end: string } =
          getStartAndEndDateCurrentWeek(today, "string") as {
            start: string;
            end: string;
          };
        console.log("TIME RANGE: ", startAndEndDateCurrentWeek);
        let result: any = await NoticeAPI.getListTimeLineWithDate({
          companyId: company?.companyId as string,
          startDate: formatDate(
            new Date(startAndEndDateCurrentWeek.start),
            "yyyy-MM-dd"
          ),
          endDate: formatDate(
            new Date(startAndEndDateCurrentWeek.end),
            "yyyy-MM-dd"
          ),
        });
        //console.log('RESULT IN FRONTEND:', result)
        setnotices([...result.items]);
      }
      //console.log("ALL NOTICES:", result.items);
    } catch (error) {
      console.log("ERRoR FETCHING NOTICES");
    }
    setloading(false);
  };

  useEffect(() => {
    fetchNoticesWithDate();
  }, [company, dailySelect, today]);

  useEffect(() => {
    console.log("Notice:", notices);
  }, [notices]);

  //###########HANDLE ADD NOTICE ##################
  interface ICurrentFocusCell {
    userId: string;
    timeValue: number;
  }

  //add notice api call
  const handleAddNoticeAPI = async (
    description: string,
    startTimeValue: number,
    endTimeValue: number
  ) => {
    if (company && currentHoverUser)
      try {
        // console.log(user.firstName)
        // console.log('TIME:', timeValue)
        const newNotice = {
          noticeId: "",
          company: company.companyId,
          description: description,
          creator: user?.userId,
          assignedUsers: [currentHoverUser.userId as string],
          pinned: false,
          status: "D",
          timeStamp: "",
          category: categorySelect?.categoryTitle ? categorySelect.categoryTitle : "",
          categoryId: categorySelect?.noticeCategoryId ? categorySelect.noticeCategoryId : "",
          isArchived: false,
          isComplete: false,
          isPrivate: false,
          isFollowup: false,
          startDate: convertDateAndTime(
            startTimeValue,
            `${dailySelect === "Daily View" ? "hour" : "date"}`
          ),
          dueDate: convertDateAndTime(
            endTimeValue,
            `${dailySelect === "Daily View" ? "hour" : "date"}`
          ),
        };
        const response = await NoticeAPI.add(newNotice);
        if (response) {
          console.log("ADDED NOTICE:", response);
          setnotices([...notices, response]);
        }
      } catch (error) {}
  };

  //####### SIZES TO CONTROL DRAGGING########
  //size of whole row
  const noticeRowSizeRef = useRef<HTMLDivElement | null>(null);

  //start pos of whole row
  const noticeRowStartPosX: number | undefined = noticeRowSizeRef.current
    ? noticeRowSizeRef.current.getBoundingClientRect().left
    : undefined;

  //size of a cell
  let widthSizeOfACell: number | undefined = noticeRowSizeRef.current
    ? noticeRowSizeRef.current.getBoundingClientRect().width /
      TimeRow()[currentPage].length
    : undefined;

  //get the current pos of mouse to control animation
  const [currentMousePosX, setcurrentMousePosX] = useState<number | undefined>(
    undefined
  );

  //get current focus row
  const [currentUserRow, setCurrentUserRow] = useState<User | undefined>(
    undefined
  );

  const [currentDraggedNotice, setCurrentDraggedNotice] = useState<
    Notice | undefined
  >(undefined);

  //######## HANDLE ADD NOTICE BEHAVIOURS #########
  const [currentHoverUser, setcurrentHoverUser] = useState<User | undefined>(
    undefined
  );

  const [addedTimeValue, setaddedTimeValue] = useState<ITimeRange | undefined>(
    undefined
  );
  const [onAddNoticeMode, setonAddNoticeMode] = useState<boolean>(false);
  const handleHoverOnUserRow = (user: User) => {
    setcurrentHoverUser(user);
  };

  const handleMouseLeaveUserRow = () => {
    if (!onAddNoticeMode) setcurrentHoverUser(undefined);
  };
  //################################################

  //####################HANDLE CHANGE DATE FOR DAILY VIEW#################

  const handleSwitchDate = (action: "next" | "previous") => {
    if (action === "previous") {
      let previousDate = new Date(today);
      previousDate.setDate(today.getDate() - 1);
      settoday(previousDate);
    } else {
      let nextDate = new Date(today);
      nextDate.setDate(today.getDate() + 1);
      settoday(nextDate);
    }
  };

  const handleSwitchWeek = (action: "next" | "previous") => {
    if (action === "previous") {
      let previousDate = new Date(today);
      previousDate.setDate(today.getDate() - 7);
      settoday(previousDate);
    } else {
      let nextDate = new Date(today);
      nextDate.setDate(today.getDate() + 7);
      settoday(nextDate);
    }
  };

  //reset to today date to get correct date every time change mode view
  useEffect(() => {
    settoday(new Date());
  }, [dailySelect]);
  //######################################################################

  //##########SHOW MENU Time List################
  const [showSideTimeList, setshowSideTimeList] = useState<boolean>(false);

  const [timeValueSelectedFromSideList, settimeValueSelectedFromSideList] =
    useState<number | undefined>(undefined);

  useEffect(() => {
    console.log("TIME FROM SIDE LIST:", timeValueSelectedFromSideList);
  }, [timeValueSelectedFromSideList]);

  const handleSwitchPage = (action: "next" | "previous") => {
    if (action === "next") setCurrentPage("page2");
    else setCurrentPage("page1");
  };
  return dailySelect === "Daily View" ? (
    // WORKING BRANCH = WEEKLY VIEW IN OUTDATED RETURN BELOW WITH FEBS VIEW

    <Box
      sx={{
        backgroundColor: "white",
        flex: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {/*Header Section */}
      <Box
        className="timetable-header-teamview"
        sx={{ filter: loading ? `blur(3px)` : undefined, zIndex: 0 }}
      >
        <Box className={"sidebar-timetable-header-teamview"}>
          <Typography fontWeight={"bold"}>Byson Team</Typography>
        </Box>

        <Box
          className={"row-timetable-header-teamview"}
          sx={{ justifyContent: "center" }}
          ref={noticeRowSizeRef}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <motion.div
              whileHover={{ zoom: 1.2 }}
              whileTap={{ opacity: 0.6, x: -5 }}
              onClick={() => handleSwitchDate("previous")}
            >
              <PreviousIcon />
            </motion.div>

            <Typography
              className="today-date-header-style"
              sx={{ padding: "0 40px 0 40px" }}
            >
              {today.getDate()}{" "}
              <span style={{ color: "#AC8FFF" }}>
                {weekdays[today.getDay()]}
              </span>
            </Typography>

            <motion.div
              whileHover={{ zoom: 1.2 }}
              whileTap={{ opacity: 0.6, x: 5 }}
              onClick={() => handleSwitchDate("next")}
            >
              <NextIcon />
            </motion.div>
          </Box>
          {noticeRowSizeRef.current ? (
            <Box className="time-header-row" sx={{ position: "relative" }}>
              {TimeRow(
                noticeRowSizeRef.current.getBoundingClientRect().width,
                currentMousePosX,
                noticeRowSizeRef.current.getBoundingClientRect().left
              )[currentPage].map((data, index) => {
                return (
                  <motion.div
                    animate={
                      noticeRowSizeRef.current &&
                      currentMousePosX &&
                      data.axisPos &&
                      currentMousePosX >= data.axisPos.start &&
                      currentMousePosX < data.axisPos.end
                        ? { scale: 1.4, filter: "brightness(1.7)" }
                        : addedTimeValue &&
                          (data.timeValue === addedTimeValue.start ||
                            data.timeValue === addedTimeValue.end)
                        ? { scale: 1.4, filter: "brightness(1.7)", y: -5 }
                        : { scale: 1, filter: "brightness(1)", y: 0 }
                    }
                    className="time-header-text-style"
                    style={{
                      width: `${(1 / TimeRow()[currentPage].length) * 100}%`,
                      opacity: addedTimeValue
                        ? data.timeValue !== addedTimeValue.start &&
                          data.timeValue !== addedTimeValue.end
                          ? 0
                          : 1
                        : undefined,
                    }}
                  >
                    {data.timeValue <= 12
                      ? data.timeValue
                      : data.timeValue - 12}{" "}
                    {data.timeValue >= 12 ? "Pm" : "Am"}
                  </motion.div>
                );
              })}

              {/*to page 2 */}
              {currentPage === "page1" ? (
                <motion.div
                  whileHover={{ x: [0, 5, 0] }}
                  whileTap={{ y: [0, -5, 0] }}
                  onClick={() => handleSwitchPage("next")}
                  style={{
                    height: "calc(100% - 4px)",
                    width: "20px",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "4px",
                  }}
                >
                  <NextPageIcon />
                </motion.div>
              ) : null}

              {/*to page 1 icon */}
              {currentPage === "page2" ? (
                <motion.div
                  whileHover={{ x: [0, -5, 0] }}
                  whileTap={{ y: [0, -5, 0] }}
                  onClick={() => handleSwitchPage("previous")}
                  style={{
                    height: "calc(100% - 4px)",
                    width: "20px",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "4px",
                  }}
                >
                  <PreviousPageIcon />
                </motion.div>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Box>

      {/*Data View Section */}
      {loading ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              position: "absolute",
              filter: `blur(2px)`,
              top: 0,
              left: 0,
              zIndex: 0,
            }}
          ></Box>
          <Box className="loading-screen-mask-container">
            <Loading />
          </Box>
        </Box>
      ) : (
        <Box
          className="timetable-data-teamview"
          sx={{ zIndex: showSideTimeList ? 0 : undefined }}
        >
          {distributeNoticeForUsers(userList, notices, categorySelect).map(
            (data, i) => (
              <Box
                className="timetable-data-row-teamview"
                key={`${data.user.userId}` + `#` + `${i}`}
                style={{
                  position: "relative",
                  filter: currentUserRow
                    ? currentUserRow.userId !== data.user.userId
                      ? "blur(3px) brightness(0.6)"
                      : undefined
                    : currentHoverUser &&
                      onAddNoticeMode &&
                      currentHoverUser.userId !== data.user.userId
                    ? "blur(5px) brightness(0.8)"
                    : undefined,
                  borderTop: currentUserRow
                    ? currentUserRow.userId === data.user.userId
                      ? "1px solid #cbdae2"
                      : undefined
                    : undefined,
                  display: "flex",
                  pointerEvents:
                    currentHoverUser &&
                    onAddNoticeMode &&
                    currentHoverUser.userId !== data.user.userId
                      ? "none"
                      : undefined,
                }}
                onMouseOver={() => {
                  handlePopoverOpen(data);
                }}
                onMouseLeave={handlePopoverClose}
              >
                <Box
                  className={"sidebar-timetable-data-teamview"}
                  sx={{
                    position: "relative",
                    border:
                      (currentHoverUser &&
                        onAddNoticeMode &&
                        currentHoverUser.userId === data.user.userId) ||
                      (currentDraggedNotice &&
                        currentUserRow &&
                        currentUserRow.userId === data.user.userId)
                        ? "0.5px solid #7949FF"
                        : undefined,

                    color:
                      (currentHoverUser &&
                        onAddNoticeMode &&
                        currentHoverUser.userId === data.user.userId) ||
                      (currentDraggedNotice &&
                        currentUserRow &&
                        currentUserRow.userId === data.user.userId)
                        ? " #7949FF"
                        : undefined,
                  }}
                  onMouseEnter={() => handleHoverOnUserRow(data.user)}
                  onMouseLeave={() => handleMouseLeaveUserRow()}
                >
                  <span
                    style={{
                      display: "flex",
                      width: "calc(100% - 40px)",
                      height: "100%",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      zIndex: 1,
                      color:
                        currentHoverUser &&
                        currentHoverUser.userId === data.user.userId &&
                        !onAddNoticeMode &&
                        !currentDraggedNotice &&
                        !currentUserRow
                          ? "white "
                          : undefined,
                    }}
                  >
                    {i + 1}. {data.user.firstName} {data.user.lastName}
                  </span>

                  {/** Addd notice btn overlay */}
                  <AnimatePresence>
                    {currentHoverUser &&
                    currentHoverUser.userId === data.user.userId &&
                    !onAddNoticeMode &&
                    !currentDraggedNotice &&
                    !currentUserRow ? (
                      <motion.div
                        whileTap={{ opacity: 0.4 }}
                        initial={{ width: "0%", opacity: 0.6 }}
                        animate={{ width: "calc(100% - 10px)" }}
                        exit={{ width: 0 }}
                        transition={{
                          width: { duration: 0.2, ease: "easeInOut" },
                          opacity: { duration: 0.2 },
                        }}
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          backgroundColor: "#7949FF",
                          position: "absolute",
                          top: 0,
                          right: 0,
                          zIndex: 0,
                          paddingRight: "10px",
                        }}
                      >
                        <AddNoticeIcon
                          onClick={() => setonAddNoticeMode(true)}
                        />
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                </Box>
                {noticeRowSizeRef.current !== null ? (
                  currentHoverUser &&
                  onAddNoticeMode &&
                  currentHoverUser.userId === data.user.userId ? (
                    <TeamViewAddNoticeRow
                      selectedUser={currentHoverUser}
                      rowSize={
                        noticeRowSizeRef.current.getBoundingClientRect().width
                      }
                      boundLeft={
                        noticeRowSizeRef.current.getBoundingClientRect().left
                      }
                      setcurrentHoverUser={setcurrentHoverUser}
                      setonAddNoticeMode={setonAddNoticeMode}
                      pageTimeRange={
                        currentPage === "page1"
                          ? { start: 7, end: 13 }
                          : { start: 13, end: 19 }
                      }
                      setcurrentMousePosX={setcurrentMousePosX}
                      setaddedTimeValue={setaddedTimeValue}
                      addedTimeValue={addedTimeValue}
                      handleAddNoticeAPI={handleAddNoticeAPI}
                      viewType={viewType}
                    />
                  ) : (
                    <TeamViewNoticeDataRow
                      noticeRowData={data}
                      pageTimeRange={
                        currentPage === "page1"
                          ? { start: 7, end: 13 }
                          : { start: 13, end: 19 }
                      }
                      rowSize={
                        noticeRowSizeRef.current?.getBoundingClientRect()
                          .width as number
                      }
                      notices={notices}
                      setnotices={setnotices}
                      currentMousePosX={currentMousePosX}
                      setcurrentMousePosX={setcurrentMousePosX}
                      boundLeft={
                        noticeRowSizeRef.current?.getBoundingClientRect()
                          .left as number
                      }
                      setCurrentUserRow={setCurrentUserRow}
                      currentUserRow={currentUserRow}
                      currentDraggedNotice={currentDraggedNotice}
                      setCurrentDraggedNotice={setCurrentDraggedNotice}
                      viewType={viewType}
                      showSideTimeList={showSideTimeList}
                      setshowSideTimeList={setshowSideTimeList}
                      timeValueSelectedFromSideList={
                        timeValueSelectedFromSideList
                      }
                      settimeValueSelectedFromSideList={
                        settimeValueSelectedFromSideList
                      }
                    />
                  )
                ) : null}

                {/*Expand Icon */}
                {userWithNoticeList &&
                userWithNoticeList.user.userId === data.user.userId &&
                !onAddNoticeMode &&
                !currentMousePosX &&
                !currentDraggedNotice ? (
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    style={{
                      position: "absolute",
                      width: "20px",
                      height: "20px",
                      right: "15px",
                      top: "calc(50% - 10px)",
                      zIndex: 10,
                    }}
                    onClick={() => setCurrentSelectedUser(data)}
                  >
                    <MaximizeIcon />
                  </motion.div>
                ) : null}
              </Box>
            )
          )}

          {populateEmptyRow().map((row, index) => (
            <Box
              className="timetable-data-row-teamview"
              key={`emptyRow-${index}`}
              sx={{
                filter: currentUserRow
                  ? "blur(3px) brightness(0.6)"
                  : currentHoverUser && onAddNoticeMode
                  ? "blur(5px) brightness(0.8)"
                  : undefined,
                borderTop: currentUserRow ? "1px solid #cbdae2" : undefined,

                pointerEvents:
                  currentHoverUser && onAddNoticeMode ? "none" : undefined,
              }}
            >
              <Box className={"sidebar-timetable-data-teamview"}></Box>
              <Box className={"row-timetable-data-teamview"}></Box>
            </Box>
          ))}
        </Box>
      )}
      {/*Side time value */}
      <AnimatePresence>
        {showSideTimeList ? (
          <motion.div
            animate={{ scaleY: [0, 1] }}
            exit={{ scaleY: [1, 0] }}
            transition={{ duration: 0.2 }}
            style={{
              position: "absolute",
              right: currentPage === "page1" ? 0 : undefined,
              left: currentPage === "page2" ? 0 : undefined,
              top: "calc(25%)",
              width: "80px",
              height: "50%",
              backgroundColor: "rgba(133, 93, 248, 0.5)",
              zIndex: 10,
              borderRadius:
                currentPage === "page1" ? "16px 0 0 16px" : "0 16px 16px 0",
              display: "flex",
              flexDirection: "column",
              userSelect: "none",
            }}
          >
            {TimeRow()[currentPage === "page1" ? "page2" : "page1"].map(
              (data, timeIndex) => {
                return (
                  <motion.div
                    whileHover={{ zoom: 1.2 }}
                    key={`sideTime${data.timeValue}-${timeIndex}`}
                    className="side-menu-time-cell"
                    style={{
                      height: `${(1 / TimeRow()[currentPage].length) * 100}%`,
                    }}
                    onMouseEnter={() =>
                      settimeValueSelectedFromSideList(data.timeValue)
                    }
                  >
                    {data.timeValue <= 12
                      ? data.timeValue
                      : data.timeValue - 12}{" "}
                    {data.timeValue >= 12 ? "Pm" : "Am"}
                  </motion.div>
                );
              }
            )}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </Box>
  ) : (
    // FEB'S WEEKLY VIEW HERE - PETER PICK OUT WHAT PARTS YOU NEED THEN REMOVE THE DIFFERENT JSX RETURNS

    <Box
      sx={{
        backgroundColor: "white",
        flex: 1,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/*Header Section */}
      <Box
        className="timetable-header-teamview"
        sx={{ filter: loading ? `blur(3px)` : undefined, zIndex: 0 }}
      >
        <Box className={"sidebar-timetable-header-teamview"}>
          <Typography fontWeight={"bold"}>Byson Team</Typography>
        </Box>

        <Box
          className={"row-timetable-header-teamview"}
          sx={{ justifyContent: "center", position: "relative" }}
          ref={noticeRowSizeRef}
        >
          <Box
            sx={{
              justifyContent: "center",
              ".MuiBox-root:last-child": {
                borderRight: "none",
              },
              height: "100%",
              width: "100%",
              display: "flex",
            }}
          >
            {noticeRowSizeRef.current &&
              getWeekDates(today).map((date, i) => {
                let rowSize: number =
                  noticeRowSizeRef.current?.getBoundingClientRect()
                    .width as number;
                let widthOfACell: number =
                  rowSize * (1 / (weekDates.length - 1)); //we dont count sunday
                let rowBoundLeft: number =
                  noticeRowSizeRef.current?.getBoundingClientRect()
                    .left as number;
                let cellAxisPos: ITimeRange = {
                  start: (i - 1) * widthOfACell + rowBoundLeft,
                  end: (i - 1) * widthOfACell + rowBoundLeft + widthOfACell,
                };
                if (i > 0)
                  return (
                    <motion.div
                      animate={
                        noticeRowSizeRef.current &&
                        currentMousePosX &&
                        currentMousePosX >= cellAxisPos.start &&
                        currentMousePosX < cellAxisPos.end
                          ? { zoom: 1.4 }
                          : addedTimeValue &&
                            (parseInt(date) === addedTimeValue.start ||
                              parseInt(date) === addedTimeValue.end)
                          ? { zoom: 1.4 }
                          : { zoom: 1 }
                      }
                      className="time-header-text-style"
                      style={{
                        width: "calc(100%/6)",
                        borderRight:
                          i !== weekDates.length - 1
                            ? "1px solid #cbdae2"
                            : undefined,
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        height: "100%",
                        opacity: addedTimeValue
                          ? parseInt(date) !== addedTimeValue.start &&
                            parseInt(date) !== addedTimeValue.end
                            ? 0
                            : 1
                          : undefined,
                      }}
                    >
                      <Typography className={"title-weekly-date"}>
                        {date}
                      </Typography>
                      <Typography className={"title-weekly-day"}>
                        <span style={{ color: "#AC8FFF" }}>{weekdays[i]}</span>
                      </Typography>
                    </motion.div>
                  );
              })}
          </Box>

          <motion.div
            whileHover={{ y: [0, -5, 0], zoom: 1.1 }}
            transition={{ y: { duration: 0.5 } }}
            whileTap={{ x: -5 }}
            style={{
              position: "absolute",
              height: "100%",
              width: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              left: 0,
            }}
            onClick={() => handleSwitchWeek("previous")}
          >
            <PreviousWeekIcon />
          </motion.div>

          <motion.div
            whileHover={{ y: [0, -5, 0], zoom: 1.1 }}
            transition={{ y: { duration: 0.5 } }}
            whileTap={{ x: 5 }}
            style={{
              position: "absolute",
              height: "100%",
              width: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              right: 0,
            }}
            onClick={() => handleSwitchWeek("next")}
          >
            <NextWeekIcon />
          </motion.div>
        </Box>
      </Box>
      {loading ? (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              position: "absolute",
              filter: `blur(2px)`,
              top: 0,
              left: 0,
              zIndex: 0,
            }}
          ></Box>
          <Box className="loading-screen-mask-container">
            <Loading />
          </Box>
        </Box>
      ) : (
        <Box className="timetable-data-teamview">
          {/*Loading Screen */}

          {distributeNoticeForUsers(userList, notices, categorySelect).map(
            (data, i) => (
              <Box
                className="timetable-data-row-teamview"
                key={`${data.user.userId}` + `#` + `${i}`}
                style={{
                  position: "relative",
                  filter: currentUserRow
                    ? currentUserRow.userId !== data.user.userId
                      ? "blur(3px) brightness(0.6)"
                      : undefined
                    : currentHoverUser &&
                      onAddNoticeMode &&
                      currentHoverUser.userId !== data.user.userId
                    ? "blur(5px) brightness(0.8)"
                    : undefined,
                  borderTop: currentUserRow
                    ? currentUserRow.userId === data.user.userId
                      ? "1px solid #cbdae2"
                      : undefined
                    : undefined,
                  display: "flex",
                  pointerEvents:
                    currentHoverUser &&
                    onAddNoticeMode &&
                    currentHoverUser.userId !== data.user.userId
                      ? "none"
                      : undefined,
                }}
                onMouseOver={() => {
                  handlePopoverOpen(data);
                }}
                onMouseLeave={handlePopoverClose}
              >
                <Box
                  className={"sidebar-timetable-data-teamview"}
                  sx={{
                    position: "relative",
                    border:
                      (currentHoverUser &&
                        onAddNoticeMode &&
                        currentHoverUser.userId === data.user.userId) ||
                      (currentDraggedNotice &&
                        currentUserRow &&
                        currentUserRow.userId === data.user.userId)
                        ? "0.5px solid #7949FF"
                        : undefined,

                    color:
                      (currentHoverUser &&
                        onAddNoticeMode &&
                        currentHoverUser.userId === data.user.userId) ||
                      (currentDraggedNotice &&
                        currentUserRow &&
                        currentUserRow.userId === data.user.userId)
                        ? " #7949FF"
                        : undefined,
                  }}
                  onMouseEnter={() => handleHoverOnUserRow(data.user)}
                  onMouseLeave={() => handleMouseLeaveUserRow()}
                >
                  <span
                    style={{
                      display: "flex",
                      width: "calc(100% - 40px)",
                      height: "100%",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      zIndex: 1,
                      color:
                        currentHoverUser &&
                        currentHoverUser.userId === data.user.userId &&
                        !onAddNoticeMode
                          ? "white "
                          : undefined,
                    }}
                  >
                    {i + 1}. {data.user.firstName} {data.user.lastName}
                  </span>

                  {/** Addd notice btn */}
                  <AnimatePresence>
                    {currentHoverUser &&
                    currentHoverUser.userId === data.user.userId &&
                    !onAddNoticeMode ? (
                      <motion.div
                        whileTap={{ opacity: 0.4 }}
                        initial={{ width: "0%", opacity: 0.6 }}
                        animate={{ width: "calc(100% - 10px)" }}
                        exit={{ width: 0 }}
                        transition={{
                          width: { duration: 0.2, ease: "easeInOut" },
                          opacity: { duration: 0.2 },
                        }}
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          backgroundColor: "#7949FF",
                          position: "absolute",
                          top: 0,
                          right: 0,
                          zIndex: 0,
                          paddingRight: "10px",
                        }}
                      >
                        <AddNoticeIcon
                          onClick={() => setonAddNoticeMode(true)}
                        />
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                </Box>

                {noticeRowSizeRef.current !== null ? (
                  currentHoverUser &&
                  onAddNoticeMode &&
                  currentHoverUser.userId === data.user.userId ? (
                    <TeamViewAddNoticeRow
                      selectedUser={currentHoverUser}
                      rowSize={
                        noticeRowSizeRef.current.getBoundingClientRect().width
                      }
                      boundLeft={
                        noticeRowSizeRef.current.getBoundingClientRect().left
                      }
                      setcurrentHoverUser={setcurrentHoverUser}
                      setonAddNoticeMode={setonAddNoticeMode}
                      pageTimeRange={
                        getStartAndEndDateCurrentWeek(
                          today,
                          "number"
                        ) as ITimeRange
                      }
                      setcurrentMousePosX={setcurrentMousePosX}
                      setaddedTimeValue={setaddedTimeValue}
                      addedTimeValue={addedTimeValue}
                      handleAddNoticeAPI={handleAddNoticeAPI}
                      viewType={viewType}
                    />
                  ) : (
                    <TeamViewNoticeDataRow
                      noticeRowData={data}
                      pageTimeRange={
                        getStartAndEndDateCurrentWeek(
                          new Date(),
                          "number"
                        ) as ITimeRange
                      }
                      rowSize={
                        noticeRowSizeRef.current?.getBoundingClientRect()
                          .width as number
                      }
                      notices={notices}
                      setnotices={setnotices}
                      currentMousePosX={currentMousePosX}
                      setcurrentMousePosX={setcurrentMousePosX}
                      boundLeft={
                        noticeRowSizeRef.current?.getBoundingClientRect()
                          .left as number
                      }
                      setCurrentUserRow={setCurrentUserRow}
                      currentUserRow={currentUserRow}
                      currentDraggedNotice={currentDraggedNotice}
                      setCurrentDraggedNotice={setCurrentDraggedNotice}
                      viewType={viewType}
                      showSideTimeList={showSideTimeList}
                      setshowSideTimeList={setshowSideTimeList}
                      timeValueSelectedFromSideList={
                        timeValueSelectedFromSideList
                      }
                      settimeValueSelectedFromSideList={
                        settimeValueSelectedFromSideList
                      }
                    />
                  )
                ) : null}

                {/*Expand Icon */}
                {userWithNoticeList &&
                userWithNoticeList.user.userId === data.user.userId &&
                !currentMousePosX ? (
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    style={{
                      position: "absolute",
                      width: "20px",
                      height: "20px",
                      right: "15px",
                      top: "calc(50% - 10px)",
                      zIndex: 1,
                    }}
                    onClick={() => setCurrentSelectedUser(data)}
                  >
                    <MaximizeIcon />
                  </motion.div>
                ) : null}
              </Box>
            )
          )}

          {populateEmptyRow().map((row, index) => (
            <Box
              className="timetable-data-row-teamview"
              key={`emptyRow-${index}`}
              sx={{
                filter: currentUserRow
                  ? "blur(3px) brightness(0.6)"
                  : currentHoverUser && onAddNoticeMode
                  ? "blur(5px) brightness(0.8)"
                  : undefined,
                borderTop: currentUserRow ? "1px solid #cbdae2" : undefined,

                pointerEvents:
                  currentHoverUser && onAddNoticeMode ? "none" : undefined,
              }}
            >
              <Box className={"sidebar-timetable-data-teamview"}></Box>
              <Box className={"row-timetable-data-teamview"}></Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default TeamView;
