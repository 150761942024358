import { Box, Button, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MailSubContent } from '../MailroomMainView'
import '../Mailroom.css'
import CommentTextField from '../GeneralComponents/CommentTextField'
import CommentContainer from '../GeneralComponents/CommentContainer'
import { motion } from 'framer-motion'

type Props = {
  setCurrentSubContent: Function
  currentSubContent: MailSubContent
  setCurrentSubContentPosition: Function
  currentSubContentPosition: {
    x: number
    y: number
    width: number
    height: number
  }
  titleSectionHeight: number
  rootViewSize: {
    width: number
    height: number
  }
}

const SubContentModalView = ({
  setCurrentSubContent,
  currentSubContent,
  setCurrentSubContentPosition,
  currentSubContentPosition,
  titleSectionHeight,
  rootViewSize,
}: Props) => {
  //calculate modal size, width - 40 because its padding horizontal
  const modalSize = {
    width: currentSubContentPosition.width - 40,
    height: rootViewSize.height * 0.6,
  }

  const [expandComment, setExpandComment] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setExpandComment(true)
    }, 700)
  }, [])

  //########### ANIMATION VARIANTS ##########
  const [animationControl, setAnimationControl] = useState<'initial' | 'close'>(
    'initial'
  )
  const chatBoxVariants = {
    initial: { scaleX: [0, 1] },
    close: {
      scaleX: [1, 0],
    },
  }

  const commentSectionVariants = {
    initial: { height: [0, modalSize.height - 100] },
    close: {
      height: [modalSize.height - 100, 0],
    },
  }

  return (
    <Box
      className='subContentModalRootContainer'
      style={{
        zIndex: 100,
        position: 'absolute',
        left: `calc(5% + ${currentSubContentPosition.x}px)`,
        top: currentSubContentPosition.y + titleSectionHeight,
        width: modalSize.width,
        height: modalSize.height,
      }}
    >
      {/*TITLE SECTION */}
      <motion.div
        animate={{ scale: [0.8, 1] }}
        transition={{ duration: 0.3 }}
        className='modalSubContentTitleContainer'
        onClick={() => {
          setAnimationControl('close')
          setTimeout(() => {
            setExpandComment(false)
          }, 700)
          setTimeout(() => {
            setCurrentSubContent(undefined)
            setCurrentSubContentPosition(undefined)
          }, 1100)
        }}
        whileHover={{ opacity: [1, 0.7] }}
        style={{
          borderBottomLeftRadius: !expandComment ? '16px' : 0,
          borderBottomRightRadius: !expandComment ? '16px' : 0,
        }}
      >
        {currentSubContent.contentTitle}
      </motion.div>

      <motion.div
        animate={commentSectionVariants[animationControl]}
        transition={{
          duration: 0.4,
          delay: animationControl === 'initial' ? 0.7 : 0.3,
          bounceDamping: 8,
        }}
        style={{
          width: 'calc(100% - 40px)',
          height: modalSize.height - 80,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        {/*COMMENT SECTION */}
        <Box className='commentSubContentTitleContainer'>
          <CommentContainer />
          <CommentContainer />
          <CommentContainer />
        </Box>

        {/*CHAT BOX SECTION */}

        <motion.div
          className='texInputSubContentTitleContainer'
          animate={chatBoxVariants[animationControl]}
          transition={{
            duration: 0.3,
            delay: animationControl === 'initial' ? 1.1 : 0,
          }}
        >
          <CommentTextField />
        </motion.div>
      </motion.div>
    </Box>
  )
}

export default SubContentModalView
