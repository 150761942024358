import { Input, TextField } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { Specification } from "../../../Models/specification";
import { highlightMultilineText, highlightText } from "../../../utilities/Markdown/markdown";
import CustomCheckbox from "../../../utilities/CheckBox/CheckBox";

const DarkerDisabledTextField = withStyles({
    root: {
        "& .Mui-disabled": {
            color: "#000000",
            WebkitTextFillColor: "#000000",
            backgroundColor: "#f5f6f7"
        }
    }
})(TextField);

interface props{
    action: string,
    row: Specification,
    setSpecTitle?: Function,
    setSpecDescription?: Function,
    openSpecs: boolean | undefined,
    editMode: string | undefined,
    editIconClicked: boolean | undefined,
    setEditMode?: Function,
    handleCheckBox?: Function,
    handleSaveButton?: Function,
    tickedSpecs: string[]

}

const EditSpecification: React.FC<props> = ({
    action,
    row,
    setSpecTitle,
    setSpecDescription,
    openSpecs,
    editMode,
    editIconClicked,
    setEditMode,
    handleCheckBox,
    handleSaveButton,
    tickedSpecs
}) => {
    const [title, setTitle] = useState<string>(row.title)
    const [description, setDescription ] = useState<string>(row.description)

    const handleCheckBoxClick = (event: any, specId: string) =>{
        if(typeof event.target.checked === "boolean" && handleCheckBox){
           console.log(specId)
           handleCheckBox(event, specId)
        }
    }

    const resetTitle = (event: any) =>{
        event.target.value = title
        if(setEditMode){
            setEditMode(undefined)
        }

    }

    const handleHighlightText = (inputText: string) =>{
        let outputValue = [
            <div key={row.specId}>
                {highlightText(inputText, row.specId)}
            </div>   
        ]
        return outputValue
    }

    const handleTitleChange = async (event: any, spec: Specification)=>{
        try {
            if (event.target.value === title) 
            {
                resetTitle(event)
            } else {
                if(setSpecTitle){
                    setSpecTitle(event.target.value)
                }
                setTitle(event.target.value)
                
            }
            
        } catch(err){
            console.log(err);
            resetTitle(event)
        }
    }

    const resetDescription = (event: any) =>{
        event.target.value = description
        if(setEditMode){
            setEditMode(undefined)
        }
    }

    const handleHighlightMultilineText = (inputText: string, spec: Specification) =>{
        
        let output = highlightMultilineText(description, spec.specId)
        return output
    }

    const handleDescriptionChange = async (event: any, spec: Specification)=>{
        try {
            if (event.target.value === description) 
            {
                resetDescription(event)
            } else {
                if(setSpecDescription){
                    setSpecDescription(event.target.value)
                }
                setDescription(event.target.value)
            }
            
        } catch(err){
            console.log(err);
            resetDescription(event)
        }
    }
    if (action === "checkbox") return (
        <CustomCheckbox
            onClick={(event: any) => {
                handleCheckBoxClick(event, row.specId)
            }}
            checked={tickedSpecs.indexOf(row.specId) !== -1}
            style={{
                transform: "scale(0.4)"
            }}
        />
    )
    else if (action === "description") return (
        <DarkerDisabledTextField size="small"
            fullWidth
            variant="standard"
            InputProps={{
                disableUnderline: true,
                sx:{
                    fontFamily:'Roboto Mono',
                    fontStyle:'normal',
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "rgba(0, 0, 0, .7 )",
                    "& .Mui-disabled": {
                        color: "#000000 !important",
                        webkitTextFillColor : "#000000 !important",
                    }
                }
             }}
            disabled={!(editMode === row.specId && editIconClicked)}
            multiline
            defaultValue={description}
            onChange={(event: any) => {
              handleDescriptionChange(event, row);
            }}
            onKeyUp={(event: any) => {
              if (event.keyCode === 27){resetDescription(event);}
            }}
        />
    )
    else return null
}
export default EditSpecification