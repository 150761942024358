import { useLottie } from 'lottie-react'
// import animationData from "./lotties/doxle-loader.json"; // wagging tail
import animationData from './lotties/progress.json' // running doggie

interface props {}

const ProgressScreen: React.FC<props> = () => {
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  }

  const style = {
    position: 'absolute' as 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  }

  const { View } = useLottie(options, { width: '200px' })

  return <div style={style}>{View}</div>
}

export default ProgressScreen
