import axios from 'axios'
import React, { createContext, useContext, useState } from 'react'
import { baseAddress, offline } from '../../settings'
import CookieService from '../../Services/cookieService'
import { XeroCostcodeData, XeroProjectData } from '../../Models/xeroData'

type Props = {}

export interface IXeroContext {
  isXeroLinked: boolean
  getAllProjectLinks: Function
  getAllCostcodeLinks: Function
}
const XeroContext = createContext({})

const XeroProvider = (children: any) => {
  const [isXeroLinked, setisXeroLinked] = useState<boolean>(true)

  const getAllProjectLinks = async () => {
    let result: XeroProjectData[] | undefined = undefined
    if (offline) {
      return result
    }
    const accessToken: string = CookieService.get('access_token')
    if (!accessToken) {
      throw 'AccessTokenNotFound'
    }
    try {
      let response = await axios.post(
        baseAddress + '/xero/tracking/',
        { company_id: 'c074feca-fd03-4620-88b4-efedc1235ad1' },
        {
          headers: { Authorization: 'Bearer ' + accessToken },
        }
      )

      if (response.data && response.data.TrackingCategories) {
        result = response.data.TrackingCategories[0]
          .Options as XeroProjectData[]
      }
    } catch (error) {
      console.log('ERROR FETCHING GETTING XERO PROJECT', error)
      return result
    }
    return result
  }

  const getAllCostcodeLinks = async () => {
    let result: XeroCostcodeData[] | undefined = undefined
    if (offline) {
      return result
    }
    const accessToken: string = CookieService.get('access_token')
    if (!accessToken) {
      throw 'AccessTokenNotFound'
    }
    try {
      let response = await axios.post(
        baseAddress + '/xero/accounts/',
        { company_id: 'c074feca-fd03-4620-88b4-efedc1235ad1' },
        {
          headers: { Authorization: 'Bearer ' + accessToken },
        }
      )
      if (response.data && response.data.Accounts)
        result = response.data.Accounts as XeroCostcodeData[]
    } catch (error) {
      console.log('ERROR FETCHING GETTING XERO PROJECT', error)
      return result
    }
    return result
  }

  const xeroContextValue: IXeroContext = {
    isXeroLinked,
    getAllProjectLinks,
    getAllCostcodeLinks,
  }
  return <XeroContext.Provider value={xeroContextValue} {...children} />
}
const useXeroContext = () => useContext(XeroContext)
export { XeroProvider, useXeroContext }
