import React from 'react';
import { useEffect, useState } from 'react';
import {TableRow, TableCell, Checkbox, IconButton} from '@mui/material';
import { Costcode } from "../../../Models/costcode";
import { User } from "../../../Models/user";
import AddComment from '../Comments/AddComment';
import AddUser from '../Users/AddUser';
import selectedTick from "../../../Assets/selectedTick.svg";
import selectedCross from "../../../Assets/selectedCross.svg";
import selectedNA from "../../../Assets/selectedNA.svg";
// import useMountStatus  from '../../../utilities/Helper/checkMountStatus';
import "../CostCodes/costCode.css";
import { useStyles } from './permitStyle';
import comments from "../Comments/Comments";
import AutocompleteCustom from "../../../utilities/Autocomplete/Autocomplete";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import userAPI from "../../../Services/DoxleAPI/userAPI";
import costCodeAPI from "../../../Services/DoxleAPI/costCodeAPI";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import listIcon from "../../../Assets/ListOrdered.svg";
import listIconHighlight from "../../../Assets/ListOrderedHighlight.svg";
import fileAgreementIcon from "../../../Assets/FileAgreement.svg";
import fileTextIcon from "../../../Assets/FileText.svg";
import Badge from "@mui/material/Badge";
import {Permit} from "../../../Models/permit";
import {Checklist} from "../../../Models/checklist";
import AnswerIcons from "../Checklists/AnswerIcons";
import ChecklistAPI from "../../../Services/DoxleAPI/checklistAPI";
import {ReactComponent as TrashIcon} from "../../../Assets/Trash2.svg";
import EditPermit from "./EditPermit";
import AddContractor from "../Contractor/AddContractor";
import {AddressBookCompany} from "../../../Models/addressBook";
interface props{
    i: number;
    initChecklist: Checklist;
    handleDeleteChecklist: Function;
    handleCheckListChange: (checklistInput: Checklist, value: "YES"|"NO"|"NA") => void;
    display: string;
    handleCommentIconClicked: Function;
    users: User[];
    // editTitleMode: boolean;
    // editTitleOption: string|null;
    // setEditTitleOption: Function;
    // exitEditTitleMode: Function;
    contractors:AddressBookCompany[]
}

const ChecklistRow: React.FC<props> = (
    {
        i,
        initChecklist,
        handleDeleteChecklist,
        handleCheckListChange,
        display,
        handleCommentIconClicked,
        users,
        // editTitleMode,
        // editTitleOption,
        // setEditTitleOption,
        // exitEditTitleMode,
        contractors
    }) => {

    const [checklistItem, setChecklistItem] = useState<Checklist>(initChecklist);
    // const [question, setQuestion] = useState<string>(initChecklist.question);
    const [assignedUsers, setAssignedUsers] = useState<User[]>(initChecklist?.assignedUsers || [] );
    const [assignedContractor, setAssignedContractor] = useState<AddressBookCompany|undefined>(initChecklist?.assignedContractor ? initChecklist.assignedContractor : undefined);
    const authContext = useAuth() as authContextInterface;
    const { setLoggedIn, user } = authContext;
    //const unmounted = useMountStatus();
    const {height, width} = WindowDimensions();
    const limitAssignee = width < 928 ? 1 : 3;
    const getTextColor = () => {
        // if (status === "P") return "red";
        // if (status === "N") return "#c4c5c7";
        return "#0D0D0D";
    }

    // const handleCheckListChange = async(checklistInput: Checklist, value: "YES"|"NO"|"NA") =>{
    //     try{
    //         const setValue = value === checklistInput.answer ? null : value;
    //         if (!checklistInput.checklistId) return;
    //         if (!user?.userId) {console.log("User not found"); setLoggedIn(false); return;}
    //         const result = await ChecklistAPI.updateChecklist(checklistInput.checklistId, {answer: setValue}, user.userId) as Checklist
    //         setChecklistItem({...checklistItem, answer: result.answer,})
    //     }
    //     catch (err){
    //         err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);;
    //     }
    // }

    useEffect(() => {
        setChecklistItem(initChecklist);
        setAssignedUsers(initChecklist?.assignedUsers || [])
    }, [initChecklist])

    useEffect(() => {
        setAssignedUsers(checklistItem?.assignedUsers || [])
      }, [checklistItem])

    useEffect(() => {
        setAssignedContractor(initChecklist.assignedContractor as AddressBookCompany | undefined)
    }, [initChecklist.assignedContractor])
    
    
    return(
        <TableRow style={{display: display, opacity: "0.75"}} className={"tableRowStyle"}>
            <TableCell sx={{color: "#0D0D0D", ...useStyles.tableBodyStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", maxWidth: "40px"}}>
                {i+1}
            </TableCell>
            <TableCell
                colSpan={2} className='strikeCell'
                // onClick={() => {
                //     console.log(editTitleMode, checklistItem);
                //     editTitleMode ? setEditTitleOption(checklistItem.checklistId) : null
                // } }
                style={{cursor: 'default', position: "sticky", zIndex: 49, background: "white", paddingLeft: "40px"}}
                sx={{color: getTextColor(), ...useStyles.tableBodyTitleFieldStyle}}
            >

                {/*   editTitleMode && editTitleOption === checklistItem.question*/}
                {/*    ?*/}
                {/*    <EditPermit checklist={checklistItem} action={"checklistQuestion"} setValue={setQuestion} exitEditTitleMode={exitEditTitleMode}/>*/}
                {/*    :*/}
                {checklistItem.question}
            </TableCell>

            {/* Comment Icon Cell */}
            <TableCell sx={{color: "#0D0D0D", ...useStyles.tableBodyStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", maxWidth: "40px"}} onClick={(event => handleCommentIconClicked({checklist: checklistItem}))}>
                <AddComment checklist={checklistItem} clickFunction={() => {}} />
            </TableCell>

            {/*/!* Assigned users Cell *!/*/}
            <TableCell sx={{color: "#0D0D0D", ...useStyles.tableBodyStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", width: "150px"}}>
                <div style={{height: "33px", overflow: "hidden", display: "flex", alignContent: "center", color: getTextColor()}}>
                    <AddUser checklistId={checklistItem.checklistId} users={users} assignedUsers={assignedUsers}  setAssignedUsers={setAssignedUsers}/>
                </div>
            </TableCell>

            {/*/!* Contractor Cell *!/*/}
            <TableCell sx={{color: "#0D0D0D", ...useStyles.tableBodyStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", paddingLeft: 3, paddingRight: 3}}>
                <div style={{height: "33px", overflow: "hidden", display: "flex", alignContent: "center", color: getTextColor()}}>
                    <AddContractor checklistId={checklistItem.checklistId} addressBookCompanies={contractors} assignedContractor={assignedContractor} setAssignedContractor={setAssignedContractor} />
                </div>
            </TableCell>


            {/* Start Date Cell */}
            <TableCell sx={{color: "#0D0D0D", ...useStyles.tableBodyDateFieldStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)"}}>
            </TableCell>

            {/* End Date Cell */}
            <TableCell sx={{color: "#0D0D0D", ...useStyles.tableBodyDateFieldStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)"}}>
            </TableCell>

            {/* Status Date Cell */}
            <TableCell sx={{color: "#0D0D0D", ...useStyles.tableBodyDateFieldStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)"}}>
                {<AnswerIcons checklist={checklistItem} handleCheckListChange={handleCheckListChange} tableMode={true}/>}
            </TableCell>

            {/* Delete  Cell */}
            <TableCell sx={{color: "#0D0D0D", ...useStyles.tableBodyDateFieldStyle}} style={{borderRight: "0.3px solid rgba(224, 224, 224, 1)", maxWidth: "40px"}}>
                <TrashIcon onClick={(e: any) => handleDeleteChecklist(checklistItem)} style={{cursor: "pointer", transform: "scale(0.9)"}} />
            </TableCell>
        </TableRow>
    )
}

export default ChecklistRow