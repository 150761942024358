import React from "react";
import ReactDOM from "react-dom";
import { Routes, Route } from 'react-router-dom'
import "./index.css";
import "./Fonts/dearJoe4.ttf";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./components/Providers/AuthProvider";
// import {AlertProvider} from "./components/Providers/AlertProvider";
import moment from "moment-timezone";
import ProjectTimelineGrid from "./components/Content/ProjectTimeline/ProjectTimelineGrid";
import PurchaseOrderResponse from "./components/Content/PuchaseOrderResponse/PurchaseOrderResponse";

moment.tz.setDefault(moment.tz.guess());
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route
          path='/po/*'
          element={<PurchaseOrderResponse/>}
        />
        <Route
          path='*'
          element={
              <AuthProvider>
                  <App />
              </AuthProvider>
          }
        />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
