import costCodesJson from './costcodes.json';
import projectsJson from './projects.json';
import noticesJson from './notices.json';
import specsJson from './specs.json';
import quotesJson from './quotes.json';
import checklistsJson from './checklists.json';


export const getCostCodes = () => {
    let costCodes = JSON.parse(JSON.stringify(costCodesJson));
    //console.log(costCodes[0]["results"])
    return (costCodes?.[0]?.results);
}

export const getProjects = () => {
    let projects = JSON.parse(JSON.stringify(projectsJson));
    //console.log(projects[0]["results"])
    return (projects?.[0]?.results);
}

export const getNotices = () => {
    let notices = JSON.parse(JSON.stringify(noticesJson));
    //console.log(projects[0]["results"])
    return (notices?.[0]?.results);
}

export const getSpecs = () => {
    let specs = JSON.parse(JSON.stringify(specsJson));
    //console.log(projects[0]["results"])
    return (specs);
}

export const getQuotes = () => {
    let quotes = JSON.parse(JSON.stringify(quotesJson));
    //console.log(projects[0]["results"])
    return (quotes);
}

export const getChecklists = () => {
    let checklists = JSON.parse(JSON.stringify(checklistsJson));
    //console.log(projects[0]["results"])
    return (checklists);
}