import React, { useState, useEffect, useCallback } from "react";
import Pin from "../Pins/Pin";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  InputAdornment,
  TableRow,
  TextField,
  Card,
  Box,
  CardContent,
} from "@mui/material";
import { Comment, NewComment } from "../../../Models/comments";
import useMountStatus from "../../../utilities/Helper/checkMountStatus";
import WindowDimensions from "../../../utilities/WindowDimensions/windowDimension";
import {
  highlightMultilineText,
  highlightText,
} from "../../../utilities/Markdown/markdown";
import { authContextInterface, useAuth } from "../../Providers/AuthProvider";
import CommentAPI from "../../../Services/DoxleAPI/commentAPI";
import sendIcon from "../../../Assets/Send.svg";
import sendIconWhite from "../../../Assets/SendWhite.svg";
import {titleCaseMultiline, titleCaseMultilineHTML} from "../../../utilities/Helper/titleCase"
import CommentMentions from "./CommentMentions";
import { User } from "../../../Models/user";
import { useSocket } from "../../../Services/Sockets/useSocket";
import commentAPI from "../../../Services/DoxleAPI/commentAPI";
interface props {
  costCodeId?: string;
  noticeId?: string;
  checklistGroupId?: string;
  checklistId?: string;
  commentList: Comment[];
  closeModal?: () => void;
  setCommentList: Function;
  userList?: User[];
}

const Comments: React.FC<props> = ({
  costCodeId,
  noticeId,
  checklistGroupId,
  checklistId,
  commentList,
  closeModal,
  setCommentList,
  userList,
}) => {
  const [commentText, setCommentText] = useState<string>("");
  const [taggedUsers, setTaggedUsers] = useState<string[]>([]);
  const unmounted = useMountStatus();
  const { height, width } = WindowDimensions();
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn, exchangeRefreshToken, user } = authContext;

  const handleTextChange = (e: any) => {
    if (e.target.value || e.target.value == "") {
      setCommentText(e.target.value as string);
    }
  };

  const handleSubmitComment = async () => {
    if (!user?.userId) {
      console.log("Unable to find user - refreshing");
      await exchangeRefreshToken();
    }
    if (!user?.userId) {
      console.log("Still unable to find user - prompting for login");
      setLoggedIn(false);
      return;
    }

    // find tagged users is comment
    let taggedUserInComment:string[] = []
    let userTagRegexp: RegExp = /\@\[(.*?)\]/gm;

    const match = commentText.match(userTagRegexp)
    if (match) {
      match.forEach(m=> {
        const [firstName, lastName] = m.slice(2, -1).split(" ");
        const userId = userList?.filter(
            (us) => us.firstName === firstName && us.lastName === lastName
        )[0].userId;
        if (userId) taggedUserInComment.push(userId);
      })
      console.log('taggedUserInComment', taggedUserInComment)
    }

    setTaggedUsers(taggedUserInComment);

    try {
      let newComment: NewComment = {
        costCode: null,
        permit: null,
        checklist: null,
        notice: null,
        timeStamp: new Date(Date.now())?.toISOString(),
        commentText: commentText,
        pinned: false,
        user: user.userId,
        taggedUsers: taggedUserInComment,
      };
      console.log("costCodeId", costCodeId);
      console.log("noticeId", noticeId);
      console.log("checklistGroupId", checklistGroupId);
      console.log("checklistId", checklistId);
      let filter: string = ""
      let id: string = ""
      if (costCodeId) {
        newComment.costCode = costCodeId;
        filter = "cost_code"
        id = costCodeId
      }
      if (noticeId) {
        newComment.notice = noticeId;
        filter = "notice"
        id = noticeId
      }
      if (checklistGroupId) {
        newComment.permit = checklistGroupId;
        filter = "permit"
        id = checklistGroupId
      }
      if (checklistId) {
        newComment.checklist = checklistId;
        filter = "checklist"
        id = checklistId
      }
      console.log("newComment", newComment);
      // let response = await CommentAPI.add(newComment);
      // if (response) setCommentList([...commentList, response]);

      // instantly update comments instead of waiting from socket
      const existingComments : Comment[] = commentList
      setCommentList((prevComments: Comment[]) => [...prevComments, {
        timeStamp: new Date(Date.now())?.toISOString(),
        commentText: commentText,
        user: user,
      }]);


      //! SOCKET SENDING
      console.log(
        "%c CLIENT = Sending newComment " + newComment,
        "background:green; color:white"
      );

      socket.send(
        JSON.stringify({
          messageType: "Comment",
          message: newComment,
        })
      );

      //! HTTP BACK UP METHOD
      setTimeout(async () => {
        const comment_list: Comment[] = await commentAPI.getList(filter, id)
        if (comment_list.filter(
            (comment: Comment) => (comment.commentText.trim() === newComment.commentText.trim())
        ).length === 0) {
          console.warn("COMMENT NOT FOUND, TRYING HTTP METHOD")
          let result = await commentAPI.add(newComment);
          if (!result) {
            console.error("HTTP REQUEST FAILED")
            setCommentList([...existingComments])
          }
        }

        // send notification for user via socket
        if (taggedUserInComment.length > 0) {
          console.log(
            "%c CLIENT = Sending notification for " + taggedUserInComment.length + " users",
            "background:green; color:black"
          );
          taggedUserInComment.forEach( userTagged => {
            socket.send(
              JSON.stringify({
                messageType: "Notification",
                message: {notifiedUser: userTagged},
              })
            );  
          })
        }

      }, 1000);

      setCommentText("");
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const handlePinClick = async (commentId: string, pin: boolean) => {
    try {
      const response = (await CommentAPI.update(commentId, {
        pinned: pin,
      })) as Comment;
      let newCommentList: Comment[] = [];
      commentList.forEach((comment: Comment) => {
        if (comment.commentId === response.commentId) {
          newCommentList.push(response);
        } else {
          newCommentList.push(comment);
        }
      });
      newCommentList.sort(function (x, y) {
        return x.pinned === y.pinned ? 0 : x ? -1 : 1;
      });
      setCommentList([...newCommentList]);
    } catch (err) {
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
    }
  };

  const updateUserTag = (text: string) => {
    // const atRegex: RegExp = /@\[(.*?)\]/gmi;
    return text.replaceAll("@[", "<span style='color:#5A36BE'> @").replaceAll("]", "</span>")
  }

  // useEffect(() => {
  //   console.log("commentList", commentList);
  // }, [commentList]);


  //! <--- SOCKETS LISTENER
  const socket = useSocket();
  const onMessage = useCallback(
    (message) => {
      const data = JSON.parse(message?.data);

      const serverStatus: string = data.status;
      const messageType: string = data.message_type;

      console.log("socket data", data);
      if (messageType === "Comment") {
        if (serverStatus === "SUCCESS") {
          const receivedMessage = data.message as Comment;

          if (checklistId && receivedMessage.checklist !== checklistId) return    
          if (costCodeId && receivedMessage.costCode !== costCodeId) return
          if (noticeId && receivedMessage.notice !== noticeId) return
          if (checklistGroupId && receivedMessage.permit !== checklistGroupId) return

          console.log(
            "%cDOXLE SOCKETS=RECEIVE SUCCESS DATA",
            "background:green; color:white"
          );
          console.log(receivedMessage);
          // prevent duplicate, search by text, not found add
          if (commentList.findIndex((comment:Comment) => comment.commentText.trim() === receivedMessage.commentText.trim()) === -1) {
            setCommentList((prevComments: Comment[]) => [...prevComments, receivedMessage]);
          }

        } else {
          console.log(
            "%cDOXLE SOCKETS=VALIDATION ERROR ! DID NOT SAVE  TO THE DATABASE",
            "background:red; color:white"
          );
        }
      }
    },
    [commentList]
  );

  useEffect(() => {
    socket.addEventListener("message", onMessage);

    return () => {
      socket.removeEventListener("message", onMessage);
    };
  }, [socket, onMessage]);
  //! SOCKETS --->

  return (
    <div
      id="comment-area"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      { false ?
      <TableContainer
        sx={{
          backgroundColor: "white",
          width: "100%",
        }}
      >
        <Table style={{ tableLayout: "fixed" }}>
          <TableBody style={{ width: "100% !important", padding: "12px" }}>
            {commentList.map((comment, num) => (
              <>
                <TableRow key={comment.commentId + "header"}>
                  <TableCell
                    style={{
                      fontFamily: "Roboto",
                      padding: costCodeId ? "7px 0 0 14px" : "7px 0 0 56px",
                      fontSize: "9px",
                      fontWeight: "500",
                      color: "#754EE0",
                      position: "relative",
                      border: "none",
                    }}
                  >
                    {comment.user
                      ? comment.user.firstName + " " + comment.user.lastName
                      : "Anonymous user"}
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Roboto",
                      padding: "7px 18px 0 0",
                      textAlign: "end",
                      fontSize: "7px",
                      fontWeight: "500",
                      color: "#B1B9CB",
                      position: "relative",
                      top: "5px",
                      border: "none",
                    }}
                  >
                    {comment.timeStamp
                      ? new Date(comment.timeStamp)?.toUTCString()
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow
                  key={comment.commentId + "body"}
                  style={{ width: "100%" }}
                >
                  <TableCell
                    colSpan={2}
                    style={{
                      fontFamily: "Roboto",
                      padding: costCodeId
                        ? "4px 18px 7px 14px"
                        : "4px 18px 7px 56px",
                      fontSize: "9px",
                      color: "#000000",
                      width: "100%",
                      fontWeight: "400",
                      borderBottom: "1px solid #D6E1EB",
                      whiteSpace: "pre-line",
                      overflowWrap: "break-word",
                      backgroundColor: comment.pinned
                        ? "#faf1f5"
                        : // comment.user && user && comment.user.userId === user.userId ? "#eff1ff" :"#eff1f5"
                          "#ffffff",
                    }}
                  >
                    {highlightMultilineText(
                      comment.commentText,
                      comment.commentId
                    )}
                    {/* <Pin pinned={comment.pinned} floatRight={true} itemId={comment.commentId} handleClick={handlePinClick}/> */}
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      :
      <Box>
      {commentList.map((comment, num) =>
        <Card elevation={0} key={"noticecomment-" + num} sx={{
          border: "1px solid #E7DFFF",
          borderRadius: "8px",
          margin: "40px 50px",
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: checklistId? '11px' : '14px',
          lineHeight: '16px',
          marginTop: num === 0? 0: "40px",
        }}>
          <CardContent>
            <Box style={{
              display: "flex", justifyContent: "space-between",
              borderBottom: "3px solid #E7DFFF",
              fontFamily: 'Roboto Mono',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: checklistId? '11px' : '14px',
              lineHeight: '18px',
              marginBottom: '9px',
            }}
            >
              <div>{comment.user
                        ? comment.user.firstName + " " + comment.user.lastName
                        : "Anonymous user"}</div>
              <div style={{color: "#855CF8"}}>{comment.timeStamp
                        ? new Date(comment.timeStamp)?.toUTCString()
                        : ""}</div>
            </Box>
            {/* {highlightMultilineText(
                      comment.commentText,
                      comment.commentId
                    )} */}
            {titleCaseMultilineHTML(updateUserTag(comment.commentText))}
          </CardContent>
        </Card>
      )}
      </Box>
      }



      {/* comment input */}
      { false ?
      <div style={{
        position: "fixed",
        bottom: "10px",
        padding: "10px 0",
        minWidth: costCodeId ? '82.5%' : '100%'
      }}>
        <TextField
          onChange={(event: any) => handleTextChange(event)}
          placeholder={"Write your reply"}
          value={commentText}
          fullWidth={true}
          multiline={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img
                  style={{ cursor: "pointer" }}
                  src={sendIcon}
                  alt="send"
                  onClick={handleSubmitComment}
                />
              </InputAdornment>
            ),
          }}
          sx={{
            borderRadius: "8px",
            background: "white",
            border: "none",
            ".MuiInputBase-root": {
              borderRadius: "8px",
              color: "black",
              fontSize: "11px",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />
      </div>

      :
      <div style={{ padding: "10px 50px 30px 50px", display: "flex", justifyContent: "space-between", gap: "10px"}}>
        <CommentMentions 
          userList={userList} 
          commentText={commentText} 
          setCommentText={setCommentText}
          submitComment={handleSubmitComment}
          fontSize={checklistId? '12px' : '18px'}
          />
        <IconButton
            onClick={handleSubmitComment}
            sx={{
              background: '#754EE0',
              borderRadius: '12px',
              height: '49px',
              maxHeight: '49px',
              padding: "0px 25px",
              display: "flex",
              alignContent: "center",
              color: "white",
              cursor: "pointer",
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: checklistId? '12px' : '16px',
              "&:hover" : {
                background: '#956EF0',
              }
            }}
          >
              <img
                style={{ paddingBottom: "4px" }}
                src={sendIconWhite}
                alt="send"
              />
                Send
              </IconButton>
      </div>
      }
    </div>
  );
};

export default Comments;
