import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Grid,
  Tab,
  IconButton,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  TextField,
  Button,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import CloseIcon from '@mui/icons-material/Close'
import { Notice, NoticeCategory } from '../../../Models/notice'
import { Company } from '../../../Models/company'
import { User } from '../../../Models/user'
import Status from '../Status/Status'
import EditNotice from '../Notices/EditNotice'
import NoticeHistory from '../Notices/NoticeHistory'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import Comments from '../Comments/Comments'
import { Comment } from '../../../Models/comments'
import { FileData } from '../../../Models/storage'
import NoticeAPI from '../../../Services/DoxleAPI/noticeAPI'
import { authContextInterface, useAuth } from '../../Providers/AuthProvider'
import { formatScheduleDate } from '../../../utilities/constants'
import Files from '../Files/Files'
import ChecklistContainer from '../NoticeBoardChecklist/ChecklistContainer'
import CustomCheckbox from '../../../utilities/CheckBox/CheckBox'
import CustomizedCircularProgress from '../../../utilities/Progress/CustomizedCircularProgress'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

interface props {
  selectedNotice: Notice | object
  selectedNoticeTab: string
  openModal: boolean
  closeModal: () => void
  height: number
  width: number
  userList?: User[]
  categoryList: NoticeCategory[]
  setCategoryList?: React.Dispatch<React.SetStateAction<NoticeCategory[]>>
  notices: Notice[]
  setNotices: React.Dispatch<React.SetStateAction<Notice[]>>
  forceUpdate: Function
  company: Company | undefined
  noticeViewMode?: 'list' | 'table' | 'board'
  projectSiteAddress?: string
}

const NoticesDetails: React.FC<props> = ({
  selectedNotice,
  selectedNoticeTab,
  openModal,
  closeModal,
  height,
  width,
  userList,
  categoryList,
  setCategoryList,
  notices,
  setNotices,
  forceUpdate,
  company,
  projectSiteAddress,
}) => {
  const [notice, setNotice] = useState<Notice>(selectedNotice as Notice)
  const [tabValue, setTabValue] = useState<string>(selectedNoticeTab)
  const [commentList, setCommentList] = useState<Array<Comment>>([])
  const [attachmentList, setAttachmentList] = useState<Array<FileData>>([])
  const { noticeId }: any = selectedNotice
  const authContext = useAuth() as authContextInterface
  const { setLoggedIn, exchangeRefreshToken, user } = authContext
  const [loading, setLoading] = useState<boolean>(true)
  type TNoticeViewMode = 'list' | 'table' | 'board'
  const [noticeViewMode, setNoticeViewMode] = useState<TNoticeViewMode>(
    localStorage.getItem('currentNoticeViewMode')
      ? (localStorage.getItem('currentNoticeViewMode') as TNoticeViewMode)
      : 'list'
  )

  const fetchNoticeDetails = async (noticeId: string) => {
    setLoading(true)
    console.log('fetchNoticeDetails', noticeId)
    try {
      const response: Notice = (await NoticeAPI.getDetailed(noticeId)) as Notice

      if (response) {
        setNotice(response)
      }
      if (response.comments) {
        setCommentList(response.comments)
      }
      if (response.attachments) {
        setAttachmentList(response.attachments)
      }
    } catch (err) {
      err === 'AccessTokenNotFound' ? setLoggedIn(false) : console.error(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (noticeId && openModal) {
      setCommentList([])
      fetchNoticeDetails(noticeId)
    }
  }, [selectedNotice, openModal])

  useEffect(() => {
    setNotice(selectedNotice as Notice)
  }, [selectedNotice])

  useEffect(() => {
    openModal && setTabValue(selectedNoticeTab)
  }, [selectedNoticeTab, openModal])

  const getDescBreadcrumb = (desc: string) => {
    if (desc) {
      desc = desc.toLowerCase()
      if (desc.length > 150) return desc.substring(0, 150) + '...'
      else return desc
    }
    return ''
  }

  return (
    <Dialog
      fullScreen
      open={openModal}
      onClose={closeModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      disableAutoFocus
      TransitionComponent={Transition}
      sx={{ '.MuiDialog-paper': { background: '#F5F6F7' } }}
      onKeyUp={(event: any) => {
        if (event.keyCode === 27) closeModal()
      }}
    >
      <AppBar
        sx={{
          position: 'relative',
          backgroundColor: 'white',
          color: 'black',
        }}
        elevation={0}
      >
        <Toolbar>
          <Typography
            style={{
              fontFamily: 'Roboto Mono',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '21px',
              color: '#000000',
              flex: 1,
              textTransform: 'capitalize',
            }}
          >
            {company ? company.name : 'Byson Group'} / Notice Board /{' '}
            {noticeViewMode === 'list'
              ? notice?.category?.toLowerCase()
              : projectSiteAddress?.toLowerCase()}{' '}
            /{' '}
            {noticeViewMode === 'list'
              ? getDescBreadcrumb(notice?.description)
              : getDescBreadcrumb(notice?.category!)}{' '}
            / Notice {tabValue === 'Notice' ? 'Details' : tabValue}
          </Typography>
          <IconButton
            edge='start'
            color='inherit'
            onClick={closeModal}
            aria-label='close'
            sx={{ '&:hover': { borderRadius: '11px' } }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          marginTop: '1%',
          marginBottom: '1%',
          alignSelf: 'center',
          backgroundColor: 'white',
          width: '98%',
          height: '92%',
          borderRadius: '11px',
          overflow: 'hidden',
        }}
      >
        {loading && (
          <Box
            sx={{ position: 'absolute', top: '50%', left: '50%', zIndex: 999 }}
          >
            <CustomizedCircularProgress />
          </Box>
        )}

        <Typography
          color='#000000'
          variant='h5'
          style={{
            margin: '30px 50px 10px 50px',
            paddingBottom: '5px',
            borderBottom: '1.5px solid #96A2BE',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '36px',
            lineHeight: '42px',
          }}
        >
          Notice Dialog
        </Typography>
        <Typography
          variant='body1'
          style={{
            marginLeft: '50px',
            fontFamily: 'Roboto Mono',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '18px',
          }}
        >
          Modify your notice information
        </Typography>

        <TabContext value={tabValue}>
          <Box sx={{ px: '50px', py: '35px' }}>
            <TabList
              textColor='inherit'
              indicatorColor='primary'
              onChange={(event: React.SyntheticEvent, newValue: string) =>
                setTabValue(newValue)
              }
              centered
              TabIndicatorProps={{ style: { display: 'none' } }}
              sx={{
                '& .MuiTabs-flexContainer': {
                  gap: '2px',
                  justifyContent: 'flex-start',
                },
                '& .MuiButtonBase-root': {
                  textTransform: 'none',
                  fontFamily: 'Roboto Mono',
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '21px',
                  minWidth: 'unset',
                  padding: '0',
                  width: 'calc(25% - 2px)',
                  maxWidth: 'unset',
                  background: '#F1F2F4',
                  minHeight: '55px',
                },
                '& .Mui-selected': {
                  color: '#8A51C3',
                  fontWeight: '600',
                },
              }}
            >
              <Tab
                key={0}
                label='Notice'
                value={'Notice'}
                sx={{ borderRadius: '8px 0 0 8px' }}
                disableRipple
              />
              <Tab
                key={1}
                label='Checklists'
                value={'Checklists'}
                sx={{ borderRadius: 0 }}
                disableRipple
              />
              <Tab
                key={2}
                label='Comments'
                value={'Comments'}
                sx={{ borderRadius: 0 }}
                disableRipple
              />
              <Tab
                key={3}
                label='History'
                value={'History'}
                sx={{ borderRadius: '0 8px 8px 0' }}
                disableRipple
              />
            </TabList>
          </Box>

          {/* content grid */}

          <Grid
            sx={{
              background: '#ffffff',
              overflowY: 'auto',
              height: 'calc(100% - 2% - 220px)',
            }}
          >
            {/* notice details panel */}
            <TabPanel
              value='Notice'
              className='notice-details'
              sx={{
                px: '51px',
                pt: 0,
                '& .MuiTextField-root': {
                  border: '1px solid #D6D9DF',
                  borderRadius: '6px',
                  background: '#F8F8F8',
                },
                '& .MuiFilledInput-root': {
                  background: 'none',
                },
                '& .MuiFilledInput-input': {
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '14px',
                  px: '14px',
                  py: '16px',
                },
                '& .purple': { color: '#855CF8' },
                height: 'calc(100% - 24px)',
              }}
            >
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    display='flex'
                    justifyContent='flex-end'
                    gap='7px'
                    alignItems='center'
                  >
                    {notice.creator?.userId === user?.userId && (
                      <EditNotice
                        notice={notice}
                        action={'private'}
                        categoryList={categoryList}
                        notices={notices}
                        setNotices={setNotices}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Typography variant='caption' sx={{ ml: 0, mt: 4, mb: 0 }}>
                      Description
                      <span className='purple'> *</span>
                    </Typography>
                    <EditNotice
                      action={'description'}
                      notice={notice}
                      setNotice={setNotice}
                      categoryList={categoryList}
                      notices={notices}
                      setNotices={setNotices}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant='caption' sx={{ ml: 0, mt: 4, mb: 0 }}>
                      Created By
                    </Typography>
                    <TextField
                      value={
                        notice?.creator?.firstName &&
                        notice.creator.firstName + ' ' + notice.creator.lastName
                      }
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                      }}
                      fullWidth={true}
                      sx={{ m: 0 }}
                      variant='filled'
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    <Typography variant='caption' sx={{ ml: 0, mt: 4, mb: 0 }}>
                      Created Date
                    </Typography>
                    <TextField
                      value={
                        notice.timeStamp
                          ? formatScheduleDate(
                              new Date(notice.timeStamp).toISOString()
                            )
                          : ''
                      }
                      InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                      }}
                      fullWidth={true}
                      sx={{ m: 0 }}
                      variant='filled'
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant='caption' sx={{ ml: 0, mt: 4, mb: 0 }}>
                      Assigned To
                      <sup className='purple'> *</sup>
                    </Typography>
                    <EditNotice
                      notice={notice}
                      action={'assignee'}
                      categoryList={categoryList}
                      notices={notices}
                      setNotices={setNotices}
                      userList={userList}
                      openModal={openModal}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    <Typography variant='caption' sx={{ ml: 0, mt: 4, mb: 0 }}>
                      Due Date
                      <sup className='purple'> *</sup>
                    </Typography>
                    <EditNotice
                      notice={notice}
                      action={'duedate'}
                      categoryList={categoryList}
                      notices={notices}
                      setNotices={setNotices}
                      openModal={openModal}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant='caption' sx={{ ml: 0, mt: 4, mb: 0 }}>
                      Category
                      <sup className='purple'> *</sup>
                    </Typography>
                    <EditNotice
                      notice={notice}
                      action={'category'}
                      categoryList={categoryList}
                      setCategoryList={setCategoryList}
                      notices={notices}
                      setNotices={setNotices}
                      closeModal={closeModal}
                      forceUpdate={forceUpdate}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    <Typography variant='caption' sx={{ ml: 0, mt: 4, mb: 0 }}>
                      Status
                    </Typography>
                    <div className='notice-detail-text'>
                      {notice?.status ? (
                        <Status
                          type={'notice'}
                          itemId={notice.noticeId ? notice.noticeId : ''}
                          status={notice.status}
                          openModal={openModal}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='caption' sx={{ ml: 0, mt: 4, mb: 0 }}>
                      Attach File(s)
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Files
                      action={'notice'}
                      notice={notice}
                      attachmentList={attachmentList}
                      setAttachmentList={setAttachmentList}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} style={{ paddingTop: '100px' }}>
                  <Grid item xs={7}>
                    <Typography style={{ color: '#855CF8' }}>
                      <sup>*</sup> required field
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '11px',
                      paddingBottom: '50px',
                    }}
                  >
                    <Button
                      onClick={() => closeModal()}
                      style={{
                        color: '#000000',
                        backgroundColor: '#D6D9DF',
                        minWidth: '188px',
                        borderRadius: '9px',
                        textTransform: 'none',
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '18px',
                        lineHeight: '21px',
                        padding: '14px',
                      }}
                    >
                      Cancel
                    </Button>

                    <EditNotice
                      action={'submit'}
                      notice={notice}
                      notices={notices}
                      categoryList={categoryList}
                      setNotices={setNotices}
                      closeModal={closeModal}
                    />
                  </Grid>
                </Grid>
              </div>
            </TabPanel>

            {/* checklists panel */}
            <TabPanel
              value='Checklists'
              sx={{
                padding: '0px',
                height: 'calc(100% - 20px)',
                paddingTop: '14px',
              }}
            >
              <ChecklistContainer notice={notice} />
            </TabPanel>

            {/* comments panel */}
            <TabPanel
              value='Comments'
              sx={{
                padding: '0px',
                height: 'calc(100% - 20px)',
                paddingTop: '14px',
              }}
            >
              <Comments
                noticeId={noticeId}
                commentList={commentList}
                setCommentList={setCommentList}
                closeModal={closeModal}
                userList={userList}
              />
            </TabPanel>

            {/* History panel */}
            <TabPanel
              value='History'
              sx={{
                padding: '0px',
                height: 'calc(100% - 20px)',
                paddingTop: '14px',
              }}
            >
              <NoticeHistory notice={notice} userList={userList} />
            </TabPanel>
          </Grid>
        </TabContext>
      </Box>
    </Dialog>
  )
}

export default React.memo(NoticesDetails)
