import React, { useEffect, useState, useRef } from "react";
import { MobileDatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Grid, Button, Box, Select, MenuItem, TextField, InputAdornment, ListItemButton } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import ProjectAPI from '../../../Services/DoxleAPI/projectAPI'
import { makeStyles } from "@material-ui/core/styles";
import { formatScheduleDate } from "../../../utilities/constants";
import Calendar from '../../../Assets/Calendar.svg'
import AccountCircle from "@mui/icons-material/AccountCircle";


import "./addProject.css";
import {authContextInterface, useAuth} from "../../Providers/AuthProvider";
import {Project} from "../../../Models/project";
import Loading from "../../../utilities/Lottie/Loading";


interface props {
  action: string
  currentProject: Project | null;
  setProjectSelected: Function;
  projects: Project[];
  setProjects: Function;
  closeModal: Function;
  setLoading: Function;
}

interface XeroProjects{
  optionId:string;
  optionName:string;
}

const formatter: Intl.NumberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const AddProjectModal: React.FC<props> = ({action, currentProject, setProjectSelected, projects, setProjects, closeModal}) => {
  const currentCompanyId = localStorage.getItem('currentCompanyId')? localStorage.getItem('currentCompanyId') : ""
  const currentDate = new Date(Date.now())?.toISOString().split('T')?.[0]
  console.log("ADD / EDIT PROJECT MODAL", action);
  
  const [startValue, setStartValue] = useState<string>(action === "editProject" && currentProject ? currentProject.startDate : currentDate);
  const [endValue, setEndValue] = useState<string>(action === "editProject" && currentProject ? currentProject.endDate : currentDate);
  const [xeroDropDownValue, setXeroDropDownValue] = useState<string>(action === "editProject" && currentProject?.trackingId ? "existing" :"none");
  const [xeroTrackingOption, setXeroTrackingOption] = useState<string>("none");
  const [siteAddress, setSiteAddress] = useState<string>(action === "editProject" && currentProject ? currentProject.siteAddress :"")
  const [contractPrice, setContractPrice] = useState<string>(action === "editProject" && currentProject  ? currentProject.contractPrice : "$0.00")
  const [budget, setBudget] = useState<string>(action === "editProject" && currentProject ? currentProject.budget : "$0.00")
  const [projectStatus, setProjectStatus] = useState<'TN'|'AC'|'AR'|'TM'>(action === "editProject" && currentProject ? currentProject.projectStatus :"TN")
  const [templateProject, setTemplateProject] = useState<string>(action === "editProject"  ? "" :"none")
  const [xeroProjectOptions, setXeroProjectOptions] = useState<XeroProjects[]>([{optionId: 'none', optionName:'Fetching Project Options'}])
  const [loading, setLoading] = useState<boolean>(false)
  const companyId: string = currentCompanyId ? currentCompanyId : ""

  const ownerNameRef = useRef<any>(null)
  const ownerPhoneRef = useRef<any>(null)
  const ownerEmailRef = useRef<any>(null)
  const ownerAbnRef = useRef<any>(null)
  const authContext = useAuth() as authContextInterface;
  const { setLoggedIn } = authContext;
  const useStyle = makeStyles(() => ({
    
    InputFieldsText: {
      minWidth: "100%",
      '&:hover': {
        border: '1px solid #0044CC',
        borderRadius: '5px'
      },
    }
  }));
  const classes = useStyle();

  useEffect(() => {
    const initLocal = { lat: -37.8136, lng: 144.9631 };
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 18,
        center: initLocal,
      }
    );
    setLoading(false)
  }, []);



  const inputLabelColor = {
    color: "#96A2BE",
    height: "15px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    display: "block",
  };

  const handleContractChange = (e:any) => {
    if (e?.target?.value && !isNaN(parseFloat(e.target.value.replaceAll('$', '').replaceAll(',', '')))) {
      setContractPrice(e.target.value)
    }
  }

  const handleBudgetChange = (e:any) => {
    if (e?.target?.value && !isNaN(parseFloat(e.target.value.replaceAll('$', '').replaceAll(',', '')))) {
      setBudget(e.target.value)
    }
  }

  const handleContractBlur = (e:any) => {
    if (e?.target?.value && !isNaN(parseFloat(e.target.value.replaceAll('$', '').replaceAll(',', '')))) {
      setContractPrice(formatter.format(parseFloat(e.target.value.replaceAll('$', '').replaceAll(',', ''))))
    }
  }

  const handleBudgetBlur = (e:any) => {
    if (e?.target?.value && !isNaN(parseFloat(e.target.value.replaceAll('$', '').replaceAll(',', '')))) {
      setBudget(formatter.format(parseFloat(e.target.value.replaceAll('$', '').replaceAll(',', ''))))
    }
  }


  const handleSubmit = async() => {
    try {
      setLoading(true)
    if (action === "editProject" && currentProject) {
      const response = await ProjectAPI.update(currentProject.projectId, {
        siteAddress: siteAddress,
        contractPrice: parseFloat(contractPrice.replaceAll('$', '').replaceAll(',', '')),
        budget: parseFloat(budget.replaceAll('$', '').replaceAll(',', '')),
        startDate: startValue.toString(),
        endDate: endValue.toString(),
        projectStatus: projectStatus,
        ownerName: ownerNameRef?.current?.value ? ownerNameRef.current.value : "",
        ownerAbn: ownerAbnRef?.current?.value ? ownerAbnRef.current.value : "",
        ownerEmail: ownerEmailRef?.current?.value ? ownerEmailRef.current.value : "",
        ownerPhone: ownerPhoneRef?.current?.value ? ownerPhoneRef.current.value : "",
      }) as Project
      if (response) localStorage.setItem("currentProject", JSON.stringify(response));
      if (response?.projectId) localStorage.setItem("currentProjectId", JSON.stringify(response.projectId));
      if (response?.projectPrefix) sessionStorage.setItem("projectPrefix", response.projectPrefix);
      else if (response?.siteAddress) sessionStorage.setItem("projectPrefix", response.siteAddress.replace(/ /g,'').substring(0,7).toUpperCase());
      setProjectSelected(response);
      closeModal();
    }
    if (action === "newProject") {
      const projectPrefixStr = siteAddress.toUpperCase().split("", 5).toString().replace(/,/g, '');
      const projectPayload = {
        siteAddress,
        contractPrice,
        budget,
        startDate: startValue,
        endDate: endValue,
        projectPrefix: projectPrefixStr,
        companyId,
        company: companyId,
        ownerName: ownerNameRef?.current?.value ? ownerNameRef.current.value : "",
        ownerAbn: ownerAbnRef?.current?.value ? ownerAbnRef.current.value : "",
        ownerEmail: ownerEmailRef?.current?.value ? ownerEmailRef.current.value : "",
        ownerPhone: ownerPhoneRef?.current?.value ? ownerPhoneRef.current.value : "",
        projectStatus,
        users: [],
        template: templateProject
      }
      const response = await ProjectAPI.add(projectPayload) as Project
      if (response && response.projectId){
        if (response) localStorage.setItem("currentProject", JSON.stringify(response));
        if (response?.projectId) localStorage.setItem("currentProjectId", response.projectId);
        if (response?.projectPrefix) sessionStorage.setItem("projectPrefix", response.projectPrefix);
        else if (response?.siteAddress) sessionStorage.setItem("projectPrefix", response.siteAddress.replace(/ /g,'').substring(0,7).toUpperCase());
        setProjects([...projects, response])
        setProjectSelected(response);
        closeModal();
      }
    }
  } catch (err){
      err === "AccessTokenNotFound" ? setLoggedIn(false) : console.error(err);
  }
  setLoading(false)
}

const icon = <img src={Calendar} alt="" />
const textStyle = {
  width: '272px'
}
  return loading
  ? (<Loading />)
  : (
    <div style={{ padding: "35px" }}>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <label style={inputLabelColor}>Owner Name</label>
            <TextField
            style={textStyle} className={classes.InputFieldsText} inputRef={ownerNameRef} defaultValue={currentProject?.ownerName ? currentProject.ownerName : ""}  variant="outlined"  fullWidth id="bootstrap-input" />
          </Grid>
          
          <Grid item xs={6}>
            <label style={inputLabelColor}>Owner ABN</label>
            <TextField style={textStyle} className={classes.InputFieldsText} inputRef={ownerAbnRef} defaultValue={currentProject?.ownerAbn ? currentProject.ownerEmail : ""} variant="outlined" fullWidth id="bootstrap-input" />
          </Grid>
          <Grid item xs={6}>
            <label style={inputLabelColor}>Owner Phone</label>
            <TextField style={textStyle} className={classes.InputFieldsText} inputRef={ownerPhoneRef} defaultValue={currentProject?.ownerPhone ? currentProject.ownerEmail : ""} variant="outlined" fullWidth id="bootstrap-input" />
          </Grid>
          <Grid item xs={6}>
            <label style={inputLabelColor}>Owner Email</label>
            <TextField type="email" style={textStyle} className={classes.InputFieldsText} inputRef={ownerEmailRef} defaultValue={currentProject?.ownerEmail ? currentProject.ownerEmail : ""} variant="outlined" fullWidth id="bootstrap-input" />
          </Grid>
          <Grid item xs={12}>
            <label style={inputLabelColor}>Site Address</label>
            <TextField  onChange={(e) => setSiteAddress(e.target.value)} value={siteAddress} variant="outlined" fullWidth id="bootstrap-input" />
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          position: "relative",
          marginTop: "20px",
          marginBottom: "50px",
          height: "200px",
        }}
        id="map"
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <label style={inputLabelColor}>Start Date</label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
              openTo="day"
              value={startValue}
              inputFormat="dd.MM.yy"
              onChange={(newValue: any) =>{
                let value = new Date(newValue)?.toISOString().split('T')?.[0]
                    setStartValue(value);
              }}
              renderInput={(params) =>
                  <TextField
                  {...params}
                  InputProps={{
                    endAdornment: (
                      <img src={Calendar} style={{position: "relative", left: "30%"}}/>
                      )
                    }}
                  style={{minWidth: "100%", ...textStyle }}
                  className={classes.InputFieldsText}
                  id="bootstrap-input"
                  fullWidth
                  />
                }
          />
      </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <label style={inputLabelColor}>End Date</label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                  openTo="day"
                  value={endValue}
                  inputFormat="dd.MM.yy"
                  onChange={(newValue: any) =>{
                    let value = new Date(newValue)?.toISOString().split('T')?.[0]
                        setEndValue(value);
                  }}

                  renderInput={(params) =>
                      <TextField
                      {...params}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                            <img src={Calendar} style={{position: "relative", left: "30%"}}/>
                        )
                      }}
                      style={{minWidth: "100%", ...textStyle }}
                      className={classes.InputFieldsText}
                      id="bootstrap-input"
                      />
                      /* <img style={{position: 'absolute', left: '28%', top: ''}} src={Calendar} alt="" /> */
                    }
              />
          </LocalizationProvider>
        </Grid>
            <Grid item xs={6}>
              <label style={inputLabelColor}>Contract Price</label>
              <TextField style={textStyle}  className={classes.InputFieldsText} onChange={handleContractChange} onBlur={handleContractBlur} value={contractPrice} variant="outlined" fullWidth id="bootstrap-input" />
            </Grid>
            <Grid item xs={6}>
              <label style={inputLabelColor}>Budget</label>
              <TextField style={textStyle}  className={classes.InputFieldsText} onChange={handleBudgetChange} onBlur={handleBudgetBlur} value={budget} variant="outlined" fullWidth id="bootstrap-input" />
            </Grid>
            <Grid item xs={6}>
              <label style={inputLabelColor}>Project Status</label>
              <Select
                  className={classes.InputFieldsText}
                  value={projectStatus}
                  label="Site Address"
                  onChange={(e) => {
                    if (e.target.value === 'TN' || e.target.value === 'AC' || e.target.value === 'AR' || e.target.value === 'TM') setProjectStatus(e.target.value)
                  }}
                  sx={{height: "41px", width: "272px"}}
              >
                <MenuItem value={'TN'}>Tender</MenuItem>
                <MenuItem value={'AC'}>Active</MenuItem>
                <MenuItem value={'AR'}>Archived</MenuItem>
                <MenuItem value={'TM'}>Template</MenuItem>
              </Select>
              {/*<TextField style={textStyle} className={classes.InputFieldsText} value={projectStatus} variant="outlined" fullWidth id="bootstrap-input" />*/}
            </Grid>
            <Grid item xs={6}>
              <label style={inputLabelColor}>Project Template</label>
              <Select
                  className={classes.InputFieldsText}
                  value={templateProject}
                  label="Site Address"
                  onChange={(e) => { setTemplateProject(e.target.value) }}
                  sx={{minWidth: "100%", height: "41px"}}
                  disabled={action==="editProject"}
              >
                <MenuItem value={'none'}>Don't Use a Template</MenuItem>
                {
                  projects.map((project: Project) => {
                      if (project.projectId && project.projectStatus==="TM") return (<MenuItem key={project.projectId} value={project.projectId}>{project.siteAddress}</MenuItem>)
                })
                }
              </Select>
            </Grid>
        <Grid item xs={6}>
          <label style={inputLabelColor}>Xero Tracking Option</label>
          <FormControl fullWidth>
            <Select
              value={xeroDropDownValue}
              onChange={(e) => {
                setXeroDropDownValue(e.target.value);
              }}
              sx={{minWidth: "100%", height: "41px"}}
            >
              <MenuItem value={"none"}>Don't link to Xero Project</MenuItem>
              <MenuItem value={"new"}>Create new xero Project</MenuItem>
              <MenuItem value={"existing"}>Linked to existing Project</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <label style={inputLabelColor}>Xero Project Option</label>
          <FormControl fullWidth>
            {
              xeroDropDownValue === "new"
              ?
              <TextField style={textStyle} className={classes.InputFieldsText} onChange={(e:any) => setXeroTrackingOption(e.target.value)} value={xeroTrackingOption} variant="outlined" fullWidth id="bootstrap-input" />
              :
              <Select
                value={xeroTrackingOption}
                onChange={(e) => {
                  setXeroTrackingOption(e.target.value);
                }}
                disabled={xeroDropDownValue==="none"}
                sx={{minWidth: "100%", height: "41px"}}
              >
                {xeroProjectOptions.map((opt: XeroProjects) => (
                  <MenuItem key={opt.optionId} value={opt.optionId}>{opt.optionName}</MenuItem>)
                )}
              </Select>
            }
          </FormControl>
        </Grid>
        <Grid item xs={12}/>
        <Grid item xs={12}/>
        <Grid item xs={12}>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Button
              style={{
                width: "441px",
                height: "40px",
                position: "relative",
                alignItems: "center",
                background: "#0044CC",
                borderRadius: "5px",
              }}
              variant="contained"
              onClick={() => {handleSubmit()}}
            >
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddProjectModal;
