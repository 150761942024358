import {Project} from "../../Models/project";
import {baseAddress, offline} from "../../settings";
import CookieService from "../cookieService";
import axios from "axios";
import {Costcode} from "../../Models/costcode";
import {ScheduleItem} from "../../Models/schedules";

interface GetParams {
    project?: string
    start?: string,
    days?: number,
}

const getList = async ({project, start = new Date().toISOString().substring(0, 10), days = 14}: GetParams)  => {
    // Get's a list of projects for a given company selected with companyId
    // Order projects with orderBy, options =  site_address, start_date, project_status
    // Pagination 100 costcodes per result - call for next page when scrolling
    interface Result{
        costCodes: Costcode[];
        scheduleItems: ScheduleItem[]
    }
    let result: Result = {'costCodes': [], 'scheduleItems': []}
    if (offline) return result;
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){
        throw "AccessTokenNotFound"
    }

    try {
        let getParams: GetParams = {start, days};
        if (project) {getParams.project = project;}
        const response = await axios.get(
            baseAddress+"/schedule/",
            {
                headers: { Authorization: "Bearer " + accessToken },
                params: getParams
            }
        )
        if (response.status === 200) result =  response.data as Result;

    } catch (error) {
        console.log("ERROR FETCHING PROJECTS", error)
    }

    return result
}

interface UpdateBody {
    title?: string;
    startDate?: string;
    endDate?: string;
    completed?: boolean;
    assignedUsers?: string[];
    assignedContractor?: string|null;
}

const update = async (
    scheduleId: string,
    { title, startDate, endDate, completed, assignedUsers, assignedContractor}: UpdateBody
    )  => {
    let result: ScheduleItem|undefined = undefined;
    if (offline){ return result }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        let body: UpdateBody = {}
        if (title) {body.title = title;}
        if (startDate) {body.startDate = startDate;}
        if (endDate) {body.endDate = endDate;}
        if (assignedUsers) {body.assignedUsers = assignedUsers;}
        if (typeof assignedContractor !== "undefined") {body.assignedContractor = assignedContractor;}
        if (typeof completed === 'boolean') {body.completed = completed;}
        console.log(body);
        const response = await axios.patch(
            baseAddress+"/schedule/"+scheduleId+"/",
            body,
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.data as ScheduleItem;
        console.log("UPDATED SCHEDULE", result)
    } catch (error) {
        console.error("ERROR UPDATING SCHEDULE", error)
    }
    return result
}

const remove = async (scheduleId: string)  => {
    let result: boolean = false;
    if (offline){ return true }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        const response = await axios.delete(
            baseAddress+"/schedule/"+scheduleId+"/",
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        result = response.status === 204;
        console.log("UPDATED SCHEDULE", result)
    } catch (error) {
        console.error("ERROR UPDATING SCHEDULE", error)
    }
    return result
}

const add = async (newScheduleItem: ScheduleItem)  => {
    let result: ScheduleItem|undefined;
    if (offline){ return undefined }
    const accessToken: string = CookieService.get("access_token");
    if (!accessToken){ throw "AccessTokenNotFound" }
    try {
        const response = await axios.post(
            baseAddress+"/schedule/",
            newScheduleItem,
            {
                headers: { Authorization: "Bearer " + accessToken },
            }
        )
        if (response.status === 201) result = response.data as ScheduleItem
        console.log("ADDED SCHEDULE", result)
    } catch (error) {
        console.error("ERROR ADDING SCHEDULE", error)
    }
    return result
}

const ScheduleAPI = {
    getList,
    update,
    remove,
    add
};

export default ScheduleAPI