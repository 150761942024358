import React, { useState, useEffect, ReactElement } from "react";
import "../Menu/filterNavigation.css";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { IconButton, Stack, Chip, Typography } from "@mui/material";
import sliderIcon from "../../Assets/Sliders.svg";
import settingsIcon from "../../Assets/GearPurple.svg";
import {
  STATUS_SYMBOL_WITH_LOCK,
  STATUS_SYMBOL_COLOR,
} from "../../utilities/constants";
import CustomCheckbox from "../../utilities/CheckBox/CheckBox";
import { ReactComponent as UserIcon } from "../../Assets/User.svg";
import { ReactComponent as UsersIcon } from "../../Assets/Users.svg";
import { ReactComponent as CalendarStartIcon } from "../../Assets/CalendarStart.svg";
import { ReactComponent as CalendarEndIcon } from "../../Assets/CalendarEnd.svg";
import { ReactComponent as Calendar1Icon } from "../../Assets/Calendar1.svg";
import { ReactComponent as Calendar7Icon } from "../../Assets/Calendar7.svg";
import { ReactComponent as Calendar14Icon } from "../../Assets/Calendar14.svg";
import { ReactComponent as Calendar30Icon } from "../../Assets/Calendar30.svg";
import { ReactComponent as AddIcon } from "../../Assets/PlusCircle-black.svg";
import { ReactComponent as EditIcon } from "../../Assets/EditMenu.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Trash-black.svg";
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { ReactComponent as DraftIcon } from "../../Assets/s-draft.svg";
import { ReactComponent as WorkingIcon } from "../../Assets/s-review.svg";
import { ReactComponent as ReadyIcon } from "../../Assets/s-ready.svg";
import { ReactComponent as ProblemIcon } from "../../Assets/s-problem.svg";
import { ReactComponent as NAIcon } from "../../Assets/s-na.svg";
import { ReactComponent as ListIcon } from "../../Assets/ListOrdered.svg";
import { ReactComponent as NotesIcon } from "../../Assets/FileAgreement.svg";
import { ReactComponent as FileIcon } from "../../Assets/FileText.svg";
import { ReactComponent as PhotoIcon } from "../../Assets/photos.svg";
import { ReactComponent as CommentIcon } from "../../Assets/Comment.svg";
import { ReactComponent as AllIcon } from "../../Assets/Stack.svg";
import { ReactComponent as StagedIcon } from "../../Assets/Staged.svg";
import { ReactComponent as BudgetIcon } from "../../Assets/OverBudget.svg";
import { ReactComponent as TickedIcon } from "../../Assets/Ticked.svg";
import { ReactComponent as UntickedIcon } from "../../Assets/Unticked.svg";

import CloseIcon from "@mui/icons-material/Close";
import { User } from "../../Models/user";
import { ColumnView } from "../../Models/columnView";
import { useNavigate } from "react-router-dom";
interface props {
  menu: string;
  setView?: Function;
  setAction?: Function;
  setOpenModal?: Function;
  editTitleMode?: boolean;
  setEditTitleMode?: Function;
  filter?: string | null;
  setFilter?: Function;
  noticePeriodFilter?: string | null;
  setNoticePeriodFilter?: Function;
  setDialogOpen?: Function;
  columnView?: ColumnView;
  setColumnView?: Function;
  handleAddCategory?: Function;
  getSpecs?: Function;
  exportCostings?: Function;
  userList?: User[];
  userFilter?: string[];
  handleFilterUserClick?: Function;
  statusFilter?: string | null;
  setStatusFilter?: Function;
}

const FilterNavigation: React.FC<props> = ({
  menu,
  setView,
  setAction,
  setOpenModal,
  editTitleMode,
  setEditTitleMode,
  filter,
  setFilter,
  noticePeriodFilter,
  setNoticePeriodFilter,
  setDialogOpen,
  columnView,
  setColumnView,
  handleAddCategory,
  getSpecs,
  exportCostings,
  userList,
  userFilter,
  handleFilterUserClick,
  statusFilter,
  setStatusFilter,
}) => {
  interface MenuObject {
    description: string;
    clickInput: string | null;
    divider: boolean;
    type: "head" | "option" | "divider" | "blank" | "checkbox";
    color?: string;
    icon?: ReactElement;
    position?: "right";
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorFilterEl, setAnchorFilterEl] = useState<null | HTMLElement>(
    null
  );
  const [filterMenuItems, setFilterMenuItems] = React.useState<MenuObject[]>(
    []
  );
  const [optionMenuItems, setOptionMenuItems] = React.useState<MenuObject[]>(
    []
  );

  const navigate = useNavigate();
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFilterEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterClose = () => {
    setAnchorFilterEl(null);
  };

  const handleColumnViewClick = async (input: string | null) => {
    const column = input !== null ? input : "";
    if (setColumnView) {
      setColumnView((prev: any) => ({
        ...prev,
        [column]: !prev[column],
      }));
    }
  };

  const handleFilterItemClick = async (input: string | null) => {
    // setAnchorFilterEl(null);
    if (setFilter) {
      if (setView && (input === "costcode" || input === "costcodeStages")) {
        setView(input);
        sessionStorage.setItem("defaultView", input);
        if (input === "costcodeStages")
          localStorage.setItem("stageView", "true");
        else localStorage.removeItem("stageView");
        setFilter(null);
        sessionStorage.removeItem("ccFilter")
      } else if (filter === input || !input) setFilter(null);
      else {
        setFilter(input)
        sessionStorage.setItem("ccFilter", input)
      };
    } else if (setNoticePeriodFilter && setStatusFilter) {
      if (input === null || input === noticePeriodFilter) {
        setNoticePeriodFilter(null);
        setStatusFilter(null);
      }
      if (
        input === "today" ||
        input === "week" ||
        input === "2week" ||
        input === "month"
      )
        setNoticePeriodFilter(input);
      if (
        input === "D" ||
        input === "W" ||
        input === "R" ||
        input === "P" ||
        input === "N"
      )
        setStatusFilter(input);
    }
  };

  const handleMenuItemClick = (input: string | null) => {
    console.log(input);
    setAnchorEl(null);
    if (
      setAction &&
      setDialogOpen &&
      (input === "addCostCode" || input === "deleteProject")
    ) {
      setAction(input);
      setDialogOpen(true);
      return;
    }
    console.log();
    if (
      setAction &&
      setOpenModal &&
      (input === "newProject" || input === "editProject")
    ) {
      setAction(input);
      setOpenModal(true);
      return;
    }
    if (
      setEditTitleMode &&
      (input === "editCostCode" || input === "editPermit")
    ) {
      editTitleMode ? setEditTitleMode(false) : setEditTitleMode(true);
      return;
    }
    if (exportCostings && input === "exportCostings") exportCostings();
    if (getSpecs && input === "exportProjectSpecs") getSpecs();
    if (
      setDialogOpen &&
      setAction &&
      (input === "addNoticeCategory" || input === "deleteNoticeCategory")
    ) {
      setDialogOpen(true);
      setAction(input);
    }

    //!PETER ADDED HERE
    if (input === "xeroMapping" && setView) {
      setView("xeroMappingView");
      return;
    }
    //!============
  };

  const symbolIcons = [
    <DraftIcon style={{ transform: "scale(0.75)" }} />,
    <WorkingIcon style={{ transform: "scale(0.75)" }} />,
    <ReadyIcon style={{ transform: "scale(0.75)" }} />,
    <ProblemIcon style={{ transform: "scale(0.75)" }} />,
    <NAIcon style={{ transform: "scale(0.75)" }} />,
  ];

  const insertStatusFilters = () => {
    let filterMenuObjects: MenuObject[] = [];
    let i = 0;
    for (const [symbol, status] of Object.entries(STATUS_SYMBOL_WITH_LOCK)) {
      filterMenuObjects.push({
        description:
          status.substring(0, 1).toUpperCase() +
          status.substring(1).toLowerCase(),
        clickInput: symbol,
        divider: true,
        type: "option",
        color: Object.values(STATUS_SYMBOL_COLOR)[i],
        icon: symbolIcons[i],
      });
      i += 1;
    }
    return filterMenuObjects;
  };

  const MenuClose: React.FC<{ handleClick: Function }> = ({ handleClick }) => (
    <MenuItem
      sx={{
        justifyContent: "end",
        height: "35px",
        paddingRight: "6px",
        "&:hover": { background: "none" },
        cursor: "default",
      }}
      disableRipple
    >
      <IconButton
        sx={{
          width: "21px",
          height: "21px",
          m: 0,
          p: 0,
          cursor: "pointer",
          borderRadius: 1,
          background: "#F5F6F7",
        }}
        size="small"
        onClick={() => handleClick()}
      >
        <CloseIcon sx={{ transform: "scale(0.8)" }} />
      </IconButton>
    </MenuItem>
  );

  const projectFilterOptions: MenuObject[] = [
    {
      description: "Columns",
      clickInput: null,
      divider: false,
      type: "head",
      color: "#754EE0",
    },
    {
      description: "Notes Icon",
      clickInput: "notesIcon",
      divider: true,
      type: "checkbox",
      icon: <NotesIcon />,
    },
    {
      description: "File Icon",
      clickInput: "fileIcon",
      divider: true,
      type: "checkbox",
      icon: <FileIcon />,
    },
    {
      description: "Photo Icon",
      clickInput: "photoIcon",
      divider: true,
      type: "checkbox",
      icon: <PhotoIcon />,
    },
    {
      description: "Checklist Icon",
      clickInput: "checklistIcon",
      divider: true,
      type: "checkbox",
      icon: <ListIcon />,
    },
    {
      description: "Signed Bills",
      clickInput: "signedBills",
      divider: true,
      type: "checkbox",
      icon: (
        <Typography
          sx={{ fontFamily: "DearJoe4", fontSize: "x-small", color: "#B6A9DA" }}
        >
          XX
        </Typography>
      ),
    },
    {
      description: "Comment Icon",
      clickInput: "commentIcon",
      divider: true,
      type: "checkbox",
      icon: <CommentIcon />,
    },
    {
      description: "Assignee",
      clickInput: "assignee",
      divider: true,
      type: "checkbox",
      icon: <UserIcon />,
    },
    {
      description: "Contractor",
      clickInput: "contractor",
      divider: true,
      type: "checkbox",
      icon: <UsersIcon />,
    },
    {
      description: "Budget",
      clickInput: "budget",
      divider: true,
      type: "checkbox",
      icon: <BudgetIcon />,
    },
    {
      description: "Order",
      clickInput: "order",
      divider: true,
      type: "checkbox",
      icon: <BudgetIcon />,
    },
    {
      description: "Billed",
      clickInput: "billed",
      divider: true,
      type: "checkbox",
      icon: <BudgetIcon />,
    },
    {
      description: "Running",
      clickInput: "running",
      divider: true,
      type: "checkbox",
      icon: <BudgetIcon />,
    },
    // {
    //   description: "Difference",
    //   clickInput: "difference",
    //   divider: true,
    //   type: "checkbox",
    //   icon: <BudgetIcon />,
    // },
    {
      description: "Start Date",
      clickInput: "startDate",
      divider: true,
      type: "checkbox",
      icon: <CalendarStartIcon />,
    },
    {
      description: "End Date",
      clickInput: "endDate",
      divider: true,
      type: "checkbox",
      icon: <CalendarEndIcon />,
    },
    {
      description: "Days",
      clickInput: "days",
      divider: true,
      type: "checkbox",
      icon: <CalendarEndIcon />,
    },

    { description: "", clickInput: null, divider: false, type: "blank" },
    {
      description: "Due Dates",
      clickInput: null,
      divider: false,
      type: "head",
      color: "#754EE0",
    },
    {
      description: "Due Today",
      clickInput: "today",
      divider: true,
      type: "option",
      icon: <Calendar1Icon />,
    },
    {
      description: "Due This Week",
      clickInput: "week",
      divider: true,
      type: "option",
      icon: <Calendar7Icon />,
    },
    {
      description: "Due This Fortnight",
      clickInput: "2week",
      divider: true,
      type: "option",
      icon: <Calendar14Icon />,
    },
    {
      description: "Due This Month",
      clickInput: "month",
      divider: true,
      type: "option",
      icon: <Calendar30Icon />,
    },

    { description: "", clickInput: null, divider: false, type: "blank" },
    {
      description: "Status",
      clickInput: null,
      divider: false,
      type: "head",
      color: "#754EE0",
    },
    ...insertStatusFilters(),

    { description: "", clickInput: null, divider: false, type: "blank" },

    // RIGHT MENU -->
    {
      description: "",
      clickInput: null,
      divider: false,
      type: "blank",
      position: "right",
    },
    {
      description: "View",
      clickInput: null,
      divider: false,
      type: "head",
      color: "#754EE0",
      position: "right",
    },
    menu === "costcode"
      ? {
          description: "Staged Accounts",
          clickInput: "costcodeStages",
          divider: true,
          type: "option",
          icon: <StagedIcon />,
          position: "right",
        }
      : {
          description: "All Accounts",
          clickInput: "costcode",
          divider: true,
          type: "option",
          icon: <AllIcon />,
          position: "right",
        },
    {
      description: "Quick Take Off",
      clickInput: "quick",
      divider: true,
      type: "option",
      icon: <ElectricBoltIcon
        style={{
          marginLeft: -7,
          transform: "scale(0.6)",
        }}
      />,
      position: "right",
    },
    {
      description: "Over Budget",
      clickInput: "overBudget",
      divider: true,
      type: "option",
      icon: <BudgetIcon />,
      position: "right",
    },
    {
      description: "Client Mode",
      clickInput: "clientMode",
      divider: true,
      type: "option",
      icon: <BudgetIcon />,
      position: "right",
    },

    {
      description: "",
      clickInput: null,
      divider: false,
      type: "blank",
      position: "right",
    },
    {
      description: "Ticked",
      clickInput: null,
      divider: false,
      type: "head",
      color: "#754EE0",
      position: "right",
    },
    {
      description: "Ticked Filters",
      clickInput: "checked",
      divider: true,
      type: "option",
      icon: <TickedIcon />,
      position: "right",
    },
    {
      description: "Unticked Filters",
      clickInput: "notChecked",
      divider: true,
      type: "option",
      icon: <UntickedIcon />,
      position: "right",
    },
  ];

  const noticeFilterOptions: MenuObject[] = [
    {
      description: "Columns",
      clickInput: null,
      divider: false,
      type: "head",
      color: "#754EE0",
    },
    {
      description: "Assignee",
      clickInput: "assignee",
      divider: true,
      type: "checkbox",
      icon: <UserIcon />,
    },
    {
      description: "Start Date",
      clickInput: "startDate",
      divider: true,
      type: "checkbox",
      icon: <CalendarStartIcon />,
    },
    {
      description: "End Date",
      clickInput: "endDate",
      divider: true,
      type: "checkbox",
      icon: <CalendarEndIcon />,
    },

    { description: "", clickInput: null, divider: false, type: "blank" },
    {
      description: "Due Dates",
      clickInput: null,
      divider: false,
      type: "head",
      color: "#754EE0",
    },
    {
      description: "Due Today",
      clickInput: "today",
      divider: true,
      type: "option",
      icon: <Calendar1Icon />,
    },
    {
      description: "Due This Week",
      clickInput: "week",
      divider: true,
      type: "option",
      icon: <Calendar7Icon />,
    },
    {
      description: "Due This Fortnight",
      clickInput: "2week",
      divider: true,
      type: "option",
      icon: <Calendar14Icon />,
    },
    {
      description: "Due This Month",
      clickInput: "month",
      divider: true,
      type: "option",
      icon: <Calendar30Icon />,
    },

    { description: "", clickInput: null, divider: false, type: "blank" },
    {
      description: "Status",
      clickInput: null,
      divider: false,
      type: "head",
      color: "#754EE0",
    },
    ...insertStatusFilters(),

    { description: "", clickInput: null, divider: false, type: "blank" },
  ];

  useEffect(() => {
    if (
      menu === "costcode" ||
      menu === "costcodeStages" ||
      menu === "timeline"
    ) {
      setOptionMenuItems([
        {
          description: "Project Options",
          clickInput: "",
          divider: false,
          type: "head",
        },
        {
          description: "projDiv",
          clickInput: "",
          divider: false,
          type: "divider",
        },
        {
          description: "Create a new project",
          clickInput: "newProject",
          divider: false,
          type: "option",
        },
        {
          description: "Edit current project",
          clickInput: "editProject",
          divider: false,
          type: "option",
        },
        {
          description: "Delete current project",
          clickInput: "deleteProject",
          divider: false,
          type: "option",
        },
        {
          description: "Export Costings (CSV)",
          clickInput: "exportCostings",
          divider: false,
          type: "option",
        },
        {
          description: "Export Project Specs",
          clickInput: "exportProjectSpecs",
          divider: false,
          type: "option",
        },
        { description: "", clickInput: null, divider: false, type: "blank" },
        {
          description: "Cost Code Options",
          clickInput: null,
          divider: false,
          type: "head",
        },
        {
          description: "ccDiv",
          clickInput: null,
          divider: false,
          type: "divider",
        },
        {
          description: "Add a Cost Code",
          clickInput: "addCostCode",
          divider: false,
          type: "option",
        },
        {
          description: "Edit Cost Codes",
          clickInput: "editCostCode",
          divider: false,
          type: "option",
        },
        { description: "", clickInput: null, divider: false, type: "blank" },

        //!PETER ADDED HERE
        {
          description: "projDiv",
          clickInput: "",
          divider: false,
          type: "divider",
        },

        {
          description: "Account Mapping",
          clickInput: "xeroMapping",
          divider: false,
          type: "option",
        },
        { description: "", clickInput: null, divider: false, type: "blank" },
        //!==================
      ]);
      if (filter)
        setFilterMenuItems([
          {
            description: "Clear Filter",
            clickInput: null,
            divider: true,
            type: "option",
          },
          ...projectFilterOptions,
        ]);
      else setFilterMenuItems([...projectFilterOptions]);
    }
    if (menu === "permits") {
      setOptionMenuItems([
        {
          description: "Permit Options",
          clickInput: "",
          divider: false,
          type: "head",
        },
        { description: "", clickInput: "", divider: false, type: "divider" },
        {
          description: "Edit Permit or checklist",
          clickInput: "editPermit",
          divider: false,
          type: "option",
        },
      ]);
      if (filter)
        setFilterMenuItems([
          {
            description: "Clear Filter",
            clickInput: null,
            divider: true,
            type: "option",
          },
          ...projectFilterOptions,
        ]);
      else setFilterMenuItems([...projectFilterOptions]);
    }
    if (menu === "notice") {
      setOptionMenuItems([
        {
          description: "Settings",
          clickInput: "",
          divider: false,
          type: "head",
          color: "#754EE0",
        },
        {
          description: "Add Category",
          clickInput: "addNoticeCategory",
          divider: false,
          type: "option",
          icon: <AddIcon />,
        },
        {
          description: "Edit Category",
          clickInput: "addNoticeCategory",
          divider: false,
          type: "option",
          icon: <EditIcon />,
        },
        {
          description: "Delete Current Category",
          clickInput: "deleteNoticeCategory",
          divider: false,
          type: "option",
          icon: <DeleteIcon />,
        },

        { description: "", clickInput: null, divider: false, type: "blank" },
        { description: "", clickInput: null, divider: false, type: "blank" },
        { description: "", clickInput: null, divider: false, type: "blank" },
      ]);
      if (statusFilter || noticePeriodFilter) {
        setFilterMenuItems([
          {
            description: "Clear Filter",
            clickInput: null,
            divider: true,
            type: "option",
          },
          ...noticeFilterOptions,
        ]);
      } else setFilterMenuItems([...noticeFilterOptions]);
    }
  }, [menu, noticePeriodFilter, statusFilter, filter]);

  return (
    <div
      className="filterNav"
      style={{
        display: "flex",
        flexDirection: "row",
        paddingTop: "7px",
        height: "fit-content",
        gap: "3px",
      }}
    >
      {/* FILTER MENU */}
      <div>
        <IconButton
          sx={{ width: "24px", height: "24px", m: 0, p: 0, marginTop: "-2px" }}
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleFilterMenu}
          color="inherit"
        >
          <img src={sliderIcon} alt="filter icon" />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorFilterEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            "& .MuiList-root": {
              p: 0,
            },
            "& .MuiMenu-paper": {
              minWidth: "345px !important",
            },
            "& .MuiMenuItem-root": {
              fontFamily: "Roboto Mono",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              alignItems: "center",
            },
          }}
          open={Boolean(anchorFilterEl)}
          onClose={handleFilterClose}
        >
          <Stack display="flex" direction="row">
            {/* LEFT MENU */}
            <Stack
              display="flex"
              direction="column"
              width="50%"
              borderRight="1px solid #E6E6E6"
            >
              <MenuItem
                sx={{
                  height: "35px",
                  "&:hover": { background: "none" },
                  cursor: "default",
                }}
                disableRipple
              />

              {filterMenuItems.map(
                (filterItem: MenuObject, num: number) =>
                  filterItem.position === undefined &&
                  (filterItem.type === "option" ||
                  filterItem.type === "checkbox" ? (
                    <MenuItem
                      key={num.toString()}
                      sx={{ color: filterItem.color || "" }}
                      divider={filterItem.divider}
                      onClick={(event: any) => {
                        if (filterItem.type === "checkbox" && setColumnView) {
                          handleColumnViewClick(filterItem.clickInput);
                        } else {
                          handleFilterItemClick(filterItem.clickInput);
                        }
                      }}
                    >
                      {filterItem.type === "checkbox" && (
                        <CustomCheckbox
                          checked={
                            (filterItem.clickInput === "notesIcon" &&
                              columnView?.notesIcon) ||
                            (filterItem.clickInput === "fileIcon" &&
                              columnView?.fileIcon) ||
                            (filterItem.clickInput === "photoIcon" &&
                              columnView?.photoIcon) ||
                            (filterItem.clickInput === "checklistIcon" &&
                              columnView?.checklistIcon) ||
                            (filterItem.clickInput === "signedBills" &&
                              columnView?.signedBills) ||
                            (filterItem.clickInput === "commentIcon" &&
                              columnView?.commentIcon) ||
                            (filterItem.clickInput === "assignee" &&
                              columnView?.assignee) ||
                            (filterItem.clickInput === "contractor" &&
                              columnView?.contractor) ||
                            (filterItem.clickInput === "budget" &&
                              columnView?.budget) ||
                            (filterItem.clickInput === "order" &&
                              columnView?.order) ||
                            (filterItem.clickInput === "billed" &&
                              columnView?.billed) ||
                            (filterItem.clickInput === "running" &&
                              columnView?.running) ||
                            // (filterItem.clickInput === "difference" &&
                            //   columnView?.difference) ||
                            (filterItem.clickInput === "startDate" &&
                              columnView?.startDate) ||
                            (filterItem.clickInput === "endDate" &&
                              columnView?.endDate) ||
                            (filterItem.clickInput === "days" &&
                              columnView?.days) ||
                            false
                          }
                          style={{
                            transform: "scale(0.5)",
                            paddingRight: "16px",
                            marginLeft: "-7px",
                          }}
                        />
                      )}

                      {filterItem.icon && (
                        <div style={{ paddingRight: "4px", display: "flex" }}>
                          {filterItem.icon}
                        </div>
                      )}
                      {filterItem.description}
                    </MenuItem>
                  ) : filterItem.type === "divider" ? (
                    <Divider key={"div" + num} />
                  ) : filterItem.type === "head" ? (
                    <MenuItem
                      key={filterItem.description + "Header"}
                      divider={filterItem.divider}
                      sx={{ color: filterItem.color || "" }}
                    >
                      {filterItem.description}
                    </MenuItem>
                  ) : filterItem.type === "blank" ? (
                    <br key={"line" + num} />
                  ) : null)
              )}
            </Stack>

            {/* RIGHT MENU */}
            <Stack display="flex" direction="column" width="50%">
              <MenuClose handleClick={handleFilterClose} />

              <MenuItem style={{ color: "#754EE0" }}>People</MenuItem>

              {userList?.map((user) => (
                <MenuItem
                  key={user.userId}
                  onClick={() => {
                    handleFilterClose();
                    user.userId &&
                      handleFilterUserClick &&
                      handleFilterUserClick(user.userId);
                  }}
                  sx={{
                    background:
                      user.userId &&
                      userFilter &&
                      userFilter.includes(user.userId)
                        ? "#DBD3F3"
                        : "#F1F2F5",
                    borderRadius: "2px",
                    mx: "10px",
                    my: "1px",
                    color:
                      user.userId &&
                      userFilter &&
                      userFilter.includes(user.userId)
                        ? "#3D3948"
                        : "#8C84A4",
                    "&:hover": {
                      background: "#DBD3F3",
                      color: "#3D3948",
                    },
                  }}
                >
                  <Chip
                    variant="outlined"
                    label={
                      user.firstName.charAt(0) + "" + user.lastName.charAt(0)
                    }
                    size="small"
                    sx={{
                      backgroundColor: "#927CD2",
                      color: "white",
                      fontSize: "8px",
                      height: "15px",
                      paddingTop: "2px",
                      marginRight: "10px",
                      border: "none",
                      cursor: "pointer",
                    }}
                  />
                  {`${user.firstName} ${user.lastName}`}
                </MenuItem>
              ))}

              {filterMenuItems.map(
                (filterItem: MenuObject, num: number) =>
                  filterItem.position === "right" &&
                  (filterItem.type === "option" ? (
                    <MenuItem
                      key={num.toString()}
                      sx={{ color: filterItem.color || "" }}
                      divider={filterItem.divider}
                      onClick={(event: any) =>
                        handleFilterItemClick(filterItem.clickInput)
                      }
                    >
                      {filterItem.icon && (
                        <div style={{ paddingRight: "4px", display: "flex" }}>
                          {filterItem.icon}
                        </div>
                      )}
                      {filterItem.description}
                    </MenuItem>
                  ) : filterItem.type === "divider" ? (
                    <Divider key={"div" + num} />
                  ) : filterItem.type === "head" ? (
                    <MenuItem
                      key={filterItem.description + "Header"}
                      divider={filterItem.divider}
                      sx={{ color: filterItem.color || "" }}
                    >
                      {filterItem.description}
                    </MenuItem>
                  ) : filterItem.type === "blank" ? (
                    <br key={"line" + num} />
                  ) : null)
              )}
            </Stack>
          </Stack>
          <MenuItem
            disabled
            style={{
              opacity: "1",
              background: "#F1F2F5",
              justifyContent: "center",
              color: "#754EE0",
              height: "35px",
            }}
          >
            {menu === "notice" && "Notice Board Filter Dialog"}
            {(menu === "costcode" ||
              menu === "costcodeStages" ||
              menu === "timeline") &&
              "Project Filter Dialog"}
          </MenuItem>
        </Menu>
      </div>

      {/* SETTING MENU */}
      <div style={{ width: "24px", height: "24px" }}>
        <IconButton
          sx={{ width: "24px", height: "24px", m: 0, p: 0 }}
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <img
            className="verticallyCenterStyle"
            src={settingsIcon}
            alt="vertical filter icon"
          />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{
            "& .MuiList-root": {
              p: 0,
            },
            "& .MuiMenu-paper": {
              minWidth: "185px",
            },
            "& .MuiMenuItem-root": {
              fontFamily: "Roboto Mono",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              alignItems: "center",
            },
          }}
        >
          <MenuClose handleClick={handleClose} />

          {optionMenuItems.map((menuItem: MenuObject, num: number) =>
            menuItem.type === "option" ? (
              <MenuItem
                key={num.toString()}
                sx={{ color: menuItem.color || "" }}
                divider={menuItem.divider}
                onClick={(event: any) => {
                  handleMenuItemClick(menuItem.clickInput);
                }}
              >
                {menuItem.icon && (
                  <div style={{ paddingRight: "4px", display: "flex" }}>
                    {menuItem.icon}
                  </div>
                )}
                {menuItem.description}
              </MenuItem>
            ) : menuItem.type === "divider" ? (
              <Divider key={"div" + num} />
            ) : menuItem.type === "head" ? (
              <MenuItem
                key={menuItem.description + "Header"}
                divider={menuItem.divider}
                sx={{ color: menuItem.color || "" }}
              >
                {menuItem.description}
              </MenuItem>
            ) : menuItem.type === "blank" ? (
              <br key={"line" + num} />
            ) : null
          )}
          <MenuItem
            disabled
            style={{
              opacity: "1",
              background: "#F1F2F5",
              justifyContent: "center",
              color: "#754EE0",
              height: "35px",
            }}
          >
            Settings Dialog
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};
export default FilterNavigation;
