import PermitCard from './PermitCard'
import styles from './ProjectRow.module.css'
import { ProjectPermits } from '../../../Models/project'
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { NoticeCategory } from '../../../Models/notice'
import { Notice } from '../../../Models/notice'

interface props {
  project: ProjectPermits | undefined
  columnLength: number
  setSelectedNotice: React.Dispatch<React.SetStateAction<Notice | object>>
  setSelectedNoticeAddress: React.Dispatch<React.SetStateAction<string>>
}

const ProjectRow: React.FC<props> = ({
  project,
  columnLength,
  setSelectedNotice,
  setSelectedNoticeAddress,
}) => {
  // Creating an empty array that will later be used for mapping cells in the table
  const columnArray = Array(columnLength + 1).fill(' ')
  const [columns, setColumns] = useState<React.ReactElement[]>(columnArray)

  const permits: Notice[] = project!.noticeBoard
  const categoryIdArray = useMemo(() => {
    let array: (string | NoticeCategory | undefined)[] = []
    permits.forEach((permit) => {
      array!.push(permit.categoryId)
    })
    return array
  }, [permits])

  // used to place the permit based on the categoryId
  const getAdminCategories = useCallback(
    (categoryId: string | NoticeCategory | undefined): number | undefined => {
      const index = categoryIdArray.indexOf(categoryId)
      return index !== -1 ? index : undefined
    },
    [categoryIdArray]
  )

  const fillColumns = () => {
    const updatedColumnArray = [...columnArray]
    permits.forEach((permit) => {
      const columnIndex: number | undefined = getAdminCategories(
        permit.categoryId
      )
      if (columnIndex !== undefined) {
        updatedColumnArray[columnIndex] = (
          <PermitCard
            permit={permit}
            projectAddress={project?.siteAddress!}
            setSelectedNotice={setSelectedNotice}
            setSelectedNoticeAddress={setSelectedNoticeAddress}
          />
        )
      }
    })
    return updatedColumnArray
  }

  useEffect(() => {
    const updatedColumnArray = fillColumns()
    setColumns(updatedColumnArray)
  }, [permits])

  return (
    <tr className={styles['table-row']}>
      <td>{project!.siteAddress}</td>
      {columns.map((column, index) => (
        <td key={index}>{column}</td>
      ))}
    </tr>
  )
}

export default React.memo(ProjectRow)
